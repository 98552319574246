import React , {useState} from "react";
import './followers.style.css';
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import AllFollowers from "./allFollowers";
import ReferAndInviteFollowers from "./referInviteFollowers";
import EmpList from "./emplist";
import EmpReq from "./empReq";
import BulkUploadFollowers from "./bulkUploadFollowers";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import TabNavigation from "../../../commonDashboardComp/tabNavigation";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { FriendOrgCount } from "../../../services/friends.service";
const Followers = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [countsOrgs,setCountsOrgs]=useState();
    const [empCount, setEmpCount] = useState(false);
    const [tab, setTab] = useState((location?.state?.tab === 'empReq')? 3 : 1);
    const tabs = [`${t('Follower')} ${countsOrgs?.followers>0?`(${countsOrgs?.followers ||0})`:''}`, t('Refer_Invite') , `${t('Employees_Requests')} ${countsOrgs?.userRequests>0?`(${countsOrgs?.userRequests ||0})`:''}` , `${t('Employees_List')} ${countsOrgs?.verifiedEmployees>0?`(${countsOrgs?.verifiedEmployees ||0})`:''}` , t('Bulk_Upload')]
    const [searchRes,setSearchRes]=useState('');
    const [totalFriends,setTotalfriends]=useState(0);
    const tabCopy=[t('Follower'),t('Refer_Invite'),t('Employees_Requests'),t('Employees_List'),t('Bulk_Upload')]

    useEffect(()=>{
        getFriends();
        setEmpCount();
     },[empCount])
 
     const getFriends=async()=>{
        let resp= await FriendOrgCount()
        if(resp?.status===200){
            setCountsOrgs(resp?.data?.data);
        }
     }
    const onKeyDownSearchText=(search)=>{
        setSearchRes(search);        
    }

    const searchUpdate = search => {
        setSearchRes(search);
    };
    return (<>

        {/* <div className="col-md-12"> <ProfileHeader coverImg='' img=''/> </div> */}

        <SectionHeader  title={t('Followers_Employees')} />
        {/* <SectionHeader  title={tabs[tab-1]} count={tab===5?'':totalFriends}/> */}
        <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab} onChangeSearchText={searchUpdate}   onKeyUpSearch={onKeyDownSearchText}inputPlaceholder={t('form.Search')}/>

        {tab === 1 && <AllFollowers searchBy={searchRes} countsOfFriends={setTotalfriends} callParentCountMethod={getFriends} />}
        {tab === 2 && <ReferAndInviteFollowers searchBy={searchRes} countsOfFriends={setTotalfriends} callParentCountMethod={getFriends} />}
        {tab === 3 && <EmpReq searchBy={searchRes} countsOfFriends={setTotalfriends} callParentCountMethod={getFriends}/>}
        {tab === 4 && <EmpList searchBy={searchRes} countsOfFriends={setTotalfriends} callParentCountMethod={getFriends}/>}
        {tab === 5 && <BulkUploadFollowers searchBy={searchRes} countsOfFriends={setTotalfriends} callParentCountMethod={getFriends} empCount={empCount} setEmpCount={setEmpCount} />}
        
        </>
    )
}

export default Followers;