import React from "react";
import LoggedInRoutes from "./LoggedInRoutes";
import LoggedOutRoutes from "./LoggedOutRoutes";
import { selectCurrentUser, selectCurrentUserRole, selectCurrentOrgRole } from "../Reducer/user/user.selector";
import { useSelector } from 'react-redux/es/exports';

const AuthRoutes = () => {
    const currentUser = useSelector(selectCurrentUser);
    const role = useSelector(selectCurrentUserRole);
    const orgRole = useSelector(selectCurrentOrgRole)
    return ( <>
            { currentUser !== null ? <LoggedInRoutes role={role} orgRole={orgRole}/> : (<><LoggedOutRoutes/></>)}
        </>
    )
}

export default AuthRoutes;