import React from "react";
import { useTranslation } from "react-i18next";
import '../dashboard.style.css';
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import TargetBox from "../../../commonDashboardComp/targetBox";
import OurImpactBox from "../../../commonDashboardComp/ourImpactBox";
import { Row, Col } from "react-bootstrap";

import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../../Reducer/user/user.selector"; 
import EventCalender from "../../../commonDashboardComp/eventCalender";
import TodayEventList from "../../../commonDashboardComp/todayEventsList";
import CreatePostBox from "../../../commonDashboardComp/createPostBox";
import PostList from "../../../commonDashboardComp/postList";
import ImpactSection from "../../../commonDashboardComp/ImpactSection";
import Memories from "../../../commonDashboardComp/memories";

const UserDashboard = () => {
  const role = useSelector(selectCurrentUserRole);
  
    return (
    <>
      {(<>
      <Row className="grid grid-3-6-3 mobile-prefer-content">
        <Col lg={3} className="col-md-12 mb-3">
          <TargetBox/>
        </Col>

        <Col lg={9} className="col-md-12">
          <ImpactSection/>
        </Col>
      </Row></>)}
      {
        role==='superAdmin' &&(
          <>
          {/* <div>
            <ProfileHeader coverImg='' img=''/>
          </div> */}
          <Row className='grid grid-3-6-3 mobile-prefer-content'>
             <Col md={3} className="grid-column">
              <TargetBox/>
            </Col>
            <Col md={6} className='grid-column'>
              <CreatePostBox/>
              <PostList/>
             
            </Col>
            <Col md={3} className="grid-column">
              <EventCalender/>
              <TodayEventList/>              
            </Col>

          </Row>
          </>
        )
      }
    </>);
}

export default UserDashboard;
