import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { showSingleToastSuccess, showToastError } from "../../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../Reducer/user/user.selector";
import { deleteUserAccountService } from "../../../../services/adminManage.services";
import swal from "sweetalert";
import DownloadProfileDialogueBox from "./downloadProfileDialogueBox";
const DeleteAccount = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const current_user = useSelector(selectCurrentUser)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleDownloadPopupClose = () => {
        handleClose();
        deleteAccountHandler();
    }

    const downloadProfileDetails = (e) => {
        e.preventDefault();

        swal({
            text: t('alerts.download_profile_prompt'),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
        .then (async(val) => {
            if(val){
                handleOpen();
            }
            else {
                swal({
                    text: t('alerts.delete_account'),
                    title: '',
                    icon: "warning",
                    buttons: {
                        Yes: {
                            text: t('yes'),
                            value: true,
                        },
                        No: {
                            text: t('no'),
                            value: false,
                        },
                    },
                })
                .then(async (val) => {
                    if (!val) return;
        
                    try{
                      deleteUserAccountService(current_user?._id)
                      .then((res)=>{
                        if(res?.data?.status === 200){                    
                            showSingleToastSuccess(t(`apiMessages.ACCOUNT_DELETE_SUCCESS`))
                            navigate('/logout');
                        }
                        else{
                          showToastError(t('errors.some_error'))
                        }
                      })
                      .catch((error)=>{
                        showToastError(t('errors.some_error'))
                      })
                    }
                    catch(error){
                      console.log(error)
                    }
                });
            }
        })
    }

    const deleteAccountHandler = () => {
        swal({
            text: t('alerts.delete_account'),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
        .then(async (val) => {
            if (!val) return;

            try{
              deleteUserAccountService(current_user?._id)
              .then((res)=>{
                if(res?.data?.status === 200){                    
                    showSingleToastSuccess(t(`apiMessages.ACCOUNT_DELETE_SUCCESS`))
                    navigate('/logout');
                }
                else{
                  showToastError(t('errors.some_error'))
                }
              })
              .catch((error)=>{
                showToastError(t('errors.some_error'))
              })
            }
            catch(error){
              console.log(error)
            }
        });
    }

    return (
        <>
            <Row className="grid deleteAccountComp">
                <div className="grid-column">
                    <div className="widget-box">
                        <p className="widget-box-title">{t('delete_account')}</p>
                        <div className="widget-box-content">
                            <Row>
                                <Col md={8}>
                                    <span className="span_heading">
                                        <span className="span_heading1">{t('delete_account_info_desc')}</span>
                                    </span>
                                </Col>
                                <Col md={4} className="text-right">
                                    <button className="btn btn-danger" onClick={downloadProfileDetails}>{t('delete_account')}</button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                
            </Row>
            <DownloadProfileDialogueBox open={open} handleClose={handleDownloadPopupClose}/>
        </>
        )
        
}

export default DeleteAccount;