import { map } from "jquery";
import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from "react-i18next";
import BarsLoader from "../commonComponents/barsLoader";
import { getAllImpacts, getOverAllImpactsService } from "../services/impactServices";
const OurImpactBox = ({ widgetClassses, Id, overAllImpacts, circularProgressbarLarge}) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [impactData, setImpactData] = useState('');
    const [categoryWiseData, setCategoryWiseData] = useState('');
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        gettingImpacts();
    }, [Id])

    const impactCalculations = (data) => {
        
        if (data.length > 0) {
            let finalArr = data?.map((a, index) => {
                let obj = { category: a?.categoryDetail?.spredeInterest };
                let subCat = [];
                let totalEvent = 0;
                let eventComp = 0;
                if (a?.subCategoryResult?.length > 0) {
                    subCat = a?.subCategoryResult?.map((sub, ind) => {
                        let subObj = {};
                        let totalEventEvidenceUnit = 0;
                        subObj['eventCompSub'] = sub?.subCategoryCompletedEventLength;
                        subObj['totalEventSub'] = sub?.subCategoryTotalEventLength;
                        eventComp += sub?.subCategoryCompletedEventLength;
                        totalEvent += sub?.subCategoryTotalEventLength;
                        subObj['subCategory'] = sub?.subCategoryDetail?.spredeInterestOptions;
                        subObj['eventUnit']=+sub?.eventUnit;
                        if(sub?.eventEvidence.length > 0){
                            sub?.eventEvidence.map((item)=>{
                                item.map((evidence)=>{
                                    totalEventEvidenceUnit +=parseInt(evidence.unit);
                                })
                            })
                        }
                        subObj['eventUnitCompleted'] = totalEventEvidenceUnit;
                        let percent= ((sub?.targetUnit == 0 || sub?.eventUnit==0 )  ? 0 : (totalEventEvidenceUnit/ sub?.targetUnit) * 100).toFixed(1);
                        subObj['targetUnit'] = sub?.targetUnit;
                        subObj['percentSub'] =isNaN(percent)?0:percent;
                        return subObj;
                    })

                }
                obj['totalEvent'] = totalEvent;
                obj['eventComp'] = eventComp;
                obj['percent'] = totalEvent === 0 ? 0 : (eventComp / totalEvent) * 100;
                obj['subCat'] = subCat;
                return obj;
            })
            let finalObj = {};
            if (finalArr?.length > 0) {
                finalArr?.map((a, index) => {
                    if (a?.category === 'air') {
                        finalObj = { ...finalObj, air: true, airData: a }
                    }
                    else if (a?.category === 'fire') {
                        finalObj = { ...finalObj, fire: true, fireData: a }
                    }
                    else if (a?.category === 'soul') {
                        finalObj = { ...finalObj, soul: true, soulData: a }
                    }
                    else if (a?.category === 'water') {
                        finalObj = { ...finalObj, water: true, waterData: a }
                    }
                    else if (a?.category === 'earth') {
                        finalObj = { ...finalObj, earth: true, earthData: a }
                    }
                })
                setCategoryWiseData(finalObj);
            }
            setImpactData(finalArr);

        }

    }
    const gettingImpacts = async () => {
        setLoader(true);
        if (overAllImpacts) {
            let resp = await getOverAllImpactsService();
            if (resp?.status === 200) {
                //setImpactData(resp?.data?.data);
                if (resp?.data?.data.length > 0) {
                    impactCalculations(resp?.data?.data)
                }
            }
            setLoader(false);
        }
        else {
            let resp = await getAllImpacts(Id ? Id : '');
            if (resp?.status === 200) {
                //setImpactData(resp?.data?.data);
                if (resp?.data?.data.length > 0) {
                    impactCalculations(resp?.data?.data)
                }
                setLoader(false);
            }
            else {
                setLoader(false);
            }
        }
    }

    return (

        <div className={`widget-box mt-3 ourImpactComp ${widgetClassses}`}>
            {loader ? <><BarsLoader /></> : <>
                <p className="widget-box-title">{overAllImpacts?t('Overall_Impacts'):t('our_impact')}</p>
                {impactData?.length > 0 ? <>

                    {categoryWiseData?.earth &&
                        <Row>
                            <div className="widget-box mb-3 mt-4">
                                <div className="d-flex align-items-center">
                                    <img src="/Assets/Earth-icon.png" className="" style={{ height: "39px", marginRight: "12px" }} />
                                    <p className="our-impect-title"><a className="bold" to="/">{t('earth')}</a></p>
                                </div>
                                <Row>

                             {categoryWiseData?.earthData?.subCat?.map((sub, index) => {
                                    return<Col md={6}>

                                        <div className="impect_earth_box ">
                                            {sub?.subCategory === 'tree plantation'?
                                               <>
                                                <img src="/Assets/tree.png" className="" />
                                                <p className="col-md-8"><span className="g-color">{sub?.eventUnit}</span> {t('trees_planted')}</p>
                                                </> 
                                            :sub?.subCategory==='solid waste management'?
                                            <>
                                               <img src="/Assets/plogging.png" className=""/>
                                               <p className="col-md-8"><span className="g-color">{sub?.eventUnit}<sup>Kg</sup></span>{sub?.subCategory}</p>
                                            </> 
                                             :sub?.subCategory==='consumption management'?
                                                        <>
                                                            <img src="/Assets/Compos Created.png" className="" />
                                                            <p className="col-md-8"><span className="g-color">{sub?.eventUnit}<sup>Kg</sup></span> {sub?.subCategory}</p>
                                                        </>

                                                 :<>
                                                <div className={`my-2 impactProgressCont ${circularProgressbarLarge}`}>
                                                    <CircularProgressbar
                                                        className="impect_area_cover"
                                                        value={sub?.percentSub}
                                                        text={`${sub?.percentSub}%`}
                                                        styles={{
                                                            text: { fill: '#000000' },
                                                            background: { fill: '#7ba73f' }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <p className="mb-2" key={index}>{sub?.subCategory}</p>
                                                    <p>
                                                        {sub?.eventUnitCompleted} out of {sub?.targetUnit} completed.
                                                    </p>
                                                </div>
                                                </> 
                                            }
                                        </div>
                                       
                                    </Col>
                                     })}
                                   
                                </Row>
                            </div>
                        </Row>
                    }
                    <Row>
                        <Col md={12}>
                            <Row>
                                {categoryWiseData?.water &&
                                    <Col md={12} className="mb-3 p-0">
                                        <div className="widget-box">
                                            <div className="widget-box-content">
                                                <div className="d-flex align-items-center">
                                                    <img src="/Assets/water.png" className="" style={{ height: "39px", marginRight: "12px" }} />
                                                    <p className="our-impect-title"><a className="bold" to="/">{t('water')}</a></p>
                                                </div>
                                                
                                                <Row>
                                                    
                                                {categoryWiseData?.waterData?.subCat?.map((sub, index) => {
                                                    return <Col md={6}>
                                                        <div className="user-status-list users-status-box" key={index}>
                                                        <div className={`impactProgressCont user-status-avatar ${circularProgressbarLarge}`}>
                                                            <CircularProgressbar
                                                                className="lt_blue_circle"
                                                                value={sub?.percentSub}
                                                                text={`${sub?.percentSub}%`}
                                                                styles={{
                                                                    text: { fill: '#000000' },
                                                                    background: { fill: '#019961' }
                                                                }} />
                                                        </div>
                                                        <div className="">
                                                        <p className="mb-2" >{sub?.subCategory}</p>
                                                        <p>
                                                            {sub?.eventUnitCompleted} out of {sub?.targetUnit} completed.
                                                        </p>
                                                            
                                                        </div>
                                                    </div>
                                                    </Col>
                                                })}
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                }
                                {categoryWiseData?.air &&
                                    <Col md={12} className="mb-3 p-0">
                                        <div className="widget-box">
                                            <div className="d-flex align-items-center">
                                                <img src="/Assets/air.png" className="" style={{ height: "39px", marginRight: "12px" }} />
                                                <p className="our-impect-title"><a className="bold" to="/">{t('air')}</a></p>
                                            </div>
                                            
                                            <Row>
                                            {categoryWiseData?.airData?.subCat?.map((sub, index) => {
                                                return<Col md={6}>
                                                    <div className="widget-box-content" key={index}>
                                                    <div className="user-status-list users-status-box">
                                                        <div className={`impactProgressCont user-status-avatar ${circularProgressbarLarge}`}>
                                                            <CircularProgressbar
                                                                className="lt_orange_circle"
                                                                value={sub?.percentSub}
                                                                text={`${sub?.percentSub}%`}
                                                                styles={{
                                                                    text: { fill: '#000000' },
                                                                    background: { fill: '#019961' }
                                                                }} />
                                                        </div>
                                                        <div className="">
                                                        <p className="mb-2" >{sub?.subCategory}</p>
                                                        <p>
                                                            {sub?.eventUnitCompleted} out of {sub?.targetUnit} completed.
                                                        </p>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                </Col>
                                                
                                            })}
                                            </Row>
                                        </div>
                                    </Col>
                                }

                                <Col md={12} className="mb-3 p-0">
                                    {categoryWiseData?.fire &&
                                        <div className="widget-box">
                                            <div className="d-flex align-items-center">
                                                <img src="/Assets/fire.png" className="" style={{ height: "39px", marginRight: "12px" }} />
                                                <p className="our-impect-title"><a className="bold" to="/">{t('fire')}</a></p>    
                                            </div>

                                            <div className="widget-box-content"> 
                                                {/* <div className="user-status-list users-status-box">
                                                    
                                                </div> */}
                                                <Row>
                                                {categoryWiseData?.fireData?.subCat?.map((sub, index) => {
                                                        return <Col md={6}>
                                                            <div className="user-status-list users-status-box" key={index}>
                                                                <div className={`impactProgressCont user-status-avatar ${circularProgressbarLarge}`}>
                                                                    <CircularProgressbar
                                                                        className="lt_red_circle"
                                                                        value={sub?.percentSub}
                                                                        text={`${sub?.percentSub}%`}
                                                                        styles={{
                                                                            text: { fill: '#000000' },
                                                                            background: { fill: '#019961' }
                                                                        }} />
                                                                </div>
                                                                <div className="">
                                                                <p className="px-1 mb-2">{sub?.subCategory}</p>
                                                                <p>
                                                                    {sub?.eventUnitCompleted} out of {sub?.targetUnit} completed.
                                                                </p>
                                                                    
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    })}
                                                </Row>
                                            </div>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} className="mb-3 p-0">
                            {categoryWiseData?.soul &&
                                <div className="widget-box">
                                    <Row>
                                        <Col md={12}>
                                            <div className="widget-box-content">
                                                <div className="">
                                                    <div className="col-md-12">
                                                        <Row className="align-items-center our-impect-title mb-3">
                                                            <div className="d-flex align-items-center">
                                                                <img src="/Assets/Soul - Copy.png" className="" style={{ height: "39px", marginRight: "12px" }} />
                                                                <p className="our-impect-title"><a className="bold" to="/">{t('soul')}</a></p>

                                                            </div>
                                                        </Row>
                                                        {/* <div className="soul-box-area">
                                                            <div className="soul-boxes">
                                                            <p className="user-status-title"><a className="bold"  to="/">41</a></p>
                                                            <p className="parag">{t('change_in_food_habit')}</p>
                                                        </div> */}
                                                        {/* <div className="soul-boxes">
                                                                <p className="user-status-title"><a className="bold"  to="/">234</a></p>
                                                                <p className="parag">{t('animals_adopted')}</p>
                                                            </div> */}
                                                    </div>
                                                </div>
                                                {/* <div className="soul-box-area">
                                                        <div className="soul-boxes">
                                                            <p className="user-status-title"><a className="bold"  to="/">2000$</a></p>
                                                            <p className="parag">{t('payment_in_ngo')}</p>
                                                        </div>
                                                        <div className="soul-boxes">
                                                            <p className="user-status-title"><a className="bold"  to="/">6 {t('months')}</a></p>
                                                            <p className="parag">{t('time_spent_with_NGOs')}</p>
                                                        </div> 
                                                 </div>*/}
                                                 <Row>
                                                    {categoryWiseData?.soulData?.subCat?.map((sub, index) => {
                                                    return<Col md={6}>
                                                    <div className="user-status-list users-status-box" key={index}>
                                                        {
                                                        sub?.subCategory==='animal life conservation'?
                                                            <>
                                                            <div className="soul-boxes">
                                                                <p className="user-status-title"><a className="bold" to="/">{sub?.eventUnit}</a></p>
                                                                <p className="parag">{sub?.subCategory}</p>
                                                            </div>
                                                            </>
                                                            :<>
                                                                <div className={`impactProgressCont user-status-avatar ${circularProgressbarLarge}`}>
                                                                    <CircularProgressbar
                                                                        className="lt_red_circle"
                                                                        value={sub?.percentSub}
                                                                        text={`${sub?.percentSub}%`}
                                                                        styles={{
                                                                            text: { fill: '#000000' },
                                                                            background: { fill: '#019961' }
                                                                        }} />
                                                                </div>
                                                                <div className="">
                                                                <p className="mb-2">{sub?.subCategory}</p>
                                                                    <p>
                                                                        {sub?.eventUnitCompleted} out of {sub?.targetUnit} completed.
                                                                    </p>
                                                                    
                                                                </div>
                                                            </>}
                                                        </div>
                                                        </Col>
                                                        })}
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Col>
                    </Row>
                    </> :
                    <Row>
                        <div className="widget-box mb-3 mt-4">
                            <h5 className="mb-3">{t('no_impact')}</h5>
                        </div>
                    </Row>

                }
            </>}
        </div>
    )
}

export default OurImpactBox;