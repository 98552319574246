import React from "react";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { bgTestimonials } from '../../commonComponents/Images';
import StarRatings from 'react-star-ratings';
import { getImageUrl } from "../../utils/helpers";
const Testimonials = ({ testimonials, cmsData }) => {
    const { t } = useTranslation();
    const testimonial_slider_settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: (testimonials?.length >= 2 ) ? 2 : testimonials?.length,
        arrows: true,
        slidesToScroll: 1,
        className: 'slides',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    const companies_slider_settings = {
        dots: true,
        speed: 500,
        slidesToShow: (cmsData?.partners?.length >= 4 ) ? 4 : cmsData?.partners?.length,
        arrows: true,
        slidesToScroll: 1,
        className: 'slides',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 376,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]

    };

    return (
        <Container fluid className="howitworks-div testimonial-box" style={{ background: '#eef2da url(' + bgTestimonials + ')  right center / contain no-repeat' }}>
            <div className="howitworks-row1">
                <h1 className="heading mb-3"> {t('homepage.testimonials.title')} </h1>
                <h4 className="detail"> {t('homepage.testimonials.sub_title')}</h4>
            </div>

            <Row className="mb-5 testimonial-slider">
                <Slider {...testimonial_slider_settings}>
                    {
                        testimonials?.length > 0 && cmsData &&
                        testimonials?.map((item, index) => {
                            return (
                                <div className="testimonial-box-area" key={index}>
                                    <div className="testimonial-box-img">
                                        <img src={getImageUrl(item?._id, 'cms', item?.image)} alt="" className='img-fluid' />
                                    </div>
                                    <div className="testimonial-content-box">
                                        <StarRatings
                                            rating={parseInt(item?.rating)}
                                            starRatedColor="#fad56b"
                                            numberOfStars={5}
                                            starDimension="15px"
                                            starSpacing="2px"
                                        /> 
                                        {/* ({item?.rating}) */}
                                        <p>{item?.content} </p>
                                        <span>{item?.name}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </Row>

            <div className="howitworks-row1 pt-4 company-text-box">
                <h1 className="heading"> {t('homepage.testimonials.companies_use_sprede')} </h1>
            </div>

            <Row id="partnerSliderBlock" className="company-slider mb-5">
                <Slider {...companies_slider_settings}>
                    {
                        cmsData?.partners?.length > 0 && cmsData &&
                        cmsData?.partners?.map((item, index) => (
                            <div className="company-box-area" key={index}>
                                {/* <img src={TestimonialGoogle} alt="" /> */}
                                <img src={getImageUrl(cmsData?._id, 'cms', item)} alt="" />
                            </div>

                        ))}
                </Slider>
            </Row>
        </Container>
    );
}

export default Testimonials;