import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { Link } from 'react-router-dom';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import { useTranslation } from 'react-i18next';
import Video from '../commonComponents/video';
import PostLikeListPopup from './postLikeListPopup';
import RoundRadio from '../commonComponents/roundCheckbox/roundRadio';
import {
  getTimeGap,
  getImageUrl,
  allowOnlyLettersNumbers,
  showToastSuccess,
  AlertConfirmation,
  showToastError,
  showSingleToastSuccess,
  numberKFormatter,
} from '../utils/helpers';
import AvatarImgBox from '../commonComponents/AvatarImgBox/avatarImgBox';
import { userAction, reportUserService } from '../services/onBoarding.services';
import { uploadFileService } from '../services/fileUpload.services';
import {
  GetPostByIdService,
  CreatePostCommentService,
  ReplyOnCommentService,
  DeletePostService,
  DeleteCommentService,
  DeleteCommentReplyService,
  PostReactionService,
  CommentReactionService,
  ReplyReactionService,
  EditPostService,
  EditCommentService,
  EditReplyService,
  HidePostService,
  ReportPostService,
  FeaturedPostService,
  sharePostsService,
  whoCanCommentOnPostService,
  voxBoxProfileService,
  externalLinkDataService,
} from '../services/post.services';
import { deleteNotificationsService } from '../services/onBoarding.services';
import { postAnyUserData, postUserCancelRequest } from '../services/friends.service';
import Popup from '../commonComponents/popup';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  selectCurrentOrg,
  selectCurrentUser,
  selectCurrentUserRole,
} from '../Reducer/user/user.selector';
import { getTagSuggestionsService } from '../services/post.services';
import swal from 'sweetalert';
import { postReactionList, reportPostTypes } from '../config/constants';
import ImageVideoSlider from '../commonComponents/imageVideoSlider';
import FormTextArea from '../commonComponents/formTextArea';
import { AvatarImg } from '../commonComponents/Images';
import EditPostPopup from './EditPostPopup';
import { convertFromRaw, ContentState } from 'draft-js';
import { convertToHTML } from 'draft-convert';

//Draft JS mentions
import { EditorState, convertToRaw, getDefaultKeyBinding } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import editorStyles from './SimpleMentionEditor.module.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { getOrgDetailsService } from '../services/orgAuth.services';
import PostPreview from './postPreview';
import { getLinkPreview } from 'link-preview-js';
import DOMPurify from 'dompurify';
import ReactLinkify from 'react-linkify';
const PostBox = ({ post, eventId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const role = useSelector(selectCurrentUserRole);
  const CURRENTUSER = useSelector(selectCurrentUser);
  const CURRENTORG = useSelector(selectCurrentOrg);
  const [postText, setPostText] = useState('');
  const [sharedPostText, setSharedPostText] = useState('');
  const addfrndIcon = '/Assets/Add Friend Button.png';
  const removefrndIcon = '/Assets/Cancel request.png';
  const respondfrndIcon = '/Assets/Respond.png';

  let current_user = {};
  if (role === 'organization') {
    current_user = CURRENTORG;
  } else {
    current_user = CURRENTUSER;
  }

  const [dropDownShow, setDropDownShow] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showReactions, setShowReactions] = useState(false);
  const [infoBoxShow, setInfoBoxShow] = useState(false);
  const wrapper = useRef(null);
  const infoBoxWrapper = useRef(null);
  const reactionWrapper = useRef(null);
  const commentWrapper = useRef(null);
  const [postData, setPostData] = useState(post);
  const [readMore, setReadMore] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const handleProfileOpen = () => setProfileOpen(true);
  const handleprofileClose = () => setProfileOpen(false);
  const [link, setLink] = useState('');
  const [previewData, setPreviewData] = useState(null);
  // const [disabled, setDisabled] = useState(true);

  const [profileInfoForPopUp, setProfileInfoForPopUp] = useState();
  //Comment Mentions
  const [comment, setComment] = useState('');
  const commentRef = useRef(null);
  const [CommentEditorState, setCommentEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [infoBoxData, setInfoBoxData] = useState({});
  const [additionalProfileInfo, setaddtionalProfileInfo] = useState({});

  useEffect(() => {
    getInfoBoxData()
    // window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    let raw = convertToRaw(CommentEditorState.getCurrentContent());
    setComment(raw.blocks[0].text.trim());
  }, [CommentEditorState]);

  const [optionsOpen, setOptionsOpen] = useState(false);

  const [suggestions, setSuggestions] = useState([]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin();
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open) => {
    setOptionsOpen(_open);
  }, []);

  const onCommentReplyOpenChange = useCallback((_open, key, type) => {
    SetEditReplyCommentValue(_open, key, type);
  });

  const onSearchChange = useCallback(({ value }) => {
    if (!value) return;
    getTagSuggestionsService(value)
      .then((res) => res?.data?.data?.data)
      .then((res) => {
        let data = [];
        res.map((user) =>
          data.push({
            _id: user?._id,
            name: `${user.firstName} ${user.lastName}`,
            link: `/profile/` + window.btoa(user?._id),
            avatar:
              user?.profilePicture !== ''
                ? getImageUrl(user?._id, 'userProfile', user?.profilePicture)
                : AvatarImg,
          })
        );
        setSuggestions(data);
      });
  }, []);

  //*************** */

  const [showComments, setShowComments] = useState(false);
  const [reply, setReply] = useState('');

  const [addReply, setAddReply] = useState({});
  const [show, setShow] = useState(true);
  const [selectedCommentMedia, setSelectedCommentMedia] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [isLoading1, setLoading1] = useState(false);
  const [isEdit, setIsEdit] = useState({});
  const [editValue, setEditValue] = useState({});

  const [reportReason, setReportReason] = useState('');
  const [reportPostReason, setReportPostReason] = useState('');
  const [reportPostOtherReason, setReportPostOtherReason] = useState('');
  const [whoComment, setWhoComment] = useState(postData?.canComment);
  const [canComment, setCanComment] = useState(true);

  const [myReaction, setMyReaction] = useState(false);

  const [commentPopup, setCommentPopup] = useState(false);
  const handleCommentPopupShow = () => {
    setDropDownShow(false);
    setCommentPopup(true);
  };
  const handleCommentPopupClose = () => setCommentPopup(false);

  const [reportUserPopup, setReportUserPopup] = useState(false);
  const [reportUserError, setReportUserError] = useState(false)
  const [reportPostError, setReportPostError] = useState(false)
  const [isCopied, setCopied] = useState(false);
  const handleReportUserPopupShow = () => {
    setDropDownShow(false);
    setReportUserPopup(true);
  };
  const handleReportUserPopupClose = () => setReportUserPopup(false);

  const handleCopyLinkToPost = (post_id) => {
    let encrptedId = window.btoa(post_id);
    let postURL = `${window.location.origin}/post/${encrptedId}`
    navigator.clipboard.writeText(postURL);
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000);
  }

  const [reportPostPopup, setReportPostPopup] = useState(false);
  const handleReportPostPopupShow = () => {
    setDropDownShow(false);
    setReportPostPopup(true);
  };
  const handleReportPostPopupClose = () => setReportPostPopup(false);

  const [likeListPopup, setLikeListPopup] = useState(false);
  const [likeCommentId, setLikeCommentId] = useState('')
  const [likeReplyId, setLikeReplyId] = useState('')
  const handleLikeListPopupShow = async (type, id) => {
    if (type === 'comment') {
      await setLikeCommentId(id)
    }
    else if (type === 'reply') {
      await setLikeReplyId(id)
    }
    setLikeListPopup(true);
  };
  const handlelikeListPopupClose = () => { setLikeCommentId(''); setLikeReplyId(''); setLikeListPopup(false) };

  const [editPostPopup, setEditPostPopup] = useState(false);
  const handleEditPostPopupShow = () => {
    setEditPostPopup(true);
  };
  const handleEditPostPopupClose = () => setEditPostPopup(false);

  const [sharePostPopup, setSharePostPopup] = useState(false);
  const handleSharePostPopupShow = () => {
    setSharePostPopup(true);
  };
  const handleSharePostPopupClose = () => setSharePostPopup(false);
  const [shareDescription, setShareDescription] = useState('');

  let commentsUseRefs = useRef([]);

  useEffect(() => {
    checkMyReaction();
  }, [postData]);

  useEffect(() => {
    getCanCommentStatus();
  }, [postData]);

  const getPostDataById = () => {
    handleEditPostPopupClose();
    GetPostByIdService(postData?._id)
      .then(async (data) => {
        if (data?.data?.status === 200) {
          setPostData(data?.data?.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const SetEditCmtReplyShowHide = (key, status) => {
    let array = addReply;
    array[`${key}_show`] = status;
    array[`${key}_open`] = false;
    array[`${key}_plugin`] = createMentionPlugin();
    commentsUseRefs.current[`${key}_ref`] = React.createRef();
    array[`${key}_editor`] = EditorState.createEmpty();
    array[`${key}_reply`] = '';
    setAddReply({ ...addReply, ...array });
  };

  const getMentonPluginInstance = (key, type) => {
    const array = type === 'reply' ? addReply : editValue;
    const MentionsPluginInstance = array[`${key}_plugin`].MentionSuggestions;
    return (
      <MentionsPluginInstance
        open={
          type === 'reply' ? addReply[`${key}_open`] : editValue[`${key}_open`]
        }
        onOpenChange={(_open) =>
          onCommentReplyOpenChange(_open, `${key}_open`, type)
        }
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={() => {
          // get the mention object selected
        }}
      />
    );
  };

  const setMedia = (param, key) => {

    if (param !== null || param !== undefined) {
      let arr = selectedCommentMedia;
      arr[key] = param;
      setSelectedCommentMedia({ ...selectedCommentMedia, ...arr });
      setLoading(true)
    } else {
      let arr = selectedCommentMedia;
      arr[key] = null;
      setSelectedCommentMedia({ ...selectedCommentMedia, ...arr });
    }
  };

  const removeSelectedFile = (key) => {
    let arr = selectedCommentMedia;
    arr[key] = null;
    setSelectedCommentMedia({ ...selectedCommentMedia, ...arr });
  };

  const setLoading = (key, status) => {
    let arr = isLoading;
    arr[key] = status;
    setIsLoading({ ...isLoading, ...arr });
  };

  const SetEditCmtReply = (key, status, type, item) => {
    let array = editValue;
    if (
      (item?.image !== null && item?.image?.length > 0) ||
      (item?.video !== null && item?.video?.length > 0)
    ) {
      array[`${key}_imageShow`] = true;
    } else {
      array[`${key}_imageShow`] = false;
    }
    array[`${key}_open`] = false;
    array[`${key}_plugin`] = createMentionPlugin();
    commentsUseRefs.current[`${key}_ref`] = React.createRef();
    if (type === 'reply') {
      array[`${key}_editor`] =
        item?.postMentions && JSON.stringify(item?.postMentions) !== '{}' &&
          item?.postMentions?.entityMap
          ? EditorState.createWithContent(convertFromRaw(item?.postMentions))
          : EditorState.createWithContent(
            ContentState.createFromText(item?.commentReply)
          );
      array[key] = item?.commentReply;
    } else if (type === 'comment') {
      array[`${key}_editor`] =
        item?.postMentions && JSON.stringify(item?.postMentions) !== '{}' &&
          item?.postMentions?.entityMap
          ? EditorState.createWithContent(convertFromRaw(item?.postMentions))
          : EditorState.createWithContent(
            ContentState.createFromText(item?.comment)
          );
      array[key] = item?.comment;
    }
    setEditValue({ ...editValue, ...array });

    let arr = isEdit;
    arr[key] = status;
    setIsEdit({ ...isEdit, ...arr });
  };

  const SetEditReplyCommentValue = (value, key, type) => {
    if (type === 'reply') {
      let arr = addReply;
      arr[key] = value;
      setAddReply({ ...addReply, ...arr });
    } else {
      let arr = editValue;
      arr[key] = value;
      setEditValue({ ...editValue, ...arr });
    }
  };

  const postCommentHandler = async (key) => {
    //e.preventDefault();
    if (comment === '' && (selectedCommentMedia[key] && selectedCommentMedia[key] === 'undefined' && selectedCommentMedia[key] === null)) {
      // && (selectedCommentMedia[key] === 'undefined' || selectedCommentMedia[key] === null)
      return;
    }
    setLoading(key, true);
    let raw = convertToRaw(CommentEditorState.getCurrentContent());
    let mentioned_users = [];

    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key];
      if (ent.type === 'mention') {
        mentioned_users.push(ent.data.mention._id);
      }
    }

    let image = [];
    let video = [];
    //Upload Image Or Video
    if (
      selectedCommentMedia[key] &&
      selectedCommentMedia[key] !== 'undefined' &&
      selectedCommentMedia[key] !== null
    ) {
      if (selectedCommentMedia[key].type.includes('video')) {
        setLoading1(true);
        video.push(selectedCommentMedia[key].lastModified);
        await uploadFileService(
          'voxBoxVideo',
          'voxBox',
          selectedCommentMedia[key].lastModified,
          selectedCommentMedia[key],
          postData?._id
        );
      }
      if (selectedCommentMedia[key].type.includes('image')) {
        setLoading1(true);
        image.push(selectedCommentMedia[key].lastModified);
        await uploadFileService(
          'voxBoxImage',
          'voxBox',
          selectedCommentMedia[key].lastModified,
          selectedCommentMedia[key],
          postData?._id
        );
      }
    }
    //

    try {
      let params = {
        postId: postData?._id,
        comment: comment,
        image: image,
        video: video,
        tags: mentioned_users,
        // postMentions: raw,
      };

      let response = await CreatePostCommentService(params);
      setCommentEditorState(EditorState.createEmpty());
      //setComment("");'
      setAddReply({});
      removeSelectedFile(key);
      getPostDataById();
      setLoading(key, false);
      setLoading1(false);
    } catch (error) {
      console.log(error);
      showToastError(t('errors.some_error'))
      setLoading(key, false);
    }
  };

  const replyHandler = async (commentId, key) => {
    //e.preventDefault();
    if (addReply[`${key}_reply`] !== '' || (selectedCommentMedia[key] && selectedCommentMedia[key] !== 'undefined' && selectedCommentMedia[key] !== null)) {
      setLoading(key, true);
      let image = [];
      let video = [];

      let editor = addReply[`${key}_editor`];
      let raw = convertToRaw(editor.getCurrentContent());
      let mentioned_users = [];

      for (let key in raw.entityMap) {
        const ent = raw.entityMap[key];
        if (ent.type === 'mention') {
          mentioned_users.push(ent.data.mention._id);
        }
      }

      //Upload Image Or Video
      if (
        selectedCommentMedia[key] &&
        selectedCommentMedia[key] !== 'undefined' &&
        selectedCommentMedia[key] !== null
      ) {
        if (selectedCommentMedia[key].type.includes('video')) {
          video.push(selectedCommentMedia[key].lastModified);
          await uploadFileService(
            'voxBoxVideo',
            'voxBox',
            selectedCommentMedia[key].lastModified,
            selectedCommentMedia[key],
            postData?._id
          );
        }
        if (selectedCommentMedia[key].type.includes('image')) {
          image.push(selectedCommentMedia[key].lastModified);
          await uploadFileService(
            'voxBoxImage',
            'voxBox',
            selectedCommentMedia[key].lastModified,
            selectedCommentMedia[key],
            postData?._id
          );
        }
      }
      //

      try {
        let params = {
          commentId: commentId,
          reply: addReply[`${key}_reply`],
          image: image,
          video: video,
          tags: mentioned_users,
          postMentions: raw,
        };

        let response = await ReplyOnCommentService(params);
        SetEditReplyCommentValue('', `${key}_reply`, 'reply');
        SetEditReplyCommentValue(
          EditorState.createEmpty(),
          `${key}_editor`,
          'reply'
        );
        removeSelectedFile(key);
        getPostDataById();
        setLoading(key, false);
      } catch (error) {
        console.log(error);
        showToastError(t('errors.some_error'))
        setLoading(key, false);
      }
    }
  };

  const EditReplyHandler = async (type, key, id) => {
    //e.preventDefault();
    if (editValue[key] !== '' || (selectedCommentMedia[key] && selectedCommentMedia[key] !== 'undefined' && selectedCommentMedia[key] !== null)) {
      setLoading(key, true);
      let params = {};
      if (!editValue[`${key}_imageShow`]) {
        params['image'] = [];
        params['video'] = [];
      }
      let state = editValue[`${key}_editor`];
      let raw = convertToRaw(state.getCurrentContent());
      let mentioned_users = [];

      for (let key in raw.entityMap) {
        const ent = raw.entityMap[key];
        if (ent.type === 'mention') {
          mentioned_users.push(ent.data.mention._id);
        }
      }

      //Upload Image Or Video
      if (
        selectedCommentMedia[key] &&
        selectedCommentMedia[key] !== 'undefined' &&
        selectedCommentMedia[key] !== null
      ) {
        if (selectedCommentMedia[key].type.includes('video')) {
          params['video'] = [selectedCommentMedia[key].lastModified];
          await uploadFileService(
            'voxBoxVideo',
            'voxBox',
            selectedCommentMedia[key].lastModified,
            selectedCommentMedia[key],
            postData?._id
          );
        }
        if (selectedCommentMedia[key].type.includes('image')) {
          params['image'] = [selectedCommentMedia[key].lastModified];
          await uploadFileService(
            'voxBoxImage',
            'voxBox',
            selectedCommentMedia[key].lastModified,
            selectedCommentMedia[key],
            postData?._id
          );
        }
      }
      params['tags'] = mentioned_users;
      params['postMentions'] = raw;

      if (type === 'comment') {
        params['commentId'] = id;
        params['comment'] = editValue[key];
        try {
          let response = await EditCommentService(params);
        }
        catch (error) {
          console.log(error)
          showToastError(t('errors.some_error'))
        }

        resetEditValues(key);
      } else if (type === 'reply') {
        params['replyId'] = id;
        params['reply'] = editValue[key];

        try {
          let response = await EditReplyService(params);
        }
        catch (error) {
          console.log(error)
          showToastError(t('errors.some_error'))
        }



        resetEditValues(key);
      }
    }
  };

  const resetEditValues = (key) => {
    SetEditReplyCommentValue('', key);
    removeSelectedFile(key);
    SetEditCmtReply(key, false);
    getPostDataById();
    setLoading(key, false);
  };

  const blockUserHandler = async (e, id) => {
    e.preventDefault();
    swal({
      text: t('alerts.block_user'),
      title: '',
      icon: 'warning',
      buttons: {
        Yes: {
          text: t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    }).then(async (val) => {
      if (!val) return;
      try {
        let params = {
          action: 'blockUserRequest',
          list: [id],
        };
        let response = await userAction(params);
        getPostDataById();
        showSingleToastSuccess(t('apiMessages.USER_BLOCKED_SUCCESS'));
      } catch (error) {
        console.log(error);
        showToastError(t('errors.some_error'))
      }
    });
  };

  const deletePostHandler = async (e) => {
    e.preventDefault();
    swal({
      text: t('alerts.delete_post'),
      title: '',
      icon: 'warning',
      buttons: {
        Yes: {
          text: t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    }).then(async (val) => {
      if (!val) return;
      try {
        let response = await DeletePostService(postData?._id);
        setShow(false);
        showSingleToastSuccess(t('apiMessages.POST_DELETED_SUCCESS'));
        deleteNotificationsService('postDelete', postData?._id);
      } catch (error) {
        console.log(error);
        showToastError(t('errors.some_error'))
      }
    });
  };

  const deleteCommentHandler = async (e, commentId) => {
    e.preventDefault();
    swal({
      text: t('alerts.delete_comment'),
      title: '',
      icon: 'warning',
      buttons: {
        Yes: {
          text: t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    }).then(async (val) => {
      if (!val) return;
      try {
        let response = await DeleteCommentService(postData?._id, commentId);
        getPostDataById();
        showSingleToastSuccess(t('apiMessages.COMMENT_DELETED_SUCCESS'));
        deleteNotificationsService('postCommentDelete', commentId);
      } catch (error) {
        console.log(error);
        showToastError(t('errors.some_error'))
      }
    });
  };

  const deleteCommentReplyHandler = async (e, commentId, replyId) => {
    e.preventDefault();
    swal({
      text: t('alerts.delete_reply'),
      title: '',
      icon: 'warning',
      buttons: {
        Yes: {
          text: t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    }).then(async (val) => {
      if (!val) return;
      try {
        let response = await DeleteCommentReplyService(commentId, replyId);
        getPostDataById();
        showSingleToastSuccess(t('apiMessages.REPLY_DELETED_SUCCESS'));
        deleteNotificationsService('postCommentReplyDelete', replyId);
      } catch (error) {
        console.log(error);
        showToastError(t('errors.some_error'))
      }
    });
  };

  const handleClickOutside = (event) => {
    if (wrapper.current && !wrapper.current.contains(event.target)) {
      setDropDownShow(false);
    }

    if (infoBoxWrapper.current && !infoBoxWrapper.current.contains(event.target)) {
      setInfoBoxShow(false);
    }

    if (
      reactionWrapper.current &&
      !reactionWrapper.current.contains(event.target)
    ) {
      setShowReactions(false);
    }
  };

  const handleLinkPreview = async(e) => {
    var input = e;
    var expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    var matches = input.match(expression);

    if(matches){
      for(var each in matches) {
        setLink(matches[each]);
      }
  }
  }

  const handleFetchLinkPreview = async() =>{
    try {
      await externalLinkDataService(link).then((data) => {
        setPreviewData(data?.data?.data);
      })
      

    } catch (error) {

      console.error('Error fetching link preview:', error);    
      setPreviewData(null);

    }
   }

  useEffect(()=>{
    if(link){
     handleFetchLinkPreview()
    }
  },[link])

  const deleteMetaData = () => {
    setPreviewData('');
    handleClose();
  }

  const postReactionHandler = (e, type, id, reaction) => {
    e.preventDefault();
    if (type === 'post') {
      let params = {
        postId: id,
        reaction: reaction,
      };
      PostReactionService(params)
        .then((data) => {
          if (data?.data?.status === 200) {
            getPostDataById();
          }
          else {
            showToastError(t('errors.some_error'))
          }
        })
        .catch((error) => {
          console.log(error);
          showToastError(t('errors.some_error'))
        });
    } else if (type === 'comment') {
      let params = {
        commentId: id,
        postId: postData?._id,
        reaction: reaction,
      };
      CommentReactionService(params)
        .then((data) => {
          if (data?.data?.status === 200) {
            getPostDataById();
          }
          else {
            showToastError(t('errors.some_error'))
          }
        })
        .catch((error) => {
          console.log(error);
          showToastError(t('errors.some_error'))
        });
    } else if (type === 'reply') {
      let params = {
        replyId: id,
        postId: postData?._id,
        reaction: reaction,
      };
      ReplyReactionService(params)
        .then((data) => {
          if (data?.data?.status === 200) {
            getPostDataById();
          }
          else {
            showToastError(t('errors.some_error'))
          }
        })
        .catch((error) => {
          console.log(error);
          showToastError(t('errors.some_error'))
        });
    }
  };

  const checkMyReaction = () => {
    let filter = postData?.likes?.filter(
      (item) => item.userId === current_user?._id
    );
    if (filter.length > 0) {
      setMyReaction(filter[0]);
    } else {
      setMyReaction(false);
    }
  };

  function keyBindingFn(e) {

    // This wasn't the delete key, so we return Draft's default command for this key
    return getDefaultKeyBinding(e);
  }

  function handleKeyCommand(command, type, key, id) {
    if (command === 'split-block') {

      if (type === 'mainComment') {
        postCommentHandler(key)
      }
      else if (type === 'comment' || type === 'reply') {
        EditReplyHandler(type, key, id)
      }
      else if (type === 'mainReply') {
        replyHandler(key, id)
      }
      return 'handled';
    }
  }
  const checkMyLikeStatus = (cmtRepItem, type) => {
    let filterData = cmtRepItem?.likes?.filter(
      (likes) => likes.userId === current_user?._id
    );

    return (
      <>

        {/* {
          <span className="count" style={{ marginLeft: '8px', cursor:'pointer' }} onClick={() => handleLikeListPopupShow(type , cmtRepItem?._id)}>
            {numberKFormatter(cmtRepItem?.likes?.length)}
          </span>
        } */}



        {/* comments and replies Likes Count */}
        {cmtRepItem.likes.length > 0 &&
          <div
            className="meta-line"
            onClick={() => handleLikeListPopupShow(type, cmtRepItem?._id)}
            style={{ cursor: 'pointer' }}
          >
            <div className="meta-line-list reaction-item-list">
              <div
                className="reaction-item"
                style={{ position: 'relative' }}
              >
                <img
                  className="reaction-image reaction-item-dropdown-trigger"
                  src="/img/reaction/wow.png"
                  alt="reaction-wow"
                />
              </div>

              <div
                className="reaction-item"
                style={{ position: 'relative' }}
              >
                <img
                  className="reaction-image reaction-item-dropdown-trigger"
                  src="/img/reaction/like.png"
                  alt="reaction-like"
                />
              </div>
            </div>
            <p className="meta-line-text">{numberKFormatter(cmtRepItem.likes.length)}</p>
          </div>}


        <div className='reply_reaction_hover post-option-wrap reaction-item-list'>
          {filterData && filterData.length > 0 ? (
            <button
              className="reaction-item"
              onClick={(e) => postReactionHandler(e, type, cmtRepItem?._id, '')}
            >
              <img
                className='reaction-image'
                src={`/img/reaction/${filterData[0].reaction}.png`}
                alt={filterData[0].reaction}
              />
            </button>
          ) : (
            <button
              className="cmt_like_btn reaction-item-dropdown-trigger"
              onClick={(e) =>
                postReactionHandler(e, type, cmtRepItem?._id, 'like')
              }
            >{t('react')}</button>

          )}
          <div className="reaction-options reaction-options-dropdown">
            {postReactionList.map((item, index) => {
              return (
                <Link
                  to="#"
                  onClick={(e) =>
                    postReactionHandler(e, type, cmtRepItem?._id, item.value)
                  }
                  className="reaction-option text-tooltip-tft"
                  data-title={item.label}
                  style={{ position: 'relative' }}
                  key={index}
                >
                  <img
                    className="reaction-option-image"
                    src={`/img/reaction/${item.value}.png`}
                    alt="reaction-like"
                  />
                  <div className="xm-tooltip">
                    <p className="xm-tooltip-text">{t(item.value)}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>

        {/* comments and replies Likes Count END */}
      </>
    );
  };

  const whoCommentHandler = (e) => {
    e.preventDefault();

    let params = {
      postId: postData?._id,
      canComment: whoComment,
    };

    try {
      whoCanCommentOnPostService(params)
        .then((data) => {
          if (data?.data?.status === 200) {
            getPostDataById();
            showSingleToastSuccess(
              t('apiMessages.WHO_CAN_COMMENT_UPDATE_SUCCESS')
            );
            handleCommentPopupClose();
          }
          else {
            showToastError(t('errors.some_error'))
          }
        })
        .catch((error) => {
          console.log(error);
          showToastError(t('errors.some_error'))
        });
    } catch (error) {
      console.log(error);
      showToastError(t('errors.some_error'))
    }
  };

  const reportUserSubmitHandler = (e) => {
    e.preventDefault();
    setReportUserError(false)
    if (reportReason === '') {
      setReportUserError(true)
      return false;
    }

    try {
      let params = {
        reason: reportReason,
        reportedUserId: postData?.createdBy?._id,
      };
      reportUserService(params)
        .then((data) => {
          if (data?.data?.status === 200) {
            getPostDataById();
            showSingleToastSuccess(t('apiMessages.USER_REPORTED_SUCCESS'));
          }
          else {
            showToastError(t('errors.some_error'))
          }
          handleReportUserPopupClose();
        })
        .catch((error) => {
          console.log(error);
          showToastError(t('errors.some_error'))
        });
    } catch (error) {
      console.log(error);
      showToastError(t('errors.some_error'))
    }
  };

  const reportPostSubmitHandler = (e) => {
    e.preventDefault();
    setReportPostError(false)
    if (
      reportPostReason === '' ||
      (reportPostReason === 'other' && reportPostOtherReason === '')
    ) {
      setReportPostError(true)
      return false;
    }

    try {
      let params = {
        reportedBy: [
          {
            reportedById: current_user?._id,
            reason:
              reportPostReason !== 'other'
                ? reportPostReason
                : reportPostOtherReason,
          },
        ],
        postId: postData?._id,
      };
      ReportPostService(params)
        .then((data) => {
          if (data?.data?.status === 200) {
            getPostDataById();
            showSingleToastSuccess(t('apiMessages.POST_REPORTED_SUCCESS'));
          }
          else {
            showToastError(t('errors.some_error'))
          }
          handleReportPostPopupClose();
        })
        .catch((error) => {
          console.log(error);
          showToastError(t('errors.some_error'))
        });
    } catch (error) {
      console.log(error);
      showToastError(t('errors.some_error'))
    }
  };

  const HidePostHandler = async (e) => {
    e.preventDefault();
    swal({
      text: t('alerts.hide_post'),
      title: '',
      icon: 'warning',
      buttons: {
        Yes: {
          text: t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    }).then(async (val) => {
      if (!val) return;
      try {
        let params = { postId: postData?._id };
        let response = await HidePostService(params);
        setShow(false);
        showSingleToastSuccess(t('apiMessages.POST_HIDDEN_SUCCESS'));
      } catch (error) {
        console.log(error);
        showToastError(t('errors.some_error'))
      }
    });
  };

  const makeFeaturedHandler = async (e, status) => {
    e.preventDefault();
    swal({
      text: status
        ? t('alerts.mark_featured_post')
        : t('alerts.mark_unfeatured_post'),
      title: '',
      icon: 'warning',
      buttons: {
        Yes: {
          text: t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    }).then(async (val) => {
      if (!val) return;
      try {
        let params = { postId: postData?._id, featuredStatus: status };
        let response = await FeaturedPostService(params);
        getPostDataById();
        showSingleToastSuccess((status) ? t('apiMessages.POST_MARKED_AS_FEATURED') : t('apiMessages.POST_MARKED_AS_UNFEATURED'));
      } catch (error) {
        console.log(error);
        showToastError(t('errors.some_error'))
      }
    });
  };

  const sharePostHandler = async (e) => {
    e.preventDefault();

    try {
      let params = {
        postId: postData?.sharedPostId?._id
          ? postData?.sharedPostId?._id
          : postData?._id,
        sharedDescription: shareDescription,
      };
      let response = await sharePostsService(params);
      handleSharePostPopupClose();
      setShareDescription('');
      showSingleToastSuccess(t('apiMessages.POST_SHARED_SUCCESS'));
    } catch (error) {
      console.log(error);
      showToastError(t('errors.some_text_error'))
    }
  };

  const getCanCommentStatus = async () => {
    if (postData?.canComment === 'private') {
      setCanComment(false);
    } else if (postData?.canComment === 'everyone') {
      setCanComment(true);
    } else if (role === 'organization') {
      setCanComment(false);
    } else if (postData?.createdBy?._id === current_user?._id) {
      setCanComment(true);
    } else {
      const response = await postAnyUserData(postData?.createdBy?._id);

      if (response?.data?.data) {
        if (response?.data?.data?.isFriend) {
          setCanComment(true);
        }
        else { setCanComment(false) }
      }
    }
  };

  const navigateProfile = (e, data) => {
    e.preventDefault();
    if (data) {
      let encrptedId = window.btoa(data?.userId);
      if (current_user?._id === data?.userId) {
        navigate('/profile');
      } else if (data?.createdBy?.name) {
        navigate(
          `/organization/${window.btoa(data?.userId)}`,
          { state: { orgId: data?.userId } }
        );
      } else {
        navigate(`/profile/${encrptedId}`, { state: { userId: data?.userId } });
      }
    } else {
      let encrptedId = window.btoa(postData?.createdBy?._id);
      if (current_user?._id === postData?.createdBy?._id) {
        navigate('/profile');
      } else if (postData?.createdBy?.name) {

        navigate(`/organization/${window.btoa(postData?.createdBy?._id)}`);
      } else {
        navigate(`/profile/${encrptedId}`, {
          state: { userId: postData?.createdBy?._id },
        });
      }
    }
  };

  const getOverLay = () => {
    return (
      <div className="overlay">
        <div className="text">
          + {postData?.image?.length + postData?.video?.length - 5}
        </div>
      </div>
    );
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (
      postData?.postMentions &&
      postData?.postMentions?.entityMap?.length &&
      JSON.stringify(postData?.postMentions) !== '{}'
    ) {
      setPostText(
        convertToHTML({
          entityToHTML: (entity, originalText) => {
            if (entity.type === 'mention') {
              return <a href={entity.data.mention.link}>{originalText}</a>;
            }
            return originalText;
          },
        })(convertFromRaw(postData?.postMentions))
      );
    } else {
      setPostText(`<p> ${postData?.post} </p>`);
      // setPostText(postData?.post);
      handleLinkPreview(postData?.post);
    }
    if (
      postData?.sharedPostId?.postMentions &&
      postData?.sharedPostId?.postMentions?.entityMap?.length &&
      JSON.stringify(postData?.sharedPostId?.postMentions) !== '{}'
    ) {
      setSharedPostText(
        convertToHTML({
          entityToHTML: (entity, originalText) => {
            if (entity.type === 'mention') {
              return <a href={entity.data.mention.link}>{originalText}</a>;
            }
            return originalText;
          },
        })(convertFromRaw(postData?.sharedPostId?.postMentions))
      );
    } else if (postData?.sharedPostId?.post) {
      setSharedPostText(`<p> ${postData?.sharedPostId?.post} </p>`);
    }
  }, [postData]);

  const getCommentReplyTextToShow = (item, type) => {
    if (
      item?.postMentions &&
      JSON.stringify(item?.postMentions) !== '{}' &&
      item?.postMentions?.entityMap?.length
    ) {
      return convertToHTML({
        entityToHTML: (entity, originalText) => {
          if (entity.type === 'mention') {
            return <a href={entity.data.mention.link}>{originalText}</a>;
          }
          return originalText;
        },
      })(convertFromRaw(item?.postMentions));
    } else {
      if (type === 'comment') {
        return `<p> ${item?.comment} </p>`;
      } else {
        return `<p> ${item?.commentReply} </p>`;
      }
    }
  };

  const renderHTML = (rawHTML) =>
    React.createElement('div', {
      dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(rawHTML, {ALLOWED_TAGS: ['p', 'a']})}, 
    });

  const readMoreToggle = () => {
    setReadMore(prevState => !prevState)
  }

  const getInfoBoxData = () => {

    let type = (postData?.createdBy?.firstName && postData?.createdBy?.lastName) ? 'user' : 'org';
    let id = postData?.createdBy?._id;
    if (type && id) {
      voxBoxProfileService(type, id)
        .then(async (resp) => {
          if (resp?.status == 200) {
            setInfoBoxData({ ...resp?.data?.data, id, type: type });
            if (type === 'user' && id !== current_user?._id) {
              const response = await postAnyUserData(postData?.createdBy?._id);
              if (response?.status == 200) {
                setaddtionalProfileInfo(response?.data?.data);
              }
            }
            else if (type == 'org' && id !== current_user?._id) {
              const response = await getOrgDetailsService(id);
              if (response?.status === 200) {
                setaddtionalProfileInfo(response?.data?.data);
              }
            }
          }
        })
    }
  }

  const handleUserRequests = async (action) => {
    // if (friendData?.status === 'active') {
    const params = {
      action: action,
      list: [infoBoxData?.id]
    }
    setTimeout(async () => {
      await postUserCancelRequest(params)
        .then((res) => {
          if (res) {
            let SuccessMsg = action === 'cancelRequest' ? t('apiMessages.FRIEND_REQUEST_CANCEL')
              : action === 'acceptRequest' ? t('apiMessages.FRIEND_REQUEST_ACCEPTED')
                : action === 'deleteRequest' ? t('apiMessages.FRIEND_REQUEST_DELETED')
                  : action === 'unFriendRequest' ? t('apiMessages.FRIEND_REMOVED')
                    : action === 'sendRequest' ? t('apiMessages.FRIEND_REQUEST_SENT')
                      : action === 'blockUserRequest' ? t('User_Blocked')
                        : action === 'unBlockUserRequest' ? t('User_unBlocked')
                          : "";

            //showToastSuccess(SuccessMsg);
            showSingleToastSuccess(t(SuccessMsg));
            getInfoBoxData();
          }
        })
        .catch((error) => {
          console.log(error);

          //showToastError("Some Error Occurred. Please try again later");

        })
    }, 1000)
    // }
    // else {
    //     showToastError(friendData?.firstName + " is not active!");
    // }


  }

  const getCommentsCount = () => {
    let count = postData?.comments?.length;

    postData?.comments?.map((item) => {
      count += item?.repDetailedComments?.length
    })

    return count;
  }

  function httpHtml(content) {
    const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
    return content.replace(reg, "<a href='$1$2'>$1$2</a>");
  }

  return (
    <>
      {show && (
        <div className="widget-box no-padding">
          <div className="widget-box-settings">
            {(postData?.eventId !== eventId ||
              postData?.isEventFeatured === false) && (
                <div
                  className="post-settings-wrap"
                  style={{ position: 'relative' }}
                >
                  <div className="post-settings widget-box-post-settings-dropdown-trigger">
                    <MoreHorizOutlinedIcon
                      className="post-settings-icon icon-more-dots"
                      onClick={(e) => {
                        e.preventDefault();
                        setDropDownShow(!dropDownShow);
                      }}
                    />
                  </div>
                  {dropDownShow && (
                    <div
                      ref={wrapper}
                      className="simple-dropdown widget-box-post-settings-dropdown"
                      style={{
                        position: 'absolute',
                        zIndex: 9999,
                        top: '47px',
                        right: '4px',
                        transform: 'translate(0px, -20px)',
                        transition:
                          'transform 0.3s ease-in-out 0s, opacity: 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s',
                      }}
                    >
                      {postData?.createdBy?._id === current_user?._id ? (
                        
                        <>
                          {postData?.featured ? (
                            <button
                              className="simple-dropdown-link"
                              onClick={(e) => makeFeaturedHandler(e, false)}
                            >
                              {t('voxbox.make_unfeatured')}
                            </button>
                          ) : (
                            <button
                              className="simple-dropdown-link"
                              onClick={(e) => makeFeaturedHandler(e, true)}
                            >
                              {t('voxbox.make_featured')}
                            </button>
                          )}
                          {/* <button className="simple-dropdown-link" value='Edit Post'>{t('copy_link_to_post')}</button> */}
                          {/* {!postData?.sharedPostId?._id &&
                            !postData?.isEventFeatured && ( */}
                          <button
                            className="simple-dropdown-link"
                            onClick={handleEditPostPopupShow}
                          >
                            {t('edit_post')}
                          </button>
                          {/* )} */}
                          {!postData?.isEventFeatured && (
                            <button
                              className="simple-dropdown-link"
                              onClick={deletePostHandler}
                            >
                              {t('delete_post')}
                            </button>
                          )}
                          <button
                            className="simple-dropdown-link"
                            onClick={handleCommentPopupShow}
                          >
                            {t('WhoCanComment')}
                          </button>

                          <button
                            className="simple-dropdown-link"
                            onClick={(e) => handleCopyLinkToPost(postData?._id)}
                          >
                            {t('CopyLinkToPost')}
                          </button>
                          {
                            isCopied && <div className="click_copy"><p className="">{t('form.Copied')}</p></div>
                          }
                        </>
                      ) : (
                        <>
                          {/* <button className="simple-dropdown-link">{t('copy_link')}</button> */}
                          <button
                            className="simple-dropdown-link"
                            onClick={HidePostHandler}
                          >
                            {t('hide_post')}
                          </button>
                          <button
                            className="simple-dropdown-link"
                            onClick={handleReportPostPopupShow}
                          >
                            {t('reportPost')}
                          </button>
                          {postData?.createdBy?.firstName && (
                            <>
                              <button
                                className="simple-dropdown-link"
                                onClick={(e) =>
                                  blockUserHandler(e, postData?.createdBy?._id)
                                }
                              >
                                {t('block_user')}
                              </button>
                              <button
                                className="simple-dropdown-link"
                                onClick={handleReportUserPopupShow}
                              >
                                {t('reportUser')}
                              </button>
                              <button
                                className="simple-dropdown-link"
                                onClick={(e) => handleCopyLinkToPost(postData?._id)}
                              >
                                {t('CopyLinkToPost')}
                              </button>
                              {
                                isCopied && <div className="click_copy"><p className="">{t('form.Copied')}</p></div>
                              }
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
          </div>

          <div className="widget-box-status">
            <div className="widget-box-status-content">
              {postData?.eventId === eventId && postData?.isEventFeatured ? (
                <>
                  <div className="user-status" style={{ padding: 0 }}>
                    <p className="user-status-title medium">
                      <Link className="bold" to="#">
                        {t('description')}
                      </Link>
                    </p>
                  </div>
                </>
              ) : (
                <div className="user-status customPolygon" style={{ position: "relative" }} >
                  <Link
                    className="user-status-avatar"
                    to="#"
                    onMouseEnter={(e) => setInfoBoxShow(!infoBoxShow)}
                    onMouseLeave={(e) => setInfoBoxShow(false)}
                  >
                    <div className="user-short-description-avatar user-avatar profilePage" style={{ pointerEvents: 'none' }}  >
                      <AvatarImgBox
                        img={
                          postData?.createdBy?.profilePicture !== '' ?
                            getImageUrl(
                              postData?.createdBy?._id,
                              'userProfile',
                              postData?.createdBy?.profilePicture
                            ) : AvatarImg
                        }
                      />

                    </div>
                  </Link>

                  <p className="user-status-title medium">
                    <Link className="bold" to="#" onClick={navigateProfile}>
                      {postData?.createdBy?.name
                        ? `${postData?.createdBy?.name}`
                        : `${postData?.createdBy?.firstName} ${postData?.createdBy?.lastName}`}
                    </Link>

                    {postData?.featured &&
                      postData?.createdBy?._id === current_user?._id && (
                        <button className="featured_tag">
                          {t('featured')}
                        </button>
                      )}
                  </p>

                  <p className="user-status-text small">
                    {getTimeGap(postData.createdAt)}
                  </p>

                  {infoBoxShow && (
                    <div
                      ref={infoBoxWrapper}
                      className="simple-dropdown simple-dropdown1 widget-box-post-settings-dropdown"
                    >
                      <Row>
                        <Col md={4} className="ps-2">
                          <div className="user-short-description-avatar user-avatar profilePage shortStatusDropdown" style={{ pointerEvents: 'none' }}  >
                            <AvatarImgBox img={infoBoxData?.profilePicture !== '' ? getImageUrl(infoBoxData?.id, 'userProfile', infoBoxData?.profilePicture) : AvatarImg} />
                          </div>
                        </Col>
                        <Col md={6} className="d-flex align-items-center ps-0">
                          <h6> {infoBoxData?.type === 'user' ? infoBoxData?.firstName + " " + infoBoxData?.lastName : infoBoxData?.name} </h6>
                          {/* <p> <small>{infoBoxData?.friends} mutual connections</small> </p> */}
                        </Col>
                      </Row>
                      <Row className='d-flex flex-nowrap'>
                        {infoBoxData?.type === 'user' &&
                          <>

                            {infoBoxData?.id !== current_user?._id && <>
                              <Col className='userInfoBox infoBoxFont text-center'>
                                {/* <p>{infoBoxData?.postCountValue}</p>
                              <p>Posts</p> */}

                                <p>Mutual connections</p>
                                <p> <small>{infoBoxData?.mutualCountValue} </small> </p>

                              </Col>
                            </>}
                          </>
                        }
                        {infoBoxData?.type === 'user' &&
                          <Col className='text-center infoBoxFont'>
                            <p>Friends</p>
                            <p>{infoBoxData?.friends}</p>
                          </Col>
                        }
                        {infoBoxData?.type === 'org' &&
                          <Col className='text-center infoBoxFont'>
                            <p>Followers</p>
                            <p><small>{infoBoxData?.followers ? infoBoxData?.followers : 0}</small></p>
                          </Col>
                        }

                        {infoBoxData?.type === 'user' && infoBoxData?.country && <Col className='infoBoxFont text-center'>
                          <p>Country</p>
                          <p>{infoBoxData?.country}</p>

                        </Col>
                        }

                        {infoBoxData?.type === 'org' && infoBoxData?.country && <Col className='infoBoxFont'>
                          <p>Country</p>
                          <p>{infoBoxData?.country}</p>

                        </Col>
                        }
                      </Row>
                      {/* { infoBoxData?.id!==current_user?._id &&
                        <Row>
                          <Col>
                            {infoBoxData?.type==='user'?
                            <>
                               {!additionalProfileInfo?.hasOwnProperty('isBlockByMe') &&(!additionalProfileInfo?.isFriend && !additionalProfileInfo?.isFriendsRequestSent && !additionalProfileInfo?.isFriendsRequestReceived) ? <button className=""  title={t('friendsMsg.ADD_FRIEND')} onClick={(e) => handleUserRequests('sendRequest')} >Add Friend</button> : ''}
                            {additionalProfileInfo?.isFriend  ? <> <button className='btn btn-danger text-white bg-danger' title={t('button.block_text')} onClick={(e) => handleUserRequests('blockUserRequest')} >{t('button.block_text')}</button>   <button className='btn btn-danger text-white bg-danger' title={t('friendsMsg.UNFRIEND_USER')} onClick={(e) => handleUserRequests('unFriendRequest')} >{t('friendsMsg.UNFRIEND_USER')}</button>  </> : ''}
                            {additionalProfileInfo?.isFriendsRequestSent ? <button   title={t('friendsMsg.CANCEL_FRIEND_REQUEST')} onClick={(e) => handleUserRequests('cancelRequest')} ><img src={removefrndIcon} className='img-fluid ' /></button> : ''}
                            {additionalProfileInfo?.isFriendsRequestReceived? 
                            <NavDropdown className='iconRight' title={
                                <span className="pull-left">
                                    <img className="thumbnail-image" 
                                        src={respondfrndIcon} 
                                        alt="user pic"
                                    />
                                </span>
                            }  id="navbarScrollingDropdown">
                            <NavDropdown.Item >
                            <button onClick={(e) => handleUserRequests('acceptRequest')} >{t('Confirm')}</button>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item >
                            <button onClick={(e) => handleUserRequests('acceptRequest')} >{t('button.delete')}</button>
                            </NavDropdown.Item>
                            </NavDropdown>:''}
                            </>
                            :
                            <>
                            </>}
                          </Col>

                        </Row>
                        } */}
                    </div>
                  )}
                </div>
              )}
              {
              readMore ?
              <p className="widget-box-status-text postListText" style={{ marginTop: 10 }}>
                {renderHTML(httpHtml(postText))}
              </p>
               :
              // <p className="widget-box-status-text postListText" style={{ marginTop: 10 }}>
              //   {/* {renderHTML(httpHtml(postText.substring(0, 150)))} */}
              //   {/* {`</p><a href="#">Sample text</a><p></p>`} */}
              //   {<ReactLinkify></ReactLinkify>}
              // </p>
              <p  className="widget-box-status-text postListText" style={{ marginTop: 10 }}>
                {
                  <ReactLinkify  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                    </a>
                )}>
                  {DOMPurify.sanitize(httpHtml(postText.substring(0, 150)),{FORBID_TAGS: ['p','a'],KEEP_CONTENT:true, ALLOWED_TAGS:['iframe','script']})}
                </ReactLinkify>
                }
              </p>
              }
              {
              postText.length > 150 && <span style={{ cursor: 'pointer' }} onClick={readMoreToggle}>{readMore ? t('Read_less') : t('Read_more')}</span>}
              {/* {postText?.length > 0 && handleLinkPreview(postText)

              } */}
              <PostPreview data={previewData} link={previewData?.url} deleteMetaData={deleteMetaData} disabled={false}/>
              <div
                className={postData?.sharedPostId?._id ? 'shared' : ''}
                onClick={() => {
                  if (postData?.sharedPostId?._id) {
                    navigate('/post', {
                      state: { postId: postData?.sharedPostId?._id },
                    });
                  }
                }}
                style={{ cursor: 'pointer' }}
              >
                {(postData.image !== null || postData.video !== null) && (
                  <div
                    className="voxbox_post_area_"
                    onClick={() => handleOpen()}
                  >
                    <div className="voxbox_post_upline">
                      {postData?.image !== null &&
                        postData?.image?.length > 0 &&
                        postData?.image?.slice(0, 2).map((item, index) => {
                          return (
                            <figure key={index}>
                              {postData?.sharedPostId?._id ? (
                                <img
                                  src={getImageUrl(
                                    postData?.sharedPostId?._id,
                                    'voxBoxImage',
                                    item
                                  )}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={getImageUrl(
                                    postData._id,
                                    'voxBoxImage',
                                    item
                                  )}
                                  alt=""
                                />
                              )}
                            </figure>
                          );
                        })}
                      {(postData.image === null ||
                        postData?.image?.length === 0) &&
                        postData.video !== null &&
                        postData?.video?.length > 0 &&
                        postData.video.slice(0, 2).map((item, index) => {
                          return (
                            <figure key={index}>
                              {postData?.sharedPostId?._id ? (
                                <Video
                                  showPoster={true}
                                  src={getImageUrl(
                                    postData?.sharedPostId?._id,
                                    'voxBoxVideo',
                                    item
                                  )}
                                />
                              ) : (
                                <Video
                                  showPoster={true}
                                  src={getImageUrl(
                                    postData?._id,
                                    'voxBoxVideo',
                                    item
                                  )}
                                />
                              )}
                            </figure>
                          );
                        })}
                    </div>
                    {((postData?.image !== null &&
                      postData?.image?.length > 2) ||
                      (postData?.video !== null &&
                        postData?.video?.length > 0)) && (
                        <div className="voxbox_post_bottomline">
                          {postData?.image !== null &&
                            postData?.image?.length > 2 &&
                            postData?.image?.slice(2, 5).map((item, index) => {
                              return (
                                <figure key={index}>
                                  {postData?.sharedPostId?._id ? (
                                    <img
                                      src={getImageUrl(
                                        postData?.sharedPostId?._id,
                                        'voxBoxImage',
                                        item
                                      )}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={getImageUrl(
                                        postData._id,
                                        'voxBoxImage',
                                        item
                                      )}
                                      alt=""
                                    />
                                  )}
                                  {index === 2 &&
                                    postData?.image?.length +
                                    postData?.video?.length >
                                    5 &&
                                    getOverLay()}
                                </figure>
                              );
                            })}
                          {postData?.image !== null &&
                            postData?.image?.length < 5 &&
                            postData?.image?.length !== 0 &&
                            postData.video !== null &&
                            postData?.video?.length > 0 &&
                            postData?.video
                              ?.slice(0, 5 - postData?.image?.length)
                              .map((item, index) => {
                                return (
                                  <figure key={index}>
                                    {postData?.sharedPostId?._id ? (
                                      <Video
                                        src={getImageUrl(
                                          postData?.sharedPostId?._id,
                                          'voxBoxVideo',
                                          item
                                        )}
                                      />
                                    ) : (
                                      <Video
                                        src={getImageUrl(
                                          postData?._id,
                                          'voxBoxVideo',
                                          item
                                        )}
                                      />
                                    )}

                                    {index === 2 &&
                                      postData?.image?.length +
                                      postData?.video?.length >
                                      5 &&
                                      getOverLay()}
                                  </figure>
                                );
                              })}
                          {(postData.image === null ||
                            postData?.image?.length === 0) &&
                            postData.video !== null &&
                            postData?.video?.length > 2 &&
                            postData.video.slice(2, 5).map((item, index) => {
                              return (
                                <figure key={index}>
                                  {postData?.sharedPostId?._id ? (
                                    <Video
                                      src={getImageUrl(
                                        postData?.sharedPostId?._id,
                                        'voxBoxVideo',
                                        item
                                      )}
                                    />
                                  ) : (
                                    <Video
                                      src={getImageUrl(
                                        postData?._id,
                                        'voxBoxVideo',
                                        item
                                      )}
                                    />
                                  )}
                                  {index === 2 &&
                                    postData?.image?.length +
                                    postData?.video?.length >
                                    5 &&
                                    getOverLay()}
                                </figure>
                              );
                            })}
                        </div>
                      )}
                  </div>
                )}

                {postData?.sharedPostId?._id && (
                  <div className="user-status customPolygon" >

                    <Link className="user-status-avatar" to="#">
                      <div className="user-short-description-avatar user-avatar profilePage">
                        <AvatarImgBox
                          img={
                            postData?.sharedPostId?.createrDetail
                              ?.profilePicture !== '' &&
                            getImageUrl(
                              postData?.sharedPostId?.createrDetail?._id,
                              'userProfile',
                              postData?.sharedPostId?.createrDetail
                                ?.profilePicture
                            )
                          }
                        />
                      </div>
                    </Link>

                    <p className="user-status-title medium">
                      <Link className="bold" to="#">
                        {postData?.sharedPostId?.createrDetail?.name
                          ? `${postData?.sharedPostId?.createrDetail?.name}`
                          : `${postData?.sharedPostId?.createrDetail?.firstName} ${postData?.sharedPostId?.createrDetail?.lastName}`}
                      </Link>
                    </p>

                    <p className="user-status-text small">
                      {getTimeGap(postData?.sharedPostId?.createdAt)}
                    </p>
                  </div>
                )}
                {postData?.sharedPostId?._id && (
                  <p
                    className="widget-box-status-text"
                    style={{ marginTop: 10, padding: '10px' }}
                  >
                    {renderHTML(sharedPostText)}
                  </p>
                )}
              </div>

              <div className="content-actions">
                <div className="content-action">
                  <div
                    className="meta-line"
                    onClick={() => handleLikeListPopupShow()}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="meta-line-list reaction-item-list">
                      <div
                        className="reaction-item"
                        style={{ position: 'relative' }}
                      >
                        <img
                          className="reaction-image reaction-item-dropdown-trigger"
                          src="/img/reaction/wow.png"
                          alt="reaction-wow"
                        />
                      </div>

                      <div
                        className="reaction-item"
                        style={{ position: 'relative' }}
                      >
                        <img
                          className="reaction-image reaction-item-dropdown-trigger"
                          src="/img/reaction/like.png"
                          alt="reaction-like"
                        />
                      </div>
                    </div>

                    <p className="meta-line-text">{numberKFormatter(postData.likes.length)}</p>
                  </div>
                </div>

                <div className="content-action">
                  {canComment && (
                    <div
                      className="meta-line"
                      onClick={() => setShowComments(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      <p className="meta-line-link">
                        {numberKFormatter(getCommentsCount())} {t('comments')}
                      </p>
                    </div>
                  )}

                  {/* <div className="meta-line">
                    <p className="meta-line-text">0 {t('share')}</p>
                </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="post-options">
            <div className="post-option-wrap mobile_btn_target">
              {myReaction ? (
                <button
                  className="post-option reaction-options-dropdown-trigger"
                  onClick={(e) =>
                    postReactionHandler(e, 'post', postData?._id, '')
                  }
                >
                  {/* <ThumbUpIcon className="post-option-icon icon-thumbs-up"/> */}
                  <img
                    src={`/img/reaction/${myReaction.reaction}.png`}
                    alt={myReaction.reaction}
                  />
                </button>
              ) : (
                <button
                  className="post-option reaction-options-dropdown-trigger"
                  onClick={(e) =>
                    postReactionHandler(e, 'post', postData?._id, 'like')
                  }
                >
                  <ThumbUpOutlinedIcon className="post-option-icon icon-thumbs-up" />
                  <p className="post-option-text">{t('react')}!</p>
                </button>
              )}

              <div className="reaction-options reaction-options-dropdown">
                {postReactionList.map((item, index) => {
                  return (
                    <Link
                      to="#"
                      onClick={(e) =>
                        postReactionHandler(
                          e,
                          'post',
                          postData?._id,
                          item.value
                        )
                      }
                      className="reaction-option text-tooltip-tft"
                      data-title={item.label}
                      style={{ position: 'relative' }}
                      key={index}
                    >
                      <img
                        className="reaction-option-image"
                        src={`/img/reaction/${item.value}.png`}
                        alt="reaction-like"
                      />
                      <div className="xm-tooltip">
                        <p className="xm-tooltip-text">{t(item.value)}</p>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>

            {canComment && (
              <button
                className="post-option"
                onClick={(e) => setShowComments(!showComments)}
              >
                <img
                  src="/Assets/Comment Icon.png"
                  className="post-option-icon icon-comment"
                />
                <p className="post-option-text">{t('comments')}</p>
              </button>
            )}

            <button
              className="post-option"
              onClick={(e) => handleSharePostPopupShow(e)}
            >
              <img
                src="/Assets/Share Icon.png"
                className="post-option-icon icon-share"
              />
              <p className="post-option-text">{t('share')}</p>
            </button>
          </div>

          {showComments && (
            <div className="widget-box-status" ref={commentWrapper}>
              <div className="commentListBox">
                {postData?.comments.length > 0 &&
                  postData?.comments.map((commentItem, i) => {
                    return (
                      <div key={i} className="">
                        <div className='d-flex'>
                          {isEdit[`comment_${commentItem?._id}`] ? (
                            <>
                              {/*  Edit  Comment Input */}
                              <div className="d-flex flex1">
                                <div
                                  className="cmt_profile"
                                  onClick={(e) =>
                                    navigateProfile(e, commentItem)
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  <AvatarImgBox
                                    img={
                                      commentItem?.profilePicture !== ''
                                        ? getImageUrl(
                                          commentItem?.userId,
                                          'userProfile',
                                          commentItem?.profilePicture
                                        )
                                        : AvatarImg
                                    }
                                  />
                                </div>
                                <div className="flex1">
                                  <p>
                                    {commentItem?.name
                                      ? commentItem?.name
                                      : `${commentItem?.firstName} ${commentItem?.lastName}`}
                                  </p>
                                  <Box
                                    component="form"
                                    sx={{
                                      '& .MuiTextField-root': {
                                        m: 1,
                                        width: '100%',
                                      },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    onSubmit={(e) =>
                                      EditReplyHandler(
                                        e,
                                        'comment',
                                        `comment_${commentItem?._id}`,
                                        commentItem?._id
                                      )
                                    }
                                  >
                                    <div className="comment-type-area flexBaseline mb-0 ms-0">
                                      <div className='d-flex flex-column flex1 me-0 mb-0'>
                                        <div
                                          className={
                                            'mb-0 ' +
                                            editorStyles.commentReplyNew
                                          }
                                          onClick={() => {
                                            commentsUseRefs.current[
                                              `comment_${commentItem?._id}_ref`
                                            ].current.focus();
                                          }}
                                        >
                                          <Editor
                                            keyBindingFn={keyBindingFn}
                                            handleKeyCommand={(command) => handleKeyCommand(command, 'comment', `comment_${commentItem?._id}`, commentItem?._id)}
                                            editorKey={'editor'}
                                            editorState={
                                              editValue[
                                              `comment_${commentItem?._id}_editor`
                                              ]
                                            }
                                            onChange={(editorState) => {
                                              let raw = convertToRaw(
                                                editorState.getCurrentContent()
                                              );
                                              SetEditReplyCommentValue(
                                                raw.blocks[0].text.trim(),
                                                `comment_${commentItem?._id}`
                                              );
                                              SetEditReplyCommentValue(
                                                editorState,
                                                `comment_${commentItem?._id}_editor`
                                              );
                                            }}
                                            plugins={[
                                              editValue[
                                              `comment_${commentItem?._id}_plugin`
                                              ],
                                            ]}
                                            ref={
                                              commentsUseRefs.current[
                                              `comment_${commentItem?._id}_ref`
                                              ]
                                            }
                                            placeholder={t('Enter_your_comment')}
                                          />

                                          {getMentonPluginInstance(
                                            `comment_${commentItem?._id}`
                                          )}
                                        </div>
                                        <div className="reply-delete-area justify-content-between">
                                          {commentItem?.userId ===
                                            current_user?._id && (
                                              <>
                                                <button
                                                  className='cancelBtn'
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    SetEditCmtReply(
                                                      `comment_${commentItem?._id}`,
                                                      false
                                                    );
                                                  }}
                                                >
                                                  {t('cancel')}{' '}
                                                </button>
                                              </>
                                            )}
                                          <button
                                            className="button primary sendBtn2 ms-2 d-block d-sm-none"
                                            type="submit"
                                          >
                                            {isLoading[
                                              `comment_${commentItem?._id}`
                                            ] ? (
                                              <span className="spinner-border" role="status" />
                                            ) : (
                                              <SendIcon className="icon-magnifying-glass" />
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                      {!editValue[
                                        `comment_${commentItem?._id}_imageShow`
                                      ] && (
                                          <label
                                            className="ms-2"
                                            htmlFor={`comment_${commentItem?._id}`}
                                          >
                                            <input
                                              type="file"
                                              style={{ display: 'none' }}
                                              id={`comment_${commentItem?._id}`}
                                              onChange={(e) =>
                                                setMedia(
                                                  e.target.files[0],
                                                  `comment_${commentItem?._id}`
                                                )
                                              }
                                            />
                                            <img src="/Assets/Photo-Video.png" />
                                            {/*  className="calender_icons" */}
                                          </label>
                                        )}

                                    </div>

                                    <Row>
                                      {editValue[
                                        `comment_${commentItem?._id}_imageShow`
                                      ] ? (
                                        <>
                                          {commentItem?.image !== null &&
                                            commentItem?.image.length > 0 &&
                                            commentItem?.image.map(
                                              (imgItem, imgIdx) => {
                                                return (
                                                  <>
                                                    <Col
                                                      // md={3}
                                                      // className=""
                                                      key={imgIdx}
                                                    >
                                                      <div className="photo-preview replyImg">
                                                        <img
                                                          src={getImageUrl(
                                                            postData._id,
                                                            'voxBoxImage',
                                                            imgItem
                                                          )}
                                                          alt=""
                                                        />
                                                        <button
                                                          className="deleteImgBtn"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            SetEditReplyCommentValue(
                                                              false,
                                                              `comment_${commentItem?._id}_imageShow`
                                                            );
                                                          }}
                                                        >
                                                          &times;
                                                        </button>
                                                      </div>
                                                    </Col>
                                                  </>
                                                );
                                              }
                                            )}

                                          {commentItem?.video !== null &&
                                            commentItem?.video.length > 0 &&
                                            commentItem?.video.map(
                                              (imgItem, videoIdx) => {
                                                return (
                                                  <>
                                                    <Col
                                                      // md={3}
                                                      className="mb-0"
                                                      key={videoIdx}
                                                    >
                                                      <div className="photo-preview replyImg">
                                                        <video
                                                          poster
                                                          src={getImageUrl(
                                                            postData._id,
                                                            'voxBoxVideo',
                                                            imgItem
                                                          )}
                                                          alt=""
                                                        />
                                                        <button
                                                          className="deleteImgBtn videoDeleteBtn"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            SetEditReplyCommentValue(
                                                              false,
                                                              `comment_${commentItem?._id}_imageShow`
                                                            );
                                                          }}
                                                        >
                                                          &times;
                                                        </button>
                                                      </div>
                                                    </Col>
                                                  </>
                                                );
                                              }
                                            )}
                                        </>
                                      ) : (
                                        <>
                                          {selectedCommentMedia[
                                            `comment_${commentItem?._id}`
                                          ] &&
                                            (selectedCommentMedia[
                                              `comment_${commentItem?._id}`
                                            ] !== '' ||
                                              selectedCommentMedia[
                                              `comment_${commentItem?._id}`
                                              ] !== null) && (
                                              <div className="picture-collage-row row scrollBar">
                                                <Col md={4} className="">
                                                  <div className="photo-preview replyImg">
                                                    {selectedCommentMedia[
                                                      `comment_${commentItem?._id}`
                                                    ].type.includes(
                                                      'video'
                                                    ) ? (
                                                      <video
                                                        poster
                                                        src={URL.createObjectURL(
                                                          selectedCommentMedia[
                                                          `comment_${commentItem?._id}`
                                                          ]
                                                        )}
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <img
                                                        src={URL.createObjectURL(
                                                          selectedCommentMedia[
                                                          `comment_${commentItem?._id}`
                                                          ]
                                                        )}
                                                        alt=""
                                                      />
                                                    )}

                                                    <button
                                                      className="deleteImgBtn"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        removeSelectedFile(
                                                          `comment_${commentItem?._id}`
                                                        );
                                                      }}
                                                    >
                                                      &times;
                                                    </button>
                                                  </div>
                                                </Col>
                                              </div>
                                            )}
                                        </>
                                      )}
                                    </Row>
                                  </Box>
                                </div>
                              </div>
                              {/* ****************** */}
                            </>
                          ) : (
                            <>
                              <div className="col-12">
                                <div className="d-flex mb-3">
                                  <div
                                    className="cmt_profile"
                                    onClick={(e) =>
                                      navigateProfile(e, commentItem)
                                    }
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <AvatarImgBox
                                      img={
                                        commentItem?.profilePicture !== ''
                                          ? getImageUrl(
                                            commentItem?.userId,
                                            'userProfile',
                                            commentItem?.profilePicture
                                          )
                                          : AvatarImg
                                      }
                                    />
                                  </div>
                                  <div>
                                    <div className='cmt_iser_area commentBackground me-3'>
                                      <h6>
                                        {commentItem?.name ? commentItem?.name : `${commentItem?.firstName} ${commentItem?.lastName}`}
                                        <span> {getTimeGap(commentItem?.createdAt)} </span>
                                      </h6>
                                      <p>
                                        {renderHTML(
                                          getCommentReplyTextToShow(
                                            commentItem,
                                            'comment'
                                          )
                                        )}
                                      </p>
                                      <div>
                                        {/* Show Image or video if any in comment */}
                                        {commentItem?.image !== null &&
                                          commentItem?.image.length > 0 &&
                                          commentItem?.image.map((imgItem, imgIdx) => {
                                            return (
                                              <Col md={3} className="" key={imgIdx}>
                                                <div className="photo-preview replyImg">
                                                  <img
                                                    src={getImageUrl(
                                                      postData._id,
                                                      'voxBoxImage',
                                                      imgItem
                                                    )}
                                                    alt=""
                                                  />
                                                </div>
                                              </Col>
                                            );
                                          })}

                                        {commentItem?.video !== null &&
                                          commentItem?.video.length > 0 &&
                                          commentItem?.video.map((imgItem, videoIdx) => {
                                            return (
                                              <Col md={3} className="" key={videoIdx}>
                                                <div className="photo-preview replyImg">
                                                  <Video
                                                    src={getImageUrl(
                                                      postData._id,
                                                      'voxBoxVideo',
                                                      imgItem
                                                    )}
                                                  />
                                                </div>
                                              </Col>
                                            );
                                          })}
                                        {/* ************************************** */}
                                      </div>
                                    </div>

                                    <div className="reply-delete-area">
                                      {checkMyLikeStatus(commentItem, 'comment')}

                                      <button
                                        // data-bs-toggle="collapse"
                                        // data-bs-target={`#collapseComment_${postData?._id}_${commentItem?._id}`}
                                        // aria-expanded="false"
                                        // aria-controls={`collapseComment_${postData?._id}_${commentItem?._id}`}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          SetEditCmtReplyShowHide(
                                            `reply_${commentItem?._id}_${postData._id}`,
                                            !addReply[
                                            `reply_${commentItem?._id}_${postData._id}_show`
                                            ]
                                          );
                                        }}
                                      >
                                        {t('reply')} {commentItem?.repDetailedComments?.length > 0 ? (numberKFormatter(commentItem?.repDetailedComments?.length)) : ""}
                                      </button>

                                      {commentItem?.updatedAt && (
                                        <button className="updatedAtText">
                                          {t('edited')}
                                        </button>
                                      )}

                                      {
                                        (commentItem?.userId ===
                                          current_user?._id) ? <>
                                          <button id={`comment_${commentItem?._id}`} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <MoreHorizOutlinedIcon
                                              className="post-settings-icon icon-more-dots" />
                                          </button>

                                          <div className="dropdown-menu" aria-labelledby={`comment_${commentItem?._id}`} >
                                            <a className="dropdown-item" href="#" onClick={(e) => {
                                              e.preventDefault();
                                              SetEditCmtReply(`comment_${commentItem?._id}`, true, 'comment', commentItem);
                                            }}>{t('edit')}</a>

                                            <a className="dropdown-item" href="#" onClick={(e) => deleteCommentHandler(e, commentItem?._id)
                                            }>{t('delete')}</a>
                                          </div>
                                        </> : (postData?.createdBy?._id === current_user?._id) ? <>
                                          <button onClick={(e) => deleteCommentHandler(e, commentItem?._id)
                                          }>{t('delete')}</button>
                                        </> : ''
                                      }

                                    </div>


                                  </div>
                                </div>

                              </div>
                            </>
                          )}
                        </div>
                        {addReply[
                          `reply_${commentItem?._id}_${postData._id}_show`
                        ] && (
                            <div className="">
                              {/* id={`collapseComment_${postData?._id}_${commentItem?._id}`} */}
                              {commentItem?.repDetailedComments?.length > 0 &&
                                commentItem?.repDetailedComments?.map(
                                  (replyItem, idx) => {
                                    return (
                                      <div className='d-flex' key={idx}>
                                        {isEdit[`reply_${replyItem?._id}`] ? (
                                          <>
                                            {/*  Edit  Reply Input */}
                                            <div className="flex1 editReply">
                                              <div
                                                className="cmt_profile"
                                                onClick={(e) =>
                                                  navigateProfile(e, replyItem)
                                                }
                                                style={{ cursor: 'pointer' }}>
                                                <AvatarImgBox
                                                  img={
                                                    replyItem?.profilePicture !==
                                                      ''
                                                      ? getImageUrl(
                                                        replyItem?.userId,
                                                        'userProfile',
                                                        replyItem?.profilePicture
                                                      )
                                                      : AvatarImg
                                                  }
                                                />
                                              </div>
                                              <div className='flex1'>
                                                <p>
                                                  {replyItem?.name
                                                    ? replyItem?.name
                                                    : `${replyItem?.firstName} ${replyItem?.lastName}`}
                                                </p>
                                                <Box
                                                  component="form"
                                                  sx={{
                                                    '& .MuiTextField-root': {
                                                      m: 1,
                                                      width: '100%',
                                                    },
                                                  }}
                                                  noValidate
                                                  autoComplete="off"
                                                  onSubmit={(e) =>
                                                    EditReplyHandler(
                                                      e,
                                                      'reply',
                                                      `reply_${replyItem?._id}`,
                                                      replyItem?._id
                                                    )
                                                  }
                                                >

                                                  <div className="comment-type-area flexBaseline mb-0 ms-0">
                                                    <div className='d-flex flex-column flex1 me-0 mb-0'>
                                                      <div
                                                        className={"mb-0 " + editorStyles.commentReplyNew}
                                                        onClick={() => {
                                                          commentsUseRefs.current[
                                                            `reply_${replyItem?._id}_ref`
                                                          ].current.focus();
                                                        }}
                                                      >
                                                        <Editor
                                                          keyBindingFn={keyBindingFn}
                                                          handleKeyCommand={(command) => handleKeyCommand(command, 'reply', `reply_${replyItem?._id}`, replyItem?._id)}
                                                          editorKey={'editor'}
                                                          editorState={
                                                            editValue[
                                                            `reply_${replyItem?._id}_editor`
                                                            ]
                                                          }
                                                          onChange={(
                                                            editorState
                                                          ) => {
                                                            let raw = convertToRaw(
                                                              editorState.getCurrentContent()
                                                            );
                                                            SetEditReplyCommentValue(
                                                              raw.blocks[0].text.trim(),
                                                              `reply_${replyItem?._id}`
                                                            );
                                                            SetEditReplyCommentValue(
                                                              editorState,
                                                              `reply_${replyItem?._id}_editor`
                                                            );
                                                          }}
                                                          plugins={[
                                                            editValue[
                                                            `reply_${replyItem?._id}_plugin`
                                                            ],
                                                          ]}
                                                          ref={
                                                            commentsUseRefs.current[
                                                            `reply_${replyItem?._id}_ref`
                                                            ]
                                                          }
                                                          placeholder={t('Enter_your_comment')}
                                                        />

                                                        {getMentonPluginInstance(
                                                          `reply_${replyItem?._id}`
                                                        )}
                                                      </div>
                                                      <div className="reply-delete-area justify-content-between">
                                                        {replyItem?.userId ===
                                                          current_user?._id && (
                                                            <>
                                                              <button
                                                                className='cancelBtn'
                                                                onClick={(e) => {
                                                                  e.preventDefault();
                                                                  SetEditCmtReply(
                                                                    `reply_${replyItem?._id}`,
                                                                    false
                                                                  );
                                                                }}
                                                              >
                                                                {t('cancel')}{' '}
                                                              </button>
                                                            </>
                                                          )}
                                                      </div>
                                                    </div>
                                                    {!editValue[
                                                      `reply_${replyItem?._id}_imageShow`
                                                    ] && (
                                                        <label
                                                          className='ms-2'
                                                          htmlFor={`reply_${replyItem?._id}`}
                                                        >
                                                          <input
                                                            type="file"
                                                            style={{
                                                              display: 'none',
                                                            }}
                                                            id={`reply_${replyItem?._id}`}
                                                            onChange={(e) =>
                                                              setMedia(
                                                                e.target.files[0],
                                                                `reply_${replyItem?._id}`
                                                              )
                                                            }
                                                          />
                                                          <img src="/Assets/Photo-Video.png" />
                                                          {/*  className="calender_icons" */}
                                                        </label>
                                                      )}

                                                  </div>

                                                  <Row>
                                                    {editValue[
                                                      `reply_${replyItem?._id}_imageShow`
                                                    ] ? (
                                                      <>
                                                        {replyItem?.image !==
                                                          null &&
                                                          replyItem?.image
                                                            .length > 0 &&
                                                          replyItem?.image.map(
                                                            (imgItem, imgIdx) => {
                                                              return (
                                                                <>
                                                                  <Col
                                                                    // md={3}
                                                                    className="mb-0"
                                                                    key={imgIdx}
                                                                  >
                                                                    <div className="photo-preview replyImg">
                                                                      <img
                                                                        src={getImageUrl(
                                                                          postData._id,
                                                                          'voxBoxImage',
                                                                          imgItem
                                                                        )}
                                                                        alt=""
                                                                      />
                                                                      <button
                                                                        className="deleteImgBtn"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.preventDefault();
                                                                          SetEditReplyCommentValue(
                                                                            false,
                                                                            `reply_${replyItem?._id}_imageShow`
                                                                          );
                                                                        }}
                                                                      >
                                                                        &times;
                                                                      </button>
                                                                    </div>
                                                                  </Col>
                                                                </>
                                                              );
                                                            }
                                                          )}

                                                        {replyItem?.video !==
                                                          null &&
                                                          replyItem?.video
                                                            .length > 0 &&
                                                          replyItem?.video.map(
                                                            (
                                                              imgItem,
                                                              videoIdx
                                                            ) => {
                                                              return (
                                                                <>
                                                                  <Col
                                                                    // md={3}
                                                                    // className=""
                                                                    key={videoIdx}
                                                                  >
                                                                    <div className="photo-preview replyImg">
                                                                      <video
                                                                        poster
                                                                        src={getImageUrl(
                                                                          postData._id,
                                                                          'voxBoxVideo',
                                                                          imgItem
                                                                        )}
                                                                        alt=""
                                                                      />
                                                                      <button
                                                                        className="deleteImgBtn"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.preventDefault();
                                                                          SetEditReplyCommentValue(
                                                                            false,
                                                                            `reply_${replyItem?._id}_imageShow`
                                                                          );
                                                                        }}
                                                                      >
                                                                        &times;
                                                                      </button>
                                                                    </div>
                                                                  </Col>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {selectedCommentMedia[
                                                          `reply_${replyItem?._id}`
                                                        ] &&
                                                          (selectedCommentMedia[
                                                            `reply_${replyItem?._id}`
                                                          ] !== '' ||
                                                            selectedCommentMedia[
                                                            `reply_${replyItem?._id}`
                                                            ] !== null) && (
                                                            <div className="picture-collage-row row scrollBar">
                                                              <Col
                                                                md={4}
                                                                className=""
                                                              >
                                                                <div className="photo-preview replyImg">
                                                                  {selectedCommentMedia[
                                                                    `reply_${replyItem?._id}`
                                                                  ].type.includes(
                                                                    'video'
                                                                  ) ? (
                                                                    <video
                                                                      poster
                                                                      src={URL.createObjectURL(
                                                                        selectedCommentMedia[
                                                                        `reply_${replyItem?._id}`
                                                                        ]
                                                                      )}
                                                                      alt=""
                                                                    />
                                                                  ) : (
                                                                    <img
                                                                      src={URL.createObjectURL(
                                                                        selectedCommentMedia[
                                                                        `reply_${replyItem?._id}`
                                                                        ]
                                                                      )}
                                                                      alt=""
                                                                    />
                                                                  )}

                                                                  <button
                                                                    className="deleteImgBtn"
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.preventDefault();
                                                                      removeSelectedFile(
                                                                        `reply_${replyItem?._id}`
                                                                      );
                                                                    }}
                                                                  >
                                                                    &times;
                                                                  </button>
                                                                </div>
                                                              </Col>
                                                            </div>
                                                          )}
                                                      </>
                                                    )}
                                                  </Row>
                                                </Box>
                                              </div>
                                            </div>
                                            {/* ****************** */}
                                          </>
                                        ) : (
                                          <>
                                            {/* SHOW REPLY */}
                                            <div className="d-flex flex-column align-items-left cmt_reply_area replyToComment">
                                              <div className='d-flex flex-row'>
                                                <div
                                                  className="cmt_profile"
                                                  onClick={(e) =>
                                                    navigateProfile(e, replyItem)
                                                  }
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  <AvatarImgBox
                                                    img={
                                                      replyItem?.profilePicture !==
                                                        ''
                                                        ? getImageUrl(
                                                          replyItem?.userId,
                                                          'userProfile',
                                                          replyItem?.profilePicture
                                                        )
                                                        : AvatarImg
                                                    }
                                                  />
                                                </div>
                                                <div>
                                                  <div className='cmt_iser_area commentBackground'>
                                                    <h6>
                                                      {replyItem?.name ? replyItem?.name : `${replyItem?.firstName} ${replyItem?.lastName}`}
                                                      <span>  {getTimeGap(replyItem?.createdAt)} </span>
                                                    </h6>
                                                    <p>
                                                      {renderHTML(
                                                        getCommentReplyTextToShow(
                                                          replyItem,
                                                          'reply'
                                                        )
                                                      )}
                                                    </p>
                                                    <div>
                                                      {/* Show Image or video if any in reply */}
                                                      {replyItem?.image !== null &&
                                                        replyItem?.image.length > 0 &&
                                                        replyItem?.image.map(
                                                          (imgItem, imgIdx) => {
                                                            return (
                                                              <>
                                                                <Row>
                                                                  <Col
                                                                    // md={3}
                                                                    // className=""
                                                                    key={imgIdx}
                                                                  >
                                                                    <div className="photo-preview replyImg">
                                                                      <img
                                                                        src={getImageUrl(
                                                                          postData._id,
                                                                          'voxBoxImage',
                                                                          imgItem
                                                                        )}
                                                                        alt=""
                                                                      />
                                                                    </div>
                                                                  </Col>
                                                                </Row>
                                                              </>
                                                            );
                                                          }
                                                        )}

                                                      {replyItem?.video !== null &&
                                                        replyItem?.video.length > 0 &&
                                                        replyItem?.video.map(
                                                          (imgItem, videoIdx) => {
                                                            return (
                                                              <>
                                                                <Row>
                                                                  <Col
                                                                    // md={3}
                                                                    // className=""
                                                                    key={videoIdx}
                                                                  >
                                                                    <div className="photo-preview replyImg">
                                                                      <video
                                                                        poster
                                                                        src={getImageUrl(
                                                                          postData._id,
                                                                          'voxBoxVideo',
                                                                          imgItem
                                                                        )}
                                                                        alt=""
                                                                      />
                                                                    </div>
                                                                  </Col>
                                                                </Row>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      {/* ************************************** */}
                                                    </div>
                                                  </div>

                                                </div>
                                              </div>



                                              <div className="reply-delete-area ms-4">
                                                {checkMyLikeStatus(
                                                  replyItem,
                                                  'reply'
                                                )}



                                                {replyItem?.updatedAt && (
                                                  <button className="updatedAtText">
                                                    {t('edited')}
                                                  </button>
                                                )}

                                                {replyItem?.userId ===
                                                  current_user?._id && (
                                                    <>
                                                      <button id={`reply_${replyItem?._id}`} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <MoreHorizOutlinedIcon
                                                          className="post-settings-icon icon-more-dots" />
                                                      </button>

                                                      <div className="dropdown-menu" aria-labelledby={`reply_${replyItem?._id}`} >
                                                        <a className="dropdown-item" href="#" onClick={(e) => {
                                                          e.preventDefault(); SetEditCmtReply(`reply_${replyItem?._id}`, true, 'reply', replyItem);
                                                        }}>{t('edit')}</a>

                                                        <a className="dropdown-item" href="#" onClick={(e) => deleteCommentReplyHandler(e, commentItem?._id, replyItem?._id)
                                                        }>{t('delete')}</a>
                                                      </div>
                                                    </>
                                                  )}

                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              {/*  Comment Reply Input */}
                              <Row>
                                <div>
                                  <Box
                                    component="form"
                                    sx={{
                                      '& .MuiTextField-root': {
                                        m: 1,
                                        width: '98%',
                                      },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    onSubmit={(e) =>
                                      replyHandler(
                                        e,
                                        commentItem?._id,
                                        `reply_${commentItem?._id}_${postData._id}`
                                      )
                                    }
                                  >
                                    <div className="comment-type-area flex-column replyToCommentInput mb-0" style={{ alignItems: "unset" }}>
                                      <div className='d-flex mb-0'>
                                        <div
                                          className={"mb-0 " + editorStyles.commentReplyNew}
                                          onClick={() => {
                                            commentsUseRefs.current[
                                              `reply_${commentItem?._id}_${postData._id}_ref`
                                            ].current.focus();
                                          }}
                                        >
                                          <Editor
                                            keyBindingFn={keyBindingFn}
                                            handleKeyCommand={(command) => handleKeyCommand(command, 'mainReply', commentItem?._id, `reply_${commentItem?._id}_${postData._id}`)}
                                            editorKey={'editor'}
                                            editorState={
                                              addReply[
                                              `reply_${commentItem?._id}_${postData._id}_editor`
                                              ]
                                            }
                                            onChange={(editorState) => {
                                              let raw = convertToRaw(
                                                editorState.getCurrentContent()
                                              );
                                              SetEditReplyCommentValue(
                                                raw.blocks[0].text.trim(),
                                                `reply_${commentItem?._id}_${postData._id}_reply`,
                                                'reply'
                                              );
                                              SetEditReplyCommentValue(
                                                editorState,
                                                `reply_${commentItem?._id}_${postData._id}_editor`,
                                                'reply'
                                              );
                                            }}
                                            plugins={[
                                              addReply[
                                              `reply_${commentItem?._id}_${postData._id}_plugin`
                                              ],
                                            ]}
                                            ref={
                                              commentsUseRefs.current[
                                              `reply_${commentItem?._id}_${postData._id}_ref`
                                              ]
                                            }
                                            placeholder={t('reply')}
                                          />

                                          {getMentonPluginInstance(
                                            `reply_${commentItem?._id}_${postData._id}`,
                                            'reply'
                                          )}
                                        </div>

                                        <label className='ms-2'
                                          htmlFor={`reply_${commentItem?._id}_${postData._id}`}
                                        >
                                          <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            id={`reply_${commentItem?._id}_${postData._id}`}
                                            onChange={(e) =>
                                              setMedia(
                                                e.target.files[0],
                                                `reply_${commentItem?._id}_${postData._id}`
                                              )
                                            }
                                          />
                                          <img src="/Assets/Photo-Video.png" />
                                          {/* className="calender_icons" */}
                                        </label>

                                      </div>
                                      <div>
                                        <Row>
                                          {selectedCommentMedia[
                                            `reply_${commentItem?._id}_${postData._id}`
                                          ] &&
                                            (selectedCommentMedia[
                                              `reply_${commentItem?._id}_${postData._id}`
                                            ] !== '' ||
                                              selectedCommentMedia[
                                              `reply_${commentItem?._id}_${postData._id}`
                                              ] !== null) && (
                                              <div className="picture-collage-row row scrollBar">
                                                <Col md={4} className="">
                                                  <div className="photo-preview replyImg">
                                                    {selectedCommentMedia[
                                                      `reply_${commentItem?._id}_${postData._id}`
                                                    ].type.includes('video') ? (
                                                      <video
                                                        poster
                                                        src={URL.createObjectURL(
                                                          selectedCommentMedia[
                                                          `reply_${commentItem?._id}_${postData._id}`
                                                          ]
                                                        )}
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <img
                                                        src={URL.createObjectURL(
                                                          selectedCommentMedia[
                                                          `reply_${commentItem?._id}_${postData._id}`
                                                          ]
                                                        )}
                                                        alt=""
                                                      />
                                                    )}

                                                    <button
                                                      className="deleteImgBtn"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        removeSelectedFile(
                                                          `reply_${commentItem?._id}_${postData._id}`
                                                        );
                                                      }}
                                                    >
                                                      &times;
                                                    </button>
                                                  </div>
                                                </Col>
                                              </div>
                                            )}
                                        </Row>
                                      </div>
                                    </div>
                                  </Box>
                                </div>
                              </Row>
                              {/* ****************** */}
                            </div>
                          )}
                      </div>
                    );
                  })}
              </div>
              {/* Main Comment Input */}
              <div className="commentInput">
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                  }}
                  noValidate
                  autoComplete="off"
                  onSubmit={(e) =>
                    postCommentHandler(e, `comment_${postData._id}`)
                  }
                >
                  <div className="comment-type-area flex-column">
                    <div className='commentBox'>
                      <div
                        className={'mb-0 ' + editorStyles.commentReplyNew}
                        onClick={() => {
                          commentRef.current.focus();
                        }}
                      >
                        <Editor
                          keyBindingFn={keyBindingFn}
                          handleKeyCommand={(command) => handleKeyCommand(command, 'mainComment', `comment_${postData._id}`)}
                          editorKey={'editor'}
                          editorState={CommentEditorState}
                          onChange={setCommentEditorState}
                          plugins={plugins}
                          ref={commentRef}
                          placeholder={t('Enter_your_comment')}
                        />
                        <MentionSuggestions
                          open={optionsOpen}
                          onOpenChange={onOpenChange}
                          suggestions={suggestions}
                          onSearchChange={onSearchChange}
                          onAddMention={() => {
                            // get the mention object selected
                          }}
                        />
                      </div>
                      <>
                        <label
                          className="ms-2 align-self-center"
                          htmlFor={`comment_${postData._id}`}
                        >
                          <input
                            type="file"
                            style={{ display: 'none' }}
                            id={`comment_${postData._id}`}
                            onChange={(e) =>
                              setMedia(
                                e.target.files[0],
                                `comment_${postData._id}`
                              )
                            }
                          />
                          <img src="/Assets/Photo-Video.png" />
                        </label>
                        {/* className="calender_icons" */}
                      </>
                    </div>
                    <div className='enterPostText'>
                      <span>{t('pressEnterToPost')}</span>
                    </div>
                  </div>

                  <Row>
                    {selectedCommentMedia[`comment_${postData._id}`] &&
                      (selectedCommentMedia[`comment_${postData._id}`] !== '' ||
                        selectedCommentMedia[`comment_${postData._id}`] !==
                        null) && (
                        <div className="picture-collage-row row scrollBar">
                          {isLoading1 ?
                            <span class="spinner-border text-secondary m-4" >
                            </span> :
                            <Col md={4} className="">
                              <div className="photo-preview">
                                {selectedCommentMedia[
                                  `comment_${postData._id}`
                                ].type.includes('video') ? (
                                  <video
                                    poster
                                    src={URL.createObjectURL(
                                      selectedCommentMedia[
                                      `comment_${postData._id}`
                                      ]
                                    )}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={URL.createObjectURL(
                                      selectedCommentMedia[
                                      `comment_${postData._id}`
                                      ]
                                    )}
                                    alt=""
                                  />
                                )}

                                <button
                                  className="deleteImgBtn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    removeSelectedFile(`comment_${postData._id}`);
                                  }}
                                >
                                  &times;
                                </button>
                              </div>
                            </Col>
                          }
                        </div>
                      )}
                  </Row>
                </Box>
              </div>
              {/* ****************** */}
            </div>
          )}
        </div>
      )}

      <Popup
        heading={t('WhoCanComment')}
        handleClose={handleCommentPopupClose}
        modalHeadingClass={'whoCanCommentHeading'}
        modalBodyClasses={'whoCanCommentModal'}
        modalContentClasses={'whoCanCommentModalContent'}
        open={commentPopup}
      >
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className="userdetail-form whoCanCommentForm"
          onSubmit={whoCommentHandler}
        >
          <FormControl fullWidth className="comment">
            <InputLabel id="co-country-select-label">
              {t('WhoCanComment')}
            </InputLabel>
            {
              (role === 'user') ? <Select
                style={{ width: "100%" }}
                labelId="co-country-select-label"
                variant='outlined'
                value={whoComment}
                label="{t('WhoCanComment')}"
                sx={{
                  textAlign: 'left',
                }}
                onChange={(e) => setWhoComment(e.target.value)}
              >
                <MenuItem value="everyone">{t('everyone_public')}</MenuItem>
                <MenuItem value="friends">{t('friends')}</MenuItem>
                <MenuItem value="private">{t('no_one')}</MenuItem>
              </Select> : <Select
                style={{ width: "100%" }}
                labelId="co-country-select-label"
                variant='outlined'
                value={whoComment}
                label="{t('WhoCanComment')}"
                sx={{
                  textAlign: 'left',
                }}
                onChange={(e) => setWhoComment(e.target.value)}
              >
                <MenuItem value="everyone">{t('everyone_public')}</MenuItem>
                <MenuItem value="private">{t('no_one')}</MenuItem>
              </Select>
            }
          </FormControl>

          <div className="whoCommentBtnBox">
            <button
              className="commentBtn whoCommentCancelBtn"
              onClick={handleCommentPopupClose}
            >
              {' '}
              {t('cancel')}
            </button>
            <button type="submit" className="commentBtn whoCommentSubmitBtn">
              {' '}
              {t('form.submit')}
            </button>
          </div>
        </Box>
      </Popup>

      <Popup
        handleClose={handleReportUserPopupClose}
        open={reportUserPopup}
        modalBodyClasses={'reportPopupContainer'}
      >
        <h2>{t('youAreReporting')}</h2>
        <h3>{`${postData?.createdBy?.firstName} ${postData?.createdBy?.lastName}`}</h3>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className="userdetail-form"
          onSubmit={reportUserSubmitHandler}
        >
          <FormTextArea
            placeholder={t('specify_reason')}
            value={reportReason}
            onChange={(e) =>
              setReportReason(allowOnlyLettersNumbers(e.target.value))
            }
          />

          {reportUserError && reportReason === '' && <div className="error-text">Enter reason to report user</div>}

          <button type="submit" className="button grey reportUserBtn">
            {t('form.submit')}
          </button>
        </Box>
      </Popup>

      <Popup
        handleClose={handleSharePostPopupClose}
        open={sharePostPopup}
        modalBodyClasses={'reportPopupContainer'}
      >
        <h2>{t('share_post')}</h2>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className="userdetail-form"
          onSubmit={sharePostHandler}
        >
          <FormTextArea
            placeholder={t('description')}
            value={shareDescription}
            onChange={(e) =>
              setShareDescription(e.target.value)
            }
          />

          <button type="submit" className="button grey reportUserBtn">
            {' '}
            {t('form.submit')}
          </button>
        </Box>
      </Popup>

      <Popup
        heading={t('reportPost')}
        handleClose={handleReportPostPopupClose}
        open={reportPostPopup}
      >
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className="userdetail-form newTargetTag"
          onSubmit={reportPostSubmitHandler}
        >
          {reportPostTypes.map((item, index) => {
            return (
              <RoundRadio
                label={item?.label}
                value={item?.value}
                name="reportPost"
                onChange={(e) => setReportPostReason(item?.value)}
                checked={reportPostReason === item?.value ? true : false}
                key={index}
              />
            );
          })}

          {reportPostReason === 'other' && (
            <FormTextArea
              placeholder={t('specify_reason')}
              value={reportPostOtherReason}
              onChange={(e) =>
                setReportPostOtherReason(
                  allowOnlyLettersNumbers(e.target.value)
                )
              }
            />
          )}

          {reportPostError && reportPostReason === '' && reportPostOtherReason === '' && <div class="error-text">Select reason to report post</div>}

          <button type="submit" className="button grey">
            {t('form.submit')}
          </button>
        </Box>
      </Popup>

      <ImageVideoSlider
        open={open}
        handleClose={handleClose}
        id={postData?._id}
        postData={postData}
        images={postData?.image}
        videos={postData?.video}
      />

      <PostLikeListPopup
        open={likeListPopup}
        handleClose={handlelikeListPopupClose}
        postId={postData?._id}
        commentId={likeCommentId}
        replyId={likeReplyId}
      />

      {editPostPopup && (
        <EditPostPopup
          open={editPostPopup}
          handleClose={handleEditPostPopupClose}
          postId={postData?._id}
          loadPostData={getPostDataById}
        />
      )}

    </>
  );
};

export default PostBox;
