import React, { useEffect, useState } from "react";
import './org.style.css';
import { Row, Col } from "react-bootstrap";
import { AvatarImg, TestimonialGoogle } from "../../../commonComponents/Images";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { FollowTheSignsSharp } from "@mui/icons-material";
import { followTheOrganization, getOrganizationSugg, OrgFollowingList } from "../../../services/onBoarding.services";
import { getImageUrl, showSingleToastSuccess } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import ReactPagination from "../../../commonComponents/reactPagination";
import Loader from "../../../commonComponents/Loader";
import { useNavigate } from "react-router-dom";
import BarsLoader from "../../../commonComponents/barsLoader";

const FollowingOrg = ({ searchBy, countsOfFriends, parentOrgMethod }) => {
    const [followOrgList, setFollowOrgList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const limit = 8;
    const { t } = useTranslation();
    const [pageNo, setPageNo] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const navigate = useNavigate();
    //const [listOfOrgs, setListOfOrgs] = useState([]);


    useEffect(() => {
        getFollowList(0)
    }, [searchBy])

    const getFollowList = async (currentPage) => {
        setLoading(true);
        setPageNo(currentPage);
        OrgFollowingList(currentPage, limit, searchBy)
            .then((response) => {
                if (response?.data?.status === 200) {
                    const _data = response?.data?.data?.data;
                    setFollowOrgList(_data);
                    let pageCount = (response?.data?.data?.totalCount) / limit;
                    setTotalPage(Math.ceil(pageCount));
                    countsOfFriends(response?.data?.data?.totalCount);
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log(error, "error");
            })
    }
    const unfollowOrganization = async (e, org) => {
        e.preventDefault();
        if (org?.status) {
            let param = {
                list: [org?._id],
                action: "unFollowOrganization"
            }
           await followTheOrganization(param)
                .then((resp) => {
                    if (resp?.status === 200) {
                        showSingleToastSuccess(t(`apiMessages.ORG_UNFOLLOW_REQUEST`));
                        const nextList = [...followOrgList];
                        let index = nextList?.findIndex(a => a._id === org?._id);
                        if (index >= 0) {
                            setFollowOrgList(nextList);
                        }
                        let cntPage = pageNo;
                        if (followOrgList?.length == 1) {
                            setPageNo(pageNo - 1);
                            cntPage = cntPage - 1;
                        }
                        getFollowList(cntPage);
                        parentOrgMethod();
                    }
                })

        }
    }
    const handlePageClick = (data) => {
        let page = data?.selected;
        getFollowList(page);

    }

    const showOrg = (org) => {
        let encrptedId = window.btoa(org?._id);
        navigate(`/organization/${encrptedId}`, { state: {orgId:org?._id}})
    }
    return (
        <Row className="grid">
            {followOrgList?.length > 0 ? <>
                {followOrgList?.map((item, index) => (
                    <Col md={3} sm={6}  className=" grid-column">

                        <div className="organizationBlock" key={index}>
                        <div >
                            <div className="org-image-block" onClick={(e)=>showOrg(item)}>
                                <img src={item?.profilePicture ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : '/Assets/org1@2x.png'} alt="" className="img-circle" />
                            </div>
                            <p>{item?.name}</p>
                        </div>
                            <ul className="employeesFollowers">
                                <li>
                                    <span>{item?.employees}</span>
                                    {t('Employees')}
                                </li>
                                <li>
                                    <span>{item?.followers}</span>
                                    {t('followers')}
                                </li>
                            </ul>
                            <button className="button white white-tertiary organizationBtn" onClick={(e) => unfollowOrganization(e, item)}> <img style={{ width: 15, height: 15, marginRight: 5 }} src="/Assets/UnFollow.png" />{t('unfollow')} </button>
                        </div>
                    </Col>
                ))}
            </> : <h4>{t('orgMsg.NO_ORG_ADDED')}</h4>}
            {isLoading &&  <BarsLoader  />}
            <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />
        </Row >
    )
}

export default FollowingOrg;