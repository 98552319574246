import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { useNavigate ,Link} from "react-router-dom";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { AvatarImg, overAllImpactImg } from "../../../commonComponents/Images";
// import overAllImpactImg from '../../../commonComponents/Images';
import { FriendRequestService } from "../../../services/onBoarding.services";
import { getImageUrl, restrictForwardAndBackwardSlash, showSingleToastSuccess, showToastError, showToastSuccess } from "../../../utils/helpers";
import { useEffect } from "react";
import { allowOnlyLettersNumbers } from "../../../utils/helpers";
const Invites = ({ searchInviteList, invites, skipClick, backClick, getInvitesList }) => {
    const { t } = useTranslation();
    const [searchBox, setSearchBox] = useState('');
    const [count, setCount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        let invite_length = 0;
        Object.keys(invites).map((key, i) => {
            invite_length += invites[key].length;
        })
        setCount(invite_length);
    }, [invites])
    const sendFriendRequest = (e, id) => {
        e.preventDefault();
        let params = {
            "action": "sendRequest",
            "list": [id]
        }
        FriendRequestService(params)
            .then((data) => {
                if (data.status === 200) {
                    // showToastSuccess(t(`apiMessages.REQUEST_SEND_SUCCESS`));
                    showSingleToastSuccess(t(`apiMessages.REQUEST_SEND_SUCCESS`));
                    handleSearch(searchBox)
                } else {
                    showToastError(t(`apiMessages.${data?.data?.data}`))
                }
            })
            .catch((error) => {
                showToastError(t(`apiMessages.${error?.data?.data}`))
            })
    }

    const handleSearch = (searchText) => {
        (searchText.trim() !== '') ? searchInviteList(searchText.trim()) : getInvitesList()
    }
  

    return (
        <>
            <Row>
                <Col md={6}>
                    <div className="heading">
                        <h1>{t('onboarding.invites')}</h1>
                        <h3>{t('onboarding.lets_expand_your_network')}</h3>
                    </div>
                </Col>

                <Col md={6} className="invite-serch-box">
                    <TextField name="search" label={t('form.label_search_name_or_email')}
                        style={{ width: '100%' }}
                        value={searchBox}
                        onChange={(e) => {
                            setSearchBox(restrictForwardAndBackwardSlash(e.target.value));
                            handleSearch(restrictForwardAndBackwardSlash(e.target.value))
                        }}
                        InputProps={{ endAdornment: <img src="/Assets/pro-search-green.png" alt="Earth" /> }}
                    />
                </Col>
            </Row>
            <div className="invite-content-area">
                {count > 0 ? <>
                    {Object.keys(invites).map((key, i) => (
                        <>
                            {(invites[key].length > 0 && key !== 'workplace') &&
                                <>
                                    <Row>
                                        <Col md={10} className="invite-sub-h">
                                            <h5 className="mt-2"> {t(`interest.${key}`)} </h5>
                                            <hr />
                                        </Col>
                                    </Row>
                                    <Row>
                                        {invites[key].map((item, idx) => {
                                            return (<div key={idx} className="invites-box">
                                                <div>
                                                    {/* TODO - ADD USER IMAGE */}
                                                    <Link to={'/profile/'+window.btoa(item?._id)} target="_blank">                                             
                                                    <AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : AvatarImg}  avatarClasses="userInfoInvitFriends"/>
                                                    <h5>{item.firstName} {item.lastname}</h5>
                                                    {/* <p>{item.email}</p> */}
                                                    <p>@{item.userName}</p>
                                                    </Link>
                                                    {
                                                        item.isFriend == true ?
                                                            <button onClick={(e) => e.preventDefault()}> <img src="/Assets/Add-Friend-Icon-inactive.png" />
                                                                {/* {item.isFriend == true ? t('event.Friend') : item.isFriendRequestReceived == true ? t('event.Request_Recieved') : item.isFriendRequestSent == true ? t('event.Request_Send') : t('friendsMsg.ADD_FRIEND')} */}
                                                                 {t('event.Friend')}
                                                            </button> :
                                                            item.isFriendsRequestReceived == true ?
                                                                <button onClick={(e) => e.preventDefault()}> <img src="/Assets/Add-Friend-Icon-inactive.png" />
                                                                    {/* {item.isFriendRequestReceived == true ? t('event.Request_Recieved')  : item.isFriend == true ? t('event.Friend') : item.isFriendRequestSent == true ? t('event.Request_Send') : t('friendsMsg.ADD_FRIEND')} */}
                                                                    {t('event.Request_Recieved')}
                                                                </button> :
                                                                item.isFriendsRequestSent == true ?
                                                                    <button onClick={(e) => e.preventDefault()}> <img src="/Assets/Add-Friend-Icon-inactive.png" />
                                                                        {/* {item.isFriendRequestSent == true ? t('event.Request_Send')  : item.isFriendRequestReceived == true ? t('event.Request_Recieved') : item.isFriend == true ? t('event.Friend') : t('friendsMsg.ADD_FRIEND')} */}
                                                                        {t('event.Request_Send')}
                                                                    </button> :
                                                                    <button onClick={(e) => sendFriendRequest(e, item._id)}> <img src="/Assets/Add-Friend-Icon-inactive.png" />
                                                                        {item.isFriend == true ? t('event.Friend') : item.isFriendRequestReceived == true ? t('event.Request_Recieved') : item.isFriendRequestSent == true ? t('event.Request_Send') : t('friendsMsg.ADD_FRIEND')}
                                                                    </button>
                                                    }
                                                    {/* // <button onClick={(e) => sendFriendRequest(e, item._id)}> <img src="/Assets/Add-Friend-Icon-inactive.png" />
                                                    //     {item.isFriend == true ? 'Friend' : item.isFriendRequestReceived == true ? 'Request Recieved' : item.isFriendRequestSent == true ? 'Request Send' : 'Add Friend'}
                                                    // </button> */}
                                                </div>


                                            </div>)
                                        })}
                                    </Row>
                                </>}
                        </>
                    ))}
                </> : <Row>
                    <Col md={10} className="invite-sub-h">
                        <h5 className="mt-2"> {t('errors.no_invites_list')} </h5>
                        <hr />
                    </Col>
                </Row>
                }


                {
                    Object.keys(invites).forEach((key, index) => {
                        invites[key].length > 0 &&
                            <>
                                <Row>
                                    <Col md={10}>
                                        <span className="formInsideHeading1"> {t(`interest.${key}`)} </span>
                                        <hr />
                                    </Col>
                                </Row>

                            </>
                    }
                    )
                }
            </div>

            <Row>
                <div className="back-next-btn-area">
                    <Stack spacing={2} direction="row">
                        <button className="button green back-btn-new" onClick={backClick}> {t('back')} </button>
                        <button className="button secondary next-btn-new" onClick={skipClick}> {t('next')} </button>
                    </Stack>
                </div>
            </Row>
        </>
    )
}

export default Invites;