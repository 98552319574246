import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, TextField } from "@mui/material";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { useCSVDownloader, useCSVReader, usePapaParse } from 'react-papaparse';
import { useTranslation } from "react-i18next";
import { validEmail } from "../../../utils/formatValidator";
import { showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import { getVendorService, postVendorService } from "../../../services/vendor.services";

const VendorUpload = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const headers = [
        { label: t('form.label_first_name'), key: "firstName" },
        { label: t('Email'), key: "email" },
    ];
    const [csvFile, setCsvFile] = useState();
    const [csvArray, setCsvArray] = useState([]);
    const [error, setError] = useState(false);
    const { CSVDownloader, Type } = useCSVDownloader();
    const { CSVReader } = useCSVReader();
    const { readString } = usePapaParse();
    const data = [
    ];
    const csvReport = {
        data: data,
        headers: headers,
        filename: 'sprede_refer_and_invite_sample.csv',
        target: '_blank'
    };

    const handleUploadFile = (e) => {
        e.preventDefault();
        let file = csvFile;
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const text = e.target.result;
                processCSV(text)
            }
            reader.readAsText(file);
        }
        else {
            setError(true);
        }

    }
    const processCSV = async (str, delim = ',') => {
        let headers = str.slice(0, str.indexOf('\n')).split(delim);
        headers = headers.map((a) => {
            if (a.includes('\r')) {
                a = a.replaceAll('\r', '');
            }
            return a;
        })
        // let checkingIfValidId = headers?.length == 4 && headers?.indexOf('"First Name"') >= 0 && headers?.indexOf('"Email"') >= 0;
        let checkIfValidHeaders = headers[0] === 'Name' && headers[1] === 'Email' && headers[2] === 'Mobile' && headers[3] === 'Location' && headers[4] === 'Supply';
        if (!checkIfValidHeaders) { showToastError(t('vendors.Details_filled_Sample_CSV')); return; }
        const rows = str.slice(str.indexOf('\n') + 1).split('\n');
        const newHeader = ['name', 'email', 'mobile', 'location', 'supply'];
        let newArray = rows.map(row => {
            const values = row.split(delim);
            const eachObject = newHeader.reduce((obj, header, i) => {
                obj[header] = values[i];
                if (obj['email']) {
                    if (obj['email'].includes('\r')) {
                        obj['email'] = obj['email'].replaceAll('\r', '');
                    }
                }
                if (obj['name']) {
                    if (obj['name'].includes('\r')) {
                        obj['name'] = obj['name'].replaceAll('\r', '');
                    }
                }
                if (obj['location']) {
                    if (obj['location'].includes('\r')) {
                        obj['location'] = obj['location'].replaceAll('\r', '');
                    }
                }
                if (obj['supply']) {
                    if (obj['supply'].includes('\r')) {
                        obj['supply'] = obj['supply'].replaceAll('\r', '');
                    }
                }
                return obj;
            }, {})
            return eachObject;
        })
        newArray = newArray?.filter((a => a?.name && a?.email && a?.location && a?.mobile && a?.supply));
        let objError = {};
        let cnt = newArray?.reduce((acc, curr) => {
            if (curr['name'] && curr['email'] && curr['mobile'] && curr['location'] && curr['supply']) {

                if (!validEmail(curr['email'])) {
                    objError['email'] = true;
                    acc += 1;
                }
                if (!checkingValidString(curr['name'])) {
                    objError['name'] = true;
                    acc += 1;
                }
                if (!checkingContact(curr['mobile'])) {
                    objError['mobile'] = true;
                    acc += 1;
                }
                if (!checkingValidString(curr['location'])) {
                    objError['location'] = true;
                    acc += 1;
                }
                if (!checkingValidString(curr['supply'])) {
                    objError['supply'] = true;
                    acc += 1;
                }
            }
            return acc;
        }, 0);
        if (cnt > 0) {
            let toastMsg = objError?.email ? t('Email') : '';
            toastMsg += toastMsg && objError?.mobile ? t('Mobile') : !toastMsg && objError?.mobile ? t('Mobile') : ''
            toastMsg += toastMsg && objError?.location ? t('Location') : !toastMsg && objError?.location ? t('Location') : '';
            toastMsg += toastMsg && objError?.supply ? t('Supply') : !toastMsg && objError?.supply ? t('Supply') : '';
            toastMsg += toastMsg && objError?.name ? t('and_Name') : !toastMsg && objError?.name ? t('form.label_name') : '';
            showToastError(toastMsg + t('vendors.entered_are_not_correct'))
        }
        else {
            let copyArr = [...newArray];
            newArray = newArray?.filter((a => a?.name && a?.email && a?.location && a?.mobile && a?.supply));
            if (copyArr?.length !== newArray?.length) {
            }
            if (newArray?.length > 0) {
                let param = {
                    vendors: newArray
                }
                setLoading(true);
                await postVendorService(param)
                    .then((resp) => {
                        if (resp?.status === 200) {
                            showSingleToastSuccess(t('vendors.Vendors_Detail_uploaded_successfully'));
                            setLoading(false);
                            setCsvArray([]);
                            setCsvFile();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        showToastError(t('apiMessages.Some_Error_Occured_Please_try_again_later'));
                        setLoading(false);

                    })

            }
            else {
                showToastError(t('apiMessages.Choose_Correct_File_to_Upload'))
            }

            setCsvArray(newArray)

        }


    }
    const checkingValidString = (str) => {
        let cnt = 0;
        for (let i = 0; i < str?.length; i++) {
            if ((str[i] >= 'a' && str[i] <= 'z') || (str[i] >= 'A' && str[i] <= 'Z') || str[i] === ' ') {
                cnt++;
            }
        }
        let check = str?.indexOf(" ") < 0 || ((str?.split(" ")?.length > 1) && str?.indexOf(" ") > 0)
        return cnt === str?.length && check

    }
    const checkingNameValid = (name) => {
        var matches = name.match(/^[a-zA-Z\s]+$/);
        return (matches) ? true : false;

    }

    const checkingContact = (contact) => {
        let cnt = contact?.length === 10 && !isNaN(contact)
        return cnt;
    }


    return (<>
        <SectionHeader title={t('vendors.Upload_Vendors')} />

        <Row className="">
            <Col md={6} className="mb-3">
                <div className="widget-box h-100">
                    <p className="widget-box-title">{t('errors.Upload_CSV')}</p>
                    <div className="widget-box-content">
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '98%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Row>

                                <Col md={12}>
                                    <label className="user_img_upload" htmlFor="upload-photo">
                                        <input type="file" accept=".csv" id="upload-photo" onInputCapture={(e) => {
                                            setCsvFile(e.target.files[0]);
                                            setError(false);
                                            e.target.value = null
                                        }} />
                                        <div className="content m-0">
                                            <img src="/Assets/Icon awesome-file-csv.png" />
                                            {csvFile ? <>
                                                <h5 className="my-3">{csvFile?.name}</h5>
                                                <h6 className="gray-ligj-color">{t('roles.Change_CSV')}</h6>
                                            </> : <>
                                                <h5 className="my-3">{t('roles.Select_a_CSV_file_to_upload')}</h5>
                                                {/* <h6 className="gray-ligj-color">Or drag and drop it here</h6> */}
                                            </>}

                                        </div>
                                    </label>
                                    {(!csvFile && error) &&
                                        <div className="error-text">
                                            {t('roles.Please_Select_CSV_file')}
                                        </div>
                                    }
                                </Col>
                                <Col md={{ span: 6, offset: 6 }} lg={{ span: 3, offset: 9 }} className="mb-0 text-right" xs={12}>
                                    <button className="button primary bulkUploadBtn" onClick={(e) => handleUploadFile(e)}> {t('errors.Upload')}   {loading && <span className="spinner-border" />}</button>
                                </Col>
                            </Row>
                        </Box>
                    </div>
                </div>
            </Col>

            <Col md={6} className="mb-3">
                <div className="widget-box h-100">
                    <p className="widget-box-title">{t('errors.Refer_Invite_Via_Email')}</p>
                    <div className="widget-box-content">
                        <Row>
                            <Col md={12} className="text-center">
                                <img src="/Assets/Icon awesome-file-csv.png" />
                                <h5 className="mt-3 mb-5">{t('errors.Here_is_the_sample_CSV_file')}</h5>
                            </Col>
                            <Col md={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }} className="text-center text-lg-center">
                                {/* <button className="button white download-btn"> Download </button> */}
                                <CSVDownloader
                                    type={Type.Link}
                                    filename={'vendors_sample.csv'}
                                    bom={true}
                                    data={'Name,Email,Mobile,Location,Supply'}
                                    separator=","
                                    className={"bulkDownloadBtn"}
                                >
                                    {t('download')}
                                </CSVDownloader>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>

    </>)
}

export default VendorUpload;