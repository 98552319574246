import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const CmsSidebar = ({ active, setActive, onSave, onDiscard, isLoading,LogoRef,BannerRef,PanchtatvaRef,
  AboutRef,HowItWorksRef,FeatureRef,termRef, PartnerRef,FooterRef,handleScroll }) => {
  const { t } = useTranslation();
  return (
    <div className="sidebar-box no-padding">
      <div className="sidebar-menu">
        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 1 ? " selected" : ""}`} onClick={(e) => setActive(e, 1)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('Logo')}</p>
          </div>
        </Link>

        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 2 ? " selected" : ""}`} onClick={(e) => setActive(e, 2)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('Banner')}</p>
          </div>
        </Link>

        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 3 ? " selected" : ""}`} onClick={(e) => setActive(e, 3)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('homepage.panchtatva.title')}</p>
          </div>
        </Link>

        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 4 ? " selected" : ""}`} onClick={(e) => setActive(e, 4)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('homepage.aboutUs.title')}</p>
          </div>
        </Link>


        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 5 ? " selected" : ""}`} onClick={(e) => setActive(e, 5)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('homepage.howItWorks.title')}</p>
          </div>
        </Link>

        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 6 ? " selected" : ""}`} onClick={(e) => setActive(e, 6)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('homepage.features.title')}</p>
          </div>
        </Link>

        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 7 ? " selected" : ""}`} onClick={(e) => setActive(e, 7)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('Blog')}</p>
          </div>
        </Link>

        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 8 ? " selected" : ""}`} onClick={(e) => setActive(e, 8)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('homepage.testimonials.title')}</p>
          </div>
        </Link>
        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 9 ? " selected" : ""}`} onClick={(e) => setActive(e, 9)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('termsConditions')}</p>
          </div>
        </Link>
        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 10 ? " selected" : ""}`} onClick={(e) => setActive(e, 10)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('privacyPolicy')}</p>
          </div>
        </Link>
        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 11 ? " selected" : ""}`} onClick={(e) => setActive(e, 11)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('paymentPolicy')}</p>
          </div>
        </Link>
        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 12 ? " selected" : ""}`} onClick={(e) => setActive(e, 12)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('faq')}</p>
          </div>
        </Link>

        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 13 ? " selected" : ""}`} onClick={(e) => setActive(e, 13)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('Partners')}</p>
          </div>
        </Link>

        <Link to="/profile-settings" className={`sidebar-menu-item ${active === 14 ? " selected" : ""}`} onClick={(e) => setActive(e, 14)}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <p className="sidebar-menu-header-title">{t('footer.Footer')}</p>
          </div>
        </Link>
      </div>

      <div className="sidebar-box-footer">
        <button className="button primary" onClick={onSave}>
          {isLoading ? <><span >{t('Saving_Changes')}</span><span className="spinner-border"/></> : 'Save Changes!'}
        </button>
        <button className="button white small-space" onClick={onDiscard}>{t('Discard_All')}</button>
      </div>
    </div>
  );
}

export default CmsSidebar;