import React from "react";
import Sidebar from "./sidebar/sidebar";
import Navbar from "./navbar/navbar";
import { useContext } from "react";
import { CommonContext } from "../../context/common.context";
const UserLayout = ({children}) => {
    const {showSmallNav} = useContext(CommonContext)
    return (
        <>
            <Sidebar/>
            <Navbar/>
            <div 
            className={`content-grid contentCanvas ${showSmallNav ? "contentCanvasCollapse" : ""}`}>
                {children}
            </div>
        </>
    )
}

export default UserLayout;