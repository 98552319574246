// nav Images
export const SearchIcon = '/Assets/SearchIcon-tab.svg';
export const SearchAddEventIcon = '/Assets/SearchIcon-tab-white.svg';

// Banner Images
export const Logo = '/Assets/weblogo.png';
export const LogoWhite = '/Assets/weblogo_neg.png';
export const LandingBanner = '/Assets/Img-4.png';
export const RegisterBanner = '/Assets/Img-2.png';

//Homepage - PanchTatva Images
export const PanchTatvaImg = '/Assets/panchtatva-icon.png';
export const EarthImg = '/Assets/Earth-icon.png';
export const WaterImg = '/Assets/Water-iicon.png';
export const AirImg = '/Assets/Air-icon.png';
export const FireImg = '/Assets/Fire-icon.png';
export const SoulImg = '/Assets/Soul-icon.png';

//Homepage - How it works sectin 
export const HowItWorksImg1 = '/Assets/web-details.png';
export const HowItWorksImg2 = '/Assets/magic.png';
export const HowItWorksImg3 = '/Assets/get-resolutes.png';
export const HowItWorksDivImg = '/Assets/hover-right-cir.png';

//Homepage - About Us 
export const AboutUsImg = '/Assets/About-us.png';

//Features
export const FeaturesProfileImg = '/Assets/account.png';
export const FeaturesCommImg = '/Assets/crowd-of-users.png';
export const FeaturesEngageImg = '/Assets/engagement.png';

//Blogs
export const BlogsImg = '/Assets/Blog-img.png';

//Testimonial
export const bgTestimonials = '/Assets/Bg-testimonial.png';
export const TestimonialImg1 = '/Assets/feed1.png';
export const TestimonialImg2 = '/Assets/feed2.png';
export const TestimonialGoogle = '/Assets/C-logo4.png';
export const TestimonialPaypal = '/Assets/C-logo3.png';
export const TestimonialInstaCart = '/Assets/C-logo2.png';
export const TestimonialBooking = '/Assets/C-logo1.png';

//Footer
export const FooterFb = '/Assets/fb.png';
export const FooterIn = '/Assets/in.png';
export const FooterTw = '/Assets/tw.png';
export const FooterGoogle = '/Assets/Google-white.png';
export const FooterEmail = '/Assets/email.png';
export const FooterPhone = '/Assets/phone.png';
export const FooterLocation = '/Assets/location.png';

//Test Form Icon
export const IconInFormImg = '/img/badge/blank-s.png';

//User Dashboard
export const AvatarImg = '/Assets/Img.png';
export const CalendarMonthIcon = '/Assets/userCalendar.png';
export const PersonIcon = '/Assets/upload-user.png';

//Social Media
export const FacebookImg = '/Assets/Facebook.png';
export const TwitterImg = '/Assets/Twitter.png';
export const LinkedInImg = '/Assets/Linkedin.png';
export const GoogleImg = '/Assets/Google.png';

//Super Admin
export const overAllImpactImg = '/Assets/Facbook.png';
export const smiley_icon = '/Assets/Facbook.png';

export const GenMaleIcon = '/Assets/male-inactive.png';
export const FemaleIcon = '/Assets/Female-inactive.png';
export const ProSearchIcon = '/Assets/pro-search-green.png';

export const buildingMark = '/Assets/condo.png';
export const runMark = '/Assets/multiple-community.png';

export const Loader = '/Assets/Loader.gif';


export const PaymentSearchIcon = '/Assets/Search-Icon-payment.png';
export const TotalEarnigsIcon = '/Assets/U$D-green-payment.png';
export const TotalEarnigsIcon2 = '/Assets/U$D-Dark-green-payment.png';
export const PinTargetIcon = '/Assets/Pin-Icon-target.png';


export const likeIcon = '/Assets/React Icon-light-green.png';
export const cmtIcon = '/Assets/React Icon-light-green.png';
export const shrIcon = '/Assets/React Icon-light-green.png';
