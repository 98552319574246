import React, {useState} from "react";
import { postLikesListService } from "../services/post.services";
import InfiniteScroll from "react-infinite-scroller";
import BarsLoader from "../commonComponents/barsLoader";
import Popup from "../commonComponents/popup";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import AvatarImgBox from "../commonComponents/AvatarImgBox/avatarImgBox";
import { AvatarImg } from "../commonComponents/Images";
import { getImageUrl } from "../utils/helpers";
import { useEffect } from "react";
import { postReactionList } from "../config/constants";
import TabNavigation from "./tabNavigation";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { numberKFormatter } from "../utils/helpers";
import { selectCurrentOrg, selectCurrentUser, selectCurrentUserRole } from "../Reducer/user/user.selector";

const PostLikeListPopup = ({open, postId, handleClose, commentId, replyId}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    
    useEffect(()=>{
        window.addEventListener("resize", resize.bind(this))
        resize()
    }, [])

    const resize = () => {
        setWindowSize(window.innerWidth)
    }

    const role = useSelector(selectCurrentUserRole)
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);

    let current_user = {};
     if(role === 'organization'){   current_user = CURRENTORG     }
     else{ current_user = CURRENTUSER }

    const limit =  20;
    const [list, setList] = useState([]);
    const [likeList, setLikeList] = useState([])
    const [sadList, setunLikeList] = useState([])
    const [loveList, setLoveList] = useState([])
    const [wowList, setWowList] = useState([])
    const [clapList, setClapList] = useState([])
    const [isLoading, setLoading] = useState(false);
    const [hasMore, SetHasMore] = useState(false);
    const [offset , setOffset] = useState(0);
    const [tab, setTab] = useState(1);

    let tabs = [`All (${numberKFormatter(list.length)})`];
    let moreTabs = [];
    postReactionList.map((item, index)=>{
        

        let pushItem = '';
        if(item.value === 'like'){ pushItem = (<><img className="reaction-option-image" src={`/img/reaction/${item.value}.png`} alt=""/> ({numberKFormatter(likeList.length)})</>) }
        if(item.value === 'sad'){ pushItem = (<><img className="reaction-option-image" src={`/img/reaction/${item.value}.png`} alt=""/> ({numberKFormatter(sadList.length)})</>) }
        if(item.value === 'love'){ pushItem = (<><img className="reaction-option-image" src={`/img/reaction/${item.value}.png`} alt=""/> ({numberKFormatter(loveList.length)})</>) }
        if(item.value === 'wow'){ pushItem = (<><img className="reaction-option-image" src={`/img/reaction/${item.value}.png`} alt=""/> ({numberKFormatter(wowList.length)})</>) }
        if(item.value === 'clap'){ pushItem = (<><img className="reaction-option-image" src={`/img/reaction/${item.value}.png`} alt=""/> ({numberKFormatter(clapList.length)})</>) }
        

        if(windowSize < 1200 && index === 4){ moreTabs.push(pushItem); }
        else if(windowSize < 1170 && index === 3){ moreTabs.push(pushItem); }
        else if(windowSize < 1000 && index === 2){ moreTabs.push(pushItem); }
        else if(windowSize < 800 && index === 1){ moreTabs.push(pushItem); }
        else if(windowSize < 680 && index === 0){ moreTabs.push(pushItem); }
        else{ tabs.push(pushItem) }
    })

    useEffect(()=>{
        if(open){
            setList([]);
            setOffset(0);
            setTab(1);
            SetHasMore(true);
            setLoading(false)
        }
    },[open])

    const getLikeList = async () => {
        setLoading(true);
        let page = offset/limit;
        let newOffset = offset+limit;

        let id = (commentId !== '')?commentId:(replyId !== '')? replyId : postId;
        let type = (commentId !== '')?'comment':(replyId !== '')? 'reply' : '';

        postLikesListService(page, limit, id, type)
        .then((response) => {
            if (response?.data?.status === 200) {
                
                if(response?.data?.data?.length === 0 || response?.data?.data?.length < limit){
                    SetHasMore(false);
                }
                const _data = response?.data?.data;
                setList([...list, ..._data]);
            }                
            setLoading(false)
            setOffset(newOffset);
        })
        .catch((error) => {
            console.log(error, "error");
        })
    }

    useEffect(()=>{
        getList()
    }, [list])

    const getList = () => {
        setLikeList(list.filter((item)=>item?.reaction === 'like'))
        setunLikeList(list.filter((item)=>item?.reaction === 'sad'))
        setLoveList(list.filter((item)=>item?.reaction === 'love'))
        setWowList(list.filter((item)=>item?.reaction === 'wow'))        
        setClapList(list.filter((item)=>item?.reaction === 'clap'))
    }

    const navigateProfile = (e, data) => {
        e.preventDefault();
        let encrptedId = window.btoa(data?.createdInfo?._id);
        if(current_user?._id === data?.createdInfo?._id){
            navigate('/profile')
        }
        else if(data?.createdInfo?.name){
            navigate(`/organization/${window.btoa(data?.createdInfo?._id)}`, { state: {orgId:data?.createdInfo?._id}})
        }
        else{
            navigate(`/profile/${encrptedId}`, {state:{userId : data?.createdInfo?._id}})
        }
    }

    const renderDiv = (e,i) => {
        return (<div className="widget-box area-widget blocked_user_area" key={i} style={{padding:'0px'}}>
            <div className="">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="hexagon-image-30-32 likesPopupProfileImg" style={{cursor:'pointer'}} onClick={(event)=>navigateProfile(event, e)}>
                            <AvatarImgBox 
                            img={(e?.createdInfo?.profilePicture !== '') ? getImageUrl(e?.createdInfo?._id, 'userProfile',e?.createdInfo?.profilePicture) : AvatarImg}
                            />
                            <div className="reaction userReaction">
                                <img className="reaction-option-image" src={`/img/reaction/${e.reaction}.png`} alt=""/>
                            </div>
                        </div>
                        <div className="">
                            <h5 className="bold userReactionName">{e?.createdInfo?.name ? e?.createdInfo?.name : `${e?.createdInfo?.firstName} ${e?.createdInfo?.lastName}`}</h5>
                            
                        </div>
                        {/* {role !== 'organization' &&
                            <div className="">
                                <div className="action-request-list justify-content-end">
                                    <button className="button unblock_btn">Friend</button>
                                </div>
                            </div>
                        } */}
                    </div>
                </div>
            </div>
        </div>)
    }
    

    return (<>
        <Popup open={open} handleClose={handleClose} className="targetPopup" modalBodyClasses={"likesPopup"}>

            <TabNavigation 
                tabs={tabs} 
                setTab={setTab} 
                activeTab={tab} 
                showInput={false} 
                sectionMenuClasses={"likesDetailsContainer"}
                moreTabs={moreTabs}
                id={(commentId !== '')?commentId:(replyId !== '')? replyId : postId}
            />
            
                <InfiniteScroll
                    pageStart={0}
                    loadMore={getLikeList}
                    hasMore={hasMore}
                    loader={<BarsLoader key={0} />}
                    useWindow={false}
                    className="scrollBar"
                >
                    {tab === 1 &&
                    <>
                        {list.length > 0 && <>
                            {list.map((e, i) => renderDiv(e,i) )}
                        </>}

                    </>}

                    {tab === 2 && 
                    <>
                        {likeList.length > 0 && <>
                            {likeList.map((e, i) => renderDiv(e,i) )}
                        </>}

                    </>}

                    {tab === 3 && 
                    <>
                        {sadList.length > 0 && <>
                            {sadList.map((e, i)  => renderDiv(e,i) )}
                        </>}

                    </>}

                    {tab === 4 && 
                    <>
                        {loveList.length > 0 && <>
                            {loveList.map((e, i)  => renderDiv(e,i) )}
                        </>}

                    </>}

                    {tab === 5 && 
                    <>
                        {wowList.length > 0 && <>
                            {wowList.map((e, i)  => renderDiv(e,i) )}
                        </>}

                    </>}

                    {tab === 6 && 
                    <>
                        {clapList.length > 0 && <>
                            {clapList.map((e, i)  => renderDiv(e,i) )}
                        </>}

                    </>}

                </InfiniteScroll>
            
                {!isLoading && list.length === 0 &&
                <>
                    <Row className="notification">
                        <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center">
                            <span className="bold">
                                {t('no_likes')} 
                            </span>
                        </Col>
                    </Row>

                </>}
        </Popup>
    </>)
}

export default PostLikeListPopup