import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SaveEditSettingsBtn from "../../../../commonDashboardComp/saveEditSettingsBtn";
import { allowOnlyLettersNumbers, showSingleToastSuccess, showToastError, showToastSuccess } from "../../../../utils/helpers";
import { validEmail, websiteValidator } from "../../../../utils/formatValidator";
import { allowOnlyLetters } from "../../../../utils/helpers";
import { Link } from "react-router-dom";
import {
    Box, TextField,
    FormHelperText,
    Button,
    OutlinedInput,
    InputLabel,
    FormControl
} from '@mui/material'
import FormTextArea from "../../../../commonComponents/formTextArea";
import {
    OrgCheckEmailMobileAvailabilityService,
    OrgVerifyEmailMobileOtpService
} from "../../../../services/orgAuth.services";
import Popup from "../../../../commonComponents/popup";
const OrgProfileSetting = ({ orgId, saveUserDetails, formValues, setFormValues, canEdit, onEdit, error, setError }) => {
    const { t } = useTranslation();
    const [emailError, setEmailError] = useState(false);
    const [open, setOpen] = useState(false);
    const [counter, setCounter] = useState(0);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const [incorrectOTP, setIncorrectOTP] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            } else {
                clearInterval(myInterval)
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    }, [counter]);

    const onSave = (e) => {
        e.preventDefault();
        setError(false);

        if ((getWordCount(formValues?.aboutMe) >= 200)
        ) { setError(true); return; }

        if (!websiteValidator(formValues.webSite)) {
            return false;
        }

        let params = {
            taxId: formValues.taxId,
            webSite: formValues.webSite,
            aboutMe: (formValues.aboutMe == '')?'Hi! We Are Sprede User!':formValues.aboutMe,
            regNo: formValues.regNo
        }
        saveUserDetails(params, 'aboutBusinessInfo');
    }

    const getWordCount = (t) => {
        let text = t.split(" ");
        return text.length
    }

    const checkEmailAvailability = () => {
        setEmailError(false);
        if (!validEmail(formValues.email)) {
            setEmailError(true); return;
        }

        try {
            let params = { organizationId: orgId, 'email': formValues.email }
            OrgCheckEmailMobileAvailabilityService(params)
                .then((data) => {
                    if (data?.status !== 200) {
                        setEmailError(true); return;
                    }
                    // showToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`))
                    showSingleToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`))
                    setCounter(60);
                    handleOpen();
                })
                .catch((error) => {
                    setEmailError(true);
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const verifyEmail = (e) => {
        e.preventDefault();
        if (formValues.email === '' || !validEmail(formValues.email)) { return; }
        setOtpError(false);
        if (otp === '' || otp.length < 6) {
            setOtpError(true); return;
        }

        try {
            setLoading(true)
            let params = { organizationId: orgId, email: formValues.email, otp: otp }
            OrgVerifyEmailMobileOtpService(params)
                .then(async (data) => {
                    if (data.status === 200) {
                        setFormValues({ ...formValues, user_email: formValues.email, isEmailVerify: true })
                        handleClose();
                    } else {
                        setOtpError(true); setIncorrectOTP(true);
                        showToastError(t(`apiMessages.${data.data.data}`))
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    setError(true);
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        catch (error) {
            console.log(error);
            setLoading(false)
        }
        setLoading(false)
    }

    function getCount(str) {
        return str.split(' ').filter(function (num) {
            return num !== ''
        }).length;
    }
    return (<Row className="grid orgProfileSettingComp">
        <div className="grid-column">
            <div className="widget-box equalPadding">
                <p className="widget-box-title">{t('About_Your_Profile')}
                    <SaveEditSettingsBtn canEdit={canEdit} onEdit={onEdit} onSave={onSave} />
                </p>
                <div className="widget-box-content">
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '98%' },
                        }}
                        noValidate
                        autoComplete="off"
                        className="userdetail-form"
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <Row>
                            <Col md={6} className="orgProfileSettingCompField">
                                <TextField type='text' label={t('form.organization_name')}
                                    disabled={true}
                                    value={formValues.name}
                                    onChange={(e) => setFormValues({ ...formValues, name: allowOnlyLettersNumbers(e.target.value) })}
                                    error={(error && formValues.name === '') ? true : false}
                                    helperText={(error && formValues.name === '') ? t('validators_info.Organization_Name_is_Required') : ''}
                                />
                            </Col>
                            <Col md={6} className="orgProfileSettingCompField">
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">{t('form.label_email')}</InputLabel>
                                    <OutlinedInput
                                        disabled={!canEdit}
                                        error={(formValues.email === '') ? true : (!validEmail(formValues.email)) ? true : false}
                                        label={t('form.label_email')}
                                        value={formValues.email}
                                        onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                        endAdornment={((formValues.email.toLowerCase() === formValues.user_email.toLowerCase() && formValues.isEmailVerify)) ?
                                            <Button disabled={!canEdit} className="verify_btn verified_btn" >{t('verified')}</Button> :
                                            ((validEmail(formValues.email))) ?
                                                <Button className="verify_btn" disabled={!canEdit} onClick={checkEmailAvailability}>{t('verify_now')}</Button> : ''}
                                    />
                                    <FormHelperText>
                                        {
                                            (formValues.email === '') ? t('errors.email_required') :
                                                (!validEmail(formValues.email)) ? t('errors.email_format') :
                                                    (emailError) ? t('errors.Email_is_already_taken') : ''
                                        }</FormHelperText>
                                </FormControl>
                            </Col>

                            <Col md={6} className="orgProfileSettingCompField orgDetailTextarea">
                                <FormTextArea rows={5} label={t('form.about_organization')}
                                    placeholder={t('describe_your_organization_in_words')}
                                    value={formValues?.aboutMe}
                                    disabled={!canEdit}
                                    onChange={(e) => (getWordCount(e.target.value) <= 200) ? setFormValues({ ...formValues, aboutMe: allowOnlyLetters(e.target.value) }) : ''}
                                />
                                {getWordCount(formValues?.aboutMe) >= 200 ?
                                    <div className="error-text">
                                        {t('errors.you_can_not_enter_more_than_200_words')}
                                    </div>
                                    : ""
                                }
                            </Col>
                            <Col>
                                <Row>
                                    <Col lg={12} className="orgProfileSettingCompField">
                                        <TextField
                                            value={formValues?.webSite}
                                            disabled={!canEdit}
                                            onChange={(e) => setFormValues({ ...formValues, webSite: e.target.value })}
                                            label={t('form.organization_website_url')} />

                                        {!websiteValidator(formValues?.webSite) &&
                                            <div className="error-text">{t('validators_info.Enter_correct_website_url')}</div>
                                        }
                                    </Col>
                                    <Col lg={12} className="orgProfileSettingCompField">
                                        <TextField
                                            value={formValues?.taxId}
                                            disabled={!canEdit}
                                            onChange={(e) => e.target.value.length < 17 ? setFormValues({ ...formValues, taxId: allowOnlyLettersNumbers(e.target.value) }) : ''}
                                            label={t('form.gat_vat_tax_id')}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={6} className="orgProfileSettingCompField">
                                <TextField
                                    value={formValues?.regNo}
                                    disabled={!canEdit}
                                    onChange={(e) => e.target.value.length < 20 ? setFormValues({ ...formValues, regNo: allowOnlyLettersNumbers(e.target.value) }) : ''}
                                    label={t('form.registration_no')} />
                            </Col>

                        </Row>
                    </Box>
                </div>
            </div>
        </div>

        <Popup heading={t('form.submit_otp')} className={'submitOtpPopUp'} handleClose={handleClose} open={open}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className="userdetail-form"
                onSubmit={(e) => verifyEmail(e)}
            >
                <p>{t('errors.Otp_sent_on_your_email')}</p>
                <div className="otp_counter">00:{(counter < 10) ? `0${counter}` : counter}</div>
                <TextField
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    error={(otpError && otp === '') ? true : (otpError && otp.length < 6) ? true : (otpError && incorrectOTP) ? true : false}
                    helperText={(otpError && otp === '') ? t('errors.Enter_OTP') : (otpError && otp.length < 6) ? t('OTP_is_of_6digits') : (otpError && incorrectOTP) ? t('apiMessages.OTP_MISS_MATCH') : ""}
                    inputProps={{ maxLength: 6 }}
                    label="OTP" />

                {counter === 0 &&
                    <Link to="#"
                        onClick={(e) => { e.preventDefault(); checkEmailAvailability() }}>
                        {t('Resend_Otp')}
                    </Link>
                }

                <button disabled={loading} className="button grey">
                    {loading ? t('errors.Please_Wait') : t('form.submit')}
                </button>
            </Box>
        </Popup>

    </Row>)
}

export default OrgProfileSetting;