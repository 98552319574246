import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BarsLoader from "../../../commonComponents/barsLoader";
import { AvatarImg, TestimonialGoogle } from "../../../commonComponents/Images";
import Loader from "../../../commonComponents/Loader";
import ReactPagination from "../../../commonComponents/reactPagination";
import { followTheOrganization, getOrganizationSugg } from "../../../services/onBoarding.services";
import { getImageUrl, showSingleToastSuccess } from "../../../utils/helpers";
const FindOrg = ({ searchBy, countsOfFriends, parentOrgMethod }) => {
    const [suggetionOrgData, setSuggetionOrgData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const limit = 8;
    const { t } = useTranslation();
    const [totalPage, setTotalPage] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const navigate = useNavigate();
   
    useEffect(() => {
        getOrgSuggetionList(0);
    }, [searchBy])

    const getOrgSuggetionList = async (currentPage) => {
        setLoading(true);
        setPageNo(currentPage);
        await getOrganizationSugg(currentPage, limit, searchBy)
            .then((response) => {
                if (response?.data?.status === 200) {
                    const _data = response?.data?.data?.data;
                    setSuggetionOrgData(_data);
                    let pageCount = (response?.data?.data?.totalCount) / limit;
                    setTotalPage(Math.ceil(pageCount));
                    setLoading(false);
                    countsOfFriends(response?.data?.data?.totalCount);
                }
            })
            .catch((error) => {
                console.log(error, "error");
            })
    }
    
    const addOrganization = async (e, org) => {
        e.preventDefault();
        if (org?.status) {
            let param = {
                list: [org?._id],
                action: "followOrganization"
            }
            followTheOrganization(param)
                .then((resp) => {
                    if (resp?.status === 200) {
                        showSingleToastSuccess(t(`apiMessages.ORG_FOLLOW_REQUEST`));
                        const nextList = [...suggetionOrgData];
                        let index = nextList?.findIndex(a => a._id === org?._id);
                        if (index >= 0) {
                            setSuggetionOrgData(nextList);
                        }
                        let cntPage = pageNo;
                        if (suggetionOrgData?.length == 1) {
                            setPageNo(pageNo - 1);
                            cntPage = cntPage - 1;
                        }
                        getOrgSuggetionList(cntPage);
                        parentOrgMethod();
                    }
                })

        }
    }

    const handlePageClick = (data) => {
        let page = data?.selected;
        getOrgSuggetionList(page);

    }
    const showOrg = (org) => { 
        let encrptedId = window.btoa(org?._id);
        navigate(`/organization/${encrptedId}`, { state: {orgId:org?._id}})
    }
    return (
        <Row className="grid orgMobile">
            {suggetionOrgData?.length > 0 ? <>
                {suggetionOrgData.map((item, index) => (
                    <Col md={3} sm={6} className=" grid-column" key={index}>

                        {/* <div className="widget-box org-list-box friends-list-box" key={index}>
                           <div onClick={()=>showOrg(item)}>
                            <div className="org-image-panel ">
                                <img src={item?.profilePicture ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : '/Assets/org1@2x.png'} alt="" className="img-circle" />
                            </div>
                            <p>{item?.name}</p>
                            <Row>
                                <Col md={6}>
                                    {item?.employees}<br />
                                    Employees<br />

                                </Col>
                                <Col md={6}>
                                    {item?.followers}<br />
                                    Followers<br />
                                </Col>
                            </Row>
                            </div>
                            <button className="button primary-hover full" onClick={(e) => addOrganization(e, item)}> <img style={{ width: 15, height: 15, marginRight: 5 }} src="/Assets/Add-Friend-Icon-inactive.png" />Follow </button>
                        </div> */}

                        <div className="organizationBlock" key={index}>
                            <div >
                                <div className="org-image-block" onClick={(e)=>showOrg(item)}>
                                    <img src={item?.profilePicture ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : '/Assets/org1@2x.png'} alt="" className="img-circle" />
                                </div>
                                <p>{item?.name}</p>
                                <ul className="employeesFollowers">
                                    <li>
                                        <span>{item?.employees}</span>
                                        {t('Employees')}
                                    </li>
                                    <li>
                                        <span>{item?.followers}</span>
                                        {t('followers')}
                                    </li>
                                </ul>
                                </div>
                                <button className="button white white-tertiary organizationBtn followBtn" onClick={(e) => addOrganization(e, item)}> <img style={{ width: 15, height: 15, marginRight: 5 }} src="/Assets/Add-Friend-Icon-inactive.png" />{t('follow')} </button>
                           
                        </div>
                    </Col>
                ))}
            </> : <> <h4>{t('orgMsg.NO_ORG_ADDED')}</h4></>}
            {isLoading &&  <BarsLoader />}
            <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />

        </Row >
    )
}

export default FindOrg;