import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Row, Col } from "react-bootstrap";
import { AvatarImg, FireImg } from "../commonComponents/Images";
import { buildingMark } from "../commonComponents/Images";
import { useTranslation } from "react-i18next";
import { getImageUrl, showSingleToastSuccess } from "../utils/helpers";
import { changeDateFormatmmddyyyy, timeFormat } from "../utils/helpers";
import { Navigate } from "react-router-dom";
import { deleteBlogsService, deleteTestimonialsService, 
        getBlogService, getTestimonialService, 
        editBlogsService, editTestimonialsService } from "../services/cms.services";
import Popup from "../commonComponents/popup";
import swal from "sweetalert";
import { Autocomplete, Box, TextField } from "@mui/material";
import FormTextArea from "../commonComponents/formTextArea";
import { uploadFileService } from "../services/fileUpload.services";
const CmsBlogTestimonialBox = ({ item}) => {
    const { t } = useTranslation();
    const [blogTestimonial, setBlogTestimonial] = useState(item) 
    const [show, setShow] = useState(true);
    const [error, setError] = useState(false)
    const stars = ['1','2','3','4','5']
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const [blogTestimonialContent , setBlogTestimonialContent] = useState({...blogTestimonial})

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const setPhoto = (param) => {
        if(param !== undefined){ setSelectedFile(param); setBlogTestimonialContent({...blogTestimonialContent, image:''})}
        else{ setSelectedFile(null); }    
    }

    const removeImage = () => {
        setSelectedFile(null)
        setBlogTestimonialContent({...blogTestimonialContent, image:''})
    }

    const onDelete = (e) => {
        e.preventDefault()
        swal({
            text: (blogTestimonial?.type === 'blog')?t('alerts.delete_blog'):t('alerts.delete_testimonial'),
            title: '',
            icon: "warning",
            buttons: {
              Yes: {
                text:t('yes'),
                value: true,
              },
              No: {
                text: t('no'),
                value: false,
              },
            },
          })
          .then(async (val) => {
                if(!val) return;
                if(blogTestimonial?.type === 'blog'){
                    deleteBlogsService(blogTestimonial?._id)
                    .then((data)=>{
                        if(data?.data?.status === 200){
                            showSingleToastSuccess('apiMessages.BLOG_DELETED_SUCCESS')
                            setShow(false);
                        }
                    })
                    .catch((error)=>{
                        console.log(error);
                    })
                }
                else{
                    deleteTestimonialsService(blogTestimonial?._id)
                    .then((data)=>{
                        if(data?.data?.status === 200){
                            showSingleToastSuccess(t(`apiMessages.TESTIMONIAL_DELETED_SUCCESS`))
                            setShow(false);
                        }
                    })
                    .catch((error)=>{
                        console.log(error);
                    })
                }
          })
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        setError(false);

        if(selectedFile === null && blogTestimonialContent?.image === ''){
            setError(true); return;
        }

        if(blogTestimonial?.type === 'blog'){
            if(blogTestimonialContent?.title === '' || blogTestimonialContent?.description === ''){
                setError(true); return;
            }

            let params = {
                id : blogTestimonialContent?._id,
                image : (selectedFile !== null)?selectedFile?.lastModified:blogTestimonialContent?.image,
                title : blogTestimonialContent?.title,
                description : blogTestimonialContent?.description
            }

            setLoading(true);
            if(selectedFile !== null)
            {   
                //upload File
                await uploadFileService('cms', 'cms', selectedFile?.lastModified, selectedFile, blogTestimonialContent?._id)
            }
            

            try{
                editBlogsService(params)
                .then(async (data)=>{
                    if(data?.data?.status === 200){
                        await getData();
                        showSingleToastSuccess(t('apiMessages.BLOG_UPDATED_SUCCESS'))
                        setSelectedFile(null);
                        handleClose();
                        setLoading(false)
                    }
                })
                .catch((error)=>{
                    console.log(error)
                })
            }
            catch(error){
                console.log(error)
            }            
        }
        else{
            if(blogTestimonialContent?.name === '' || 
            blogTestimonialContent?.company === '' ||
            blogTestimonialContent?.rating === '' || 
            blogTestimonialContent?.content === ''){
                setError(true); return;
            }

            let params = {
                id : blogTestimonialContent?._id,
                image : (selectedFile !== null)?selectedFile?.lastModified:blogTestimonialContent?.image,
                name : blogTestimonialContent?.name,
                rating : blogTestimonialContent?.rating,
                content : blogTestimonialContent?.content,
                company : blogTestimonialContent?.company
            }

            setLoading(true);
            if(selectedFile !== null)
            {   
                //upload File
                await uploadFileService('cms', 'cms', selectedFile?.lastModified, selectedFile, blogTestimonialContent?._id)
            }
            

            try{
                editTestimonialsService(params)
                .then(async (data)=>{
                    if(data?.data?.status === 200){
                        await getData();
                        showSingleToastSuccess(t(`apiMessages.TESTIMONIAL_UPDATED_SUCCESS`))
                        setSelectedFile(null);
                        handleClose();
                        setLoading(false)
                    }
                })
                .catch((error)=>{
                    console.log(error)
                })
            }
            catch(error){
                console.log(error)
            }     
        }
    }

    const getData = async() => {
        if(blogTestimonial?.type === 'blog'){
            getBlogService(blogTestimonial?._id)
            .then((data)=>{
                if(data?.data?.status === 200){
                    setBlogTestimonial({...blogTestimonial, ...data?.data?.data, image: data?.data?.data?.blogImg})
                    setBlogTestimonialContent({...blogTestimonial, ...data?.data?.data, image: data?.data?.data?.blogImg})
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        else{
            getTestimonialService(blogTestimonial?._id)
            .then((data)=>{
                if(data?.data?.status === 200){
                    setBlogTestimonial({...blogTestimonial,...data?.data?.data})
                    setBlogTestimonialContent({...blogTestimonial,...data?.data?.data})
                }
            })
            .catch((error)=>{
                console.log(error)
            })
        }
    }
    return (
        <>        
            {show && <div className="testimonialBoxComp all-event-box px-0" style={{background: '#fff'}}>
                <Row className="align-items-lg-center mx-0 h-100">
                        <div className="col-lg-2 col-12 text-center eventImgBox">  
                            <img src={(blogTestimonial?.image !== '') ? getImageUrl(blogTestimonial?._id, 'cms', blogTestimonial?.image) : AvatarImg} alt="" className="img-fluid rounded" />
                        </div>
                        {/* <div className="col-lg-2 col-12 py-2 d-flex align-items-center">
                            
                        </div> */}

                        <div className="col-lg-7 pb-2 col-12">
                        <div className="running-online hideBullet">
                                {blogTestimonial?.type ==='blog' ? 
                                    <h5 className="bold mt-md-3">{blogTestimonial.title}</h5> :
                                    <>
                                        <h5 className="bold mt-md-3">{blogTestimonial.name}</h5>
                                        <p className="user-status-text">
                                        {blogTestimonial?.company} <br/><span className=""><b>{t('Rating')}</b> : {blogTestimonial?.rating}</span> 
                                        </p>
                                    </>
                                }                                
                            </div>
                            <p className="user-status-text small-space locat_icons">
                                {blogTestimonial?.type ==='blog' ? 
                                     <p className="action-request-text">{blogTestimonial.description.substring(0, 100) + ' ...'}</p> :
                                     <p className="action-request-text">{blogTestimonial.content.substring(0, 100) + ' ...'}</p>
                                }                               
                            </p>
                        </div>

                        <div className="col-lg-3 col-12 col-offset-sm-6 align-self-md-end align-self-lg-auto">
                            <div className="action-request-list justify-content-end">
                                <button 
                                onClick={(e)=>handleOpen()} 
                                className="button primary view-event-btn">{t('button.edit')}</button>

                                <button 
                                onClick={(e)=>onDelete(e, blogTestimonial?._id)} 
                                className="button primary view-event-btn">{t('button.delete')}</button>
                            </div>
                        </div>
                </Row>    
            </div>}

            <Popup heading={(blogTestimonial?.type === 'blog')?t('Edit Blog') : t('Edit_Testimonial')} open={open} handleClose={handleClose} className={(blogTestimonial?.type === 'blog')?'blogTargetPopup' : 'testimonialTargetPopup'}>
                <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className="userdetail-form mobile_profile_margin row justify-content-center"
                onSubmit={handleSubmit}
                >
                    <Row className="mt-lg-4">
                        <Col lg={4} md={12}>
                            
                                <div className="position-relative mt-3">
                                    <label htmlFor={`upload-photo-blog-${blogTestimonialContent?._id}`}>
                                        <input type="file" id={`upload-photo-blog-${blogTestimonialContent?._id}`}
                                        style={{display:"none"}}
                                        accept="image/png, image/gif, image/jpeg"
                                            onChange={(event) => {
                                                setPhoto(event.target.files[0]);
                                            }}
                                            />
                                        <div className="content">
                                        <div className="upload-box">
                                            {(selectedFile !==null || blogTestimonialContent?.image !== '') ?
                                                <img src={selectedFile!==null ?
                                                        URL.createObjectURL(selectedFile) : 
                                                        getImageUrl(blogTestimonialContent?._id, 'cms', blogTestimonialContent?.image)}
                                                        className="user_attach_upload"
                                                     /> : 
                                                <img src="/Assets/Member_Icon.png" alt="" className="upload-box-icon icon-members"/>    }
                                                <p className="upload-box-title">{t('Image')}</p>
                                                <p className="upload-box-text">{t('onboarding.size_minimum')}</p>
                                            </div>
                                           
                                        </div>
                                    </label>
                                    {(selectedFile !==null || blogTestimonialContent?.image !== '') && 
                                    <img src='/Assets/Close.png' className="closeIconUpload" onClick={()=>removeImage()} /> }

                                    {error && selectedFile === null && blogTestimonialContent?.image === '' &&
                                        <div className="error-text">Select Image</div>
                                    }
                                </div>
                                

                            <Row className="grid">
                                <div className="grid-column myProfileSettings">
                                    <div className="widget-box area-widget">
                                        <div className="widget-box-content">
                                            {blogTestimonialContent?.type === 'blog' ?
                                            <Col md={12}>
                                                <TextField label={t('superAdmin.Title')}
                                                value={blogTestimonialContent?.title}
                                                onChange={(e)=>setBlogTestimonialContent({...blogTestimonialContent, title:e.target.value})}
                                                />
                                                {error && blogTestimonialContent?.title === '' &&
                                                    <div className="error-text>">Enter Title</div>
                                                }
                                            </Col>
                                            :
                                            <Col md={12}>
                                                <Autocomplete
                                                    disablePortal
                                                    value={blogTestimonialContent?.rating}
                                                    options={stars}
                                                    getOptionLabel={(option) => option || ""}
                                                
                                                    onChange={(e, val)=>{ setBlogTestimonialContent({...blogTestimonialContent, rating:val}); }}
                                                    renderInput={(params) => <TextField {...params} label={t('form.Stars')} />}
                                                />
                                                {error && blogTestimonialContent?.rating === '' &&
                                                    <div className="error-text>">Select Rating</div>
                                                }
                                            </Col>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Row>              
                        </Col>
                        <Col lg={8} md={12} className="mt-lg-3">
                            <Row className="grid">
                                <div className="grid-column myProfileSettings">
                                    <div className="widget-box area-widget">
                                        <div className="row widget-box-content">
                                            {blogTestimonialContent?.type === 'blog' ?
                                            <Col md={12}>
                                                <FormTextArea label={t('Text')}
                                                className="mb-0" 
                                                value={blogTestimonialContent?.description}
                                                onChange={(e)=>setBlogTestimonialContent({...blogTestimonialContent, description:e.target.value})}
                                                />
                                                {error && blogTestimonialContent?.description === '' &&
                                                    <div className="error-text>">Enter Description</div>
                                                }
                                            </Col>
                                            :
                                            <>
                                                <Col lg={6} md={6}>
                                                    <TextField label={t('form.label_name')}
                                                        value={blogTestimonialContent?.name}
                                                        onChange={(e)=>setBlogTestimonialContent({...blogTestimonialContent, name:e.target.value})}
                                                    />
                                                    {error && blogTestimonialContent?.name === '' &&
                                                        <div className="error-text>">Enter Name</div>
                                                    }
                                                </Col>
                                                <Col lg={6} md={6}>
                                                    <TextField label={t('Company')}
                                                        value={blogTestimonialContent?.company}
                                                        onChange={(e)=>setBlogTestimonialContent({...blogTestimonialContent, company:e.target.value})}
                                                    />
                                                    {error && blogTestimonialContent?.company === '' &&
                                                        <div className="error-text>">Enter Company</div>
                                                    }
                                                </Col>
                                                <Col md={12}>
                                                    <FormTextArea label={t('Text')}
                                                    value={blogTestimonialContent?.content}
                                                    onChange={(e)=>setBlogTestimonialContent({...blogTestimonialContent, content:e.target.value})}
                                                    />
                                                    {error && blogTestimonialContent?.content === '' &&
                                                        <div className="error-text>">Enter Content</div>
                                                    }
                                                </Col>
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Row>   

                            <button type="" className="button blogTargetPopUpSubmit me-lg-0" onClick={handleSubmit}>{t('Submit')}</button>
                        </Col>
                    </Row>
            </Box>
            </Popup>
        </>
    )
}

export default CmsBlogTestimonialBox