import React from "react";
import { BrowserRouter, Routes, Route , Navigate } from "react-router-dom";

import Logout from "../pages/logout";
import Dashboard from "../pages/Dashboard/dashboard.component";
import OrganizationInfo from "../pages/orgInfo/orgInfo";
import UserInfo from "../pages/userInfo/userInfo";
import ProfilePage from "../pages/Dashboard/profilePage.component";
import MyProfilePage from "../pages/Dashboard/myProfilePage.component";
import FriendProfilePage from '../pages/Dashboard/friendProfilePage.component';
import FriendsPage from "../pages/Dashboard/friendsPage.component";
import OrganizationsPage from "../pages/Dashboard/organizationsPage.component";
import NewsFeedPage from "../pages/Dashboard/newsFeedPage.component";

import EventsPage from "../pages/Dashboard/eventsPage.component";
import ViewEventPage from "../pages/Dashboard/viewEventPage.component";
import AddEventPage from "../pages/Dashboard/addEventPage.component";

import EventsValidatePage from "../pages/Dashboard/eventsValidatePage.component";
import ValidateEventPage from "../pages/Dashboard/validateEventPage.component";

import ManageOrgPage from "../pages/Dashboard/manageOrgPage.component";
import ManageUsersPage from "../pages/Dashboard/manageUsersPage.component";
import ImpactPage from "../pages/Dashboard/ImpactPage.component"

import TargetPage from "../pages/Dashboard/targetPage.component";
import AddTargetPage from "../pages/Dashboard/addTargetPage.component";

import WalletPage from "../pages/Dashboard/walletPage.component";
import FollowersPage from "../pages/Dashboard/followersPage.component";

import ProfileSettingsPage from "../pages/Dashboard/profileSettingsPage.component";
import AccountSettingsPage from "../pages/Dashboard/accountSettingsPage.component";
import PrivacySettingsPage from "../pages/Dashboard/privacysettingsPage.component";
import ManageRolesPage from "../pages/Dashboard/manageRolesPage";
import ManageEventPage from "../pages/Dashboard/manageEventPage.component";
import PostReviewPage from "../pages/Dashboard/postReviewPage.component";

import NotificationsPage from "../pages/Dashboard/notificationsPage.component";
import GlobalSearchComp from "../pages/Dashboard/globalSearchComp";
import AllGlobalSearchComp from "../pages/Dashboard/allGlobalSearchComp";
import AllGlobalUserSearchComp from "../pages/Dashboard/allGlobalUserSearchComp";
import AllGlobalOrgSearchComp from "../pages/Dashboard/allglobalOrgSearchComp";
import AllGlobalEventSearchComp from "../pages/Dashboard/allGlobalEventSearchComp";
import OrganizationPage from "../pages/Dashboard/organizationPage.component";
import OrgManageRolePage from "../pages/Dashboard/orgManageRolePage.component";
import ValidatorFormPage from "../pages/Dashboard/validatorFormPage.component";
import ValidatorAvailibiltyPage from "../pages/Dashboard/validatorAvailibiltyPage";
import VendorsPage from "../pages/Dashboard/vendorPage.component";
import CmsPage from "../pages/Dashboard/cmsPage.component";
import PostPage from "../pages/Dashboard/postPage.component";
import ValidatorProfilePage from "../pages/Dashboard/validatorProfilePage.component";
import ReportPostPage from "../pages/Dashboard/reportPostPage.component";
import ReportedUser from "../pages/Dashboard/reportedUserPage.component";
import ActivityLoginPage from '../pages/Dashboard/activityLoginPage.component';
import ViewPostPage from "../pages/Dashboard/viewPostPage.component"

import UnSubscribeNewsLetterPage from "../pages/home/unSubscribeNewsLetterPage.component";

import ComplaintFeedbackPage from "../pages/Dashboard/complaintFeedbackPage.component";
import MemoriesPage from "../pages/Dashboard/memoriesPage.component";
import ValidatorTermsConditionsPage from "../pages/Dashboard/validatorTermsConditionsPage.component";
import ManageInvitesPage from "../pages/Dashboard/manageInvites.component";
import TermsConditions from "../components/homepage/termsConditions";
import PrivacyPolicy from "../components/homepage/privacyPolicy";
import TermsConditionsOrg from "../components/homepage/orgTermsConditions";
const LoggedInRoutes = ({role, orgRole}) => {
    return (
        <BrowserRouter>
          <Routes>
              {/* Move to logged in routes */}

                  <Route path="/voxbox" element={<NewsFeedPage/>}/>
                  <Route path="/memories" element={<MemoriesPage/>}/>

                  {role === 'user' && <>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/my-profile" element={<MyProfilePage/>} />
                    <Route path='/events-validate' element={<EventsValidatePage/>} />                    
                    <Route path="/user/details" element={<UserInfo/>}/>
                    <Route path='/validator-form' element={<ValidatorFormPage/>} />
                    <Route path='/validator-terms-conditions' element={<ValidatorTermsConditionsPage/>} />
                    <Route path='/validator-availibility' element={<ValidatorAvailibiltyPage/>} />
                    <Route path='/termsandconditions' element={<TermsConditions/>} />
                    <Route path='/privacypolicy' element={<PrivacyPolicy/>} />
                  </>}

                  {/* Evidence Upload */}
                  <Route path='/validate-event' element={<ValidateEventPage/>}/>  
                  
                  {/* Complaint and Feedback */}
                  <Route path='/complaint-feedback' element={<ComplaintFeedbackPage/>}/>  
                  

                  <Route path="/profile" element={<ProfilePage/>} />
                  <Route path="/logout" element={<Logout/>} />
                  
                  {/* <Route path="/friends/:id" element={<FriendProfilePage />} /> */}
                  <Route exact path="/profile" element={<FriendProfilePage />}>
                     <Route path=":id" element={<FriendProfilePage />} />
                  </Route>

                  <Route path="/u/:userName" element={<FriendProfilePage />} />
                  <Route path="/o/:userName" element={<OrganizationPage />} />
                  
                  <Route exact path="/user" element={<FriendProfilePage />}>
                  </Route>

                  <Route path="validator/:id" element={<ValidatorProfilePage/>}/>
                  {role !== 'organization' &&
                  <Route path="/my-friends" element={<FriendsPage/>}/>}

                  {role === 'organization' &&  orgRole === 'admin' && <>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/org/details" element={<OrganizationInfo/>}/>                    
                    <Route path='/manage-roles' element={<OrgManageRolePage/>}/> 
                    <Route path='/vendors'  element={<VendorsPage/>}/>
                    <Route path='/orgtermsandconditions' element={<TermsConditionsOrg/>} />
                    </>
                  }

                  <Route path="/organizations" element={<OrganizationsPage/>}/>
                  <Route path="/organization/:id" element={<OrganizationPage/>}/>
                  
                  {((role==='organization' && (orgRole === 'admin' || orgRole === 'event_manager')) || role === 'user' || role === 'superAdmin') && <>
                    <Route path="/events" element={<EventsPage/>}/>
                    <Route path="/add-event" element={<AddEventPage/>}/>
                    <Route path="/view-event" element={<ViewEventPage/>}/>
                    <Route path='/manage-event' element={<ManageEventPage/>} />
                    <Route path="/target" element={<TargetPage/>}/>
                    <Route path="/add-target" element={<AddTargetPage/>}/>
                  </>}

                  {((role==='organization' && orgRole === 'admin') || role === 'user' || role === 'superAdmin') && <>
                    <Route path="/wallet" element={<WalletPage/>}/>
                    <Route path="/profile-settings" element={<ProfileSettingsPage/>}/>
                    <Route path="/account-settings" element={<AccountSettingsPage/>}/>
                  </>}
                  {((role==='organization' && orgRole === 'admin')  || role === 'superAdmin') && <>
                    <Route path="/activity-login" element={<ActivityLoginPage/>}/>
                    
                  </>}

                  {((role==='organization' && (orgRole === 'user_approver' || orgRole === 'admin'))  || role === 'superAdmin') && <>
                    <Route path="/followers" element={<FollowersPage/>}/>
                    
                  </>}

                  {role === 'user' && <>
                    <Route path="/privacy-settings" element={<PrivacySettingsPage/>}/>
                  </>}
                  
                  <Route path="/notifications" element={<NotificationsPage/>}/>

                  <Route path="/globalSearchComp" element={<GlobalSearchComp/>}/>
                  <Route path="/allGlobalSearchComp" element={<AllGlobalSearchComp/>}/>
                  <Route path="/allGlobalUserSearchComp" element={<AllGlobalUserSearchComp/>}/>
                  <Route path="/allGlobalOrgSearchComp" element={<AllGlobalOrgSearchComp/>}/>
                  <Route path="/allGlobalEventSearchComp" element={<AllGlobalEventSearchComp/>}/>
                {/* ****************** */}
              {/* super admin urls*/}

              {role === 'superAdmin' && <>
                <Route path="/superAdminDashboard" element={<UserInfo/>}/>
                <Route path='/manage-organizations'  element={<ManageOrgPage/>}/>
                <Route path='/manage-users'  element={<ManageUsersPage/>}/>
                <Route path='/manage-invites'  element={<ManageInvitesPage/>}/>
                <Route path='/reported-users'  element={<ReportedUser/>}/>
                <Route path='/cms'  element={<CmsPage/>}/>
                <Route path="/privacy-settings" element={<PrivacySettingsPage/>}/>
                <Route path='/complaint-feedback' element={<ComplaintFeedbackPage/>}/>  
              </>}
              {role !== 'user'  && <>
                <Route path='/manage-roles' element={<ManageRolesPage/>}/>
                <Route path='/post-review'  element={<PostReviewPage/>}/>
                <Route path='/impacts'  element={<ImpactPage/>}/>
                <Route path='/manage-reported-post' element={<ReportPostPage/>} />
              </>}

              <Route path="/post">
                <Route index={true} element={<PostPage/>}/>
                <Route path=":postId" element={<ViewPostPage/>}/>
              </Route>

              {/* ***************************** */}

              <Route path="/unsubscribe" element={<UnSubscribeNewsLetterPage/>}/>
            <Route path="*" element={<Navigate to="/voxbox" replace />} />
          </Routes>
        </BrowserRouter>
      );
}

export default LoggedInRoutes;