import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Popup from "../commonComponents/popup";
import UserSelector from "./userSelectBox";
import TabNavigation from "./tabNavigation.jsx";
import { useSelector } from "react-redux";
import GreenCheckBox from "../commonComponents/greenCheckbox/greenCheckbox";
import { selectCurrentUserRole, selectCurrentUser, selectCurrentOrg } from "../Reducer/user/user.selector";
import {
    getFriendsInSuggestion,
    searchUserByEmailService,
    registerUserByEmailService,
    getUsersBySearchRequest,
    getFriendsListService
} from "../services/friends.service";
import { getTargetInvitesService } from "../services/event.services";
import { getEmployeesIdService } from "../services/follower.service";
import { getOrganzationListingService } from "../services/orgAuth.services";
import { getfollowerList } from "../services/follower.service";
import { Row, Col } from "react-bootstrap";
import { validEmail } from "../utils/formatValidator";
import { showSingleToastSuccess, showToastError, showToastSuccess } from "../utils/helpers";
import ReactPagination from "../commonComponents/reactPagination";
const InvitePopup = ({ popupOpen, handleClose, invites, addInInvitesHandler, removeInvitesHandler, orgInvites, targetId, inviteUserError }) => {

    const { t } = useTranslation();
    const [tab, setTab] = useState(1);

    const role = useSelector(selectCurrentUserRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);

    let current_user = {};
    if (role === 'organization') { current_user = CURRENTORG }
    else { current_user = CURRENTUSER }

    let tabs = [];
    if (role === 'organization') {
        tabs = [
            t('friendsMsg.SEARCH_EMP'),
            t('friendsMsg.SEARCH_BY_EMAIL'),
            t('friendsMsg.SEARCH_ORG')
        ];
        if (targetId) {
            tabs.push(t('friendsMsg.TARGET_INVITES'))
            tabs.push(t('friendsMsg.TARGET_ORG_INVITES'))
        }
    }
    else {
        tabs = [
            t('friendsMsg.SEARCH_FRIENDS'),
            t('friendsMsg.SEARCH_BY_EMAIL')
        ];
        if (targetId) {
            tabs.push(t('friendsMsg.TARGET_INVITES'))
        }
    }

    const limit = 30;
    const [friendsList, setFriendsList] = useState([]);
    const [loading, setLoading] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(0);

    const [emailUserList, setEmailUserList] = useState([]);
    const [loadingTab2, setLoadingTab2] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [totalPageTab2, setTotalPageTab2] = useState(0);
    const [pageTab2, setPageTab2] = useState(0);
    const [inviteLoader, setInviteLoader] = useState(false);

    const [orgList, setOrgList] = useState([]);
    const [loadingTab3, setLoadingTab3] = useState(false);
    const [totalPageTab3, setTotalPageTab3] = useState(0);
    const [pageTab3, setPageTab3] = useState(0);

    const [targetInvitesList, setTargetInvitesList] = useState([]);
    const [loadingTab4, setLoadingTab4] = useState(false);
    const [totalPageTab4, setTotalPageTab4] = useState(0);
    const [pageTab4, setPageTab4] = useState(0);

    const [targetorgList, setTargetOrgList] = useState([]);
    const [loadingTab5, setLoadingTab5] = useState(false);
    const [totalPageTab5, setTotalPageTab5] = useState(0);
    const [pageTab5, setPageTab5] = useState(0);

    const [allEmpInvite, setAllInvites] = useState(false);
    const [allFrdsInvite, setAllFrdsInvites] = useState(false);
    const [allSearchUserInvite, setallSearchUserInvite] = useState(false);
    const [allSearchOrgInvite, setAllSearchOrgInvite] = useState(false);
    const [allSearchTargetInvite, setallSearchTargetInvite] = useState(false);
    const [allSearchTargetOrgInvite, setallSearchTargetOrgInvite] = useState(false);

    const [targetInvitesTotalCount, setTargetInvitesTotalCount] = useState(0);
    const [targetOrgInvitesTotalCount, setTargetOrgInvitesTotalCount] = useState(0);
    const [frdsInvitesTotalCount, setFrdsInvitesTotalCount] = useState(0);

    useEffect(() => {
        if (tab === 1) {
            getUserSuggestedList();
        }
        else if (tab === 2) {
            searchUserByEmail();
        }
        else if (tab === 3) {
            searchOrg();
        }
        else if (tab === 4) {
            searchTargetInvites()
        }
        else if (tab === 5) {
            searchTargetOrgInvites()
        }
    }, [searchText, tab, role])

    useEffect(() => {
        searchTargetOrgInvites();
    }, [pageTab5])

    useEffect(() => {
        searchTargetInvites();
    }, [pageTab4])

    useEffect(() => {
        searchOrg();
    }, [pageTab3])

    useEffect(() => {
        searchUserByEmail();
    }, [pageTab2])

    useEffect(() => {
        getUserSuggestedList();
    }, [page])

    const getUserSuggestedList = () => {
        setLoading(true);
        if (role === 'organization') {
            getfollowerList(page, limit, 'employeeList', searchText)
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setFriendsList(data?.data?.data?.data);
                        setFrdsInvitesTotalCount(data?.data?.data?.totalCount)
                        let pageCount = (data?.data?.data?.totalCount) / limit;
                        setTotalPage(Math.ceil(pageCount));
                        setLoading(false);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        else {
            if (searchText !== '') {
                let url = '?searchText=' + searchText + '&page=' + page + '&limit=' + limit + '&type=friendList'
                getUsersBySearchRequest(url)
                    .then((data) => {
                        if (data?.data?.status === 200) {
                            setFriendsList(data?.data?.data?.data);
                            setFrdsInvitesTotalCount(data?.data?.data?.totalCount)
                            let pageCount = (data?.data?.data?.totalCount) / limit;
                            setTotalPage(Math.ceil(pageCount));
                            setLoading(false);
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            else {
                getFriendsListService('friendList', page, limit)
                    .then((data) => {
                        if (data?.data?.status === 200) {
                            setFriendsList(data?.data?.data?.data);
                            setFrdsInvitesTotalCount(data?.data?.data?.totalCount)
                            let pageCount = (data?.data?.data?.totalCount) / limit;
                            setTotalPage(Math.ceil(pageCount));
                            setLoading(false);
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        }
    }

    const searchUserByEmail = async () => {
        setLoadingTab2(true);
        searchUserByEmailService(searchText, pageTab2, limit)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setEmailUserList(data?.data?.data?.data);
                    let pageCount = (data?.data?.data?.totalCount) / limit;
                    setTotalPageTab2(Math.ceil(pageCount));
                    setLoadingTab2(false);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const searchOrg = async () => {
        if (role === 'organization') {
            setLoadingTab3(true)
            getOrganzationListingService(pageTab3, limit, searchText, true)
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setOrgList(data?.data?.data?.data);
                        let pageCount = (data?.data?.data?.totalCount) / limit;
                        setTotalPageTab3(Math.ceil(pageCount));
                        setLoadingTab3(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        else {
            setLoadingTab3(true)
            if (targetId !== '') {
                getTargetInvitesService(targetId, pageTab3, limit, searchText, 'userInvites')
                    .then((data) => {
                        if (data?.data?.status === 200) {
                            setOrgList(data?.data?.data?.data);
                            setTargetInvitesTotalCount(data?.data?.data?.totalCount)
                            let pageCount = (data?.data?.data?.totalCount) / limit;
                            setTotalPageTab3(Math.ceil(pageCount));
                            setLoadingTab3(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        }
    }

    const searchTargetInvites = async () => {
        if (targetId) {
            setLoadingTab4(true)
            if (targetId !== '') {
                getTargetInvitesService(targetId, pageTab4, limit, searchText, 'userInvites')
                    .then((data) => {
                        if (data?.data?.status === 200) {
                            setTargetInvitesList(data?.data?.data?.data);
                            setTargetInvitesTotalCount(data?.data?.data?.totalCount)
                            let pageCount = (data?.data?.data?.totalCount) / limit;
                            setTotalPageTab4(Math.ceil(pageCount));
                            setLoadingTab4(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        }
        else {
            setTargetOrgList([])
        }
    }

    const searchTargetOrgInvites = async () => {
        if (targetId) {
            setLoadingTab5(true)
            getTargetInvitesService(targetId, pageTab5, limit, searchText, 'organizationInvites')
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setTargetOrgList(data?.data?.data?.data);
                        setTargetOrgInvitesTotalCount(data?.data?.data?.totalCount)
                        let pageCount = (data?.data?.data?.totalCount) / limit;
                        setTotalPageTab5(Math.ceil(pageCount));
                        setLoadingTab5(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        else {
            setTargetOrgList([])
        }
    }

    const onChangeHandler = (e, id, user) => {
        if (e.target.checked) { addInInvitesHandler(id, 'invites', user); }
        else {
            if (tab === 1) {
                setAllInvites(false);
            }
            removeInvitesHandler(id)
        }
    }

    const onChangeOrgHandler = (e, id, user) => {
        if (e.target.checked) { addInInvitesHandler(id, 'orgInvites', user); }
        else { removeInvitesHandler(id, 'orgInvites') }
    }

    const inviteButtonHandler = (e) => {
        setInviteLoader(true);
        registerUserByEmailService(searchText, 'event')
            .then((data) => {
                if (data?.data?.status !== 200) {
                    showToastError(t('errors.some_error'));
                }
                else {
                    // showToastSuccess("Invitation Sent Successfully!");
                    showSingleToastSuccess(t('apiMessages.INVITATION_SENT_SUCCESSFULLY'));
                    let addedUser = {
                        "_id": data?.data?.data,
                        "email": searchText,
                        "userName": "",
                        "status": "inactive",
                        "role": "user",
                        "profilePicture": ""
                    }
                    addInInvitesHandler(data?.data?.data, 'invites', addedUser);
                }
            })
            .catch((error) => {
                console.log(error);
            })
        setInviteLoader(false);
    }

    const handlePageClickTab5 = (data) => {
        let page = data?.selected;
        setPageTab5(page);
    }

    const handlePageClickTab4 = (data) => {
        let page = data?.selected;
        setPageTab4(page);
    }

    const handlePageClickTab3 = (data) => {
        let page = data?.selected;
        setPageTab3(page);
    }

    const handlePageClickTab2 = (data) => {
        let page = data?.selected;
        setPageTab2(page);
    }

    const handlePageClick = (data) => {
        let page = data?.selected;
        setPage(page);
    }

    const inviteAllHandler = (e, type) => {
        if (type === 'emp') {
            setAllInvites(e.target.checked);
            //get All Employees Ids
            try {
                getEmployeesIdService()
                    .then((data) => {
                        if (e.target.checked) {
                            data.data?.data?.map((item) => {
                                addInInvitesHandler(item?._id, 'invites', item)
                            })
                        }
                        else {
                            let remove = []
                            data.data?.data?.map((item) => {
                                remove.push(item?._id);
                            })
                            removeInvitesHandler(remove)
                        }
                    })
            }
            catch (error) {
                console.log(error)
            }
        }
        else if (type === 'friend') {
            setAllFrdsInvites(e.target.checked);
            //get All Frds Ids
            try {
                if (searchText !== '') {
                    let url = '?searchText=' + searchText + '&page=0&limit=' + frdsInvitesTotalCount + '&type=friendList';
                    getUsersBySearchRequest(url)
                        .then((data) => {
                            if (e.target.checked) {
                                data.data?.data?.data?.map((item) => {
                                    addInInvitesHandler(item?._id, 'invites', item)
                                })
                            }
                            else {
                                let remove = []
                                data.data?.data?.data?.map((item) => {
                                    remove.push(item?._id);
                                })
                                removeInvitesHandler(remove)
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                }
                else {
                    getFriendsListService('friendList', page, frdsInvitesTotalCount)
                        .then((data) => {
                            if (e.target.checked) {
                                data.data?.data?.data?.map((item) => {
                                    addInInvitesHandler(item?._id, 'invites', item)
                                })
                            }
                            else {
                                let remove = []
                                data.data?.data?.data?.map((item) => {
                                    remove.push(item?._id)
                                })
                                removeInvitesHandler(remove)
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                }
                // getEmployeesIdService()
                // .then((data)=>{
                //     if(e.target.checked){
                //         data.data?.data?.map((item)=>{
                //             addInInvitesHandler(item?._id, 'invites', item)
                //         })
                //     }
                //     else{
                //         let remove = []
                //         data.data?.data?.map((item)=>{
                //             remove.push(item?._id);
                //         })
                //         removeInvitesHandler(remove)
                //     }
                // })
            }
            catch (error) {
                console.log(error)
            }
        }
        else if (type === 'searchByEmail') {
            setallSearchUserInvite(e.target.checked)
            searchUserByEmailService(searchText, pageTab2, limit, true)
                .then((data) => {
                    if (e.target.checked) {
                        data.data?.data?.data?.map((item) => {
                            addInInvitesHandler(item?._id, 'invites', item)
                        })
                    }
                    else {
                        let remove = []
                        data.data?.data?.data?.map((item) => {
                            remove.push(item?._id);
                        })
                        removeInvitesHandler(remove)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        else if (type === 'searchOrg') {

            setAllSearchOrgInvite(e.target.checked)
            getOrganzationListingService(pageTab3, limit, searchText, true, true)
                .then((data) => {
                    if (e.target.checked) {
                        data.data?.data?.data?.map((item) => {
                            addInInvitesHandler(item?._id, 'orgInvites', item)
                        })
                    }
                    else {
                        let remove = []
                        data.data?.data?.data?.map((item) => {
                            remove.push(item?._id);
                        })
                        removeInvitesHandler(remove, 'orgInvites')
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        else if (type === 'target') {
            setallSearchTargetInvite(e.target.checked)
            if (targetId !== '') {
                getTargetInvitesService(targetId, pageTab3, targetInvitesTotalCount, searchText, 'userInvites')
                    .then((data) => {
                        if (e.target.checked) {
                            data.data?.data?.data?.map((item) => {
                                addInInvitesHandler(item?._id, 'invites', item)
                            })
                        }
                        else {
                            let remove = []
                            data.data?.data?.data?.map((item) => {
                                remove.push(item?._id);
                            })
                            removeInvitesHandler(remove)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        }
        else if (type === 'targetOrg') {
            setallSearchTargetOrgInvite(e.target.checked)
            getTargetInvitesService(targetId, pageTab3, targetOrgInvitesTotalCount, searchText, 'organizationInvites')
                .then((data) => {
                    if (e.target.checked) {
                        data.data?.data?.data?.map((item) => {
                            addInInvitesHandler(item?._id, 'orgInvites', item)
                        })
                    }
                    else {
                        let remove = []
                        data.data?.data?.data?.map((item) => {
                            remove.push(item?._id);
                        })
                        removeInvitesHandler(remove, 'orgInvites')
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }
    return (<Popup handleClose={handleClose} modalBodyClasses="inviteFriendsPopup" modalContentClasses="inviteFriendsPopupContent" open={popupOpen}>
        <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab} value={searchText} onChangeSearchText={setSearchText} />
        {tab === 1 &&
            <>
                <Row className="ms-2">
                    <div className="ms-3 d-flex pt-2 pb-2">
                        {role === 'organization' ?
                            <GreenCheckBox checked={allEmpInvite} onChange={(e) => inviteAllHandler(e, 'emp')} label={t('Invite_All_Employees')} /> :
                            <GreenCheckBox checked={allFrdsInvite} onChange={(e) => inviteAllHandler(e, 'friend')} label={t('Invite_All_Friends')} />
                        }
                    </div>
                    {
                        (inviteUserError) ? <div className="error py-0 ms-4 mb-2">The validator cannot be invited to participate in an event.</div> : ''
                    }
                    <div className="scrollBar popup_friend_boxes org_invite_box">

                        {friendsList.length > 0 && friendsList.map((item, index) => {
                            return (
                                <UserSelector
                                    key={index}
                                    user={item}
                                    checked={(invites.indexOf(item._id) > -1) ? true : false}
                                    onChange={onChangeHandler}
                                />)
                        })}

                    </div>
                    <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />
                </Row>

                {friendsList.length === 0 &&
                    <>
                        <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center">
                            <span className="bold">
                                {role === 'organization' ? t('friendsMsg.No_EMP') : t('friendsMsg.No_FRIENDS')}
                            </span>
                        </Col>
                    </>}
            </>}
        {tab === 2 && <>
            <Row className="ms-2">
                <div className="ms-3 d-flex pt-2 pb-2">
                    {emailUserList.length !== 0 &&
                        <GreenCheckBox checked={allSearchUserInvite} onChange={(e) => inviteAllHandler(e, 'searchByEmail')} label={t('Invite_All_Searched_Users')} />
                    }
                    {
                        (inviteUserError) ? <div className="error py-0 ms-4 mb-2">The validator cannot be invited to participate in an event.</div> : ''
                    }
                </div>
                <div className="col-md-12">
                    <div className="scrollBar popup_friend_boxes org_invite_box" >
                        <Row>



                            {emailUserList.length > 0 && emailUserList.map((item, index) => {
                                return (
                                    <UserSelector
                                        key={index}
                                        user={item}
                                        checked={(invites.indexOf(item._id) > -1) ? true : false}
                                        onChange={onChangeHandler}
                                    />)
                            })}
                        </Row>
                    </div>
                </div>
                <ReactPagination pageCount={totalPageTab2} onPageChange={handlePageClickTab2} />
            </Row>

            {emailUserList.length === 0 &&
                <>
                    <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center">
                        <span className="bold">
                            {t('friendsMsg.RECORD_NOT_FOUND')}
                        </span>
                        {searchText !== '' && !validEmail(searchText) &&
                            <div className="error-text">
                                {t('errors.email_format')}
                            </div>
                        }
                        {validEmail(searchText) &&
                            <button
                                className="button primary"
                                disabled={inviteLoader}
                                onClick={inviteButtonHandler}> {inviteLoader ? t('sending_invite') : t('invite')} </button>
                        }
                    </Col>
                </>
            }
        </>}
        {tab === 3 &&
            <>
                <Row className="ms-2">
                    <div className="ms-3 d-flex pt-3 pb-3">
                        {role === 'organization' ?
                            <GreenCheckBox checked={allSearchOrgInvite} onChange={(e) => inviteAllHandler(e, 'searchOrg')} label={t('Invite_All_Organizations')} /> :
                            <GreenCheckBox checked={allSearchTargetInvite} onChange={(e) => inviteAllHandler(e, 'target')} label={t('Invite_All_Target_Invites')} />
                        }
                    </div>
                    <div className="col-md-12">
                        <div className="scrollBar popup_friend_boxes org_invite_box">
                            <Row className="ms-2">
                                {orgList.length > 0 && orgList.map((item, index) => {
                                    return (
                                        <UserSelector
                                            key={index}
                                            user={item}
                                            checked={(role === 'organization') ? (orgInvites.indexOf(item._id) > -1) ? true : false : (invites.indexOf(item._id) > -1) ? true : false}
                                            onChange={(role === 'organization') ? onChangeOrgHandler : onChangeHandler}
                                        />)
                                })}
                            </Row>
                        </div>
                    </div>
                    <ReactPagination pageCount={totalPageTab3} onPageChange={handlePageClickTab3} />
                </Row>

                {orgList.length === 0 &&
                    <>
                        <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center">
                            <span className="bold">
                                {role === 'organization' ? t('friendsMsg.No_ORG') : t('friendsMsg.RECORD_NOT_FOUND')}
                            </span>
                        </Col>
                    </>
                }
            </>}
        {tab === 4 &&
            <>
                <Row className="ms-2">
                    <div className="ms-3 d-flex pt-3 pb-3">
                        {targetInvitesList.length > 0 &&
                            <GreenCheckBox checked={allSearchTargetInvite} onChange={(e) => inviteAllHandler(e, 'target')} label={t('Invite_All_Target_Invites')} />}
                    </div>
                    <div className="col-md-12">
                        <div className="scrollBar popup_friend_boxes org_invite_box">
                            <Row>
                                {targetInvitesList.length > 0 && targetInvitesList.map((item, index) => {
                                    return (
                                        <UserSelector
                                            key={index}
                                            user={item}
                                            checked={(invites.indexOf(item._id) > -1) ? true : false}
                                            onChange={onChangeHandler}
                                        />)
                                })}
                            </Row>
                        </div>
                    </div>
                    <ReactPagination pageCount={totalPageTab4} onPageChange={handlePageClickTab4} />
                </Row>

                {targetInvitesList.length === 0 &&
                    <>
                        <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center">
                            <span className="bold">
                                {role === 'organization' ? t('friendsMsg.RECORD_NOT_FOUND') : t('friendsMsg.RECORD_NOT_FOUND')}
                            </span>
                        </Col>
                    </>}
            </>}
        {tab === 5 &&
            <>
                <Row className="ms-2">
                    <div className="ms-3 d-flex pt-3 pb-3">
                        {targetorgList.length > 0 &&
                            <GreenCheckBox checked={allSearchTargetOrgInvite} onChange={(e) => inviteAllHandler(e, 'targetOrg')} label={t('Invite_All_Target_Org_Invites')} />}
                    </div>
                    <div className="col-md-12">
                        <div className="scrollBar popup_friend_boxes org_invite_box" >
                            <Row>
                                {targetorgList.length > 0 && targetorgList.map((item, index) => {
                                    return (
                                        <UserSelector
                                            key={index}
                                            user={item}
                                            checked={(orgInvites.indexOf(item._id) > -1) ? true : false}
                                            onChange={onChangeOrgHandler}
                                        />)
                                })}
                            </Row>
                        </div>
                    </div>
                    <ReactPagination pageCount={totalPageTab5} onPageChange={handlePageClickTab5} />
                </Row>

                {targetorgList.length === 0 &&
                    <>
                        <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center">
                            <span className="bold">
                                {role === 'organization' ? t('friendsMsg.RECORD_NOT_FOUND') : t('friendsMsg.RECORD_NOT_FOUND')}
                            </span>
                        </Col>
                    </>}
            </>}
        <button className="button primary inviteOkBtn" onClick={handleClose}> {t('proceed')} </button>
    </Popup>)
}

export default InvitePopup;