import React from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "../commonComponents/Images";
const SaveEditSettingsBtn = ({canEdit, onEdit, onSave, isLoading}) => {
    const { t } = useTranslation();
    return (<span style={{float:"right"}}>
        {!canEdit ?
            <img onClick={onEdit} src="/Assets/Edit.png" title={t('button.edit')} alt="" style={{cursor : "pointer"}}/>
            : (isLoading) 
             ? <img src={Loader} alt="" style={{cursor : "pointer"}} />
            : 
            <img onClick={onSave} src="/Assets/Save.png"  title={t('button.save')} alt="" style={{cursor : "pointer"}} />
        }
    </span>)
}

export default SaveEditSettingsBtn;