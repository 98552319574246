import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {
    Box,
    TextField,
    FormControl,
    Autocomplete
} from '@mui/material';
import { CalendarMonthIcon } from "../../../../commonComponents/Images";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { fromToDateValidator } from "../../../../utils/formatValidator";
import { EducationList, UniversityList } from "../../../../config/constants";
import SaveEditSettingsBtn from "../../../../commonDashboardComp/saveEditSettingsBtn";
import GreenCheckBox from "../../../../commonComponents/greenCheckbox/greenCheckbox";

const EducationSetting = ({ saveUserDetails, initial_education, formValues, setFormValues, canEdit, onEdit, error, setError }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState(false);

    const [educationFormValues, setEducationFormValues] = useState(formValues.education);
    const [showPursuing, setShowPursuing] = useState(true);

    useEffect(() => {
        setEducationFormValues(formValues.education.length > 0 ? formValues.education : [initial_education]);
    }, [formValues.education])

    useEffect(() => {
        setShowPursuing(true);
        educationFormValues.map((item) => {
            if (item.isCurrentlyPursuing === true) {
                setShowPursuing(false); return;
            }
        })
    }, [educationFormValues]);

    const addFormFields = (e) => {
        e.preventDefault()
        setEducationFormValues([...educationFormValues, initial_education])
        //setFormValues({...formValues ,  education:educationFormValues})
    }

    const handleChange = (i, e) => {
        let newFormValues = [...educationFormValues];
        newFormValues[i][e.target.name] = e.target.value;
        setEducationFormValues(newFormValues);
        setFormValues({ ...formValues, education: educationFormValues })
    }

    const handleChangeVal = (i, name, val, reason) => {
        var selectedValue = (reason == 'input') ? val : val?.value;
        let newFormValues = [...educationFormValues];
        if(selectedValue){
            newFormValues[i][name] = selectedValue;
            setEducationFormValues(newFormValues);
            setFormValues({ ...formValues, education: newFormValues })
        }
        
        if(reason == "clear"){ 
            newFormValues[i][name] = selectedValue;
            setEducationFormValues(newFormValues);
            setFormValues({ ...formValues, education: newFormValues })
        }
    }

    const handledateChange = (i, name, value) => {
        let newFormValues = [...educationFormValues];
        newFormValues[i][name] = value;
        setEducationFormValues(newFormValues);
        setFormValues({ ...formValues, education: educationFormValues })
    }

    const handleCheckboxChange = (i, e) => {
        let newFormValues = [...educationFormValues];
        newFormValues[i][e.target.name] = e.target.checked;
        newFormValues[i]['endYear'] = null;
        setEducationFormValues(newFormValues);
        setFormValues({ ...formValues, education: educationFormValues })
    }
    const removeFormFields = (i) => {
        let newFormValues = [...educationFormValues];
        newFormValues.splice(i, 1);
        setEducationFormValues(newFormValues)
        //setFormValues({...formValues ,  education:educationFormValues})
    }

    const onSave = (e) => {
        e.preventDefault();
        setErrors(false);
        let loop_error = false
        educationFormValues.map((item) => {
            if ((item.startYear !== null && item.endYear !== null) && !fromToDateValidator(item.startYear, item.endYear)) {
                loop_error = true;
            }
            if (item.course === '' || item.university === '' || item.startYear === '' || item.endYear === '') {
                loop_error = true;
            }
            if (item.course === undefined || item.university === undefined) {
                loop_error = true;
            }
            if (item.course === '' && item.university === '' && item.startYear === '' && item.endYear === '') {
                loop_error = false;
            }
        })
        if (loop_error) { setErrors(true); return; }
        let params = {
            education: educationFormValues
        }
        saveUserDetails(params, 'educationInfo');
    }

    return (<Row className="grid educationSettingComp">
        <div className="grid-column">
            <div className="widget-box equalPadding">
                <p className="widget-box-title">{t('form.label_education')}
                    <SaveEditSettingsBtn canEdit={canEdit} onEdit={onEdit} onSave={onSave} />
                </p>
                <div className="widget-box-content mb-0">
                    <Box
                        component="form"
                        sx={{
                            '&': { m: 1, width: '99%' },
                        }}
                        noValidate
                        autoComplete="off"
                        className="userdetail-form profile_parent11"
                        onSubmit={(e) => e.preventDefault()}
                    >
                        {educationFormValues.map((e, index) => (
                            <div key={index} style={{ position: 'relative' }} className="mb-0">
                                {
                                    ((e._id || index) && canEdit) ? <><hr /><div className="delete_row_2 delete_row" onClick={() => removeFormFields(index)}><DeleteForeverIcon /></div></> : ''
                                }
                                <Row className="profile_subcategory22">
                                    <Col md={6} className="educationSettingCompField fullWidth target_title88">
                                        {/* <TextField label={t('form.label_education')} value={e.course || ''} name="course" onChange={e => handleChange(index, e)}/> */}
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                disablePortal
                                                disabled={!canEdit}
                                                value={EducationList.find((option) => option.value === e.course)}
                                                options={EducationList}
                                                freeSolo
                                                getOptionLabel={(option) => t(option.label)}
                                                onChange={(e, val) => handleChangeVal(index, 'course', val)}
                                                inputValue={e.course || ''}
                                                onInputChange={(e, val, reason) => handleChangeVal(index, 'course', val, reason)}
                                                renderInput={(params) => <TextField {...params} label={t('form.label_education')} />}
                                            />
                                            {errors && (e.course === '' || e.course === undefined)  &&
                                                <div className="error-text">
                                                    {t('errors.onboarding_education_required')}
                                                </div>
                                            }
                                        </FormControl>
                                    </Col>
                                    <Col md={6} className="educationSettingCompField fullWidth target_title88">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                disablePortal
                                                disabled={!canEdit}
                                                value={UniversityList.find((option) => option.value === e.university)}
                                                options={UniversityList}
                                                freeSolo
                                                getOptionLabel={(option) => t(option.label)}
                                                onChange={(e, val) => handleChangeVal(index, 'university', val)}
                                                inputValue={e.university || ''}
                                                onInputChange={(e, val, reason) => handleChangeVal(index, 'university', val, reason)}
                                                renderInput={(params) => <TextField {...params} label={t('form.label_uni_ins')} />}
                                            />
                                            {errors && (e.university === '' || e.university === undefined) &&
                                                <div className="error-text">
                                                    {t('errors.onboarding_university_required')}
                                                </div>
                                            }
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row className="profile_subcategory99">
                                    <Col lg={3} className="educationSettingCompField col-md-6 fullWidth target_title88">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                closeOnSelect={true}
                                                disabled={!canEdit}
                                                label={t('from')}
                                                disableFuture={true}
                                                value={e.startYear || ''}
                                                onChange={(newDob) => {
                                                    handledateChange(index, 'startYear', newDob);
                                                }}
                                                InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                                                renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                                                inputFormat="DD MMM YYYY"
                                            />
                                        </LocalizationProvider>
                                        {errors && e.startYear === '' &&
                                            <div className="error-text">
                                                {t('errors.start_date_required')}
                                            </div>
                                        }

                                    </Col>
                                    <Col lg={3} className="col-md-6 fullWidth mb-0 target_title88 educationSettingCompField profileField2">
                                        {!e.isCurrentlyPursuing ?
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker
                                                    closeOnSelect={true}
                                                    label={t('to')}
                                                    disableFuture={true}
                                                    value={e.endYear || ''}
                                                    onChange={(newDob) => {
                                                        handledateChange(index, 'endYear', newDob);
                                                    }}
                                                    InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                                                    disabled={(e.currently_pursuing || !canEdit) ? true : false}
                                                    renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                                                    inputFormat="DD MMM YYYY"
                                                />
                                            </LocalizationProvider> :

                                            <TextField
                                                disabled={true}
                                                label={t('tillDate')}
                                                InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" alt="" style={{ cursor: 'pointer' }} /> }}
                                            />
                                        }

                                        {errors && e.endYear === '' &&
                                            <div className="error-text">
                                                {t('errors.end_date_required')}
                                            </div>
                                        }
                                        {(e.startYear !== null && e.endYear !== null) && !fromToDateValidator(e.startYear, e.endYear) &&
                                            <div className="error-text">
                                                {t('errors.start_end_year')}
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                {(e.isCurrentlyPursuing || showPursuing) &&
                                    <Row>
                                        <Col md={12} className="mx-3 mb-4 mt-0">
                                            <GreenCheckBox disabled={!canEdit}
                                                checked={e.isCurrentlyPursuing}
                                                name="isCurrentlyPursuing"
                                                onChange={e => handleCheckboxChange(index, e)}
                                                label={t('form.label_currently_pursuing')}
                                            />
                                        </Col>
                                    </Row>}
                            </div>
                        ))}

                        <Col className="removeMargin">
                            <button disabled={!canEdit} className="button add_more_record m-0" onClick={addFormFields}> + {t('Add_more_Record')} </button>
                        </Col>
                    </Box>
                </div>
            </div>
        </div>

    </Row>)
}

export default EducationSetting;