import React, { useEffect, useState } from "react";
import ShowPostLogin from "../../commonDashboardComp/showPostLogin";
import ShowPostLogout from "../../commonDashboardComp/showPostLogout";
import { useParams } from "react-router-dom";
import { getObjectFromStore } from "../../storage/Storage";
const ViewPostPage= () => {
    const {postId} = useParams();
    const post_id = window.atob(postId);
    const [selectedPost_id, setSelectedPost_id] = useState(post_id);

    useEffect(()=>{
        if(post_id){
            setSelectedPost_id(post_id)
        }
    },[post_id])

    const layout=()=>{
        if(getObjectFromStore('token')===null || getObjectFromStore('token')===undefined){
            return <>
                    <div className="mx-5"><ShowPostLogout selectedPost_id={selectedPost_id} setSelectedPost_id={selectedPost_id}/></div>
            </>  
       
    }
    else {
        return <>
                <ShowPostLogin selectedPost_id={selectedPost_id} setSelectedPost_id={selectedPost_id}/>
            </>
                    
        }

    }
    return layout()     
}

export default ViewPostPage;