import React from "react";
import { useState } from "react";
import EventBox from "../../../commonDashboardComp/eventBox";
import InfiniteScroll from "react-infinite-scroller";
import BarsLoader from "../../../commonComponents/barsLoader";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GetEventParticipantsService } from "../../../services/event.services";

const ActiveEventParticipants = ({eventId}) => {
    const {t} = useTranslation();
    const [partipants, setParticipants] = useState([]);
    const [isLoading, setIsLoading] = useState (false);
    const limit=20;
    const [hasMore, SetHasMore] = useState(true);
    const [offset , setOffset] = useState(0);

    const getEventParticipants = () => {
        let page = offset/limit;
        let newOffset = offset+limit;
        setIsLoading(true);
        GetEventParticipantsService(eventId, 'participantList', page, limit)
          .then((data)=>{
            if(data?.data?.status === 200){
                
                if(data?.data?.data?.length === 0 || data?.data?.data?.length < limit){
                    SetHasMore(false);
                }
                setParticipants([...partipants, ...data?.data?.data]);
            }            
            setOffset(newOffset);
            setIsLoading(false);
          })
          .catch((error)=>{
            console.log(error);
          })
      }
    return (<>
        <InfiniteScroll
                pageStart={0}
                loadMore={getEventParticipants}
                hasMore={hasMore}
                loader={<BarsLoader key={0} />}
                useWindow={false}
                className="grid-column"
            >
          <>
            {
              partipants.length > 0 && partipants.map((item, index)=>{
                return <EventBox key={index} userDet={item}  btnText={t('button.block_text')} action="block" eventId={eventId}/>
              })
            }
          </>
        </InfiniteScroll>

        {!isLoading && partipants.length === 0 &&
            <>
                <Row className="notification">
                    <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center mt-4">
                        <span className="bold">
                            {t('no_participants')} 
                        </span>
                    </Col>
                </Row>

            </>}
    </>)
}

export default ActiveEventParticipants;