import { Button, TextField } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
const SearchBox = ({onChangeSearchText,value}) => {
    const { t } = useTranslation();

    return <div className="">

        {/* <p className="widget-box-title fewFriendsHeading">Invite Friends <span className='friendsCountText'></span></p> */}

        <div className="">
            <div className="search-icon newSearchIcon invitePoupBlock" style={{ width: '200px' }}>
                <TextField type='text' label={t('form.Search')}
                    onChange={(e)=>onChangeSearchText(e.target.value)}
                    value={value}
                    InputProps={{ endAdornment: <Button className="button secondary"><SearchIcon style={{ fill: "#fff" }} /></Button> }} />
            </div>

        </div>


    </div>


}
export default SearchBox;