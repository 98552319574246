import React from "react";
import UserLayout from "../../components/dashboard-layout";
import ManageOrgs from "../../components/dashboard/superAdmin/manageOrgs";

const ManageOrgPage = () => {
    return <UserLayout>
                <ManageOrgs/>
            </UserLayout>;
}

export default ManageOrgPage;