import React , {useState , useEffect} from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Row, Col } from "react-bootstrap";
import SettingsSidebar from "../../../commonDashboardComp/settingsSidebar";
import PersonalAccountInfo from "./accountSettings/personalAccountInfo";
import OrgInfoSetting from "./accountSettings/orgInfoSetting";
import ChangePasswordInfo from "./accountSettings/changePasswordInfo";
import GeneralSettingInfo from "./accountSettings/generalSettingInfo";
import RecentLoginInfo from "./accountSettings/recentLoginInfo";
import BlockedUsersInfo from "./accountSettings/blockedUsersInfo";
import PrivacySettingsOrg from "./privacySettings/privacySettingsOrg";
import RequestResetPassword from "./accountSettings/requestResetPassword";
import UnauthorisedAlertInfo from "./accountSettings/unauthorisedAlertInfo";
import DeleteAccount from "./accountSettings/deleteAccount";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUserRole, selectCurrentUser, selectCurrentOrg } from "../../../Reducer/user/user.selector";
import { getUserDetailsService } from "../../../services/auth.services";
import { getGeneralSettingService, updateGeneralSettingService, updatePasswordService } from "../../../services/userSettings.services";
import { GetUserDetailsService, saveUserDetailsService} from "../../../services/onBoarding.services";
import { showSingleToastSuccess, showToastError, showToastSuccess } from "../../../utils/helpers";
import { passwordRegexValidate , 
            firstLastNameValidate, 
            validEmail
        } from "../../../utils/formatValidator";
import { setCurrentUser , setCurrentOrg} from "../../../Reducer/user/user.action";
import { ForgotPasswordService, ResetPasswordService } from "../../../services/auth.services";

import { getOrgDetailsService,
    getOrgGeneralSettingService,
    updateOrgDetailsService,
    updateOrgGeneralSettingService
 } from "../../../services/orgAuth.services";
import { loginUserType } from "../../../config/constants";

import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import DialogBox from "../../../commonComponents/dialogBox";
import { setCanSwitch } from "../../../Reducer/user/user.action";
import AcceptanceBlock from "./accountSettings/acceptanceBlock";
import AcceptanceBlockOrg from "./accountSettings/acceptanceBlockOrg";
const AccountSettings = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const role = useSelector(selectCurrentUserRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);

    const editOptionDefault = {
        orgPrivacy: false,
    }
    const [editOption, setEditOption] = useState(editOptionDefault);
    let current_user = {};
    if(role === 'organization'){   current_user = CURRENTORG     }
    else{ current_user = CURRENTUSER }

    const [disabledAccountInfo, setDisabledAccountInfo] = useState(true)
    const [error, setError] = useState(false);

    const [PasswordPopupOpen, setPasswordPopupOpen] = useState(false);
    const [counter, setCounter] = useState(0);
    const handleOtpBoxOpen = () => setPasswordPopupOpen(true);
    const handleClose = () => setPasswordPopupOpen(false); 

    let INITIAL_GENERAL_SETTINGS = {
        "search" : false,
        "tagging" : false,
        "factAndTips" : false,
        "events" : false,
        "emailNotifications" : false,
        'whoCanSeeProfile' : '',
        'whoCanSharePost' : ''
    }
    let INITIAL_FORGOT_PASSWORD = {
        currentPassword : '',
        newPassword : '',
        confirmNewPassword : '',
        otp : '',
        password : '',
        confirmPassword : '',
        error:false,
        popupError:false
    }
    let INITIAL_ACCOUNT_INFO = {
        'base_url' : `${window.location.origin}`,
        "name" : "",
        "firstName" : '',
        "lastName" : '',
        "email" : '',
        "mobile" : '',
        "phone" : '',
        "userName" : '',
        "country" : '',
        "city" : "",
        "secondaryEmail" : '',
        "language" : '',
        'isEmailVerify'  : false,
        'isMobileVerify'  : false,
        'url' : '',
        "taxId" : "",
        "webSite" : "",
        "regNo" : "",
        "country" : "",
        "language" : "",
        "secondaryEmail" : ""
    }
    const [generalSettingsValues, setGeneralSettingsValues] = useState(INITIAL_GENERAL_SETTINGS);
    const [passwordFormValues , setPasswordFormValues] = useState(INITIAL_FORGOT_PASSWORD);
    const [formValues , setFormValues] = useState(INITIAL_ACCOUNT_INFO);
    const [dataDupCopy , setDupCopy] = useState(INITIAL_ACCOUNT_INFO);
    const [isLoading , setIsLoading] = useState(false);

    const [showDialog, setShowDialog] = useState(false)
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

    useEffect(()=>{
        setShowDialog(!(JSON.stringify(formValues) === JSON.stringify(dataDupCopy)))
    }, [formValues, dataDupCopy])

    useState(()=>{
        dispatch(setCanSwitch(!showDialog))
   }, [showDialog])


    useEffect(()=>{ 
        getUserDetails();
        getGeneralSettings();
     }, [role])

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            }else
            {
                clearInterval(myInterval)
            }            
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
          };
    }, [counter]);

    const onChangeEditOption = (key, value) => {
        setEditOption({ ...editOption, [key]: value })
    }

    //Account Info
        const getUserDetails = async() => {
            if(role === 'organization')
            {
                getOrgDetailsService(current_user?._id)
                .then((data)=>{
                    if(data?.data?.status === 200){
                        let FORM_VALUES = {
                            'base_url' : `${window.location.origin}`,
                            //demographic details 
                                "name" : (data?.data?.data?.name)?data?.data?.data?.name:"",
                                "taxId" : (data?.data?.data?.taxId)?data?.data?.data?.taxId:"",
                                "webSite" : (data?.data?.data?.webSite)?data?.data?.data?.webSite:"",
                                "regNo" : (data?.data?.data?.regNo)?data?.data?.data?.regNo:"",

                            //Contact Info
                                'user_email' : (data?.data?.data?.email)?data?.data?.data?.email:'',
                                'user_mobile' : (data?.data?.data?.mobile)?data?.data?.data?.mobile:'',
                                'email'  : (data?.data?.data?.email)?data?.data?.data?.email:'',
                                'mobile'  : (data?.data?.data?.mobile)?data?.data?.data?.mobile:'',
                                'isEmailVerify'  : (data?.data?.data?.isEmailVerify)?data?.data?.data?.isEmailVerify:false,
                                'isMobileVerify'  : (data?.data?.data?.isMobileVerify)?data?.data?.data?.isMobileVerify:false,

                                "userName" : (data?.data?.data?.userName)?data?.data?.data?.userName:'',
                                'url' : (data?.data?.data?.userName)?formValues.base_url+'/'+data?.data?.data?.userName.toLowerCase():'',

                                "country" : (data?.data?.data?.corporateAddress[0]?.country)?data?.data?.data?.corporateAddress[0]?.country:'',
                                "city" : (data?.data?.data?.corporateAddress[0]?.city)?data?.data?.data?.corporateAddress[0]?.city:'',
                                "corporateAddress":[{city : data?.data?.data?.corporateAddress[0]?.city, country: data?.data?.data?.corporateAddress[0]?.country}],
                                "secondaryEmail" : (data?.data?.data?.secondaryEmail)?data?.data?.data?.secondaryEmail:'',
                                policyStatus: data?.data?.data?.policyStatus
                        }
                        setFormValues({...formValues, ...FORM_VALUES})
                        setDupCopy({...dataDupCopy, ...FORM_VALUES});
                    }
                })
                .catch((error)=>{
                    console.log(error);
                })
            }
            else{
                GetUserDetailsService()
                .then((data)=>{
                    if(data?.data?.status === 200){
                        let FORM_VALUES = {
                                'base_url' : `${window.location.origin}`,
                            //demographic details 
                                'firstName'  : (data?.data?.data?.firstName)?data?.data?.data?.firstName:'',
                                'lastName'  : (data?.data?.data?.lastName)?data?.data?.data?.lastName:'',
                                "name" : `${data?.data?.data?.firstName} ${data?.data?.data?.lastName}`,
                                'user_email' : (data?.data?.data?.email)?data?.data?.data?.email:'',
                                'user_mobile' : (data?.data?.data?.mobile)?data?.data?.data?.mobile:'',
                                'email'  : (data?.data?.data?.email)?data?.data?.data?.email:'',
                                'mobile'  : (data?.data?.data?.mobile)?data?.data?.data?.mobile:'',
                                "userName" : (data?.data?.data?.userName)?data?.data?.data?.userName:'',
                                "country" : (data?.data?.data?.currentAddress?.country)?data?.data?.data?.currentAddress?.country:'',
                                "city" : (data?.data?.data?.currentAddress?.city)?data?.data?.data?.currentAddress?.city:'',
                                "currentAddress":{city : data?.data?.data?.currentAddress?.city, country: data?.data?.data?.currentAddress?.country},
                                "secondaryEmail" : (data?.data?.data?.secondaryEmail)?data?.data?.data?.secondaryEmail:'',
                                "language" : (data?.data?.data?.language)?data?.data?.data?.language:'',
                                'isEmailVerify'  : (data?.data?.data?.isEmailVerify)?data?.data?.data?.isEmailVerify:false,
                                'isMobileVerify'  : (data?.data?.data?.isMobileVerify)?data?.data?.data?.isMobileVerify:false,
                                'url' : (data?.data?.data?.userName)?formValues.base_url+'/'+data?.data?.data?.userName.toLowerCase():'',
                                policyStatus: data?.data?.data?.policyStatus
                        }
                        setFormValues({...formValues, ...FORM_VALUES})
                        setDupCopy({...dataDupCopy, ...FORM_VALUES});
                    }
                })
                .catch((error)=>{
                    console.log(error);
                })
            }
            
        }

        const saveAccountInfo = () => {
            if(role === 'organization'){
                if(
                    (formValues.secondaryEmail!=='' && !validEmail(formValues.secondaryEmail)) ||
                    formValues.email?.toLowerCase() === formValues?.secondaryEmail?.toLowerCase() || 
                    (formValues.mobile !== '' && formValues?.mobile?.length < 10)
                    
                    ){
                    setError(true); return;
                }
                let params = {
                    "organizationId" : current_user?._id,
                    "name":formValues.name,
                    "country" : formValues.country,
                    "corporateAddress":[{city : formValues.city, country: formValues.country}],
                    "language" : formValues.language,
                    "mobile" : formValues.mobile,
                    "secondaryEmail" : formValues.secondaryEmail
                }
                try{
                    updateOrgDetailsService(params)
                    .then((data)=>{
                        if (data.status === 200) {
                            // showToastSuccess(t(`apiMessages.DATA_SAVED_SUCCESSFULLY`));
                            if(chnageDetection(params)){
                                showSingleToastSuccess(t('apiMessages.DATA_SAVED_SUCCESSFULLY'));
                            }
                            else{
                                showSingleToastSuccess(t('errors.No_change_have_been_made'));
                                
                            }
                            
                            //showSingleToastSuccess(t(`apiMessages.DATA_SAVED_SUCCESSFULLY`));
                            updateProfileDetails();
                            setDisabledAccountInfo(true)
                        } else {
                            setError(true);
                            showToastError(t(`apiMessages.${data.data.data}`))
                        }
                    })
                    .then(()=>{ getUserDetails(); })
                    .catch((error)=>{
                        showToastError(t(`apiMessages.${error?.data?.data}`))
                    })

                    setIsLoading(false);
                }
                catch(error){
                    console.log(error);
                }
            }
            else{
                if(formValues.name === '' || 
                    !firstLastNameValidate(formValues.name) || 
                    (formValues.secondaryEmail!=='' && !validEmail(formValues.secondaryEmail)) ||
                    formValues.email?.toLowerCase() === formValues?.secondaryEmail?.toLowerCase() ||
                    (formValues.mobile !== '' && formValues?.mobile?.length < 10)
                    ){
                    setError(true); return;
                }
                setIsLoading(true);

                let index = formValues.name.indexOf(" ");
                let firstName = formValues.name.substr(0, index); // Gets the first part
                let lastName = formValues.name.substr(index + 1);

                let params = {
                    "firstName":firstName,
                    "lastName": lastName,
                    "country" : formValues.country,
                    "currentAddress":{city : formValues.city, country: formValues.country},
                    "language" : formValues.language,
                    "mobile" : formValues.mobile,
                    "secondaryEmail" : formValues.secondaryEmail
                }
                try{
                    saveUserDetailsService(params)
                    .then((data)=>{
                        if (data.status === 200) {
                            // showToastSuccess(t(`apiMessages.DATA_SAVED_SUCCESSFULLY`));
                            if(chnageDetection(params)){
                                showSingleToastSuccess(t('apiMessages.DATA_SAVED_SUCCESSFULLY'));
                            }
                            else{
                                showSingleToastSuccess(t('errors.No_change_have_been_made'));
                                
                            }
                            updateProfileDetails();
                            i18next.changeLanguage(params.language || 'en');
                            setDisabledAccountInfo(true)
                        } else {
                            setError(true);
                            showToastError(t(`apiMessages.${data.data.data}`))
                        }
                    })
                    .then(()=>{ getUserDetails(); })
                    .catch((error)=>{
                        showToastError(t(`apiMessages.${error?.data?.data}`))
                    })

                    setIsLoading(false);
                }
                catch(error){
                    console.log(error);
                }
            }
            
        }

        const chnageDetection=(param)=>{
            const propertyNames = Object.keys(param);
            for(let i=0;i<propertyNames?.length;i++){
                if(JSON.stringify(dataDupCopy[propertyNames[i]])!==JSON.stringify(param[propertyNames[i]])){
                   return true;                   
                }
            }
            return false;
           }

        const updateProfileDetails = async() => {
            if(role === 'organization'){
                const response = await getOrgDetailsService(current_user?._id);
                if (response?.data?.data) {
                    const org = response?.data?.data
                    dispatch(setCurrentOrg(org));
                }
            }
            else{
                const response = await getUserDetailsService();
                if (response?.data?.data) {
                    const user = response?.data?.data
                    dispatch(setCurrentUser(user));
                }
            }            
        }
    
    //General Settings
        const getGeneralSettings = () => {
            if(role === 'organization'){
                getOrgGeneralSettingService(current_user?._id)
                .then((data)=>{
                    if(data?.data?.status === 200){
                        let FORM_VALUES = {
                            "search" : (data?.data?.data?.search)?data?.data?.data?.search:false,
                            "tagging" : (data?.data?.data?.tagging)?data?.data?.data?.tagging:false,
                            "factAndTips" : (data?.data?.data?.factAndTips)?data?.data?.data?.factAndTips:false,
                            "events" : (data?.data?.data?.events)?data?.data?.data?.events:false,
                            "emailNotifications" : (data?.data?.data?.emailNotifications)?data?.data?.data?.emailNotifications:false,
                            'whoCanSeeProfile' : (data?.data?.data?.whoCanSeeProfile)?data?.data?.data?.whoCanSeeProfile:'',
                            'whoCanSharePost' : (data?.data?.data?.whoCanSharePost)?data?.data?.data?.whoCanSharePost:'',
                        }
                        setGeneralSettingsValues(FORM_VALUES)
                    }
                })
                .catch((error)=>{
                    console.log(error);
                })
            }
            else{
                getGeneralSettingService()
                .then((data)=>{
                    if(data?.status === 200){
                        let FORM_VALUES = {
                            "search" : (data?.data?.search)?data?.data?.search:false,
                            "tagging" : (data?.data?.tagging)?data?.data?.tagging:false,
                            "factAndTips" : (data?.data?.factAndTips)?data?.data?.factAndTips:false,
                            "events" : (data?.data?.events)?data?.data?.events:false,
                            "emailNotifications" : (data?.data?.emailNotifications)?data?.data?.emailNotifications:false,
                            'whoCanSeeProfile' : (data?.data?.whoCanSeeProfile)?data?.data?.whoCanSeeProfile:'',
                            'whoCanSharePost' : (data?.data?.whoCanSharePost)?data?.data?.whoCanSharePost:'',
                        }
                        setGeneralSettingsValues(FORM_VALUES)
                    }
                })                
                .catch((error)=>{
                    console.log(error);
                })
            }            
        }

        const saveGeneralSettings = (params) => {
            if(role === 'organization'){
                params['organizationId'] = current_user?._id
                updateOrgGeneralSettingService(params)
                .then((data)=>{
                    //console.log(data?.data);
                })
                .catch((error)=>{
                    console.log(error?.data);
                })
            }
            else{
                updateGeneralSettingService(params)
                .then((data)=>{
                    //console.log(data?.data);
                })
                .catch((error)=>{
                    console.log(error?.data);
                })
            }
        }
    
    //Forgot Password
        const updatePassword = () => {
            setPasswordFormValues({...passwordFormValues, error:false});
            if(passwordFormValues.currentPassword === '' ||
                passwordFormValues.newPassword === '' ||
                !passwordRegexValidate(passwordFormValues.newPassword) || 
                passwordFormValues.confirmNewPassword === '' ||
                passwordFormValues.currentPassword === passwordFormValues.newPassword ||
                passwordFormValues.newPassword !== passwordFormValues.confirmNewPassword
             ){
                setPasswordFormValues({...passwordFormValues, error:true}); return;
             }

            let params = {
                "currentPassword" : passwordFormValues.currentPassword,
                "newPassword" : passwordFormValues.newPassword
            }

            try
            {
                updatePasswordService(params)
                .then((data)=>{
                    if(data?.data?.status === 200){
                        // showToastSuccess(t(`apiMessages.PASSWORD_UPDATE_SUCCESS`))
                        showSingleToastSuccess(t(`apiMessages.PASSWORD_UPDATE_SUCCESS`))
                        setPasswordFormValues(INITIAL_FORGOT_PASSWORD)
                    }
                    else{
                        showToastError(t(`apiMessages.${data.data.data}`))
                    }
                })
                .catch((error)=>{
                    //console.log(error)
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
            }
            catch(error){
                //console.log(error)
            }
        }

        const onClickForgotPassword = (e) => {
            e.preventDefault();
            let email = current_user?.email;
            if(email === '')
            {
                showToastError(t('errors.some_error'));
            }
            else
            {
                try
                {     
                    ForgotPasswordService(email)
                    .then(async (data) => {
                        if (data.status === 200) {
                            setCounter(60);
                            // showToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`));
                            showSingleToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`));
                        } else {
                        setError(true);
                        showToastError(t(`apiMessages.${data?.data?.data}`))
                        }
                    })
                    .then(()=>handleOtpBoxOpen())
                    .catch((error) => {
                        setError(true);
                        showToastError(t(`apiMessages.${error?.data?.data}`))
                    });
                }
                catch(error){
                    console.log(error);
                }
            }
        }  

        const resetPassword = (e) => {
            e.preventDefault();
            setPasswordFormValues({...passwordFormValues, popupError:false});
            if( passwordFormValues.otp === '' || 
                passwordFormValues.password.length < 8 || 
                !passwordRegexValidate(passwordFormValues.password)||
                passwordFormValues.password !== passwordFormValues.confirmPassword   )
            {
                setPasswordFormValues({...passwordFormValues, popupError:true});return;
            }

            try
            {     
                let email = current_user?.email;
                var params = {
                    email : email,
                    otp : passwordFormValues.otp,
                    password : passwordFormValues.password
                }      
                ResetPasswordService(params)
                .then(async (data) => {
                    if (data.status === 200) {
                        // showToastSuccess(t(`apiMessages.PASSWORD_UPDATE_SUCCESS`))
                        showSingleToastSuccess(t(`apiMessages.PASSWORD_UPDATE_SUCCESS`))
                        handleClose();
                    } else {
                        showToastError(t(`apiMessages.${data?.data?.data}`))
                    }
                })
                .catch((error) => {
                    setError(true);
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                });
            }
            catch(error){
                console.log(error);
            }
        }

    //Privacy Setting
        const onSaveSetting = async (option, key) => {
            if(role === 'organization'){
                try{

                    const response = updateOrgGeneralSettingService(option)
                    if(response){
                        onChangeEditOption(key, false)
                        // showToastSuccess(t(`apiMessages.SETTING_UPDATE_SUCCESS`))
                        showSingleToastSuccess(t(`apiMessages.SETTING_UPDATE_SUCCESS`))
                    }
                }catch (error) {
                    console.log(error)
                }
            }
            else{
                try {
                    const response = await updateGeneralSettingService(option)
                    if (response) {
                        onChangeEditOption(key, false)
                        // showToastSuccess(t(`apiMessages.SETTING_UPDATE_SUCCESS`))
                        showSingleToastSuccess(t(`apiMessages.SETTING_UPDATE_SUCCESS`))
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        }

        const getSettingOptionValue = () => {
            let option = {}
            option['settings'] = {
                                    "whoCanSeeProfile": generalSettingsValues.whoCanSeeProfile,
                                    "whoCanSharePost": generalSettingsValues.whoCanSharePost,
                                }
            if(role === 'organization'){
                option['organizationId'] = current_user?._id
            }
            return option;
        }

        const onChangeSetting = (key, value) => {
            setGeneralSettingsValues({ ...generalSettingsValues, [key]: value })
        }

    return (<Row className="grid grid-3-6-3 mobile-prefer-content">
                <DialogBox
                    showDialog={showPrompt}
                    confirmNavigation={confirmNavigation}
                    cancelNavigation={cancelNavigation}
                />

                <Col md={3} className="grid-column">
                    <SettingsSidebar/>
                </Col>

                <Col md={9} className="grid-column">
                    {role !=='superAdmin' && 
                        <>
                            {role === 'organization' ?
                                <OrgInfoSetting
                                    orgId = {current_user?._id}
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    error={error}
                                    setError={setError}
                                    saveAccountInfo={saveAccountInfo}
                                    disabledAccountInfo={disabledAccountInfo}
                                    setDisabledAccountInfo={setDisabledAccountInfo}
                                    isLoading={isLoading}
                                />
                            :
                            
                            <PersonalAccountInfo
                                formValues={formValues}
                                setFormValues={setFormValues}
                                error={error}
                                setError={setError}
                                saveAccountInfo={saveAccountInfo}
                                disabledAccountInfo={disabledAccountInfo}
                                setDisabledAccountInfo={setDisabledAccountInfo}
                                isLoading={isLoading}
                            />
                            }
                            {role === loginUserType.USER ?
                                <ChangePasswordInfo
                                    passwordFormValues={passwordFormValues}
                                    setPasswordFormValues={setPasswordFormValues}
                                    updatePassword={updatePassword}
                                    PasswordPopupOpen={PasswordPopupOpen}
                                    counter={counter}
                                    onClickForgotPassword={onClickForgotPassword}
                                    handleClose={handleClose}
                                    resetPassword={resetPassword}
                                /> : null}
                        </>
                    }
                    
                    <GeneralSettingInfo 
                        generalSettingsValues={generalSettingsValues}
                        setGeneralSettingsValues={setGeneralSettingsValues}
                        saveGeneralSettings={saveGeneralSettings}
                    />

                    {role !== 'user' && 
                        <PrivacySettingsOrg
                            setting={generalSettingsValues}
                            onEdit={() => onChangeEditOption('orgPrivacy', true)}
                            canEdit={editOption.orgPrivacy}
                            onSave={() => onSaveSetting(getSettingOptionValue() , 'orgPrivacy')}
                            onChangeSetting={onChangeSetting}
                        />
                    }

                    {role !== 'organization' && <RecentLoginInfo/>}
                    {role ==='superAdmin' && <RequestResetPassword/>}
                    {role !== 'organization' && <UnauthorisedAlertInfo/>}
                    <BlockedUsersInfo/>
                    {role === 'user' && <AcceptanceBlock policyStatus={formValues.policyStatus}/>}
                    {role === 'organization' && <AcceptanceBlockOrg currentOrg ={CURRENTORG} policyStatus={formValues.policyStatus}/>}
                    {role === 'user' && <DeleteAccount/>}
                    
                </Col>
            </Row>)
}

export default AccountSettings;

