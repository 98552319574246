import React, { useContext, useState, useEffect } from "react";
import { CommonContext } from "../../../context/common.context";
import './sidebar.style.css';
import { AvatarImg } from "../../../commonComponents/Images";
import { SidebarExpandedMenu, SidebarSmallMenu } from "./sidebarMenu";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from "react-redux";
import {
    selectCurrentUser,
    selectCurrentUserRole,
    selectCurrentOrg,
    selectCurrentOrgRole,
    selectValidatorChange
} from "../../../Reducer/user/user.selector";
import { Link, useNavigate } from "react-router-dom";
import Popup from "../../../commonComponents/popup";
import ValidatorForm from "../../dashboard/validator/validatorForm";
import { getUserDetailsService } from "../../../services/auth.services";
import { validatorOffForForm } from "../../../services/event.services";
import { Switch } from "@mui/material";
import { getImageUrl, showSingleToastSuccess } from "../../../utils/helpers";
import swal from 'sweetalert';
import { showToastSuccess } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { setSearchText } from "../../../Reducer/user/user.action";
import { setCurrentUser } from "../../../Reducer/user/user.action";
const Sidebar = () => {
    const OrgImg = '/Assets/org1@2x.png';
    const role = useSelector(selectCurrentUserRole);
    const validatorChange = useSelector(selectValidatorChange);
    const OrgRole = useSelector(selectCurrentOrgRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);
    const { t } = useTranslation();
    const navigate = useNavigate();
    let current_user = {};
    if (role === 'organization') { current_user = CURRENTORG }
    else { current_user = CURRENTUSER }
    

    //const current_user = useSelector(selectCurrentUser);
    const [coverImg, setCoverImg] = useState((current_user?.coverPicture) ? getImageUrl(current_user?._id, 'userCover', current_user?.coverPicture) : false)
    const [userImg, setUserImg] = useState((current_user?.profilePicture) ? getImageUrl(current_user?._id, 'userProfile', current_user?.profilePicture) : (role === 'organization') ? OrgImg : AvatarImg)
    const [loggedInUserDet, setLoggedInUserDet] = useState();
    const isValidatorOff = current_user?.isValidator;
  
    const dispatch = useDispatch();
    useEffect(() => {
        setCoverImg((current_user?.coverPicture) ? getImageUrl(current_user?._id, 'userCover', current_user?.coverPicture) : false);
        setUserImg((current_user?.profilePicture) ? getImageUrl(current_user?._id, 'userProfile', current_user?.profilePicture) : (role === 'organization') ? OrgImg : AvatarImg);
       // getLoginUserDetails(loggedInUserDet);
    }, [current_user, isValidatorOff, role, validatorChange]);
    //for search
    useEffect(() => {
        if (window.location.pathname !== '/allGlobalSearchComp' && window.location.pathname !== '/allGlobalUserSearchComp' && window.location.pathname !== '/allGlobalOrgSearchComp' && window.location.pathname !== '/allGlobalEventSearchComp') {
            dispatch(setSearchText(''))
        }

    }, [window.location.pathname])


    const getLoginUserDetails = async () => {
        if (role !== 'organization') {
            const response = await getUserDetailsService();
            if (response?.data?.data) {
                dispatch(setCurrentUser(response?.data?.data));
             }
         }
    }

    const { showSmallNav, setShowSmallNav } = useContext(CommonContext);
    const active_path = window.location.pathname;
    const [showValidator, setShowValidatorPopUp] = useState(false);

    const handleNavigateFromToggle = (e) => {
        e.preventDefault();
        navigate('/validator-form', { state: {} })
    }

    // Sidebar Menus
    const userMenu = [
        {
            name: t('VoxBox'),
            link: '/voxbox',
            icon: <img src="/Assets/Newsfeed Icon-inactive.png" alt="" />,
        },
        {
            name: t('target'),
            link: '/target',
            icon: <img src="/Assets/Target-inactive.png" alt="" />,
        },
        {
            name: t('form.Events'),
            link: '/events',
            icon: <img src="/Assets/EventsIcon-inactive.png" alt="" />,
        }
    ]
    if (isValidatorOff) {
        userMenu.push({
            name: t('Events_Validate'),
            link: '/events-validate',
            icon: <img src='/Assets/Events validate.png' alt="" />,
        })
    }
    userMenu.push(
        {
            name: t('friends'),
            link: '/my-friends',
            icon: <img src="/Assets/FriendsIcon-inactive.png" alt="" />,
        },
        {
            name: t('organizations'),
            link: '/organizations',
            icon: <img src="/Assets/ORG-green.png" alt="" />,
        },
        {
            name: t('Wallet'),
            link: '/wallet',
            icon: <img src="/Assets/wallet-inactive.png" alt="" />,
        },
        {
            name: t('Dashboard'),
            link: '/dashboard',
            icon: <img src="/Assets/Dashboard-inactive.png" alt="" />,
        },
        {
            name: t('Complaint_Feedback'),
            link: '/complaint-feedback',
            icon: <img src="/Assets/complaint-feedback.png" alt="" />,
        }
    )
    if (isValidatorOff) {
        userMenu.push({
            name: t('Validator_Availibilty'),
            link: '/validator-availibility',
            icon: <img src='/Assets/clock.png' alt="" />,

        })
    }
    const orgMenu = [
        {
            name: t('VoxBox'),
            link: '/voxbox',
            icon: <img src="/Assets/Newsfeed Icon-inactive.png" alt="" />,
        }
    ]



    //Move menus according to Organization role
    if (role === 'organization' && (OrgRole === 'event_manager' || OrgRole === 'admin')) {
        orgMenu.push({
            name: t('target'),
            link: '/target',
            icon: <img src="/Assets/Target-inactive.png" alt="" />,
        },
            {
                name: t('form.Events'),
                link: '/events',
                icon: <img src="/Assets/EventsIcon-inactive.png" alt="" />,
            })
    }

    if (role === 'organization' && (OrgRole === 'admin')) {
        orgMenu.push({
            name: t('superAdmin.manage_role_heading2'),
            link: '/manage-roles',
            icon: <img src="/Assets/Manage Roles.png" alt="" />
        })
    }

    if (role === 'organization' && (OrgRole === 'admin' || OrgRole === 'user_approver')) {
        orgMenu.push({
            name: t('Followers_Employees'),
            link: '/followers',
            icon: <img src="/Assets/FriendsIcon-inactive.png" alt="" />,
        })
    }


    if (role === 'organization' && (OrgRole === 'admin')) {
            orgMenu.push(
            {
                name: t('vendors.Vendorss'),
                link: '/vendors',
                icon: <img src="/Assets/vendor-inactive.png" alt="" />,
            },
            {
                name: t('My_Payment'),
                link: '/wallet',
                icon: <img src="/Assets/wallet-inactive.png" alt="" />,
            },
            {
                name: t('Activity_Login'),
                link: '/activity-login',
                icon: <img src='/Assets/Activity Login.png' alt='' />
            },
            {
                name: t('Dashboard'),
                link: '/dashboard',
                icon: <img src="/Assets/Dashboard-inactive.png" alt="" />,
            })
            
    }
    

    if (role === 'organization' && (OrgRole === 'post_reviewer')) {
        orgMenu.push({
            name: t('superAdmin.post_review_text'),
            link: '/post-review',
            icon: <img src="/Assets/Post Review-inactive.png" alt="" />,
        })
    }
    orgMenu.push({
        name: t('Complaint_Feedback'),
        link: '/complaint-feedback',
        icon: <img src="/Assets/complaint-feedback.png" alt="" />,
    })

    const superAdminMenu = [
        {
            name: t('VoxBox'),
            link: '/voxbox',
            icon: <img src="/Assets/Newsfeed Icon-inactive.png" alt="" />,
        },
        {
            name: t('Overall_Impacts'),
            link: '/impacts',
            icon: <img src="/Assets/Overall Impact-inactive.png" alt="" />,
        },
        {
            name: t('Manage_Organisations'),
            link: '/manage-organizations',
            icon: <img src="/Assets/Manage Organizations-inactive.png" alt="" />,
        },
        {
            name: t('superAdmin.manage_user_heading'),
            link: '/manage-Users',
            icon: <img src="/Assets/Manage Users-inactive.png" alt="" />,
        },
        {
            name: t('superAdmin.reported_user'),
            link: '/reported-users',
            icon: <img src="/Assets/report_User.png" alt="" />,
        },
        {
            name: t('superAdmin.Reported_Posts'),
            link: '/manage-reported-post',
            icon: <img src='/Assets/Reported_Post.png' alt='' />
        },
        {
            name: t('CMS'),
            link: '/cms',
            icon: <img src="/Assets/cms-inactive.png" alt="" />,
        },
        {
            name: t('target'),
            link: '/target',
            icon: <img src="/Assets/Target-inactive.png" alt="" />,
        },
        {
            name: t('form.Events'),
            link: '/events',
            icon: <img src="/Assets/EventsIcon-inactive.png" alt="" />,
        },
        {
            name: t('superAdmin.post_review_text'),
            link: '/post-review',
            icon: <img src="/Assets/Post Review-inactive.png" alt="" />,
        },
        {
            name: t('My_Payment'),
            link: '/wallet',
            icon: <img src="/Assets/wallet-inactive.png" alt="" />,
        },
        {
            name: t('Activity_Logs'),
            link: '/activity-login',
            icon: <img src='/Assets/Activity Login.png' alt='' />
        },
        {
            name: t('Complaint_Feedback'),
            link: '/complaint-feedback',
            icon: <img src='/Assets/complaint-feedback.png' alt='' />
        }

    ]
    //***************** */

    const menus = (role === 'user') ? userMenu : (role === 'organization') ? orgMenu : superAdminMenu;

    const forMakingValidatorOff = async () => {
        try {
            let resp = await validatorOffForForm();
            if (resp) {
                getLoginUserDetails();
                if (window.location.pathname === '/validator-availibility') {
                    navigate('/validator-form');
                }

            }
        }
        catch (error) {
            console.log(error);
        }
    }


    const handleMarkValidatorOff = (e) => {
        swal({
            text: t('alerts.validator_off'),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
            .then((val) => {
                if (!val) return;
                forMakingValidatorOff();
            })

    }

    const handleValidatorValueChange = () => {
        //setIsValidatorOff(true);

        // showToastSuccess("validation Details Submitted Successfully!");
        // showSingleToastSuccess("validation Details Submitted Successfully!");

    }


    return (
        <>
            {showSmallNav ?
                <button className="sidebar_toggle" id="toggleBtn" onClick={() => setShowSmallNav(false)}> <ArrowForwardIosIcon /> </button>
                :
                <button className="sidebar_toggle_close" id="toggleBtn" onClick={() => setShowSmallNav(true)}> <ArrowBackIosIcon style={{ fill: '#fff' }} /> </button>
            }
            {showSmallNav && <nav id="navigation-widget" className="navigation-widget navigation-widget-desktop closed sidebar left delayed">

                <Link className="user-avatar-content sidebar_after close-sidebar-avatar" to="/profile">
                    {role !== 'user' ?
                        <>
                            <img src={userImg} alt="" className="navSettingImg for_org_area" />
                        </>
                        :
                        <>
                            <div className="poligon-green">
                                <div className="poligon-white">
                                    <div className="avatar_bg" style={{ '--background': 'url(' + userImg + ')  center/cover' }}>
                                    </div>
                                </div>
                            </div>
                            <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ visibility: 'hidden', position: 'absolute' }}>
                                                        <defs>
                                                            <filter id="round">
                                                                <feGaussianBlur in="SourceGraphic" stdDeviation="9" result="blur" />
                                                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                                                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                                                            </filter>
                                                            <filter id="roundNew">
                                                                <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                                                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                    {(isValidatorOff && role !== 'organization') ? <img className="validatorcollapseIcon"
                                                src={"/Assets/validator.png"} alt=""
                                            /> : null
                                            }
                        </>
                    }

                    <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ visibility: 'hidden', position: 'absolute' }}>
                        <defs>
                            <filter id="round">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="9" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                            </filter>
                            <filter id="roundNew">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                            </filter>
                        </defs>
                    </svg>
                </Link>

                <SidebarSmallMenu menus={menus} active={active_path} toggle={true} role={role} handleNavigateFromToggle={handleNavigateFromToggle} isValidatorOff={isValidatorOff} handleMarkValidatorOff={handleMarkValidatorOff} handleValidatorValueChange={handleValidatorValueChange()} />
            </nav>}

            {!showSmallNav && <nav id="navigation-widget" className="navigation-widget navigation-widget-desktop sidebar left delayed" data-simplebar="init">
                <div className="simplebar-wrapper">
                    <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer"></div>
                    </div>
                    <div className="simplebar-mask">
                        <div className="simplebar-offset">
                            <div className="simplebar-content-wrapper">
                                <div className="simplebar-content">

                                    <figure className="navigation-widget-cover liquid">
                                        {/* {role !== 'user'? '':<>
                                        {coverImg ? <img src={coverImg} alt="cover-01" /> : <img src="/Assets/Cover Image.png" alt="cover-01" />}
                                        </>} */}
                                    </figure>


                                    <div className={(role !== 'user') ? 'user-short-description sidebarDescription pt-0': 'user-short-description sidebarDescription pt-0 ms-3'}>
                                        {role !== 'user' ?
                                            <div className="orgSideDescription">
                                                <Link className="org_sidebar_pic orgExpandedProfilePic" to="/profile">
                                                    <img src={userImg} alt="" className="" />
                                                </Link>
                                            </div>
                                            :
                                            <Link className="" to="/profile">
                                                <div className="user-avatar-content closeSidebarAvatar">
                                                    {role !== 'user' ?
                                                        <img src={userImg} alt="" className="" style={{ width: '100px' }} />
                                                        :
                                                        <div className="poligon-green">
                                                            <div className="poligon-white">
                                                                <div className="avatar_bg" style={{ '--background': 'url(' + userImg + ')  center/cover' }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ visibility: 'hidden', position: 'absolute' }}>
                                                        <defs>
                                                            <filter id="round">
                                                                <feGaussianBlur in="SourceGraphic" stdDeviation="9" result="blur" />
                                                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                                                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                                                            </filter>
                                                            <filter id="roundNew">
                                                                <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                                                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                    {(isValidatorOff && role !== 'organization') ? <img className="validatorSidebarOff"
                                                src={"/Assets/validator.png"} alt=""
                                            /> : null
                                            }
                                                </div>
                                            </Link>}

                                        <p className={(role !== 'user') ? 'user-short-description-title orgShortDescription': 'user-short-description-title'}>
                                          {t('welcome')} {(role === 'organization') ? `${current_user?.name}` : `${current_user.firstName} `}!
                                        </p>


                                    </div>
                                    {/* <div style={{ display: "flex", alignItems: "center",marginTop:20 }}>
                                        <div style={{ flex: 1, backgroundColor: "#b2c955 ", height: "3px" }} />

                                        <p style={{ margin: "0 10px" }}>Time To Program</p>

                                        <div style={{ flex: 1, backgroundColor: "#b2c955 ", height: "3px" }} />
                                    </div> */}
                                    <SidebarExpandedMenu menus={menus} active={active_path} toggle={true} role={role} handleNavigateFromToggle={handleNavigateFromToggle} isValidatorOff={isValidatorOff} handleMarkValidatorOff={handleMarkValidatorOff} handleValidatorValueChange={handleValidatorValueChange()} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="simplebar-placeholder"></div>
                </div>
                <div className="simplebar-track simplebar-horizontal">
                    <div className="simplebar-scrollbar simplebar-visible" ></div>
                </div>
                <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}><div className="simplebar-scrollbar simplebar-visible" style={{ height: '43px', transform: ' translate3d(0px, 0px, 0px)', display: 'block' }}>
                </div>
                </div>
            </nav>
            }
        </>
    );
}

export default Sidebar;