import React from "react";
const BarsLoader = () =>{
    return (<div className="loader-bars">
    <div className="loader-bar"></div>
    <div className="loader-bar"></div>
    <div className="loader-bar"></div>
    <div className="loader-bar"></div>
    <div className="loader-bar"></div>
    <div className="loader-bar"></div>
    <div className="loader-bar"></div>
    <div className="loader-bar"></div>
  </div>)
}

export default BarsLoader;