import React, { useState, useEffect } from 'react';
import { Row,Col } from 'react-bootstrap';
import ProfileHeader from '../../../commonDashboardComp/profileHeader';
import ManageRoleList from '../../../commonDashboardComp/manageRoleList';
import Popup from "../../../commonComponents/popup";
import { Link } from "react-router-dom";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import {Box,TextField, 
  Button, 
  Select, 
  MenuItem, 
  Checkbox, 
  Stack,
  FormControlLabel, 
  InputLabel,
  FormHelperText,
  Switch,
  FormControl} from '@mui/material';
 
import { useTranslation } from "react-i18next";
  
const ManageRoles=({})=>{
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const [error,setError]=useState(false);
  const [formValues,setFormValues]=useState({userName:'',userRole:'',userRoleEnable:''});
  
  // let buttonForAddRole=<button className='btn btn-primary' onClick={(e)=>handleRole(e)} >Create Role</button>  
 function handleRole(e){
    setOpen(true);
  }
  function makeButtons(){
    return <>
          <button  className="button add_new_target"  onClick={(e)=>handleRole(e)} >{t('superAdmin.manage_role_popup_text')} </button>
          </>
   
}
  const handleSubmit = (e) => {
    e.preventDefault();
    if(formValues.userName === '' || formValues.userRole === '' )
    {
        setError(true); return;
    }
    handleClose();
    setFormValues({userName:'',userRole:'',userRoleEnable:''});
    setError(false);
  
}
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

return (
    <>
      {/* <Row> <ProfileHeader coverImg='' img='' buttons={makeButtons()}   /> </Row> */}
      <Popup heading={t('superAdmin.manage_role_popup_text')}  handleClose={handleClose} open={open}>
                <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className="form "
                onSubmit={handleSubmit}
                >
               
                {/* <TextField type='text' id="userName" name="userName" label="Enter Name" /> */}
                <TextField 
                        error={(error && formValues.userName === '')?true:false}
                        type='text'
                        helperText={( error && formValues.userName === '')?t('errors.name_required'):''}
                        value = {formValues.userName}
                        onChange = {(e)=> {setFormValues({...formValues , userName: e.target.value })}}
                        label={t('form.label_name')}/>



                <FormControl fullWidth>
                        <InputLabel id="country-select-label">{t('superAdmin.manage_role_text')}</InputLabel>
                        <Select
                                labelId="country-select-label"
                                value={formValues.userRole}
                                label={t('manager_role_dd_option')}
                                //  onChange={handleCountryChange}
                                onChange = {(e)=> setFormValues({...formValues , userRole: e.target.value })}
                            >
                            <MenuItem value="" defaultValue={true}>{t('superAdmin.manager_role_dd_option')}</MenuItem>
                            <MenuItem value="Tech Support User">{t('Tech_Support_User')}</MenuItem>
                            <MenuItem value="Canada">{t('roles.post_reviewer')}</MenuItem>
                          
                        </Select>
                        {error && formValues.userRole===''?<FormHelperText error >{t('errors.superAdmin_role_required')}</FormHelperText>:''}
                    </FormControl>
                        <div>
                          <label>{t('superAdmin.manage_role_toggle_option')}</label>
                          <Switch  defaultChecked />
                        </div>

                        <div className="form-row">
                        <div className="form-item">
                        <button className="button medium primary ">{t('superAdmin.manage_role_popup_text')}</button>
                        </div>
                    </div>
                       
                {/* <button className="btn btn-primary" onClick={(e)=>e.preventDefault()}> {t('superAdmin.manage_role_popup_text')}</button> */}
                </Box>
            </Popup>
          <Row>
           <ManageRoleList/>
          </Row>

    </>
)

}
export default ManageRoles;