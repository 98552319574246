import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "../commonComponents/popup";
import { Row, Col } from "react-bootstrap";
import Loader from "../commonComponents/Loader";
const PhotoVideoPostPopup = ({ open, handleClose, setPhoto, selectedFiles, deleteSelectedFile }) => {
    const { t } = useTranslation();
    const [isLoading, setLoader] = useState(true);
    return (
        <Popup open={open} handleClose={handleClose} heading={t('upload_images_and_videos')} className="targetPopup">
            <Row>

                {selectedFiles.length === 0 ? <div className="upload-area pe-5">
                    <Col md={12} lg={12}>
                        <label className="user_cover_upload" htmlFor="upload-cover">
                            <input type="file" id="upload-cover" multiple
                                accept="image/*,image/heif,image/heic,video/*,video/mp4,video/x-m4v,video/x-matroska,.mkv"
                                onChange={(event) => {
                                    setPhoto(event.target.files)
                                    if (event.target.files.length)
                                        setLoader(false)
                                }}
                            />
                            <div className="content">
                                <p>{t('upload_images_and_videos')}</p>
                            </div>
                        </label>
                    </Col>
                </div>
                    :
                    <div>
                        <div className="picture-collage">
                            <Row>
                                <Col>
                                    <label className="uploadChooseBtn">
                                        <input type="file" id="upload-more" multiple
                                            accept="image/*,image/heif,image/heic,video/*,video/mp4,video/x-m4v,video/x-matroska,.mkv"
                                            onChange={(event) => {
                                                setPhoto(event.target.files)
                                                if (event.target.files.length)
                                                    setLoader(false)
                                            }}
                                        />
                                    </label>
                                </Col>
                            </Row>
                            <div className="picture-collage-row voxBoxUploadContainer scrollBar">
                                {selectedFiles.map((item, index) => {
                                    return (
                                        <div className="voxBoxUploadItems" key={index}>
                                            <div className="photo-preview voxBoxUpload">
                                                {item.type.includes('video') ?
                                                    <video poster src={URL.createObjectURL(item)} alt="" onLoad={() => setLoader(false)} />
                                                    :
                                                    <img src={URL.createObjectURL(item)} alt="" onLoad={() => setLoader(false)} />
                                                }

                                                <button className="deleteImgBtn"
                                                    onClick={(e) => deleteSelectedFile(e, index)}
                                                >&times;</button>
                                            </div>
                                        </div>)
                                })}
                            </div>
                        </div>
                    </div>
                }

            </Row>
            <hr />
            <button className="button primary inviteOkBtn" onClick={handleClose}> {t('proceed')} </button>
        </Popup>
    )
}

export default PhotoVideoPostPopup;