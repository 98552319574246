import React from "react";
import { useTranslation } from "react-i18next";
import RoundRadio from "../../commonComponents/roundCheckbox/roundRadio";
import { Row, Col } from "react-bootstrap";
import { TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { CalendarMonthIcon } from "../../commonComponents/Images";
import { fromToDateValidator } from "../../utils/formatValidator";
import { frequencyTypeList, frequencyRecTypeList } from "../../config/constants";
import { changeDateFormatddmmyyyy, changeDateFormatmmddyy, getWeekDay, checkEventFrequencyAvailable, showToastError } from "../../utils/helpers";

const EventDatesStep = ({
    //formValues,
    values,
    setFormValues,
    dates,
    setDates,
    startTime,
    setStartTime,
    eventData,
    activeTarget,
    error,
    timeError,
    validatorError,
    handleFrequencyTypeHandler,
    handleRecurringPopupOpen,
    repeatEvery,
    fetchEventDatesByDateAndFrequency,
    eventdate,
    weekday,
    checkEventDateRange,
    categoryVal

}) => {
    const { t } = useTranslation();
    const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };
    const getDateTime = (item) => {

        let startDateTime = new Date(dates?.startDate);
        let startHour, startMinutes;
        [startHour, startMinutes] = new Date(item);
        startDateTime.setHours(startHour);
        startDateTime.setMinutes(startMinutes);
        return startDateTime;
    }
    return (<Row>

        <Col lg={4} className="target_title88 newEvents col-md-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                    label={t('superAdmin.Event_Start_Date')}
                    closeOnSelect={true}
                    value={dates.startDate}
                    disabled={eventData?.isPaid}
                    minDate={(activeTarget?.fromDate && activeTarget?.fromDate !== '' && (new Date() < new Date(activeTarget?.fromDate))) ? new Date(activeTarget?.fromDate).toLocaleDateString('en-US', dateOptions) : new Date()}
                    maxDate={(activeTarget?.toDate && activeTarget?.toDate !== '') ? new Date(activeTarget?.toDate).toLocaleDateString('en-US', dateOptions) : ""}
                    onChange={(newDate) => {
                        setDates({ ...dates, startDate: newDate });
                        setFormValues(prev => ({ ...prev, startTime: "" }));
                        setStartTime("")
                    }}
                    InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                    renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                    inputFormat="DD MMM YYYY"
                />
            </LocalizationProvider>
            {((error || validatorError) && (dates.startDate === '' || dates?.startDate === 'Invalid Date')) ?
                <div className="error-text">{t('superAdmin.Please_select_event_start_date')} </div> : ''}

        </Col>

        <Col lg={4} className="target_title88 newEvents col-md-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                    closeOnSelect={true}
                    label={t('superAdmin.Event_End_Date')}
                    value={dates.endDate}
                    minDate={dates.startDate !== '' ? dates.startDate : new Date()}
                    maxDate={(activeTarget?.toDate && activeTarget?.toDate !== '') ? new Date(activeTarget?.toDate).toLocaleDateString('en-US', dateOptions) : ""}
                    onChange={(newDob) => {
                        setDates({ ...dates, endDate: newDob });
                    }}
                    InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                    renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                    inputFormat="DD MMM YYYY"
                />
            </LocalizationProvider>
            {((error || validatorError) && (dates.endDate === '' || dates?.endDate === 'Invalid Date')) ?
                <div className="error-text">{t('superAdmin.Please_select_event_end_date')} </div> : ''}

            {(dates.startDate !== '' && dates.endDate !== '') ?
                !fromToDateValidator(dates.startDate, dates.endDate) ?
                    <div className="error-text">{t('superAdmin.select_correct_start_and_end_date')}</div> :
                    (activeTarget?.fromDate && activeTarget?.toDate) ?
                        !checkEventDateRange(dates.startDate, dates.endDate, activeTarget?.fromDate, activeTarget?.toDate) ?
                            <div className="grey-text">{t('superAdmin.select_start_and_end_date_between')} {changeDateFormatddmmyyyy(activeTarget.fromDate)} - {changeDateFormatddmmyyyy(activeTarget.toDate)}</div>
                            : ""
                        : ""
                : ""
            }
        </Col>

        <Col lg={4} className="newEvents col-md-6 target_title88">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                    label={t('superAdmin.Start_Time')}
                    value={startTime}
                    onChange={(newValue) => {
                        if (changeDateFormatmmddyy(new Date(dates.startDate)) === changeDateFormatmmddyy(new Date())) {
                            if (new Date() < new Date(newValue)) {
                                setFormValues(prev => ({ ...prev, startTime: newValue }));
                                setStartTime(newValue)
                            }
                        }
                        else {
                            setFormValues(prev => ({ ...prev, startTime: newValue }));
                            setStartTime(newValue)
                        }
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="HH:MM" />}
                />
            </LocalizationProvider>

            {error && values.startTime === '' &&
                <div className="error-text">{t('errors.Please_select_event_start_time')}  </div>
            }
            {timeError && (new Date() < new Date(startTime)) && <div className="error-text">{t('errors.event_time_error')}  </div>}
        </Col>

        <Col lg={6} className="event_config_box">
            <p className="formInsideHeading1 event_config_heading widget-box-title">
                <span className="highlighted ">1</span> {t('Frequency')}
            </p>
            {
                checkEventFrequencyAvailable(categoryVal) === 'monthly' ?
                    <>

                        {
                            frequencyRecTypeList.length > 0 && frequencyRecTypeList.map((item, index) =>
                                <RoundRadio name="frequenctType"
                                    key={index}
                                    label={item.label}
                                    value={item.value}
                                    checked={(values.frequency === item.value) ? true : false}
                                    onChange={(e) => (e.target.checked) ? handleFrequencyTypeHandler(e, item.value) : ''}
                                    onClick={(e) => (item.value === 'recurring') ? handleRecurringPopupOpen() : ''}
                                />
                            )}
                    </>
                    :

                    <>
                        {frequencyTypeList.length > 0 && frequencyTypeList.map((item, index) =>
                            <RoundRadio name="frequenctType"
                                key={index}
                                label={item.label}
                                value={item.value}
                                checked={(values.frequency === item.value) ? true : false}
                                onChange={(e) => (e.target.checked) ? handleFrequencyTypeHandler(e, item.value) : ''}
                                onClick={(e) => (item.value === 'recurring') ? handleRecurringPopupOpen() : ''}
                            />
                        )}
                    </>
            }

            {values?.frequency === 'one_time' &&
                <Row>
                    <Col md={6} className="datefromto">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                                closeOnSelect={true}
                                label={t('superAdmin.Start_Date')}
                                disabled={true}
                                value={dates.startDate}
                                onChange={(newDob) => {
                                    setFormValues(prev => ({ ...prev, startDate: newDob }));
                                }}
                                InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                                renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                                inputFormat="DD MMM YYYY"
                            />
                        </LocalizationProvider>
                    </Col>
                </Row>
            }
            {values?.frequency === 'recurring' &&
                <>
                    {fetchEventDatesByDateAndFrequency().length === 0 ?
                        <div className="error-text">
                            {t('superAdmin.Please_select_frequency')}
                        </div>
                        :
                        <div className="grey-text">
                            {values?.recurringType === 'daily' && repeatEvery === 1 &&
                                <>{t(`event.occursEveryDay`).replace('${day}', '').replace('${startDate}', `${changeDateFormatmmddyy(new Date(dates.startDate))}`).replace('${endDate}', `${changeDateFormatmmddyy(new Date(dates.endDate))}`)}</>
                            }
                            {values?.recurringType === 'daily' && repeatEvery !== 1 &&
                                <>{t(`event.occursEveryDay`).replace('${day}', `${repeatEvery}`).replace('${startDate}', `${changeDateFormatmmddyy(new Date(dates.startDate))}`).replace('${endDate}', `${changeDateFormatmmddyy(new Date(dates.endDate))}`)}</>
                            }
                            {values?.recurringType === 'weekly' && repeatEvery === 1 &&
                                <>{t(`event.occursEveryWeek`).replace('${day}', '').replace('${weekDay}', `${t(getWeekDay(weekday))}`).replace('${startDate}', `${changeDateFormatmmddyy(new Date(dates.startDate))}`).replace('${endDate}', `${changeDateFormatmmddyy(new Date(dates.endDate))}`)}</>
                            }
                            {values?.recurringType === 'weekly' && repeatEvery !== 1 &&
                                <>{t(`event.occursEveryWeek`).replace('${day}', `${repeatEvery}`).replace('${weekDay}', `${t(getWeekDay(weekday))}`).replace('${startDate}', `${changeDateFormatmmddyy(new Date(dates.startDate))}`).replace('${endDate}', `${changeDateFormatmmddyy(new Date(dates.endDate))}`)}</>
                            }
                            {values?.recurringType === 'monthly' && repeatEvery === 1 &&
                                <>{t(`event.occursEveryMonth`).replace('${day}', '').replace('${date}', `${eventdate}`).replace('${startDate}', `${changeDateFormatmmddyy(new Date(dates.startDate))}`).replace('${endDate}', `${changeDateFormatmmddyy(new Date(dates.endDate))}`)}</>
                            }
                            {values?.recurringType === 'monthly' && repeatEvery !== 1 &&
                                <>{t(`event.occursEveryMonth`).replace('${day}', `${repeatEvery}`).replace('${date}', `${eventdate}`).replace('${startDate}', `${changeDateFormatmmddyy(new Date(dates.startDate))}`).replace('${endDate}', `${changeDateFormatmmddyy(new Date(dates.endDate))}`)}</>
                            }

                        </div>
                    }
                </>
            }
            {/* {values?.frequency === 'ad_hoc' && 
                <Row>
                    <Col md={6} className="datefromto">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                            closeOnSelect={true}
                            label={t('superAdmin.Event_End_Date')}
                            disabled={true}
                            value={values.endDate}
                            onChange={(newDob) => {
                                setFormValues(prev => ({...prev, endDate:newDob}));
                            }}
                            InputProps={{endAdornment:<img src={CalendarMonthIcon} className="calender_icons" style={{cursor:'pointer'}}/> }}
                            renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY"/>}
                            inputFormat="DD MMM YYYY"
                            />
                        </LocalizationProvider>
                    </Col>
                </Row>
            } */}

            {error && values.frequency === '' &&
                <div className="error-text">{t('superAdmin.Please_enter_event_frequency')} </div>
            }
        </Col>
    </Row>)
}

export default EventDatesStep;