import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { IconInFormImg } from "../../../commonComponents/Images";
import { Link } from "react-router-dom";
import {languageObject } from "../../../config/constants";
import GreenCheckBox from "../../../commonComponents/greenCheckbox/greenCheckbox";
import { Country, City } from 'country-state-city';
import {
    Box, TextField,
    OutlinedInput,
    Button,
    Stack,
    FormHelperText,
    InputLabel,
    Autocomplete,
    FormControl
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { validEmail } from "../../../utils/formatValidator";
import { showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import ClearIcon from '@mui/icons-material/Clear';
import Popup from "../../../commonComponents/popup";
import { OrgCheckEmailMobileAvailabilityService, OrgVerifyEmailMobileOtpService } from "../../../services/orgAuth.services";
import PhoneInput from "react-phone-input-2";

const ContactInfo = ({ orgId, saveOrgDetails, formValues, setFormValues, error, setError, backClick }) => {
    const { t, i18n} = useTranslation();
    const [emailError, setEmailError] = useState(false);
    const [open, setOpen] = useState(false);
    const [counter, setCounter] = useState(0);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const [incorrectOTP, setIncorrectOTP] = useState(false);
    const [phonePrefix, setPhonePrefix] = useState("+91");
    const [loading, setLoading] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // const newCountryList = [];
    // Country.getAllCountries()?.filter((a) => {
    //     newCountryList.push(a?.name);
    //     return a?.name;
    // })

    const newCountryList = [];
    Country.getAllCountries()?.filter((a) => {
        if (City.getCitiesOfCountry(a?.isoCode)?.length > 0) {
            newCountryList.push(a?.name);
            if (newCountryList?.indexOf(a?.name) === -1) { newCountryList.push(a?.name); }
        }
        return a?.name;
    })

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            } else {
                clearInterval(myInterval)
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    }, [counter]);

    const initial_mail_address = {
        city: '',
        country: '',
    };
    const [errors, setErrors] = useState(false);
    const [mailingAddress, setMailingAddress] = useState(
        (formValues.mailAddress.length > 0) ?
            formValues.mailAddress :
            [initial_mail_address]);
    const cityListSamp = [];
    mailingAddress?.map((a) => {
        let cityArr = [];
        let code = Country.getAllCountries()?.find(b => b?.name === a?.country);
        City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
            cityArr.push(a?.name);
        });
        cityListSamp.push(cityArr);
    });
    const [mailCityList, setMailCityList] = useState(cityListSamp);
    const initial_corporate_address = {
        city: '',
        country: '',
    };
    const [corporateAddress, setCorporateAddress] = useState(
        (formValues.corporateAddress.length > 0) ?
            formValues.corporateAddress :
            [initial_corporate_address])


    const cityListSamp2 = [];
    mailingAddress?.map((a) => {
        let cityArr = [];
        let code = Country.getAllCountries()?.find(b => b?.name === a?.country);
        City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
            if (cityArr.indexOf(a?.name) === -1) { cityArr.push(a?.name); }

        });
        cityListSamp2.push(cityArr);
    });
    const [corpCityList, setCorpCityList] = useState(cityListSamp2);

    const addFormFields = (e) => {
        e.preventDefault();
        let cityArrCopy = [...mailCityList];
        cityArrCopy.push([]);
        setMailCityList(cityArrCopy);
        setMailingAddress([...mailingAddress, initial_mail_address]);

        setFormValues({ ...formValues, mailAddress: mailingAddress })
    }

    const removeFormFields = (i) => {
        let newFormValues = [...mailingAddress];
        newFormValues.splice(i, 1);
        setMailingAddress(newFormValues)
        setFormValues({ ...formValues, mailAddress: mailingAddress })
    }

    const handleChange = (i, e) => {
        let newFormValues = [...mailingAddress];
        newFormValues[i][e.target.name] = e.target.value;
        setMailingAddress(newFormValues);
        setFormValues({ ...formValues, mailAddress: mailingAddress })
    }

    const handleCountryChange = (i, val) => {
        let newFormValues = [...mailingAddress];
        newFormValues[i]['country'] = val;
        newFormValues[i]['city'] = '';
        let cityListCopy = [...mailCityList];
        let code = Country.getAllCountries()?.find(b => b?.name === val);
        let cityArr = [];
        City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
            if (cityArr.indexOf(a?.name) === -1) { cityArr.push(a?.name); }

        });
        cityListCopy[i] = cityArr;
        setMailCityList(cityListCopy);
        setMailingAddress(newFormValues);
        setFormValues({ ...formValues, mailAddress: mailingAddress })
    }

    const handleCityChange = (i, val) => {
        let newFormValues = [...mailingAddress];
        newFormValues[i]['city'] = val;
        setMailingAddress(newFormValues);
        setFormValues({ ...formValues, mailAddress: mailingAddress })
    }

    const handleCorporateAddressChange = (i, e) => {
        console.log(e);
        let newFormValues = [...corporateAddress];
        newFormValues[i][e.target.name] = e.target.value;
        setCorporateAddress(newFormValues);
        setFormValues({ ...formValues, corporateAddress: corporateAddress })
    }

    const handleCorporateCountryChange = (i, val) => {
        let newFormValues = [...corporateAddress];
        newFormValues[i]['country'] = val;
        newFormValues[i]['city'] = '';
        let cityListCopy = [...corpCityList];
        let code = Country.getAllCountries()?.find(b => b?.name === val);
        let cityArr = [];
        City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
            if (cityArr?.indexOf(a?.name) === -1) { cityArr.push(a?.name); }
            //cityArr.push(a?.name);
        });
        cityListCopy[i] = cityArr;
        setCorpCityList(cityListCopy);
        setCorporateAddress(newFormValues);
        setFormValues({ ...formValues, corporateAddress: corporateAddress })
    }

    const handleCityChangeCorp = (i, val) => {
        let newFormValues = [...corporateAddress];
        newFormValues[i]['city'] = val;
        setCorporateAddress(newFormValues);
        setFormValues({ ...formValues, mailAddress: mailingAddress })
    }

    const handlePhonePrefixChange = (event) => {
        setPhonePrefix(event.target.value);
    };

    const onSubmitClick = (e) => {
        e.preventDefault();

        setError(false);
        if (formValues.mobile !== '' && formValues?.mobile?.length < 10) {
            setError(true);
            return;
        }

        if (confirmation !== true) {
            setError(true);
            return;
        }


        let params = {
            organizationId: orgId,
            taxId: formValues.taxId,
            mobile: formValues.mobile,
            mailAddress: formValues.mailAddress,
            corporateAddress: formValues.corporateAddress,
            onBoarding: true,
        }
        saveOrgDetails(params);
    }

    const checkEmailAvailability = () => {
        setEmailError(false);
        if (!validEmail(formValues.email)) {
            setEmailError(true); return;
        }

        try {
            let params = { organizationId: orgId, 'email': formValues.email }
            OrgCheckEmailMobileAvailabilityService(params)
                .then((data) => {
                    if (data?.status !== 200) {
                        setEmailError(true); return;
                    }
                    // showToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`))
                    showSingleToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`))
                    setCounter(60);
                    handleOpen();
                })
                .catch((error) => {
                    setEmailError(true);
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const verifyEmail = (e) => {
        e.preventDefault();
        if (formValues.email === '' || !validEmail(formValues.email)) { return; }
        setOtpError(false);
        if (otp === '' || otp.length < 6) {
            setOtpError(true); return;
        }
        try {
            setLoading(true)
            let params = { organizationId: orgId, email: formValues.email, otp: otp }
            OrgVerifyEmailMobileOtpService(params)
                .then(async (data) => {
                    if (data.status === 200) {
                        setFormValues({ ...formValues, user_email: formValues.email, isEmailVerify: true })
                        handleClose();
                    } else {
                        setOtpError(true); setIncorrectOTP(true);
                        showToastError(t(`apiMessages.${data.data.data}`))
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    setError(true);
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        catch (error) {
            console.log(error);
            setLoading(false)
        }
        setLoading(false)
    }

    const setPhoneNum = (value) => {
        setError(formValues.mobile === '' && formValues.mobile.length < 10 && formValues.mobile.length > 13 ? true : false)
        if (formValues.mobile === '' && formValues.mobile.length < 10 && formValues.mobile.length > 13) {
            return;
        }
        setFormValues({ ...formValues, mobile: value })
        // isValid(validatePhoneNumber(event.target.value))
    }

    const handleCheckBox = (e) => {
        if (e.target.checked) {
            setConfirmation(true);
            setError(false);
        }
        else {
            setConfirmation(false);
        }
    }

    return (
        <>
            <div className="heading">
                <h1>{t('onboarding.fill_your_contact_info')}</h1>
                <h4>{t('onboarding.to_continue_sprede')}</h4>
            </div>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className="userdetail-form"
            >
                <Row>

                    <Col md={8} className="number_bottom_icon">
                        <FormControl fullWidth variant="outlined">
                            {/* <InputLabel htmlFor="outlined-adornment-password">{t('form.label_your_mobile')}</InputLabel>
                            <OutlinedInput
                                value={formValues.mobile}
                                onChange={(e) =>
                                    e.target.value.replace(/[^0-9]/g, '').length <= 10 ?
                                        setFormValues({ ...formValues, mobile: e.target.value.replace(/[^0-9]/g, '') }) : ''}
                                startAdornment={<FormControl><Select
                                    className='adornmentSelect'
                                    value={phonePrefix}
                                    onChange={handlePhonePrefixChange}
                                >
                                    <MenuItem value="+91">+91</MenuItem>
                                    <MenuItem value="+92">+92</MenuItem>
                                    <MenuItem value="+93">+93</MenuItem>
                                </Select></FormControl>}

                                label={t('form.label_your_mobile')}
                                endAdornment={<img src="/Assets/i.png" alt="#" />}
                            /> */}

                            <PhoneInput
                                localization={languageObject[i18n.language]}
                                countryCodeEditable={false}
                                label={t('Mobile')}
                                id="mobile"
                                className="userMobileInput"
                                name="mobile"
                                placeholder={t('form.label_your_mobile')}
                                native={true}
                                value={formValues.mobile}
                                defaultCountry="IN"
                                variant="outlined"
                                onChange={(newValue) => {
                                    setPhoneNum(newValue)
                                }}
                            />

                        </FormControl>
                    </Col>

                    {
                        error && formValues?.mobile !== '' && formValues?.mobile?.length < 10 &&
                        <Col md={4}>
                            <div className="error">
                                <ClearIcon /> {t('errors.enter_correct_phone')}
                            </div>
                        </Col>
                    }

                    <Col md={8} className="orgDemographicFields">
                        <TextField label={t('form.gat_vat_tax_id')}
                            value={formValues?.taxId}
                            onChange={(e) => setFormValues({ ...formValues, taxId: e.target.value })}
                            InputProps={{ endAdornment: <img src="/Assets/i.png" alt="#" /> }}
                        />
                    </Col>

                    <Col md={8} className="orgDemographicFields">

                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">{t('form.label_email')}</InputLabel>
                            <OutlinedInput
                                error={(formValues.email === '') ? true : (!validEmail(formValues.email)) ? true : false}
                                label={t('form.label_email')} icon={IconInFormImg}
                                value={formValues.email}
                                onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                endAdornment={
                                    ((formValues.email.toLowerCase() === formValues.user_email.toLowerCase() && formValues.isEmailVerify)) ?
                                        <><Button className="verify_btn verified_btn" >{t('verified')}</Button> <img src="/Assets/i.png" alt="#" /> </> :
                                        ((validEmail(formValues.email))) ?
                                            <><Button className="verify_btn" onClick={checkEmailAvailability}>{t('verify_now')}</Button> <img src="/Assets/i.png" alt="#" /> </> :
                                            <img src="/Assets/i.png" alt="#" />}
                            />
                            <FormHelperText>{(formValues.email === '') ? t('errors.email_required') : (!validEmail(formValues.email)) ? t('errors.email_format') : ''}</FormHelperText>
                        </FormControl>
                    </Col>

                    {emailError &&
                        <Col md={4}>
                            <div className="error">
                                <ClearIcon /> {t('errors.Email_is_already_taken')}
                            </div>
                        </Col>
                    }

                    <h2 className="org_info_heading"> {t('mailing_address')} </h2>

                    {mailingAddress.map((e, index) => (
                        <div key={index} style={{ position: 'relative' }} className="add_area_delete_area">
                            {
                                index ? <><hr /><div className="delete_row" onClick={() => removeFormFields(index)}><DeleteForeverIcon /></div></> : ''
                            }
                            <Row>

                                <Col md={6} className="contact-info-select-tag orgDemographicFields">

                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disablePortal
                                            value={e?.country}
                                            name="country"
                                            options={newCountryList}
                                            getOptionLabel={(option) => option}
                                            onChange={(e, val) => handleCountryChange(index, val)}
                                            renderInput={(params) => <TextField {...params} label={t('country')} />}
                                        />
                                    </FormControl>
                                </Col>

                                <Col md={6} className="contact-info-select-tag orgDemographicFields orgContactInfoField">
                                    {/* <TextField
                                        name="city"
                                        value={e.city}
                                        onChange={e => handleChange(index, e)}
                                        label={t('city')} /> */}
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disablePortal
                                            value={e?.city}
                                            name="city"
                                            options={mailCityList[index]}
                                            getOptionLabel={(option) => option}
                                            onChange={(e, val) => handleCityChange(index, val)}
                                            renderInput={(params) => <TextField {...params} label={t('city')} />}
                                        />
                                    </FormControl>
                                </Col>

                            </Row>
                        </div>
                    ))}

                    <div>
                        <button className="add-more-btn" onClick={addFormFields}> {t('add_more')} </button>
                    </div>

                    <span className="org_info_heading"> {t('corporate_hq')} </span>

                    {corporateAddress.map((e, index) => (
                        <div key={index} style={{ position: 'relative' }}>
                            <Row>
                                <Col md={6} className="contact-info-select-tag orgDemographicFields">

                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disablePortal
                                            value={e?.country}
                                            name="country"
                                            options={newCountryList}
                                            getOptionLabel={(option) => option}
                                            onChange={(e, val) => handleCorporateCountryChange(index, val)}
                                            renderInput={(params) => <TextField {...params} label={t('country')} />}
                                        />
                                    </FormControl>
                                </Col>

                                <Col md={6} className="contact-info-select-tag orgDemographicFields orgContactInfoField1">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disablePortal
                                            value={e?.city}
                                            name="city"
                                            options={corpCityList[index]}
                                            getOptionLabel={(option) => option}
                                            onChange={(e, val) => handleCityChangeCorp(index, val)}
                                            renderInput={(params) => <TextField {...params} label={t('city')} />}
                                        />
                                    </FormControl>
                                </Col>

                            </Row>
                        </div>
                    ))}

                    <div>
                        <GreenCheckBox
                            onChange={(e) => handleCheckBox(e)}
                            label={t('form.contact_confirm_checckbox')} />
                        {(error && (confirmation !== true)) ? <div className='error-text ps-4'>
                            {t('errors.required_field')}
                        </div> : ''}
                    </div>
                </Row>
                <Row>
                    <div className="back-next-btn-area contact-back-next-btn-area">
                        <Stack spacing={2} direction="row">
                            <button className="button green back-btn-new" onClick={backClick}> {t('back')} </button>
                            <button className="button secondary next-btn-new" onClick={onSubmitClick}> {t('next')} </button>
                        </Stack>
                    </div>
                </Row>
            </Box>

            <Popup heading={t('form.submit_otp')} handleClose={handleClose} modalBodyClasses="otpPopup" modalContentClasses="changePasswordPopupContent" open={open}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '98%' },
                    }}
                    noValidate
                    autoComplete="off"
                    className="userdetail-form"
                    onSubmit={(e) => verifyEmail(e)}
                >
                    <p>{t('errors.Otp_sent_on_your_email')}</p>
                    <div className="otp_counter">00:{(counter < 10) ? `0${counter}` : counter}</div>
                    <TextField
                        value={otp}
                        className="userDetailsOtpInput"
                        onChange={(e) => setOtp(e.target.value)}
                        error={(otpError && otp === '') ? true : (otpError && otp.length < 6) ? true : (otpError && incorrectOTP) ? true : false}
                        helperText={(otpError && otp === '') ? t('errors.Enter_OTP') : (otpError && otp.length < 6) ? t('errors.OTP_is_of_6digits') : (otpError && incorrectOTP) ? t('errors.otp_wrong') : ""}
                        inputProps={{ maxLength: 6 }}
                        label={t('errors.otp')} />

                    <div className="d-flex justify-content-left">
                        {counter === 0 &&
                            <Link className="ps-2" to="#"
                                onClick={(e) => { e.preventDefault(); checkEmailAvailability() }}>
                                {t('errors.Resend_Otp')}
                            </Link>
                        }
                    </div>

                    <button disabled={loading} className="spredeSubmitBtn mt-5">
                        {loading ? t('errors.Please_Wait') : t('form.submit')}
                    </button>
                </Box>
            </Popup>
        </>
    )
}

export default ContactInfo;