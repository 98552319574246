import React, { useEffect, useState, useRef } from "react";
import { AvatarImg } from "../commonComponents/Images";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAllFriendsAddedList, postDeleteOrCancelRequest } from "../services/friends.service";
import { getImageUrl, showSingleToastSuccess, showToastError, showToastSuccess } from "../utils/helpers";
import AvatarImgBox from "../commonComponents/AvatarImgBox/avatarImgBox";
import { InterestedListService, ManageEventParticipantsService } from "../services/event.services";

const InterestedPeople = ({ heading, eventId, searchItem, toShowInviteIcon }) => {
  const observer = useRef();
  const { t } = useTranslation();
  let defaultUserImage = '/Assets/Img.png';
  const n = 1;
  const [listOfIntereted, setListOfIntereted] = useState([]);
  const [totalPeople, setTotalPeople] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [PageNo, setPageNo] = useState(0);
  const limit = 5;
  const lastElement = (node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((enteries) => {
      if (enteries[0].isIntersecting && hasMore) {
        setPageNo((prev) => prev + 1);
      }
    })
    if (node) {
      observer.current.observe(node);
    }
  }
  useEffect(() => {
    setListOfIntereted([]);
    setPageNo(0);
  }, [searchItem]);

  useEffect(() => {
    getInterestedPeople(PageNo);
  }, [searchItem, PageNo])

  const getInterestedPeople = async (currentPage) => {
    setLoading(true);
    const response = await InterestedListService(eventId, currentPage, limit, searchItem)
    if (response?.data?.data) {
      const user = response?.data?.data?.data;
      const user2 = user.map(a => ({ ...a, interestIS: false }));
      let newInterested = [...listOfIntereted, ...user2];
      if (PageNo === 0) newInterested = [...user2]
      let newArr = newInterested.reduce((acc, curr) => {
        let fnd = acc.find((a) => a._id === curr?._id);
        if (!fnd) acc.push(curr);
        return acc;
      }, []);
      newArr = newArr?.filter(a => !a?.interestIS);
      setHasMore(response?.data?.data?.data?.length > 0);
      setListOfIntereted(newArr);

    }
    setLoading(false);
  }

  const sendInvite = (e, userDet, index1) => {
    e.preventDefault();
    let params = {
      eventId: eventId,
      userId: userDet?._id,
      action: 'request'
    }
    ManageEventParticipantsService(params)
      .then((data) => {
        if (data?.data?.status === 200) {
          const nextList = [...listOfIntereted];
          let index = nextList?.findIndex(a => a._id === userDet?._id);
          if (index >= 0) {
            nextList[index]['interestIS'] = true;
            setListOfIntereted(nextList);
            let pageNo1 = Math.floor(index1 / limit);
            setPageNo(pageNo1);
            getInterestedPeople(pageNo1)
            listOfIntereted?.splice(index, 1);
            showSingleToastSuccess(t(`apiMessages.INVITE_SENT_SUCCESSFULLY`));

          }
          // setShow(false)

        }
        else {
          showToastError(t(`errors.some_error`));
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  return (

    <div className="widget-box interested_people_area">

      <p className="widget-box-title fewFriendsHeading">{heading ? heading : t('friends')} <span className='friendsCountText'></span></p>
      {
        listOfIntereted?.length > 0 ? <>
          <div className="widget-box-content Interestedscroller">
            <div className="user-status-list interestedPeople">

              {listOfIntereted.map((item, index) => {
                if (listOfIntereted?.length == index + 1) {
                  return <div className="d-flex align-items-center" key={index} ref={lastElement}>
                    <div className="invite_people_img">
                    <AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : defaultUserImage} />

                    </div>
                    <div className="invite_people_text">
                      <h6 to="/friends">{(`${item?.firstName} ${item?.lastName}`) || ''}</h6>
                      <p>{item?.totalMutualFriend} {t('friends_in_common')}</p>
                    </div>


                    {toShowInviteIcon ?
                      <div className="action-request-list mail_icons_box">
                        <div className="action-request accept">
                          {/* <InsertEmoticonIcon className="action-request-icon" /> */}
                          <button className="box-btn" onClick={(e) => sendInvite(e, item, index)}> <img src="/Assets/invite.png" /></button>
                        </div>
                      </div>
                      : ""}
                  </div>
                }
                else {
                  return <div className="d-flex align-items-center" key={index} >
                    <div className="invite_people_img">
                    <AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : defaultUserImage} />

                    </div>
                    <div className="invite_people_text">
                      <h6 to="/friends">{(`${item?.firstName} ${item?.lastName}`) || ''}</h6>
                      <p>{item?.totalMutualFriend} {t('friends_in_common')}</p>
                    </div>


                    {toShowInviteIcon ?
                      <div className="action-request-list mail_icons_box">
                        <div className="action-request accept">
                          <button className="box-btn" onClick={(e) => sendInvite(e, item, index)}> <img src="/Assets/invite.png" /></button>
                        </div>
                      </div>
                      : ""}
                  </div>
                }

              })}

            </div>
          </div>
        </> : <h6 className="my-2">
          {t('no_interested_users')}
        </h6>
      }

    </div>

  )
}

export default InterestedPeople;