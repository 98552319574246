import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './cfStyle.css';
import { getImageUrl } from '../../../utils/helpers';
import { UseTranslationOptions, useTranslation } from 'react-i18next';
import { useState } from 'react';
import AvatarImgBox from '../../../commonComponents/AvatarImgBox/avatarImgBox';
import { getAnyUserData } from '../../../services/friends.service';
import ShowVideoPopUp from '../../../commonDashboardComp/showVideoPopUp';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
const ComplaintFeedbackBox = ({
  requestImage,
  requestType,
  requestHeading,
  requestDescription,
  requestId,
  creatorId,
  createdAt,
  requestVideo,
}) => {
  let defaultUserImage = '../../../Assets/Img.png';
  const [viewMore, setViewMore] = useState(false);
  const { t } = useTranslation();
  let createdDate = new Date(createdAt);
  const [videoOpen, setVideoOpen] = useState(false);
  const [imageGetData, setImageGetdata] = useState();
  const handleVideoClose = () => setVideoOpen(false);
  const handleVideoOpen = () => {
    let videoData = {
      id: creatorId,
      type: 'feedback',
      param: requestVideo[0],
    };
    setImageGetdata(videoData);
    setVideoOpen(true);
  };
  return (
    <div className="complaintFeedbackBox">
      <Row className="bg-white rounded-4 p-3 my-3 align-items-center">
        <Col lg={1}>
          {requestImage != null && requestImage.length !== 0 ? (
            <img
              src={
                requestImage != null && requestImage.length !== 0
                  ? getImageUrl(creatorId, 'feedback', requestImage[0])
                  : ''
              }
              alt=""
              className="img-fluid rounded"
            />
          ) : requestVideo != null && requestVideo.length !== 0 ? (
            <video
              poster
              src={getImageUrl(creatorId, 'feedback', requestVideo[0])}
              alt=""
              className="img-fluid w-100"
            />
          ) : (
            <p>N.A</p>
          )}
        </Col>
        <Col lg={2}>
          <p className="itemTitle">{t('complaintFeedback.headingOption1')}:</p>
          <p className="itemInfo">{requestType}</p>
        </Col>
        <Col lg={3}>
          <p className="itemTitle">{t('complaintFeedback.headingOption4')}:</p>
          <p className="itemInfo">{requestHeading}</p>
        </Col>
        <Col lg={4}>
          <p className="itemTitle">{t('complaintFeedback.headingOption5')}:</p>
          <p className="itemInfo">
            {requestDescription.substring(0, 50) + '...'}
          </p>
        </Col>
        <Col lg={2} className="text-end">
          <button
            className="viewMore"
            onClick={(e) => {
              e.preventDefault();
              setViewMore(!viewMore);
            }}
          >
            {viewMore ? t('button.view_less') : t('button.view_more')}
          </button>
        </Col>
      </Row>
      {viewMore && (
        <>
          <Row className="bg-white mt-3 p-4 rounded-4">
            <Col lg={6}>
              <div className="cfImageContainer">
                {requestImage != null && requestImage.length !== 0 ? (
                  <img
                    src={
                      requestImage.length !== 0
                        ? getImageUrl(creatorId, 'feedback', requestImage[0])
                        : ''
                    }
                    alt=""
                    className="img-fluid rounded"
                  />
                ) : (
                  ''
                )}
                {requestVideo != null && requestVideo.length !== 0 ? (
                  <div className='videoContainer'>
                    <button>
                      <PlayCircleIcon/>
                    </button>
                    <video
                    poster
                    src={getImageUrl(creatorId, 'feedback', requestVideo[0])}
                    alt=""
                    onClick={handleVideoOpen}
                    className="img-fluid w-100"
                  />
                  </div>
                  
                ) : (
                  ''
                )}
              </div>
            </Col>
            <Col lg={6}>
              <p className="fs-1">{requestHeading}</p>
              <p className="mt-3">{requestDescription}</p>
              <p className="mt-3">{t('complaintFeedback.headingOption1')}: {requestType}</p>
              {/* <div className="d-flex align-items-center">
                <p className="mt-3">By:</p>
                <AvatarImgBox>
                  avatarClasses = {'profileAvatarBox'}
                  img={defaultUserImage}
                </AvatarImgBox>
              </div> */}
              <p className="mt-3">
                {t('complaintFeedback.creationLabel')}: {createdDate.toLocaleDateString()}
              </p>
            </Col>
          </Row>
          <ShowVideoPopUp
            videoGetData={imageGetData}
            open={videoOpen}
            handleClose={handleVideoClose}
          />
        </>
      )}
    </div>
  );
};

export default ComplaintFeedbackBox;
