import React, { Fragment, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { getAllFriendsAddedList, getUsersBySearchRequest, postDeleteOrCancelRequest } from "../../../services/friends.service";
import { listClasses } from "@mui/material";
import { useTranslation } from "react-i18next";
import { showSingleToastSuccess, showToastError, showToastSuccess } from "../../../utils/helpers";
import PhotosPopup from "../../../commonDashboardComp/photoesPopup";
import { useNavigate } from "react-router-dom";
import { uploadFileService } from "../../../services/fileUpload.services";
import { uploadEvidenceService } from "../../../services/validatorPost.service";
import moment from "moment";
import { t } from "i18next";
import {
    Box,
    TextField,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    Button,
    MenuItem,
    FormHelperText
} from '@mui/material';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../Reducer/user/user.selector";
import RoundRadio from "../../../commonComponents/roundCheckbox/roundRadio";
import GreenCheckBox from "../../../commonComponents/greenCheckbox/greenCheckbox";
import { getEventDetailByDate, getEventDetailService, markingEvent } from "../../../services/event.services";
const EventActivityList = ({ event, creater , updated, setUpdated, getEventDetail}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [dateOfUpload, setDateOfUpload] = useState(null)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const user = useSelector(selectCurrentUser);
    const [dates, setDates] = useState(event?.eventDates);
    const [selDate, setSelDate] = useState('');
    const [showMoreAndLess,setShowMoreAndLess]=useState(false);
    const [eventDetail, setEventDetail] = useState(null);
    const setPhoto = async (param) => {
        if (param.length !== 0) {
            setSelectedFiles([...selectedFiles, ...param]);
        }
    }
    useEffect(() => {
        setEventDetail(event);
    }, [event, creater])

    // const getEventDetail = () => {
    //     getEventDetailService(event?._id)
    //         .then((data) => {
    //             if (data?.data?.status === 200) {
    //                 setEventDetail(data?.data?.data);
                    
    //             }
    //             else {

    //             }
    //         })
    // }

    const deleteSelectedFile = (e, i) => {
        e.preventDefault();
        let arr = selectedFiles;
        let filter = arr.filter((item, index) => { return index !== i });
        setSelectedFiles(filter)
    }

    const handlleOpenPopUp = (e) => {
        e.preventDefault();
        setOpen(true)
    }
    const handleChange = (e) => {
        e.preventDefault();
        setSelDate(e.target.value);
    }

    const closeAndSubmitPhotoes = () => {
        setOpen(false);
        try {
            const totalEvidence = [];
            if (selectedFiles.length > 0) {
                selectedFiles.map((item, index) => {
                    let obj = {};
                    if (item.type.includes('video')) {
                        obj['attachment'] = item?.lastModified;
                        obj['attachmentType'] = 'video';
                    }
                    if (item.type.includes('pdf')) {
                        obj['attachment'] = item?.lastModified;
                        obj['attachmentType'] = 'application/pdf';
                    }
                    if (item.type.includes('image')) {
                        obj['attachment'] = item?.lastModified;
                        obj['attachmentType'] = 'image';
                    }
                    totalEvidence.push(obj);
                })
            }
            let Params = {
                date: dateOfUpload,
                eventId: event?._id,
                evidence: totalEvidence
            }
            if (selectedFiles?.length > 0) {
                uploadEvidenceService(Params)
                    .then(async (data) => {
                        if (data?.data?.status === 200) {
                            // getEventEvidenceDetail(); 
                            if (selectedFiles.length > 0) {
                                let result = await uploadFiles(data?.data?.data?.insertedId);
                            }
                            // showToastSuccess(t(`apiMessages.EVIDENCE_UPLOADED_SUCCESS`));
                            showSingleToastSuccess(t(`apiMessages.EVIDENCE_UPLOADED_SUCCESS`));
                        }
                        else {
                            showToastError(t(`apiMessages.${data?.data?.data}`))
                        }

                    })
                    .catch((error) => {
                        showToastError(t(`apiMessages.${error?.data?.data}`))
                    })
            }
        }
        catch (error) { showToastError(t(`apiMessage.SOME_THING_WENT_WRONG`)) }
    }

    const uploadFiles = async (id) => {
        selectedFiles.map(async (item) => {
            if (item.type.includes('video')) {
                await uploadFileService('eventEvidence', 'event', item.lastModified, item, user?._id)
            }
            if (item.type.includes('image')) {
                await uploadFileService('eventEvidence', 'event', item.lastModified, item, user?._id)
            }
        })

        return true;
    }
    const handleStepsMark = async (e, step, task) => {
        //new Date(data).getTime()<new Date().getTime()
        e.preventDefault();
        if(task?.isMarked){
        //   showToastError("");
        }
        else{
        let param = {
            eventId: eventDetail?._id,
            markType: 'stepsMark',
            markId: step?._id
        }
        param['status'] = e.target.checked;
        markingEvent(param)
            .then(async (resp) => {
                if (resp?.status == 200) {
                    await getEventDetail();
                    setUpdated(!updated)
                }
            })
            .catch((error) => {
                console.log(error);
            })
        }


    }
    const handleTaskMark = (e, task, wholeActivity) => {
        let param = {
            eventId: eventDetail?._id,
            markType: 'tasksMark',
            markId: task?._id
        }
        if (wholeActivity) {
            param['status'] = wholeActivity;
        }
        else {
            e.preventDefault();
            param['status'] = e.target.checked;
        }
        markingEvent(param)
            .then(async(resp) => {
                if (resp?.status == 200) {
                    await getEventDetail();
                    setUpdated(!updated)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const markingOneActivity = (e, activity) => {
        let params = {
            eventId: eventDetail?._id,
            markType: "activityMark",
            status: e.target.checked,
            markId: activity?._id
        }
        markingEvent(params)
            .then(async(resp) => {
                if (resp?.status == 200) {
                    //getEventDetail();
                    if (e.target.checked === false) {
                        // for (let i = 0; i < activity?.steps?.length; i++) {
                        //     let step = activity?.steps[i]?.isMarked;
                        //     if (!step) {
                        //         handleStepsMark('', activity?.steps[i], true)
                        //     }
                        // }
                        for (let i = 0; i < activity?.tasks?.length; i++) {
                            let task = activity?.tasks[i]?.isMarked;
                            if (!task) {
                                handleTaskMark('', activity?.tasks[i], true)
                            }
                        }
                    }
                    await getEventDetail();
                    setUpdated(!updated)

                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const navigateToUpload = (e) => {
        e.preventDefault();
    }
    const markingAllActivity = (e) => {
        e.preventDefault();
        let param = {
            eventId: eventDetail?._id,
            markType: "activityAllMark",
            status: e.target.checked,
            "markId": ""
        }
        markingEvent(param)
            .then(async (resp) => {
                if (resp?.status == 200) {
                    await getEventDetail();
                }
            })
            .catch((error) => {
                console.log(error);
            })


    }
    return (
        <>
            <div className="widget-box orgBlock">
                <div className="widget-box-content newTargetTag">
                    <div className="evidenceUpload">
                        <div className="evidenceHeading">{t('Event_Activities')}</div>
                        <div className="widget-box-content">
                            <h5 className='evidenceSubHeading'>{t('Event_Have')}:</h5>
                            <RoundRadio name="acitivityType"
                                label={eventDetail?.activityId?.activity?.length === 1 ? t('single_activity') :eventDetail?.activityId?.activity?.length >1? t('multiple_activity'):''}
                                checked={true}
                                readOnly
                            />
                            {
                                eventDetail?.activityId?.isCompleted && <> {t('marking_whole_activity')}::<GreenCheckBox checked={eventDetail?.activityId?.isCompleted} onChange={(e) => markingAllActivity(e)} /> </>
                            }
                            {eventDetail?.activityId?.activity?.length > 0 && eventDetail?.activityId?.activity?.map((item, index) => {
                                return <Fragment key={index}>
                                    {eventDetail?.activityId?.activity?.length===1 ?<>
                                    <h4 className='evidenceSubHeading mt-10'> {t('activity')}  {index + 1}<span className="EventCompletion-status"> ({t('Event_Completion_Status')})</span></h4>
                                    <Row key={index} className='grid my-2 eventDate'>
                                        <Col md={12}>
                                            <Row className='mb-20'>
                                                <Col md={10} >
                                                    <TextField value={item?.title} readOnly label={t('activity_title')} />
                                                </Col>
                                                <Col md={2} className="pt-15">  <GreenCheckBox checked={item?.isCompleted} disabled={!creater|| creater && event?.status==='verified'} onChange={(e) => markingOneActivity(e, item)} /></Col>
                                            </Row>
                                        </Col>
                                        {item?.tasks.map((tsk, index2) => {
                                            return (tsk?.title !== '') && <>
                                              
                                                        <Col md={10}><TextField value={tsk?.title} readOnly label={t('task_title')} /></Col>
                                                        <Col md={2} className="pt-15"> <GreenCheckBox checked={tsk?.isMarked} disabled={item?.isCompleted && !creater} onChange={(e) => { creater && handleTaskMark(e, tsk) }} /></Col>

                                                        {tsk?.steps.map((s, index3) => {
                                            return <Col md={12} className="mt-4"><Row key={index3}>
                                                <Col md={10}> <TextField value={s?.title} readOnly label={t('step_title')} /></Col>
                                                <Col md={2} className="pt-15"> <GreenCheckBox checked={s?.isMarked} disabled={item?.isCompleted && !creater} onChange={(e) => { creater && handleStepsMark(e, s,tsk) }} /></Col>
                                            </Row></Col>
                                        })}   
                                                    
                                            </>
                                        })}
                                        {/* {item?.steps.map((s, index2) => {
                                            return <Col md={12}><Row key={index2} className='my-1 mb-20'>
                                                <Col md={10}> <TextField value={s?.title} readOnly label={t('step_title')} /></Col>
                                                <Col md={2} className="pt-15"> <GreenCheckBox checked={s?.isMarked} disabled={item?.isCompleted && !creater} onChange={(e) => { creater && handleStepsMark(e, s) }} /></Col>
                                            </Row></Col>
                                        })} */}
                                    </Row>
                                    </>
                                      :
                                      <>
                                    {((index===0 ) || showMoreAndLess) &&<>
                                    <h4 className='evidenceSubHeading mt-10'> {t('activity')}  {index + 1}<span className="EventCompletion-status"> ({t('Event_Completion_Status')})</span></h4>
                                    <Row key={index} className='grid my-2 eventDate'>
                                        <Col md={12}>
                                            <Row className='mb-20'>
                                                <Col md={10} >
                                                    <TextField value={item?.title} readOnly label={t('activity_title')} />
                                                </Col>
                                                <Col md={2} className="pt-15">  <GreenCheckBox checked={item?.isCompleted} disabled={!creater|| creater && event?.status==='verified'} onChange={(e) => markingOneActivity(e, item)} /></Col>
                                            </Row>
                                        </Col>

                                        {item?.tasks.map((tsk, index2) => {
                                            return <>
                                                <Col md={12} className="mt-3">
                                                    <Row key={index2} className='my-1 mb-20'>
                                                        <Col md={10}><TextField value={tsk?.title} readOnly label={t('task_title')} /></Col>
                                                        <Col md={2} className="pt-15"> <GreenCheckBox checked={tsk?.isMarked} disabled={item?.isCompleted} onChange={(e) => { creater && handleTaskMark(e, tsk) }} /></Col>
                                                        {tsk?.steps.map((s, index3) => {
                                            return <Col md={12}><Row key={index3} className='my-1 mb-20 mt-4'>
                                                <Col md={10}> <TextField value={s?.title} readOnly label={t('step_title')} /></Col>
                                                <Col md={2} className="pt-15"> <GreenCheckBox checked={s?.isMarked} disabled={item?.isCompleted && !creater} onChange={(e) => { creater && handleStepsMark(e, s,tsk) }} /></Col>
                                            </Row></Col>
                                        })}   
                                                    </Row>
                                                </Col>
                                            </>
                                        })}
                                      
                                    </Row>
                                    </>}
                                    </>
                                      }

                                </Fragment>

                            })}
                    {eventDetail?.activityId?.activity?.length > 1 &&<>{(!showMoreAndLess)?<button className="showMoreBtn" onClick={()=>setShowMoreAndLess(true)}>Show More</button>:<><button className="showMoreBtn" onClick={()=>setShowMoreAndLess(false)}>Show less</button></>}</>}
 
                        </div>
                    </div>

                    {/* {creater &&
                        <button className="button primary uploadEvidence" onClick={(e) => {
                            e.preventDefault();
                            navigate('/validate-event', { state: { eventId: eventDetail?._id, creater: true } })
                        }}> Upload Evidence <img src="/Assets/upload-inactive-icon.png" className='imageInfindFriend' /> </button>
                    } */}

                </div>
            </div>
           
        </>
    )

}

export default EventActivityList;