import React from "react";
import { Row ,Col} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { timeFormat, dayDateFormat, dateTimeEventFormat } from "../utils/helpers";
const EventsHeader = ({eventDetail,widgetBoxClasses}) => {
    const {t} = useTranslation();
    return (
    <div className={`widget-box ${widgetBoxClasses}`}>
      <Row>
        <Col md={4}>
         
          <h4 className="text-capitalize">{eventDetail?.title}</h4>
          {/* <p className="eventTarget"><span>{t('superAdmin.target_id_text')}</span> : {eventDetail?.targetId}</p> */}
        </Col>
        <Col md={8}>
          <div className="dateLocationPrice">
            <div className="calendarBlk">
              <div className="calenderIconBlk">
                <div className="imgBlk">
                  <img src={'Assets/userCalendar.png'} />
                </div>
                <div className="imgConter">
                  {dayDateFormat(eventDetail?.startDate)}
                  <br />
                  <span>{timeFormat(dateTimeEventFormat(eventDetail?.startDate,eventDetail?.startTime))}</span>
                </div>
              </div>
            </div>
            <div className="locationBlk">
              <img src={'Assets/Pin-Icon-target.png'} />
              {eventDetail?.location} 
              , {eventDetail?.country}
            </div>
            <div className="priceBlk">
              <img src={'Assets/Ticket Icon.png'} />
              {eventDetail?.amount ? 
              <>{eventDetail?.currency} {eventDetail?.amount} </>
              :
              <>{t('free')}</>}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default EventsHeader;