import React from "react";
import { useTranslation } from "react-i18next";
import RoundRadio from "../../commonComponents/roundCheckbox/roundRadio";
import RoundCheckbox from "../../commonComponents/roundCheckbox";
import { Row, Col } from "react-bootstrap";
import { TextField, Autocomplete } from "@mui/material";
import { allowOnlyLetters } from "../../utils/helpers";
import { privateParticipationModeList, publicParticipationModeList, paymentModeList, currencyList, currencyData, incentivesList, giftsList } from "../../config/constants";

const EventConfigurationStep = ({
    //formValues,
    values,
    setFormValues,
    error,
    handleIncentiveGiftChange,
    otherGift,
    setOtherGift
}) => {
    const { t } = useTranslation();
    const setCurrency = (val) => {
        for (let key in currencyData) {
            if (currencyData[key].name == val) {
                setFormValues(prev => ({ ...prev, currency: val, currencyCode: currencyData[key].code }));
            }
        }
        // setFormValues(prev => ({...prev, currency:val}));
    }
    return (<Row>

        <Col md={6} className="event_config_box">
            <p className="formInsideHeading1 event_config_heading widget-box-title">
                <span className="highlighted ">1</span> {t('superAdmin.Mode_Of_Participation')}
            </p>
            {values?.type === 'private' ?
                <>
                    {privateParticipationModeList.length > 0 && privateParticipationModeList.map((item, index) =>
                        <RoundRadio
                            name="participationMode"
                            key={`privateParticipationModeList` + index}
                            label={item.label}
                            value={item.value}
                            checked={(values.modeOfParticipation === item.value) ? true : false}
                            onChange={(e) => (e.target.checked) ? setFormValues(prev => ({ ...prev, modeOfParticipation: item.value })) : ''} />
                    )}
                </>
                :
                <>
                    {publicParticipationModeList.length > 0 && publicParticipationModeList.map((item, index) =>
                        <RoundRadio
                            name="participationMode"
                            key={`publicParticipationModeList` + index}
                            label={item.label}
                            value={item.value}
                            checked={(values.modeOfParticipation === item.value) ? true : false}
                            onChange={(e) => (e.target.checked) ? setFormValues(prev => ({ ...prev, modeOfParticipation: item.value })) : ''} />
                    )}
                </>
            }

            {error && values.modeOfParticipation === '' &&
                <div className="error-text" >{t('superAdmin.Please_enter_event_participation')} </div>
            }
        </Col>

        <Col lg={6} className="event_config_box">
            <p className="formInsideHeading1 event_config_heading widget-box-title">
                <span className="highlighted">2</span> {t('superAdmin.Payment')}
            </p>

            {paymentModeList.length > 0 && paymentModeList.map((item, index) => {
                return (
                    <RoundRadio name="paidType"
                        onChange={(e) => (item.value === "paid") ? setFormValues(prev => ({ ...prev, isPaid: true })) : setFormValues(prev => ({ ...prev, isPaid: false, amount: 0 }))}
                        checked={(item.value === "paid") ? values.isPaid : !values.isPaid}
                        key={`paidType` + index} label={item.label} value={item.value} />
                )
            })}
            <br />

            {values?.isPaid &&
                <>
                    <RoundCheckbox
                        // label={t('other')}
                        other={true}
                        checkBoxClasses="addEventCurrencyInput"
                        checked={(values.isPaid) ? true : false}
                        input={
                            <>
                                <Autocomplete
                                    disablePortal
                                    value={values.currency}
                                    options={currencyList}
                                    getOptionLabel={(option) => option || ""}
                                    onChange={(e, val) => {
                                        setCurrency(val)
                                    }}
                                    renderInput={(params) => <TextField {...params} label={t('form.currency')} />}
                                />
                            </>
                        }
                    />
                    <RoundCheckbox
                        // label={t('other')}
                        other={true}
                        checkBoxClasses="addEventAmountInput"
                        checked={(values.isPaid) ? true : false}
                        input={
                            <>
                                <input type="text"
                                    value={values.isPaid ? values.amount : ''}
                                    onChange={(e) => { !isNaN(e.target.value) && setFormValues(prev => ({ ...prev, isPaid: true, amount: e.target.value })) }}
                                    className="checkbox_text_feild" placeholder={t('superAdmin.Enter_amount')} />
                            </>
                        }
                    />
                </>
            }

            {error && values?.isPaid !== false && (values?.currency === '' || values?.amount === '') ?
                <div className="error-text" >
                    {t('errors.event_currency_amount')}
                </div>
                :
                error && values?.isPaid !== false && (parseInt(values?.amount) === 0) ?
                    <div className="error-text" >
                        {t('errors.event_amount_cant_be_zero')}
                    </div>
                    : ''
            }

        </Col>

        <Col lg={6} className="event_config_box">
            <p className="formInsideHeading1 event_config_heading widget-box-title">
                <span className="highlighted">3</span> {t('incentive')}
            </p>

            {incentivesList.length > 0 && incentivesList.map((item, index) =>
                <RoundRadio
                    name="incentive"
                    key={`incentivesList` + index}
                    label={item.label}
                    value={item.value}
                    checked={(values.incentive === item.value) ? true : false}
                    onChange={(e) => (e.target.checked) ? setFormValues(prev => ({ ...prev, incentive: item.value })) : ''}
                />
            )}

            {error && values.incentive === '' &&
                <div className="error-text" >
                    {t('errors.target_incentive_required')}
                </div>
            }

            {values.incentive === 'incentivized' && <div>
                {giftsList.length > 0 && giftsList.map((item, index) =>
                    <RoundRadio
                        key={`giftsList` + index}
                        name="gift"
                        label={item.label}
                        value={item.value}
                        checked={(values.incentivePrize === item.value) ? true : false}
                        onChange={(e) => handleIncentiveGiftChange(e, item.value)}
                    />
                )}
                {error && values.incentivePrize === '' &&
                    <div className="error-text" >
                        {t('errors.target_incentive_required')}
                    </div>
                }
                <RoundCheckbox
                    label={t('other')}
                    other={true}
                    checkBoxClasses={(otherGift)?'otherActive':''}
                    input={
                        <input type="text"
                            value={otherGift}
                            onChange={(e) => {
                                setFormValues(prev => ({ ...prev, incentivePrize: allowOnlyLetters(e.target.value) }))
                                setOtherGift(allowOnlyLetters(e.target.value))
                            }
                            }
                            className="checkbox_text_feild" placeholder={t('form.Enter_other_prize')} />}
                />
            </div>}
        </Col>

        {/*  DON't REMOVE MAY BE NEED TO ENABLE THIS IN FUTURE
        <Col md={6} lg={6}> 
            <p className="formInsideHeading1"> 
            <span className="highlighted">4</span> Complexity 
            </p>

            {complexityTypeList.length >0 && complexityTypeList.map((item, index) => 
                <RoundRadio 
                name="complexity" 
                key={index} 
                label={item.label} 
                value={item.value}
                checked={(values.complexity===item.value) ? true : false}
                onChange={(e)=>(e.target.checked) ? setFormValues(prev => ({...prev, complexity: item.value })) : ''}
                />
            )}
        </Col> */}

    </Row>)
}

export default EventConfigurationStep;