import { Autocomplete, FormControl, TextField } from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState,useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Popup from "../../../commonComponents/popup";
import { timezone } from "../../../config/constants";
import moment from "moment";
import { getEventDetailByDate, postValidatorInfo } from "../../../services/event.services";
import { showSingleToastSuccess } from "../../../utils/helpers";
import { setValidatorChange } from "../../../Reducer/user/user.action";
import { useDispatch, useSelector } from "react-redux";
import { selectValidatorChange } from "../../../Reducer/user/user.selector";

const EditAvailibityPopUp= ({open, handleClose,dateForEdit,handleAgainAfterUpdate}) => {
    const {t} = useTranslation();
    const [availData,setAvailData]=useState();
    const [dateCAL, setDateCAL] = useState('');
    const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };
    const dispatch = useDispatch();
    const validatorChange = useSelector(selectValidatorChange)
    const validatorDataSelected = {
        "date": "",
        "startTime": "",
        "endTime": "",
        "timezone": "",
    }
    const [validatorOneTimeData, setValidatorOneTimeData] = useState(validatorDataSelected);
    const [eventStartTime, setEventStartTime] = useState('');
    const [validatorError, setValidatorError] = useState({});
    const [eventEndTime, setEventEndTime] = useState('');
    const [formValues,setFormValues]=useState();

    useEffect(()=>{
        getBasicValidatorData();
    },[dateForEdit])


    const getBasicValidatorData=async()=>{
        //let dateToday = moment(new Date, 'DD-MM-YYYY').format();
       await getEventDetailByDate(dateForEdit).
            then((resp) => {
                if (resp?.status === 200) {
                    setFormValues(resp?.data?.data);
                    setEventStartTime(resp?.data?.data?.validatorDetail[0]?.startTime);
                    setEventEndTime(resp?.data?.data?.validatorDetail[0]?.endTime);
                    setValidatorOneTimeData(resp?.data?.data?.validatorDetail[0])
                }
            })
    }

    const handleChangeTimeZone = (e, id) => {
        const validatorOneTimeDataCopy = { ...validatorOneTimeData };
        if ( id === 'timezone') {
            validatorOneTimeDataCopy[id] = e.target.innerText;
            let validatorErrorCopy = { ...validatorError, timezone: e.target.innerText?false:true  };
            setValidatorError(validatorErrorCopy);
            setValidatorOneTimeData(validatorOneTimeDataCopy);
        }
        else {
            validatorOneTimeDataCopy[id] = e.target.value;
        }
        
       
    }

    const manageEventTime = (time, id) => {
        const copyValidorOneTimeData = { ...validatorOneTimeData };
        copyValidorOneTimeData[id] = time;
        setValidatorOneTimeData(copyValidorOneTimeData);
        let dt = moment(copyValidorOneTimeData?.startTime?.$d?copyValidorOneTimeData?.startTime?.$d:copyValidorOneTimeData?.startTime);
        let dt2 = moment(copyValidorOneTimeData?.endTime?.$d?copyValidorOneTimeData?.endTime?.$d:copyValidorOneTimeData?.endTime);
        const diff = dt.diff(dt2, 'time');
        if (diff < 0) {
            let validatorErrorCopy = { ...validatorError, startEndTime: false };
            setValidatorError(validatorErrorCopy);
        }
        else {
            let validatorErrorCopy = { ...validatorError, startEndTime: true };
            setValidatorError(validatorErrorCopy);
        }

    }

    const handleFormSubmit=(e)=>{
        e.preventDefault();
        if(validatorError?.startEndTime || validatorError?.endTime || validatorError?.timezone){
    
        }
        else{
           
                const objVal = {date:dateForEdit,startTime:eventStartTime,endTime:eventEndTime,timezone:validatorOneTimeData?.timezone}
            const FinalObj = { ...formValues, validatorDetail: [objVal]};
            postValidatorInfo(FinalObj).
            then((resp) => {
                if (resp.status === 200) {
                    showSingleToastSuccess(t('Validator_Availabilty_Updated'));
                    dispatch(setValidatorChange(!validatorChange));
                    setEventStartTime('');
                    setEventEndTime('');
                    handleClose();
                    getBasicValidatorData();
                    handleAgainAfterUpdate();
                }
            })
            .catch((error) => console.log(error))

        }
    }
    return(
        <Popup className={"editAvailabilityDiv"} open={open}  handleClose={ handleClose}  modalBodyClasses="validatorAvailabilityModal" modalContentClasses="validatorAvailabilityModalContent"> 


                                        <>
                                        <Row>
                                            <Col md={6} className='popup_input '>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileTimePicker
                                                        label={t('superAdmin.Start_Time')}
                                                        value={eventStartTime}
                                                        id='startTime'
                                                        closeOnSelect='true'
                                                        name='startTime'
                                                        onChange={(newValue) => {
                                                            manageEventTime(newValue, 'startTime')
                                                            setEventStartTime(newValue)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </Col>
                                            <Col md={6} className='popup_input validatorPopupField'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileTimePicker
                                                        label={t('superAdmin.End_Time')}
                                                        id='endTime'
                                                        name='endTime'
                                                        value={eventEndTime}
                                                        onChange={(newValue) => {
                                                            manageEventTime(newValue, 'endTime')
                                                            setEventEndTime(newValue)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </Col>
                                            </Row>

                                            {validatorError?.startEndTime &&
                                                <div className="error-text">
                                                    {t('errors.validator_start_EndTime')}
                                                </div>
                                            }
                                            <Row>
                                            <Col md={12} className='popup_select mt-4'>
                                               
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        disablePortal
                                                        value={validatorOneTimeData?.timezone}
                                                        options={timezone}
                                                        getOptionLabel={(option) => option}
                                                        onChange={(e) => handleChangeTimeZone(e, 'timezone')}
                                                        renderInput={(params) => <TextField {...params} label={t('superAdmin.Time_Zone')} />}
                                                    />
                                                </FormControl>
                                                {validatorError?.timezone &&
                                                <div className="error-text">
                                                    {t('errors.timezone')}
                                                </div>
                                            }
                                            </Col>
                                            </Row>
                                            <Col md={12} className='popup_select'>
                                              <button className='btn small_submit_btn validatorPopupBtn' onClick={(e)=>handleFormSubmit(e)}>Submit</button>
                                            </Col>
                                        </>
                                   
             
        </Popup>
    )
}

export default EditAvailibityPopUp;