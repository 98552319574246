import instance, {instanceOrg} from "../AppInterceptor";
import ApiConfig from "../config/ApiConfig";
import { getObjectFromStore } from "../storage/Storage";

export const walletGetService=()=>{
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.getWalletData);
    }
    else{
        return instance.get(ApiConfig.getWalletData);
    }
}

