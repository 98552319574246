import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import {
    Box, TextField,
    Select,
    MenuItem,
    InputLabel,
    OutlinedInput,
    Button,
    FormHelperText,
    FormControl
} from '@mui/material'
import FormTextArea from "../../../../commonComponents/formTextArea";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import { CalendarMonthIcon } from "../../../../commonComponents/Images";
import { validEmail } from "../../../../utils/formatValidator";
import SaveEditSettingsBtn from "../../../../commonDashboardComp/saveEditSettingsBtn";
import {
    verifyEmailMobileOtpService,
    checkEmailMobileAvailabilityService
} from "../../../../services/onBoarding.services";
import Popup from "../../../../commonComponents/popup";
import { Link } from "react-router-dom";
import moment from 'moment/moment';
import { IconInFormImg } from "../../../../commonComponents/Images";
import { showSingleToastSuccess, showToastError, showToastSuccess } from "../../../../utils/helpers";
import { allowOnlyLetters } from "../../../../utils/helpers";
const AboutProfile = ({ saveUserDetails, formValues, setFormValues, canEdit, onEdit, error, setError }) => {
    const { t } = useTranslation();
    const maxDate = moment().subtract(18, "years");
    const [emailError, setEmailError] = useState(false);
    const [open, setOpen] = useState(false);
    const [counter, setCounter] = useState(0);
    const [otp, setOtp] = useState("");
    const [aboutMe, setaboutMe] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const [incorrectOTP, setIncorrectOTP] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [changeMade, setChangeMade] = useState(false);


    useEffect(() => {
        let myInterval = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            } else {
                clearInterval(myInterval)
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    }, [counter]);

    const onSave = (e) => {
        e.preventDefault();
        setError(false);
        if (formValues.firstName === '' ||
            formValues.lastName === '' || (getWordCount(formValues?.aboutMe) >= 200)
        ) { setError(true); return; }

        let params = {
            firstName: formValues.firstName,
            middleName: formValues.middleName,
            lastName: formValues.lastName,
            aboutMe: (formValues.aboutMe == '')?'Hi! I am a Sprede User!':formValues.aboutMe,
            dob: formValues.dob,
            gender: formValues.gender,
            occupation: formValues.occupation,
        }
        saveUserDetails(params, 'aboutInfo');
    }

    const checkEmailAvailability = () => {
        setEmailError(false);
        if (!validEmail(formValues.email)) {
            setEmailError(true); return;
        }

        try {
            let params = { 'email': formValues.email }
            checkEmailMobileAvailabilityService(params)
                .then((data) => {
                    if (data?.status !== 200) {
                        setEmailError(true); return;
                    }
                    // showToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`))
                    showSingleToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`))
                    setCounter(60);
                    handleOpen();
                })
                .catch((error) => {
                    setEmailError(true);
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const verifyEmail = (e) => {
        e.preventDefault();
        if (formValues.email === '' || !validEmail(formValues.email)) { return; }
        setOtpError(false);
        if (otp === '' || otp.length < 6) {
            setOtpError(true); return;
        }

        try {
            setLoading(true)
            let params = { email: formValues.email, otp: otp }
            verifyEmailMobileOtpService(params)
                .then(async (data) => {
                    if (data.status === 200) {
                        setFormValues({ ...formValues, user_email: formValues.email, isEmailVerify: true })
                        handleClose();
                    } else {
                        setOtpError(true); setIncorrectOTP(true);
                        showToastError(t(`apiMessages.${data.data.data}`))
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    setError(true);
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
        setLoading(false);
    }

    function getCount(str) {
        return str.split(' ').filter(function (num) {
            return num !== ''
        }).length;
    }

    const getWordCount = (t) => {
        let text = t.split(" ");
        return text.length
    }

    return (<Row className="grid">
        <div className="grid-column">
            <div className="widget-box equalPadding">
                <p className="widget-box-title">{t('About_Your_Profile')}
                    <SaveEditSettingsBtn canEdit={canEdit} onEdit={onEdit} onSave={onSave} />
                </p>
                <div className="widget-box-content mb-0">
                    <Box
                        component="form"
                        sx={{
                            '&': { m: 1, width: '98%' },
                        }}
                        noValidate
                        autoComplete="off"
                        className="userdetail-form mb-0 profile_parent11"
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <Row className="mb-0 profile_subcategory22">
                            <Col lg={3} className="col-md-6 aboutProfileField target_title88 text_error_msg91">
                                <TextField type='text' label={t('form.label_first_name')}
                                    disabled={!canEdit}
                                    value={formValues.firstName}
                                    onChange={(e) => { setFormValues({ ...formValues, firstName: allowOnlyLetters(e.target.value) }) }}
                                    error={(error && formValues.firstName === '') ? true : false}
                                    helperText={(error && formValues.firstName === '') ? t('errors.First_Name_is_Required') : ''}
                                />
                            </Col>
                            <Col lg={3} className="col-md-6 aboutProfileField target_title88 profileField2">
                                <TextField type='text'
                                    disabled={!canEdit}
                                    value={formValues.middleName}
                                    onChange={(e) => setFormValues({ ...formValues, middleName: allowOnlyLetters(e.target.value) })}
                                    label={t('form.label_middle_name')} />
                            </Col>
                            <Col lg={3} className="col-md-6 aboutProfileField target_title88 text_error_msg91 profileFields">
                                <TextField type='text' label={t('form.label_last_name')}
                                    value={formValues.lastName}
                                    disabled={!canEdit}
                                    onChange={(e) => setFormValues({ ...formValues, lastName: allowOnlyLetters(e.target.value) })}
                                    error={(error && formValues.lastName === '') ? true : false}
                                    helperText={(error && formValues.lastName === '') ? t('errors.Last_Name_is_Required') : ''}
                                />
                            </Col>
                            <Col lg={3} className="col-md-6 aboutProfileField target_title88 profileFields">
                                <FormControl fullWidth>
                                    <InputLabel id="co-country-select-label">{t('form.label_gender')}</InputLabel>
                                    <Select
                                        labelId="co-country-select-label"
                                        value={formValues.gender}
                                        disabled={!canEdit}
                                        label={t('form.label_gender')}
                                        onChange={(e) => setFormValues({ ...formValues, gender: e.target.value })}
                                    >
                                        <MenuItem value="male">{t('form.label_male')}</MenuItem>
                                        <MenuItem value="female">{t('form.label_female')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                        <Row className="mb-0 profile_subcategory99">
                            <Col md={6} className="aboutProfileField">
                                <div className="about-text-area target_textarea90">
                                    <FormTextArea label={t('form.label_about_me')} placeholder={t('form.about_placeholder')}
                                        value={formValues.aboutMe}
                                        disabled={!canEdit}
                                        onChange={(e) => (getWordCount(e.target.value) <= 200) ? setFormValues({ ...formValues, aboutMe: e.target.value }) : ''}
                                    />
                                    {getWordCount(formValues?.aboutMe) >= 200 ?
                                        <div className="error-text">
                                            {t('errors.you_can_not_enter_more_than_200_words')}
                                        </div>
                                        : ""
                                    }
                                </div>
                            </Col>

                            <Col md={6} className="fullWidth aboutProfileField margin_btm_remove target_title88 profileField2">
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">{t('form.label_email')}</InputLabel>
                                    <OutlinedInput
                                        disabled={!canEdit}
                                        error={(formValues.email === '') ? true : (!validEmail(formValues.email)) ? true : false}
                                        label={t('form.label_email')} icon={IconInFormImg}
                                        value={formValues.email}
                                        onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                        endAdornment={((formValues.email.toLowerCase() === formValues.user_email.toLowerCase() && formValues.isEmailVerify)) ?
                                            <Button className="verify_btn verified_btn" >{t('verified')}</Button> :
                                            ((validEmail(formValues.email))) ?
                                                <Button className="verify_btn" disabled={!canEdit} onClick={checkEmailAvailability}>{t('verify_now')}</Button> : ''}
                                    />
                                    <FormHelperText className="about_colorchnage45">
                                        {
                                            (formValues.email === '') ? t('errors.email_required') :
                                                (!validEmail(formValues.email)) ? t('errors.email_format') :
                                                    (emailError) ? t('errors.Email_is_already_taken') : ''
                                        }</FormHelperText>
                                </FormControl>

                                {/* <TextField label="Occupation"
                                    className=""
                                    disabled={!canEdit}
                                    value={formValues.occupation}
                                    onChange={(e) => setFormValues({ ...formValues, occupation: allowOnlyLetters(e.target.value) })}
                                /> */}

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        closeOnSelect={true}
                                        label={t('form.label_your_dob')}
                                        disableFuture={true}
                                        disabled={!canEdit}
                                        maxDate={maxDate}
                                        value={(formValues.dob) ? formValues.dob : ""}
                                        onChange={(newDob) => {
                                            setFormValues({ ...formValues, dob: newDob });
                                        }}
                                        InputProps={{
                                            endAdornment:
                                                <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} />
                                        }}
                                        renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                                        inputFormat="DD MMM YYYY"
                                    />
                                </LocalizationProvider>
                            </Col>

                            {/* <Col md={6} className="fullWidth removeMargin">
                                
                            </Col>                                  */}
                        </Row>
                    </Box>
                </div>
            </div>
        </div>

        <Popup heading={t('form.submit_otp')} className={'submitOtpPopUp'} handleClose={handleClose} open={open}>
            <Box
                component="form"
                sx={{
                    '&': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className="userdetail-form"
                onSubmit={(e) => verifyEmail(e)}
            >
                <p>{t('errors.Otp_sent_on_your_email')}</p>
                <div className="otp_counter">{counter} {t('form.seconds')}</div>
                <div className="d-flex align-items-center">
                    <TextField
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        error={(otpError && otp === '') ? true : (otpError && otp.length < 6) ? true : (otpError && incorrectOTP) ? true : false}
                        helperText={(otpError && otp === '') ? t('errors.Enter_OTP') : (otpError && otp.length < 6) ? t('errors.OTP_is_of_6digits') : (otpError && incorrectOTP) ? t('apiMessages.OTP_MISS_MATCH') : ""}
                        inputProps={{ maxLength: 6 }}
                        label="OTP" />

                    {counter === 0 &&
                        <Link className="ms-3" to="#"
                            onClick={(e) => { e.preventDefault(); checkEmailAvailability() }}>
                            {t('Resend_Otp')}
                        </Link>
                    }
                </div>


                <button disabled={loading} className="button grey">
                    {loading ? t('errors.Please_Wait') : t('form.submit')}
                </button>
            </Box>
        </Popup>

    </Row>)
}

export default AboutProfile;