import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import BarsLoader from '../../commonComponents/barsLoader'
import { LanguageList } from '../../config/constants'
import { getImageUrl } from '../../utils/helpers'
import HomeBanner from './banner'
import i18next from "i18next";
import { useLocation } from 'react-router'
import { getBlogService, getCmsServiceList } from '../../services/cms.services'
import Footer from '../footer/footer'

function BlogDetail(item) {
    const location = useLocation();
    const blogId = location?.state?.blogId;
    const { t } = useTranslation();
    const defaultLanguage = i18next?.language?.split('-')[0];
    const find = LanguageList?.find(a => a.value === defaultLanguage);
    const [cmsData, setcmsData] = useState([])


    const [language, setLanguage] = useState((find?.name) ? find : LanguageList[0])
    const [isLoading, setLoading] = useState(true);
    const [blogDetail, setBlogDetail] = useState(false);



    useEffect(() => {
        let filter = LanguageList?.find(a => a.value === defaultLanguage);

        setLanguage((filter?.name) ? filter : LanguageList[0])
    }, [defaultLanguage])

    React.useEffect(() => {
        const handleLanguageChange = () => {

            let defaultLanguage1 = i18next?.language?.split('-')[0];

            let filter = LanguageList?.find(a => a.value === defaultLanguage1);

            setLanguage((filter?.name) ? filter : LanguageList[0])
        };

        i18next.on("languageChanged", handleLanguageChange);

        return () => {
            i18next.off("languageChanged", handleLanguageChange);
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        if (language !== null) { getBlogsDetail(); }
    }, [language]);

    const getBlogsDetail = () => {
        getBlogService(blogId, language?.value)
            .then((response) => {
                if (response.data.status === 200) {
                    setBlogDetail(response?.data?.data)
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (language !== null) { getCmsData(); }
    }, [language]);

    const getCmsData = async () => {
        getCmsServiceList(language?.value)
            .then((data) => {
                if (data?.data?.status === 200) {
                    const _data = data.data.data;
                    setcmsData(_data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    return (
        <>
            <HomeBanner cmsData={cmsData} onlyMenu={true} />
            <Container fluid className="blogs-div">
                <div className="blog-container">
                    <Row className="panchtatva-row1 mb-4">
                        <div className="heading">
                            <h1 style={{ color: "#a9ca4b" }}>{blogDetail?.title} </h1>
                        </div>
                    </Row>
                    {isLoading ? <BarsLoader /> : <>
                        <Row>
                                <div className="briefBlog">
                                    <img src={getImageUrl(blogDetail?._id, 'cms', blogDetail?.blogImg)} alt="blogsImg" className="img" />
                                        <p className="heading"> {blogDetail?.title} </p>
                                        <p className="desc">
                                            {blogDetail?.description}
                                        </p>
                                </div>
                        </Row>
                    </>}

                </div>

            </Container>
            <Footer cmsData={cmsData} />
        </>
    )
}

export default BlogDetail