import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentOrgRole, selectCurrentUserRole } from "../Reducer/user/user.selector";

const AboutOrg = ({ CURRENTORG }) => {
 
  const role = useSelector(selectCurrentUserRole);
  const { t } = useTranslation();
  let orgSuperAdmin=CURRENTORG?.pageRoles?.length>0?(CURRENTORG?.pageRoles?.filter(r=>r?.role==='admin')):'';
  let orgPostReviwer=CURRENTORG?.pageRoles?.length>0?(CURRENTORG?.pageRoles?.filter(r=>r?.role==='post_reviewer')):'';
  let orgEventManager=CURRENTORG?.pageRoles?.length>0?(CURRENTORG?.pageRoles?.filter(r=>r?.role==='event_manager')):'';
  return (
    <div className="widget-box">
      <p className="widget-box-title">{t('about_org')}</p>

      <div className="widget-box-content">
        <p className="paragraph">{CURRENTORG?.aboutMe || '_'}</p>

        <div className="information-line-list">
          <div className="information-line">
            <p className="information-line-title">{t('website')}</p>

            <p className="information-line-text">{CURRENTORG?.webSite || '_'}</p>
          </div>

          <div className="information-line">
            <p className="information-line-title">{t('licence_id')}</p>

            <p className="information-line-text">{CURRENTORG?.regNo || '_'}</p>
          </div>

          <div className="information-line">
            <p className="information-line-title">{t('country')}</p>
            <p className="information-line-text">{CURRENTORG?.corporateAddress?.[0]?.country || '_'}</p>
          </div>
        </div>
      </div>

     {(role==='superAdmin' && CURRENTORG?.pageRoles?.length>0) && <>
    <>
     <p className="widget-box-title">{t('Manage_By')}</p>
     <div className="widget-box-content">
        
        <div className="information-line-list">
        {orgSuperAdmin?.length>0 &&
          <div className="information-line">
            <p className="information-line-title">{t('roles.admin')}</p>
            <p className="information-line-text">{ orgSuperAdmin.map((m,index)=><span key={index}>{`${m?.userId?.firstName} ${m?.userId?.lastName}` } <br/></span>)}</p>
          </div>
          }
         {orgEventManager?.length>0 &&
          <div className="information-line">
            <p className="information-line-title">{t('roles.event_manager')}</p>
            <p className="information-line-text">{orgEventManager?.length>0 && orgEventManager.map((m,index)=><span key={index}>{`${m?.userId?.firstName} ${m?.userId?.lastName}` } <br/></span>)}</p>
          </div>
           }
          {orgPostReviwer?.length>0 &&
          <div className="information-line">
            <p className="information-line-title">{t('roles.post_reviewer')}</p>
            <p className="information-line-text">{orgPostReviwer?.length>0 && orgPostReviwer.map((m,index)=><span key={index}>{`${m?.userId?.firstName} ${m?.userId?.lastName}` } <br/></span>)}</p>
          </div>
           }
        </div>
      </div>
      </>
    
     
     </>
     }
    </div>
  )
}

export default AboutOrg;