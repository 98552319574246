import React, { useEffect, useState } from "react";
import '../dashboard.style.css';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import TabNavigation from "../../../commonDashboardComp/tabNavigation";
import AllTargets from "./allTargets";
import YourTargets from "./yourTargets";
import OrgTargets from "./orgTargets";
import { getTargetAllList } from "../../../services/onBoarding.services";
import { selectCurrentUserRole } from "../../../Reducer/user/user.selector";
import { loginUserType } from "../../../config/constants";
import InvitedTargets from "./invitedTargets";
import { useLocation } from "react-router-dom";
import { getAllTargetCountService } from "../../../services/event.services";
import PendingInvitedTargets from "./pendingInvitesTargets";
const Target = () => {
  const role = useSelector(selectCurrentUserRole);
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [allCount, setAllCount] = useState({});
  const [category, setCategory] = useState('');
  
  
  const [tab, setTab] = useState((location?.state?.tab)? (role === 'organization') ? 3 : 4 : 1);
  const [countNumber, setCount] = useState(0)
  const tabs = (role === loginUserType.USER) ? 
                    [
                      `${t('superAdmin.All_Targets')} ${countNumber>0?`(${countNumber})`:''}`, //
                      `${t('superAdmin.Org_Targets')} ${allCount?.organizationTargetTotalCount>0?`(${allCount?.organizationTargetTotalCount?allCount?.organizationTargetTotalCount:0})`:''}`, 
                      `${t('superAdmin.Invited_Targets')} ${allCount?.invitedTargetTotalCount>0?`(${allCount?.invitedTargetTotalCount?allCount?.invitedTargetTotalCount:0})`:''}`,
                      `${t('superAdmin.Pending_Invited_Targets')} ${allCount?.pendingInviteCount>0?`(${allCount?.pendingInviteCount?allCount?.pendingInviteCount:0})`:''}`,
                      `${t('superAdmin.My_Targets')} ${allCount?.myTargetTotalCount>0?`(${allCount?.myTargetTotalCount?allCount?.myTargetTotalCount:0})`:''}`, 
                    ] 
                  : [
                    `${t('superAdmin.All_Targets')}  ${countNumber>0?`(${countNumber})`:''}`, // ( ${allCount?.myTargetTotalCount?allCount?.myTargetTotalCount:0} ) 
                    `${t('superAdmin.Invited_Targets')} ${allCount?.invitedTargetTotalCount>0?`(${allCount?.invitedTargetTotalCount?allCount?.invitedTargetTotalCount:0})`:''}`,
                    `${t('superAdmin.Pending_Invited_Targets')} ${allCount?.pendingInviteCount>0?`(${allCount?.pendingInviteCount?allCount?.pendingInviteCount:0})`:''}`,
                    `${t('superAdmin.My_Targets')} ${allCount?.myTargetTotalCount>0?`(${allCount?.myTargetTotalCount?allCount?.myTargetTotalCount:0})`:''}`, 
                  ];

  const addTargetHandler = () => {
    navigate("/add-target")
  }

  useEffect(()=>{
    getAllCount("all");
  }, [])

  const getAllCount = (category) => {
    getAllTargetCountService(category)
    .then((data)=>{
      setAllCount(data?.data?.data)
      setCount(parseInt(data?.data?.data?.invitedTargetTotalCount)+parseInt(data?.data?.data?.myTargetTotalCount)+parseInt(data?.data?.data?.organizationTargetTotalCount))
      setCategory(category);
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  const makeButton = () => {
    return (((role === 'organization' || role === 'superAdmin') && tab === 4) || tab === 5 )?<Link className="button secondary popup-event-creation-trigger" to="/add-target">+ {t('button.add_new_target')}</Link>:false
  }
  return (
    <>
      <SectionHeader pretitle="" title={t('target')}  /> 
      {/* count={countNumber} */}

      <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab} showInput={false} button={makeButton()} />
      
      <div id="" className="eventFilterDropdown">
          <FormControl fullWidth className="comment">
                <InputLabel id="co-country-select-label" shrink={true}>
                  {t('Filter')}
                </InputLabel>
            <Select
              // MenuProps={{
              //   disableScrollLock: true
              // }}
              labelId="co-country-select-label"
              value={category}
              label={t('filter')}
              sx={{
                maxWidth: '125px',
              }}
              onChange={(e)=>{getAllCount(e.target.value)}}
            >
              <MenuItem value="all">{t('All')}</MenuItem>
              <MenuItem value="earth">{t('Earth')}</MenuItem>
              <MenuItem value="water">{t('Water')} </MenuItem>
              <MenuItem value="air">{t('Air')}</MenuItem>
              <MenuItem value="fire">{t('Fire')} </MenuItem>
              <MenuItem value="soul">{t('Soul')}</MenuItem>
            </Select>
          </FormControl>
        </div>

      {tab === 1 && <AllTargets getAllCount={getAllCount} filter={category}/>} 
      {(role === 'organization' || role === 'superAdmin') ?
        <>
          {tab === 2 && <InvitedTargets  getAllCount={getAllCount} filter={category}/>}
          {tab === 3 && <PendingInvitedTargets getAllCount={getAllCount} filter={category}/>}
          {tab === 4 && <YourTargets  getAllCount={getAllCount} filter={category}/>}
        </>
        :
        <>
          {tab === 2 && <OrgTargets  getAllCount={getAllCount} filter={category}/>}
          {tab === 3 && <InvitedTargets  getAllCount={getAllCount} filter={category}/>}
          {tab === 4 && <PendingInvitedTargets  getAllCount={getAllCount} filter={category}/>}
          {tab === 5 && <YourTargets  getAllCount={getAllCount} filter={category}/>}
        </>
      }
    </>);
}

export default Target;
