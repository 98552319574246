import ApiConfig from "../config/ApiConfig";
import instance, { instanceOrg } from "../AppInterceptor";
import { getObjectFromStore } from "../storage/Storage";

export const GetUserLoginLogService = (limit) => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.get(ApiConfig.userLoginLog+'?page=0&limit='+limit)
    }
    else {
        return instance.get(ApiConfig.userLoginLog+'?page=0&limit='+limit)
    }
}

export const GetUserDetailsService = () => {
    return instance.get(ApiConfig.getUserDetails);
}

export const GetInterestsListService = (isOnlyDefault) => {
    let url = ApiConfig.interestList
    if(isOnlyDefault){
        url += '?onlyDefault='+isOnlyDefault
    }
    return instance.get(url);
}

export const addNewInterestService = (payload) => {
    return instance.post(ApiConfig.interestList, payload);
}
export const getAllOrgsService = () => {
    return instance.get(ApiConfig.organizationList);
}

export const getManageUserOrgList = () => {
    return instance.get(ApiConfig.manageOrganizationList);
}

export const CheckOrGetOrgService = (name) => {
    return instance.get(ApiConfig.createOrCheckOrg + '?name=' + name);
}

export const verifyEmailMobileOtpService = (payload) => {
    return instance.post(ApiConfig.verifyEmailMobileOtp, payload);
}

export const checkEmailMobileAvailabilityService = (payload) => {
    return instance.post(ApiConfig.emailMobileAvailable, payload);
}

export const usernameSuggestionsService = (text) => {
    return instance.get(ApiConfig.usernameSuggestions + '?text=' + text);
}

export const checkUsernameAvailabilityService = (username) => {
    let url = ApiConfig.checkUsernameAvailability + '?username=' + username;
    return instance.get(url);
}

export const saveUserDetailsService = (payload) => {
    return instance.post(ApiConfig.saveUserDetails, payload);
}

export const getInvitesListService = () => {
    return instance.get(ApiConfig.getInvitesList);
}

export const FriendRequestService = (payload) => {
    return instance.post(ApiConfig.FriendRequest, payload);
}

export const searchUsersService = (keyword, limit, offset) => {
    let url = ApiConfig.searchUsers + '?searchText=' + keyword + '&limit=' + limit + '&offset=' + offset;
    return instance.get(url);
}
export const blockUserList = (page, limit, type) => {
    return instance.get(ApiConfig.getBlockUsers + '?listType=' + type + '&page=' + page + '&limit=' + limit);
}
export const userAction = (payload) => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.post(ApiConfig.userAction, payload);
    }
    else {
        return instance.post(ApiConfig.userAction, payload);
    }
}

export const reportUserService = (payload) => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.post(ApiConfig.userReport, payload);
    }
    else {
        return instance.post(ApiConfig.userReport, payload);
    }
}

export const addNewUserEmpService = (payload) => {
    return instance.post(ApiConfig.updateUserEmp, payload)
}

export const updateUserEmpService = (payload) => {
    return instance.put(ApiConfig.updateUserEmp, payload)
}

export const updateAdditionalEmpService = (payload) => {
    return instance.post(ApiConfig.updateAdditionalEmpDetails, payload)
}

export const deleteUserEmpService = (uOrgId, orgId) => {
    let url = ApiConfig.updateUserEmp + '?userOrganizationId=' + uOrgId + '&organizationId=' + orgId
    return instance.delete(url)
}

// Notification API 
export const getNotificationCountService = () => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.get(ApiConfig.notificationCount)
    }
    else {
        return instance.get(ApiConfig.notificationCount)
    }
}

export const deleteNotificationsService = (type, id) => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.delete(ApiConfig.notificationList + '?type='+type+'&defaultId='+id)
    }
    else {
        return instance.delete(ApiConfig.notificationList + '?type='+type+'&defaultId='+id)
    }   
}

export const getNotificationListService = (page, limit, subject) => {
    let url = ApiConfig.notificationList + '?page=' + page + '&limit=' + limit;
    if(subject)
    {
        url += `&subject=${subject}`;
    }
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.get(url)
    }
    else {
        return instance.get(url)
    }
}

export const markAllNotificationsSeenServce = () => {
    let url = ApiConfig.notificationMark + '?markAll=true'
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.get(url)
    }
    else {
        return instance.get(url)
    }
}

export const markNotificationSeenService = (payload) => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.post(ApiConfig.notificationMarkById, payload);
    }
    else {
        return instance.post(ApiConfig.notificationMarkById, payload);
    }
}

export const getAllOrgnizationList = () => {
    return instance.get(ApiConfig.getOrgnizationList)
};

//Organization Service
export const getOrganizationSugg = (page, limit, search) => {
    let finalurl = `${ApiConfig.getOrganizationSuggetion}?page=${page}&limit=${limit}`;
    if (search) finalurl += `&searchText=${search}`;
    return instance.get(finalurl)
};

export const followTheOrganization = (payload) => {
    return instance.post(ApiConfig.followOrganization, payload);
}

export const OrgFollowingList = (page, limit, search) => {
    let finalurl = `${ApiConfig.followOrgList}?page=${page}&limit=${limit}`;
    if (search) finalurl += `&searchText=${search}`;
    return instance.get(finalurl);
}

//Target Services
export const getTargetAllList = (page, limit, type, filter) => {
    let url = ApiConfig.targetApi + '?page=' + page + '&limit=' + limit;
    if(type){
        url += '&listType='+type
    }
    if(filter){
        url += '&filter='+filter
    }
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.get(url);
    }
    else {
        return instance.get(url);
    }
};

export const getUserOrganizationTargets = (page, limit, filter) => {
    let url = ApiConfig.userOrganizationTarget + '?page=' + page + '&limit=' + limit;
    if(filter){
        url += '&filter='+filter
    }
    return instance.get(url)
};

export const getInvitedTargetsService = (page, limit, filter) => {
    let url = ApiConfig.userOrgInvitedtargetApi + '?page=' + page + '&limit=' + limit;
    if(filter){
        url += '&filter='+filter
    }
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.get(url);
    }
    else {
        return instance.get(url);
    }
}

export const getPendingInvitedTargetsService = (page, limit, filter) => {
    let url = ApiConfig.targetStatusApi + '?page=' + page + '&limit=' + limit+'&listType=pendingInvites';
    if(filter){
        url += '&filter='+filter
    }
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.get(url);
    }
    else {
        return instance.get(url);
    }
}

export const getTargetDetailService = (id) => {
    let url = ApiConfig.targetDetail + '?targetId=' + id;
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.get(url);
    }
    else {
        return instance.get(url);
    }
}

export const addTargetService = (payload) => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.post(ApiConfig.targetApi, payload);
    }
    else {
        return instance.post(ApiConfig.targetApi, payload);
    }
}

export const updateTargetService = (payload) => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.put(ApiConfig.targetApi, payload);
    }
    else {
        return instance.put(ApiConfig.targetApi, payload);
    }
}

export const deleteTargetService = (id) => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.delete(ApiConfig.targetApi + '?targetId=' + id);
    }
    else {
        return instance.delete(ApiConfig.targetApi + '?targetId=' + id);
    }
}

export const acceptRejectTargetService = (payload) => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.post(ApiConfig.targetStatusApi, payload);
    }
    else {
        return instance.post(ApiConfig.targetStatusApi, payload);
    }
}

// All Events
export const getAllEvents = (page, limit, listType, searchText, isPaid = '') => {
    let url = ApiConfig.AllEvents + '?page=' + page + '&limit=' + limit + '&listType=' + listType;
    if (searchText) {
        url += '&searchText=' + searchText;
    }
    if(isPaid !== '' && isPaid !== 'all')
    {
        url += '&filter='+isPaid;
    }
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.get(url)
    }
    else {
        return instance.get(url)
    }
};

// All Events Report
export const getEventReport = (Id) => {
    let url = ApiConfig.eventReport + '?eventId=' + Id;
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.get(url)
    }
    else {
        return instance.get(url)
    }
};

//getAll Global
export const getAllglobalList = (searchText, listType, page, limit) => {
    let url = ApiConfig.getAllGlobal + '?searchText=' + searchText + '&listType=' + listType + '&page=' + page + '&limit=' + limit;
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.get(url)
    }
    else {
        return instance.get(url)
    }
};

//Complaint and Feedback
export const addComplaintFeedback = (payload) => {    
    return instance.post(ApiConfig.raiseComplaintFeedback, payload);
}

export const getComplaintFeedback = () => {    
    return instance.get(ApiConfig.getComplaintFeedbackData);
}
