import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import SectionHeader from '../../../commonDashboardComp/sectionHeader';
import { useTranslation } from 'react-i18next';
import {getReportedPostSercice } from '../../../services/post.services';
import BarsLoader from '../../../commonComponents/barsLoader';
import { useSelector } from 'react-redux';
import { selectCurrentUserRole } from '../../../Reducer/user/user.selector';
import ReportPostBox from '../../../commonDashboardComp/reportPostBox';

const ManageReportPost = ({ }) => {
    const { t } = useTranslation();
    const role = useSelector(selectCurrentUserRole);
    const [posts, setPosts] = useState([]);

    const [targets, setTargets] = useState([]);
    const [events, setEvents] = useState([]);
    const [target, setTarget] = useState(null);
    const [event, setEvent] = useState(null);
    const [searchText, setSearchText] = useState('');

    //for selected ORG List
    const [orgList, setOrgList] = useState(null);
    const [orgTargets, setORGTargets] = useState([]);

    const limit = 20;
    const [loading, setLoading] = useState(false);
    const [page, setPageNo] = useState(0)
    const [hasMore, SetHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const path_name = window.location.pathname;
    const observer = useRef();

    const lastElement = (node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((enteries) => {
            if (enteries[0].isIntersecting && hasMore) {
                setPageNo((prev) => prev + 1);
            }
        })
        if (node) {
            observer.current.observe(node);
        }
    }

    useEffect(() => {
        setPosts([]);
        setPageNo(0)
        setOffset(0);
        getPostLists()
    }, [target, event, orgList])
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    useEffect(() => {
        getPostLists();
    }, [page, searchText])

    const getPostLists = () => {
        setLoading(true);
        // if(role === 'organization'){
        //     if(target?._id){
        getReportedPostSercice(page, limit,searchText)
            .then((data) => {
                if (data?.data?.status === 200) {
                    if (data?.data?.data?.finalData?.length === 0 || data?.data?.data?.finalData.length < limit) {
                        SetHasMore(false);
                    }
                    let postList = page===0?data?.data?.data?.finalData:[...posts, ...data?.data?.data?.finalData]
                    
                    let newArr = postList.reduce((acc, curr) => {
                        let fnd = acc.find((a) => a?._id === curr?._id);
                        if (!fnd) acc.push(curr);
                        return acc;
                    }, []);
                    setPosts(newArr)
                }
                
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            })
        }

    const renderEmptyMessage = () => {
        return (
            <>
                {posts?.length === 0 && !loading &&
                    <>
                        <Row className="notification">
                            <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center">
                                <span className="bold">
                                    {t('no_posts')}
                                </span>
                            </Col>
                        </Row>
                    </>}
            </>
        )
    }

    return (
        <>
            <SectionHeader title='Reported Post' />

            {/* <div className="section-filters-bar v6 post_rev_search_area">
                <div className="col-lg-3 col-sm-6 col-12">
                    <form className="form eventSearch ">
                        <Autocomplete
                            disablePortal
                            value={orgList}
                            options={orgTargets}
                            getOptionLabel={(option) => option?.name || ""}

                            onChange={(e, val) => { setOrgList(val); }}
                            renderInput={(params) => <TextField {...params} label={t('errors.select_ORG')} />}
                        />
                    </form>
                </div>
            </div> */}

            {/* {role === 'organization' &&
                <div className="section-filters-bar v6 post_rev_search_area">
                    <div className="col-lg-3 col-sm-6 col-12">
                        <form className="form eventSearch ">
                            <Autocomplete
                                disablePortal
                                value={target}
                                options={targets}
                                getOptionLabel={(option) => option?.title || ""}

                                onChange={(e, val) => { setTarget(val); }}
                                renderInput={(params) => <TextField {...params} label={t('errors.target_required')} />}
                            />
                        </form>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                        <form className="form eventSearch post_rev_search_option">
                            <Autocomplete
                                disablePortal
                                value={event}
                                options={events}
                                getOptionLabel={(option) => option?.title || ""}

                                onChange={(e, val) => { setEvent(val); }}
                                renderInput={(params) => <TextField {...params} label={t('errors.event_required')} />}
                            />
                        </form>
                    </div>

                    <div className="section-filters-bar-actions">
                    </div>
                </div>
            } */}


            <Row className='grid'>
                <Col md={12} className="grid-column">
                    {
                        posts.length > 0 && posts.map((item, index) => {
                            return (<Fragment key={index}>
                                {index + 1 === posts.length ?
                                    <div ref={lastElement} className="grid-column">
                                        <ReportPostBox post={item} />
                                    </div> :
                                    <div className="grid-column">
                                        <ReportPostBox post={item} />
                                    </div>}
                            </Fragment>)
                        })
                    }

                    {loading && <BarsLoader />}

                    {!loading && posts.length === 0 && renderEmptyMessage()}
                </Col>
            </Row>
        </>
    )

}
export default ManageReportPost;