import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../../Reducer/user/user.selector";
import { getImageRequestService } from "../../../services/auth.services";
import {
    allowOnlyLetters,
    eventDateRange,
    showSingleToastSuccess,
    changeDateFormatmmddyy,
    dateTimeEventFormat,
    changeDateFormatYYYY,
    checkEventFrequencyAvailable,
    convertToBool,
} from "../../../utils/helpers";
import { getTargetDetailService } from "../../../services/onBoarding.services";
import { CreatePostService, EditPostService } from "../../../services/post.services";
import { fromToDateValidator } from "../../../utils/formatValidator";
import { Box } from '@mui/material';
import { validEmail } from "../../../utils/formatValidator";
import { showToastError } from "../../../utils/helpers";
import { giftsList } from "../../../config/constants";
import {
    GetTargetListDropDownService,
    RegisterValidatorByEmail,
    searchValidatorService,
    addEventService,
    updateEventService,
    updateEventPostIdService
} from "../../../services/event.services";
import InvitePopup from "../../../commonDashboardComp/InvitePopUp";
import InvitedPeoplePopup from "../../../commonDashboardComp/invitedPeoplePopup";
import { uploadFileService } from "../../../services/fileUpload.services";
import VendorSearchPopup from "../../../commonDashboardComp/vendorSearchPopup";
import swal from "sweetalert";
import { Country, City } from 'country-state-city';

//Event Steps
import EventCreationStep from "../../../commonDashboardComp/addEventComp/eventCreationStep";
import EventDatesStep from "../../../commonDashboardComp/addEventComp/eventDatesStep";
import EventValidatorVendorStep from "../../../commonDashboardComp/addEventComp/validatorVendorStep";
import EventConfigurationStep from "../../../commonDashboardComp/addEventComp/eventConfiguration.tep";
import EventActivityStep from "../../../commonDashboardComp/addEventComp/activityStep";
import RecurringEventPopup from "../../../commonDashboardComp/addEventComp/recurringEventPopup";
import { validatorRequiredNeccessary } from "../../../utils/helpers";


const AddEvent = React.memo(() => {
    const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };
    const { t } = useTranslation();
    const location = useLocation();
    const eventData = location?.state?.eventData;
    const [searchParams] = useSearchParams();
    const actionType = searchParams.get("type");
    const targetId = location?.state?.targetId;
    const role = useSelector(selectCurrentUserRole);
    const navigate = useNavigate();
    const [popupOpen, setPopupOpen] = useState(false);
    const handlePopupOpen = () => setPopupOpen(true);
    const handlePopupClose = () => setPopupOpen(false);

    const [popupInvitedOpen, setPopupInvitedOpen] = useState(false);
    const handlePopupInvitedOpen = () => setPopupInvitedOpen(true);
    const handlePopupInvitedClose = () => setPopupInvitedOpen(false);

    const [recurringPopupOpen, setRecurringPopupOpen] = useState(false);
    const handleRecurringPopupOpen = () => setRecurringPopupOpen(true);
    const handleRecurringPopupClose = () => setRecurringPopupOpen(false);

    const [userInvites, setUserInvites] = useState([]);
    const [orgInvites, setOrgInvites] = useState([]);

    const [targets, setTargets] = useState([]);
    const [targetVal, setTargetVal] = useState(null)
    const [activeTarget, setActiveTarget] = useState({});
    const [validatorEmail, setValidatorEmail] = useState("");
    const [valEmailSuccess, setValEmailSuccess] = useState(false);
    const [valEmailError, setValEmailError] = useState(false);
    const [activityType, setActivityType] = useState('single');
    const [eventdate, setEventDate] = useState(1);
    const [weekday, setWeekDay] = useState(0);
    const [repeatEvery, setRepeatEvery] = useState(1);
    const [onDay, setOnDay] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const [eventValidators, setEventValidators] = useState([]);
    const [prevInvites, setPrevInvites] = useState([]);
    const [prevOrgInvites, setPrevOrgInvites] = useState([]);
    const [maxUnits, setMaxUnits] = useState(0);
    const [unitsMeasure, setUnitsMeasure] = useState('#');
    const [showVendorPopup, setShowVendorPopup] = useState(false);
    const [vendorSearchKey, setVendorSearchKey] = useState('');
    const [categoryVal, setCategoryVal] = useState('');
    const [wordLimit, setWordLimit] = useState(false);

    const [pendingInvites, setPendingInvites] = useState({});

    const handleVendorPopupOpen = (e) => { e.preventDefault(); setShowVendorPopup(true) }
    const handleVendorPopupClose = (e) => { e.preventDefault(); setShowVendorPopup(false) }

    const newCountryList = [];
    Country.getAllCountries()?.filter((a) => {
        if (City.getCitiesOfCountry(a?.isoCode)?.length > 0) {
            newCountryList.push(a?.name);
            if (newCountryList?.indexOf(a?.name) === -1) { newCountryList.push(a?.name); }
        }
        return a?.name;
    })
    const [cityList, setCityList] = useState([]);


    //scroll to top of page when redirect on this
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const INITIAL_ACTIVITY = {
        title: "",
        tasks: [{ title: "", steps: [{ title: "" }] }],
        steps: [{ title: "" }]
    }
    const INITIAL_FORM_VALUES = {
        "title": "",
        "createdBy": (role === 'organization') ? role : 'user',
        "category": "",
        "type": "",
        "location": "",
        "city": "",
        "country": "",
        "startTime": "",
        "description": "",
        "photo": "",
        "targetId": "",
        "frequency": "",
        "chargingType": "",
        "modeOfParticipation": "",
        "isPaid": false,
        "amount": 0,
        "unit": '',
        "unitCompleted": 0,
        "currency": '',
        "incentive": "",
        "incentivePrize": "",
        "validatorMode": "",
        "complexity": "",
        "recurringType": "monthly",
        "eventValidators": [],
    }

    //invites Const
    const [invites, setInvites] = useState({
        "invites": [],
        "invitesNew": [],
        "orgInvites": [],
        "organizationInvites": [],
    })
    //************* */

    const [activity, setActivity] = useState([INITIAL_ACTIVITY])
    const [dates, setDates] = useState({ startDate: '', endDate: '' })
    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);

    const [vendors, setVendors] = useState([]);
    const [eventValidatorsList, setEventValidatorsList] = useState([]);
    const [validatorRequired, setValidatorRequired] = useState(false);

    const [otherGift, setOtherGift] = useState("");
    const [error, setError] = useState(false);
    const [showSelected, setShowSelected] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [validatorSearch, setValidatorSearch] = useState("");
    const [validatorError, setValidatorError] = useState(false);
    const [validators, setValidators] = useState([]);
    const [showValidators, setShowValidators] = useState(false);
    const [errorCover, setErrorCover] = useState(false);
    const [startTime, setStartTime] = useState('');
    const [timeError, setTimeError] = useState(false);

    useEffect(() => {
        getTargetListing();
    }, [role])

    // actionType === "edit"? true: false

    const handleCountryChange = (e, val) => {

        //e.preventDefault();
        const cityListSamp = [];
        if (formValues?.country || val) {
            let code = Country.getAllCountries()?.find(a => a?.name === val);
            City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                if (cityListSamp.indexOf(a?.name) === -1) { cityListSamp.push(a?.name); }
            });
        }
        setCityList(cityListSamp?.length > 0 ? cityListSamp : []);
        setFormValues({ ...formValues, country: (val !== null) ? allowOnlyLetters(val) : null, city: "" });
    }
    const removeImage = () => {
        setShowSelected(false);
        setSelectedImage(null); 
        setFormValues(prev => ({ ...prev, photo: '' }))
    }
    const resetHandler = () => {
        if (eventData === undefined) {
            setTargetVal(null)
            setActiveTarget({})
            setFormValues(INITIAL_FORM_VALUES)
            setShowSelected(false);
            setSelectedImage(null);
            setDates({ startDate: '', endDate: '' })
            setStartTime('');
            setActivityType('single');
            let resetActivity = [INITIAL_ACTIVITY];
            setActivity(resetActivity);
        }
        else {
            setEventFormValues()
            setShowSelected(false);
            setSelectedImage(null);
        }
    }
    useEffect(() => {
        setEventFormValues()
    }, [eventData])

    const setEventFormValues = () => {
        if (eventData !== undefined) {
            let chk = giftsList?.filter((list) => list.value === eventData.incentivePrize);
            //check if giftlist is from options or other data
            if (chk.length === 0) {
                setOtherGift(eventData.incentivePrize)
            }

            if (eventData?.activityId?.activity?.length > 1) {
                setActivityType("multiple")
            }
            let eventVal = [];
            let eventInvites = [];
            let orgInvites = [];
            let vendors = [];
            let pendingUserInvites = [];
            let pendingOrgInvites = [];

            if (eventData?.eventValidators?.length > 0) {
                eventData?.eventValidators?.map((item) => {
                    eventVal.push({ userId: item?.userId[0]?._id, status: item?.status })
                })
            }

            if (eventData?.invites?.length > 0) {
                eventData?.invites?.map((item) => {
                    eventInvites.push(item?._id)
                })
            }

            if (eventData?.organizationInvites?.length > 0) {
                eventData?.organizationInvites?.map((item) => {
                    orgInvites.push(item?._id)
                })
            }

            if (eventData?.pendingUserInvites && eventData?.pendingUserInvites?.length > 0) {
                eventData?.pendingUserInvites?.map((item) => {
                    eventInvites.push(item?._id)
                    pendingUserInvites.push(item?._id)
                })
            }

            if (eventData?.pendingOrganizationInvites && eventData?.pendingOrganizationInvites?.length > 0) {
                eventData?.pendingOrganizationInvites?.map((item) => {
                    orgInvites.push(item?._id)
                    pendingOrgInvites.push(item?._id)
                })
            }

            setUserInvites((eventData?.invites) ? [...eventData?.invites, ...eventData?.pendingUserInvites] : [])
            setOrgInvites((eventData?.organizationInvites) ? [...eventData?.organizationInvites, ...eventData?.pendingOrganizationInvites] : [])
            setPendingInvites({ pendingUserInvites: pendingUserInvites, pendingOrgInvites: pendingOrgInvites })

            if (eventData?.weekday) {
                setWeekDay(eventData?.weekday)
            }
            if (eventData?.recurringDate) {
                setEventDate(eventData?.recurringDate)
            }

            if (eventData?.vendor?.length > 0) {
                eventData?.vendor?.map((item) => {
                    if (item !== '' || item !== null) {
                        vendors.push(item)
                    }
                })
            }
            setPrevInvites([...eventInvites])
            setPrevOrgInvites(orgInvites)
            setEventValidators(eventVal);
            setEventValidatorsList(eventVal);
            setFormValues(prev => ({ ...prev, ...eventData }))

            setInvites(prev => ({
                ...prev,
                invites: eventData?.invites,
                orgInvites: eventData?.organizationInvites,
                invitesNew: [...eventInvites],
                orgInvites: orgInvites
            }))
            setVendors(vendors)
            setValidatorRequired(convertToBool(eventData?.validatorRequired));
            setActivity(eventData?.activityId?.activity);
            setStartTime(dateTimeEventFormat(new Date(), eventData?.startTime))
            setDates({
                startDate: new Date(eventData.startDate).toLocaleDateString('en-US', dateOptions),
                endDate: new Date(eventData.endDate).toLocaleDateString('en-US', dateOptions)
            })
        }
    }
    // const getDateTime = (item) => {
    //     let startDateTime = new Date(item?.startDate);
    //     let startHour, startMinutes;
    //     [startHour, startMinutes] = item?.startTime.split(':');
    //     startDateTime.setHours(startHour);
    //     startDateTime.setMinutes(startMinutes);
    //     return startDateTime;
    // }
    useEffect(() => {
        if (targetVal !== null) {
            setTargetHandler(targetVal?._id)
            getTargetDetail(targetVal?._id)
        }
    }, [targetVal])

    const getTargetDetail = (id) => {
        try {
            getTargetDetailService(id)
                .then((data) => {

                    let max_units = data?.data?.data?.targetUnit;
                    setUnitsMeasure(data?.data?.data?.unitType)
                    if (data?.data?.data?.events?.length > 0) {
                        data?.data?.data?.events?.map((item) => {
                            if ((eventData && eventData?._id !== item?._id) || !eventData) {
                                max_units -= item?.unit;
                            }
                        })
                    }
                    setCategoryVal(data?.data?.data?.spredeInterestSubCategory?.spredeInterestOptions);
                    setMaxUnits(max_units);
                })
        }
        catch (error) {
            console.log(error)
        }
    }

    const setTargetHandler = (value, targetList = targets, formVal = formValues) => {


        let selected_target = targetList?.filter((target) => target._id === value);

        setActiveTarget(selected_target[0])
        if (targetVal === null) { setTargetVal(selected_target[0]) }

        let activity = formValues?.activity;
        if (formVal?.activityId && formVal?.activityId?.activity.length > 0) {
            activity = formVal?.activityId?.activity;
        }

        if (selected_target[0]?.targetType === 'private') {
            setFormValues(prev => ({
                ...prev, ...formVal,
                targetId: value,
                targetType: "private",
            }));
        }
        else {
            setFormValues(prev => ({
                ...prev, ...formVal,
                targetId: value
            }));
        }

    }

    const addActivityHandler = (e) => {
        e.preventDefault()
        let newActivity = [...activity, INITIAL_ACTIVITY]
        setActivity(newActivity)
    }

    const AddTaskStepHandler = (e, type, index, index2) => {
        e.preventDefault();
        let newActivity = [...activity]
        if (type === 'tasks') {
            newActivity[index][type] = [...newActivity[index][type], { title: '', steps: [{ title: "" }] }]
        }
        else {
            newActivity[index]['tasks'][index2][type] = [...newActivity[index]['tasks'][index2][type], { title: '' }]
        }

        setActivity(newActivity)
    }

    const removeActivityHandler = (e, i) => {
        e.preventDefault();
        let newActivity = [...activity]
        if (newActivity[i]?._id) {
            swal({
                text: t('alerts.delete_activity'),
                title: '',
                icon: "warning",
                buttons: {
                    Yes: {
                        text: t('yes'),
                        value: true,
                    },
                    No: {
                        text: t('no'),
                        value: false,
                    },
                },
            })
                .then(async (val) => {
                    if (!val) return;
                    newActivity.splice(i, 1);
                    setActivity(newActivity)
                });
        }
        else {
            newActivity.splice(i, 1);
            setActivity(newActivity)
        }
    }

    const RemoveTaskStepHandler = (e, type, index, removeIndex, taskIndex) => {
        e.preventDefault();
        let newActivity = [...activity]
        if (type === 'tasks') {
            if (newActivity[index][type][removeIndex]?._id) {
                swal({
                    text: t(`alerts.delete_${type}`),
                    title: '',
                    icon: "warning",
                    buttons: {
                        Yes: {
                            text: t('yes'),
                            value: true,
                        },
                        No: {
                            text: t('no'),
                            value: false,
                        },
                    },
                })
                    .then(async (val) => {
                        if (!val) return;
                        newActivity[index][type].splice(removeIndex, 1)
                        setActivity(newActivity)
                    });
            }
            else {
                newActivity[index][type].splice(removeIndex, 1)
                setActivity(newActivity)
            }
        }
        else {
            newActivity[index]['tasks'][taskIndex][type].splice(removeIndex, 1)
            setActivity(newActivity)
        }
    }

    const onChangeActivityHandler = (value, index, type = '', index2 = '', index3 = '') => {
        let newActivity = [...activity]
        if (type === '') {
            newActivity[index]['title'] = value
        }
        else if (type === 'tasks') {
            newActivity[index][type][index2]['title'] = value
        }
        else {
            newActivity[index]['tasks'][index3]['steps'][index2]['title'] = value
        }
        setActivity(newActivity)
    }

    const fetchEventDatesByDateAndFrequency = () => {
        if (formValues.frequency === "one_time") {
            return [dates.startDate]
        }
        else {
            let eventDatesVal = eventDateRange(dates.startDate,
                dates.endDate,
                formValues.recurringType,
                eventdate,
                weekday,
                repeatEvery
            );
            return eventDatesVal;
        }


    }

    const changeDate = (params) => {
        let result = params;
        result.startDate = changeDateFormatmmddyy(new Date(params.startDate));
        result.endDate = changeDateFormatmmddyy(new Date(params.endDate));
        if (result?.eventDates?.length) {
            result.eventDates = result?.eventDates?.map((date) => changeDateFormatmmddyy(new Date(date)));
        }
        return result;
    }

    useEffect(() => {
        if (error) {
            //scollToRef.current.scrollIntoView()
            const element = document.getElementsByClassName('error-text');
            if (element[0]) {
                // 👇 Will scroll smoothly to the top of the next section
                element[0].scrollIntoView({ behavior: 'smooth', block: "center" });
            }
        }
    }, [error])

    const scrollToError = () => {
        const element = document.getElementsByClassName('error-text');
        if (element[0]) {
            // 👇 Will scroll smoothly to the top of the next section
            element[0].scrollIntoView({ behavior: 'smooth', block: "center" });
        }
    }

    const getWordCount = (t) => {
        let text = t.split(" ");
        return text.length
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(false);
        setTimeError(false);


        if (formValues.title === '' || formValues.type === '' ||
            formValues.location === '' || dates.startDate === '' ||
            dates.endDate === '' || formValues.startTime === '' || formValues.targetId === '' ||
            !fromToDateValidator(dates.startDate, dates.endDate) ||
            !checkEventDateRange() ||
            formValues.description === '' || getWordCount(formValues?.description) >= 200 || (formValues.chargingType === '' && !valEmailSuccess && validatorRequired) ||
            formValues.frequency === '' || parseInt(formValues.unit) === 0 || formValues.unit === "" ||
            parseInt(formValues.unit) > maxUnits ||
            (formValues.frequency === 'recurring' && formValues.recurringType === '') ||
            (formValues.frequency === 'recurring' && formValues.recurringType === 'weekly' && weekday === '') ||
            (formValues.frequency === 'recurring' && formValues.recurringType !== 'weekly' && formValues.recurringType !== 'daily' && eventdate === '') ||
            (formValues.photo === '' && selectedImage === null) || formValues.incentive === '' || (formValues?.incentive === 'incentivized' && formValues?.incentivePrize === '') ||
            (formValues?.isPaid && formValues?.currency === '') || (formValues?.isPaid && (formValues?.amount === '' || parseInt(formValues?.amount) === 0))
        ) {
            setError(true); scrollToError(); return;
        }

        //check if everything is entered in activity feilds
        let err = false;

        activity.map((item) => {
            if (item.title === '') {
                err = true;
                return;
            }
        })

        if (!valEmailSuccess && validatorRequired && eventValidators.length === 0) {
            err = true;
        }

        if (err) {
            setError(true); scrollToError(); return;
        }
        //************************************************ */
        let activitiesArr = [];
        //remove Empty values from activity array
        activity.map((item) => {
            if (item.title !== '') {
                activitiesArr.push(item);
            }
        })
        //************************************ */




        const timeStamp = Date.now();
        let params = { ...formValues }
        params = {
            ...params,
            invites: invites?.invitesNew,
            organizationInvites: invites?.orgInvites,
            weekday: weekday,
            recurringDate: eventdate,
            unitType: unitsMeasure,
            unitCompleted: formValues?.unitCompleted,
            startDate: dates.startDate,
            endDate: dates.endDate,
            vendor: vendors,
            vendors: vendors,
            category: categoryVal,
            startTime: ("0" + new Date(startTime).getHours()).slice(-2) + ':' + ("0" + new Date(startTime).getMinutes()).slice(-2),
            startTimeObj: ((new Date(startTime).getUTCHours()) + ':' + (new Date(startTime).getUTCMinutes())),
            activity: activitiesArr,
            validatorRequired: validatorRequired
        }

        //setting Image for event
        if (selectedImage !== null) {
            params = { ...params, photo: timeStamp }
        }
        //event Dates fetching 
        if (formValues.frequency === "one_time") {
            params = { ...params, eventDates: [dates.startDate] }
            //check the time 
            if (eventData === undefined && changeDateFormatmmddyy(new Date(dates.startDate)) === changeDateFormatmmddyy(new Date())) {
                if (new Date() > new Date(startTime)) {
                    setTimeError(true); scrollToError(); return;
                }
            }
        }
        else {
            let eventDatesVal = eventDateRange(dates.startDate,
                dates.endDate,
                formValues.recurringType,
                eventdate,
                weekday,
                repeatEvery
            );

            if (eventDatesVal.length === 0) { return; }
            if (eventDatesVal.length === 1) {
                //check the time 
                if (eventData === undefined && changeDateFormatmmddyy(new Date(eventDatesVal[0])) === changeDateFormatmmddyy(new Date())) {
                    if (new Date() > new Date(startTime)) {
                        setTimeError(true); scrollToError(); return;
                    }
                }
            }
            params = { ...params, eventDates: eventDatesVal }

        }
        setLoading(true)
        //event activity setting 
        if (activityType === 'single') {
            params = { ...params, activity: [activity[0]] }
        }

        // event validators list 
        params = { ...params, eventValidators: eventValidators }
        //******************* */
        if (eventData !== null && eventData !== undefined) {
            let invites_final = invites?.invitesNew?.filter(function (el) {
                return prevInvites.indexOf(el) < 0;
            });

            let org_invites_final = invites?.orgInvites?.filter(function (el) {
                return prevOrgInvites.indexOf(el) < 0;
            });
            params = {
                ...params,
                invites: invites_final,
                organizationInvites: org_invites_final,
                removeInvites: prevInvites.filter(item => !invites?.invitesNew.includes(item)),
                removeOrganizationInvites: prevOrgInvites.filter(item => !invites?.orgInvites.includes(item)),
                removePendingUserInvites: pendingInvites?.pendingUserInvites.filter(item => !invites?.invitesNew.includes(item)),
                removePendingOrganizationInvites: pendingInvites?.pendingOrgInvites.filter(item => !invites?.orgInvites.includes(item))
            }

            if (formValues?.activity?.length !== 0) {
                params = {
                    ...params, activity: {
                        _id: eventData?.activityId?._id,
                        isCompleted: eventData?.activityId?.isCompleted,
                        activity: activity
                    }
                }
            }

            try {
                const result = changeDate(params);
                updateEventService(result)
                    .then(async (data) => {
                        if (data.status === 200) {

                            if (timeStamp === params?.photo) {
                                const result = await uploadFileService('event', 'event', timeStamp, selectedImage, formValues?._id)
                            }
                            let response = await eventPostHandler(formValues?._id, true);
                            // showToastSuccess(t('apiMessages.EVENT_UPDATE_SUCCESS'));
                            showSingleToastSuccess(t('apiMessages.EVENT_UPDATE_SUCCESS'));
                            navigate('/view-event', { state: { eventId: formValues?._id } });
                        } else {
                            setLoading(false)
                            showToastError(t(`apiMessages.${data.data.data}`))
                        }
                    })
                    .catch((error) => {
                        setLoading(false)
                        showToastError(t(`apiMessages.${error?.data?.data}`))
                    })
            }
            catch (error) {
                setLoading(false)
                console.log(error);
            }
        }
        else {
            try {
                const result = changeDate(params);
                addEventService(result)
                    .then(async (data) => {
                        if (data?.data?.status === 200) {
                            const result = await uploadFileService('event', 'event', timeStamp, selectedImage, data?.data?.data)
                            const response = await eventPostHandler(data?.data?.data);
                            // showToastSuccess(t('apiMessages.EVENT_ADD_SUCCESS'));
                            showSingleToastSuccess(t('apiMessages.EVENT_ADD_SUCCESS'));
                            navigate('/view-event', { state: { eventId: data?.data?.data } });
                        } else {
                            setLoading(false)
                            showToastError(t(`apiMessages.${data.data.data}`))
                        }
                    })
                    .catch((error) => {
                        setLoading(false)
                        showToastError(t(`apiMessages.${error?.data?.data}`))
                    })
            }
            catch (error) {
                setLoading(false)
                console.log(error);
            }
        }
    }

    const eventPostHandler = async (eventId, update = false) => {
        let params = {
            eventId: eventId,
            targetId: formValues.targetId,
            categoryId: targetVal?.spredeInterestSubCategory,
            post: formValues.description,
            isEventFeatured: true
        }
        if (update) {
            params['postId'] = eventData?.postId
            if (selectedImage !== null) {
                params['image'] = [selectedImage.lastModified]
            }
            try {
                let resp = await EditPostService(params);
                if (resp) {
                    if (selectedImage !== null) {
                        //upload image for post
                        const result = await uploadFileService('voxBoxImage', 'voxBox', selectedImage.lastModified, selectedImage, eventData?.postId)
                    }
                }
                return true;
            }
            catch (error) {
                console.log(error)
            }
        }
        else {
            params['image'] = [selectedImage.lastModified]
            try {
                let resp = await CreatePostService(params);
                if (resp) {
                    //upload image for post
                    const result = await uploadFileService('voxBoxImage', 'voxBox', selectedImage.lastModified, selectedImage, resp?.data?.data?.insertedId)
                }

                //Update event and send post Id 
                let eventParams = {
                    eventId: eventId,
                    postId: resp?.data?.data?.insertedId
                }
                let response = await updateEventPostIdService(eventParams)
                //**************************** */
                return true;
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    const setPhoto = async (param) => {
        //let isValidSize = await readImageFileCover(param)
        if (param !== null && param !== undefined) {
            setErrorCover(param?.size < 5000 || param?.size > 5120000 ? true : false) // || !isValidSize
            if (param?.size < 5000 || param?.size > 5120000) { //|| !isValidSize
                return;
            }
        }
        setShowSelected(true);
        setSelectedImage(param);
    }

    const handleFrequencyTypeHandler = (e, value) => {
        if (e.target.checked) {
            if (value === 'one_time') { setFormValues(prev => ({ ...prev, recurringType: "" })) }
            setFormValues(prev => ({ ...prev, frequency: value }))
        }
        ;
    }

    const putImageRequest = async (url, actParam) => {
        const response = await fetch(url + '&x-ms-blob-type=BlockBlob', {
            method: 'PUT',
            headers: {
                'x-ms-blob-type': `BlockBlob`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data'
            },
            body: actParam
        })
            .catch((error) => {
                console.error(error);
            });
        return response;
    }

    const fetchImageData = async (fileName, param, id) => {
        try {
            let params = {
                fileName: fileName,
                category: "event",
                type: "event",
                defaultId: id
            }
            var response = await getImageRequestService(params);
            putImageRequest(response?.data?.data, param);

        } catch (error) {
            console.error(error);
        }
    }

    const handleIncentiveGiftChange = (e, value) => {
        if (e.target.checked) {
            setFormValues({ ...formValues, incentivePrize: value })
            setOtherGift("");
        }
    }

    const getTargetListing = async () => {
        GetTargetListDropDownService("myTarget")
            .then((data) => {
                if (data?.data?.status === 200) {
                    setTargets(data?.data?.data);
                    if (targetId !== undefined) {
                        if (eventData === undefined) {
                            setTargetHandler(targetId, data?.data?.data)
                        }
                        else {
                            setTargetHandler(targetId, data?.data?.data, eventData)
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const [inviteUserError, setInviteUserError] = useState(false);
    const addInInvitesHandler = (id, type = 'invites', user) => {
        if (type === 'invites') {
            let foundSameMember = false;
            if (eventValidators.length != 0) {
                for (let i = 0; i < eventValidators.length; i++) {
                    if (eventValidators[i].userId == id) {
                        foundSameMember = true;
                    }
                }
                if (!foundSameMember) {
                    setInviteUserError(false);
                    if (invites.invitesNew.indexOf(id) === -1) {
                        let arr = invites.invitesNew;
                        arr.push(id);
                        setInvites({ ...invites, invitesNew: arr })

                        let invitesArr = userInvites;
                        invitesArr.push(user);
                        setUserInvites(invitesArr)
                    }
                }
                else {
                    setInviteUserError(true);
                }
            }
            else {
                if (invites.invitesNew.indexOf(id) === -1) {
                    let arr = invites.invitesNew;
                    arr.push(id);
                    setInvites({ ...invites, invitesNew: arr })

                    let invitesArr = userInvites;
                    invitesArr.push(user);
                    setUserInvites(invitesArr)
                }
            }

        }
        else {
            if (invites.orgInvites.indexOf(id) === -1) {
                let arr = invites.orgInvites;
                arr.push(id);
                setInvites({ ...invites, orgInvites: arr })

                let invitesArr = orgInvites;
                invitesArr.push(user);
                setOrgInvites(invitesArr)
            }
        }
    }

    const removeInvitesHandler = (id, type = 'invites') => {
        if (type === 'invites') {
            if (Array.isArray(id)) {
                let arr = invites.invitesNew;
                arr = arr.filter(item => id.indexOf(item) === -1);
                setInvites({ ...invites, invitesNew: arr })

                let invitesArr = userInvites;
                let filterInvitesArr = invitesArr.filter(item => id.indexOf(item?._id) === -1)
                setUserInvites(filterInvitesArr)
            }
            else {
                let arr = invites.invitesNew;
                let filter = arr.filter((item) => { return item !== id });
                setInvites({ ...invites, invitesNew: filter })

                let invitesArr = userInvites;
                let filterInvitesArr = invitesArr.filter(item => item._id !== id)
                setUserInvites(filterInvitesArr)
            }

        }
        else {
            if (Array.isArray(id)) {
                let arr = invites.orgInvites;
                arr = arr.filter(item => id.indexOf(item) === -1);
                setInvites({ ...invites, orgInvites: arr })

                let invitesArr = orgInvites;
                let filterInvitesArr = invitesArr.filter(item => id.indexOf(item?._id) === -1)
                setOrgInvites(filterInvitesArr)
            }
            else {
                let arr = invites.orgInvites;
                let filter = arr.filter((item) => { return item !== id });
                setInvites({ ...invites, orgInvites: filter })

                let invitesArr = orgInvites;
                let filterInvitesArr = invitesArr.filter(item => item._id !== id)
                setOrgInvites(filterInvitesArr)
            }

        }

    }

    const addVendorInList = (id) => {
        if (!(vendors.includes(id))) {
            setVendors([...vendors, id]);
        }
    }

    const removeVendorsInList = (id) => {
        let arr = vendors;
        if(arr.includes(id)){
            arr.pop(id);
            setVendors([...arr]);
        }
    }

    const sendValidatorEmailHandler = () => {
        setValEmailError(false);
        if (validatorEmail === '' || !validEmail(validatorEmail)) {
            setValEmailError(true); return;
        }

        RegisterValidatorByEmail(validatorEmail)
            .then((data) => {
                if (data?.data?.status !== 200) {
                    showToastError(t('errors.some_error'));
                }
                else {
                    // showToastSuccess("Email sent to validator successfully!");
                    showSingleToastSuccess(t('errors.Email_sent_to_validator_successfully'));
                    setValEmailSuccess(true);
                    // addInInvitesHandler(data?.data?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getValidatorHandler = (e) => {
        e.preventDefault();

        setValidatorError(false);
        setShowValidators(false);
        if (formValues?.targetId === "" || dates?.startDate === '' ||
            dates?.endDate === '' || formValues.chargingType === '' ||
            formValues.location === '' || formValues?.country === '' || formValues?.city === '') {
            setValidatorError(true); scrollToError(); return;
        }

        let params = {
            searchText: validatorSearch.trim(),
            startDate: changeDateFormatYYYY(new Date(dates.startDate)),
            endDate: changeDateFormatYYYY(new Date(dates.endDate)),
            chargesMode: formValues.chargingType,
            location: formValues.location,
            country: formValues?.country,
            city: formValues?.city
        }

        searchValidatorService(params)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setValidators(data?.data?.data)
                    setShowValidators(true);
                }
                else {
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const getValidatorHandlerEdit = () => {
        setValidatorError(false);
        setShowValidators(false);
        if (formValues?.targetId === "" || dates?.startDate === '' ||
            dates?.endDate === '' || formValues.chargingType === '' ||
            formValues.location === '' || formValues?.country === '' || formValues?.city === '') {
            setValidatorError(true); scrollToError(); return;
        }

        let params = {
            searchText: validatorSearch.trim(),
            startDate: changeDateFormatYYYY(new Date(dates.startDate)),
            endDate: changeDateFormatYYYY(new Date(dates.endDate)),
            chargesMode: formValues.chargingType,
            location: formValues.location,
            country: formValues?.country,
            city: formValues?.city
        }

        searchValidatorService(params)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setValidators(data?.data?.data)
                    setShowValidators(true);
                }
                else {
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        try {
            if (actionType === "edit" && (formValues?.targetId && dates?.startDate &&
                dates?.endDate && formValues.chargingType &&
                formValues.location && formValues?.country && formValues?.city) && validatorRequired === true) {
                getValidatorHandlerEdit()
            }
        } catch (e) { }
    }, [formValues, dates])

    const [sameUser, setSameUser] = useState(false);
    const onSelectValidatorHandler = (e, id) => {
        setSameUser(false);
        if (e.target.checked) {
            //chk if already exists in list or not
            let arr = eventValidators;
            let filter = arr.filter((item) => { return item?.userId === id });
            if (filter.length === 0) {
                //Check if available in already existing validators
                let check = eventValidatorsList.filter((item) => { return item?.userId === id });
                if (check.length === 0) {
                    //Checking if validator is not a participated member.
                    setSameUser(false);
                    if (userInvites.length != 0) {
                        let foundSameMember = false;
                        for (let i = 0; i < userInvites.length; i++) {
                            if (userInvites[i]._id == id) {
                                foundSameMember = true;
                            }
                        }
                        if (!foundSameMember) {
                            setEventValidators([...arr, { userId: id, status: 'pending' }])
                            setSameUser(false);
                        }
                        else {
                            setSameUser(true);
                        }
                    }
                    else {
                        setEventValidators([...arr, { userId: id, status: 'pending' }])
                    }

                }
                else {

                    if (userInvites.length != 0) {
                        let foundSameMember = false;
                        for (let i = 0; i < userInvites.length; i++) {
                            if (userInvites[i]._id == id) {
                                foundSameMember = true;
                            }
                        }
                        if (!foundSameMember) {
                            setEventValidators([...arr, check[0]])
                            setSameUser(false);
                        }
                        else {
                            setSameUser(true);
                        }
                    }
                    else {
                        setEventValidators([...arr, check[0]])
                    }

                }
            }

        }
        else {
            //Remove From Validator List  
            let arr = eventValidators;
            let filter = arr.filter((item) => { return item?.userId !== id });
            setEventValidators(filter)
        }
    }

    //for Cover image upload check width and height

    function readImageFileCover(file) {
        const promise = new Promise((resolve, reject) => {
            var reader = new FileReader(); // CREATE AN NEW INSTANCE.
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {
                    var w = this.width;
                    var h = this.height;
                    if (h < 300 || w < 800) {
                        resolve(false)
                    } else
                        resolve(true)


                }
            };
        });
        return promise;

    }

    const checkEventDateRange = () => {
        if (changeDateFormatYYYY(new Date(activeTarget?.fromDate).toLocaleDateString('en-US', dateOptions)) <= changeDateFormatYYYY(new Date(dates?.startDate)) &&
            changeDateFormatYYYY(new Date(activeTarget?.toDate).toLocaleDateString('en-US', dateOptions)) >= changeDateFormatYYYY(new Date(dates?.endDate))) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleValidatorCheck = (value) => {
        setValidatorRequired(value)
        if (value === false) {
            setValidatorEmail("");
            setValidatorSearch("");
            setFormValues(prev => ({ ...prev, chargingType: "" }))
            setShowValidators(false)
        }
        if (actionType == 'edit' && convertToBool(eventData?.validatorRequired) || actionType == 'edit' && !value) {
            setShowValidators(value)
        }
    }
    
    return (<>

        <SectionHeader pretitle="" title={eventData === undefined ? t('Add_New_Event') : t('superAdmin.edit_event_text')} />

        <Row className="grid">
            <div className="grid-column addNewEventSettings myProfileSettings">
                <div className="widget-box addEve_boxes addNewTargetWidget">
                    <p className="widget-box-title">{t('superAdmin.Event_Creation')}   <span className="highlighted">{t('superAdmin.Step')} 1</span> </p>
                    <p className="widget-box-title" style={{ color: '#d06569', fontSize: '13px !important' }}>{t('required_feilds')}  </p>
                    <div className="widget-box-content">
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '98%' },
                            }}
                            noValidate
                            autoComplete="off"
                            className="userdetail-form addEve_form_mobile"
                        >
                            <EventCreationStep
                                //formValues={formValues}
                                values={
                                    {
                                        title: formValues?.title,
                                        targetId: formValues?.targetId,
                                        type: formValues?.type,
                                        unit: formValues?.unit,
                                        description: formValues?.description,
                                        photo: formValues?.photo,
                                        _id: formValues?._id,
                                        location: formValues?.location,
                                        country: formValues?.country,
                                        city: formValues?.city,
                                        invitesNew: invites?.invitesNew,
                                        orgInvites: invites?.orgInvites
                                    }
                                }
                                setFormValues={setFormValues}
                                eventData={eventData}
                                activeTarget={activeTarget}
                                error={error}
                                validatorError={validatorError}
                                targetVal={targetVal}
                                targets={targets}
                                setTargetVal={setTargetVal}
                                categoryVal={categoryVal}
                                maxUnits={maxUnits}
                                unitsMeasure={unitsMeasure}
                                showSelected={showSelected}
                                setPhoto={setPhoto}
                                removeImage={removeImage}
                                cityList={cityList}
                                role={role}
                                handleCountryChange={handleCountryChange}
                                handlePopupInvitedOpen={handlePopupInvitedOpen}
                                handlePopupOpen={handlePopupOpen}
                                selectedImage={selectedImage}
                                errorCover={errorCover}
                                newCountryList={newCountryList}
                                validatorRequired={validatorRequired}
                                wordLimit={wordLimit}
                                setWordLimit={setWordLimit}

                            />
                        </Box>
                    </div>
                </div>

                <div className="widget-box addEve_boxes addNewTargetWidget">
                    <p className="widget-box-title">{t('event.Event_Dates')}  <span className="highlighted">{t('superAdmin.Step')} 2</span> </p>
                    <p className="widget-box-title" style={{ color: '#d06569', fontSize: '13px !important' }}>{t('required_feilds')}  </p>
                    <div className="widget-box-content newTargetTag">
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '98%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <EventDatesStep
                                //formValues={formValues}
                                values={{
                                    startTime: formValues?.startTime,
                                    frequency: formValues?.frequency,
                                    recurringType: formValues?.recurringType
                                }}
                                setFormValues={setFormValues}
                                dates={dates}
                                setDates={setDates}
                                startTime={startTime}
                                setStartTime={setStartTime}
                                eventData={eventData}
                                activeTarget={activeTarget}
                                error={error}
                                timeError={timeError}
                                validatorError={validatorError}
                                handleFrequencyTypeHandler={handleFrequencyTypeHandler}
                                handleRecurringPopupOpen={handleRecurringPopupOpen}
                                repeatEvery={repeatEvery}
                                fetchEventDatesByDateAndFrequency={fetchEventDatesByDateAndFrequency}
                                eventdate={eventdate}
                                weekday={weekday}
                                checkEventDateRange={checkEventDateRange}
                                categoryVal={categoryVal}
                            />
                        </Box>
                    </div>
                </div>

                <div className="widget-box addEve_boxes addNewTargetWidget">
                    <p className="widget-box-title">{(role === 'organization') ? t('event.Event_Validator_Vendor') : t('event.Event_Validator')}  <span className="highlighted">{t('superAdmin.Step')} 3</span> </p>
                    <div className="widget-box-content newTargetTag">
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '98%' },
                            }}
                            noValidate
                            autoComplete="off"
                            className="userdetail-form eventValidatorBlock addEve_form_mobil"
                        >
                            <EventValidatorVendorStep
                                values={{
                                    chargingType: formValues?.chargingType
                                }}
                                setFormValues={setFormValues}
                                validatorSearch={validatorSearch}
                                setValidatorSearch={setValidatorSearch}
                                getValidatorHandler={getValidatorHandler}
                                valEmailError={valEmailError}
                                validatorEmail={validatorEmail}
                                role={role}
                                vendorSearchKey={vendorSearchKey}
                                handleVendorPopupOpen={handleVendorPopupOpen}
                                showValidators={showValidators}
                                eventValidators={eventValidators}
                                onSelectValidatorHandler={onSelectValidatorHandler}
                                validators={validators}
                                setValidatorEmail={setValidatorEmail}
                                sendValidatorEmailHandler={sendValidatorEmailHandler}
                                setVendorSearchKey={setVendorSearchKey}
                                validatorRequired={validatorRequired}
                                setValidatorRequired={setValidatorRequired}
                                handleValidatorCheck={handleValidatorCheck}
                                categoryVal={categoryVal}
                                error={error}
                                valEmailSuccess={valEmailSuccess}
                                sameUser={sameUser}
                            />
                        </Box>
                    </div>
                </div>

                <div className="widget-box addEve_boxes addNewTargetWidget">
                    <p className="widget-box-title">{t('event.Event_Configuration')}  <span className="highlighted">{t('superAdmin.Step')} 4</span> </p>
                    <p className="widget-box-title" style={{ color: '#d06569', fontSize: '13px !important' }}>{t('required_feilds')}  </p>
                    <div className="widget-box-content newTargetTag">
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '98%' },
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={(e)=>{
                                e.preventDefault();
                            }}
                        >
                            <EventConfigurationStep
                                //formValues={formValues} 
                                values={{
                                    modeOfParticipation: formValues?.modeOfParticipation,
                                    currency: formValues?.currency,
                                    isPaid: formValues?.isPaid,
                                    amount: formValues?.amount,
                                    incentive: formValues?.incentive,
                                    incentivePrize: formValues?.incentivePrize,

                                }}
                                setFormValues={setFormValues}
                                error={error}
                                handleIncentiveGiftChange={handleIncentiveGiftChange}
                                otherGift={otherGift}
                                setOtherGift={setOtherGift}
                            />
                        </Box>
                    </div>
                </div>

                <div className="widget-box newTargetTag addEve_boxes ">
                    <p className="widget-box-title">{t('event_template')}  <span className="highlighted">{t('superAdmin.Step')} 5</span> </p>
                    <div className="widget-box-content mb-0">
                        <Box
                            component="form"
                            noValidate
                            autoComplete="off"
                        >

                            <EventActivityStep
                                activity={activity}
                                setActivityType={setActivityType}
                                activityType={activityType}
                                onChangeActivityHandler={onChangeActivityHandler}
                                addActivityHandler={addActivityHandler}
                                removeActivityHandler={removeActivityHandler}
                                AddTaskStepHandler={AddTaskStepHandler}
                                RemoveTaskStepHandler={RemoveTaskStepHandler}
                                error={error}
                            />
                            <Row>
                                <Col className="createButtonBox p-0">
                                    <button type="button" className="button primary createButton" onClick={(e) => { e.preventDefault(); navigate('/events') }}>
                                        {t('cancel')}
                                    </button>

                                    {actionType !== "edit" && 
                                        <button type="button" className="button primary createButton" onClick={resetHandler}>
                                        {t('reset')}
                                    </button>
                                    }
                                    
                                    <button type="button" className="button primary createButton" onClick={handleSubmit} disabled={isLoading}>
                                        {t('superAdmin.Finish')} {isLoading && <span className="spinner-border" />}
                                    </button>
                                </Col>
                            </Row>
                        </Box>
                    </div>
                </div>
            </div>

        </Row>

        <InvitePopup
            popupOpen={popupOpen}
            handleClose={handlePopupClose}
            invites={invites?.invitesNew}
            orgInvites={invites?.orgInvites}
            addInInvitesHandler={addInInvitesHandler}
            removeInvitesHandler={removeInvitesHandler}
            targetId={formValues?.targetId}
            inviteUserError={inviteUserError}
        />

        <VendorSearchPopup
            popupOpen={showVendorPopup}
            handleClose={handleVendorPopupClose}
            addVendorInList={addVendorInList}
            removeVendorsInList={removeVendorsInList}
            searchKey={vendorSearchKey}
            setSearchKey={setVendorSearchKey}
            vendors={vendors}
        />

        <InvitedPeoplePopup
            popupOpen={popupInvitedOpen}
            handleClose={handlePopupInvitedClose}
            userInvites={userInvites}
            orgInvites={orgInvites}
            removeInvitesHandler={removeInvitesHandler}
        />

        <RecurringEventPopup
            recurringPopupOpen={recurringPopupOpen}
            handleRecurringPopupClose={handleRecurringPopupClose}
            eventData={eventData}
            dates={dates}
            setDates={setDates}
            activeTarget={activeTarget}
            error={error}
            validatorError={validatorError}
            repeatEvery={repeatEvery}
            setRepeatEvery={setRepeatEvery}
            onDay={onDay}
            setOnDay={setOnDay}
            formValues={formValues}
            setFormValues={setFormValues}
            eventdate={eventdate}
            setEventDate={setEventDate}
            weekday={weekday}
            setWeekDay={setWeekDay}
            checkEventDateRange={checkEventDateRange}
            categoryVal={categoryVal}
            fetchEventDatesByDateAndFrequency={fetchEventDatesByDateAndFrequency}
        />


    </>)
})

export default AddEvent;