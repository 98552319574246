import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { AvatarImg } from "../../../commonComponents/Images";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import BarsLoader from "../../../commonComponents/barsLoader";
import Backdrop from '@mui/material/Backdrop';
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { getUsersAllRequestSent, postUserCancelRequest, getUsersBySearchRequest } from "../../../services/friends.service";
import ReactPagination from "../../../commonComponents/reactPagination";
import { showToastError, showToastSuccess, showSingleToastSuccess } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loader from "../../../commonComponents/Loader";
import { getImageUrl } from "../../../utils/helpers";
const FriendReqSent = ({ searchBy, countsOfFriends,callParentCountMethod }) => {
    let defaultUserImage = '/Assets/Img.png';
    const [listofAllrequesSent, setListofAllrequesSent] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [noReqMsg, setNoReqMsg] = useState(false);
    const limit = 12;
    const [isLoading, setIsLoading] = useState(false);
    const [currentPageNo,setCurrentPageNo]=useState(0);
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    useEffect(() => {
        getFriendReqList(0);
    }, [searchBy])

    const getFriendReqList = async (page) => {
        setCurrentPageNo(page);
        if (searchBy) {
            setIsLoading(true);
            let url = `?searchText=${searchBy}&page=${page}&limit=${limit}&findType=sentRequests`;
            const response = await getUsersBySearchRequest(url);
            const user = response?.data?.data?.data;
            const user2 = user.map(a => ({ ...a, cancelReqIS: false }));
            setListofAllrequesSent(user2);
            let pageCount = (response?.data?.data?.totalCount) / limit;
            setTotalPage(Math.ceil(pageCount));
            countsOfFriends(response?.data?.data?.totalCount);
            setNoReqMsg((response?.data?.data?.totalCount) > 0 ? false : true);
            setIsLoading(false)
        }
        else {
            setIsLoading(true);
            const response = await getUsersAllRequestSent(`&page=${page}&limit=${limit}`);
            if (response?.data?.data) {
                const user = response?.data?.data?.data;
                const user2 = user.map(a => ({ ...a, cancelReqIS: false }));
                const user3 = JSON.parse(JSON.stringify(user2));
                setListofAllrequesSent(user3);
                let pageCount = (response?.data?.data?.totalCount) / limit;
                setTotalPage(Math.ceil(pageCount));
                countsOfFriends(response?.data?.data?.totalCount);
                setNoReqMsg((response?.data?.data?.totalCount) === 0 ? true : false);
            }
            setIsLoading(false);
        }
    }
    const handlePageClick = (data) => {
        let page = data?.selected;
        getFriendReqList(page);
    }

    const cancelFriendRequest = async (item) => {
        if (item?.status === 'active') {
            const params = {
                action: 'cancelRequest',
                list: [item?._id]
            }

            await postUserCancelRequest(params)
                .then((res) => {
                    if (res) {
                        // showToastSuccess(t(`apiMessages.FRIEND_REQUEST_CANCEL`));
                        showSingleToastSuccess(t(`apiMessages.FRIEND_REQUEST_CANCEL`));
                        // showSingleToastSucces2(t(`apiMessages.FRIEND_REQUEST_SENT`));
                        const nextList = [...listofAllrequesSent];
                        let index = nextList?.findIndex(a => a._id === item?._id);
                        if (index >= 0) {
                            nextList[index]['cancelReqIS'] = true;
                            setListofAllrequesSent(nextList);
                        }
                        let cntPage=currentPageNo;
                        if(listofAllrequesSent?.length==1){
                           setCurrentPageNo(currentPageNo-1);
                           cntPage=cntPage-1;
                        }
                        getFriendReqList(cntPage);
                        callParentCountMethod();
                    }

                })
                .catch((error) => {
                    console.log(error);
                    showToastError(t(`errors.Some_Error_Occurred_Please_try_again_later`));

                })
        }
        else {
            showToastError(item?.firstName + t('apiMessages.is_not_active'));
        }
    }
    const showUser = (user) => {
        let encrptedId = window.btoa(user?._id);
        //navigate(`/profile/${encrptedId}`)
        navigate(`/profile/${encrptedId}`, { state: {userId:user?._id}})
    }
    return (
        <Row className="mt-4">
            {listofAllrequesSent.length > 0 ? <>
                {listofAllrequesSent.length > 0 && listofAllrequesSent.map((item, index) =>
                    <Col md={3} key={index} className="mb-4 col-lg-2 col-sm-4">
                        <div className="widget-box  friends-list-box h-100">
                            <div className="d-flex flex-column h-100">
                                <div onClick={() => showUser(item)}>
                                    <AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile',item?.profilePicture) : defaultUserImage} />
                                    <p> {(`${item?.firstName} ${item?.lastName}`) || ''}</p>
                                    <small>{item?.userName? `@${item?.userName}`:''}</small>
                                </div>
                                {item.cancelReqIS ?
                                    <button className="button  red-tertiary disabled" > <img src="/Assets/Cancel Request-inactive.png" className='imageInfindFriend' /> {t('friendsMsg.CANCEL_FRIEND_REQUEST')} </button>
                                    :
                                    <button className="button white white-tertiary cancelReqBtn" onClick={() => cancelFriendRequest(item)}> <img src="/Assets/Cancel Request-green-inactive.png" className='imageInfindFriend' />  {t('friendsMsg.CANCEL_FRIEND_REQUEST')} </button>
                                }
                            </div>
                        </div>
                    </Col>
                )}
            </>
                : noReqMsg === true ? <h4 className="nopeMsg">{t('friendsMsg.REQUEST_NOT_SENT')}</h4> : ''}
            {
                isLoading?
                <Loader/>
                :''
            }
            <ReactPagination pageCount={totalPage} onPageChange={handlePageClick}/>
        </Row>
    )
}

export default FriendReqSent;