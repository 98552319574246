import React from "react";
import UserLayout from "../../components/dashboard-layout";
import MyProfile from "../../components/dashboard/profile/myProfile";
import ComplaintFeedback from "../../components/dashboard/complaintFeedback/complaintFeedback";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../Reducer/user/user.selector";
import CheckComplaintFeedback from "../../components/dashboard/complaintFeedback/checkComplaintFeedback";
const ComplaintFeedbackPage = () => {
    const role = useSelector(selectCurrentUserRole);
    return <UserLayout>
            {
                (role==='superAdmin')?<CheckComplaintFeedback/>:<ComplaintFeedback/>
            }
            </UserLayout>;
}
export default ComplaintFeedbackPage;