import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { AvatarImg } from "../../../commonComponents/Images";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { getFriendsInSuggestion, postUserSentReqest } from "../../../services/friends.service";
import { useTranslation } from "react-i18next";
import { showSingleToastSuccess, showToastSuccess } from "../../../utils/helpers";
import { useNavigate } from "react-router-dom";
import Loader from "../../../commonComponents/Loader";
import { getImageUrl } from "../../../utils/helpers";
const FindFriends = ({ searchBy, countsOfFriends,callParentCountMethod }) => {
    let defaultUserImage = '/Assets/Img.png';
    const { t } = useTranslation();
    const [suggestFriendList, setSuggestFriendList] = useState([]);
    const [noReqMsg, setNoReqMsg] = useState(false);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const limit = 18;
    const [totalCount, setTotalcount] = useState(0);
    const [PageNo, setPageNo] = useState(0);
    const observer = useRef();

    const navigate = useNavigate();
    const lastElement = (node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((enteries) => {
            if (enteries[0].isIntersecting && hasMore) {
                setPageNo((prev) => prev + 1);
            }
        })
        if (node) {
            observer.current.observe(node);
        }
    }
    useEffect(() => {
        setSuggestFriendList([]);
        setPageNo(0)
    }, [searchBy]);

    useEffect(() => {
        getSuggestedList(PageNo);
    }, [searchBy, PageNo])

    const getSuggestedList = async (pageNo) => {
        setLoading(true);
        setTimeout(async () => {
            const response = await getFriendsInSuggestion(searchBy, PageNo, limit)
                .then((response) => {

                    if (response?.data?.data) {
                        const user = response?.data?.data?.data;
                        const user2 = user.map(a => ({ ...a, cancelReqIS: false }));
                        let newSuggestion = [...suggestFriendList, ...user2];
                        if (PageNo === 0) newSuggestion = [...user2]
                        let newArr = newSuggestion.reduce((acc, curr) => {
                            let fnd = acc.find((a) => a._id === curr?._id);
                            if (!fnd) acc.push(curr);
                            return acc;
                        }, []);
                        newArr=newArr?.filter(a=>!a?.cancelReqIS);
                        setHasMore(response?.data?.data?.data?.length > 0 && user?.length === limit);
                        setSuggestFriendList(newArr);
                        countsOfFriends(response?.data?.data?.totalCount);
                        setNoReqMsg((response?.data?.data?.totalCount) > 0 ? false : true);

                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                })
        }, 700)

    }

    const addFriendRequest = async (item,index1) => {
        const params = {
            action: 'sendRequest',
            list: [item?._id]

        }
        await postUserSentReqest(params)
            .then((res) => {
                if (res) {
                    const nextList = [...suggestFriendList];
                    let index = nextList?.findIndex(a => a._id === item?._id);
                    if (index >= 0) {
                        nextList[index]['cancelReqIS'] = true;
                        setSuggestFriendList(nextList);
                        let pageNo1=Math.floor(index1/limit);
                        //setPageNo(pageNo1);
                        getSuggestedList(pageNo1)
                        suggestFriendList?.splice(index,1);
                        // showToastSuccess(t(`apiMessages.FRIEND_REQUEST_SENT`));
                        showSingleToastSuccess(t(`apiMessages.FRIEND_REQUEST_SENT`));
                        callParentCountMethod();
                    }
                }

            })
            .catch((error) => console.log(error));
    }

    const showUser = (user) => {
        let encrptedId = window.btoa(user?._id);
        //navigate(`/profile/${encrptedId}`)
        navigate(`/profile/${encrptedId}`, { state: { userId: user?._id } })

    }

    return (
        <Row className="mt-4" >
            {suggestFriendList.length > 0 ? <>
                {suggestFriendList.length > 0 && suggestFriendList.map((item, index) => {
                    if (suggestFriendList.length === index + 1) {
                        return <Col md={3} key={index} className="mb-4 col-lg-2 col-sm-4" ref={lastElement}>
                            <div className="widget-box  friends-list-box h-100">
                                <div className="d-flex flex-column h-100">
                                    <div onClick={() => showUser(item)}>
                                        <AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : defaultUserImage} />
                                        <p> {(`${item?.firstName} ${item?.lastName}`) || ''}</p>
                                        <small>{item?.userName? `@${item?.userName}`:''}</small>

                                    </div>
                                    {item.cancelReqIS ?
                                        <button className="button success-hover full one " disabled> <img src="/Assets/Add-Friend-Icon-inactive.png" className='imageInfindFriend' /> {t('friendsMsg.FRIEND_ADDED_MSG')}</button>
                                        : <button className="button success-hover full two " onClick={() => addFriendRequest(item)}> <img src="/Assets/Add-Friend-Icon-inactive.png" className='imageInfindFriend' /> {t('friendsMsg.ADD_FRIEND')}</button>
                                    }
                                </div>
                            </div>

                        </Col>
                    }
                    else {
                        return <Col md={3} key={index} className="mb-4 col-xl-2 grid-column" >

                            <div className="widget-box  friends-list-box h-100">
                                <div className="d-flex flex-column h-100">
                                    <div onClick={() => showUser(item)}>
                                        <AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : defaultUserImage} />
                                        <p> {(`${item?.firstName} ${item?.lastName}`) || ''}</p>
                                        <small>{item?.userName? `@${item?.userName}`:''}</small>

                                    </div>
                                    {item.cancelReqIS ?
                                        <button className="button success-hover full one " disabled> <img src="/Assets/Add-Friend-Icon-inactive.png" className='imageInfindFriend' /> {t('friendsMsg.FRIEND_ADDED_MSG')}</button>
                                        : <button className="button success-hover full two " onClick={() => addFriendRequest(item,index)}> <img src="/Assets/Add-Friend-Icon-inactive.png" className='imageInfindFriend' /> {t('friendsMsg.ADD_FRIEND')}</button>
                                    }
                                </div>
                            </div>

                        </Col>
                    }
                }
                )}
            </>
                : noReqMsg === true ? <h4>{t('friendsMsg.RECORD_NOT_FOUND')}</h4> : ''}

            {
                loading ?
                    <Loader />
                    : ''
            }
        </Row>
    )
}

export default FindFriends;