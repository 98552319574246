import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "../commonComponents/popup";
import { getImageUrl } from "../utils/helpers";
const CoverImagePopUp= ({open, handleClose,imageShow}) => {
    const {t} = useTranslation();
    return(
        <Popup open={open}  handleClose={ handleClose} modalBodyClasses="coverImgPopup" modalContentClasses="coverImgPopupContent"> 
         {imageShow &&
          <img src={imageShow} />
         }     
        </Popup>
    )
}

export default CoverImagePopUp;