const apiUrl = process.env.REACT_APP_API_URL;

let ApiConfig = {
  token: null,

  //Homepage API's
  blog: `${apiUrl}blog/blogList`,
  testimonial: `${apiUrl}testimonial/testimonialList`,
  setSubscription: `${apiUrl}user/news-letter`,
  unSubscribe: `${apiUrl}user/unsubscribe-news-letter`,
  
  //Authentication API's
  login: `${apiUrl}user/login`,
  register: `${apiUrl}user/register`,
  otpVerify: `${apiUrl}user/verify-otp`,
  getUserByToken: `${apiUrl}user/profile`,
  forgotPasswordOTP: `${apiUrl}user/forgot-password`,
  resetPassword: `${apiUrl}user/forgot-password/verify`,
  resendRegisterOtp: `${apiUrl}/user/resend-otp`,
  userOrganizationStatus: `${apiUrl}user/organization-role-info`,
  findUser: `${apiUrl}user/find-user`,

  //SuperAdmin Login
  superLogin: `${apiUrl}user/admin/login`,

  //Social Login 
  socialLogin: `${apiUrl}user/social-register`,
  linkedInUserDetails: `${apiUrl}user/linkedin-profile`,

  //OnBoarding Api's
  getUserDetails: `${apiUrl}user/profile-detail`,
  saveUserDetails: `${apiUrl}user/profile`,
  verifyEmailMobileOtp: `${apiUrl}user/verify-email-mobile-otp`,
  emailMobileAvailable: `${apiUrl}user/email-mobile-available`,
  organizationList: `${apiUrl}organization/org`,
  manageOrganizationList: `${apiUrl}user/org/list`,
  imageRequestIs: `${apiUrl}user/file-upload`,
  createOrCheckOrg: `${apiUrl}organization/check-organization`,

  //Listing Api's
  interestList: `${apiUrl}user/interest`,
  usernameSuggestions: `${apiUrl}user/username-suggestions`,
  checkUsernameAvailability: `${apiUrl}user/username-available`,
  getInvitesList: `${apiUrl}user/invites`,
  FriendRequest: `${apiUrl}user/request-action`,
  searchUsers: `${apiUrl}user/search`,

  refreshToken: `${apiUrl}user/refresh-token`,

  //Account Settings
  updatePassword: `${apiUrl}user/change-password`,

  // User Setting
  getGeneralSetting: `${apiUrl}user/general-setting`,
  // Block User 
  getBlockUsers: `${apiUrl}user/friend-list`,
  // Unblock user
  userAction: `${apiUrl}user/request-action`,
  userReport: `${apiUrl}user/report`,

  //friends Apis
  getSuggestedFriends: `${apiUrl}user/find-friend-suggestion`,
  userSentRequestAcion: `${apiUrl}user/request-action`,
  userAllsentRequest: `${apiUrl}user/friend-list?listType=sentFriendRequestList`,
  userAllrecRequest: `${apiUrl}user/friend-list?listType=receivedFriendRequestList`,
  userCalcelFriendRequest: `${apiUrl}user/request-action`,
  getUsersBySearchRequest: `${apiUrl}user/friend-search`,
  userAccepOrDeletetFriendRequest: `${apiUrl}user/request-action`,
  getAllFriendsAdded: `${apiUrl}user/friend-list?listType=friendList`,
  getFriendsListingApi: `${apiUrl}user/friend-list`,
  getSomeUserData: `${apiUrl}user/find-user?userId=`,
  postSomeUserData: `${apiUrl}user/find-user`,
  inviteFriendByMail: `${apiUrl}user/friend/invite`,
  defaultprofileUrl: `${apiUrl}user/default-profile?`,
  OrgAndUserCount: `${apiUrl}user/friends-count`,


  //Add,edit,delete Org
  updateUserEmp: `${apiUrl}/user/organization`,
  updateAdditionalEmpDetails: `${apiUrl}/user/updateUserAdditional`,

  //Notifications API
  notificationCount: `${apiUrl}user/notification-count`,
  notificationList: `${apiUrl}user/notification`,
  notificationMark: `${apiUrl}user/notification-mark`,
  notificationMarkById: `${apiUrl}user/notification-mark-by-id`,

  //userInterest API
  userInterestList: `${apiUrl}/user/interest?userId=`,



  //orglisting API
  getOrgnizationList: `${apiUrl}user/organization/following`,
  getOrganizationSuggetion: `${apiUrl}user/organization/suggestion`,

  //target Listing
  targetApi: `${apiUrl}user/target`,
  targetDetail: `${apiUrl}user/target-detail`,
  userOrganizationTarget: `${apiUrl}user/organization-target`,
  userOrgInvitedtargetApi: `${apiUrl}user/invited-target`,
  targetStatusApi: `${apiUrl}user/target/request-status`,
  eventStatusApi: `${apiUrl}user/event/pending-request`,

  //SearchEmail By User
  searchUserByEmail: `${apiUrl}user/searchByEmail`,
  registerByEmail: `${apiUrl}user/register-by-email`,  //on target and event invite popup

  //Events API's
  getTargetDropdown: `${apiUrl}user/target-list`,
  eventByTarget: `${apiUrl}user/target/event-list`,
  registerValidatorByEmail: `${apiUrl}/user/register-validator-by-email`,
  searchValidator: `${apiUrl}user/search-validator`,
  eventApi: `${apiUrl}user/event`,
  eventPostUpdateApi: `${apiUrl}user/event/post-id`,
  eventDetail: `${apiUrl}user/event-detail`,
  eventDetailByDate: `${apiUrl}user/event-detail-by-date`,
  eventDetailByDateAdmin: `${apiUrl}user/event-detail-by-date-admin`,
  getEventParticipants: `${apiUrl}/user/event-participants`,
  manageEventParticipants: `${apiUrl}user/manage-event-members`,
  interestedEventUser: `${apiUrl}user/event/interest-action?eventId`,
  interestedList: `${apiUrl}user/event/interested?eventId`,
  invitedList: `${apiUrl}user/event/invite-people?eventId`,
  remindEventApi: `${apiUrl}user/event-remind`,
  eventTargetInvites: `${apiUrl}user/target/invite`,

  //All Events
  AllEvents: `${apiUrl}user/event`,

  //Complaint and Feedback
  raiseComplaintFeedback: `${apiUrl}utils/suggestion`,
  getComplaintFeedbackData: `${apiUrl}utils/get-suggestion`,
  //validator Apis
  eventByDate: `${apiUrl}user/validator-detail?date=`,
  postValidatorInfo: `${apiUrl}user/validator-detail`,
  postBankData: `${apiUrl}user/wallet`,
  getBankData: `${apiUrl}user/wallet?userId=`,
  validatoroff: `${apiUrl}user/mark-validator-off`,
  getEvidenceByDate: `${apiUrl}user/event-evidence?eventId=`,
  evidencePost: `${apiUrl}user/event-evidence`,
  markValidator: `${apiUrl}user/mark-event-completed`,
  submitValidatorReport: `${apiUrl}user/event-report`,
  validatorEventStatus: `${apiUrl}user/validator/change-request-status`,
  eventMarking: `${apiUrl}user/event/mark-activity`,
  validatorDates: `${apiUrl}user/validator-detail-by-range`,
  deleteAvailibilty: `${apiUrl}user/delete-availability`,
  validatorEvents: `${apiUrl}user/validator/event-info`,
  validatorBasicInfo: `${apiUrl}user/validator-basic-info`,
  deleteEvidence: `${apiUrl}user/event-evidence`,

  //Posts
  createPost: `${apiUrl}voxbox/post/create`,
  getMyPosts: `${apiUrl}voxbox/my/feed`,
  getAllPosts: `${apiUrl}voxbox/all/feed`,
  userOrgFeeds: `${apiUrl}voxbox/userfeeds`,
  publicInterestFeeds: `${apiUrl}voxbox/interestFeeds`,
  getPost: `${apiUrl}voxbox/post/details`,
  getSelectedPost: `${apiUrl}voxbox/post/sharing`,
  getMyMemories: `${apiUrl}voxbox/memories`,
  createComment: `${apiUrl}voxbox/comment/create`,
  commentReply: `${apiUrl}voxbox/comment/reply`,
  deletePost: `${apiUrl}voxbox/post/delete`,
  deleteComment: `${apiUrl}voxbox/comment/delete`,
  deleteReply: `${apiUrl}voxbox/reply/delete`,
  postReacttion: `${apiUrl}voxbox/post/like`,
  commentReacttion: `${apiUrl}voxbox/comment/like`,
  replyReacttion: `${apiUrl}voxbox/reply/like`,
  editPost: `${apiUrl}voxbox/post/edit`,
  editComment: `${apiUrl}voxbox/comment/edit`,
  editReply: `${apiUrl}voxbox/reply/edit`,
  hidePost: `${apiUrl}voxbox/post/hide`,
  reportPost: `${apiUrl}voxbox/post/report`,
  featurePost: `${apiUrl}voxbox/post/featured`,
  eventPosts: `${apiUrl}voxbox/post/event`,
  sharePost: `${apiUrl}voxbox/post/share`,
  whoCanCommentPost: `${apiUrl}voxbox/comment/commentPrivacy`,
  postLikeList: `${apiUrl}/voxbox/post/likeList`,
  getTagSuggestions: `${apiUrl}user/search`,
  getVoxboxProfile: `${apiUrl}voxbox/userProfile`,
  getLinkData: `${apiUrl}voxbox/external-url`,

  //Event Report
  eventReport: `${apiUrl}user/event-detail`,
  reportUser: `${apiUrl}user/report-users-list`,



  //wallet
  getWalletData: `${apiUrl}wallet/`,


  //Organizations
  generateOrgToken: `${apiUrl}organization/auth`,
  OrgrefreshToken: `${apiUrl}organization/refresh-token`,
  orgDetails: `${apiUrl}organization/profile`,
  orgUsernameSuggestions: `${apiUrl}organization/username-suggestions`,
  orgUsernameAvailability: `${apiUrl}organization/username-available`,
  getLegalServiceType: `${apiUrl}organization/legal-service-type`,
  newLegalStructure: `${apiUrl}organization/orglegal`,
  newServiceType: `${apiUrl}organization/orgbusinesstype`,
  orgVerifyEmailMobileOtp: `${apiUrl}organization/verify-otp/email-mobile`,
  orgEmailMobileAvailable: `${apiUrl}organization/send-otp/email-mobile`,
  OrgInterests: `${apiUrl}organization/interest`,
  OrgGeneralSettings: `${apiUrl}organization/general-setting`,
  followOrganization: `${apiUrl}user/organization/request-action`,
  followOrgList: `${apiUrl}user/organization/following`,
  orgActionRequest: `${apiUrl}organization/request-action`,
  orgInviteUser: `${apiUrl}organization/invites`,
  orgUserStatus: `${apiUrl}organization/user-status`,
  orgStatus: `${apiUrl}organization/verify`,

  // follower list
  getFollowers: `${apiUrl}organization/request-list`,
  reqRejectAcceptRemove: `${apiUrl}organization/employee-request-action`,
  roleList: `${apiUrl}organization/roles`,
  orgAssigningRole: `${apiUrl}organization/manage-role`,
  orgRemoveRole: `${apiUrl}organization/manage-role`,
  bulkUploadInvite: `${apiUrl}user/bulk-upload`,


  // Admin manage
  getAllUserList: `${apiUrl}user/user-list`,
  getPostUser: `${apiUrl}voxbox/post-user`,
  getAllOrganizationList: `${apiUrl}user/organization-list`,
  getEmployeeList: `${apiUrl}organization/request-list?listType=employeeList`,

  removeOrgAdmin: `${apiUrl}organization/remove-organization-admin`,
  changeOrgAdmin: `${apiUrl}organization/change-page-admin`,
  canChangeOrgAdmin: `${apiUrl}organization/can-change-page-admin`,
  createOrgAdmin: `${apiUrl}organization/register`,
  passwordResetReq: `${apiUrl}user/password-request`,

  //globalSearch
  getAllGlobal: `${apiUrl}user/global-search`,

  //Post review
  orgReviewFeeds: `${apiUrl}voxbox/org/review/feed`,
  hidePostByReviewer: `${apiUrl}voxbox/org/review/postVisibility`,

  //reported post
  getReportPost: `${apiUrl}voxbox/post/getReport`,

  //CMS
  getLanguageList: `${apiUrl}utils/language`,
  getCmsData: `${apiUrl}utils/cms/getList`,
  getCmsList: `${apiUrl}utils/cms/list`,
  postCmsData: `${apiUrl}utils/cms/postList`,
  blogsApi: `${apiUrl}utils/blogList`,
  getBlog: `${apiUrl}utils/getBlog`,
  testimonialsApi: `${apiUrl}utils/testimonialList`,
  faqApi: `${apiUrl}utils/faq`,
  getTestimonial: `${apiUrl}utils/getTestimonial`,

  //vendors
  getVendors: `${apiUrl}organization/vendor`,
  postVendors: `${apiUrl}organization/vendor`,
  deleteVendor: `${apiUrl}organization/vendor?vendorId=`,
  updateVendorStatus: `${apiUrl}organization/vendor-status`,

  //impacts
  ImpactsAre: `${apiUrl}user/impact`,
  OverAllImpact: `${apiUrl}user/overall-impact`,
  participantImpact: `${apiUrl}user/participant-impact`,

  //delete Accounts
  deleteOrgAccount: `${apiUrl}organization/delete/account`,
  deleteUserAccount: `${apiUrl}user/delete/account`,
  userLoginLog: `${apiUrl}user/login-log`,
  getIPAddress: `https://geolocation-db.com/json/`,

  //Activity
  loginActivityList: `${apiUrl}user/activity-log`,
  getEmpIds: `${apiUrl}organization/request-list`,
  targetCount: `${apiUrl}user/target-count`,

  //Payment API
  getOrderId: `${apiUrl}wallet/razorpay`,
  paymentSessionResponse: `${apiUrl}wallet/payment/verify`,
  transactionList: `${apiUrl}wallet/list`,
  adminTransactionList: `${apiUrl}wallet/payment/list`,

  //Invite user to platform
  inviteUserToPlatform:`${apiUrl}user/referral-link`,
  invitedUserList:`${apiUrl}user/referral-link/list`
}

export default ApiConfig;