import React, { useEffect , useState} from "react";
import { getValidatorBasicInfoService,
    getValidatorEventsService
 } from "../../../services/event.services";
import { postAnyUserData } from "../../../services/friends.service";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row , Col} from "react-bootstrap";
import RoundRadio from "../../../commonComponents/roundCheckbox/roundRadio";
import ValidatorEventsBox from "../../../commonDashboardComp/validatorEventsBox";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import BarsLoader from "../../../commonComponents/barsLoader";
import ReactPagination from "../../../commonComponents/reactPagination";
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import { getImageUrl } from "../../../utils/helpers";

const ValidatorProfile = () => {
    const {t} = useTranslation()
    const {id} = useParams();
    const validatorId = window.atob(id);
    const [userInfo, setUserInfo] = useState({})
    const [validatorInfo, setValidatorInfo] = useState(null);
    
    const limit = 10;
    const [isLoading, setLoading] = useState(false);
    const [validatorEvents, setValidatorEvents] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPageNo, setCurrentPageNo] = useState(0);

    useEffect(()=>{
        if(validatorId !== undefined || validatorId !== null){
            getValidatorInfo();
            getValidatorEvents();
        }
    }, [validatorId])

    useEffect(()=>{
        getValidatorEvents()
    }, [currentPageNo])

    useEffect(() => {
        if(validatorId !== undefined || validatorId !== null){getUserDetails();}
    }, [validatorId]);

    const getUserDetails = async () => {
        const response = await postAnyUserData(validatorId).
        then((response)=>{
            if(response?.status===200){
                setUserInfo(response?.data?.data);
            }  
        })
        .catch((err)=>{
            console.log(err);
        })  
    }

    const getValidatorInfo = () => {
        getValidatorBasicInfoService(validatorId)
        .then((data)=>{
            if(data?.data?.status === 200){
                setValidatorInfo(data?.data?.data);
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const getValidatorEvents = async () => {
        setLoading(true)
        getValidatorEventsService(validatorId,currentPageNo, limit)
          .then((response) => {
            if (response.data.status === 200) {
              setValidatorEvents(response.data.data.data)
              let pageCount = (response?.data?.data?.totalCount) / limit;
              setTotalPage(Math.ceil(pageCount));
            }
            setLoading(false)
          })
          .catch((error) => {
            console.log(error);
          })
    }

    const handlePageClick = (data) => {
        let Page = data.selected
        setCurrentPageNo(Page)
    }

    const onDownloadClick = (e) => {
        e.preventDefault();
        fetch(getImageUrl(userInfo?._id, 'validatorCv', validatorInfo?.cv)).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `${userInfo?.firstName}-${userInfo?.lastName}-CV.pdf`;
                alink.click();
            })
        })
    }

    const makeButtons = () => {
        return (<div className="ff-btn-area viewEventBannerBlk">
                    <div className="col-md-12 banner-btn-area event-btn">
                        <button onClick={onDownloadClick} target="_blank" className="btn btn-secondary green" >{t('Download_CV')}</button>
                    </div>
                </div>
            )
    }
    //buttons={makeButtons()}

    return (
        <>
            <Row> <ProfileHeader detail={userInfo}  /> </Row>

            <Row className="grid grid-9-3 mobile-prefer-content">
                <Col md={12} className="grid-column">
                    <Row className="grid grid-9-3 mobile-prefer-content">
                        <Col md={12}>
                            <div className="widget-box">
                                <p className="widget-box-title evidenceHeading">{t('Validator_Details')} </p>
                                <Row className="grid">
                                    <div className="grid-column">
                                        <div className="widget-box orgBlock">
                                            <div className="widget-box-content newTargetTag">
                                                <Row className="grid">
                                                    <Col md={3}>
                                                        <h6 className="mb-20">{t('region')}</h6>
                                                        <RoundRadio name="modeOfParticipation"
                                                            label={validatorInfo?.city}
                                                            checked={true}
                                                            readOnly
                                                            id='modeofParticpant'
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        {( validatorInfo?.fixed_rate !== null || 
                                                            validatorInfo?.rate_per_hour!==null ||
                                                            validatorInfo?.rate_per_participant !== null
                                                        ) &&
                                                        <h6 className="mb-20">{t('Rate')}</h6>}
                                                        {validatorInfo?.fixed_rate !== null && <RoundRadio name="fixed_rate"
                                                            label={`${t('fixed_rate')} : ${validatorInfo?.fixed_rate}`}
                                                            checked={true}
                                                        />}
                                                        {validatorInfo?.rate_per_hour!==null && <RoundRadio name="rate_per_hour"
                                                            label={`${t('rate_per_hour')} : ${validatorInfo?.rate_per_hour}`}
                                                            checked={true}
                                                        />}
                                                        {validatorInfo?.rate_per_participant !== null && <RoundRadio name="rate_per_participant"
                                                            label={`${t('rate_per_participant')} : ${validatorInfo?.rate_per_participant}`}
                                                            checked={true}
                                                        />}
                                                    </Col>
                                                    <Col>
                                                      {validatorInfo?.currency &&
                                                      <>
                                                         <h6 className="mb-20">Currency</h6>
                                                         <RoundRadio name="modeOfCurrency"
                                                            label={validatorInfo?.currency}
                                                            checked={true}
                                                            readOnly
                                                            id='currency'
                                                        />
                                                         </>
                                                      }
                                                    </Col>
                                                </Row>
                                            </div>

                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="grid">
                <div className="grid-column">
                    <div className="widget-box">
                    <h6 className="widget-box-title">{t('Validator_Completed_Events')}</h6>

                    <>
                        {isLoading ? <BarsLoader /> : validatorEvents.length > 0 ?
                        <Row>
                            <div className="widget-box-content col-lg-12 col-md-6 col-12 m-0 mb-2">
                            <div className="all-event-box validatorEventPageMain">
                            {
                                validatorEvents.length > 0 && <>
                                    {validatorEvents.map((item, i) => (
                                        <div key={i} className="my-3">
                                            <ValidatorEventsBox
                                            eventData={item}
                                            />
                                        </div>
                                        ))
                                    }
                                </>
                            }
                            </div>
                            </div>
                        </Row> :
                        <>
                            {validatorEvents.length === 0 &&
                            <>
                                <div style={{ display: 'flex', marginTop: 10 }}>
                                {t('errors.There_is_no_Events')}
                                </div>
                            </>
                            }
                        </>
                        }

                    </>
                    <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />

                    </div>
                </div>
            </Row>
        </>
    )
}   

export default ValidatorProfile