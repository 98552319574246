import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, TextField } from '@mui/material';
import FormTextArea from '../../../commonComponents/formTextArea';
import BarsLoader from '../../../commonComponents/barsLoader';
import ReactPagination from '../../../commonComponents/reactPagination';
import TabNavigation from '../../../commonDashboardComp/tabNavigation';
import Popup from '../../../commonComponents/popup';
import { faqCategories } from '../../../config/constants';
import { editFaqService, getFaqService } from '../../../services/cms.services';
import { showSingleToastSuccess } from '../../../utils/helpers';
import FaqCmsBox from './faqCmsBox';
// Draft Js imports
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// Draft Js imports
const FaqCms = ({
  list,
  handlePageClick,
  totalPage,
  isLoading,
  addFaqs,
  getFaqs,
  language,
}) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(1);
  const tabs = [t('faq')];
  const [error, setError] = useState(false);
  const [show, setShow] = useState(true);
  const [faq, setFaq] = useState({
    id: '',
    category_id: '',
    ques: '',
    answer: '',
  });
  const [faqItem, setFaqItem] = useState();
  const [open, setOpen] = useState(false);
  const [loader, setLoad] = useState(false);
  const [type, setType] = useState('add');
  const [editedItem, setEditedItem] = useState();
  const handleOpen = (e, t) => {
    e.preventDefault();
    setOpen(true);
    setType(t);
    switch (t) {
      case 'add':
        setFaqItem({});
        setEditorState(EditorState.createEmpty());
        break;
      case 'edit':
        setFaqItem({});
        break;
      default:
        break;
    }
  };
  const handleClose = (e) => {
    setOpen(false);
  };

  const makeButton = () => {
    return (
      <Link
        className="button secondary popup-event-creation-trigger"
        to="#"
        onClick={(e) => {
          handleOpen(e, 'add');
        }}
      >
        + {t('Add_Faq')}
      </Link>
    );
  };

  const getData = () => {
    getFaqService(language)
      .then((data) => {
        if (data?.data?.status === 200) {
          setFaq({ ...faq, ...data?.data?.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);

    if (type === 'add') {
      if (
        Object.keys(faqItem).length == 0 ||
        faqItem?.category_id === '' ||
        faqItem?.ques === '' ||
        faqItem?.answer === ''
      ) {
        setError(true);
        return;
      }

      let param = {
        category_id: faqItem?.category_id,
        ques: faqItem?.ques,
        answer: faqItem?.answer,
      };
      setLoad(true);
      await addFaqs(param);
      setLoad(false);
      handleClose();
      setFaq({ category_id: '', ques: '', answer: '' });
      setFaqItem({});
      setEditorState(EditorState.createEmpty());
    }

    if (type === 'edit') {
      if (
        faqItem?.category_id === '' ||
        faqItem?.ques === '' ||
        faqItem?.answer === ''
      ) {
        setError(true);
        return;
      }

      let param = {
        id: faqItem?.id,
        categoryId: faqItem?.category_id,
        question: faqItem?.ques,
        answer: faqItem?.answer,
        langCode: language
      };
      setLoad(true);
      try {
        editFaqService(param)
          .then(async (data) => {
            if (data?.data?.status === 200) {
              await getFaqs();
              showSingleToastSuccess(t('apiMessages.QUES_UPDATED_SUCCESS'));
              handleClose();
              setLoad(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  //Draft Js
  const [editorState, setEditorState] = useState();
  const saveEditorContent = () => {
    const content = editorState.getCurrentContent();
    setFaqItem({
      ...faqItem,
      answer: convertToRaw(content),
    });
  };

  useEffect(() => {
    if (editedItem && Object.keys(editedItem).length !== 0) {
      setFaqItem({ ...editedItem });
      setEditorState(
        EditorState.createWithContent(convertFromRaw(editedItem.answer))
      );
    }
  }, [editedItem]);
  //Draft Js
  return (
    <>
      <TabNavigation
        tabs={tabs}
        setTab={setTab}
        activeTab={tab}
        showInput={false}
        button={makeButton()}
      />

      <Row className="grid">
        <div className="grid-column">
          <div className="">
            <>
              {isLoading ? (
                <BarsLoader />
              ) : show && list?.length > 0 ? (
                <Row>
                  {list?.map((item, i) => {
                    return (
                      <div
                        className="widget-box-content col-lg-12 col-md-6 col-12 m-0 mb-2 my-md-2"
                        key={i}
                      >
                        <FaqCmsBox
                          data={item}
                          index={i}
                          handleOpen={handleOpen}
                          handleSubmit={handleSubmit}
                          setEditedItem={setEditedItem}
                        />
                      </div>
                    );
                  })}
                </Row>
              ) : (
                <>
                  {show && list?.length === 0 && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: 10,
                          justifyContent: 'center',
                        }}
                      >
                        {t('no_faq')}
                      </div>
                    </>
                  )}
                </>
              )}
            </>
            <ReactPagination
              pageCount={totalPage}
              onPageChange={handlePageClick}
            />
          </div>
        </div>

        <Popup
          heading={t('Add_Faq')}
          open={open}
          handleClose={handleClose}
          className="AddBlog-popup targetPopup"
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '98%' },
            }}
            noValidate
            autoComplete="off"
            className="userdetail-form mobile_profile_margin row"
            onSubmit={handleSubmit}
          >
            <Row>
              <Col lg={4} md={6}>
                <Row className="grid">
                  <div className="grid-column myProfileSettings">
                    <div className="widget-box area-widget">
                      <div className="widget-box-content">
                        <Col md={12}>
                          {
                            ((error == true && faqItem.category_id == undefined)||
                            (error == true && faqItem.category_id == ''))?
                            <p className='text-danger mb-2'>{t('errors.faq_category_required')}</p>:''
                          }
                          <TextField
                            name="category"
                            label="Category"
                            id="category"
                            placeholder="Select Category"
                            SelectProps={{
                              native: true,
                            }}
                            select
                            value={faqItem?.category_id || ''}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              classes: {
                                notchedOutline: 'rounded',
                              },
                            }}
                            onChange={(e) => {
                              setFaqItem({
                                ...faqItem,
                                category_id: e.target.value,
                              });
                            }}
                            //   error={
                            //     formik.touched.country &&
                            //     Boolean(formik.errors.country)
                            //   }
                            //   helperText={
                            //     formik.touched.country && formik.errors.country
                            //   }
                          >
                            <option key={'Select Category'} value={''}>
                              {t('Select_Category')}
                            </option>

                            {faqCategories &&
                              faqCategories?.map((cat) => (
                                <option key={cat.value} value={cat.value}>
                                  {cat.label}
                                </option>
                              ))}
                          </TextField>
                        </Col>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row className="grid">
                  <div className="grid-column myProfileSettings">
                    <div className="widget-box area-widget">
                      <div className="widget-box-content">
                        <Col md={12}>
                          {
                            ((error == true && faqItem.ques == undefined)||
                            (error == true && faqItem.ques == ''))?
                            <p className='text-danger mb-2'>{t('errors.faq_ques_required')}</p>:''
                          }
                          <FormTextArea
                            label={t('superAdmin.ques')}
                            value={faqItem?.ques || ''}
                            onChange={(e) =>
                              setFaqItem({ ...faqItem, ques: e.target.value })
                            }
                          />
                        </Col>
                        {error && faqItem?.ques === '' && (
                          <div className="error-text">
                            {' '}
                            {t('Enter_Question')}{' '}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Row>
              </Col>
              <Col md={8}>
                <Row className="grid">
                  <div className="grid-column myProfileSettings">
                    <div className="widget-box area-widget">
                      <div className="widget-box-content">
                        <Col md={12} className='cmsEditorContainer'>
                          {
                            ((error == true && faqItem.answer == undefined)||
                            (error == true && faqItem.answer == ''))?
                            <p className='text-danger mb-2'>{t('Enter_Answer')}</p>:''
                          }
                          <Editor
                            onChange={saveEditorContent}
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                          />
                        </Col>
                        {error && faqItem?.answer === '' && (
                          <div className="error-text">
                            {' '}
                            {t('Enter_Answer')}{' '}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Row>

                <button type="" className="button" onClick={handleSubmit}>
                  {t('Submit')}
                  {loader && <span className="spinner-border" />}
                </button>
              </Col>
            </Row>
          </Box>
        </Popup>
      </Row>
    </>
  );
};
export default FaqCms;
