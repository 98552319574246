import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import { CircularProgressbar } from 'react-circular-progressbar';
import { AvatarImg } from "../commonComponents/Images";
import { changeDateFormatmmddyy, dateTimeEventFormat, getImageUrl, showSingleToastSuccess, showToastError, showToastSuccess, timeFormat } from "../utils/helpers";
import { Link } from "react-router-dom";
import { changeDateFormatddmmyyyy, dateTimeFormat } from "../utils/helpers";
import { deleteTargetService, acceptRejectTargetService } from "../services/onBoarding.services";
import { deleteNotificationsService } from "../services/onBoarding.services";
import { PinTargetIcon } from "../commonComponents/Images";
import { targetTypeList } from "../config/constants";
import moment from 'moment/moment';
import swal from 'sweetalert';
import Popup from "../commonComponents/popup";

const TargetDescriptionBox = ({ targetData, canChangeDetail = true, acceptReject = false, getAllCount }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [show, setShow] = useState(true)
    const [viewMore, setViewMore] = useState(false);
    const [showAssociateEvents, setShowAssociateEvents] = useState(false);
    const [copyText, setCopyText] = useState(targetData?._id);
    const [isCopied, setCopied] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const [acceptRejectBtn, setAcceptRejectBtn] = useState(0);
    const editTarget = (e) => {
        e.preventDefault();
        navigate('/add-target?type=edit', { state: { targetId: targetData?._id } })
    }

    const createNewEventHandler = (e) => {
        e.preventDefault();
        navigate('/add-event', { state: { targetId: targetData._id } })
    }

    const viewEventHandler = (e, id) => {
        e.preventDefault();
        navigate('/view-event', { state: { eventId: id } })
    }

    const AcceptRejectTargetHandler = (e, status) => {
        e.preventDefault();
        swal({
            text: (status === 'accept') ? t('alerts.accept_target') : t('alerts.cancel_target'),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
            .then(async (val) => {
                if (!val) return;
                let param = {
                    targetId: targetData?._id,
                    status: status
                }
                acceptRejectTargetService(param)
                    .then((data) => {
                        if (data?.data?.status === 200) {
                            setAcceptRejectBtn((status === 'accept') ? 1 : 2)
                            showSingleToastSuccess((status === 'accept') ? t('apiMessages.TARGET_ACCEPT_SUCCESS') : t('apiMessages.TARGET_REJECT_SUCCESS'));
                            getAllCount()
                        }
                        else {
                            showToastError(t('errors.some_error'));
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            });

    }

    const DeleteTargetHandler = (e) => {
        e.preventDefault();
        swal({
            text: t('alerts.delete_target'),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
            .then(async (val) => {
                if (!val) return;
                deleteTargetService(targetData._id)
                    .then((data) => {
                        if (data?.data?.status === 200) {
                            setShow(false)
                            deleteNotificationsService('targetDelete', targetData._id)
                            showSingleToastSuccess(t('apiMessages.TARGET_DELETE_SUCCESS'));
                            getAllCount()
                        }
                        else {
                            showToastError(t('errors.some_error'));
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            });
    }
    const copyTextChange = () => {
        navigator.clipboard.writeText(copyText)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 3000);
    }

    const getTargetCompletePercentage = () => {
        let percentage = 0;

        let totalUnits = targetData?.targetUnit;
        let completedUnits = 0;

        if (targetData?.events?.length > 0) {
            targetData?.events?.map((item) => {
                if (item?.unitCompleted != 0) {
                    completedUnits += parseInt(item?.unitCompleted);
                }
                else {
                    if (item?.eventEvidence && item?.eventEvidence.length > 0) {
                        // if(item?.eventEvidence[0].verified == true){
                        //     completedUnits += parseInt(item?.eventEvidence[0]?.unit);
                        // }
                        // else if(item?.eventEvidence[0].completed == true){
                        //     completedUnits += parseInt(item?.eventEvidence[0]?.unit);
                        // }
                        // else{
                        //     completedUnits += 0;
                        // }
                        item?.eventEvidence.map((item) => {
                            completedUnits += parseInt(item?.unit);
                        })
                    }
                    else {
                        completedUnits += 0;
                    }
                }

            })
            percentage = (completedUnits / totalUnits) * 100;
        }

        return percentage.toFixed(1);
    }

    const checkTargetUnits = () => {
        let totalUsedUnits = 0;

        if (targetData?.events?.length > 0) {
            targetData?.events?.map((item) => {
                totalUsedUnits += item?.unit;
            })
        }
        if (targetData?.targetUnit > totalUsedUnits) {
            return true;
        }

        return false;
    }

    const generateString = (s) => {
        let str = s?.length;
        if (!str) return <></>
        return <p>{s?.substring(0, 44)}{str > 45 ? <>... <a href="#!" onClick={() => handleOpen()}>Read More</a></> : <></>}</p>
    }
    let impactImgVal;
    switch (targetData.spredeInterestCategory.spredeInterest) {
        case 'earth':
            impactImgVal = "Earth-icon"
            break;
        case 'soul':
            impactImgVal = "Soul - Copy"
            break;
        case 'water':
            impactImgVal = "water"
            break;
        case 'fire':
            impactImgVal = "fire"
            break;
        case 'air':
            impactImgVal = "air"
            break;
        default:
            impactImgVal = "not working";
    }
    let taglineText = "";
    switch (targetData.spredeInterestSubCategory.spredeInterestOptions) {
        //Earth
        case 'Area Covered By Trees':
            taglineText = "Sq.M of Area To Be Covered With Trees";
            break;
        case 'Trees Planted':
            taglineText = "Trees To Be Planted";
            break;
        case 'Waste Recycled':
            taglineText = "KG of Waste To Be Recycled";
            break;
        case 'Plogging':
            taglineText = "KG of Garbage To Be Collected";
            break;
        case 'Compost Created':
            taglineText = "KG of Compost To Be Created";
            break;
        //Water
        case 'Renewable Electricity Consumption Hydro':
            taglineText = "K.W. of Renewable Energy To Be Produced";
            break;
        case 'Water Consumption Reduction':
            taglineText = "KL of Water Consumption To Be Reduced";
            break;
        case 'Beach Cleanup':
            taglineText = "KGs of Garbage To Be Collected";
            break;
        case 'Waste Water Recycled':
            taglineText = "KLs of Water To Be Recycled";
            break;
        case 'Water Body Rejuvenation':
            taglineText = "Water Bodies To Be Rejuvenated";
            break;
        //Fire
        case 'Electricity Consumption Reduction':
            taglineText = "K.W. Of Reduction In Electricity Consumption";
            break;
        case 'Fossil Fuel Consumption Reduction':
            taglineText = "KL Of Reduction In Fossil Fuel Consumption";
            break;
        case 'Renewable Electricity Consumption Solar':
            taglineText = "K.W. of Solar Energy To Be Used";
            break;
        case 'Cooking Gas Consumption Reduction':
            taglineText = "Cc Of Reduction In Cooking Gas Consumption";
            break;
        //Air
        case 'Public Transport Rides Taken':
            taglineText = "Rides of Public Transport To Be Taken";
            break;
        case 'Renewable Electricity Consumption Wind':
            taglineText = "K.W. of Wind Energy To Be Used";
            break;
        //Soul
        case 'Wildlife Conservation':
            taglineText = "Events To Be Hosted";
            break;
        case 'Women in Workforce':
            taglineText = "Women To Be Hired";
            break;
        case 'Training and Awareness':
            taglineText = "Hours To Be Hosted";
            break;
        case 'Vegetarian Meals':
            taglineText = "Vegetarian Meals To Be Eaten";
            break;
        case 'Payment to NGOs':
            taglineText = "Dollars To Be Donated To NGOs";
            break;
        case 'Time Spent with NGOs':
            taglineText = "Hours To Be Spent with NGOs";
            break;
        case 'Persons Hired':
            taglineText = "People To Be Hired";
            break;
    }

    const getDateTime = (item) => {
        let startDateTime = new Date(item?.startDate);
        let startHour, startMinutes;
        [startHour, startMinutes] = item?.startTime.split(':');
        startDateTime.setHours(startHour);
        startDateTime.setMinutes(startMinutes);
        return startDateTime;
    }

    const getCurrentDate = () => {
        let d = new Date();
        d.setHours(0, 0, 0, 0);
        return d;
    }

    return (<>
        {show && <><div className="bg-white borderRadius targetDescComp target_post_box">
            <div className="target_box_area mainTargetArea">
                <div className="align-self-center">
                    <div className="target_box_profile_area mainTargetProfileArea">
                        <div className="your_target_avatar targetAvatar">
                            <img src={(targetData?.photo !== '') ? getImageUrl(targetData?._id, 'target', targetData?.photo) : AvatarImg} alt="" />
                        </div>
                        <div className="target-box targetUserInfo">
                            <h6 className="">{targetData?.title}</h6>
                            <p>{targetData?.description.substring(0, 20) + '..'}</p>
                        </div>
                    </div>
                </div>
                <div className="target-box-content targetContent">
                    <div className="information-line target-para TargetInfoLine">
                        <p className="information-line-title category targetCategory">{t('category')}:</p>
                        <p className="information-line-text targetInfoData"><img src={"/Assets/" + impactImgVal + ".png"} className="" style={{ height: "24px", marginRight: "12px" }} />{t(`${targetData?.spredeInterestCategory?.spredeInterest}`)}</p>
                    </div>
                    <div className="information-line target-para TargetInfoLine">
                        <p className="information-line-title noSpace">{t('sub_category')}:</p>
                        <p className="information-line-text targetInfoData">{(t(`${targetData?.spredeInterestSubCategory?.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) !== `${targetData?.spredeInterestSubCategory?.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) ? t(`${targetData?.spredeInterestSubCategory?.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) : targetData?.spredeInterestSubCategory?.spredeInterestOptions.substring(0, 20) + '..'}</p>
                    </div>
                </div>

                <div className="targetDateDetails">
                    <div className="information-line target-para TargetInfoLine">
                        <p className="information-line-title">{t('from')}</p>
                        <p className="information-line-text noSpace">{changeDateFormatddmmyyyy(targetData?.fromDate)}</p>
                    </div>
                    <div className="information-line target-para TargetInfoLine">
                        <p className="information-line-title">{t('to')}</p>
                        <p className="information-line-text noSpace">{changeDateFormatddmmyyyy(targetData?.toDate)}</p>
                    </div>

                </div>
                <div>
                    <div className="information-line target_box_progressbar pe-3 targetProgressBar open_box_circle">
                        {/* <p className="information-line-title" style={{ width: '80%' }}>#{targetData?.targetUnit} {targetData?.unitType}
                            <LinearProgress value={getTargetCompletePercentage()} className="target-progress" style={{ marginTop: '4px' }} variant="determinate" color="success" title={t('test')} />
                        </p>
                        <p style={{marginTop: '17px' }}>{getTargetCompletePercentage()}%</p> */}

                        <CircularProgressbar className="" value={getTargetCompletePercentage()} text={`${getTargetCompletePercentage()}%`} styles={{ text: { fill: '#000000' }, background: { fill: '#7ba73f' } }} />

                    </div>
                </div>
                <div className="text-center targetViewBtn d-flex mt-auto mb-0 mb-lg-auto">
                    <Link to="#" className="target-btn view-btn" onClick={(e) => { e.preventDefault(); setViewMore(!viewMore) }}>{(viewMore) ? t('button.view_less') : t('button.view_more')}</Link>
                </div>
            </div>
        </div>

            {viewMore && <>

                <div className="col-md-12 viewMoreBox bg-white overflow-hidden mt-4">
                    <div className="open_box">
                        <div className="col-lg-6 open_target_Left_box">
                            <div className="bi-thumb-img">
                                <img src={(targetData?.photo !== '') ? getImageUrl(targetData?._id, 'target', targetData?.photo) : AvatarImg} alt="" />
                            </div>
                            <div className="target-box open_target_box">
                                <h5>{targetData?.title}</h5>
                                {generateString(targetData.description)}
                                {/* <p>{targetData?.description.substring(0,44) + "..."} <a href="#!" onClick={()=>handleOpen()}>Read More</a></p> */}

                                <div className="information-line target-para info-m">
                                    <p className="information-line-title">{t('category')} : </p>
                                    <p className="information-line-text"> {t(`${targetData?.spredeInterestCategory?.spredeInterest}`)} </p>
                                </div>
                                <div className="information-line target-para info-m expandedModeInfo">
                                    <p className="information-line-title">{t('sub_category')}: </p>
                                    <p className="information-line-text">{(t(`${targetData?.spredeInterestSubCategory?.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) !== `${targetData?.spredeInterestSubCategory?.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) ? t(`${targetData?.spredeInterestSubCategory?.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) : targetData?.spredeInterestSubCategory?.spredeInterestOptions}</p>
                                </div>
                                <div className="information-line target-para info-m">
                                    <p className="information-line-title">{t('incentive')}: </p>
                                    <p className="information-line-text">{t(`${targetData?.incentive}`)}</p>
                                </div>
                                <div className="information-line target-para info-m">
                                    <p className="information-line-title">{t('Created_by')}: </p>
                                    <p className="information-line-text">{targetData?.createdBy === 'user' ? `${targetData?.userId?.firstName} ${targetData?.userId?.lastName}` : targetData?.createdBy === 'organization' ? `${targetData?.organizationId?.name}` : ""}</p>
                                </div>
                            </div>
                            <div className="open_box_circle">
                                <CircularProgressbar className="" value={getTargetCompletePercentage()} text={`${getTargetCompletePercentage()}%`} styles={{ text: { fill: '#000000' }, background: { fill: '#7ba73f' } }} />
                            </div>
                        </div>

                        <div className="col-lg-6 open_target_right_box">
                            <div className="col-12 col-md-12">
                                <div className="open_target_inner_right_box">
                                    <div className="newTargetDates">
                                        <p className="information-line-title open_box_heading" style={{ fontSize: '25px', color: '#bbbbbb', fontWeight: 'bold' }}>#{targetData?.targetUnit} {taglineText}</p>
                                        <div className="information-line target-para info-m">
                                            <p className="information-line-title">{t('from')}:</p>
                                            <p className="information-line-text">{changeDateFormatddmmyyyy(targetData?.fromDate)}</p>
                                            <p className="information-line-title">{t('to')}:</p>
                                            <p className="information-line-text">{changeDateFormatddmmyyyy(targetData?.toDate)}</p>

                                        </div>
                                    </div>
                                    <div className="buttons-area">
                                        {canChangeDetail ?
                                            <Link to="#" onClick={(e) => editTarget(e)} className="button target-btn edit-btn ">
                                                {t('button.edit')}
                                            </Link> :
                                            null}
                                        {canChangeDetail ?
                                            <Link to="#" onClick={(e) => DeleteTargetHandler(e)} className="button target-btn delete-btn">{t('button.delete')}</Link> :
                                            null}

                                        {acceptReject &&
                                            <>
                                                {acceptRejectBtn === 0 ? <>
                                                    <Link to="#" onClick={(e) => AcceptRejectTargetHandler(e, 'accept')} className="button target-btn edit-btn">{t('button.accept')}</Link>
                                                    <Link to="#" onClick={(e) => AcceptRejectTargetHandler(e, 'cancel')} className="button target-btn delete-btn">{t('button.reject')}</Link>
                                                </>
                                                    :
                                                    <>
                                                        {acceptRejectBtn === 1 ?
                                                            <Link to="#" onClick={(e) => e.preventDefault()} className="button target-btn edit-btn">{t('button.accepted')}</Link> :
                                                            <Link to="#" onClick={(e) => e.preventDefault()} className="button target-btn delete-btn">{t('button.rejected')}</Link>
                                                        }
                                                    </>}
                                            </>
                                        }

                                        {/* {canChangeDetail ?
                                            <Link to="#" onClick={(e) => invitePeople()} className="button target-btn edit-btn ">Invite</Link> :
                                            null} */}
                                        {/* <Link to="#" className="button target-btn view-less-btn" onClick={(e) => { e.preventDefault(); setViewMore(false) }}>{t('button.view_less')}</Link> */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="information-line target-para info-m">
                                        {/* <p className="information-line-title">{t('target_id')} :</p> */}
                                        <p className="information-line-text">
                                            {/* {targetData?._id} */}
                                            {/* <button onClick={copyTextChange}><img src="/Assets/copy2.png" />
                                        {
                                            isCopied &&  <div className="click_copy_2"><p className="">{t('copied')}</p></div>
                                        } 
                                        </button> */}
                                        </p>
                                    </div>
                                    <div className="open_target_right_btn_box">
                                        <div className="open_box_btn_area">
                                            {targetData?.events?.length > 0 ?

                                                <Link to="#" className="button target-btn view-btn" onClick={(e) => { e.preventDefault(); setShowAssociateEvents(!showAssociateEvents) }}>{showAssociateEvents ? t('hide_asociated_events') : t('show_asociated_events')}</Link> :
                                                <Link to="#" className="button target-btn view-btn" onClick={(e) => { e.preventDefault(); }}>{t('no_asociated_events')}</Link>
                                            }

                                            {canChangeDetail && (new Date().setHours(0, 0, 0, 0) <= new Date(targetData?.toDate).setHours(0, 0, 0, 0)) && getTargetCompletePercentage() < 100 && checkTargetUnits() ?
                                                <Link onClick={(e) => createNewEventHandler(e)} to="#" className="button create-new-events">{t('create_new_event')}</Link> : null}
                                        </div>
                                        <div className="privateText">
                                            <p>{t(`${targetData?.targetType}`)}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {showAssociateEvents && targetData.events.length > 0 && <div className="associate-events mt-3">
                    <h5>{t('associated_events')}</h5>
                    <div className="close-icon-btn">
                        <button type="button" className="close" aria-label="Close" onClick={(e) => { e.preventDefault(); setShowAssociateEvents(false) }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {targetData.events.map((item, index) => (
                        <div className="row bg-white my-3 align-items-center" key={index} >
                            <div className="col-md-6 col-lg-3">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="associatedEventsImg">
                                        <span className="star positionRelative">
                                            <img src={(item?.photo !== '') ? getImageUrl(item?._id, 'event', item?.photo) : AvatarImg} alt="" className="your_target_avatar" />
                                        </span>
                                        <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ visibility: 'hidden', position: 'absolute' }}>
                                            <defs>
                                                <filter id="roundInvite">
                                                    <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                                    <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                                                </filter>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div className="associatedEventsContent  associatedEventLocation">
                                        <Tooltip title={item?.title} placement="bottom">
                                            <h4>{item?.title}</h4>
                                        </Tooltip>
                                        <div>
                                            <p>{changeDateFormatddmmyyyy(item?.startDate)} {timeFormat(dateTimeEventFormat(item?.startDate, item?.startTime))}
                                                <span>{new Date() <= getDateTime(item) ?
                                                    <img src="/Assets/gray.png" /> :
                                                    (getCurrentDate() > new Date(item?.endDate) || item?.status == "verified") ?
                                                        <img src="/Assets/red.png" /> :
                                                        <img src="/Assets/green.png" />
                                                }
                                                </span> {changeDateFormatddmmyyyy(item?.endDate)}
                                            </p>
                                            {/* <p></p> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="col-sm-6 col-md-3 col-lg-2">
                                <div className="information-line target-para">
                                    {
                                        <p>{item?.description.substring(0, 200) + '..'}</p>
                                    }
                                </div>

                            </div> */}
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="d-flex justify-content-center">
                                            <img src={PinTargetIcon} className="mr-2 ms-lg-3 locationIcon" /><span>{item?.location}</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-2 col-lg-4">
                                <div className='col-12'>
                                    <div className="d-flex justify-content-center">
                                        Target: {item?.unit + " " + item?.unitType}
                                    </div>

                                </div>

                            </div>

                            <div className="col-md-6 col-lg-2 d-flex justify-content-center" >
                                <Link to="#" onClick={(e) => viewEventHandler(e, item?._id)} className="button small primary target-btn view-btn associate_btn">{t('button.view_event')}</Link>
                            </div>
                        </div>
                    ))}
                </div>}
            </>}
        </>
        }

        <Popup heading={targetData?.title} open={open} handleClose={handleClose} modalBodyClasses="targetDescriptionModal" modalContentClasses="TargetDescriptionodalContent" modalHeadingClass={"whoCanCommentHeading"}>
            <div>
                <p>{targetData?.description}</p>
            </div>
        </Popup>
    </>);
}

export default TargetDescriptionBox;