import React from "react";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { getImageUrl } from "../../../utils/helpers";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
const PartnerCms = ({formValues, partnerFiles, setPhoto, removeImage, addInstance, removeInstance}) => {
    const {t} = useTranslation();
    return (<>
        <SectionHeader pretitle="" title={t('Partners')}/>
        <Row>
            <div className="col-1">
                <div className="cms_feature_btn_area mt-2">
                <button className="add-more-btn" onClick={(e)=> addInstance(e, 'partner')}> <AddIcon/></button>
                </div>
            </div>
            <div className="col-11">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <p>Please Note: Try to use logo images which have either no or less whitespace around them for best outcome.</p>
                        </div>
                    </div>
                    <div className="row">
                        {partnerFiles?.length > 0 && partnerFiles?.map((item, index)=>{
                    return (<Col lg={4} md={6} sm={6} key={index} className="partnerCmsBlock my-3">                   
                                <div className="position-relative h-100">
                                    {((item?.partner !=='' && formValues?._id) || item?.selected !==null) && 
                                    <span className="cross_cms_btn" onClick={()=>removeImage('partner', index)}> <CloseIcon/></span>}
                                    <label htmlFor={`upload-photo-partner-${index}`} className="h-100">
                                        <input type="file" id={`upload-photo-partner-${index}`}
                                        style={{display:"none"}}
                                        accept="image/png, image/gif, image/jpeg"
                                            onChange={(event) => {
                                                setPhoto(event.target.files[0], 'partner', index);
                                            }}
                                            />
                                        <div className="content h-100">
                                        <div className="partnerCmsOpt upload-box cms_partner_area h-100">
                                        
                                            {((item?.partner !=='' && formValues?._id) || item?.selected !==null) ?
                                                <img src={item?.selected!==null ?
                                                        URL.createObjectURL(item?.selected) : 
                                                        getImageUrl(formValues?._id, 'cms', item?.partner)} className="user_attach_upload" /> : 
                                                        <span className="up_load_btn">{t('errors.Upload')}</span>}
                                                <p className="upload-box-title">{t('Partners')} {index+1}</p>
                                                <p className="upload-box-text">{t('onboarding.size_minimum')}</p>
                                            </div>
                                            
                                        </div>
                                    </label> 
                                    {
                                        index ? <><div className="closeIconUpload" onClick={(e) => removeInstance(e, 'partner', index)}><DeleteForeverIcon /></div></> : ''
                                    } 
                                
                                </div>
                                
                    </Col>)
                        })}
                    </div>
                </div>
            </div>
            

            
        </Row>
        
    </>)
}
export default PartnerCms;
