import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";

import { TextField, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import RoleBox from "./roleBox";
import ReactPagination from "../commonComponents/reactPagination";
import { getEmployeeListService } from "../services/adminManage.services";
import Loader from "../commonComponents/Loader";
import { AvatarImg } from "../commonComponents/Images";


const limit = 20;
let page = 0;

const ManageRoleList = () => {
  const { t } = useTranslation();
  const [totalPage, setTotalPage] = useState(0);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setLoader] = useState(false);
  let defaultUserImage = AvatarImg;

  useEffect(() => {
    page = 0;
    getData(page);
  }, [searchText])


  const getData = async (page) => {
    try {
      setLoader(true)
      const response = await getEmployeeListService(page, limit, searchText);
      setLoader(false)
      if (response?.data?.data) {
        setData(response.data.data);
        let pageCount = (response?.data?.totalCount) / limit;
        setTotalPage(Math.ceil(pageCount));
      }
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }

  const handlePageClick = (data) => {
    page = data.selected;
    getData(page);
  }


  const renderEmptyMessage = () => {
    return !data?.length && !isLoading ? <h4>{t('orgMsg.NO_MANAGE_ROLE')}</h4> : null
  }

  const renderLoader = () => isLoading ? <Loader /> : null;


  return (
    <div>
      <div className='text-secondary superAdminheading1 mt-3'> {t('superAdmin.manage_org_heading1')} </div>
      <h2 className='mb-4 mt-1'> {t('superAdmin.manage_role_heading2')} </h2>
      <div className="widget-box ">
        <Row>
          <Col md={2}>
            <div className="form-item split">
              <TextField
                type='text'
                label={t('Search_Users')}
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText ? searchText : ''}
                InputProps={{ endAdornment: <Button className="button secondary"><SearchIcon style={{ fill: "#fff" }} /></Button> }} />
            </div>

          </Col>
          <Col md={10} className='text-end'>
            <Button className='btn export-btn'>{t('export_all')}</Button>
          </Col>
        </Row>
      </div>
      {renderEmptyMessage()}
      {renderLoader()}
      <Row>
        <Col md={12}>
          {data.map((item, index) => {
            return (<RoleBox data={item} key={index} />)
          })}
        </Col>
      </Row>
      <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />
      
      
    </div>
  )
}

export default ManageRoleList;