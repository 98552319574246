import './greenCheckbox.style.css';
import { FormControlLabel } from '@mui/material';

const GreenCheckBox = ({label, checked , onChange, ...props}) => {
    return (<>
        <FormControlLabel control={<div className="checkbox-container">
            <input type="checkbox" checked={checked} onChange = {onChange} {...props}/>
            <span className="checkmark"/>
        </div>} label={label} />
    </>)
}

export default GreenCheckBox;