export const validEmail = (email) => {
    return /^(?!\d+@)\w+([-+.']\w+)*@(?!\d+\.)\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email);
}

export const firstLastNameValidate = (name) =>{
    var matches = name.match(/\b[^\d\s]+\b/g);
    return (matches && matches.length >= 2)?true:false; 
}

export const firstLastNameNumberValidate = (name) =>{
    var matches = name.match(/^[a-zA-Z ]+$/);
    return (matches)?true:false; 
}

// export const NumberValidate = (input_str) =>{
//     var number=/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
//     return number.test(input_str); 
// }

export const passwordRegexValidate = (password)=>{
    return /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password);
}

export const usernameValidate = (username) => {
    if(username.length < 5){ return false; }

    const userNameRegex = /^[a-z0-9_.]+$/; 
    return userNameRegex.test(username);
}

export const fromToDateValidator = (from, to) => {
    if(new Date(from).getTime() > new Date(to).getTime()){
        return false;
    }
    return true;
}

export const websiteValidator = (website) => {
    if(website.length === 0){ return true; }
    return /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(website);
}