import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { Country, City } from 'country-state-city';
import { IconInFormImg } from "../../../commonComponents/Images";
import {
    Button,
    Stack,
    TextField,
    Box,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormHelperText,
    Autocomplete
} from '@mui/material';
import { validEmail } from "../../../utils/formatValidator";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import Popup from "../../../commonComponents/popup";
import {
    verifyEmailMobileOtpService,
    checkEmailMobileAvailabilityService
} from "../../../services/onBoarding.services";
import { showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import ClearIcon from '@mui/icons-material/Clear';
import { allowOnlyLetters } from "../../../utils/helpers";
import GreenCheckBox from "../../../commonComponents/greenCheckbox/greenCheckbox";
import { phoneCountryCode } from "../../../config/constants";
const ContactInfo = React.memo(({ error, setError, skipClick, backClick, formValues, setFormValues, saveUserDetails }) => {
    const { t } = useTranslation();
    const [emailError, setEmailError] = useState(false);
    const [open, setOpen] = useState(false);
    const [counter, setCounter] = useState(0);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const [incorrectOTP, setIncorrectOTP] = useState(false);
    const [phonePrefix, setPhonePrefix] = useState("+91");
    const [loading, setLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [cityListAccordingToCountry, setCityAccordingToCountry] = useState({ permanentCity: [], currentCity: [] });

    const handlePhonePrefixChange = (event) => {
        setPhonePrefix(event.target.value);
    };
    const [phonecode, setPhoneCode] = useState('in');
    useEffect(()=>{
        setPhCode();
    },[]);
    useEffect(() => {
        setPhCode();
    }, [formValues?.currentAddress?.country]);
    
    const setPhCode = () =>{
        if(formValues?.currentAddress?.country){
            let phonecode;
            phonecode = phoneCountryCode?.filter((item)=>{
                let country = formValues?.currentAddress?.country?.toLowerCase();
                let countryName = item?.name?.toLocaleLowerCase();
                if(countryName==country){
                    return (item?.code);
                }
            })
            if(phonecode.length !=0){
                setPhoneCode(phonecode[0]?.code?.toLowerCase());
            }
        }
        else{
            setPhoneCode('in');
        }
    }

    // const newCountryList=[];
    // Country.getAllCountries()?.filter((a)=>{
    //     newCountryList.push(a?.name);
    //     return a?.name;
    // })

    const newCountryList = [];
    Country.getAllCountries()?.filter((a) => {
        if (City.getCitiesOfCountry(a?.isoCode)?.length > 0) {
            newCountryList.push(a?.name);
            if (newCountryList?.indexOf(a?.name) === -1) { newCountryList.push(a?.name); }
        }
        return a?.name;
    })

    const nextClick = (e) => {
        e.preventDefault();
        setError(false);

        // if(formValues.mobile !== '' && formValues?.mobile?.length < 10)
        // {
        //     setError(true); 
        //     return;
        // }

        let params = {
            mobile: formValues.mobile,
            // email : formValues.email,
            permanentAddress: formValues.permanentAddress,
            currentAddress: formValues.currentAddress,
        }
        saveUserDetails(params);
    }

    const checkEmailAvailability = () => {
        setEmailError(false);
        if (!validEmail(formValues.email)) {
            setEmailError(true); return;
        }

        try {
            let params = { 'email': formValues.email }
            checkEmailMobileAvailabilityService(params)
                .then((data) => {
                    if (data?.status !== 200) {
                        setEmailError(true); return;
                    }
                    // showToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`))
                    showSingleToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`))
                    setCounter(60);
                    handleOpen();
                })
                .catch((error) => {
                    setEmailError(true);
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const verifyEmail = (e) => {
        e.preventDefault();
        if (formValues.email === '' || !validEmail(formValues.email)) { return; }
        setOtpError(false);
        if (otp === '' || otp.length < 6) {
            setOtpError(true); return;
        }

        try {
            setLoading(true)
            let params = { email: formValues.email, otp: otp }
            verifyEmailMobileOtpService(params)
                .then(async (data) => {
                    if (data.status === 200) {
                        setFormValues({ ...formValues, user_email: formValues.email, isEmailVerify: true })
                        handleClose();
                    } else {
                        setOtpError(true); setIncorrectOTP(true);
                        showToastError(t(`apiMessages.${data.data.data}`))
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    setError(true);
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        catch (error) {
            console.log(error);
            setLoading(false)
        }
        setLoading(false)
    }

    const setCurrentAddressHandler = (e) => {
        if (e.target.checked) {
            setFormValues({
                ...formValues,
                currentAddress: {
                    city: (formValues.permanentAddress?.city) ? formValues.permanentAddress?.city : '',
                    country: (formValues.permanentAddress?.country) ? formValues.permanentAddress?.country : ''
                }
            })
        }
        else {
            setFormValues({
                ...formValues,
                currentAddress: {
                    city: '',
                    country: ''
                }
            })
        }
    }

    useEffect(() => {
        setCityListFunc('none')
        let myInterval = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            } else {
                clearInterval(myInterval)
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    }, [counter]);


    const setCityListFunc = (type, val) => {
        if (type === 'none') {
            let CityCopy = { ...cityListAccordingToCountry };
            if (formValues?.permanentAddress?.country) {
                let code = Country.getAllCountries()?.find(a => a?.name === formValues?.permanentAddress?.country);
                let newCityPermanentList = [];
                City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                    if (newCityPermanentList.indexOf(a?.name) === -1) { newCityPermanentList.push(a?.name); }
                });
                CityCopy['permanentCity'] = newCityPermanentList;
            }
            if (formValues?.currentAddress?.country) {
                let code = Country.getAllCountries()?.find(a => a?.name === formValues?.currentAddress?.country);
                let newCityPermanentList = [];
                City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                    if (newCityPermanentList.indexOf(a?.name) === -1) { newCityPermanentList.push(a?.name); }
                });
                CityCopy['currentCity'] = newCityPermanentList;

            }
            setCityAccordingToCountry(CityCopy)

        }
        if (type === 'permanent') {

            if (val) {
                let code = Country.getAllCountries()?.find(a => a?.name === val);
                let newCityPermanentList = [];
                City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                    if (newCityPermanentList.indexOf(a?.name) === -1) { newCityPermanentList.push(a?.name); }
                });
                setCityAccordingToCountry({ currentCity: cityListAccordingToCountry?.currentCity, permanentCity: newCityPermanentList })
            }

        }
        if (type === 'current') {
            if (val) {
                let code = Country.getAllCountries()?.find(a => a?.name === val);
                let newCityPermanentList = [];
                City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                    if (newCityPermanentList.indexOf(a?.name) === -1) { newCityPermanentList.push(a?.name); }

                });
                setCityAccordingToCountry({ currentCity: newCityPermanentList, permanentCity: cityListAccordingToCountry?.permanentCity })
            }
        }
    }
    const setPhoneNum = (value) => {
        setError(formValues.mobile === '' && formValues.mobile.length < 10 && formValues.mobile.length > 13 ? true : false)
        if (formValues.mobile === '' && formValues.mobile.length < 10 && formValues.mobile.length > 13) {
            return;
        }
        setFormValues({ ...formValues, mobile: value })
        // isValid(validatePhoneNumber(event.target.value))
    }
    return (
        <>
            <div className="heading mb-5">
                <h1>{t('onboarding.fill_your_contact_info')}</h1>
                <h3>{t('onboarding.you_can_update_your_contact_info')}</h3>
            </div>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className="userdetail-form"
            >
                <Row>
                    <Col md={8} className="contact-info-box number_bottom_icon col-xs-6">

                        <FormControl fullWidth variant="outlined">
                            {/* <InputLabel htmlFor="outlined-adornment-password">{t('form.label_your_mobile')}</InputLabel>
                            <OutlinedInput
                                value={formValues.mobile}
                                onChange={(e) =>
                                    e.target.value.replace(/[^0-9]/g, '').length <= 10 ?
                                        setFormValues({ ...formValues, mobile: e.target.value.replace(/[^0-9]/g, '') }) : ''}
                                startAdornment={<FormControl><Select
                                    className='adornmentSelect'
                                    value={phonePrefix}
                                    onChange={handlePhonePrefixChange}
                                >
                                    <MenuItem value="+91">+91</MenuItem>
                                    <MenuItem value="+92">+92</MenuItem>
                                    <MenuItem value="+93">+93</MenuItem>
                                </Select></FormControl>}

                            // label={t('form.label_your_mobile')}
                            /> */}
                            {/* endAdornment={<Button className="verify_btn" onClick={handleOpen}>{t('verify_now')}</Button>} */}
                            <PhoneInput
                                countryCodeEditable={false}
                                label={t('Mobile')}
                                id="mobile"
                                className="userMobileInput"
                                name="mobile"
                                placeholder={t('form.label_your_mobile')}
                                native={true}
                                value={formValues.mobile}
                                country={phonecode}
                                variant="outlined"
                                onChange={(newValue) => {
                                    setPhoneNum(newValue)
                                }}
                            />
                        </FormControl>
                    </Col>

                    {
                        error && formValues?.mobile !== '' && formValues?.mobile?.length < 10 &&
                        <Col md={4}>
                            <div className="error phoneError">
                                <ClearIcon /> {t('errors.enter_correct_phone')}
                            </div>
                        </Col>
                    }



                    <Col md={8} className="contact-info-box contactInfoEmailField">

                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">{t('form.label_email')}</InputLabel>
                            <OutlinedInput
                                error={(formValues.email === '') ? true : (!validEmail(formValues.email)) ? true : false}
                                label={t('form.label_email')} icon={IconInFormImg}
                                value={formValues.email}
                                onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                endAdornment={((formValues.email.toLowerCase() === formValues.user_email.toLowerCase() && formValues.isEmailVerify)) ?
                                    <Button className="verify_btn verified_btn" >{t('verified')}</Button> :
                                    ((validEmail(formValues.email))) ?
                                        <Button className="verify_btn" onClick={checkEmailAvailability}>{t('verify_now')}</Button> : ''}
                            />
                            <FormHelperText>{(formValues.email === '') ? t('errors.email_required') : (!validEmail(formValues.email)) ? t('errors.email_format') : ''}</FormHelperText>
                        </FormControl>
                    </Col>

                    {emailError &&
                        <Col md={4}>
                            <div className="error">
                                <ClearIcon /> {t('errors.Email_is_already_taken')}
                            </div>
                        </Col>
                    }

                    <Col md={12}> <h2 className="formInsideHeading"> {t('permanent_address')} </h2></Col>

                    <Col md={6} className="contact-info-select-tag countryField">

                        <FormControl fullWidth>

                            <Autocomplete
                                disablePortal
                                value={formValues.permanentAddress?.country || ''}
                                options={newCountryList}
                                getOptionLabel={(option) => option}
                                onChange={(e, val) => {
                                    setFormValues({
                                        ...formValues,
                                        permanentAddress: {
                                            country: (val !== null) ? allowOnlyLetters(val) : null,
                                            city: ''
                                        }
                                    }); setCityListFunc('permanent', val);
                                }}
                                renderInput={(params) => <TextField {...params} label={t('country')} />}
                            />
                        </FormControl>
                    </Col>

                    <Col md={6} className="contact-info-box cityField">
                        {/* <TextField
                            value={formValues.permanentAddress?.city}
                            onChange={(e) => {setFormValues({
                                ...formValues,
                                permanentAddress: {
                                    city: allowOnlyLetters(e.target.value),
                                    country: (formValues.permanentAddress?.country) ? formValues.permanentAddress?.country : ''
                                }
                            })}}
                            label={t('city')} /> */}
                        <FormControl fullWidth>

                            <Autocomplete
                                disablePortal
                                value={formValues.permanentAddress?.city || ''}
                                options={cityListAccordingToCountry?.permanentCity}
                                getOptionLabel={(option) => option}
                                onChange={(e, val) => {
                                    setFormValues({
                                        ...formValues,
                                        permanentAddress: {
                                            city: (val !== null) ? allowOnlyLetters(val) : null,
                                            country: (formValues.permanentAddress?.country) ? formValues.permanentAddress?.country : ''
                                        }
                                    });
                                }}
                                renderInput={(params) => <TextField {...params} label={t('city')} />}
                            />
                        </FormControl>
                    </Col>

                    <Col md={12} className="contact-current-address">
                        <div>
                            <span className="formInsideHeading"> {t('current_address')} </span>
                        </div>
                        <div>
                            <GreenCheckBox checked={(formValues.permanentAddress?.city !== '' && formValues.permanentAddress?.country !== '')
                                && (formValues.permanentAddress?.city === formValues.currentAddress?.city &&
                                    formValues.permanentAddress?.country === formValues.currentAddress?.country
                                ) ? true : false}
                                onChange={(e) => { setCurrentAddressHandler(e) }}
                                label={t('same_as_permanent_address')} />
                        </div>

                    </Col>

                    <Col md={6} className="contact-info-box countryField">
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                value={formValues.currentAddress?.country || ''}
                                options={newCountryList}
                                getOptionLabel={(option) => option}
                                onChange={(e, val) => {
                                    setFormValues({
                                        ...formValues,
                                        currentAddress: {
                                            country: (val !== null) ? allowOnlyLetters(val) : null,
                                            city: ''
                                        }
                                    }); setCityListFunc('current', val)
                                }}
                                renderInput={(params) => <TextField {...params} label={t('country')} />}
                            />
                        </FormControl>
                    </Col>

                    <Col md={6} className="contact-info-box cityField">
                        {/* <TextField
                            value={formValues.currentAddress?.city}
                            onChange={(e) => setFormValues({
                                ...formValues,
                                currentAddress: {
                                    city: allowOnlyLetters(e.target.value),
                                    country: (formValues.currentAddress?.country) ? formValues.currentAddress?.country : ''
                                }
                            })}
                            label={t('city')} /> */}
                        <FormControl fullWidth>

                            <Autocomplete
                                disablePortal
                                value={formValues.currentAddress?.city || ''}
                                options={cityListAccordingToCountry?.currentCity}
                                getOptionLabel={(option) => option}
                                onChange={(e, val) => {
                                    setFormValues({
                                        ...formValues,
                                        currentAddress: {
                                            city: (val !== null) ? allowOnlyLetters(val) : null,
                                            country: (formValues.currentAddress?.country) ? formValues.currentAddress?.country : ''
                                        }
                                    })
                                }}
                                renderInput={(params) => <TextField {...params} label={t('city')} />}
                            />
                        </FormControl>
                    </Col>
                </Row>
            </Box>


            <Row>
                <div className="back-next-btn-area contact-back-next-btn-area">
                    <Stack spacing={2} direction="row">
                        <button className="button green back-btn-new" onClick={backClick}> {t('back')} </button>
                        <button className="button secondary next-btn-new" onClick={nextClick}> {t('next')} </button>
                        <button className="button back-btn-new skip-color" onClick={skipClick}> {t('skip')} </button>
                    </Stack>
                </div>
            </Row>


            <Popup heading={t('form.submit_otp')} handleClose={handleClose} modalBodyClasses="otpPopup" modalContentClasses="changePasswordPopupContent" open={open}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '98%' },
                    }}
                    noValidate
                    autoComplete="off"
                    className="userdetail-form"
                    onSubmit={(e) => verifyEmail(e)}
                >
                    <p>{t('errors.Otp_sent_on_your_email')}</p>
                    <div className="otp_counter">00:{(counter < 10) ? `0${counter}` : counter}</div>
                    <TextField
                        value={otp}
                        className="userDetailsOtpInput"
                        onChange={(e) => setOtp(e.target.value)}
                        error={(otpError && otp === '') ? true : (otpError && otp.length < 6) ? true : (otpError && incorrectOTP) ? true : false}
                        helperText={(otpError && otp === '') ? t('errors.Enter_OTP') : (otpError && otp.length < 6) ? t('errors.OTP_is_of_6digits') : (otpError && incorrectOTP) ? t('errors.otp_wrong') : ""}
                        inputProps={{ maxLength: 6 }}
                        label={t('errors.otp')} />

                    <div className="d-flex justify-content-left">
                        {counter === 0 &&
                            <Link className="ps-2" to="#"
                                onClick={(e) => { e.preventDefault(); checkEmailAvailability() }}>
                                {t('errors.Resend_Otp')}
                            </Link>
                        }
                    </div>

                    <button disabled={loading} className="spredeSubmitBtn mt-5">
                        {loading ? t('errors.Please_Wait') : t('form.submit')}
                    </button>
                </Box>
            </Popup>

        </>
    )
})

export default ContactInfo;