import { t } from 'i18next'
import React from 'react'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AvatarImgBox from '../../../commonComponents/AvatarImgBox/avatarImgBox'
import { AvatarImg } from '../../../commonComponents/Images'
import Loader from '../../../commonComponents/Loader'
import ReactPagination from '../../../commonComponents/reactPagination'
import { getImageUrl } from '../../../utils/helpers'

function AllOrgSearchList({ organizationList, isLoading, totalPageForOrg, handlePageClick }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const showOrg = (org) => {
        let pathName = org?.name.replaceAll(' ', '_');
        navigate(`/organization/${window.btoa(org?._id)}`, { state: { orgId: org?._id } })
    }
    return (
        <div className="widget-box">
            <>
                {
                    isLoading ? <Loader /> : organizationList.length > 0 ?
                        <>
                            {
                                organizationList.length > 0 &&
                                <>
                                    {
                                        organizationList.map((item, i) => (
                                            <div className="widget-box blocked_user_area" style={{ marginTop: 10,marginBottom:10 ,cursor:'pointer'}} key={i} onClick={() => showOrg(item)}>
                                                <div className="">
                                                    <Row className="align-items-center" style={{ justifyContent: 'space-between' }}>
                                                        <div className="col-lg-7 col-md-6 d-flex align-items-center gray_plate">
                                                            <div className="hexagon-image-30-32" onClick={() => showOrg(item)}>
                                                                <AvatarImgBox
                                                                    img={(item?.profilePicture !== '') ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : AvatarImg}
                                                                />
                                                            </div>
                                                            <div style={{ marginLeft: '12px' }} className="">
                                                                <h5 className="bold">{`${item?.name}`}</h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-lg-5 col-md-6">
                                                            <div className="globalSearchViewBtn globalSearchList justify-content-end">
                                                                <button
                                                                    onClick={() => showOrg(item)}

                                                                    className="button primary view-event-btn">{t('button.view')}</button>
                                                            </div>
                                                        </div> */}
                                                    </Row>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                            }
                        </> :
                        <>
                            <>
                                {organizationList.length === 0 &&
                                    <>
                                        <div style={{ display: 'flex', marginTop: 10 }}>
                                        {t('form.There_is_no_Organization')}
                                        </div>
                                    </>
                                }
                            </>
                        </>
                }
            </>
            <ReactPagination pageCount={totalPageForOrg} onPageChange={handlePageClick} />
        </div>
    )
}

export default AllOrgSearchList