import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { TextField } from "@mui/material";
import { allowOnlyLettersNumbers } from "../../utils/helpers";
import FormTextArea from "../../commonComponents/formTextArea";
import { FormControl, Autocomplete, InputLabel, Select, MenuItem } from "@mui/material";
import { targetPrivateTypeList, targetTypeList, eventChargingTypeList } from "../../config/constants";
import { getImageUrl } from "../../utils/helpers";

const EventCreationStep = ({
    //formValues,
    values,
    setFormValues,
    eventData,
    activeTarget,
    error,
    validatorError,
    targetVal,
    targets,
    setTargetVal,
    categoryVal,
    maxUnits,
    unitsMeasure,
    showSelected,
    setPhoto,
    removeImage,
    cityList,
    role,
    handleCountryChange,
    handlePopupInvitedOpen,
    handlePopupOpen,
    selectedImage,
    errorCover,
    newCountryList,
    validatorRequired,
    wordLimit,
    setWordLimit,
}) => {
    const { t } = useTranslation();
    const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };
    
    const getWordCount = (t) => {
        let text = t.split(" ");
        return text.length
    }
    const [initialFormVAlues, setInitialFormValues] = useState({});
    useEffect(function(){
        setInitialFormValues({...values});
    },[values]);
    const onChangeHandler = (value, field)=>{
        switch(field){
            case 'eventTitle':
                setInitialFormValues(function(prevState){
                    return({...prevState, title:value})
                });
                break;
            case 'eventUnits':
                setInitialFormValues(function(prevState){
                    return({...prevState, unit:value})
                });
                break;
            case 'description':
                setInitialFormValues(function(prevState){
                    return({...prevState, description:value})
                });
                break;
            case 'location':
                setInitialFormValues(function(prevState){
                    return({...prevState, location:value})
                });
                break;
        }
        
    }
    return (<Row className="mb-0">
        <Col lg={4} className="newEvents col-md-6 target_title88 event_fix_height">
            <TextField 
                value={(initialFormVAlues.title)?initialFormVAlues.title:''}
                inputProps={{
                    autocomplete: 'new-password',
                    form: {
                        autocomplete: 'off',
                    },
                }}
                onChange={(e)=>{onChangeHandler(e.target.value, 'eventTitle')}}
                onBlur={e => setFormValues(prev => ({ ...prev, title: allowOnlyLettersNumbers(e.target.value) }))}
                // onChange={e => setFormValues(prev => ({ ...prev, title: allowOnlyLettersNumbers(e.target.value) }))}
                //    onChange={e => (e.target.value.length <= 30)?setFormValues(prev => ({...prev, title:allowOnlyLettersNumbers(e.target.value)})):""} 
                label={t('superAdmin.Event_Name')} />
                {error && values.title === '' &&
                    <div className="error-text">{t('superAdmin.Please_enter_event_title')}  </div>
                }
            {values.title.length >= 30 ?
                <div className="error-text">
                    {t('errors.you_can_not_enter_more_than_30_characters')}
                </div>
                : ""
            }
        </Col>


        <Col lg={4} className="newEvents col-md-6 target_select89">
            <FormControl fullWidth>

                <Autocomplete
                    MenuProps={{ disableScrollLock: false }}
                    disablePortal
                    value={targetVal}
                    options={targets}
                    getOptionLabel={(option) => option?.title || ""}
                    disabled={(eventData === undefined) ? false : true}
                    onChange={(e, val) => { setTargetVal(val); }}
                    renderInput={(params) => <TextField {...params} label={t('form.select_target')} />}
                />
                {((error || validatorError) && values.targetId === '') ?
                    <div className="error-text">{t('errors.target_required')} </div> : ''}

            </FormControl>

        </Col>

        <Col lg={4} className="newEvents col-md-6 target_title88">
            <TextField
                label={t('superAdmin.Event_Category')}
                value={(t(`${categoryVal.replace(/\s+/g, '_').toLowerCase()}`) !== `${categoryVal.replace(/\s+/g, '_').toLowerCase()}`) ?
                    t(`${categoryVal.replace(/\s+/g, '_').toLowerCase()}`) :
                    t(`${categoryVal.replace(/\s+/g, '_').toLowerCase()}`)}
                disabled={true}
                onChange={e => setFormValues(prev => ({ ...prev, category: allowOnlyLettersNumbers(e.target.value) }))} />
        </Col>

        <Col lg={4} className="newEvents col-md-6 eventTypeField target_title88">
            {activeTarget?.targetType ?
                (activeTarget?.targetType !== 'private') ?
                    <FormControl fullWidth>
                        <InputLabel id="target-type">{t('superAdmin.Event_Type')}</InputLabel>
                        <Select
                            // MenuProps={{ disableScrollLock: true }}
                            labelId="target-type"
                            value={values.type}
                            onChange={e => setFormValues(prev => ({ ...prev, type: e.target.value }))}
                            label={t('superAdmin.Target_Type')}
                        >
                            {targetTypeList.map((item, idx) => {
                                return (
                                    <MenuItem key={`targetTypeList` + idx} value={item.value}>{item.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    :
                    <FormControl fullWidth>
                        <InputLabel id="target-type">{t('superAdmin.Event_Type')}</InputLabel>
                        <Select
                            // MenuProps={{ disableScrollLock: true }}
                            labelId="target-type"
                            value={values.type}
                            onChange={e => setFormValues(prev => ({ ...prev, type: e.target.value }))}
                            label={t('superAdmin.Target_Type')}
                        >
                            {targetPrivateTypeList.map((item, idx) => {
                                return (
                                    <MenuItem key={`targetPrivateTypeList` + idx} value={item.value}>{item.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                :
                <FormControl fullWidth>
                    <InputLabel id="target-type">{t('superAdmin.Event_Type')}</InputLabel>
                    <Select
                        // MenuProps={{ disableScrollLock: true }}
                        labelId="target-type"
                        value={values.type}
                        onChange={e => setFormValues(prev => ({ ...prev, type: e.target.value }))}
                        label={t('superAdmin.Target_Type')}
                    >
                        {targetTypeList.map((item, idx) => {
                            return (
                                <MenuItem key={`targetTypeList` + idx} value={item.value}>{item.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            }
            {error && values.type === '' &&
                <div className="error-text" >{t('superAdmin.Please_enter_event_type')}  </div>
            }

        </Col>

        <Col lg={4} className="eventUnits col-md-6 target_title88 event_fix_height">
            <TextField
                //InputProps={{endAdornment: <button className="verify_btn verified_btn">{getMeasuringUnitsValue(values.spredeInterestSubCategory)}</button>}}
                className="mx-0" label={t('form.EventUnits')} placeholder={t('form.Enter_your_target_units')}
                value={(initialFormVAlues.unit)?initialFormVAlues.unit:''}
                // onChange={e => (e.target.value.length < 10) ? setFormValues(prev => ({ ...prev, unit: (e.target.value === '') ? '' : +e.target.value.replace(/[^0-9]/g, '') })) : ''}
                onChange={(e)=>{onChangeHandler(e.target.value, 'eventUnits')}}
                onBlur={e => (e.target.value.length < 10) ? setFormValues(prev => ({ ...prev, unit: (e.target.value === '') ? '' : +e.target.value.replace(/[^0-9]/g, '') })) : ''}
            />
            {error && values.unit === '' ?
                <div className="error-text" >
                    {t('errors.target_units_required')}
                </div>
                : error && parseInt(values.unit) === 0
                    ?
                    <div className="error-text" >
                        {t('errors.event_units_not_zero')}
                    </div>
                    : ''
            }
            {
                (values.unit !== '' && parseInt(values.unit) > maxUnits) ?
                    <div className="error-text" >
                        {t('Units_should_be_less_than')} {maxUnits}
                    </div> :
                    (maxUnits === 0 && values?.targetId !== '') ?
                        <div className="grey-text">
                            {t('target_units_already_distributed')}
                        </div>
                        :
                        <div className="grey-text">
                            {t('Max_Units')} : {maxUnits} {unitsMeasure}
                        </div>
            }
        </Col>
        <Col lg={4} className="eventUnits col-md-6 target_title88 event_fix_height">
            <TextField
                className="mx-0" label={t('form.Event_units_measure')}
                value={unitsMeasure}
                disabled={true}
            />
        </Col>

        <Col md={8} className="target_textarea90 event_description97">
            <FormTextArea
                value={(initialFormVAlues.description)?initialFormVAlues.description:''}
                // onChange={e => (e.target.value.length <= 1500) ? setFormValues(prev => ({ ...prev, description: e.target.value })) : ''}
                onChange={e => (e.target.value.length <= 1500) ? onChangeHandler(e.target.value, 'description'):''}
                onBlur={e => (e.target.value.length <= 1500) ? setFormValues(prev => ({ ...prev, description: e.target.value })) : ''}
                label={t('superAdmin.Event_Description')}
                className={"mb-0"}
            />
            {error && values.description === '' && <div className="error-text" >{t('errors.event_description_required')} </div>}
            {(values?.description.length >= 1500) ?
                <div className="error-text">
                    {t('errors.you_can_not_enter_more_than_1500_characters')}
                </div>
                : ""
            }
        </Col>

        <Col md={4} className="event_description97">
            {((values?.photo && values?._id) || showSelected) ? <>
                <div className="position-relative">
                    <label className="user_img_upload uploadFile" htmlFor="upload-photo">
                        <input type="file" id="upload-photo"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(event) => {
                                setPhoto(event.target.files[0]);
                            }} />
                        <div className="content e_cov_img event_photo95">
                            <img src={showSelected ? URL.createObjectURL(selectedImage) : getImageUrl(values?._id, 'event', values?.photo)} className="user_attach_upload" />
                        </div>
                    </label>
                    <img src='/Assets/Close.png' className="closeIconUpload" onClick={() => removeImage()} />
                </div>
                {errorCover && <p style={{ color: "red" }} className="error-message">{t('Choose_correct_file_according_to_mentioned_size')}</p>}
            </>
                : <><label className="user_img_upload uploadFile" htmlFor="upload-photo">
                    <input type="file" id="upload-photo"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(event) => {
                            setPhoto(event.target.files[0])
                        }} />
                    <div className="content addEvent_cover event_photo95 text-center">
                        <p>{t('superAdmin.event_Cover_Photo')}</p>
                        <p>{t('Size')} : 5KB - 5MB <br />
                            {t('Best_Resolution')} : 800X300 Or 3000X2500
                        </p>
                        <img src="/Assets/Photos Icon-green@2x.png" />
                    </div>
                </label>
                    {errorCover && <p style={{ color: "red" }} className="error-message">{t('Choose_correct_file_according_to_mentioned_size')}</p>}
                </>
            }
            {error && values.photo === '' && selectedImage === null &&
                <div className="error-text" >
                    {t('errors.event_image_required')}
                </div>
            }
        </Col>

        <Col md={4} className="newEvents target_title88 event_fix_height">
            <TextField
                label={t('superAdmin.Event_Address')}
                value={(initialFormVAlues.location)?initialFormVAlues.location:''}
                onChange={(e)=>{onChangeHandler(e.target.value, 'location')}}
                onBlur={e => setFormValues(prev => ({ ...prev, location: allowOnlyLettersNumbers(e.target.value) }))}
            />
            {(error || validatorError) && values.location === '' &&
                <div className="error-text" >{t('errors.event_location_required')} </div>
            }
        </Col>

        <Col md={4} className="newEvents target_select89">
            <FormControl fullWidth>
                <Autocomplete
                    disablePortal
                    value={values?.country}
                    options={newCountryList}
                    getOptionLabel={(option) => { return option }}
                    onChange={(e, val) => {
                        handleCountryChange(e, val)
                    }}

                    renderInput={(params) => <TextField {...params} label={t('country')} />}
                />
            </FormControl>
            {(error || validatorError) && values.country === '' &&
                <div className="error-text" >{t('errors.event_country_required')} </div>
            }
        </Col>

        <Col md={4} className="newEvents target_select89">
            <FormControl fullWidth>
                <Autocomplete
                    disablePortal
                    value={values.city || ''}
                    options={cityList}
                    onChange={(e, val) => { setFormValues(prev => ({ ...prev, city: val })); }}
                    renderInput={(params) => <TextField {...params} label={t('city')} />}
                />
            </FormControl>
            {(error || validatorError) && values.city === '' &&
                <div className="error-text">{t('errors.event_city_required')} </div>
            }
        </Col>


        <Col md={4} className="positionRelative mt-0 inviteFriendBox">
            <div className="inviteFriendsHead eventinviteFriends">
                {role === 'organization' ? t('invite') : t('invite_friends')}
            </div>
            <div className="inviteBlock addEve_invite_box inviteBoxContainer">
                <div className="pulsIcon eventPlusIcon">
                    <button className="button primary" type="button" onClick={(e) => { e.preventDefault(); handlePopupOpen() }}>+</button>
                </div>
                <div className="inviteUserBlk">
                    <div className="inviteUser" onClick={() => { handlePopupInvitedOpen() }} style={{ cursor: 'pointer' }}>
                        <span>
                            <span className="star"><span className="targetNumber">+ {values?.invitesNew?.length + values?.orgInvites?.length}</span></span>
                            <span className="star1"></span>
                        </span>
                    </div>

                    <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ visibility: 'hidden', position: 'absolute' }}>
                        <defs>
                            <filter id="roundInvite">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                            </filter>
                        </defs>
                    </svg>
                </div>
            </div>
        </Col>
    </Row>)
}

export default EventCreationStep;