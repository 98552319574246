import React, { useEffect, useState } from 'react';
import { LanguageList } from '../../config/constants';
import HomeBanner from './banner';
import i18next from 'i18next';
import {getFaqService } from '../../services/cms.services';
import {Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BarsLoader from '../../commonComponents/barsLoader';
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from 'draft-js';
import DOMPurify from 'dompurify';
import { Accordion } from 'react-bootstrap';
import { getCmsServiceList } from '../../services/cms.services';
const Faq = () => {
  const { t } = useTranslation();
  const defaultLanguage = i18next?.language?.split('-')[0];
  const find = LanguageList?.find((a) => a.value === defaultLanguage);
  const [cmsData, setcmsData] = useState([]);
  const [language, setLanguage] = useState(find?.name ? find : LanguageList[0]);
  const [isLoading, setLoading] = useState(true);
  const [emptyData, setEmptyData] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  useEffect(() => {
    let filter = LanguageList?.find((a) => a.value === defaultLanguage);

    setLanguage(filter?.name ? filter : LanguageList[0]);
  }, [defaultLanguage]);
  React.useEffect(() => {
    const handleLanguageChange = () => {
      let defaultLanguage1 = i18next?.language?.split('-')[0];

      let filter = LanguageList?.find((a) => a.value === defaultLanguage1);

      setLanguage(filter?.name ? filter : LanguageList[0]);
    };

    i18next.on('languageChanged', handleLanguageChange);

    return () => {
      i18next.off('languageChanged', handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    if (language !== null) {
      getFaqData();
      getCmsData();
      //   getBlogs();
    }
  }, [language]);
  useEffect(() => {
    extractCategories(cmsData);
  }, [cmsData]);

  //To sanitize the html code
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }
  //Function to convert data to plain html content.
  const convertData = (receivedData) =>{
    let htmlData = convertToHTML(convertFromRaw(receivedData));
    let convertedHtml = createMarkup(htmlData);
    return convertedHtml;
  }
  //Function to fetch all categories
  function extractCategories(data) {
    let categories;
    if (data != undefined && data?.length != 0) {
      categories = data.map((item, index) => {
        return item.categoryId.name;
      });
      let categorySet = new Set(categories);
      let categoryList = [...categorySet];
      createData(categoryList);
    }
    
  }

  const [faqData, setFaqData] = useState([]);
  //To create and load accordion data.
  function createData(categoryList) {
    let accordionData = categoryList.map((category, categoryIndex) => {
      return (
        <Accordion.Item eventKey={categoryIndex} className='faqAccordionItem' key={'accordionItem' + categoryIndex}>
          <Accordion.Header>{category}</Accordion.Header>
          <Accordion.Body>
            {cmsData.map((item, itemIndex) => {
              if (item.categoryId.name === category) {
                return (
                  <div key={'accordionBody' + itemIndex}>
                    <p className='question'><span>Question:</span> {item.question}</p>
                    <p className='answer'><span>Ans.:</span><span dangerouslySetInnerHTML={convertData(item.answer)}></span></p>
                  </div>
                );
              }
            })}
          </Accordion.Body>
        </Accordion.Item>
      );
    });
    setFaqData(accordionData);
  }

    function createMarkup(html) {
      return {
        __html: DOMPurify.sanitize(html)
      }
    }
  //For Draft JS

  const getFaqData = async () => {
    getFaqService(language?.value)
      .then((data) => {
        if (data?.data?.status === 200) {
          if(data.data.data != undefined && data.data.data.length !=0){
            const _data = data.data.data;
            setcmsData(_data);
            setLoading(false);
          }
          else{
            setEmptyData(true);
            setLoading(false);
          }
        }
        else{
          setLoading(false);
          setNetworkError(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setNetworkError(true);
        console.log(error);
      });
  };

  const [cmsData2, setcmsData2] = useState();
  const getCmsData = async () => {
    getCmsServiceList(language?.value)
      .then((data) => {
        if (data?.data?.status === 200) {
          const _data = data.data.data;
          setcmsData2(_data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <HomeBanner onlyMenu={true} cmsData={cmsData2} />
      <Container fluid className="blogs-div">
        <div className="blog-container">
          <Row className="panchtatva-row1 mb-4">
            <h1 className="heading"> {t('faq')} </h1>
          </Row>
          <Row>
            {isLoading ? (
              <BarsLoader />
            ) :(emptyData == true)?<p className='text-center fs-2'>{t('errors.no_faq_added')}</p>
            :(networkError == true)?<p className='text-center text-danger fs-2'>{t('errors.networkError')}</p> :(
              <div className='faqSection'>
                <Accordion defaultActiveKey={0}>
                  {faqData}
                </Accordion>
              </div>
            )}
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Faq;
