import React, {useState, useEffect} from "react";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import FormTextArea from "../../../commonComponents/formTextArea";
import { TextField, Box } from "@mui/material";
import { getImageUrl } from "../../../utils/helpers";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw } from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertToRaw } from "draft-js";
const AboutCms = ({formValues, setFormValues, selectedFiles, setPhoto, removeImage}) => {
    const [editorState, setEditorState] = useState();
    const saveEditorContent =() =>{
        const content = editorState.getCurrentContent();
        setFormValues({
            ...formValues,
            aboutUsPage: convertToRaw(content),
        })
    }
    const [ediorData, setEditorData] = useState(EditorState.createEmpty());
    const [dataFetchCount, setDataFetchCount] = useState(0);
    useEffect(()=>{
        if(formValues?.aboutUsPage){
            setEditorData(formValues?.aboutUsPage);
            setDataFetchCount((prevState)=>{
                return prevState+1;
            });
        }
    },[formValues?.aboutUsPage]);

    useEffect(()=>{
        if(formValues?.aboutUsPage && Object.keys(formValues?.aboutUsPage).length !=0 && dataFetchCount == 1){
            setEditorState(EditorState.createWithContent(convertFromRaw(formValues?.aboutUsPage)));    
        }
    },[dataFetchCount])

    const {t} = useTranslation();
    return (<>
        <SectionHeader pretitle="" title={t('aboutHome')}/>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '98%' },
            }}
            noValidate
            autoComplete="off"
            className="userdetail-form mobile_profile_margin p-0 aboutCmsComp"
            >
                <Row>
                    <Col lg={4} md={6}>
                        <Row>
                        
                            <div className="position-relative">
                                {
                                    ((formValues?.aboutUsVideo?.video && formValues?._id) || selectedFiles?.aboutUsVideo!==null) &&
                                    <span className="cross_cms_btn" onClick={()=>removeImage('aboutUsVideo')} ><CloseIcon/></span>
                                }
                                <label htmlFor="upload-video-about">
                                    <input type="file" id="upload-video-about"
                                    style={{display:"none"}}
                                    accept="video/*,video/mp4,video/x-m4v,video/x-matroska,.mkv"
                                        onChange={(event) => {
                                            setPhoto(event.target.files[0], 'aboutUsVideo');
                                        }}
                                        />
                                    <div className="content">
                                        <div className="upload-box cms_about_area">
                                        

                                        {((formValues?.aboutUsVideo?.video && formValues?._id) || selectedFiles?.aboutUsVideo!==null) ?
                                        <video src={selectedFiles?.aboutUsVideo!==null ? URL.createObjectURL(selectedFiles?.aboutUsVideo) : getImageUrl(formValues?._id, 'cms', formValues?.aboutUsVideo?.video)} className="user_attach_upload" /> :
                                        <span className="up_load_btn">{t('errors.Upload')}</span> }
                                            <p className="upload-box-title">{t('uploaded_a_Video')}</p>
                                            <p className="upload-box-text">{t('onboarding.size_minimum')}</p>
                                        </div>
                                        
                                    </div>
                                </label>  
                              
                            </div>
                            
                        </Row>
                        <Row>
                        <div className="position-relative">
                            {
                                ((formValues?.aboutUs?.aboutUsImage && formValues?._id) || selectedFiles?.aboutUs!==null) &&
                                <span className="cross_cms_btn" onClick={()=>removeImage('aboutUs')} ><CloseIcon/></span>
                            }
                            <label htmlFor="upload-photo-about">
                                    <input type="file" id="upload-photo-about"
                                    style={{display:"none"}}
                                    accept="image/png, image/gif, image/jpeg"
                                        onChange={(event) => {
                                            setPhoto(event.target.files[0], 'aboutUs');
                                        }}
                                        />
                                    <div className="content">
                                        <div className="upload-box cms_about_area">
                                        

                                        {((formValues?.aboutUs?.aboutUsImage && formValues?._id) || selectedFiles?.aboutUs!==null) ?
                                        <img src={selectedFiles?.aboutUs!==null ? URL.createObjectURL(selectedFiles?.aboutUs) : getImageUrl(formValues?._id, 'cms', formValues?.aboutUs?.aboutUsImage)} className="user_attach_upload" /> :
                                        <span className="up_load_btn">{t('errors.Upload')}</span> }
                                            <p className="upload-box-title">{t('Image')}</p>
                                            <p className="upload-box-text">{t('onboarding.size_minimum')}</p>
                                        </div>
                                        
                                    </div>
                                </label>  
                        </div>    
                        </Row>

                        <Row className="grid">
                            <div className="grid-column myProfileSettings">
                                <div className="widget-box area-widget">
                                    <div className="widget-box-content">
                                        <Col md={12}>
                                            <TextField label={t('superAdmin.Title')}
                                            value={formValues?.aboutUs?.aboutUsTitle}
                                            onChange={(e)=>setFormValues({...formValues, aboutUs : {
                                                ...formValues?.aboutUs,
                                                aboutUsTitle : e.target.value
                                            }})}
                                            />
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </Row>              
                    </Col>
                    <Col lg={8} md={12}>
                        <Row className="grid">
                            <div className="grid-column myProfileSettings">
                                <div className="widget-box area-widget">
                                    <div className="widget-box-content" >
                                        <Col md={12} className="cms_about_textarea">
                                            <FormTextArea label={t('Text')}
                                                value={formValues?.aboutUs?.aboutUsText}
                                                onChange={(e)=>( e.target.value.length < 1000)?setFormValues({...formValues, aboutUs : {
                                                    ...formValues?.aboutUs,
                                                    aboutUsText : e.target.value
                                                }}) : ''}
                                            />
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </Row>   
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <SectionHeader pretitle="" title={t('aboutUs')}/>
                        <div className="grid-column myProfileSettings">
                            <div className="widget-box area-widget">
                                <div className="widget-box-content">
                                    <Col md={12} className="cmsEditorContainer cms_about_textarea">
                                        <Editor
                                            onChange={saveEditorContent}
                                            editorState={editorState}
                                            onEditorStateChange={setEditorState}
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                        />
                                    </Col>
                                </div>
                            </div>
                        </div>                        
                    </Col>
                </Row>
        </Box>
    </>)
}
export default AboutCms;
