import React, { useEffect, useState } from "react";
import { AvatarImg } from "../commonComponents/Images";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAllFriendsAddedList,postDeleteOrCancelRequest } from "../services/friends.service";
import { getImageUrl, showSingleToastSuccess, showToastError, showToastSuccess } from "../utils/helpers";
import AvatarImgBox from "../commonComponents/AvatarImgBox/avatarImgBox";
const FewFriendsList = ({ heading }) => {
  const { t } = useTranslation();
  const n = 4;
  const [listOfFriends, setListOfFriends] = useState([]);
  const [totalFriends, setTotalFriends] = useState(0);
  const navigate = useNavigate();
  let defaultUserImage = '/Assets/Img.png';
  const limit = 4;
  useEffect(() => {
    getAllfriendsList(0);
  }, [])
  const getAllfriendsList = async (currentPage) => {
    const response = await getAllFriendsAddedList(`&page=${currentPage}&limit=${limit}`);

    if (response?.data?.data) {
      const user = response?.data?.data?.data;
      setListOfFriends(user);
      setTotalFriends(response?.data?.data?.totalCount);

    }
  }
  const unFriend = async (item) => {
    if (item?.status === 'active') {
        const params = {
            action: 'unFriendRequest',
            list: [item?._id]
        }
        await postDeleteOrCancelRequest(params)
            .then((res) => {
                if (res) {
                    const nextList = [...listOfFriends];
                    let index = nextList?.findIndex(a => a._id === item?._id);
                    showSingleToastSuccess(t(`apiMessages.FRIEND_REMOVED`));
                    getAllfriendsList(0);
                }
            })
            .catch((error) => {
                console.log(error);
                showToastError(t('errors.some_error'));

            })
    }
    else {
        showToastError(item?.firstName + t('apiMessages.is_not_active'));
    }
}
const showUser=(user)=>{
  let encrptedId = window.btoa(user?._id);
  //navigate(`/profile/${encrptedId}`)
  navigate(`/profile/${encrptedId}`, { state: {userId:user?._id}})
}
  return (
    <div className="widget-box">

      <p className="widget-box-title fewFriendsHeading">{heading ? heading : t('friends')} <span className='friendsCountText'>{totalFriends}</span></p>

      <div className="widget-box-content">
        <div className="user-status-list interestedPeople">
          {listOfFriends.map((item, i) => (
            <div className="d-flex align-items-center" key={i}>
                    <div className="invite_people_img" onClick={()=>showUser(item)}>
                      <AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile',item?.profilePicture) : defaultUserImage} />

                   </div>
              <div>
                <h6 to="/friends">{(`${item?.firstName} ${item?.lastName}`) || ''}</h6>
                <p>{item?.totalMutualFriend} {t('friends_in_common')}</p>
              </div>

              <div className="action-request-list mail_icons_left">
                <div className="action-request accept">
                  {/* <InsertEmoticonIcon className="action-request-icon" /> */}
                   <button className="box-btn" title={t('Remove_Friend')} onClick={() => unFriend(item)}> <img src="/Assets/Remove Friend.png" /></button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="widget-box-content">
        <Link to="/my-friends" className="button small primary">{t('see_all_friends')}</Link>
      </div>
    </div>
  )
}

export default FewFriendsList;