import React, {
  useState, useEffect, useCallback, useMemo,
  useRef
} from "react";
import { useTranslation } from "react-i18next";
import { GetTargetListDropDownService, GetEventByTargetService } from "../services/event.services";
import { CreatePostService , externalLinkDataService, getTagSuggestionsService} from "../services/post.services";
import { FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import PhotoVideoPostPopup from "./photoVideoPostPopup";
import { showSingleToastSuccess, showToastError } from "../utils/helpers";
import { uploadFileService } from "../services/fileUpload.services";
import { useDispatch, useSelector } from "react-redux";
import { setLoadNewPosts } from "../Reducer/user/user.action";
import { Autocomplete, TextField } from '@mui/material';
import { selectCurrentUserRole,selectLoadNewPosts  } from "../Reducer/user/user.selector";
import { getImageUrl } from "../utils/helpers";

//Draft JS mentions 
import { CompositeDecorator, ContentState, EditorState, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import { AvatarImg } from "../commonComponents/Images";
import editorStyles from './SimpleMentionEditor.module.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import { getLinkPreview } from "link-preview-js";
import { Col, Row } from "react-bootstrap";
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import { convertFromHTML } from "draft-convert";
import '@draft-js-plugins/linkify/lib/plugin.css';
import LinkifyContent from "./LinkifyContent";
import PostPreview from "./postPreview";
import { getItemFromStorage, setItemInStorage } from "../storage/Storage";


const CreatePostBox = () => {
    const {t} = useTranslation();
    const role = useSelector(selectCurrentUserRole);
    const dispatch = useDispatch();
    const loadNewPosts = useSelector(selectLoadNewPosts)
    const [open, setOpen] = useState(false);
    const max_length = 1000;
    const [targets, setTargets] = useState([]);
    const [error, setError] = useState(false);
    const [events, setEvents] = useState([]);
    const [isLoading , setLoading] = useState(false)
    const [target, setTarget] = useState(null);
    const [event, setEvent] = useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const [previewData, setPreviewData] = useState(null);
    const [selectedFiles , setSelectedFiles] = useState([]);
    const INITIAL_FORM_VALUES = {
      targetId : "",
      eventId : "",
      photos : [],
      videos : [],
      postText : "",
      postVisibility : 'everyone',
    }
    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);
    const [tags, setTags] = useState([])

  //DRAFT JS 
  const linkifyPlugin = createLinkifyPlugin();

  const ref = useRef(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(()=>{
    discardHandler()
  },[loadNewPosts])


  useEffect(() => {
    let raw = convertToRaw(editorState.getCurrentContent())
    setFormValues({ ...formValues, postText: raw.blocks[0].text.trim() })
  }, [editorState])

  const [optionsOpen, setOptionsOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const _handlePastedText = (text, html) => {
    const contentState = ContentState.createFromBlockArray(
      convertFromHTML(html).contentBlocks,
      convertFromHTML(html).entityMap
    );
    const newEditorState = EditorState.push(editorState, contentState);
    setEditorState(newEditorState);
    return "handled";
  };

  const decorator = new CompositeDecorator([
    {
      strategy: (contentBlock, callback) => {
        contentBlock.findEntityRanges((character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            editorState.getCurrentContent().getEntity(entityKey).getType() ===
              "LINK"
          );
        }, callback);
      },
      component: (props) => {
        const { url } = props.contentState.getEntity(props.entityKey).getData();
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {" "}
            {props.children}{" "}
          </a>
        );
      },
    },
  ]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin();
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, linkifyPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open) => {
    setOptionsOpen(_open);
  }, []);
  const onSearchChange = useCallback(({ value }) => {
    if (!value) return;
    getTagSuggestionsService(value)
      .then((res) => res?.data?.data?.data)
      .then((res) => {
        let data = []
        res.map((user) => data.push({
          _id: user?._id,
          name: `${user.firstName} ${user.lastName}`,
          link: `/profile/` + window.btoa(user?._id),
          avatar: user?.profilePicture !== '' ? getImageUrl(user?._id, 'userProfile', user?.profilePicture) : AvatarImg
        }))
        setSuggestions(data);
      })
  }, []);
  //********* */

  useEffect(() => {
    setEvent(null);
    setTarget(null);
  }, [role])

  useEffect(() => {
    getTargetListing();
  }, [role])

  useEffect(() => {
    getEventByTarget();
  }, [formValues.targetId,target])

  useEffect(() => {
    if (target !== null) {
      setFormValues({ ...formValues, targetId: target?._id })
    } else { 
      setFormValues({ ...formValues, targetId: '' }) 
    }
  }, [target])

  useEffect(() => {
    if (event !== null) {
      setFormValues({ ...formValues, eventId: event?._id })
    } else { 
      setFormValues({ ...formValues, eventId: '' }) 
    }
  }, [event])

  const getTargetListing = async () => {
    GetTargetListDropDownService("allTarget")
      .then((data) => {
        if (data?.data?.status === 200) {
          setTargets(data?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const getEventByTarget = () => {
    if (formValues?.targetId !== '') {
      GetEventByTargetService(formValues?.targetId)
        .then((data) => {
          if (data?.data?.status === 200) {
            setEvents(data?.data?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }
    else {
      setEvents([]);
    }

  }

  const submitPostHandler = () => {
    setError(false)
    let raw = convertToRaw(editorState.getCurrentContent())

    // let preview = {
    //   image : previewData?.image,
    //   title : previewData?.title,
    //   description : previewData.description
    // }
    if (formValues.targetId === '' || formValues.eventId === '' || formValues.postText === '') {
      setError(true); return;
    }
    setLoading(true);
    try {

      let mentioned_users = []

      for (let key in raw.entityMap) {
        const ent = raw.entityMap[key]
        if (ent.type === 'mention') {
          mentioned_users.push(ent.data.mention._id)
        }
      }

      let photos = []
      let videos = []

      if (selectedFiles.length > 0) {
        selectedFiles.map((item, index) => {
          if (item.type.includes('video')) {
            videos.push(item.lastModified)
          }
          if (item.type.includes('image')) {
            photos.push(item.lastModified)
          }
        })
      }
      let params = {
        targetId: formValues.targetId,
        eventId: formValues.eventId,
        categoryId: target?.spredeInterestSubCategory?._id,
        post: formValues.postText,
        postVisibility : formValues.postVisibility,
        tags: mentioned_users,
        postMentions: raw,
        image: photos,
        video: videos,
        // metaData : preview
      }
      CreatePostService(params)
        .then(async (data) => {
          if (data?.data?.status === 200) {
            if (selectedFiles.length > 0) {
              let result = await uploadFiles(data?.data?.data?.insertedId);
            }
            setEditorState(EditorState.createEmpty())
            showSingleToastSuccess(t(`apiMessages.POST_CREATE_SUCCESS`))
            dispatch(setLoadNewPosts(!loadNewPosts));
            discardHandler();
            setPreviewData(null);
          }
          else{
            showToastError(t(`apiMessages.${data?.data?.data}`))
          }

        })
        .catch((error)=>{
          showToastError(t(`apiMessages.${error?.data?.data}`))
          setLoading(false);
        })
    }
    catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const uploadFiles = async (id) => {
    await Promise.all(selectedFiles.map(async (item) => {
      if (item.type.includes('video')) {
        await uploadFileService('voxBoxVideo', 'voxBox', item.lastModified, item, id)
      }
      if (item.type.includes('image')) {
        await uploadFileService('voxBoxImage', 'voxBox', item.lastModified, item, id)
      }
    }))

    return true;
  }

  const setPhoto = async (param) => {
    if (param.length !== 0) {
      setSelectedFiles([...selectedFiles, ...param]);
    }
  }

  const deleteSelectedFile = (e, i) => {
    e.preventDefault();
    let arr = selectedFiles;
    let filter = arr.filter((item, index) => { return index !== i });
    setSelectedFiles(filter)
  }

  const deleteMetaData = () => {
    setPreviewData('');
    setTimeout(() => {
      setItemInStorage('postlink', '')
    }, 300);
    handleClose();
  }

  const discardHandler = () => {
    setLoading(false)
    setFormValues(INITIAL_FORM_VALUES);
    setSelectedFiles([]);
    setTarget(null);
    setTags([]);
    setEvent(null);
    setEditorState(EditorState.createEmpty())
    setError(false);
  }

  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        };
      }
    }

    return length;
  }
  
  const handleLinkPreview = async (e) => {
    var input = e;
    var expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    var matches = input.match(expression);
    if(matches?.length) {
      let finalLink = matches[matches?.length-1];
      try {
          let link = getItemFromStorage('postlink');
          if(finalLink !== link){
            setItemInStorage('postlink', finalLink)
            let data = await externalLinkDataService(finalLink)    
            setPreviewData(data?.data?.data);
          }    
        
        } catch (error) {
    
          console.error('Error fetching link preview:', error);    
          setPreviewData(null);
    
        }
    }

  }

  const _handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > max_length - 1) {
      return 'handled';
    }
  }

  // const _handlePastedText = (pastedText) => {
  //   const currentContent = editorState.getCurrentContent();
  //   const currentContentLength = currentContent.getPlainText('').length;
  //   const selectedTextLength = _getLengthOfSelectedText();

  //   if (currentContentLength + pastedText.length - selectedTextLength > max_length) {
  //     return 'handled';
  //   }
  // }
 const setTargetvalNow=(val)=>{
   setTarget(val);
   setFormValues({ ...formValues, targetId: val?._id })
   getEventByTarget();
  }

  return (
    <div className="quick-post">
    <div className="quick-post-header">
      <div className="option-items">
        <div className="option-item active ms-0">
          <svg className="option-item-icon icon-status">
          </svg>
          <p className="option-item-title me-3"><img src="/Assets/Status Icon.png" style={{marginRight: "13px"}} />{t('button.status')}</p>
        </div>
        <div id="postVisibilitySelect" className="option-item postVisibilityInput">
          <FormControl fullWidth className="comment">
            {/* <InputLabel id="co-country-select-label">
              {t('postVisibility')}
            </InputLabel> */}
            {
              (role==='user')?<Select
              MenuProps={{
                disableScrollLock: false
              }}
              labelId="co-country-select-label"
              value={formValues.postVisibility}
              label={t('postVisibility')}
              sx={{
                textAlign: 'left',
                maxWidth: '125px',
                background: '#fff'
              }}
              onChange={(e) => setFormValues({...formValues, postVisibility:e.target.value})}
            >
              <MenuItem value="everyone"><img className="createPostMenuImg" src="img/Post-icon/Everyone.png" alt="" /> {t('public')}</MenuItem>
              <MenuItem value="friends"><img className="createPostMenuImg" src="img/Post-icon/friends.png" alt="" /> {t('friends')} </MenuItem>
              <MenuItem value="private"><img className="createPostMenuImg" src="img/Post-icon/Privet.png" alt="" />{t('private')}</MenuItem>
            </Select>:
            <Select
            MenuProps={{
              disableScrollLock: false
            }}
            labelId="co-country-select-label"
            value={formValues.postVisibility}
            label={t('postVisibility')}
            sx={{
              textAlign: 'left',
              maxWidth: '125px',
              background: '#fff'
            }}
            onChange={(e) => setFormValues({...formValues, postVisibility:e.target.value})}
          >
            <MenuItem value="everyone"><img className="createPostMenuImg" src="img/Post-icon/Everyone.png" alt="" /> {t('public')}</MenuItem>
            <MenuItem value="private"><img className="createPostMenuImg" src="img/Post-icon/Privet.png" alt="" />{t('private')}</MenuItem>
          </Select>
            }
          </FormControl>
        </div>
      </div>
    </div>
      <div className="quick-post-body">
        <form className="form">
          <div className="form-row">
            <div className="form-item">
              <div className="form-textarea">

                <div
                  className={editorStyles.editor}
                  onClick={() => {
                    ref.current.focus();
                  }}
                >
                  <Editor
                    id="postText"
                    editorKey={'editor'}
                    editorState={editorState}
                    onChange={(e)=>{setEditorState(e)
                      if(e.getCurrentContent().getPlainText('')){
                        handleLinkPreview(e.getCurrentContent().getPlainText(''))
                      } else {
                        setPreviewData(null);
                      }
                    }}
                    plugins={plugins}
                    ref={ref}
                    value={formValues?.postText}
                    placeholder={t('Enter_some_post_text')}
                  />
                  <MentionSuggestions
                    open={optionsOpen}
                    onOpenChange={onOpenChange}
                    suggestions={suggestions}
                    onSearchChange={onSearchChange}
                    onAddMention={() => {
                      // get the mention object selected
                    }}
                  />
                </div>
                <div onClick={() => { ref.current.blur(); }}>
                    <PostPreview data={previewData} deleteMetaData={deleteMetaData}/>
                </div>

                <p className="form-textarea-limit-text">{ editorState.getCurrentContent().getPlainText('').length}/{max_length}</p>
              </div>
              {/* { error && formValues?.postText.length > max_length && <div className="error-text"> {t('You_have_been_exceded_the_limit')} </div>} */}
              {/* {error && formValues.postText === '' && <div className="error-text"> {t('errors.post_desc_required')} </div>} */}
            </div>
          </div>
        </form>
      </div>

      <div className="quickPostContainer">
        {selectedFiles.map((item, index) => {
          return (
            <div className="quickPostItems" key={index}>
              <div className="photo-preview quickPost">
                {
                  item.type.includes('video') ?
                    <video poster src={URL.createObjectURL(item)} alt="" />
                    :
                    <img src={URL.createObjectURL(item)} alt="" />
                }

                <button className="deleteImgBtn"
                  onClick={(e) => deleteSelectedFile(e, index)}
                >&times;</button>
              </div>
            </div>)
        })}
      </div>

      <div className="edit_post_area">
        <div className="edit_post_options editPostInputs">
          <div className="">
            <FormControl>
              <Autocomplete
                disablePortal
                value={target}
                options={targets}
                getOptionLabel={(option) => option?.title || ""}
                id="targetLink"
                onChange={(e, val) => {  setEvent(null); setTarget(val); setFormValues({ ...formValues, targetId: val?._id }) }}
                renderInput={(params) => <TextField {...params} label={t('form.select_target')} />}
              />
              {/* {error && formValues.targetId === '' && <div className="error-text"> {t('errors.target_required')} </div>} */}
            </FormControl>

          </div>

          <div className="eventLinkingField">
            <FormControl>
              <Autocomplete
                disablePortal
                value={event}
                options={target?events:[]}
                getOptionLabel={(option) => option?.title || ""}
                id="eventLink"
                onChange={(e, val) => { setEvent(val); }}
                renderInput={(params) => <TextField {...params} label={t('form.select_event')} />}
              />
              {/* {error && formValues.eventId === '' && <div className="error-text"> {t('errors.event_required')} </div>} */}
            </FormControl>

          </div>

          <div className="quick-post-footer-action text-tooltip-tft-medium  d-flex justify-content-end pe-2 mt-2" onClick={handleOpen} style={{ cursor: "pointer" }}>
            <img src="/Assets/Photo-Video.png" alt="" className="quick-post-footer-action-icon icon-camera" /> {t('form.photoVideo')}
          </div>
        </div>

        <div className="quick-post-footer-actions edit_btn_area">
          {(formValues.targetId !== '' || formValues.eventId !== '' || formValues.postText !== '' || formValues.photos.length > 0 || formValues.videos.length > 0) &&
            <button className="button void d-flex m-2" 
              onClick={(e) => discardHandler(e)}
              id="discardPost"
            >
              {t('button.discard')}
            </button>
          }
          <button type="button" className="button primary mt-2"
            id="submitPost"
            disabled={isLoading}
            onClick={(e) => submitPostHandler(e)}>
            {t('button.post')}
            {isLoading && <span className="spinner-border" />}
          </button>
        </div>
      </div>
      <div className={(error)?'error-text px-4':'white-text px-4'}>
          {!error || (error && formValues.postText === '' && formValues.targetId === '' && formValues.eventId === '') ?
              "Enter post description, select target and event"
              :
              (error && formValues.postText !== '' && formValues.targetId === '' && formValues.eventId === '') ? 
              'Select target and event' :
              (error && formValues.postText === '' && formValues.targetId !== '' && formValues.eventId === '') ?
              'Enter post description and select event' :
              (error && formValues.postText === '' && formValues.targetId === '' && formValues.eventId !== '') ?
              'Enter post description and select target' : 
              (error && formValues.postText === '') ?
              'Enter post description' : 
              (error && formValues.targetId === '') ?
              'Select target' : 
              (error && formValues.eventId === '') ?
              'Select event' : 
              ''
          }          
        </div>

      <PhotoVideoPostPopup
        open={open}
        handleClose={handleClose}
        setPhoto={setPhoto}
        selectedFiles={selectedFiles}
        deleteSelectedFile={deleteSelectedFile}
      />

    </div>);
}

export default CreatePostBox;