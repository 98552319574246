import React, { useEffect} from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { useState } from "react";

const PdfDocument= ({url, openPhotoHandler}) => {
    const [file, setFile] = useState(url);
    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, []);

    return(
        
        <Document file={file} onClick={(e) => openPhotoHandler()} onLoadSuccess={console.log("Pdf success")}><Page pageNumber={1} renderTextLayer={false} /></Document>

    )
}

export default PdfDocument;