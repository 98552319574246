import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AvatarImg } from "../commonComponents/Images";
import { getImageUrl} from "../utils/helpers";
import { Link } from "react-router-dom";
import { changeDateFormatmmddyyyy, dateTimeFormat } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
const ValidatorEventsBox = ({ eventData }) => {
    const { t } = useTranslation();
    const [viewMore, setViewMore] = useState(false);
    const navigate = useNavigate();
    return (<>
        <div className="bg-white borderRadius target_post_box mt-2">
        <div className="target_box_area row">
                <div className="col-md-5 validatorEventTextArea">
                    <div className="target_box_profile_area">
                        <div className="your_target_avatar">
                            <img src={(eventData?.photo !== '') ? getImageUrl(eventData?._id, 'event', eventData?.photo) : AvatarImg} alt="" />
                        </div>
                        <div className="target-box">
                            <h5 className="">{eventData?.title}</h5>
                            <p>{eventData?.description.substring(0, 20) + '..'}</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 validatorEventInfo">
                    <div className="information-line target-para">
                        <p className="information-line-title">{t('to')}</p>
                        <p className="information-line-text">{changeDateFormatmmddyyyy(eventData?.endDate)}</p>
                    </div>
                    <div className="information-line target-para">
                        <p className="information-line-title">{t('from')}</p>
                        <p className="information-line-text">{changeDateFormatmmddyyyy(eventData?.startDate)}</p>
                    </div>
                </div>
                
                <div className="validatorEventBtnBox col-md-3">
                    <Link to="#" className="button primary view-event-btn" 
                        onClick={(e)=>{e.preventDefault();
                            navigate('/view-event' , {state:{eventId : eventData?._id}})
                        }} >
                            {t('button.view_event')}
                    </Link>
                </div>
            </div>
        </div>
    </>);
}

export default ValidatorEventsBox;