import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RoundRadio from "./roundCheckbox/roundRadio";
import RoundCheckbox from "./roundCheckbox";

const EditOrgLegalServiceType = ({ canEdit = true, legalServiceType, formValues, setFormValues, saveNewServiceType, saveNewLegalType, additionServiceType }) => {
    const { t } = useTranslation();
    const [newLegalType, setNewLegalType] = useState('')
    const [newServiceType, setNewServiceType] = useState('')


    const removeServiceTypeToArray = (element) => {
        // Removing the specified element by value from the array 
        let arr = formValues.serviceType;
        let filter = arr.filter((item) => { return item !== element });
        setFormValues({ ...formValues, serviceType: filter })
    }

    const addServiceTypeToArray = (element) => {
        let arr = formValues.serviceType;
        arr.push(element);
        setFormValues({ ...formValues, serviceType: arr })
    }

    useEffect(() => {
        if (additionServiceType !== '') {
            addServiceTypeToArray(additionServiceType)
        }
    }, [additionServiceType])

    const handleLegalTypeKeyUp = (event, value) => {
        event.preventDefault();
        if (value !== '' && value !== undefined && event.key === 'Enter') {
            saveNewLegalType(value)
            setNewLegalType('');
        }
    }

    const handleServiceTypeKeyUp = (event, value) => {
        if (value !== '' && value !== undefined && event.key === 'Enter') {
            saveNewServiceType(value)
            setNewServiceType('');
        }
    }

    return (
        <>
            <div className="Int-Set-title">
                <h3 className="">
                    {t('legal_structure')}  <img src="/Assets/i.png" alt="#" />
                </h3>
            </div>
            <div className="inv-set-opt">
                {legalServiceType?.legalStructure && legalServiceType?.legalStructure.length > 0 && legalServiceType?.legalStructure.map((item, index) =>
                    <RoundRadio
                        disabled={!canEdit}
                        name="legalStructure"
                        key={index}
                        label={item.name}
                        value={item._id}
                        checked={formValues?.legalStructure.includes(item._id) ? true : false}
                        onChange={(e) => {
                            (e.target.checked) ?
                                setFormValues({ ...formValues, legalStructure: item._id }) :
                                setFormValues({ ...formValues, legalStructure: '' })
                        }}
                    />
                )}

                {canEdit && <RoundCheckbox
                    label={t('other')}
                    other={true}
                    // checked={(formValues.interests.indexOf(newInterest['newInterest_' + item._id]) > 0) ? true : false}
                    input={
                        <input type="text"
                            value={newLegalType}
                            // defaultChecked={check}
                            //name={`newInterest_${item._id}`}
                            onChange={(e) => setNewLegalType(e.target.value)}
                            onKeyUp={(e) => handleLegalTypeKeyUp(e, newLegalType?.trim())}
                            className="checkbox_text_feild" placeholder={t('Enter_other_legal_structure')} />}
                />}
            </div>


            <div className="Int-Set-title">
                <h3 className="">
                    {t('business_comdity_service_type')} <img src="/Assets/i.png" alt="#" />
                </h3>
            </div>
            <div className="inv-set-opt">
                {legalServiceType?.serviceType && legalServiceType?.serviceType.length > 0 > 0 && legalServiceType?.serviceType.map((item, index) =>
                    <RoundCheckbox
                        disabled={!canEdit}
                        key={index}
                        label={item.name}
                        value={item._id}
                        checked={
                            (formValues?.serviceType?.indexOf(item._id) > -1) ? true : false}
                        onChange={(e) => {
                            (e.target.checked) ?
                                addServiceTypeToArray(item._id) :
                                removeServiceTypeToArray(item._id)
                        }}
                    />
                )}

                {canEdit && <RoundCheckbox
                    label={t('other')}
                    other={true}
                    // checked={(formValues.interests.indexOf(newInterest['newInterest_' + item._id]) > 0) ? true : false}
                    input={
                        <input type="text"
                            value={newServiceType}
                            // defaultChecked={check}
                            // name={newServiceType}
                            onChange={(e) => setNewServiceType(e.target.value)}
                            onKeyUp={(e) => handleServiceTypeKeyUp(e, newServiceType?.trim())}
                            className="checkbox_text_feild" placeholder={t('Enter_other_business_type')} />}
                />}
            </div>
        </>
    )
}

export default EditOrgLegalServiceType