import ApiConfig from "../config/ApiConfig";
import instance from "../AppInterceptor";


export const getGeneralSettingService = async () => {
  const response = await instance.get(`${ApiConfig.getGeneralSetting}`,);
  if (response?.data?.status === 200) {
    return response.data || '';
  }
}

export const updateGeneralSettingService = async (option) => {
  const response = await instance.post(`${ApiConfig.getGeneralSetting}`, option);
  if (response?.data?.status === 200) {
    return response.data || '';
  }
}

export const updatePasswordService = (payload) => {
  return instance.post(ApiConfig.updatePassword , payload);
}
