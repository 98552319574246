import React, { useState } from 'react';
import { useEffect } from 'react';
import { getComplaintFeedback } from '../../../services/onBoarding.services';
import SectionHeader from '../../../commonDashboardComp/sectionHeader';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import './cfStyle.css';
import ComplaintFeedbackBox from './complaintFeedbackBox';
const CheckComplaintFeedback = () => {
  useEffect(() => {
    loadComplaintFeedback();
  }, []);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const loadData = (data) => {
    setData(data);
    sortData(data);
  };
  const sortData = (data) =>{
    let sortedData = data.sort((date1, date2)=>{
      let d1 = new Date(date1.createdAt);
      let d2 = new Date(date2.createdAt);
      return d2-d1;
    });
    setFilteredData(sortedData);
  }
  const loadComplaintFeedback = () => {
    try {
      getComplaintFeedback().then(async (data) => {
        if (data?.data?.status == 200) {
          loadData(data?.data?.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [filteredData, setFilteredData] = useState([]);
  const filterData = (e) => {
    let selectedValue = e.target.value;
    if(selectedValue == 'All'){
      setFilteredData(data);
    }
    else{
      let filteredDataValue = data.filter((item)=>{
        if(item.request_type == selectedValue){
          return(item);
        }
      })
      sortData(filteredDataValue);
    }
  }
  return (
    <>
      <SectionHeader pretitle="" title={t('complaintAndFeedback')} />
      <div id="checkcf_container">
        <Row className="rounded-4 bg-white mt-3 py-3 px-2">
          <Col lg={12}>
            <label id="filterLabel" htmlFor="cfFilter">
              Filter:
            </label>
            <select name="" id="cfFilter" onChange={filterData}>
              <option value="All">All</option>
              <option value="Complaint">Complaint</option>
              <option value="Feedback">Feedback</option>
            </select>
          </Col>
        </Row>
        {filteredData.length != 0
          ? filteredData.map((item) => {
              if (item.request_type != null) {
                return (
                    
                  <ComplaintFeedbackBox
                    key={item._id}
                    requestImage={(item.image)?item.image:[]}
                    requestVideo={(item.video)?item.video:[]}
                    requestType={item.request_type}
                    requestHeading={item.issue}
                    requestDescription={item.description}
                    requestId={item._id}
                    creatorId={item.createdBy}
                    createdAt={item.createdAt}
                  ></ComplaintFeedbackBox>
                );
              }
            })
          : ''}
      </div>
    </>
  );
};

export default CheckComplaintFeedback;
