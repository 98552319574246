import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import RoundRadio from "../../../commonComponents/roundCheckbox/roundRadio";
import ClearIcon from '@mui/icons-material/Clear';
import {
    usernameSuggestionsService,
    checkUsernameAvailabilityService
} from "../../../services/onBoarding.services";
import { useEffect } from "react";
import { usernameValidate } from "../../../utils/formatValidator";
const ProfileInfo = ({ skip, skipClick, backClick, formValues, setFormValues, saveUserDetails }) => {
    const { t } = useTranslation();
    const [unameError, setUnameError] = useState(false);
    const [isCopied, setCopied] = useState(false);

    const [suggestions, setSuggestions] = useState([]);
    const [copyText, setCopyText] = useState(formValues.url);
    // const [click, setClick] = useState(false);
    useEffect(() => {
        getSuggestionsList();
    }, []) //formValues.userName

    useEffect(() => {
        setFormValues({ ...formValues, url: formValues.base_url + '/u/'+formValues?.userName })
    }, [formValues.userName]);

    const checkUsernameAvailability = async (username) => {
        setUnameError(false);

        if (formValues.default_userName !== formValues.userName) {
            if (!usernameValidate(username)) {
                setUnameError(true); return;
            }
            checkUsernameAvailabilityService(username)
                .then(async (data) => {
                    if (data?.data?.status !== 200) {
                        setUnameError(true);
                    }
                    else {
                        setFormValues({ ...formValues, url: formValues.base_url + '/' + formValues.userName })
                    }
                })
                .catch((error) => {
                    setUnameError(true);
                    console.log(error);
                })
        }
    }

    const getSuggestionsList = async () => {
        setUnameError(false);
        usernameSuggestionsService(formValues.userName)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setSuggestions(data?.data?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleSuggestionsChange = (e) => {
        setFormValues({ ...formValues, userName: e.target.value });
    }


    const nextClick = async (e) => {
        e.preventDefault();
        //await checkUsernameAvailability(formValues.userName);
        if (unameError) { return; }
        if (!usernameValidate(formValues.userName)) {
            setUnameError(true); return;
        }
        if (formValues.default_userName !== formValues.userName) {
            let params = {
                userName: formValues.userName,
            }
            saveUserDetails(params)
            //setFormValues({ ...formValues, default_userName: formValues.userName });
        }
        else {
            skip();
        }
    }
    const copyTextChange = () => {
        navigator.clipboard.writeText(formValues.url)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 3000);
    }
    return (
        <>
            <div className="heading">
                <h1>{t('onboarding.userProfileInfo')}</h1>
                <h3>{t('onboarding.choose_username_and_url')}</h3>
            </div>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className="userdetail-form"
            >
                <Row>
                    <Col md={7} className="userNameDemographic">
                        <TextField
                            value={formValues.userName}
                            onChange={(e) => {
                                if (e.target.value.length < 20) {
                                    setFormValues({ ...formValues, userName: e.target.value })
                                }
                            }}
                            onKeyUp={()=>getSuggestionsList()}
                            label={t('form.user_name')}
                            disabled={(formValues.userNameCount === 1) ? true : false}
                            helperText={t('form.you_can_update_username_only_once')}
                            InputProps={{
                                endAdornment:
                                    <img src="/Assets/pro-search-green.png" alt="#" className="calender_icons"
                                        onClick={() => checkUsernameAvailability(formValues.userName)}
                                        style={{ cursor: 'pointer' }}
                                        title={t('form.check_username_availability')}
                                    />
                            }}
                        />
                    </Col>
                    {unameError &&
                        <Col md={5}>
                            <div className="error">
                                <ClearIcon />
                                {!usernameValidate(formValues.userName) ?
                                    t('errors.username_format_invalid') :
                                    t('errors.username_already_available')}

                            </div>
                        </Col>
                    }

                    {formValues.userNameCount !== 1 && suggestions.length > 0 &&
                        <Col md={10} className="suggest_tab_box">
                            <span className="sugge_text"> {t('suggestions')} : </span>
                            {suggestions.map((item, index) =>
                                <RoundRadio key={index} label={item} value={item} name='suggestion' onChange={handleSuggestionsChange} aria-labelledby="demo-controlled-radio-buttons-group" />
                            )}
                        </Col>
                    }

                    <Col md={7} className="url_mobile">
                        <TextField
                            disabled={true}
                            value={formValues.url}
                            label={t('form.your_url')}
                            InputProps={{ endAdornment: <img onClick={copyTextChange} src="/Assets/copy-inactive.png" alt="#" className="calender_icons" /> }}
                        // InputProps={{endAdornment: <button  onClick={copyTextChange}><img src="/Assets/copy-inactive.png" /></button>}}
                        />
                        {
                            isCopied && <div className="click_copy"><p className="">{t('form.Copied')}</p></div>
                        }
                        {/* {click?<span>Copied</span>:null} */}
                    </Col>
                </Row>
            </Box>


            <Row>
                <div className="back-next-btn-area contact-back-next-btn-area">
                    <Stack spacing={2} direction="row">
                        <button className="button green back-btn-new" onClick={backClick}> {t('back')} </button>
                        <button className="button secondary next-btn-new" onClick={(formValues.userNameCount !== 1) ? nextClick : skipClick}> {t('next')} </button>
                        {formValues.userNameCount !== 1 && <button className="button back-btn-new skip-color" onClick={skipClick}> {t('skip')} </button>}
                    </Stack>
                </div>
            </Row>

        </>
    )
}

export default ProfileInfo;