import React from "react";
import './avatarImgBox.style.css';
import { AvatarImg } from "../Images";
const AvatarImgBox = ({img, avatarClasses})=>{
    if(img === '' || img === null){
        img = AvatarImg;
    }
    return (<>
        <div className={`avatarImgBox ${avatarClasses}`}>
            <div className="poligon-green">
                <div className="poligon-white">
                    <div className="avatar_bg" style={{'--background': `url(${img})  center/cover`}}>
                    </div>
                </div>
            </div>
        </div>
        <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1" style={{visibility: 'hidden', position: 'absolute'}}>
            <defs>
                <filter id="roundNew">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />    
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
                            </filter>
            </defs>
        </svg>
        </>
    )
}

AvatarImgBox.defaultProps={avatarClasses:""}
export default AvatarImgBox;