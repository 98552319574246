import React from "react";
import { useTranslation } from "react-i18next";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { Link } from "react-router-dom";
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { getImageUrl } from "../utils/helpers";
const EventDetailBox = ({event}) => {
    const {t} = useTranslation();
    return(<>
        <div className="widget-box no-padding">
          <div className="widget-box-status">
            <div className="widget-box-status-content">
              <div className="user-status">
                <p className="user-status-title medium">
                    <div className="bold">{t('description')}</div>
                </p>
              </div>
      
              <p className="widget-box-status-text">{event?.description}</p>

              <div className="voxbox_post_area_">
                  <div className="voxbox_post_upline">
                    <figure>
                        <img src={getImageUrl(event?._id, 'event', event?.photo)} alt="" />
                    </figure>
                  </div>
              </div>
              <div className="content-actions">
                <div className="content-action">
                  <div className="meta-line">
                    <div className="meta-line-list reaction-item-list">
                      <div className="reaction-item" style={{position: 'relative'}}>
                        <img className="reaction-image reaction-item-dropdown-trigger" src="/img/reaction/wow.png" alt="reaction-wow"/>
            
                        <div className="simple-dropdown padded reaction-item-dropdown" style={{position: 'absolute', zIndex: 9999, bottom: '38px', left: '-16px', opacity: 0, visibility: 'hidden', transform: 'translate(0px, 20px)', transition: 'transform 0.3s ease-in-out 0s, opacity: 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s'}}>
                          <p className="simple-dropdown-text"><img className="reaction" src="/img/reaction/wow.png" alt="reaction-wow"/> <span className="bold">Wow</span></p>
                        
                          <p className="simple-dropdown-text">Matt Parker</p>
                        </div>
                      </div>
      
                      <div className="reaction-item" style={{position: 'relative'}}>
                        <img className="reaction-image reaction-item-dropdown-trigger" src="/img/reaction/like.png" alt="reaction-like"/>
            
                        <div className="simple-dropdown padded reaction-item-dropdown" style={{position: 'absolute', zIndex: 9999, bottom: '38px', left: '-16px', opacity: 0, visibility: 'hidden', transform: 'translate(0px, 20px)', transition: 'transform 0.3s ease-in-out 0s, opacity: 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s'}}>
                          <p className="simple-dropdown-text"><img className="reaction" src="/img/reaction/like.png" alt="reaction-like"/> <span className="bold">Like</span></p>
                        
                          <p className="simple-dropdown-text">Sandra Strange</p>
      
                          <p className="simple-dropdown-text">Jane Rodgers</p>
                        </div>
                      </div>
                    </div>
            
                    <p className="meta-line-text">3</p>
                  </div>
            
                  <div className="meta-line">
                    <div className="meta-line-list user-avatar-list">
                      <div className="user-avatar micro no-stats">
                        <div className="user-avatar-border">
                          <div className="hexagon-22-24" style={{width: '22px', height: '24px', position: 'relative'}}><canvas width="22" height="24" style={{position: 'absolute', top: '0px', left: '0px'}}></canvas></div>
                        </div>
                    
                        <div className="user-avatar-content">
                          <div className="hexagon-image-18-20" data-src="/img/avatar/03.jpg" style={{width: '18px', height: '20px', position: 'relative'}}><canvas width="18" height="20" style={{position: 'absolute', top: '0px', left: '0px'}}></canvas></div>
                        </div>
                      </div>
            
                      <div className="user-avatar micro no-stats">
                        <div className="user-avatar-border">
                          <div className="hexagon-22-24" style={{width: '22px', height: '24px', position: 'relative'}}><canvas width="22" height="24" style={{position: 'absolute', top: '0px', left: '0px'}}></canvas></div>
                        </div>
                    
                        <div className="user-avatar-content">
                          <div className="hexagon-image-18-20" data-src="/img/avatar/15.jpg" style={{width: '18px', height: '20px', position: 'relative'}}><canvas width="18" height="20" style={{position: 'absolute', top: '0px', left: '0px'}}></canvas></div>
                        </div>
                      </div>
            
                      <div className="user-avatar micro no-stats">
                        <div className="user-avatar-border">
                          <div className="hexagon-22-24" style={{width: '22px', height: '24px', position: 'relative'}}><canvas width="22" height="24" style={{position: 'absolute', top: '0px', left: '0px'}}></canvas></div>
                        </div>
                    
                        <div className="user-avatar-content">
                          <div className="hexagon-image-18-20" data-src="/img/avatar/14.jpg" style={{width: '18px', height: '20px', position: 'relative'}}><canvas width="18" height="20" style={{position: 'absolute', top: '0px', left: '0px'}}></canvas></div>
                        </div>
                      </div>
            
                      <div className="user-avatar micro no-stats">
                        <div className="user-avatar-border">
                          <div className="hexagon-22-24" style={{width: '22px', height: '24px', position: 'relative'}}><canvas width="22" height="24" style={{position: 'absolute', top: '0px', left: '0px'}}></canvas></div>
                        </div>
                    
                        <div className="user-avatar-content">
                          <div className="hexagon-image-18-20" data-src="/img/avatar/07.jpg" style={{width: '18px', height: '20px', position: 'relative'}}><canvas width="18" height="20" style={{position: 'absolute', top: '0px', left: '0px'}}></canvas></div>
                        </div>
                      </div>
                    </div>
            
                    <p className="meta-line-text">4 Participants</p>
                  </div>
                </div>
            
                <div className="content-action">
                  <div className="meta-line">
                    <p className="meta-line-link">3 Comments</p>
                  </div>
            
                  <div className="meta-line">
                    <p className="meta-line-text">0 Shares</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
          <div className="post-options">
            <div className="post-option-wrap" style={{position: 'relative'}}>
              <div className="post-option reaction-options-dropdown-trigger">
                <ThumbUpOutlinedIcon className="post-option-icon icon-thumbs-up"/>
      
                <p className="post-option-text">React!</p>
              </div>
      
              <div className="reaction-options reaction-options-dropdown" style={{position: 'absolute', zIndex: 9999, bottom: '54px', left: '-16px', opacity: 0, visibility: 'hidden', transform: 'translate(0px, 20px)', transition: 'transform 0.3s ease-in-out 0s, opacity: 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s'}}>
                <div className="reaction-option text-tooltip-tft" data-title="Like" style={{position: 'relative'}}>
                  <img className="reaction-option-image" src="/img/reaction/like.png" alt="reaction-like"/>
                <div className="xm-tooltip" style={{whiteSpace: 'nowrap', position: 'absolute', zIndex: 99999, top: '-28px', left: '50%', marginLeft: '-22px', opacity: 0, visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s'}}><p className="xm-tooltip-text">Like</p></div></div>
      
                <div className="reaction-option text-tooltip-tft" data-title="Love" style={{position: 'relative'}}>
                  <img className="reaction-option-image" src="/img/reaction/love.png" alt="reaction-love"/>
                <div className="xm-tooltip" style={{whiteSpace: 'nowrap', position: 'absolute', zIndex: 99999, top: '-28px', left: '50%', marginLeft: '-23.5px', opacity: 0, visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s'}}><p className="xm-tooltip-text">Love</p></div></div>
      
                <div className="reaction-option text-tooltip-tft" data-title="Dislike" style={{position: 'relative'}}>
                  <img className="reaction-option-image" src="/img/reaction/dislike.png" alt="reaction-dislike"/>
                <div className="xm-tooltip" style={{whiteSpace: 'nowrap', position: 'absolute', zIndex: 99999, top: '-28px', left: '50%', marginLeft: '-28px', opacity: 0, visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s'}}><p className="xm-tooltip-text">Dislike</p></div></div>
      
                <div className="reaction-option text-tooltip-tft" data-title="Happy" style={{position: 'relative'}}>
                  <img className="reaction-option-image" src="/img/reaction/happy.png" alt="reaction-happy"/>
                <div className="xm-tooltip" style={{whiteSpace: 'nowrap', position: 'absolute', zIndex: 99999, top: '-28px', left: '50%', marginLeft: '-27.5px', opacity: 0, visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s'}}><p className="xm-tooltip-text">Happy</p></div></div>
      
                <div className="reaction-option text-tooltip-tft" data-title="Funny" style={{position: 'relative'}}>
                  <img className="reaction-option-image" src="/img/reaction/funny.png" alt="reaction-funny"/>
                <div className="xm-tooltip" style={{whiteSpace: 'nowrap', position: 'absolute', zIndex: 99999, top: '-28px', left: '50%', marginLeft: '-27px', opacity: 0, visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s'}}><p className="xm-tooltip-text">Funny</p></div></div>
      
                <div className="reaction-option text-tooltip-tft" data-title="Wow" style={{position: 'relative'}}>
                  <img className="reaction-option-image" src="/img/reaction/wow.png" alt="reaction-wow"/>
                <div className="xm-tooltip" style={{whiteSpace: 'nowrap', position: 'absolute', zIndex: 99999, top: '-28px', left: '50%', marginLeft: '-24px', opacity: 0, visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s'}}><p className="xm-tooltip-text">Wow</p></div></div>
      
                <div className="reaction-option text-tooltip-tft" data-title="Angry" style={{position: 'relative'}}>
                  <img className="reaction-option-image" src="/img/reaction/angry.png" alt="reaction-angry"/>
                <div className="xm-tooltip" style={{whiteSpace: 'nowrap', position: 'absolute', zIndex: 99999, top: '-28px', left: '50%', marginLeft: '-26.5px', opacity: 0, visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s'}}><p className="xm-tooltip-text">Angry</p></div></div>
      
                <div className="reaction-option text-tooltip-tft" data-title="Sad" style={{position: 'relative'}}>
                  <img className="reaction-option-image" src="/img/reaction/sad.png" alt="reaction-sad"/>
                <div className="xm-tooltip" style={{whiteSpace: 'nowrap', position: 'absolute', zIndex: 99999, top: '-28px', left: '50%', marginLeft: '-21.5px', opacity: 0, visibility: 'hidden', transform: 'translate(0px, 10px)', transition: 'all 0.3s ease-in-out 0s'}}><p className="xm-tooltip-text">Sad</p></div></div>
              </div>
            </div>
      
            <div className="post-option">
                <InsertCommentOutlinedIcon className="post-option-icon icon-comment"/>
                <p className="post-option-text">Comment</p>
            </div>
      
            <div className="post-option">
                <ShareOutlinedIcon className="post-option-icon icon-share"/>
                <p className="post-option-text">Share</p>
            </div>
          </div>
        </div>
    </>)
}

export default EventDetailBox;