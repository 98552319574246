import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { AvatarImg } from "../../../commonComponents/Images";
import { Box, TextField } from "@mui/material";
import { CSVLink } from "react-csv";
import { uploadFileService } from "../../../services/fileUpload.services";
import { showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import { bulkInvite } from "../../../services/follower.service";
import { useCSVDownloader, useCSVReader, usePapaParse } from 'react-papaparse';
import { firstLastNameValidate, validEmail } from "../../../utils/formatValidator";
import { useTranslation } from "react-i18next";


const BulkUploadFollowers = ({callParentCountMethod, setEmpCount}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const headers = [
        { label: t('form.label_first_name'), key: "firstName" },
        { label: t('Email'), key: "email" },
    ];


    const [csvFile, setCsvFile] = useState();
    const [csvArray, setCsvArray] = useState([]);
    const [error, setError] = useState(false);
    const { CSVDownloader, Type } = useCSVDownloader();
    const { CSVReader } = useCSVReader();
    const { readString } = usePapaParse();
    const data = [
    ];
    const csvReport = {
        data: data,
        headers: headers,
        filename: 'sprede_refer_and_invite_sample.csv',
        target: '_blank'
    };

    const handleUploadFile = (e) => {
        e.preventDefault();
        let file = csvFile;
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const text = e.target.result;
                processCSV(text)
            }
            reader.readAsText(file);
        }
        else {
            setError(true);
        }

    }
    const processCSV = async (str, delim = ',') => {
        let headers = str.slice(0, str.indexOf('\n')).split(delim);
        headers=headers.map((a)=>{
            if (a.includes('\r')) {
                a = a.replaceAll('\r', '');
            }
          return a;
        })
        //let checkingIfValidId = headers?.length == 2 && headers?.indexOf('"First Name"') >= 0 && headers?.indexOf('"Email"') >= 0;
        let checkIfValidHeaders=headers[0]==='First Name' && headers[1]==='Email';
        if(!checkIfValidHeaders){showToastError(t('Details_filled_in_choosed_CSV_file_should_be_same_as_given_Sample_CSV')); return;}

        const rows = str.slice(str.indexOf('\n') + 1).split('\n');
        const newHeader = ['firstName', 'email'];
        let newArray = rows.map(row => {
            const values = row.split(delim);
            const eachObject = newHeader.reduce((obj, header, i) => {
                obj[header] = values[i];

                if (obj['email']) {
                    if (obj['email'].includes('\r')) {
                        obj['email'] = obj['email'].replaceAll('\r', '');
                    }
                }
                return obj;
            }, {})
            return eachObject;
        })
        newArray = newArray?.filter((a => a?.firstName && a?.email));
        let cnt = newArray?.reduce((acc, curr) => {
            if (curr['firstName'] && curr['email']) {
                if (!validEmail(curr['email'])) {
                    acc += 1;
                }
                if (!checkingNameValid(curr['firstName'])) {
                    acc += 1;
                }
            }
            return acc;
        }, 0);
        if (cnt > 0) {
            showToastError(t('apiMessages.Email_or_FirstName_Enterted_are_not_correct'))
        }
        else {
            newArray = newArray?.filter((a => a?.firstName && a?.email));
            if (newArray?.length > 0) {
                let param = {
                    users: newArray
                }
                setLoading(true);
                await bulkInvite(param)
                    .then((resp) => {
                        if (resp?.status === 200) {
                            showSingleToastSuccess(t('apiMessages.Employee_invite_Details_uploaded_successfully'));
                            setLoading(false);
                            setCsvArray([]);
                            setCsvFile();
                            setEmpCount(true);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        showToastError(t('apiMessages.Some_Error_Occured_Please_try_again_later'));
                        setLoading(false);

                    })

            }
            else {
                showToastError(t('apiMessages.Choose_Correct_File_to_Upload'))
            }

            setCsvArray(newArray)

        }


    }
    const checkingNameValid = (name) => {
        var matches = name.match(/^[a-zA-Z]+$/);
        return (matches) ? true : false;

    }


    return (
        <Row className="mt-3">
            <Col md={6} className="my-2">
                <div className="widget-box h-100">
                    <p className="widget-box-title">{t('errors.Upload_CSV')}</p>
                    <div className="widget-box-content">
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '98%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Row>

                                <Col md={12}>
                                    <label className="user_img_upload" htmlFor="upload-photo">
                                        <input type="file" accept=".csv" id="upload-photo" onInputCapture={(e) => {
                                            setCsvFile(e.target.files[0]);
                                            setError(false);
                                            e.target.value=null
                                        }} />
                                        <div className="content m-0">
                                            <img src="/Assets/Icon awesome-file-csv.png" />
                                            {csvFile ? <>
                                                <h5 className="my-3">{csvFile?.name}</h5>
                                                <h6 className="gray-ligj-color">{t('roles.Change_CSV')}</h6>
                                            </> : <>
                                                <h5 className="my-3">{t('roles.Select_a_CSV_file_to_upload')}</h5>
                                                {/* <h6 className="gray-ligj-color">Or drag and drop it here</h6> */}
                                            </>}

                                        </div>
                                    </label>
                                    {(!csvFile && error) &&
                                        <div className="error-text">
                                            {t('roles.Please_Select_CSV_file')}
                                        </div>
                                    }
                                </Col>
                                <Col md={{ span: 6, offset: 6 }} lg={{ span: 3, offset: 9 }} className="mb-0 text-right" xs={12}>
                                    <button className="button primary bulkUploadBtn" onClick={(e) => handleUploadFile(e)}> {t('errors.Upload')}   {loading && <span className="spinner-border" />}</button>
                                </Col>
                            </Row>
                        </Box>
                    </div>
                </div>
            </Col>

            <Col md={6} className="my-2">
                <div className="widget-box h-100">
                    <p className="widget-box-title">{t('errors.Refer_Invite_Via_Email')}</p>
                    <div className="widget-box-content">
                        <Row>
                            <Col md={12} className="text-center">
                                <img src="/Assets/Icon awesome-file-csv.png" />
                                <h5 className="mt-3 mb-5">{t('errors.Here_is_the_sample_CSV_file')}</h5>
                            </Col>
                            <Col xs={12} className="text-center">
                                    <CSVDownloader
                                        type={Type.Link}
                                        filename={'sprede_refer_and_invite_sample.csv'}
                                        bom={true}
                                        data={'First Name,Email'}
                                        separator=","
                                        className={"bulkDownloadBtn"}
                                    >
                                        {t('download')}
                                    </CSVDownloader>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default BulkUploadFollowers;