import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { AvatarImg } from "../../../commonComponents/Images";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { getAllFriendsAddedList, getUsersBySearchRequest, postDeleteOrCancelRequest } from "../../../services/friends.service";
import { listClasses } from "@mui/material";
import ReactPagination from "../../../commonComponents/reactPagination";
import { useTranslation } from "react-i18next";
import { showSingleToastSuccess, showToastError, showToastSuccess } from "../../../utils/helpers";
import { CircularProgress } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Loader from "../../../commonComponents/Loader";
import { useNavigate } from "react-router-dom";
import { getImageUrl } from "../../../utils/helpers";
const AllFriends = ({ searchBy, countsOfFriends,callParentCountMethod }) => {
    const [listOfFriends, setListOfFriends] = useState([]);
    let defaultUserImage = '/Assets/Img.png';
    const { t } = useTranslation();
    const [totalPage, setTotalPage] = useState(0);
    const limit = 12;
    const [noReqMsg, setNoReqMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPageNo,setCurrentPageNo]=useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        getAllfriendsList(0);
    }, [searchBy])

    const getAllfriendsList = async (currentPage) => {
        setCurrentPageNo(currentPage);
        if (searchBy) {
            setIsLoading(true);
            let url = `?searchText=${searchBy}&page=${currentPage}&limit=${limit}&findType=friends`;
            const response = await getUsersBySearchRequest(url);
            const user = response?.data?.data?.data;
            const user2 = user.map(a => ({ ...a, isNotFriend: false }));
            setListOfFriends(user2);
            let pageCount = (response?.data?.data?.totalCount) / limit;
            setTotalPage(Math.ceil(pageCount));
            countsOfFriends(response?.data?.data?.totalCount);
            setNoReqMsg((response?.data?.data?.totalCount) > 0 ? false : true);
            setIsLoading(false);

        }
        else {
            setIsLoading(true);
            const response = await getAllFriendsAddedList(`&page=${currentPage}&limit=${limit}`);
            if (response?.data?.data) {
                const user = response?.data?.data?.data;
                const user2 = user.map(a => ({ ...a, isNotFriend: false }));
                setListOfFriends(user2);
                let pageCount = (response?.data?.data?.totalCount) / limit;
                setTotalPage(Math.ceil(pageCount));
                countsOfFriends(response?.data?.data?.totalCount);
                setNoReqMsg((response?.data?.data?.totalCount) > 0 ? false : true);
            }
            setIsLoading(false);
        }
    }
    const handlePageClick = (data) => {
        let page = data?.selected;
        getAllfriendsList(page);
    }
    const unFriend = async (item) => {
        if (item?.status === 'active') {
            const params = {
                action: 'unFriendRequest',
                list: [item?._id]
            }
            await postDeleteOrCancelRequest(params)
                .then((res) => {
                    if (res) {
                        const nextList = [...listOfFriends];
                        let index = nextList?.findIndex(a => a._id === item?._id);
                        // showToastSuccess(t(`apiMessages.FRIEND_REMOVED`));
                        showSingleToastSuccess(t(`apiMessages.FRIEND_REMOVED`));
                        // showSingleToastSucces2(t(`apiMessages.FRIEND_REQUEST_SENT`));
                        if (index >= 0) {
                            nextList[index]['isNotFriend'] = true;
                            setListOfFriends(nextList);
                        }
                        let cntPage=currentPageNo;
                        if(listOfFriends?.length==1){
                           setCurrentPageNo(currentPageNo-1);
                           cntPage=cntPage-1;
                        }
                        getAllfriendsList(cntPage);
                        callParentCountMethod();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    showToastError(t(`errors.Some_Error_Occurred_Please_try_again_later`));

                })
                
        }
        else {
            showToastError(item?.firstName + t('apiMessages.is_not_active'));
        }
    }
    const showUser=(user)=>{
        let encrptedId = window.btoa(user?._id);
        //navigate(`/profile/${encrptedId}`)
        navigate(`/profile/${encrptedId}`, { state: {userId:user?._id}})
    }
    return (
        <Row className="mt-4">
        {listOfFriends.length > 0 ? <>
            {listOfFriends.length > 0 && !isLoading && listOfFriends.map((item, index) =>
                 <Col md={3} key={index} className="mb-4 col-lg-2 col-sm-4">
                                     <div className="widget-box  friends-list-box h-100">
                                         <div className="d-flex flex-column h-100">
                                             <div onClick={() => showUser(item)}>
                                                  <AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile',item?.profilePicture) : defaultUserImage} />
                                                  <p> {(`${item?.firstName} ${item?.lastName}`) || ''}</p>
                                                  <small>{item?.userName? `@${item?.userName}`:''}</small>
                                              </div>
                                              {
                                                  item?.isNotFriend ?
                                                     <button className="button white white-tertiary disabled bg-danger" > <img src="/Assets/Remove Friend-active.png" className='imageInfindFriend' /> {t('friendsMsg.UNFRIEND_USER')} </button>
                                                      : <button className="button white white-tertiary" onClick={() => unFriend(item)}> <img src="/Assets/Remove Friend.png" className='imageInfindFriend' />{t('friendsMsg.UNFRIEND_USER')} </button>
         
                                             }
                                          </div>
                                      </div>
                                 </Col>
            )}
        </>
            : noReqMsg === true ? <h4 className="nopeMsg">{t('friendsMsg.FRIENDS_NOT_ADDED')}</h4> : ''}
             {
                isLoading?
                <Loader/>
                :''
            }
        <ReactPagination pageCount={totalPage} onPageChange={handlePageClick}/>
        
    </Row>
    )

}

export default AllFriends;