import React from "react";
import './roundCheckbox.style.css';

const RoundRadio = ({label , value , name, icon, onChange, other, input, ...props }) => {
    return (<div className="roundCheckbox">
                {other ? <span className='other'>
                    {label} {input}
                </span> : 
                <label>
                    <input type="radio" name={name} value={value} onChange={onChange} {...props}/>
                    <span>{label} {icon}</span>
                </label>
                }
            </div>)
}

export default RoundRadio;