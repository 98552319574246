import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";
import { getUserDetailServices } from "../../../services/auth.services";
import { isValidDate } from "@fullcalendar/react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setLoadNewPosts } from "../../../Reducer/user/user.action";
import { selectLoadNewPosts } from "../../../Reducer/user/user.selector";
export const SidebarSmallMenu = ({ menus, active,toggle,role,handleNavigateFromToggle,handleMarkValidatorOff,isValidatorOff }) => {
    const { t } = useTranslation();
    const dispatch=useDispatch();
    const loadMoreposts = useSelector(selectLoadNewPosts);
    return (<ul className="menu small">
        {menus.length && menus.map((item, index) => {
            let classes = (active === item.link) ? 'menu-item active' : 'menu-item';
            return (
                <li className={classes} key={index}>
                    {item.link === '/voxbox'?
                    <Link className="menu-item-link text-tooltip-tfr" title={item.name} to={item.link} data-title={item.namw} style={{ position: 'relative' }}
                    onClick={()=>dispatch(setLoadNewPosts(!loadMoreposts))}
                    >
                        {item.icon}
                        <div className="xm-tooltip"><p className="xm-tooltip-text">{item.name}</p></div>
                    </Link>
                    :
                    <Link className="menu-item-link text-tooltip-tfr" title={item.name} to={item.link} data-title={item.namw} style={{ position: 'relative' }}>
                        {item.icon}
                        <div className="xm-tooltip"><p className="xm-tooltip-text">{item.name}</p></div>
                    </Link>}
                </li>
            )
        })}
        {toggle && role === 'user' && <li className="menu-item active">
            {!isValidatorOff ?
                <><Switch aria-label='Switch demo' title={t('Turn_Validator_on')} checked={isValidatorOff} onChange={(e) => handleNavigateFromToggle(e)} /></> : <>
                    <Switch aria-label='Switch demo' title={t('Turn_Validator_Off')} checked={isValidatorOff} onChange={(e) =>handleMarkValidatorOff(e)} /> <a onClick={(e) =>handleNavigateFromToggle(e)}></a>
                </>}
        </li>}
    </ul>)
}

export const SidebarExpandedMenu = ({ menus, active, toggle, role,handleNavigateFromToggle,handleMarkValidatorOff,isValidatorOff }) => {
    const dispatch=useDispatch();
    const loadMoreposts = useSelector(selectLoadNewPosts);
    return (<ul className="menu">
        {menus.length && menus.map((item, index) => {
            let classes = (active === item.link) ? 'menu-item active' : 'menu-item';
            return (
                <li className={classes} key={index}>
                    {item.link === '/voxbox'?
                    
                    <Link className="menu-item-link" to={item.link} onClick={()=>dispatch(setLoadNewPosts(!loadMoreposts))}>
                        {item.icon}
                        {item.name}
                    </Link>
                    :
                    <Link className="menu-item-link" to={item.link}>
                        {item.icon}
                        {item.name}
                    </Link>}
                    
                </li>
            )
        })}

        {toggle && role === 'user' && <li className="menu-item active">
            <div className="d-flex align-items-end">
            {!isValidatorOff ?
                <><Switch aria-label='Switch demo' checked={isValidatorOff} onChange={(e) => handleNavigateFromToggle(e)} /> <div className="validatorMode"> {t('validator_mode')} </div></> : <>
                    <Switch aria-label='Switch demo' checked={isValidatorOff} onChange={(e) =>handleMarkValidatorOff(e)} /> <div className="validatorMode"> {t('validator_mode')} </div>
                </>}
            </div>
        </li>}
    </ul>)
}

