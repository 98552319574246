
import { Switch } from '@mui/material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AvatarImgBox from '../commonComponents/AvatarImgBox/avatarImgBox';
import { AvatarImg } from '../commonComponents/Images';
import { userStatusUpdateService } from '../services/adminManage.services';
import { getImageUrl, showSingleToastSuccess } from '../utils/helpers';

function ReportedUserBox({ reportData, deleteUserHandler }) {
    const { t } = useTranslation();

    const [data, setData] = useState(reportData);
    const [viewMore, setViewMore] = useState(false);
    const userStatusChangeHandler = (status) => {
        let params = {
            "userId": data?.reportedUser?._id,
            "status": status ? 'active' : 'deactive'
        }

        userStatusUpdateService(params)
            .then(async (response) => {
                if (response?.data?.status === 200) {
                    let reportedUserData = data;
                    reportedUserData.reportedUser.status = (status) ? 'active' : 'deactive';
                    setData({...reportedUserData});           
                    showSingleToastSuccess((status) ? t('apiMessages.USER_ACTIVE_SUCCESS') : t('apiMessages.USER_INACTIVE_SUCCESS'))
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <>
        <div className="col-md-12 mt-4">
	<div className="manage_box_area">
		<div className="align-items-center row">
			<div className="col-lg-3 col-md-4 col-sm-6">
				<div className="d-flex align-items-center flex-sm-row flex-column">
					<div className="manage_box_pic_area">
                    <AvatarImgBox img={reportData?.reportedUser?.profilePicture !== "" ? getImageUrl(reportData?.reportedUser?._id, 'userProfile', reportData?.reportedUser?.profilePicture) : AvatarImg} />
					</div>
					<div className="mamage_pro_area">{(`${reportData?.reportedUser?.firstName} ${reportData?.reportedUser?.lastName}`) || ''}</div>
				</div>
			</div>

			<div className="col-lg-3 col-md-4 col-sm-6 text-center text-sm-start">
            <p className="bold">{t('vendors.Reported_By')}: {reportData?.reportedBy?.length}</p>
			</div>

			<div className="col-lg-3 col-md-4 col-sm-6 text-lg-end text-center enable_role_margin">
				<span>{'Enable/Disable'}</span> 
				<span>
                    <Switch checked={(data?.reportedUser?.status === 'active') ? true : false} onChange={(e) => userStatusChangeHandler(e.target.checked)} />
                </span>
                <span className="bold">
                               
                            </span>
			</div>

			<div className="text-end manage_r_btn_area col-lg-3 col-md-12 col-sm-6">
				{/* <button className="btn change_role_btn">change Role</button>
				<button className="btn change_emp" onClick={(e)=>deleteUserHandler(e, data?._id)}>{t('button.delete')}</button> */}

                <button className="btn btn-danger" onClick={(e) => deleteUserHandler(e, data?._id)}>{t('button.delete')}</button>
                {!viewMore && <button className="btn change_role_btn viewMoreBtn" onClick={(e) => { e.preventDefault(); setViewMore(true) }}>{t('button.view_more')}</button>}
			</div>
								</div>
							</div>
						</div>

           
            {viewMore && <>
                <div className="mt-2">
                    <div className='reson_main_box'>
                    {reportData.reportedBy.map((item, index) => (
                        <>{!!item?.userId &&
                            <div className="reason_box" key={index}>
                                <div className="avtarImgBlck"><AvatarImgBox
                                    img={item?.userId?.profilePicture !== "" ? getImageUrl(item?.userId?._id, 'userProfile', item?.userId?.profilePicture) : AvatarImg}
                                /></div>
                                <div className='resion_content'>
                                <span className="bold">
                                            {(`${item?.userId?.firstName} ${item?.userId?.lastName}`) || ''}
                                        </span>
                                        <p>{'Reason'}</p>
                                        <p>{item?.reason}</p>
                                        {/* <span className="bold">{(`${data?.reportedBy?.firstName} ${data?.reportedBy?.lastName}`) || ''}</span> */}

                                </div>

                            </div>
                        }
                            {!!item?.organizationId &&
                                <div className="reason_box">
                                    <div className="avtarImgBlck"><AvatarImgBox
                                        img={item?.organizationId?.profilePicture !== "" ? getImageUrl(item?.organizationId?._id, 'userProfile', item?.organizationId?.profilePicture) : AvatarImg}
                                    /></div>
                                    <div className='resion_content'>
                                            <span className="bold">
                                                {(`${item?.organizationId?.name}`) || ''}
                                            </span>
                                            <p>{'Reason'}</p>
                                            <p>{item?.reason}</p>
                                            {/* <span className="bold">{(`${data?.reportedBy?.firstName} ${data?.reportedBy?.lastName}`) || ''}</span> */}


                                    </div>
                                    <div>
                                        
                                    </div>

                                </div>
                            }

                        </>

                    )
                    )
                    }
                    <div className='text-lg-end text-center mx-3'>
                        <button className="btn view-less-btn" onClick={(e) => { e.preventDefault(); setViewMore(false) }}>{t('button.view_less')}</button>
                        </div>
                </div>
                </div>
            </>}

        </>
    )
}

export default ReportedUserBox