import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LogoWhite } from "../../../commonComponents/Images";
import { Col } from "react-bootstrap";
import StepperSidebar from "../../../commonComponents/stepperSidebar";

const DetailsSideBar = ({ activeIndex, handleStepperClick }) => {
  const [menuToggleClass, setMenuToggleClass] = useState('menu-btn-tog');
  const [showSidebarMenu, setShowSidebarMenu] = useState(true);

  useEffect(() => {
    if (showSidebarMenu) { setMenuToggleClass('menu-btn-tog change') }
    else { setMenuToggleClass('menu-btn-tog') }
  }, [showSidebarMenu])

  const { t } = useTranslation();
  const steps = [
    { label: t('onboarding.organizationInfo'), desc: "" },
    { label: t('onboarding.userProfileInfo'), desc: "" },
    { label: t('onboarding.interestSettings'), desc: "" },
    { label: t('onboarding.businessInfo'), desc: "" },
    { label: t('onboarding.contactInfo'), desc: "" }
  ];
  return (
    <>
      <div className={menuToggleClass} onClick={(e) => { setShowSidebarMenu(!showSidebarMenu) }}>
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div>
      <div className="sidebar-logo">
        <Link to="/"> <img src={LogoWhite} alt="" /> </Link>
      </div>
      {showSidebarMenu && <div className="sidebarMenu">
        <StepperSidebar className="detailsStepper" steps={steps} activeIndex={activeIndex} handleStepperClick={handleStepperClick} />
      </div>}
    </>
  );
}

export default DetailsSideBar;