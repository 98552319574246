import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SectionHeader from '../../../commonDashboardComp/sectionHeader';
import ReactPagination from '../../../commonComponents/reactPagination';
import { getAllUserListServices } from '../../../services/adminManage.services';
import { useSelector } from 'react-redux';
import { selectCurrentUserRole } from '../../../Reducer/user/user.selector';
import { useTranslation } from 'react-i18next';
import { loginUserType } from '../../../config/constants';
import BarsLoader from '../../../commonComponents/barsLoader';
import { TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import swal from 'sweetalert';
import ManageUserBox from '../../../commonDashboardComp/manageUserBox';
import { getAllOrgsService, getManageUserOrgList } from '../../../services/onBoarding.services';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { showToastError } from '../../../utils/helpers';
const ManageUsers = ({ }) => {
  const navigate = useNavigate();
  const limit = 50;
  const { t } = useTranslation();
  const role = useSelector(selectCurrentUserRole);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [orgData, setOrgData] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [listMoreOrgs, setListMoreOrgs] = useState(true);
  const [exportData, setExportData] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getOrgList();
  }, [listMoreOrgs])
 const headers = [
    {label:t('form.label_first_name'), key: "firstName" },
    {label:t('form.label_last_name'), key: "lastName" },
    {label:t('button.status'), key: "status" },
    {label:t('form.label_email'),key:"email"},
    {label:t('form.label_gender'),key:'gender'},
    {label:t('DOB'),key:'dob'},
    {label:t('Address'),key:'address'},
    {label:t('form.organization_name'),key:'orgName'},
    {label:t('Organization_Role'),key:'orgRole'},

  
  ];


  const getOrgList = async () => {
    getManageUserOrgList()
      .then((data) => {
        if (data?.data?.status === 200) {
          let OrgList = [];
          data?.data?.data?.data.map((item) => {
            OrgList.push(item?.name);
          })
          setOrgData(OrgList);
          setOrgList(data?.data?.data?.data )
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    setLoading(true);
    getUserList();
  }, [page, searchText])

  const handlePageClick = (data) => {
    let Page = data.selected
    setPage(Page)
  }

  const getUserList = async () => {
    try {
      if (role === loginUserType.SUPER_ADMIN) {
        const response = await getAllUserListServices(page, limit, searchText);
        setLoading(false);
        if (response?.data) {
          setData(response.data?.data);
          let pageCount = (response?.data?.totalCount) / (limit);
          setTotalPage(Math.ceil(pageCount));
          if (response?.status == 200) {
            let resp = await getAllUserListServices(0, response?.data?.totalCount, searchText);
            if (resp?.status === 200) {
              let newList = resp?.data?.data?.map((a, index) => {
                
                let obj={ firstName: a?.firstName, lastName: a?.lastName, status: a?.status,gender:a?.gender ,email:a?.email}
                if(a?.dob){
                   let selectedDate = moment(a?.dob);
                   let currentDate = selectedDate.format('YYYY-MM-DD');
                   obj['dob']=currentDate;
                }
                if(a?.permanentAddress?.country ||a?.permanentAddress?.country ){
                  obj['address']=a?.permanentAddress?.country+"("+a?.permanentAddress?.city+")";
                }
                if(a?.pages){
                  if(a?.pages[0]?.organizationId?.name || a?.pages[0]?.role){
                  obj['orgName']=a?.pages[0]?.organizationId?.name;
                  obj['orgRole']=t(`roles.${a?.pages[0]?.role}`);
                  }
                }
                return obj;
              })
              setExportData(newList);

            }

          }
          setTotalCount(response?.data?.totalCount);
        }else{
          showToastError(t(`apiMessages.Some_Error_Occured_Please_try_again_later`));
        }

      }
    } catch (error) {
      setLoading(false);
    }
  }

  const renderEmptyMessage = () => {
    return (
      <>
        {data?.length === 0 && !isLoading &&
          <>
            <Row className="notification">
              <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center">
                <span className="bold">
                  {t('no_users')}
                </span>
              </Col>
            </Row>
          </>}
      </>
    )
  }

  const removeOrgFromOtherList = (orgId) => {
    let allData = data;
    let update = '';
    data?.map((item, index) => {
      if (item?.pages?.length > 0) {
        item?.pages?.map((item1) => {
          if (item1?.organizationId?._id === orgId) {
            update = index; return;
          }
        })
        if (update !== '') { return; }
      }
    })
    if (update !== '') {
      allData[update]['pages'] = [];
    }
    setData(allData);
  }

 


  return (
    <>
      <SectionHeader title={t('superAdmin.manage_user_heading')} />

      <div className="section-filters-bar v6">
        <div className="section-filters-bar-actions">
          <form className="form manageUsersSearch eventSearch">
            <div className="form-item split">
              <TextField type='text' label={t('Search_Users')}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <button className="button primary"
                onClick={(e) => e.preventDefault()}
              >
                <SearchOutlinedIcon className="icon-magnifying-glass" />
              </button>
            </div>
          </form>
        </div>
        <div className="section-filters-bar-actions">
          <button
            type='button'
            className="me-3 exportBtn popup-event-creation-trigger text-white"
            to="#"
            onClick={()=>{
              navigate('/manage-invites')
            }}>
            Manage Invites
          </button>
          {
            exportData?.length > 0 && <button type='button' className="exportBtn popup-event-creation-trigger" to="#" >
            <CSVLink data={exportData} headers={headers} filename='User-List'> {t('export_all')}</CSVLink>
          </button>
          }
        </div>
        
      </div>

      <Row className='grid manageUserListBox'>
        <Col md={12} className="">
          <div className='row'>
            {isLoading ? <BarsLoader /> : <>
              {data.map((item, index) => {
                return (
                  <ManageUserBox
                    key={index}
                    userData={item}
                    orgList={orgList}
                    orgData={orgData}
                    setListMoreOrgs={setListMoreOrgs}
                    listMoreOrgs={listMoreOrgs}
                    removeOrgFromOtherList={removeOrgFromOtherList}
                    loadUsers={getUserList}
                  />
                )
              })}
            </>}
          </div>

          <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />

        </Col>
      </Row>

      {renderEmptyMessage()}
    </>
  )

}
export default ManageUsers;