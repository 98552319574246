import React from "react";
import { useTranslation } from "react-i18next";
import Popup from "../../commonComponents/popup";
import RoundRadio from "../../commonComponents/roundCheckbox/roundRadio";
import { Row, Col } from "react-bootstrap";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { CalendarMonthIcon } from "../../commonComponents/Images";
import {Box,TextField,  
    Select, 
    MenuItem, 
    InputLabel,
    FormControl} from '@mui/material';
import { changeDateFormatmmddyy, changeDateFormatddmmyyyy, getWeekDay } from "../../utils/helpers";
import { weekDaysList, recurringTypeList, recurringMonthlyTypeList } from "../../config/constants";
import { fromToDateValidator } from "../../utils/formatValidator";
import { checkEventFrequencyAvailable } from "../../utils/helpers";


const RecurringEventPopup = ({recurringPopupOpen,
    handleRecurringPopupClose,
    eventData,
    dates,
    setDates,
    activeTarget,
    error,
    validatorError,
    repeatEvery,
    setRepeatEvery,
    formValues,
    setFormValues,
    eventdate,
    setEventDate,
    weekday,
    setWeekDay,
    checkEventDateRange,
    categoryVal,
    onDay,
    setOnDay,
    fetchEventDatesByDateAndFrequency}) => {
    const {t} = useTranslation();
    const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };

    return (<Popup 
        handleClose={handleRecurringPopupClose} 
        modalHeadingClass="eventRecurringPopupHeading"
        modalBodyClasses="eventRecurringPopup" 
        modalContentClasses="eventRecurringPopupContent" 
        open={recurringPopupOpen}
        heading={t('set_recurrence')}
    >
        <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        className="userdetail-form addEve_form_mobil"
                        >
                    <div className="container-fluid">
                    <Row>            
                        
                        <Col lg={12} className="col-12 col-md-12 mb-3 text-start datefromto newRecurringEvents set_recurrence93">
                        <label className="mb-3">{t('superAdmin.Event_Start_Date')}</label>
                            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                //label={t('superAdmin.Event_Start_Date')}
                                fullWidth
                                closeOnSelect={true}
                                label={t('superAdmin.Start_Date')}
                                disabled={eventData?.isPaid}
                                value={dates.startDate}
                                minDate={(activeTarget?.fromDate && activeTarget?.fromDate!=='' && (new Date() < new Date(activeTarget?.fromDate)))?new Date(activeTarget?.fromDate).toLocaleDateString('en-US', dateOptions):new Date()}
                                maxDate={(activeTarget?.toDate && activeTarget?.toDate!=='')?new Date(activeTarget?.toDate).toLocaleDateString('en-US', dateOptions):""}
                                onChange={(newDate) => {
                                    setDates({...dates, startDate:newDate});
                                }}
                                InputProps={{endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{cursor:'pointer'}}/> }}
                                renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY"/>}
                                inputFormat="DD MMM YYYY"
                                />
                            </LocalizationProvider>

                            {((error || validatorError) && dates.startDate === '')?
                                    <div className="error-text">{t('superAdmin.Please_select_event_start_date')} </div> : ''}
                            
                        </Col>

                        <Col lg={12} className="col-12 text-start datefromto newRecurringEvents col-md-12">
                            <label className="mb-3">{t('superAdmin.repeat_every')}</label>
                            <div className="container-fluid px-0">
                            <Row>
                                <Col lg={6} className="col-12 col-md-6 mb-3">
                                    <TextField 
                                        disabled={formValues.recurringType === 'daily'? true:false}
                                        type="number"
                                        value={repeatEvery}
                                        onChange={e => setRepeatEvery((e.target.value > 0)?e.target.value:1)}
                                    />
                                </Col>

                                <Col lg={6} className="col-12 col-md-6 mb-3 recurring_type94">
                                    <FormControl fullWidth>
                                        <InputLabel id="target-type">{t('Recurring_Type')}</InputLabel>
                                        {checkEventFrequencyAvailable(categoryVal) === 'monthly'?
                                                <>
                                                    <Select
                                                        // MenuProps={{ disableScrollLock: true }}
                                                        labelId="target-type"
                                                        value={'monthly'}
                                                        onChange={e => {
                                                            setFormValues(prev => ({...prev, recurringType:e.target.value}))
                                                            
                                                        }}
                                                        label={t('superAdmin.Target_Type')}
                                                    >
                                                        {recurringMonthlyTypeList.map((item, idx) => {
                                                            return (
                                                                <MenuItem key={`recurringMonthlyTypeList`+idx} value={item.value}>{item.name}</MenuItem>
                                                            )
                                                        })}
                                        
                                                    </Select>
                                                    
                                                </>
                                                :
                                                <>
                                                    <Select
                                                        // MenuProps={{ disableScrollLock: true }}
                                                        labelId="target-type"
                                                        value={formValues.recurringType}
                                                        onChange={e => {
                                                            setFormValues(prev => ({...prev, recurringType:e.target.value}))
                                                            
                                                        }}
                                                        label={t('superAdmin.Target_Type')}
                                                    >
                                                        {recurringTypeList.map((item, idx) => {
                                                                    return (
                                                                        <MenuItem key={`recurringTypeList`+idx} value={item.value}>{item.name}</MenuItem>
                                                                    )
                                                                })}
                                        
                                                    </Select>
                                                </>
                                            }
                                        
                                    </FormControl>

                                    {error && formValues.frequency !== '' && formValues.recurringType ==='' && 
                                        <div className="error-text">{t('superAdmin.Please_enter_event_recurring_type')} </div>
                                    }
                                </Col>
                            </Row>
                            </div>
                            {((error || validatorError) && (dates.startDate === '' || dates.startDate === 'Invalid Date'))?
                                    <div className="error-text">{t('superAdmin.Please_select_event_start_date')} </div> : ''}
                            
                        </Col>
                            {formValues?.recurringType === 'weekly' &&
                                <Col lg={12} className="col-12 sample text-start datefromto newRecurringEvents col-md-12 newTargetTag mb-3">
                                    {weekDaysList.length >0 && weekDaysList.map((item, index) => 
                                        <RoundRadio name="weekDay" 
                                            key={`weekDaysList`+index} 
                                            label={item.label} 
                                            value={item.value}
                                            checked={(weekday===item.value) ? true : false}
                                            onChange={(e)=>(e.target.checked) ? setWeekDay(item.value) : ''} 
                                        />
                                    )}
                                </Col>
                            }
                            {formValues?.recurringType === 'monthly' &&
                            <Col lg={12} className="col-12 sample text-start datefromto newRecurringEvents col-md-12 newTargetTag mb-3">
                                <Row>
                                    <Col lg={6} className="datefromto newEvents col-md-12 newTargetTag">
                                        <label className="mb-3"> On Day </label>
                                        <TextField
                                            id="outlined-number"
                                            type="number"
                                            value={eventdate}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{ inputProps: { 
                                                min: 1, max: 31 } 
                                            }}
                                            onChange={e => setEventDate((e.target.value > 0)?e.target.value:"")}
                                        />
                                    
                                        {/* <FormControl fullWidth>
                                            <InputLabel id="target-type"></InputLabel>
                                            <Select
                                                MenuProps={{ disableScrollLock: true }}
                                                labelId="target-type"
                                                value={eventdate}
                                                onChange={e => {
                                                    setEventDate(e.target.value)    
                                                }}
                                            >
                                                {[...Array(31)].map((item, idx) => {
                                                    return (
                                                        <MenuItem key={`arr`+idx} value={idx+1}>{idx+1}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl> */}
                                    </Col>
                                </Row>
                            </Col>
                            }
                        <Col lg={12} className="col-12 text-start datefromto newRecurringEvents col-md-12 set_recurrence93">
                        <label className="mb-3">{t('superAdmin.Event_End_Date')}</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                closeOnSelect={true}
                                label={t('superAdmin.Event_End_Date')}
                                value={dates.endDate}
                                minDate={dates.startDate!==''?dates.startDate:new Date()}
                                maxDate={(activeTarget?.toDate && activeTarget?.toDate!=='')?new Date(activeTarget?.toDate).toLocaleDateString('en-US', dateOptions):""}
                                onChange={(newDob) => {
                                    setDates({...dates, endDate:newDob});
                                }}
                                InputProps={{endAdornment:<img src={CalendarMonthIcon} className="calender_icons" style={{cursor:'pointer'}}/> }}
                                renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                                inputFormat="DD MMM YYYY"
                                />
                            </LocalizationProvider>
                            {((error || validatorError)  && (dates.endDate === '' || dates.endDate === 'Invalid Date'))?
                                <div className="error-text">{t('superAdmin.Please_select_event_end_date')} </div> : ''}

                            {(dates.startDate!=='' && dates.endDate!=='')?
                                !fromToDateValidator(dates.startDate, dates.endDate)?
                                <div className="error-text">{t('superAdmin.select_correct_start_and_end_date')}</div>:
                                (activeTarget?.fromDate && activeTarget?.toDate)?
                                !checkEventDateRange(dates.startDate, dates.endDate, activeTarget?.fromDate , activeTarget?.toDate)?
                                <div className="grey-text">{t('superAdmin.select_start_and_end_date_between')} {changeDateFormatddmmyyyy(activeTarget.fromDate)} - {changeDateFormatddmmyyyy(activeTarget.toDate)}</div>
                                :""
                                :""
                                :""
                            }
                        </Col>
                    </Row>
                    </div>
                    {fetchEventDatesByDateAndFrequency().length === 0 ?
                        <div className="error-text select_frequency96">
                            {t('superAdmin.Please_select_frequency')}
                        </div>
                        :
                        <div className="grey-text">
                            
                            {formValues?.recurringType === 'daily' && repeatEvery === 1 &&
                                <>{t(`event.occursEveryDay`).replace('${day}', '').replace('${startDate}', `${changeDateFormatmmddyy(new Date(dates.startDate))}`).replace('${endDate}',`${changeDateFormatmmddyy(new Date(dates.endDate))}`)}</>
                            }
                            {formValues?.recurringType === 'daily' && repeatEvery !== 1 &&
                                <>{t(`event.occursEveryDay`).replace('${day}', `${repeatEvery}`).replace('${startDate}', `${changeDateFormatmmddyy(new Date(dates.startDate))}`).replace('${endDate}',`${changeDateFormatmmddyy(new Date(dates.endDate))}`)}</>
                            }
                            {formValues?.recurringType === 'weekly' && repeatEvery === 1 &&
                                <>{t(`event.occursEveryWeek`).replace('${day}', '').replace('${weekDay}', `${t(getWeekDay(weekday))}`).replace('${startDate}', `${changeDateFormatmmddyy(new Date(dates.startDate))}`).replace('${endDate}',`${changeDateFormatmmddyy(new Date(dates.endDate))}`)}</>
                            }
                            {formValues?.recurringType === 'weekly' && repeatEvery !== 1 &&
                                <>{t(`event.occursEveryWeek`).replace('${day}', `${repeatEvery}`).replace('${weekDay}', `${t(getWeekDay(weekday))}`).replace('${startDate}', `${changeDateFormatmmddyy(new Date(dates.startDate))}`).replace('${endDate}',`${changeDateFormatmmddyy(new Date(dates.endDate))}`)}</>
                            }                                                
                            {formValues?.recurringType === 'monthly' && repeatEvery === 1 &&
                                <>{t(`event.occursEveryMonth`).replace('${day}', '').replace('${date}', `${eventdate}`).replace('${startDate}', `${changeDateFormatmmddyy(new Date(dates.startDate))}`).replace('${endDate}',`${changeDateFormatmmddyy(new Date(dates.endDate))}`)}</>
                            }
                            {formValues?.recurringType === 'monthly' && repeatEvery !== 1 &&
                                <>{t(`event.occursEveryMonth`).replace('${day}', `${repeatEvery}`).replace('${date}', `${eventdate}`).replace('${startDate}', `${changeDateFormatmmddyy(new Date(dates.startDate))}`).replace('${endDate}',`${changeDateFormatmmddyy(new Date(dates.endDate))}`)}</>
                            }
                            
                        </div>
                    }
                    
                </Box>   
                <button className="button primary inviteOkBtn" onClick={handleRecurringPopupClose}> {t('proceed')} </button> 
    </Popup>)
}

export default RecurringEventPopup