import { Box } from "@mui/material"
import { Col, Row } from "react-bootstrap"

const PostPreview = ({data, deleteMetaData, disabled=true, link}) => {

    return data && (
            <Box>
              <a href={link}>
                <Row className="postUrlPreview" >
                  <Col className="col-lg-4 p-0">
                    {data?.images?.length > 0 && (
                      <div className="postPreviewImage">
                        <img className="img-fluid" src={data?.images[0]} alt="Link Preview" />
                      </div> 
                    )}
                  </Col>
                  {disabled &&<button className="deleteImgBtn" onClick={(e) => deleteMetaData()}>&times;</button>}

                  <Col className="col-lg-8 postPreviewContent">
                        <div className="">
                            <h5>{data?.title}</h5>
                            <p>{data?.description}</p>
                        </div>
                  </Col>
                </Row>
              </a>
            </Box>
        )
}

export default PostPreview