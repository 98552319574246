import { USER_ACTION_TYPES } from "./user.types";
const INITIAL_STATE = {
  currentUser: null,
  role: null,
  loadNew: false,
  error: null,
  currentOrg: null,
  currentOrgRole: null,
  searchText: null,
  validatorChange : false,
  userWorkingOrg : {},
  notificationCount : 0,
  canSwitch : true,
  switchOrgPanel: false,
  switchUserPanel : false
}

export const userReducter = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTION_TYPES.SET_CURRENT_USER:
      return { ...state, currentUser: payload };
    case USER_ACTION_TYPES.SET_CURRENT_ROLE:
      return { ...state, role: payload };
    case USER_ACTION_TYPES.SET_LOAD_NEW_POSTS:
      return { ...state, loadNew: payload };
    case USER_ACTION_TYPES.SET_CURRENT_ORG:
      return { ...state, currentOrg: payload };
    case USER_ACTION_TYPES.SET_CURRENT_ORG_ROLE:
      return { ...state, currentOrgRole: payload };
    case USER_ACTION_TYPES.SET_SEARCH_TEXT:
      return { ...state, searchText: payload };
    case USER_ACTION_TYPES.SET_VALIDATOR_CHANGE:
      return { ...state, validatorChange: payload};
    case USER_ACTION_TYPES.SET_USER_WORKING_ORG:
      return { ...state, userWorkingOrg: payload};
    case USER_ACTION_TYPES.SET_NOTIFICATION_COUNT:
      return { ...state, notificationCount: payload};
    case USER_ACTION_TYPES.SET_CAN_SWITCH:
      return { ...state, canSwitch: payload};
    case USER_ACTION_TYPES.SET_SWITCH_USER_PANEL:
      return { ...state, switchUserPanel: payload};
    case USER_ACTION_TYPES.SET_SWITCH_ORG_PANEL:
      return { ...state, switchOrgPanel: payload};
    default:
      return state;
  }
}
