import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { AvatarImg } from '../commonComponents/Images';
import { selectCurrentUser, selectCurrentUserRole, selectCurrentOrg } from "../Reducer/user/user.selector";
import { useSelector } from "react-redux";
import EventsHeader from "./eventsHeader";
import UserDetails from "../components/user-org-details/UserDetails";
import { getUserDetailServices } from "../services/auth.services";
import { getImageUrl } from "../utils/helpers";
import { getObjectFromStore } from "../storage/Storage";
import ShowImagePopUp from "./showImagePopUp";
import CoverImagePopUp from "./coverImagePopUp";

const ProfileHeader = ({ buttons, showEventHeader, eventDetail, imageContainer = true, detail, cover, widgetBoxClasses, profileHeaderClasses }) => {
  //const current_user = useSelector(selectCurrentUser);
  const role = useSelector(selectCurrentUserRole);
  const CURRENTUSER = useSelector(selectCurrentUser);
  const CURRENTORG = useSelector(selectCurrentOrg);
  const [photoOpen, setPhotoOpen] = useState(false);
  const handlePhotoOpen = () => setPhotoOpen(true);
  const handlePhotoClose = () => setPhotoOpen(false);
  const [imageGetData, setImageGetdata] = useState(null);
  const orgPic = '/Assets/org1@2x.png';
  const userPic = '/Assets/Img@2x.png';
  let current_user = {};
  if (role === 'organization') { current_user = CURRENTORG }
  else if (detail?.pageRoles?.role === 'admin') { current_user = { ...detail } }
  else if (detail?.name) { current_user = { ...detail } }
  else { current_user = CURRENTUSER }

  const getCoverImage = () => {
    let coverPic = cover ? cover : detail ? (detail?.coverPicture ? getImageUrl(detail?._id, 'userCover', detail?.coverPicture) : '/Assets/Cover Image.png') : current_user?.coverPicture ? getImageUrl(current_user?._id, 'userCover', current_user?.coverPicture) : '/Assets/Cover Image.png'
    return coverPic;
  }

  const getUserImage = () => {
    let defPic = (detail) ? detail?.role === 'user' ? userPic : orgPic : (role === 'organization') ? orgPic : AvatarImg;
    //(detail && detail?.name || current_user && current_user?.name)?orgPic:AvatarImg;

    return detail ?
      detail?.profilePicture ?
        getImageUrl(detail?._id, 'userProfile', detail?.profilePicture)
        : defPic
      : current_user?.profilePicture ?
        getImageUrl(current_user?._id, 'userProfile', current_user?.profilePicture) : defPic
  }

  const getUserFullName = () => {
    let name = '';
    name = detail && detail?.name ?
      detail?.name :
      detail && (detail?.firstName && detail?.lastName) ?
        detail?.middleName ?
          detail?.firstName + " " + detail?.middleName + " " + detail?.lastName :
          detail?.firstName + " " + detail?.lastName :
        current_user && current_user?.name ?
          current_user?.name :
          current_user && (current_user?.firstName && current_user?.lastName) ?
            current_user?.middleName ?
              current_user?.firstName + " " + current_user?.middleName + " " + current_user?.lastName :
              current_user?.firstName + " " + current_user?.lastName
            : "";
    return name;
  }
  const [showRole, setShowRole] = useState(detail ? 'otheruser' : role)
  const [coverImg, setCoverImg] = useState(getCoverImage());
  const [userImg, setUserImg] = useState(getUserImage());
  const [userFullNameIS, setUserFullNameIS] = useState(getUserFullName())
  useEffect(() => {
    setCoverImg(getCoverImage());
    setUserImg(getUserImage());
    //setShowRole(detail?'user':role);
    setShowRole(detail?.name ? 'organization' : detail?.firstName ? 'user' : role)
    setUserFullNameIS(getUserFullName());
  }, [detail, current_user, role])

  const [isValidatorOff, setIsValidatorOff] = useState(false);
  const [loggedInUserDet, setLoggedInUserDet] = useState();

  useEffect(() => {
    getLoginUserDetails(loggedInUserDet);
  }, [])

  const getLoginUserDetails = async () => {
    if (getObjectFromStore('token') !== null) {
      if (role !== 'organization') {
        const response = await getUserDetailServices();
        if (response?.data?.data) {
          const user = response?.data?.data;
          setIsValidatorOff(response?.data?.data?.isValidator);
          setLoggedInUserDet(user);
        }
      }
    }
  }
  const openPhotoHandler = (type) => {
    // let imageObj = { id: detail?detail?._id:current_user?.Id, type: 'userCover',param: detail? detail?.profilePicture:current_user?.profilePicture,contentType:'image'};

    if (type === 'cover')
      setImageGetdata(coverImg)
    else setImageGetdata(userImg)
    handlePhotoOpen();


  }

  return (<div className={`profile-header ${profileHeaderClasses}`}>
    <figure className="profile-header-cover liquid" onClick={() => { openPhotoHandler('cover') }}>
      {coverImg ? <img src={coverImg} alt="cover-01" /> : <img src="/Assets/Cover Image.png" alt="cover-01" />}
    </figure>

    {imageContainer &&
      <div className="profile-header-info">
        <div className="user-short-description big profileUserDesc" onClick={() => { openPhotoHandler('user') }}>
          {(showRole === 'organization' || showRole === 'superAdmin') ?
            <div className="orgImg">
              <img src={userImg} alt="" className="" />
            </div>
            :
            <div className="user-short-description-avatar user-avatar profileAvatar">
              {/* <div className="user-avatar-border">
                    <div className="hexagon-148-164" style={{ width: '148px', height: '164px', position: 'relative' }}></div>
                  </div> */}
              <div className="user-avatar-content" >
                <div className="user-avatar-content online-sign">
                  <div className="poligon-white big_poligon_white">
                    <div className="avatar_bg big_avatar_bg" style={{ '--background': 'url(' + userImg + ')  center/cover' }} >
                    </div>
                  </div>
                </div>
              </div>
              {(isValidatorOff && showRole === 'user' && !detail) ? <img className="validatorOff"
                src={"/Assets/validator.png"} alt=""
              /> : null
              }
            </div>
          }
          {(current_user !== null || getObjectFromStore('token') === null) &&
            <>
              <p className="profileTitle">
                {userFullNameIS}
              </p>


            </>
          }

        </div>
      </div>}
    {buttons ? buttons : ""}
    {
      showEventHeader && <EventsHeader eventDetail={eventDetail} widgetBoxClasses={widgetBoxClasses} />
    }
    <CoverImagePopUp
      imageShow={imageGetData}
      open={photoOpen}
      handleClose={handlePhotoClose}

    />
  </div>);
}

export default ProfileHeader;