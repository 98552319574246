import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AvatarImgBox from "../commonComponents/AvatarImgBox/avatarImgBox";
import { AvatarImg } from "../commonComponents/Images";
import BarsLoader from "../commonComponents/barsLoader";
import { getImageUrl } from "../utils/helpers";
import ReactPagination from "../commonComponents/reactPagination";
const BlockedUser = ({ data, onAction, isLoading , totalPage, handlePageClick, currentPage}) => {
    const { t } = useTranslation();

    return (

        <Row className="grid">
            <Col md={12} className=" grid-column">
                {isLoading ?
                    <BarsLoader /> : data.length > 0 ?
                        <>
                            {data.map((e, i) => (
                                <div className="widget-box area-widget blocked_user_area" key={i}>
                                    <div className="">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center gray_plate">
                                                <div className="hexagon-image-30-32">
                                                    <AvatarImgBox 
                                                    img={(e?.profilePicture !== '') ? getImageUrl(e?._id, 'userProfile', e?.profilePicture) : AvatarImg}
                                                    />
                                                </div>
                                                <div className="">
                                                    <h5 className="bold">{`${e?.firstName} ${e?.lastName}`}</h5>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="action-request-list justify-content-end">
                                                    <button onClick={() => onAction(e._id)} className="button unblock_btn">{t('button.unblock')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </>
                        :
                        <>
                            {data.length === 0 && <div>
                                <h3 style={{ textAlign: 'center' }}>
                                    {t('no_blocked_users')}
                                </h3>
                            </div>}
                        </>
                }

            </Col>

            <ReactPagination
             pageCount={totalPage} onPageChange={handlePageClick}
            />
        </Row>)
}

export default BlockedUser