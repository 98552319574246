import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { AvatarImg } from "../../../commonComponents/Images";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import ReactPagination from "../../../commonComponents/reactPagination";
import { useTranslation } from "react-i18next";
import { getUsersAllRequestReceived, getUsersBySearchRequest, postDeleteOrCancelRequest } from "../../../services/friends.service";
import { showSingleToastSuccess, showToastError, showToastSuccess } from "../../../utils/helpers";
import Loader from "../../../commonComponents/Loader";
import { useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from "@mui/material";
import { getImageUrl } from "../../../utils/helpers";
const FriendReqReceived = ({ searchBy, countsOfFriends,callParentCountMethod }) => {
    const { t } = useTranslation();
    let defaultUserImage = '/Assets/Img.png';
    const [listofAllrequesRec, setListofAllrequesRec] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [noReqMsg, setNoReqMsg] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPageNo,setCurrentPageNo]=useState(0);
    const navigate = useNavigate();
    const limit = 12;
    useEffect(() => {
        getFriendReqList(0);
    }, [searchBy])
    const getFriendReqList = async (page) => {
        setCurrentPageNo(page);
        if (searchBy) {
            setIsLoading(true);

            let url = `?searchText=${searchBy}&page=${page}&limit=${limit}&findType=receivedRequests`;
            const response = await getUsersBySearchRequest(url);
            const user = response?.data?.data?.data;
            const user2 = user.map(a => ({ ...a, acceptedReq: false, rejectedReq: false }));
            setListofAllrequesRec(user2);
            let pageCount = (response?.data?.data?.totalCount) / limit;
            setTotalPage(Math.ceil(pageCount));
            countsOfFriends(response?.data?.data?.totalCount);
            setNoReqMsg((response?.data?.data?.totalCount) === 0 ? true : false);
            setIsLoading(false);

        }
        else {
            setIsLoading(true);
            const response = await getUsersAllRequestReceived(`&page=${page}&limit=${limit}`);
            if (response?.data?.data) {
                const user = response?.data?.data?.data;
                setListofAllrequesRec(user);
                let pageCount = (response?.data?.data?.totalCount) / limit;
                setTotalPage(Math.ceil(pageCount));
                countsOfFriends(response?.data?.data?.totalCount);
                setNoReqMsg((response?.data?.data?.totalCount) === 0 ? true : false);
            }
            setIsLoading(false);

        }

    }
    const handlePageClick = (data) => {
        let page = data?.selected;
        getFriendReqList(page);

    }
    const acceptFriendRequest = async (item) => {
        if (item?.status === 'active') {
            const params = {
                action: 'acceptRequest',
                list: [item?._id]
            }
            await postDeleteOrCancelRequest(params)
                .then((res) => {
                    if (res) {
                        const nextList = [...listofAllrequesRec];
                        // showToastSuccess(t(`apiMessages.FRIEND_REQUEST_ACCEPTED`));
                        showSingleToastSuccess(t(`apiMessages.FRIEND_REQUEST_ACCEPTED`));
                        // showSingleToastSucces2(t(`apiMessages.FRIEND_REQUEST_SENT`));
                        let index = nextList?.findIndex(a => a._id === item?._id);
                        if (index >= 0) {
                            nextList[index]['acceptedReq'] = true;
                            setListofAllrequesRec(nextList);
                        }
                        let cntPage=currentPageNo;
                        if(listofAllrequesRec?.length==1){
                           setCurrentPageNo(currentPageNo-1);
                           cntPage=cntPage-1;
                        }
                        getFriendReqList(cntPage);
                        callParentCountMethod();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    showToastError(t(`errors.Some_Error_Occurred_Please_try_again_later`));
                });
        }
        else {
            showToastError(item?.firstName + t('apiMessages.is_not_active'));
        }

    }
    const deleteFriendRequest = async (item) => {
        if (item?.status === 'active') {
            const params = {
                action: 'deleteRequest',
                list: [item?._id]
            }
            await postDeleteOrCancelRequest(params)
                .then((res) => {
                    if (res) {
                        // showToastSuccess(t(`apiMessages.FRIEND_REQUEST_DELETED`));
                        showSingleToastSuccess(t(`apiMessages.FRIEND_REQUEST_DELETED`));
                        const nextList = [...listofAllrequesRec];
                        let index = nextList?.findIndex(a => a._id === item?._id);
                        if (index >= 0) {
                            nextList[index]['rejectedReq'] = true;
                            setListofAllrequesRec(nextList);
                        }
                        let cntPage=currentPageNo;
                        if(listofAllrequesRec?.length==1){
                           setCurrentPageNo(currentPageNo-1);
                           cntPage=cntPage-1;
                        }
                        getFriendReqList(cntPage);
                        callParentCountMethod();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    showToastError(t(`errors.Some_Error_Occurred_Please_try_again_later`));
                });
        }
        else {
            showToastError(item?.firstName + t('apiMessages.is_not_active'));
        }

    }
    const showUser = (user) => {
        let encrptedId = window.btoa(user?._id);
        //navigate(`/profile/${encrptedId}`)
        navigate(`/profile/${encrptedId}`, { state: {userId:user?._id}})
    }
    return (

        <Row className="mt-4">
            {listofAllrequesRec.length > 0 ? <>
                {listofAllrequesRec.length > 0 && !isLoading && listofAllrequesRec.map((item, index) =>
                    <Col md={3} key={index} className="mb-4 col-lg-2 col-sm-4">
                        <div className="widget-box  friends-list-box h-100">
                            <div className="d-flex flex-column h-100">
                                <div onClick={() => showUser(item)}>
                                    <AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile',item?.profilePicture) : defaultUserImage} />
                                    <p> {(`${item?.firstName} ${item?.lastName}`) || ''}</p>
                                    <small>{item?.userName? `@${item?.userName}`:''}</small>
                                </div>
                                <div className="FRR-Control-Block">
                                    {(item?.acceptedReq || item?.rejectedReq) ? <button className="box-btn" disabled> <img src="/Assets/Add-Friend-Icon-inactive.png" /></button> : <button className="box-btn"  title={t('button.Accept_Request')} onClick={() => acceptFriendRequest(item)}> <img src="/Assets/Add-Friend-Icon-inactive.png" /></button>}
                                    {(item?.acceptedReq || item?.rejectedReq) ? <button className="box-btn" disabled> <img src="/Assets/Remove Friend.png" /></button> : <button className="box-btn" title={t('button.Delete_Request')} onClick={() => deleteFriendRequest(item)}> <img src="/Assets/Remove Friend.png" /></button>}
                                </div>
                            </div>
                        </div>
                    </Col>
                )}
            </>
                : noReqMsg === true ? <h4 className="nopeMsg">{t('friendsMsg.REQUEST_NOT_RECEIVED')}</h4> : ''}
            {
                isLoading?
                <Loader/>
                :''
            }
            <ReactPagination pageCount={totalPage} onPageChange={handlePageClick}/>
        </Row>
    )
}

export default FriendReqReceived;