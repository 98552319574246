import React from "react";
import { Row, Col } from "react-bootstrap";
import { AvatarImg } from "../../../commonComponents/Images";
import { FormControlLabel, Checkbox } from "@mui/material";
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { empRejectAcceptRemoveRequest, getfollowerList } from "../../../services/follower.service";
import { useState, useRef, useEffect } from "react";
import { getImageUrl, showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import BarsLoader from "../../../commonComponents/barsLoader";
import GreenCheckBox from "../../../commonComponents/greenCheckbox/greenCheckbox";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const EmpReq = ({ searchBy, countsOfFriends, callParentCountMethod }) => {
    const { t } = useTranslation();
    const n = 4;
    const limit = 4;
    const [PageNo, setPageNo] = useState(0);
    const [employeeRequestList, setEmployeeRequest] = useState([]);
    const [lodaing, setLoading] = useState(false);
    const [noReqMsg, setNoReqMsg] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [actionDropDownStatus, setActionDropdownStatus] = useState(true);
    const [allActionListStatus, setallActionListStatus] = useState(false);
    const [valChecked, setValueChecked] = useState(false)
    const [selectedItemList, setSelectedItemList] = useState(0);
    const [dropdownOption, setDropdownOption] = useState(false);
    let defaultUserImage = AvatarImg;
    const navigate = useNavigate();
    const observer = useRef();

    useEffect(() => {
        //setSuggestFriendList(suggestFriendList.splice(0));
        setEmployeeRequest([]);
        setPageNo(0)
    }, [searchBy]);

    useEffect(() => {
        getEmployeeRequest(PageNo);
    }, [searchBy, PageNo])

    useEffect(() => {
        if (selectedItemList > 0) {
            setActionDropdownStatus(false);
        }
        else {
            setActionDropdownStatus(true);
        }
    }, [selectedItemList])

    const lastElement = (node) => {
        if (lodaing) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((enteries) => {
            if (enteries[0].isIntersecting && hasMore) {
                setPageNo((prev) => prev + 1);
            }
        })
        if (node) {
            observer.current.observe(node);
        }
    }

    const getEmployeeRequest = (currentPage) => {
        setLoading(true);
        getfollowerList(currentPage, limit, 'employeeRequestList', searchBy)
            .then((resp) => {
                const user = resp?.data?.data?.data;
                const user2 = user.map(a => ({ ...a, approveIs: false, rejectIs: false, checkedItem: false }));
                let newemployeeList = [...employeeRequestList, ...user2];
                if (PageNo === 0) newemployeeList = [...user2]
                let newArr = newemployeeList?.reduce((acc, curr) => {
                    let fnd = acc.find((a) => a._id === curr?._id);
                    if (!fnd) acc.push(curr);
                    return acc;
                }, []);
                newArr = newArr?.filter(a => !a?.approveIs && !a?.rejectIs);
                if (searchBy) {
                    console.log("")
                }
                setEmployeeRequest(newArr);
                countsOfFriends(resp?.data?.data?.totalCount);
                setLoading(false);
                setHasMore(resp?.data?.data?.data?.length > 0 && user?.length === limit);
                setNoReqMsg((resp?.data?.data?.totalCount) > 0 ? false : true);

            })
    }
    const handleApprove = (e, emp, index) => {
        e.preventDefault();
        if (emp?.status === 'active') {
            let param = {
                action: "approve",
                list: [emp?._id]
            }
            empRejectAcceptRemoveRequest(param)
                .then((resp) => {
                    if (resp?.status == 200) {
                        const nextList = [...employeeRequestList];
                        let index1 = nextList?.findIndex(a => a._id === emp?._id);
                        if (index >= 0) {
                            nextList[index]['approveIs'] = true;
                            setEmployeeRequest(nextList);
                            let pageNo1 = Math.floor(index1 / limit);
                            getEmployeeRequest(pageNo1)
                            employeeRequestList?.splice(index, 1);
                            showSingleToastSuccess(t(`apiMessages.Request_Approved`));
                            callParentCountMethod();

                        }
                    }
                })
                .catch((error) => {
                    showToastError(t(`apiMessages.SOME_THING_WENT_WRONG`))
                })

        }
        else {
            showToastError(emp?.firstName + t(`apiMessages.is_not_active`));
        }

    }
    const handleReject = (e, emp, index) => {
        e.preventDefault();
        if (emp?.status === 'active') {
            let param = {
                action: "reject",
                list: [emp?._id]
            }
            empRejectAcceptRemoveRequest(param)
                .then((resp) => {
                    if (resp?.status == 200) {
                        const nextList = [...employeeRequestList];
                        let index1 = nextList?.findIndex(a => a._id === emp?._id);
                        if (index >= 0) {
                            nextList[index]['approveIs'] = true;
                            setEmployeeRequest(nextList);
                            let pageNo1 = Math.floor(index1 / limit);
                            getEmployeeRequest(pageNo1)
                            employeeRequestList?.splice(index, 1);
                            showSingleToastSuccess(t(`apiMessages.Request_Rejected`));
                            callParentCountMethod();

                        }
                    }
                })
                .catch((error) => {
                    showToastError(t(`apiMessages.SOME_THING_WENT_WRONG`))
                })

        }
        else {
            showToastError(emp?.firstName + t(`apiMessages.is_not_active`));
        }
    }
    const handleCheckBox = (e) => {
        if (e.target.checked) {
            setallActionListStatus(true);
            setSelectedItemList(2);
        }
        else {
            setallActionListStatus(false);
            setSelectedItemList(0);
        }

        setValueChecked(e.target.checked);
        let empReqCopy = [...employeeRequestList];
        empReqCopy = empReqCopy?.map((a => ({ ...a, checkedItem: e.target.checked })));
        setEmployeeRequest(empReqCopy)

    }
    const handleCheck = (requestId, checkedStatus) => {
        setallActionListStatus(false);
        setValueChecked(false);
        let empReqCopy = [...employeeRequestList];
        for (let i = 0; i < empReqCopy.length; i++) {
            if (empReqCopy[i]._id == requestId) {
                empReqCopy[i].checkedItem = checkedStatus;
                if (checkedStatus === true) {
                    setSelectedItemList(selectedItemList + 1);
                }
                else {
                    setSelectedItemList(selectedItemList - 1);
                }
            }
        }

        setEmployeeRequest(empReqCopy);
    }

    const selectTypes = (e) => {
        if (e.target.value) {

            let idsArr = [];
            for (let i = 0; i < employeeRequestList.length; i++) {
                if (employeeRequestList[i].checkedItem === true) {
                    idsArr.push(employeeRequestList[i]._id);
                }
            }
            let param = {
                action: e.target.value,
                list: idsArr
            }
            empRejectAcceptRemoveRequest(param)
                .then((resp) => {
                    if (resp?.status == 200) {
                        let toastMsg = e.target.value === 'approve' ? t(`apiMessages.Approved_Successfully`) : t(`apiMessages.Rejected_Successfully`);
                        showSingleToastSuccess(toastMsg);
                        setPageNo(0)
                        getEmployeeRequest(0);
                        setActionDropdownStatus(true);
                        callParentCountMethod();
                        setDropdownOption(true);
                        setSelectedItemList(selectedItemList - 1);
                    }
                })
                .catch((error) => { console.log(error) })
        }
    }

    const showUser = (user) => {
        let encrptedId = window.btoa(user?._id);
        //navigate(`/profile/${encrptedId}`)
        navigate(`/profile/${encrptedId}`, { state: { userId: user?._id } })
    }
    return (<>
        {employeeRequestList?.length > 0 ? <>
            <div className="mt-20">
                <div className="section-header-info">
                    {/* <p className="section-pretitle"></p> */}
                    <h2 className="section-title">
                        <div className="d-flex align-items-center px-4">
                            <div>
                                <GreenCheckBox label={t('friendsMsg.select_All')} checked={valChecked} onChange={(e) => handleCheckBox(e)} />
                                {/* <FormControlLabel control={<Checkbox checked={valChecked}  />} id='selectAll' label="Select All"  /> */}
                            </div>
                            <div>
                                <select className="action_dropdown" id='ddAction' disabled={actionDropDownStatus} onChange={(e) => selectTypes(e)}>
                                    {
                                        (allActionListStatus === true) ? <>
                                            <option selected={dropdownOption} value=''>{t('friendsMsg.Action')}</option>
                                            <option value='approve'>{t('friendsMsg.Approve_All')}</option>
                                            <option value='reject'>{t('friendsMsg.Reject_All')}</option>
                                        </> :
                                            <>
                                                <option selected={dropdownOption} value=''>{t('friendsMsg.Action')}</option>
                                                <option value='approve'>{t('friendsMsg.Approve_Selected')}</option>
                                                <option value='reject'>{t('friendsMsg.Reject_Selected')}</option>
                                            </>
                                    }

                                </select>
                            </div>
                        </div>

                    </h2>
                </div>
            </div>

            <Row className="grid">

                <Col md={12} className=" grid-column">
                    {employeeRequestList?.length > 0 && employeeRequestList?.map((emp, index) => {
                        if (employeeRequestList?.length === index + 1) {
                            return <div className="empReqBlock widget-box pa-20" key={index} ref={lastElement}>
                                <div className="target_box_area m-none">
                                    <div className="customCheckbox"><GreenCheckBox onChange={(e) => { handleCheck(emp?._id, e.target.checked) }} checked={emp?.checkedItem} /></div>
                                    <div className="avtarImgBlck">
                                        <div onClick={() => {
                                            if (emp.status === "active") showUser(emp)
                                            else showToastError("Account is inactive.")
                                        }}>
                                            <AvatarImgBox img={emp?.profilePicture !== "" ? getImageUrl(emp?._id, 'userProfile', emp?.profilePicture) : defaultUserImage} /></div>
                                    </div>
                                    <div className="fullWidthRef inviteFollower">

                                        <p className="user-status-title">
                                            <span className="bold">{(`${emp?.firstName} ${emp?.lastName}`) || ''}</span>
                                        </p>
                                        <small>{emp?.userName ? `@${emp?.userName}` : ''}</small>
                                    </div>
                                    <div className="inviteBtnBlock">
                                        <div className="action-request-list">
                                            <div className="action-request-list">
                                                <button className="button primary refer-btn" onClick={(e) => handleApprove(e, emp, index)}>{t('button.Approve')}</button>
                                                <button className="button removeBtn reqEmpBtn" style={{ marginLeft: "10px" }} onClick={(e) => handleReject(e, emp, index)}>{t('button.Reject')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        else {
                            return <div className="empReqBlock widget-box pa-20" key={index}>
                                <div className="target_box_area m-none">
                                    <div className="customCheckbox"><GreenCheckBox onChange={(e) => { handleCheck(emp?._id, e.target.checked) }} checked={emp?.checkedItem} /></div>
                                    <div className="avtarImgBlck">
                                        <div onClick={() => {
                                            if (emp.status === "active") showUser(emp)
                                            else showToastError("Account is inactive.")
                                        }}>

                                            <AvatarImgBox img={emp?.profilePicture !== "" ? getImageUrl(emp?._id, 'userProfile', emp?.profilePicture) : defaultUserImage} /></div>
                                    </div>
                                    <div className="fullWidthRef inviteFollower">
                                        <p className="user-status-title">
                                            <span className="bold">{(`${emp?.firstName} ${emp?.lastName}`) || ''}</span>
                                        </p>
                                        <small>{emp?.userName ? `@${emp?.userName}` : ''}</small>
                                    </div>
                                    <div className="inviteBtnBlock">
                                        <div className="action-request-list">
                                            <div className="action-request-list">
                                                <button className="button primary refer-btn" onClick={(e) => handleApprove(e, emp, index)}>{t('button.Approve')}</button>
                                                <button className="button removeBtn reqEmpBtn" style={{ marginLeft: "10px" }} onClick={(e) => handleReject(e, emp, index)}>{t('button.Reject')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    })}
                </Col>

            </Row>
        </> : noReqMsg === true ? <h4 className="nopeMsg">{t('friendsMsg.No_Employee_Request')}</h4> : ''}
        {
            lodaing ?
                <BarsLoader />
                : ''
        }

    </>
    )
}

export default EmpReq;