import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../details.style.css';
import OrganizationInfo from './organizationInfo';
import ProfileInfo from './profileInfo';
import BusinessInfo from './businessInfo';
import ContactInfo from './contactInfo';
import DetailsSideBar from './sidebar';
import { showToastError } from '../../../utils/helpers';
import { useSelector } from 'react-redux';
import { selectCurrentUser, selectCurrentUserRole } from '../../../Reducer/user/user.selector';
import {
    getLegalServiceTypeService,
    getOrgDetailsService,
    updateOrgDetailsService,
    saveNewLegalStructureService,
    saveNewServiceTypeService,
    getOrgInterestsService,
    saveNewOrgInterestsService
} from '../../../services/orgAuth.services';
import { useDispatch } from 'react-redux';
import InterestSettings from '../orgDetails/interestSettings';
import { setCurrentOrg, setCurrentRole } from '../../../Reducer/user/user.action';

const OrganizationDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const role = useSelector(selectCurrentUserRole);
    const [activeIndex, setActiveIndex] = useState(0);
    const [interests, setInterests] = useState([]);
    const [error, setError] = useState(false);
    const [additionalInterest, setAdditionalInterest] = useState('');
    const INITIAL_FORM_VALUES = {
        'base_url': `${window.location.origin}`,

        //Organization Info
        "name": "",
        "taxId": "",
        "webSite": "",
        "regNo": "",
        "aboutMe": "",
        'profilePicture': '',
        'coverPicture': '',
        'userNameCount': 0,

        //UserName and profile URL
        'default_userName': '',
        'userName': '',
        'url': '',

        //Business Info
        'legalStructure': [],
        'serviceType': [],


        //Contact Info
        'user_email': '',
        'user_mobile': '',
        'email': '',
        'mobile': '',
        'isEmailVerify': false,
        'isMobileVerify': false,
        'mailAddress': [],
        'corporateAddress': [],
        //Interests
        'interests': [],
    }

    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);
    const [legalServiceType, setLegalServiceType] = useState([]);
    const [additionServiceType, setAdditionServiceType] = useState('');
    const current_user = useSelector(selectCurrentUser);
    const [orgId, setOrgId] = useState(current_user?.pages[0]?.organizationId);

    useEffect(() => {
        getOrgData();
    }, [orgId])


    useEffect(() => {
        document.body.classList.remove('dashboard');
    }, [])

    useEffect(() => {
        document.body.classList.remove('dashboard');
    }, [])

    const getInterestsLists = async () => {
        getOrgInterestsService(orgId)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setInterests(data?.data?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const getOrgData = () => {
        getOrgDetail();
        getInterestsLists();
        if (role === 'organization') {
            getLegalServiceType();
        }


    }
    const saveNewInterest = (id, value) => {
        let params = {
            "spredeInterestId": id,
            "spredeInterestOptions": value,
            "organizationId": orgId
        }
        // params['organizationId'] = orgId;
        saveNewOrgInterestsService(params)
            .then(async (data) => {
                if (data.status === 200) {
                    await getInterestsLists();
                    //await getUserDetails();
                    setAdditionalInterest(data?.data?.data?.insertedId)
                } else {
                    showToastError(t(`apiMessages.${data?.data?.data}`))
                }
            })
            .catch((error) => {
                showToastError(t(`apiMessages.${error?.data?.data}`))
            })
    }


    const getOrgDetail = () => {
        try {
            getOrgDetailsService(orgId)
                .then((data) => {
                    if (data?.data?.status === 200) {
                        const FORM_VALUES = {
                            'base_url': `${window.location.origin}`,

                            //Organization Info
                            "name": (data?.data?.data?.name) ? data?.data?.data?.name : "",
                            "taxId": (data?.data?.data?.taxId) ? data?.data?.data?.taxId : "",
                            "webSite": (data?.data?.data?.webSite) ? data?.data?.data?.webSite : "",
                            "regNo": (data?.data?.data?.regNo) ? data?.data?.data?.regNo : "",
                            "aboutMe": (data?.data?.data?.aboutMe) ? data?.data?.data?.aboutMe : "",
                            'profilePicture': (data?.data?.data?.profilePicture) ? data?.data?.data?.profilePicture : '',
                            'coverPicture': (data?.data?.data?.coverPicture) ? data?.data?.data?.coverPicture : '',
                            'userNameCount': (data?.data?.data?.userNameCount) ? data?.data?.data?.userNameCount : '',

                            //UserName and profile URL
                            'default_userName': (data?.data?.data?.userName) ? data?.data?.data?.userName : '',
                            'userName': (data?.data?.data?.userName) ? data?.data?.data?.userName : '',
                            'url': (data?.data?.data?.userName) ? formValues.base_url + '/o/' + data?.data?.data?.userName : '',

                            //Business Info
                            'legalStructure': (data?.data?.data?.legalStructure) ? data?.data?.data?.legalStructure : [],
                            'serviceType': (data?.data?.data?.serviceType) ? data?.data?.data?.serviceType : [],


                            //Contact Info
                            'user_email': (data?.data?.data?.email) ? data?.data?.data?.email : '',
                            'user_mobile': (data?.data?.data?.mobile) ? data?.data?.data?.mobile : '',
                            'email': (data?.data?.data?.email) ? data?.data?.data?.email : '',
                            'mobile': (data?.data?.data?.mobile) ? data?.data?.data?.mobile : '',
                            'isEmailVerify': (data?.data?.data?.isEmailVerify) ? data?.data?.data?.isEmailVerify : false,
                            'isMobileVerify': (data?.data?.data?.isMobileVerify) ? data?.data?.data?.isMobileVerify : false,
                            'mailAddress': (data?.data?.data?.mailAddress) ? data?.data?.data?.mailAddress : [],
                            'corporateAddress': (data?.data?.data?.corporateAddress) ? data?.data?.data?.corporateAddress : [],
                            //interests 
                            'interests': (data?.data?.data?.spredeInterest) ? data?.data?.data?.spredeInterest : [],
                        }

                        setFormValues(FORM_VALUES)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const getLegalServiceType = () => {
        try {
            getLegalServiceTypeService()
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setLegalServiceType(data?.data?.data)
                    }
                })
        }
        catch (error) {
            console.log(error);
        }
    }
    const updateProfileDetails = async () => {
        const response = await getOrgDetailsService(orgId);
        if (response?.data?.data) {
            const org = response?.data?.data
            dispatch(setCurrentOrg(org));
            dispatch(setCurrentRole('organization'));
        }
    }
    const saveOrgDetails = (params) => {
        try {
            updateOrgDetailsService(params)
                .then((data) => {
                    if (data.status === 200) {
                        if (activeIndex === 2) {
                            setFormValues({ ...formValues, userNameCount: 1 })
                        }
                        updateProfileDetails();
                        if (activeIndex === 4) { navigate('/voxbox'); }
                        else { setActiveIndex(activeIndex + 1) }
                    } else {
                        setError(true);
                        showToastError(t(`apiMessages.${data.data.data}`))
                    }
                })
                .then(() => { getOrgDetail(); })
                .catch((error) => {
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const skipClick = (e) => {
        e.preventDefault();
        setActiveIndex(activeIndex + 1);
    }

    const backClick = (e) => {
        e.preventDefault();
        setActiveIndex(activeIndex - 1);
    }

    const skip = () => {
        setActiveIndex(activeIndex + 1);
    }

    const handleStepperClick = (index) => {
        if (index < activeIndex) {
            setActiveIndex(index)
        }
    }

    const saveNewLegalType = (value) => {
        let params = {
            "legalStructure": value,
            "organizationId": orgId
        }
        saveNewLegalStructureService(params)
            .then(async (data) => {
                if (data.status === 200) {
                    await getLegalServiceType()
                    setFormValues({ ...formValues, 'legalStructure': [data?.data?.data?.insertedId] })
                } else {
                    showToastError(t(`apiMessages.${data?.data?.data}`))
                }
            })
            .catch((error) => {
                showToastError(t(`apiMessages.${error?.data?.data}`))
            })
    }

    const saveNewServiceType = (value) => {
        let params = {
            "serviceType": value,
            "organizationId": orgId
        }
        saveNewServiceTypeService(params)
            .then(async (data) => {
                if (data.status === 200) {
                    await getLegalServiceType()
                    setAdditionServiceType(data?.data?.data?.insertedId)
                } else {
                    showToastError(t(`apiMessages.${data?.data?.data}`))
                }
            })
            .catch((error) => {
                showToastError(t(`apiMessages.${error?.data?.data}`))
            })
    }

    return (
        <div className="userdetails_row main-content-area">
            <div className="userdetails-sidebar">
                <DetailsSideBar activeIndex={activeIndex} handleStepperClick={handleStepperClick} />
            </div>
            <div className="userdetails-div">
                {activeIndex === 0 &&
                    <OrganizationInfo
                        orgId={orgId}
                        formValues={formValues}
                        saveOrgDetails={saveOrgDetails}
                        setFormValues={setFormValues}
                        error={error}
                        setError={setError}
                    />}
                {activeIndex === 1 && <ProfileInfo
                    orgId={orgId}
                    skipClick={skipClick}
                    skip={skip}
                    backClick={backClick}
                    formValues={formValues}
                    saveOrgDetails={saveOrgDetails}
                    setFormValues={setFormValues}
                    error={error}
                    setError={setError}
                />}
                {activeIndex === 2 && <InterestSettings interests={interests}
                    orgId={orgId}
                    error={error}
                    setError={setError}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    activeIndex={activeIndex}
                    saveNewInterest={saveNewInterest}
                    setActiveIndex={setActiveIndex}
                    saveOrgDetails={saveOrgDetails}
                    skipClick={skipClick}
                    skip={skip}
                    additionalInterest={additionalInterest}
                    backClick={backClick} />
                }
                {activeIndex === 3 && <BusinessInfo
                    legalServiceType={legalServiceType}
                    orgId={orgId}
                    skipClick={skipClick}
                    skip={skip}
                    backClick={backClick}
                    activeIndex={activeIndex}
                    formValues={formValues}
                    saveNewLegalType={saveNewLegalType}
                    saveNewServiceType={saveNewServiceType}
                    setActiveIndex={setActiveIndex}
                    additionServiceType={additionServiceType}
                    setFormValues={setFormValues}
                    saveOrgDetails={saveOrgDetails}
                    error={error}
                    setError={setError}

                />}
                {activeIndex === 4 && <ContactInfo
                    orgId={orgId}
                    backClick={backClick}
                    formValues={formValues}
                    saveOrgDetails={saveOrgDetails}
                    setFormValues={setFormValues}
                    error={error}
                    setError={setError}
                />}
            </div>
        </div>
    );
}

export default OrganizationDetails;