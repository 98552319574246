import React from "react";
import {
    Modal,
    Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './popup.style.css';
const Popup = ({ handleClose, className, open, heading, children, modalBodyStyle, modalBodyClasses, modalHeadingClass, modalContentClasses }) => {
    return (<Modal
        open={open}
        //disableAutoFocus="true"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`modal_div ${className}`}
        style={{ overflow: "auto" }}
    >
        <Box className={`modal_body ${modalBodyClasses}`} style={modalBodyStyle}>
            <div style={{ maxHeight: "80vh", overflow: "hidden", overflowY: "auto" }}>
                <div className="cancel_btn invitePopupClose" style={{ zIndex: "1" }} onClick={handleClose}><CloseIcon /></div>
                {
                    (heading) ? <div className={`modal_heading ${modalHeadingClass}`} variant="h6" component="h2">
                        {heading}
                    </div> : ''
                }

                <div className={`modalContent ${modalContentClasses}`}>
                    {children}
                </div>
            </div>

        </Box>
    </Modal>);
}

Popup.defaultProps = { modalBodyStyle: {}, modalBodyClasses: "", modalHeadingClass: "", modalContentClasses: "" }
export default Popup;
