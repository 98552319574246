import React, {useState} from "react";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@mui/material";
import FormTextArea from "../../../commonComponents/formTextArea";
import BarsLoader from "../../../commonComponents/barsLoader";
import CmsBlogTestimonialBox from "../../../commonDashboardComp/cmsBlogTestimonialBox";
import ReactPagination from "../../../commonComponents/reactPagination";
import TabNavigation from "../../../commonDashboardComp/tabNavigation";
import Popup from "../../../commonComponents/popup";
const BlogCms = ({list, handlePageClick, totalPage, isLoading, addBlogTestimonial}) => {
    const {t} = useTranslation();
    const [tab, setTab] = useState(1);
    const tabs = [t('Blogs')];
    const [error, setError] = useState(false)
    const [blog, setBlog] = useState({
                                        title : '',
                                        description : ''
                                    })
    const [selectedFile, setSelectedFile] = useState(null);

    const [open, setOpen] = useState(false);
    const [loader, setLoad] = useState(false)
    const handleOpen = (e) => {e.preventDefault(); setOpen(true)}
    const handleClose = (e) => {setOpen(false)}


    const makeButton = () => {
        return <Link className="button secondary popup-event-creation-trigger" to="#" onClick={handleOpen}>+ {t('Add_Blog')}</Link>
    }

    const setPhoto = (param) => {
        if(param !== undefined){ setSelectedFile(param)}
        else{ setSelectedFile(null) }    
    }

    const removeImage = () => {
        setSelectedFile(null)
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        setError(false)

        if(selectedFile === '' || blog?.title === '' || blog?.description === ''){
            setError(true); return;
        }

        let param = {
            image : selectedFile,
            title : blog?.title,
            description : blog?.description
        }
        setLoad(true)
        await addBlogTestimonial(param, 'blog');
        setLoad(false)
        handleClose();
        setBlog({title:'', description:''})
        setSelectedFile(null)
    }

    const onEdit = (e, item) => {

    }

    const onDelete = (e, item) => {

    }

    return (<>
        <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab} showInput={false} button={makeButton()} />

        <Row className="grid">
            <div className="grid-column">
                <div className="">
                <>
                    {isLoading ? <BarsLoader /> : list?.length > 0 ?
                    <Row>
                        {list?.map((item, i) => 
                            {
                                return (
                                    <div className="widget-box-content col-lg-12 col-md-6 col-12 m-0 mb-2 my-md-2" key={i}>
                                        <CmsBlogTestimonialBox
                                        item={{...item, image : item?.blogImg, type:'blog' }}
                                        onEdit={onEdit}
                                        />
                                    </div>
                                    )
                            })
                        }
                    </Row> :
                    <>
                        {list?.length === 0 &&
                        <>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                            {t('no_blogs')}
                            </div>
                        </>
                        }
                    </>
                    }

                </>
                <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />

                </div>
            </div>

            <Popup heading={t('Add_Blog')} open={open} handleClose={handleClose} className="AddBlog-popup targetPopup">
                <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className="userdetail-form mobile_profile_margin row"
                onSubmit={handleSubmit}
                >
                    <Row>
                        <Col lg={4} md={6}>
                            
                                <div className="position-relative">
                                    <label htmlFor={`upload-photo-blog`}>
                                        <input type="file" id={`upload-photo-blog`}
                                        style={{display:"none"}}
                                        accept="image/png, image/gif, image/jpeg"
                                            onChange={(event) => {
                                                setPhoto(event.target.files[0]);
                                            }}
                                            />
                                        <div className="content">

                                            <div className="upload-box">
                                                {(selectedFile !==null) ?
                                                <img src={selectedFile!==null &&
                                                        URL.createObjectURL(selectedFile)} className="user_attach_upload" /> : 
                                                <img src="/Assets/Member_Icon.png" alt="" className="upload-box-icon icon-members"/>    }
                                                <p className="upload-box-title">{t('Image')}</p>
                                                <p className="upload-box-text">{t('onboarding.size_minimum')}</p>
                                            </div>

                                            
                                        </div>
                                    </label> 
                                    {(selectedFile !==null) &&
                                    <img src='/Assets/Close.png' className="closeIconUpload" onClick={()=>removeImage()} />}

                                    {error && selectedFile === null && 
                                        <div className="error-text"> {t('Please_select_Blog_image')} </div>
                                    }
                                </div>
                                
                            <Row className="grid">
                                <div className="grid-column myProfileSettings">
                                    <div className="widget-box area-widget">
                                        <div className="widget-box-content">
                                            <Col md={12}>
                                                <TextField label={t('superAdmin.Title')}
                                                value={blog?.title}
                                                onChange={(e)=>setBlog({...blog, title:e.target.value})}
                                                />
                                            </Col>
                                            {error && blog?.title === '' && 
                                                <div className="error-text"> {t('Enter_Blog_title')} </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Row>              
                        </Col>
                        <Col md={8}>
                            <Row className="grid">
                                <div className="grid-column myProfileSettings">
                                    <div className="widget-box area-widget">
                                        <div className="widget-box-content">
                                            <Col md={12}>
                                                <FormTextArea label={t('Text')}
                                                value={blog?.description}
                                                onChange={(e)=>setBlog({...blog, description:e.target.value})}
                                                />
                                            </Col>
                                            {error && blog?.description === '' && 
                                                <div className="error-text"> {t('Enter_Blog_Description')} </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Row>   

                            <button type="" className="button" onClick={handleSubmit}>{t('Submit')}
                            {loader && <span className="spinner-border" />}
                            </button>
                        </Col>
                    </Row>
            </Box>
            </Popup>
        </Row>
    </>)
}
export default BlogCms;
