import React, { useState,useEffect } from "react";
import './org.style.css';
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import FindOrg from "./findOrg";
import FollowingOrg from "./followingOrg";
import TabNavigation from "../../../commonDashboardComp/tabNavigation";
import { useTranslation } from "react-i18next";
import { FriendOrgCount } from "../../../services/friends.service";

const Organizations = () => {
    const { t } = useTranslation();
    const [tab, setTab] = useState(1);
    const [searchRes, setSearchRes] = useState('');
    const [totalFriends, setTotalfriends] = useState(0);
    const [countsOrgs,setCountsOrgs]=useState();
    const tabs = [t('Find_Organization'), `${t('Following')} ${countsOrgs?.orgFollowing>0?`(${countsOrgs?.orgFollowing ||0})`:''}`];

    const onKeyDownSearchText = (search) => {
        setSearchRes(search);
    }

    const searchUpdate = search => {
        setSearchRes(search);
    }
    useEffect(()=>{
        getOrgs();
     },[])
 
     const getOrgs=async()=>{
        let resp= await FriendOrgCount()
        if(resp?.status===200){
            setCountsOrgs(resp?.data?.data);
        }
     }
    
//<span className="highlighted">{totalFriends}</span>
    return (<>

        {/* <Row> <ProfileHeader coverImg='' img='' /> </Row> */}

        <div className="section-header">
            <div className="section-header-info">
                <p className="section-pretitle"></p>
                <h2 className="section-title">{t('organizations')} </h2>
            </div>
        </div>

        <TabNavigation tabClass="organizationSearchTab" tabs={tabs} setTab={setTab} activeTab={tab} onChangeSearchText={searchUpdate} onKeyUpSearch={onKeyDownSearchText} inputPlaceholder={t('superAdmin.Search_Organizations')} />
        {tab === 1 && <FindOrg searchBy={searchRes} countsOfFriends={setTotalfriends} parentOrgMethod={getOrgs}/>}
        {tab === 2 && <FollowingOrg searchBy={searchRes} countsOfFriends={setTotalfriends} parentOrgMethod={getOrgs}/>}

    </>
    )
}

export default Organizations