import React, {useEffect, useState} from "react";
import {GetSelectedPostByIdService, voxBoxProfileService } from "../services/post.services";
import PostBox from "./postBox";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserLayout from "../components/dashboard-layout";
import AvatarImgBox from "../commonComponents/AvatarImgBox/avatarImgBox";
import { AvatarImg } from "../commonComponents/Images";
import { getImageUrl } from "../utils/helpers";

const ShowPostLogin = ({selectedPost_id, setSelectedPost_id}) => {
    const postId = selectedPost_id;
    const [postData, setPostData] = useState({});
    const [infoBoxData, setInfoBoxData] = useState({});
    const navigate = useNavigate();
    let defaultUserImage = AvatarImg;

    useEffect(()=>{
        
        getInfoBoxData();
        getPostData();
    }, [postId])

    const getPostData = () => {
        
        if(postId !==  null || postId !== undefined)
        {
            GetSelectedPostByIdService(postId)
            .then((data)=>{
                if(data?.data?.status === 200){
                    setPostData(data?.data?.data[0]);
                }
            })
            .catch((error)=>{
                console.log(error);
            })
        }
    }

    const getInfoBoxData = () => {

        let type = (postData?.createdBy?.firstName && postData?.createdBy?.lastName) ? 'user' : 'org';
        let id = postData?.createdBy?._id;
        if (type && id) {
          voxBoxProfileService(type, id)
            .then(async (resp) => {
              if (resp?.status == 200) {
                setInfoBoxData({ ...resp?.data?.data, id, type: type });
              }
            })
        }
      }

    return (
        <>
            <UserLayout>
                <div md={12}>
                    <Row className="grid grid-3-6-3 mobile-prefer-content">
                        {/* <Col lg={3} className="col-md-12 showPostProfile">
                            <Row className="d-flex align-items-end">
                                <Col lg={4} className="col-md-4">
                                    <AvatarImgBox img={postData?.createdBy?.profilePicture !== "" ? getImageUrl(postData?.createdBy?._id, 'userProfile', postData?.createdBy?.profilePicture) : defaultUserImage} />
                                </Col>
                                <Col lg={8} className="col-md-8 text-left">
                                    <h5> {infoBoxData?.type === 'user' ? infoBoxData?.firstName + " " + infoBoxData?.lastName : infoBoxData?.name} </h5>
                                    {infoBoxData?.type === 'user' &&
                                        <div className='showPostFont'>
                                            <p>Friends</p>
                                            <p>{infoBoxData?.friends}</p>
                                        </div>
                                    }
                                    {infoBoxData?.type === 'org' &&
                                        <div className='showPostFont'>
                                            <p>Followers</p>
                                            <p><small>{infoBoxData?.followers ? infoBoxData?.followers : 0}</small></p>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col> */}
                        <Col lg={6} className="col-md-8 col-sm-10 col-xs-12 offset-lg-3 offset-md-2 offset-sm-1">
                            {postData?._id && <PostBox post={postData}/>} 
                        </Col>
                    </Row>
                </div>  
            </UserLayout>
        </>
    )
};

export default ShowPostLogin;