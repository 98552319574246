import ApiConfig from "../config/ApiConfig";
import instance, { instanceOrg } from "../AppInterceptor";

export const getAllUserListServices = async (page, limit, searchText) => {
  let url = `${ApiConfig.getAllUserList}?page=${page}&limit=${limit}`;
  if (searchText) {
    url = url + `&searchText=${searchText}`;
  }
  const response = await instance.get(url)
  if (response?.data?.status === 200) {
    return response.data || '';
  }
}

export const getAllUserListWithPosts = async() =>{
  let url = `${ApiConfig.getPostUser}`;
  const response = await instance.get(url)
  if (response?.data?.status === 200) {
    return response.data || '';
  }
}


export const getAllOrganizationListServices = async (page, limit, searchText) => {
  let url = `${ApiConfig.getAllOrganizationList}?page=${page}&limit=${limit}`;
  if (searchText) {
    url = url + `&searchText=${searchText}`;
  }
  const response = await instance.get(url)
  if (response?.data?.status === 200) {
    return response.data || '';
  }
}

export const getEmployeeListService = async (page, limit, searchText) => {
  let url = `${ApiConfig.getEmployeeList}&page=${page}&limit=${limit}`;
  if (searchText) {
    url = url + `&searchText=${searchText}`;
  }
  const response = await instanceOrg.get(url)
  if (response?.data?.status === 200) {
    return response.data || '';
  }
}

export const userStatusUpdateService = (payload) => {
  return instance.post(ApiConfig.orgUserStatus, payload)
}

export const orgStatusUpdateService = (payload) => {
  return instance.post(ApiConfig.orgStatus, payload)
}

export const getUserByIdDetailService = (payload) => {
  return instance.post(ApiConfig.findUser , payload);
}

export const removeUserOrgAdminService = (payload) =>{
  return instance.post(ApiConfig.removeOrgAdmin, payload);
}

export const changeOrgAdminService = (payload) => {
  return instance.post(ApiConfig.changeOrgAdmin, payload)
}

export const createOrgAdminService = (payload) => {
  return instance.post(ApiConfig.createOrgAdmin, payload)
}

export const canChangeOrgAdminService = (orgId) => {
  return instance.get(ApiConfig.canChangeOrgAdmin+'?organizationId='+orgId)
}

export const passwordResetReqService = () => {
  return instance.get(ApiConfig.passwordResetReq);
}

//Report User
export const reportUserlist = (page, limit, searchText) => {
  let url = ApiConfig.reportUser + '?page=' + page + '&limit=' + limit + '&searchText=' + searchText;
  return instance.get(url)
};

//Delete ACCounts 
export const deleteOrgAccountService = (id) => {
  let url = ApiConfig.deleteOrgAccount + '?orgId=' + id;
  return instance.delete(url)
}

export const deleteUserAccountService = (id) => {
  let url = ApiConfig.deleteUserAccount + '?userId=' + id;
  return instance.delete(url)
}
