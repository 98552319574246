import ApiConfig from "../config/ApiConfig";
import {instanceOrg} from "../AppInterceptor";

export const getfollowerList = (page,limit,listType,searchText) => {
    let finalurl=`${ApiConfig?.getFollowers}?page=${page}&limit=${limit}&listType=${listType}`;
    if(searchText)finalurl+=`&searchText=${searchText}`
    return instanceOrg.get(finalurl); 
}

export const getEmployeesIdService = () => {
    let finalurl=`${ApiConfig?.getFollowers}?page=0&limit=10&listType=allEmployeeList`;
    return instanceOrg.get(finalurl); 
}

export const orgBlockOrFollowOrUnfollowOrUnblock=(payload)=>{
   return instanceOrg.post(ApiConfig.orgActionRequest,payload);
}

export const sendInvitesOrgUser=(payload)=>{
    return instanceOrg.post(ApiConfig.orgInviteUser,payload);
}

export const empRejectAcceptRemoveRequest=(payload)=>{
    return instanceOrg.post(ApiConfig.reqRejectAcceptRemove,payload);
}

export const getAllRoles=()=>{
    return instanceOrg.get(ApiConfig.roleList);
}

export const assignUserRole=(payload)=>{
    return instanceOrg.post(ApiConfig.orgAssigningRole,payload);
}

export const removeUserRole=(payload)=>{
    return instanceOrg.delete(ApiConfig.orgRemoveRole,{data:payload});
}

export const bulkInvite=(payload)=>{
    return instanceOrg.post(ApiConfig.bulkUploadInvite,payload);
}



