import { createContext, useState } from 'react';

export const CommonContext = createContext({
    showSmallNav: true,
    setShowSmallNav: () => { },
})

export const CommonProvider = ({ children }) => {
    const [showSmallNav, setShowSmallNav] = useState(true);

    return (
        <CommonContext.Provider value={{
            showSmallNav,
            setShowSmallNav
        }}>
          {children}
        </CommonContext.Provider>
    )
}
