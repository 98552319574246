import React, { useEffect, useState } from 'react'
import { LanguageList } from '../../config/constants';
import HomeBanner from './banner'
import i18next from "i18next";
import { getBlogsService, getCmsServiceList } from '../../services/cms.services';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { getImageUrl } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import ReactPagination from '../../commonComponents/reactPagination';
import BarsLoader from '../../commonComponents/barsLoader';

function BlogsList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const defaultLanguage = i18next?.language?.split('-')[0];
    const find = LanguageList?.find(a => a.value === defaultLanguage);
    const [cmsData, setcmsData] = useState([])

    const [language, setLanguage] = useState((find?.name) ? find : LanguageList[0])


    const limit = 9;
    const [isLoading, setLoading] = useState(true);
    const [blogs, setBlogs] = useState([]);
    const [currentBlogPage, setCurrentBlogPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    useEffect(() => {
        let filter = LanguageList?.find(a => a.value === defaultLanguage);

        setLanguage((filter?.name) ? filter : LanguageList[0])
    }, [defaultLanguage])

    React.useEffect(() => {
        const handleLanguageChange = () => {

            let defaultLanguage1 = i18next?.language?.split('-')[0];

            let filter = LanguageList?.find(a => a.value === defaultLanguage1);

            setLanguage((filter?.name) ? filter : LanguageList[0])
        };

        i18next.on("languageChanged", handleLanguageChange);

        return () => {
            i18next.off("languageChanged", handleLanguageChange);
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        if (language !== null) { getCmsData(); getBlogs(); }
    }, [currentBlogPage, language]);

    const getCmsData = async () => {
        getCmsServiceList(language?.value)
            .then((data) => {
                if (data?.data?.status === 200) {
                    const _data = data.data.data;
                    setcmsData(_data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getBlogs = () => {
        getBlogsService(currentBlogPage, limit, language?.value)
            .then((response) => {
                if (response.data.status === 200) {
                    setBlogs(response?.data?.data?.res)
                    let pageCount = (response?.data?.data?.totalCount) / (limit);
                    setTotalPage(Math.ceil(pageCount));
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handlePageClick = (data) => {
        let Page = data.selected
        setCurrentBlogPage(Page)
    }
    return (
        <>
            <HomeBanner onlyMenu={true} cmsData={cmsData}/>
            <Container fluid className="blogs-div">
                <div className="blog-container">
                    <Row className="panchtatva-row1 mb-4">
                        <h1 className="heading"> {t('homepage.blogs.title')} </h1>
                        <p className="detail"> {t('homepage.blogs.sub_title')}</p>
                    </Row>
                    <Row>
                        {isLoading ? <BarsLoader /> : <>
                            {
                                blogs?.length > 0 &&
                                blogs.map((item, index) => {
                                    return (

                                        <Col md={6} className="col-lg-4" key={index}>
                                            <div className="blogs-row2">
                                                <img src={getImageUrl(item?._id, 'cms', item?.blogImg)} alt="blogsImg" className="img" />
                                                <div className="blog_detail">
                                                    <p className="heading"> {item?.title} </p>
                                                    <p className="desc">
                                                        {item?.description}
                                                    </p>
                                                    <p className="blog_link" onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate('/blogsDetail', { state: { blogId: item?._id } })
                                                    }}>
                                                        <Link to="" >{t('button.read_more')}</Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })

                            }
                        </>}

                    </Row>
                    <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />
                </div>

            </Container>
        </>
    )
}

export default BlogsList