import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import './home.style.css';
import { Container , Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Header from "../../components/header/header";
import LogInForm from "../../components/loginForm/loginForm";
import SignInForm from "../../components/signInForm/signInForm";
import ForgotPasswordForm from "../../components/forgot-password-form/forgotPwdForm";
import { useState } from "react";
import Slider from "react-slick";
import { LandingBanner, RegisterBanner } from "../../commonComponents/Images";
import { useSelector } from 'react-redux';
import { getImageUrl } from "../../utils/helpers";
import { selectCurrentUser } from '../../Reducer/user/user.selector';
const HomeBanner = ({cmsData, onlyMenu = false}) => {

    useEffect(()=>{
        document.body.classList.remove('dashboard');
    }, []);

    const current_path = window.location.pathname;
    const {t} = useTranslation();
    const currentUser = useSelector(selectCurrentUser);
    const [loginBox, setLogin] = useState(1);
    const [bannerImage , setBannerImage] = useState(LandingBanner);
    const banner_slider_settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 3000,
        className: 'slides',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 376,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]

    };
    useEffect(()=>{
        setBannerImage((onlyMenu)?'':(loginBox)?LandingBanner:RegisterBanner);
    }, [loginBox])
    
    return (
        
        <Container fluid className="landing2 landing" style={{background:(cmsData?.banners?.length>0)?"":'url(' + bannerImage + ')  right center / contain no-repeat'}}>
            {
                (cmsData?.banners?.length > 0 && current_path == '/')?<div id="homeBannerSlider" className="container w-50">
                    <div className="row">
                        <div className="col-12">
                            <Slider {...banner_slider_settings}>
                            {
                            cmsData?.banners?.length > 0 && cmsData &&
                            cmsData?.banners?.map((item, index) => (
                                <div className="company-box-area" key={index}>
                                    {/* <img src={TestimonialGoogle} alt="" /> */}
                                    <img src={getImageUrl(cmsData?._id, 'cms', item)} alt="" />
                                </div>

                            ))}
                            </Slider>
                        </div>
                    </div>
                </div>:""
            }
            <Header cmsData={cmsData}/>
            {!onlyMenu && 
            <div className="col-md-12 overflow:hidden;">
            <Row className="">
                <Col lg={6} className="text-center">
                    <div className="landing-info">
                <img className="w-50" src="../Assets/V_logo_2.png" alt={t('water')}/>
                        <div className="login_area">{currentUser === null && <>
                                <Link to="/register" className={current_path==='/register'?'active':''} >{t('register')}</Link>
                                <Link to="/login" className={current_path==='/login'?'active':''} >{t('login')}</Link>
                            </>}
                        </div>
                        <div className="login_area">
                            {/* <button className="banner_link greenGradientBtn mt-2">{t('button.learn_more')}</button> */}
                            <Link to={'/aboutus'} className="banner_link greenGradientBtn mt-2">{t('button.learn_more')}</Link>
                        </div>
                    </div>
                </Col>
                <Col lg={6} className="">
                    <div className="landing-form">
                        {current_path === '/login' && <LogInForm setLogin={setLogin}/>}
                        {current_path === '/register' && <SignInForm setLogin={setLogin}/> }
                        {current_path === '/forgot-password' && <ForgotPasswordForm setLogin={setLogin}/> }
                    </div>
                </Col>
            </Row>
            </div>}
        </Container>
    );
}

export default HomeBanner;