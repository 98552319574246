import Popup from "./popup";
import Slider from "react-slick";
import { getImageUrl } from "../utils/helpers";
import Video from "./video";


const ImageVideoSlider = ({images, videos, open, handleClose, id, postData}) => {

    const slider_settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1,
        className: 'slides',
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
      };

    return (<Popup open={open} handleClose={handleClose} className="postAllArea" modalBodyClasses="imageVideoSliderModal">
                <Slider {...slider_settings}>
                    {
                        images !== null && images?.length > 0 &&
                        images.map((item,index)=>{
                            return (
                                <div className="postImgVid" key={index}>
                                    {postData?.sharedPostId?._id ?
                                        <img src={getImageUrl(postData?.sharedPostId?._id, 'voxBoxImage', item)} alt="" />
                                    :
                                        <img src={getImageUrl(id, 'voxBoxImage', item)} alt="" />
                                    }    
                                </div>
                         ) })
                    } 
                    {
                        videos !== null && videos?.length > 0 &&
                        videos.map((item,index)=>{
                            return (
                                <div className="postImgVid" key={index}>
                                    {postData?.sharedPostId?._id ?
                                        <Video src={getImageUrl(postData?.sharedPostId?._id, 'voxBoxVideo', item)}/>
                                    :
                                        <Video src={getImageUrl(id, 'voxBoxVideo', item)}/>
                                    }
                                </div>
                         ) })
                    } 
                </Slider>
            </Popup>
        )
}

export default ImageVideoSlider;