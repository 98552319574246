import React , {useState , useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import GlobalSideBar from "./globalSideBar";
const GlobalSearch = () => {
    const {t} = useTranslation();

  
    return (<Row className="grid grid-3-6-3 mobile-prefer-content">
                <Col md={3} className="grid-column">
                    <GlobalSideBar/>
                </Col>
            </Row>)
}

export default GlobalSearch;

