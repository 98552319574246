import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import RoundCheckbox from "../../../commonComponents/roundCheckbox";
import EditOrgLegalServiceType from "../../../commonComponents/editOrgLegalServiceType";
const BusinessInfo = ({orgId, backClick, formValues, setFormValues, saveOrgDetails, legalServiceType, saveNewLegalType, saveNewServiceType, additionServiceType}) => {
    const {t} = useTranslation();
    
    const nextClick = (e) => {
        e.preventDefault();
        let params = {
            organizationId : orgId,
            legalStructure: formValues.legalStructure,
            serviceType: formValues.serviceType
        }
        saveOrgDetails(params);
    }


    return (
        <>
            <div className="heading">
                <h1>{t('onboarding.businessInfo')}</h1>
                <h4>{t('onboarding.to_continue_sprede')}</h4>
            </div>

            <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '98%' },
                    }}
                    noValidate
                    autoComplete="off"
                    className="userdetail-form"
                    onSubmit={(e)=>{e.preventDefault();}}
                    >
                <Row>
                    <EditOrgLegalServiceType
                    legalServiceType={legalServiceType}
                    formValues = {formValues}
                    setFormValues = {setFormValues}
                    saveNewLegalType={saveNewLegalType}
                    saveNewServiceType={saveNewServiceType}
                    additionServiceType={additionServiceType}
                    />
                </Row>

                <Row>
                    <div className="back-next-btn-area contact-back-next-btn-area">
                        <Stack spacing={2} direction="row">
                            <button className="button green back-btn-new" type="button" onClick={backClick}> {t('back')} </button>
                            <button className="button secondary next-btn-new" type="button" onClick={nextClick}> {t('next')} </button>
                        </Stack>
                    </div>
                </Row>
            </Box>
        </>
    )
}

export default BusinessInfo;