import React, {useState} from "react";
import { Row, Col } from "react-bootstrap";
import {Box,
    Select, 
    MenuItem, 
    InputLabel,
    FormControl} from '@mui/material'
import SaveEditSettingsBtn from "../../../../commonDashboardComp/saveEditSettingsBtn";
import { useTranslation } from "react-i18next";
const PrivacySettingsOrg = ({setting, onEdit, onChangeSetting, canEdit, onSave} ) => {
    const { t } = useTranslation();
    return (<Row className="grid">
            <div className="grid-column myProfileSettings">
                <div className="widget-box area-widget">
                    <p className="widget-box-title">{t('privacy_setting')}
                    <span style={{float:"right"}}>
                        <SaveEditSettingsBtn canEdit={canEdit} onEdit={onEdit} onSave={onSave}/>
                    </span>
                    </p>
                    <div className="widget-box-content">
                    <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '98%' },
                            }}
                            noValidate
                            autoComplete="off"
                            className="userdetail-form account_info_org_area mobile_profile_margin privacy_parent11"
                            >
                        <Row className="privacy_subcategory22">
                            <Col md={7}>
                                <span className="span_heading">
                                    <span className="span_heading1">{t('Who_can_see_your_Profile')}</span>
                                </span>
                            </Col>
                            <Col md={5}>
                                <FormControl fullWidth>
                                    <InputLabel id="co-country-select-label">{t('Who_can_see_your_Profile')}</InputLabel>
                                    <Select
                                            labelId="co-country-select-label"
                                            value={setting?.whoCanSeeProfile || ''}
                                            disabled={!canEdit}
                                            label="Who can see your Profile"
                                            onChange={(e) => onChangeSetting('whoCanSeeProfile', e.target.value)}
                                        >
                                        <MenuItem value="private">{t('Private_only_me')}</MenuItem>
                                        <MenuItem value="everyone">{t('Everyone')}</MenuItem>
                                        {/* <MenuItem value="friends">{t('friends')}</MenuItem>
                                        <MenuItem value="friends_of_friends">{t('interest.friendOfFriend')}</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </Col>

                            <Col md={7}>
                                <span className="span_heading">
                                    <span className="span_heading1">{t('Share_posts_profile_details_interests_events_etc_with')}</span> 
                                </span>
                            </Col>
                            <Col md={5}>
                                <FormControl fullWidth>
                                    <InputLabel id="co-country-select-label">{t('Share_posts_profile_details_interests_events_etc_with')}</InputLabel>
                                    <Select
                                            labelId="co-country-select-label"
                                            value={setting?.whoCanSharePost || ''}
                                            disabled={!canEdit}
                                            label="share posts, profile details, interests, events etc. with"
                                            onChange={(e) => onChangeSetting('whoCanSharePost', e.target.value)}
                                        >
                                        <MenuItem value="private">{t('Private_only_me')}</MenuItem>
                                        <MenuItem value="everyone">{t('Everyone')}</MenuItem>
                                        {/* <MenuItem value="friends">{t('friends')}</MenuItem>
                                        <MenuItem value="friends_of_friends">{t('interest.friendOfFriend')}</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                    </Box>
                    </div>
                </div>
            </div>
            
        </Row>)
}

export default PrivacySettingsOrg;