import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import '../dashboard.style.css';
import { Row, Col } from "react-bootstrap";
import FormTextArea from "../../../commonComponents/formTextArea";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { CalendarMonthIcon } from "../../../commonComponents/Images";
import RoundCheckbox from "../../../commonComponents/roundCheckbox";
import RoundRadio from "../../../commonComponents/roundCheckbox/roundRadio";
import { useSelector } from "react-redux";
import { selectCurrentUserRole, selectCurrentUser, selectCurrentOrg } from "../../../Reducer/user/user.selector";
import { showSingleToastSuccess, showToastError, changeDateFormatYYYY, getMeasuringUnits, capitalizeFirstLetter } from "../../../utils/helpers";
import { GetInterestsListService } from "../../../services/onBoarding.services";
import { targetTypeList, incentivesList, giftsList } from "../../../config/constants";
import { allowOnlyLetters, allowOnlyLettersNumbers } from "../../../utils/helpers";
import { getTargetDetailService, addTargetService, updateTargetService } from "../../../services/onBoarding.services";
import { getOrgInterestsService } from "../../../services/orgAuth.services";
import { getImageUrl } from "../../../utils/helpers";
import InvitePopup from "../../../commonDashboardComp/InvitePopUp";
import InvitedPeoplePopup from "../../../commonDashboardComp/invitedPeoplePopup";
import { uploadFileService } from "../../../services/fileUpload.services";

const AddTarget = React.memo(() => {
    const { t } = useTranslation();
    let location = useLocation();
    //const targetData = location.state;
    const targetId = location?.state?.targetId;
    const navigate = useNavigate();

    const role = useSelector(selectCurrentUserRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);
    const [searchParams] = useSearchParams();
    const actionType = searchParams.get("type");

    let current_user = {};

    if (role === 'organization') { current_user = CURRENTORG }
    else { current_user = CURRENTUSER }

    const [popupOpen, setPopupOpen] = useState(false);
    const handlePopupOpen = () => setPopupOpen(true);
    const handlePopupClose = () => setPopupOpen(false);

    const [popupInvitedOpen, setPopupInvitedOpen] = useState(false);
    const handlePopupInvitedOpen = () => setPopupInvitedOpen(true);
    const handlePopupInvitedClose = () => setPopupInvitedOpen(false);


    const [isLoading, setLoading] = useState(false);
    const [prevTargetInvites, setPrevTargetInvites] = useState([]);
    const [prevTargetOrgInvites, setPrevTargetOrgInvites] = useState([]);
    const [targetData, setTargetData] = useState(null);
    const [userInvites, setUserInvites] = useState([]);
    const [orgInvites, setOrgInvites] = useState([]);
    const INITIAL_FORM_VALUES = {
        "title": "",
        "createdBy": (role === 'organization') ? role : 'user',
        "targetType": "",
        "spredeInterestCategory": "",
        "spredeInterestSubCategory": "",
        "targetUnit": "",
        "fromDate": "",
        "toDate": "",
        "description": "",
        "photo": "",
        "incentive": "",
        "incentivePrize": "",
        "invites": [],
        "organizationInvites": []
    }
    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);
    const [interests, setInterests] = useState([]);
    const [subInterests, setSubInterests] = useState([]);
    const [originalData, setOriginalData] = useState();

    const [otherGift, setOtherGift] = useState("");
    const [error, setError] = useState(false);
    const [showSelected, setShowSelected] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [errorCover, setErrorCover] = useState(false);

    const [pendingInvites, setPendingInvites] = useState({});


    const setPhoto = async (param) => {
        let isValidSize = await readImageFileCover(param)
        if (param !== null && param !== undefined) {
            setErrorCover(param?.size < 5000 || param?.size > 5120000 ? true : false) //|| !isValidSize
            if (param?.size < 5000 || param?.size > 5120000) { //|| !isValidSize
                return;
            }
        }
        setShowSelected(true);
        setSelectedImage(param);
    }

    const removeImage = () => {
        setShowSelected(false);
        setSelectedImage(null);
        setFormValues({ ...formValues, photo: '' })
    }

    useEffect(() => {
        setFormValues({ ...formValues, createdBy: (role === 'organization') ? role : 'user' });
    }, [role])
    useEffect(() => {
        getInterestsLists()
    }, [role])
    useEffect(() => {
        if (targetId !== null && targetId !== undefined) {
            getTargetDetail();
        }
    }, [])
    const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };
    useEffect(() => {
        setTargetFormValues()
    }, [targetData])

    const getWordCount = (t) => {
        let text = t.split(" ");
        return text.length
    }


    const setTargetFormValues = () => {
        if (targetData !== null) {
            const Form_Values = {
                "title": (targetData.title) ? targetData.title : "",
                "createdBy": (role === 'organization') ? role : 'user',
                "targetType": (targetData.targetType) ? targetData.targetType : "public",
                "spredeInterestCategory": (targetData?.spredeInterestCategory) ? targetData?.spredeInterestCategory?._id : "",
                "spredeInterestSubCategory": (targetData?.spredeInterestSubCategory) ? targetData?.spredeInterestSubCategory?._id : "",
                "targetUnit": (targetData.targetUnit) ? targetData.targetUnit : "",
                "fromDate": (targetData.fromDate) ? new Date(targetData.fromDate).toLocaleDateString('en-US', dateOptions) : "",
                "toDate": (targetData.toDate) ? new Date(targetData.toDate).toLocaleDateString('en-US', dateOptions) : "",
                "description": (targetData.description) ? targetData.description : "",
                "photo": (targetData.photo) ? targetData.photo : "",
                "incentive": (targetData.incentive) ? targetData.incentive : "",
                "incentivePrize": (targetData.incentivePrize) ? targetData.incentivePrize : "",
                "invites": (targetData.invites) ? targetData.invites : [],
                "organizationInvites": (targetData.organizationInvites) ? targetData.organizationInvites : [],
            }

            setUserInvites((targetData.invites) ? [...targetData?.invites, ...targetData?.pendingUserInvites] : [])
            setOrgInvites((targetData.organizationInvites) ? [...targetData.organizationInvites, ...targetData?.pendingOrganizationInvites] : [])
            setOriginalData(Form_Values);

            let targetInvites = [];
            let targetInvitesBk = [];

            let targetOrgInvites = [];
            let targetOrgInvitesBk = [];

            let pendingUserInvites = [];
            let pendingOrgInvites = [];

            if (targetData?.invites && targetData?.invites?.length > 0) {
                targetData?.invites?.map((item) => {
                    targetInvitesBk.push(item?._id)
                    targetInvites.push(item?._id)
                })
            }

            if (targetData?.organizationInvites && targetData?.organizationInvites?.length > 0) {
                targetData?.organizationInvites?.map((item) => {
                    targetOrgInvitesBk.push(item?._id)
                    targetOrgInvites.push(item?._id)
                })
            }

            if (targetData?.pendingUserInvites && targetData?.pendingUserInvites?.length > 0) {
                targetData?.pendingUserInvites?.map((item) => {
                    targetInvitesBk.push(item?._id)
                    targetInvites.push(item?._id)
                    pendingUserInvites.push(item?._id)
                })
            }

            if (targetData?.pendingOrganizationInvites && targetData?.pendingOrganizationInvites?.length > 0) {
                targetData?.pendingOrganizationInvites?.map((item) => {
                    targetOrgInvitesBk.push(item?._id)
                    targetOrgInvites.push(item?._id)
                    pendingOrgInvites.push(item?._id)
                })
            }
            Form_Values['_id'] = targetData._id
            Form_Values['invites'] = targetInvitesBk;
            Form_Values['organizationInvites'] = targetOrgInvitesBk;
            setPrevTargetInvites(targetInvites)
            setPrevTargetOrgInvites(targetOrgInvites)
            let chk = giftsList?.filter((list) => list.value === targetData.incentivePrize);

            setPendingInvites({ pendingUserInvites: pendingUserInvites, pendingOrgInvites: pendingOrgInvites })

            //check if giftlist is from options or other data
            if (chk.length === 0) {
                setOtherGift(targetData.incentivePrize)
            }
            setFormValues(Form_Values);
        }
    }


    useEffect(() => {
        filterSubCategories(formValues.spredeInterestCategory)
    }, [formValues.spredeInterestCategory])

    const getTargetDetail = () => {
        if (targetId !== null && targetId !== undefined) {
            getTargetDetailService(targetId)
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setTargetData(data?.data?.data);
                    }
                })
        }
    }

    const filterSubCategories = (value, interestList = interests) => {
        let sub_interests = interestList?.filter((interest) => interest._id === value);
        setSubInterests(sub_interests[0]?.option)
    }

    const getInterestsLists = async () => {
        if (role === 'organization') {
            getOrgInterestsService(current_user?._id, true)
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setInterests(data?.data?.data);
                        if (targetData !== null) {
                            filterSubCategories(targetData.spredeInterestCategory?._id, data?.data?.data)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        else {
            GetInterestsListService(true)
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setInterests(data?.data?.data);
                        if (targetData !== null) {
                            filterSubCategories(targetData.spredeInterestCategory?._id, data?.data?.data)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    const handleIncentiveGiftChange = (e, value) => {
        if (e.target.checked) {
            setFormValues({ ...formValues, incentivePrize: value })
            setOtherGift("");
        }
    }


    const changeDate = (params) => {
        let result = params;
        result.fromDate = changeDateFormatYYYY(new Date(params.fromDate));
        result.toDate = changeDateFormatYYYY(new Date(params.toDate));
        return result;
    }

    const resetHandler = (e) => {
        e.preventDefault();
        if (targetData === null) {
            setFormValues(INITIAL_FORM_VALUES);
            setShowSelected(false);
            setSelectedImage(null);

        }
        else {
            setTargetFormValues();
            setShowSelected(false);
            setSelectedImage(null);

        }
    }

    const changeDetection = (param) => {
        const propertyNames = Object.keys(param);
        for (let i = 0; i < propertyNames?.length; i++) {
            if (JSON.stringify(originalData[propertyNames[i]]) !== JSON.stringify(param[propertyNames[i]])) {
                return true;
            }
        }
        return false;
    }

    const scrollToError = () => {
        const element = document.getElementsByClassName('error-text');
        if (element[0]) {
            // 👇 Will scroll smoothly to the top of the next section
            element[0].scrollIntoView({ behavior: 'smooth', block: "center" });
        }
    }

    const targetSubmitHandler = async (e) => {
        e.preventDefault();
        setError(false);
        if (formValues.title === '' || formValues.title.length >= 30 || formValues.targetType === '' ||
            formValues.spredeInterestCategory === '' || formValues.spredeInterestSubCategory === '' ||
            formValues.fromDate === '' || formValues.toDate === '' ||
            formValues.description === '' || getWordCount(formValues?.description) >= 200 || formValues.targetUnit === '' ||
            (formValues.photo === '' && selectedImage === null) || formValues.incentive === '' ||
            (formValues?.incentive === 'incentivized' && formValues?.incentivePrize === '')
        ) {
            setError(true); scrollToError(); return;
        }
        setLoading(true)
        const timeStamp = Date.now();
        let params = { ...formValues }
        if (selectedImage !== null) {
            params = { ...params, photo: timeStamp }
        }
        params['unitType'] = getMeasuringUnitsValue(formValues?.spredeInterestSubCategory);
        // params['onlyDefault']=true;
        if (targetData !== null && targetData !== undefined) {
            let invites_final = formValues?.invites?.filter(function (el) {
                return prevTargetInvites?.indexOf(el) < 0;
            });
            let org_invites_final = formValues?.organizationInvites?.filter(function (el) {
                return prevTargetOrgInvites?.indexOf(el) < 0;
            });

            params = {
                ...params,
                invites: invites_final,
                removeInvites: prevTargetInvites.filter(item => !formValues?.invites.includes(item)),
                organizationInvites: org_invites_final,
                removeOrganizationInvites: prevTargetOrgInvites.filter(item => !formValues?.organizationInvites.includes(item)),
                removePendingUserInvites: pendingInvites?.pendingUserInvites.filter(item => !formValues?.invites.includes(item)),
                removePendingOrganizationInvites: pendingInvites?.pendingOrgInvites.filter(item => !formValues?.organizationInvites.includes(item))
            }
            changeDetection(formValues);
            try {
                const result = changeDate(params);
                updateTargetService(result)
                    .then(async (data) => {
                        if (data.status === 200) {
                            if (timeStamp === params?.photo) {
                                const result = await uploadFileService('target', 'target', timeStamp, selectedImage, formValues?._id);
                                if (result) {
                                    // showToastSuccess(t('apiMessages.TARGET_UPDATE_SUCCESS'));
                                    showSingleToastSuccess(t('apiMessages.TARGET_UPDATE_SUCCESS'));
                                    navigate('/target');
                                }
                            }
                            else {
                                // showToastSuccess(t('apiMessages.TARGET_UPDATE_SUCCESS'));
                                // showSingleToastSuccess(t('apiMessages.TARGET_UPDATE_SUCCESS'));
                                if (changeDetection(formValues)) {
                                    showSingleToastSuccess(t('apiMessages.TARGET_UPDATE_SUCCESS'));
                                }
                                else {
                                    showSingleToastSuccess("No changes have been made")
                                }
                                navigate('/target');
                            }
                        } else {
                            setLoading(false)
                            showToastError(t(`apiMessages.${data.data.data}`))
                        }
                    })
                    .catch((error) => {
                        setLoading(false)
                        showToastError(t(`apiMessages.${error?.data?.data}`))
                    })
            }
            catch (error) {
                setLoading(false)
                console.log(error);
            }
        }
        else {
            try {
                const result = changeDate(params);
                addTargetService(result)
                    .then(async (data) => {
                        if (data?.data?.status === 200) {
                            const result = await uploadFileService('target', 'target', timeStamp, selectedImage, data?.data?.data);
                            if (result) {
                                // showToastSuccess(t('apiMessages.TARGET_ADD_SUCCESS'));
                                showSingleToastSuccess(t('apiMessages.TARGET_ADD_SUCCESS'));
                                navigate('/target');
                            }
                        } else {
                            setLoading(false)
                            showToastError(t(`apiMessages.${data.data.data}`))
                        }
                    })
                    .catch((error) => {
                        setLoading(false)
                        showToastError(t(`apiMessages.${error?.data?.data}`))
                    })
            }
            catch (error) {
                setLoading(false)
                console.log(error);
            }
        }


    }

    const addInInvitesHandler = (id, type = "invites", user) => {
        if (type === 'invites') {
            if (formValues.invites.indexOf(id) === -1) {
                let arr = [...formValues.invites];
                arr.push(id);
                setFormValues({ ...formValues, invites: arr })

                let invitesArr = userInvites;
                invitesArr.push(user);
                setUserInvites(invitesArr)
            }
        }
        else {
            if (formValues.organizationInvites.indexOf(id) === -1) {
                let arr = formValues.organizationInvites;
                arr.push(id);
                setFormValues({ ...formValues, organizationInvites: arr })

                let invitesArr = orgInvites;
                invitesArr.push(user);
                setOrgInvites(invitesArr)
            }
        }

    }

    const removeInvitesHandler = (id, type = "invites") => {
        if (type === 'invites') {
            if (Array.isArray(id)) {
                let arr = formValues.invites;
                arr = arr.filter(item => id.indexOf(item) === -1);
                setFormValues({ ...formValues, invites: arr })

                let invitesArr = userInvites;
                let filterInvitesArr = invitesArr.filter(item => id.indexOf(item?._id) === -1)
                setUserInvites(filterInvitesArr)
            }
            else {
                let arr = formValues.invites;
                let filter = arr.filter((item) => { return item !== id });
                setFormValues({ ...formValues, invites: filter })

                let invitesArr = userInvites;
                let filterInvitesArr = invitesArr.filter(item => item._id !== id)
                setUserInvites(filterInvitesArr)
            }

        }
        else {
            if (Array.isArray(id)) {
                let arr = formValues.organizationInvites;
                arr = arr.filter(item => id.indexOf(item) === -1);
                setFormValues({ ...formValues, organizationInvites: arr })

                let invitesArr = orgInvites;
                let filterInvitesArr = invitesArr.filter(item => id.indexOf(item?._id) === -1)
                setOrgInvites(filterInvitesArr)
            }
            else {
                let arr = formValues.organizationInvites;
                let filter = arr.filter((item) => { return item !== id });
                setFormValues({ ...formValues, organizationInvites: filter })

                let invitesArr = orgInvites;
                let filterInvitesArr = invitesArr.filter(item => item._id !== id)
                setOrgInvites(filterInvitesArr)
            }


        }
    }

    const getMeasuringUnitsValue = (value) => {
        let sub_interests = subInterests?.filter((interest) => interest._id === value);
        let key = (sub_interests !== undefined && sub_interests.length !== 0) ? sub_interests[0]?.spredeInterestOptions : t('abc')
        return getMeasuringUnits(key)
    }

    //for Cover image upload check width and height

    function readImageFileCover(file) {
        const promise = new Promise((resolve, reject) => {
            var reader = new FileReader(); // CREATE AN NEW INSTANCE.
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {
                    var w = this.width;
                    var h = this.height;
                    if (h < 300 || w < 800) {
                        resolve(false)
                    } else
                        resolve(true)


                }
            };
        });
        return promise;

    }
    return (
        <>
            {/* <div> <ProfileHeader coverImg='' img=''/> </div> */}
            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle"></p>
                    <h2 className="section-title">{targetData === null ? t('button.add_new_target') : t('button.Edit_Target')}</h2>
                </div>
            </div>

            <Row className="grid addTargetComp">
                <div id="addTargetBlock" className="grid-column myProfileSettings addNewTargetSettings">
                    <div className="widget-box addNewTargetWidget">
                        <div className="widget-box-content">
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                                onSubmit={(e) => (e).preventDefault()}
                            >
                                <Row>
                                    <Col md={4} className="targetInputs target_title88">
                                        <TextField type='text' label={t('form.Target_Title')}
                                            value={formValues.title}
                                            onChange={e => (e.target.value.length <= 30) ? setFormValues({ ...formValues, title: allowOnlyLettersNumbers(e.target.value) }) : ''}
                                        />
                                        {error && formValues.title === '' &&
                                            <div className="error-text">
                                                {t('errors.target_title_required')}
                                            </div>
                                        }
                                        {formValues.title.length >= 30 ?
                                            <div className="error-text">
                                                {t('errors.you_can_not_enter_more_than_30_characters')}
                                            </div>
                                            : ""
                                        }
                                    </Col>
                                    <Col md={4} className="newTargetSelect target_title88">
                                        <FormControl fullWidth>
                                            <InputLabel id="target-type">{t('superAdmin.Target_Type')}</InputLabel>
                                            <Select
                                                // MenuProps={{ disableScrollLock: true }}
                                                labelId="target-type"
                                                className="targetSelect"
                                                value={formValues.targetType}
                                                onChange={e => setFormValues({ ...formValues, targetType: e.target.value })}
                                                label={t('superAdmin.Target_Type')}
                                            >
                                                {targetTypeList.map((item, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={item.value}>{item.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            {error && formValues.targetType === '' &&
                                                <div className="error-text">
                                                    {t('errors.target_type_required')}
                                                </div>
                                            }
                                        </FormControl>

                                    </Col>
                                    <Col md={4} className="newTargetSelect target_title88">
                                        <FormControl fullWidth>
                                            <InputLabel id="target-type">{t('category')}</InputLabel>
                                            <Select
                                                // MenuProps={{ disableScrollLock: true }}
                                                labelId="target-type"
                                                value={formValues.spredeInterestCategory}
                                                onChange={e => {
                                                    setFormValues({ ...formValues, spredeInterestCategory: capitalizeFirstLetter(e.target.value), spredeInterestSubCategory: '' })
                                                }}
                                                label={t('superAdmin.Target_Type')}
                                            >
                                                {interests.map((item, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={item._id}>{t(`${item.spredeInterest}`)}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            {error && formValues.spredeInterestCategory === '' &&
                                                <div className="error-text">
                                                    {t('errors.target_category_required')}
                                                </div>
                                            }
                                        </FormControl>

                                    </Col>

                                    <Col md={4} className="newTargetSelect target_title88">
                                        <FormControl fullWidth>
                                            <InputLabel id="target-type">{t('form.Sub_Category')}</InputLabel>
                                            <Select
                                                // MenuProps={{ disableScrollLock: true }}
                                                labelId="target-type"
                                                value={formValues.spredeInterestSubCategory}
                                                onChange={e => setFormValues({ ...formValues, spredeInterestSubCategory: capitalizeFirstLetter(e.target.value) })}
                                                label={t('form.Sub_Category')}
                                            >
                                                {subInterests && subInterests.map((item, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={item._id}>
                                                            {(t(`${item.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) !== `${item.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) ?
                                                                t(`${item.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) :
                                                                t(`${item.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`)
                                                            }
                                                            {/* {item.spredeInterestOptions} */}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            {error && formValues.spredeInterestSubCategory === '' &&
                                                <div className="error-text">
                                                    {t('errors.target_sub_category_required')}
                                                </div>
                                            }
                                        </FormControl>

                                    </Col>
                                    <Col md={4} className="targetInputs target_title88">
                                        <TextField
                                            //InputProps={{endAdornment: <button className="verify_btn verified_btn">{getMeasuringUnitsValue(formValues.spredeInterestSubCategory)}</button>}}
                                            className="mx-0" label={t('form.Target')} placeholder={t('form.Enter_your_target_units')}
                                            value={formValues.targetUnit}
                                            onChange={e => (e.target.value?.length < 10) ? setFormValues({ ...formValues, targetUnit: (e.target.value === '') ? '' : +e.target.value.replace(/[^0-9]/g, '') }) : ''}
                                        />
                                        {error && formValues.targetUnit === '' &&
                                            <div className="error-text">
                                                {t('errors.target_units_required')}
                                            </div>
                                        }
                                    </Col>
                                    <Col md={4} className="targetUnitField target_title88">
                                        <TextField
                                            label={t('form.Enter_your_target_units_measure')} placeholder={t('form.Enter_your_target_units_measure')}
                                            value={getMeasuringUnitsValue(formValues.spredeInterestSubCategory)}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col md={6}
                                        className="targetInputs target_title88">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                closeOnSelect={true}
                                                className="mx-0"
                                                label={t('form.From_Date')}
                                                disabled={targetData === null ? false : true}
                                                minDate={new Date()}
                                                maxDate={new Date(formValues?.toDate).toLocaleDateString('en-US', dateOptions)}
                                                value={formValues.fromDate}
                                                onChange={(newDob) => {
                                                    if(newDob === null){
                                                        setFormValues({ ...formValues, fromDate: new Date() });
                                                    }else {
                                                        setFormValues({ ...formValues, fromDate: newDob });
                                                    } 
                                                }}
                                                InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                                                renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                                                inputFormat="DD MMM YYYY"
                                            />
                                        </LocalizationProvider>
                                        {error && formValues.fromDate === '' &&
                                            <div className="error-text">
                                                {t('errors.target_from_date_required')}
                                            </div>
                                        }
                                    </Col>
                                    <Col md={6} className="target_title88 targetInputs">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                className="mx-0"
                                                closeOnSelect={true}
                                                label={t('form.To_Date')}
                                                minDate={(targetData === null) ? (formValues?.fromDate && formValues?.fromDate > new Date()) ? new Date(formValues?.fromDate).toLocaleDateString('en-US', dateOptions) : new Date().toLocaleDateString('en-US', dateOptions) : new Date(targetData?.toDate).toLocaleDateString('en-US', dateOptions)}
                                                value={formValues.toDate}
                                                onChange={(newDob) => {
                                                    if(newDob === null){
                                                        setFormValues({ ...formValues, toDate: new Date() });
                                                    }else {
                                                        setFormValues({ ...formValues, toDate: newDob });
                                                    }
                                                }}
                                                InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                                                renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                                                inputFormat="DD MMM YYYY"
                                            />
                                        </LocalizationProvider>
                                        {error && formValues.toDate === '' &&
                                            <div className="error-text">
                                                {t('errors.target_to_date_required')}
                                            </div>
                                        }
                                    </Col>

                                    <Col md={8} className="target_textarea90">
                                        <FormTextArea label={t('form.Target_Description')} value={formValues.description}
                                            onChange={e => (getWordCount(e.target.value) <= 200) ? setFormValues({ ...formValues, description: e.target.value }) : ''}
                                            className={"newTargetTextArea"}
                                        />
                                        {error && formValues.description === '' &&
                                            <div className="error-text">
                                                {t('errors.target_description_required')}
                                            </div>
                                        }

                                        {getWordCount(formValues?.description) >= 200 ?
                                            <div className="error-text">
                                                {t('errors.you_can_not_enter_more_than_200_words')}
                                            </div>
                                            : ""
                                        }
                                    </Col>

                                    <Col lg={4} className="targetUploadBox">

                                        {((formValues?.photo && formValues?._id) || showSelected) ? <>
                                            <div className="position-relative">
                                                <label className="user_img_upload uploadFile" htmlFor="upload-photo">
                                                    <input type="file" id="upload-photo"
                                                        accept="image/png, image/gif, image/jpeg"
                                                        onChange={(event) => {
                                                            setPhoto(event.target.files[0]);
                                                        }} />
                                                    <div className="content">
                                                        <img src={showSelected ? URL.createObjectURL(selectedImage) : getImageUrl(formValues?._id, 'target', formValues?.photo)} className="user_attach_upload" />
                                                    </div>
                                                </label>
                                                <img src='/Assets/Close.png' className="closeIconUpload" onClick={() => removeImage()} />
                                            </div>
                                            {errorCover && <p style={{ color: "red" }} className="error-message">{t('Choose_correct_file_according_to_mentioned_size')}</p>}
                                        </>
                                            : <><label className="user_img_upload uploadFile" htmlFor="upload-photo">
                                                <input type="file" id="upload-photo"
                                                    accept="image/png, image/gif, image/jpeg"
                                                    onChange={(event) => {
                                                        setPhoto(event.target.files[0])
                                                    }} />
                                                {/* <div className="content">
                                            <p>{t('form.Upload_Photo')}</p>
                                            <img src="/Assets/Photos Icon-green@2x.png" />
                                        </div> */}
                                                <div className="content addEvent_cover uploadBox">
                                                    <p>{t('form.Upload_Photo')}</p>
                                                    <p>{t('Size')} : 5Kb - 5Mb <br />
                                                        {t('Best_Resolution')} : 800X300 Or 3000X2500
                                                    </p>
                                                    <img src="/Assets/Photos Icon-green@2x.png" />
                                                </div>
                                            </label>
                                                {errorCover && <p style={{ color: "red" }} className="error-message">{t('Choose_correct_file_according_to_mentioned_size')}</p>}
                                            </>
                                        }
                                        {error && formValues.photo === '' && selectedImage === null &&
                                            <div className="error-text">
                                                {t('errors.target_image_required')}
                                            </div>
                                        }
                                    </Col>

                                    <Col md={12}> <span className="formInsideHeading"> {t('incentive')} </span></Col>

                                    <Col md={12} className="newTargetTag">
                                        {incentivesList.length > 0 && incentivesList.map((item, index) =>
                                            <RoundRadio
                                                name="incentive"
                                                key={index}
                                                label={item.label}
                                                value={item.value}
                                                checked={(formValues.incentive === item.value) ? true : false}
                                                onChange={(e) => (e.target.checked) ? setFormValues({ ...formValues, incentive: item.value }) : ''}
                                            />
                                        )}

                                        {error && formValues.incentive === '' &&
                                            <div className="error-text">
                                                {t('errors.target_incentive_required')}
                                            </div>
                                        }
                                    </Col>

                                    {formValues.incentive === 'incentivized' &&
                                        <Col md={12} className="newTargetTag">
                                            {giftsList.length > 0 && giftsList.map((item, index) =>
                                                <RoundRadio
                                                    key={index}
                                                    name="gift"
                                                    label={item.label}
                                                    value={item.value}
                                                    checked={(formValues.incentivePrize === item.value) ? true : false}
                                                    onChange={(e) => handleIncentiveGiftChange(e, item.value)}
                                                />
                                            )}
                                            {error && formValues.incentivePrize === '' &&
                                                <div className="error-text" >
                                                    {t('errors.target_incentive_required')}
                                                </div>
                                            }
                                            <RoundCheckbox
                                                label={t('other')}
                                                other={true}
                                                checked={true}
                                                checkBoxClasses={(otherGift) ? 'otherActive' : ''}
                                                input={
                                                    <input type="text"
                                                        value={otherGift}
                                                        onChange={(e) => {
                                                            setFormValues({ ...formValues, incentivePrize: allowOnlyLetters(e.target.value) })
                                                            setOtherGift(allowOnlyLetters(e.target.value))
                                                        }
                                                        }
                                                        className="w-100 px-2 checkbox_text_feild" placeholder={t('form.Enter_other_prize')} />}
                                            />
                                        </Col>
                                    }

                                    <Col md={4} className="inviteText">
                                        {role === 'organization' ? t('invite') : t('invite_friends')}

                                        <div className="inviteBlock">
                                            <div className="pulsIcon">
                                                <button className="button primary" type="button" onClick={(e) => { e.preventDefault(); handlePopupOpen() }}>+</button>
                                            </div>
                                            <div className="inviteUserBlk">
                                                <div className="inviteUser" onClick={() => { handlePopupInvitedOpen() }} style={{ cursor: 'pointer' }}>
                                                    {/* <span>
                                                <span className="star"></span>
                                                <span className="star1"></span>
                                            </span>
                                            <span>
                                                <span className="star"></span>
                                                <span className="star1"></span>
                                            </span>
                                            <span>
                                                <span className="star"></span>
                                                <span className="star1"></span>
                                            </span>
                                            <span>
                                                <span className="star"></span>
                                                <span className="star1"></span>
                                            </span> */}
                                                    <span>
                                                        <span className="star"><span className="targetNumber">+{formValues?.invites?.length + formValues?.organizationInvites?.length}</span></span>
                                                        <span className="star1"></span>
                                                    </span>
                                                </div>

                                                <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ visibility: 'hidden', position: 'absolute' }}>
                                                    <defs>
                                                        <filter id="roundInvite">
                                                            <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
                                                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                                            <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                                                        </filter>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </Col>

                                    {/* <Col md={4}>
                                <TextField type='text' value={Date.now()} label="Target ID"/>
                            </Col> */}
                                    <Col md={8} className={"createButtonBox"}>

                                        <button type="button" className="button primary createButton" onClick={(e) => { e.preventDefault(); navigate('/target') }}>
                                            {t('cancel')}
                                        </button>

                                        {actionType !== "edit" && 
                                        <button type="button" className="button primary createButton" onClick={resetHandler}>
                                        {t('reset')}
                                    </button>
                                    }

                                        <button type="button" className="button primary createButton" disabled={isLoading} onClick={targetSubmitHandler}>
                                            {targetData === null ? t('form.Create_Target') : t('form.Update_Target')}
                                            {isLoading && <span className="spinner-border" />}
                                        </button>



                                    </Col>
                                </Row>
                            </Box>
                        </div>
                    </div>
                </div>
            </Row>

            <InvitePopup
                popupOpen={popupOpen}
                handleClose={handlePopupClose}
                invites={formValues?.invites}
                orgInvites={formValues?.organizationInvites}
                addInInvitesHandler={addInInvitesHandler}
                removeInvitesHandler={removeInvitesHandler}
            />

            <InvitedPeoplePopup
                popupOpen={popupInvitedOpen}
                handleClose={handlePopupInvitedClose}
                userInvites={userInvites}
                orgInvites={orgInvites}
                removeInvitesHandler={removeInvitesHandler}
            />
        </>);
})

export default AddTarget;
