import React from "react";
import EditUserInterests from "../../../../commonComponents/editUserInterests";
import { Row } from "react-bootstrap";
import { Box } from "@mui/material";
import SaveEditSettingsBtn from "../../../../commonDashboardComp/saveEditSettingsBtn";
import { useTranslation } from "react-i18next";
import { useState } from "react";
const InterestSettings = ({canEdit, onEdit,saveUserDetails,saveNewInterest,formValues,setFormValues,interests, additionalInterest}) => {
    const {t} = useTranslation();
    const [minInterestError, SetMinInterestError] = useState(false);
    const onSave = (e) => {
        e.preventDefault();
        if(formValues?.interests.length!=0){
            SetMinInterestError(false);
            let params = {
                "interest": formValues.interests,
                "spredeInterest" : formValues.interests
            }
            saveUserDetails(params , 'interestInfo')
        }
        else{
            SetMinInterestError(true);
        }
        
    }

    return (<div className="widget-box pb-0">  
    <p className="widget-box-title my-2">
      <SaveEditSettingsBtn canEdit={canEdit} onEdit={onEdit} onSave={onSave} />
      <span className='mb-3'>{t('Interests')}</span>
    </p>
    {
        (minInterestError)?<>
            <div className="error-text">
                <p className="text-danger mb-3">{t('errors.Minimum_Interest_Error')}</p>
            </div>
        </>
        :
        ''
    }
    
        <Box
              component="form"
              sx={{
                  '& .MuiTextField-root': { m: 1, width: '98%' },
              }}
              noValidate
              autoComplete="off"
              className=""
        >
            <Row>
                <EditUserInterests 
                    canEdit={canEdit} 
                    interests={interests}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    saveUserDetails={saveUserDetails}
                    saveNewInterest={saveNewInterest}
                    additionalInterest={additionalInterest}
                />
            </Row>
        </Box>
    </div>)
}

export default InterestSettings;