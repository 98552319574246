import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import Popup from "../commonComponents/popup";
import TabNavigation from "./tabNavigation.jsx";
import GreenCheckBox from "../commonComponents/greenCheckbox/greenCheckbox";
import UserSelector from "./userSelectBox";
import { useSelector } from "react-redux";
import { Row , Col} from "react-bootstrap";
import { selectCurrentUserRole, selectCurrentUser, selectCurrentOrg } from "../Reducer/user/user.selector";

const InvitedPeoplePopup = ({popupOpen, handleClose, userInvites, orgInvites, removeInvitesHandler}) => {
    const {t} = useTranslation();
    const [tab, setTab] = useState(1);

    const role = useSelector(selectCurrentUserRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);

    let current_user = {};
    if(role === 'organization'){   current_user = CURRENTORG     }
    else{ current_user = CURRENTUSER }

    let tabs = [];
    if(role === 'organization'){
        tabs = [
            t('friendsMsg.INVITES') + ' ('+ userInvites.length + ')' , 
            t('friendsMsg.ORG_INVITES')+ ' ('+ orgInvites.length + ')'
        ];
    }
    else{
        tabs = [
            t('friendsMsg.INVITES') + ' ('+ userInvites.length + ')'
        ];
    }   

    const onChangeHandler = (e , id) => {
        removeInvitesHandler(id)  
    }

    const onChangeOrgHandler = (e , id) => {
        removeInvitesHandler(id, 'orgInvites')
    }

    return (<Popup handleClose={handleClose} modalBodyClasses="inviteFriendsPopup" modalContentClasses="inviteFriendsPopupContent" open={popupOpen}>
                <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab} showInput={false} />

                {tab===1 && 
                <>
                        <Row className="ms-2">                            
                            <div className="scrollBar popup_friend_boxes">
                                    {userInvites?.length > 0 && userInvites?.map((item, index)=>{
                                        return (
                                            <UserSelector 
                                            key={index}
                                            user={item}
                                            checked={true}
                                            onChange={onChangeHandler}
                                        />)
                                    })}
                            </div>
                        </Row>
                    
                    {userInvites?.length === 0 && 
                        <>
                            <Col md={{span:6, offset:3}} className="user-status-title text-center">
                                <span className="bold">
                                {t('friendsMsg.RECORD_NOT_FOUND')}
                                </span>
                            </Col>                            
                        </>}
                </>}
                {tab===2 && <>
                        <Row className="ms-2">
                            <div className="col-md-12">
                                <div className="scrollBar popup_friend_boxes">
                                    <Row className="ms-2">
                                        {orgInvites?.length > 0 && orgInvites?.map((item, index)=>{
                                            return (
                                                <UserSelector 
                                                key={index}
                                                user={item}
                                                checked={true}
                                                onChange={(role === 'organization')?onChangeOrgHandler:onChangeHandler}
                                            />)
                                        })}
                                    </Row>
                                </div>
                            </div>
                        </Row>
                    
                    {orgInvites?.length === 0 && 
                        <>
                            <Col md={{span:6, offset:3}} className="user-status-title text-center">
                                <span className="bold">
                                    {t('friendsMsg.RECORD_NOT_FOUND')}
                                </span>
                            </Col>                            
                        </>
                    }
                </>}
                {/* <button className="button primary inviteOkBtn" onClick={handleClose}> {t('proceed')} </button>  */}
            </Popup>)
}

export default InvitedPeoplePopup