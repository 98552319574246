import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { validEmail, passwordRegexValidate } from "../../utils/formatValidator";
import { ForgotPasswordService, ResetPasswordService } from "../../services/auth.services";
import { showSingleToastSuccess, showToastError, showToastSuccess } from "../../utils/helpers";
import ReCAPTCHA from "react-google-recaptcha";
const ForgotPasswordForm = () => {
    const captchaRef = useRef(null);    
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showOtpBox, setShowOtpBox] = useState(false);
    const [counter, setCounter] = useState(0);
    const [email, setEmail] = useState('');
    const [siteKey, setSiteKey] = useState('');
    const [otp, setOTP] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setLoader] = useState(false);
    //for OTP
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(false);
    //forPass
    const [pType, setPtype] = useState('password');
    const [pIcon, setPicon] = useState(false);
    //for Fpass
    const [fpType, setFptype] = useState('password');
    const [fpIcon, setFpicon] = useState(false);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            } else {
                clearInterval(myInterval)
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    }, [counter]);

    const sendOtp = (e) => {
        e.preventDefault();
        setError(false);
        if (email === '' || !validEmail(email)) {
            setError(true); return;
        }
        if(siteKey === ''){
            setError(true); return;
        }
        setLoader(true);
        setSiteKey('');
        captchaRef.current.reset();
        try {
            ForgotPasswordService(email, siteKey)
                .then(async (data) => {
                    if (data?.status === 200) {
                        setCounter(60);
                        // showToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`));
                        showSingleToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`));
                        setShowOtpBox(true);
                    } else {
                        setError(true);
                        showToastError(t(`apiMessages.${data?.data?.data}`))
                    }
                    setLoader(false)
                })
                .catch((error) => {
                    setError(true);
                    showToastError(t(`apiMessages.${error?.data?.data}`));
                });
        }
        catch (error) {
            console.log(error);
        }
        setLoader(false)
    }

    const updatePasswordHandler = (e) => {
        e.preventDefault();
        setError(false);
        if (otp === '' ||
            password.length < 8 ||
            !passwordRegexValidate(password) ||
            password !== repeatPassword) {
            setError(true); return;
        }

        try {
            var params = {
                email: email,
                otp: otp,
                password: password
            }
            ResetPasswordService(params)
                .then(async (data) => {
                    if (data?.status === 200) {
                        // showToastSuccess(t(`apiMessages.PASSWORD_UPDATE_SUCCESS`))
                        showSingleToastSuccess(t(`apiMessages.PASSWORD_UPDATE_SUCCESS`))
                    } else {
                        setError(true);
                        showToastError(t(`apiMessages.${data?.data?.data}`))
                    }
                    setLoader(false)
                })
                .then(() => {
                    navigate('/login');
                })
                .catch((error) => {
                    setError(true);
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                });
        }
        catch (error) {
            console.log(error);
        }
        setLoader(false)
    }
    //for OTP
    const handleOTPView = () => {
        if (type === 'password') {
            setIcon(true);
            setType('text');
        } else {
            setIcon(false);
            setType('password');
        }
    }
    //for Passsword
    const handlePasswordView = () => {
        if (pType === 'password') {
            setPicon(true);
            setPtype('text');
        } else {
            setPicon(false);
            setPtype('password');
        }
    }
    //for Fpasssword
    const handleFpasswordView = () => {
        if (fpType === 'password') {
            setFpicon(true);
            setFptype('text');
        } else {
            setFpicon(false);
            setFptype('password');
        }
    }
    const handleCaptchaChange = (e) =>{
        setSiteKey(e);
    }

    return (
        <div className="form-box login-register-form-element" >
            <h2 className="form-box-title">{t('form.forgot_password')}</h2>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                className="form"
            >
                <div className="login-input-boxes forgetPasswordInputs mb-4">
                    <TextField
                        error={(error && email === '') ? true : (error && !validEmail(email)) ? true : false}
                        helperText={(error && email === '') ? t('errors.email_required') : (error && !validEmail(email)) ? t('errors.email_format') : ''}
                        value={email}
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        label={t('form.label_email')} />
                </div>
                {
                        (error && siteKey == '')?<div className="mt-2 error-text">{'Please check captcha'}</div>:''
                    }
                    <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
                    onChange={handleCaptchaChange}
                    ref={captchaRef}
                    className="mt-3"
                    />
                <div className="form-row">
                    <div className="form-item">
                        <button className="button medium secondary login-account-btn" onClick={sendOtp}>
                            {t('form.send_otp')}
                            {isLoading &&
                                <div style={{ marginTop: "-45px", marginLeft: "45%" }}>
                                    <div className="spinner-border" role="status" />
                                </div>
                            }
                        </button>
                    </div>
                </div>


            </Box>

            {showOtpBox && <><h2 className="form-box-title" style={{ marginTop: '35px' }}>{t('form.submit_otp')}</h2><Box
                component="form"
                noValidate
                autoComplete="off"
                className="form"
                onSubmit={updatePasswordHandler}
            >
                <div className="forgetPasswordInputs">
                    <div className="otp_counter">00:{(counter < 10) ? `0${counter}` : counter}</div>
                    <TextField type={type}
                        error={(error && otp === '') ? true : (error && otp.length < 6) ? true : false}
                        className={"otpInput"}
                        helperText={(error && otp === '') ? t('errors.Enter_OTP') : (error && otp.length < 6) ? t('errors.OTP_is_of_6digits') : ""}
                        value={otp}

                        onChange={(e) => setOTP(e.target.value)}
                        InputProps={{
                            endAdornment: <span className="passwordEye" onClick={handleOTPView} style={{ cursor: 'pointer' }}>
                                {icon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </span>
                        }}
                        label={t('form.lebel_otp')} />
                    {counter === 0 &&
                        <Link className="ps-2" to="#"
                            onClick={(e) => { e.preventDefault(); sendOtp() }}>
                            {t('errors.Resend_Otp')}
                        </Link>
                    }

                    <div className="passwordInputBox">
                        <TextField type={pType}
                            error={(error && password === '') ? true : (error && password.length < 8) ? true : (error && !passwordRegexValidate(password)) ? true : false}
                            helperText={(error && password === '') ? t('errors.password_required') : (error && password.length < 8) ? t('errors.password_length') : (error && !passwordRegexValidate(password)) ? t('errors.password_format') : ''}
                            value={password}
                            className={"passwordInput"}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                endAdornment: <span className="passwordEye" onClick={handlePasswordView} style={{ cursor: 'pointer' }}>
                                    {pIcon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </span>
                            }}
                            inputProps={{ maxLength: 25, minLength: 8 }}
                            label={t('form.label_password')}
                        />
                    </div>

                    <div className="repeatInputBox">
                        <TextField type={fpType}
                            error={(error && repeatPassword === '') ? true : (error && repeatPassword !== password) ? true : false}
                            helperText={(error && repeatPassword === '') ? t('errors.repeat_password_required') : (error && repeatPassword !== password) ? t('errors.repeat_password_wrong') : ''}
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            InputProps={{
                                endAdornment: <span className="passwordEye" onClick={handleFpasswordView} style={{ cursor: 'pointer' }}>
                                    {fpIcon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </span>
                            }}
                            inputProps={{ maxLength: 25, minLength: 8 }}
                            label={t('form.label_repeat_password')} />
                    </div>

                </div>

                <div className="form-row">
                    <div className="form-item">
                        <button className="button medium secondary login-account-btn">
                            {t('form.submit')}
                            {isLoading &&
                                <div style={{ marginTop: "-41px", marginLeft: "60%" }}>
                                    <div className="spinner-border" role="status" />
                                </div>
                            }
                        </button>
                    </div>
                </div>


            </Box></>}
        </div>
    )
}

export default ForgotPasswordForm