import React, { useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import '../dashboard.style.css';
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import ProfileCompletion from "../../../commonDashboardComp/profileCompletion";
import InterestLists from "../../../commonDashboardComp/interestsList";
import AboutMe from "../../../commonDashboardComp/aboutMe";
import PersonalInfo from "../../../commonDashboardComp/personalInfo";
import UserEmployementInfo from "../../../commonDashboardComp/userEmployementInfo";
import UserEducationInfo from "../../../commonDashboardComp/userEducationInfo";
import { Row, Col } from "react-bootstrap";
import { deafaultProfileByUserNameService, defaultProfileGetService, getAnyUserData, postAnyUserData, postAnyUserDataByUserName, postUserCancelRequest } from "../../../services/friends.service";
import { useSelector } from "react-redux";
import { empRejectAcceptRemoveRequest } from "../../../services/follower.service";
import { selectCurrentOrg, selectCurrentOrgRole, selectCurrentUser, selectCurrentUserRole } from "../../../Reducer/user/user.selector";
import { useParams,useLocation, Link } from "react-router-dom";
import { showSingleToastSucces2, showSingleToastSuccess, showToastError, showToastSuccess } from "../../../utils/helpers";
import NavDropdown from 'react-bootstrap/NavDropdown';
import swal from 'sweetalert';
import { getObjectFromStore } from "../../../storage/Storage";
import { useNavigate } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import PostList from "../../../commonDashboardComp/postList";

const FriendProfile = () => {
    const location = useLocation();
    const query=useQuery();

    const { userName } = useParams();
    const navigate = useNavigate();
    const detail=useSelector(selectCurrentUser);
    const CURRENTORGROLE=useSelector(selectCurrentOrgRole);

    const role = useSelector(selectCurrentUserRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);

    let current_user = {};
    if (role === 'organization') { current_user = CURRENTORG }
    else { current_user = CURRENTUSER }

    const [friendData, setFriendData] = useState();
    const { id } = useParams();
    const { t } = useTranslation();
    const userId =id?window.atob(id):''
    const addfrndIcon = '/Assets/Add Friend Button.png';
    const removefrndIcon = '/Assets/Cancel request.png';
    const respondfrndIcon = '/Assets/Respond.png';
    const [userBlocked,setUserBlocked]=useState(false);
    useEffect(() => {
        getUserFriendDetails();
        window.scrollTo(0,0);
    }, [userId]);

    const getUserFriendDetails = async () => {
        if(getObjectFromStore('token')===null){
              const response=await deafaultProfileByUserNameService('user',userName);
              if(response?.status===200){
                setFriendData(response?.data?.data);
              }
       }
    else {
        if(userName){
            const response = await postAnyUserDataByUserName(userName).
            then((response)=>{
                if(response?.status===200){
                    const user = response?.data?.data;
                    setFriendData(user);
                    setUserBlocked(false);
                }  
            })
            .catch((err)=>{
                if(err?.data?.data?.message==='BLOCKED_USER' && err?.data?.data?.isBlockByMe){
                    setUserBlocked(true);
                    let obj=err?.data?.data;
                    obj['_id']=userId;
                    setFriendData(obj);
                }
                else {
                    let obj=err?.data?.data;
                    obj['_id']=userId;
                    setFriendData(obj);
    
                }
                // if(err?.data?.data==='BLOCKED_USER' && err?.data?.data)
            }) 


        }
        else{
        const response = await postAnyUserData(userId).
        then((response)=>{
            if(response?.status===200){
                const user = response?.data?.data;
                setFriendData(user);
                setUserBlocked(false);
            }  
        })
        .catch((err)=>{
            if(err?.data?.data?.message==='BLOCKED_USER' && err?.data?.data?.isBlockByMe){
                setUserBlocked(true);
                let obj=err?.data?.data;
                obj['_id']=userId;
                setFriendData(obj);
            }
            else {
                let obj=err?.data?.data;
                obj['_id']=userId;
                setFriendData(obj);

            }
            // if(err?.data?.data==='BLOCKED_USER' && err?.data?.data)
        })  

       }
      }
    }

    const handleUserRequests = async (action) => {
        // if (friendData?.status === 'active') {
            const params = {
                action: action,
                list: [userId]
            }
            setTimeout(async () => { 
            await postUserCancelRequest(params)
                .then((res) => {
                    if (res) {
                        let SuccessMsg = action === 'cancelRequest' ? t('apiMessages.FRIEND_REQUEST_CANCEL')
                            : action === 'acceptRequest' ? t('apiMessages.FRIEND_REQUEST_ACCEPTED')
                                : action === 'deleteRequest' ? t('apiMessages.FRIEND_REQUEST_DELETED')
                                    : action === 'unFriendRequest' ? t('apiMessages.FRIEND_REMOVED')
                                        : action === 'sendRequest' ? t('apiMessages.FRIEND_REQUEST_SENT')
                                            : action==='blockUserRequest'? t('User_Blocked')
                                            :action==='unBlockUserRequest'? t('User_unBlocked')
                                             :"";

                        //showToastSuccess(SuccessMsg);
                        showSingleToastSuccess(t(SuccessMsg));
                        getUserFriendDetails();
                    }
                })
                .catch((error) => {
                    console.log(error);
                   
                    //showToastError("Some Error Occurred. Please try again later");

                })
            }, 1000)
        // }
        // else {
        //     showToastError(friendData?.firstName + " is not active!");
        // }
        

    }

   const handleOrgRequests=async (actionType)=>{
    //  if(friendData?.status==='active'){
         let param={
            action:actionType,
            list:[userId]
         }
         let swalMessage=actionType==="approve"? t('Are_You_Sure_You_want_to_accept_Employee_Request'):
                         actionType==='reject'? t('Are_You_Sure_You_want_to_Reject_Employee_Request'):
                         actionType==='remove'? t('Are_You_Sure_You_want_to_remove_Employee'):""
         swal({
            text: t(swalMessage),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
        .then((val)=>{
            if (!val) return;
           empRejectAcceptRemoveRequest(param)
         .then((resp)=>{
            if(resp?.status===200){
                let successMessage=actionType==='approve'?t('Employee_Request_Accepted')
                                   :actionType==='reject'?t('Employee_Request_Rejected')
                                   :actionType==='remove'?t('Employee_Removed')
                                   :""
                showSingleToastSuccess(successMessage);
                getUserFriendDetails();
            }
         })
         .catch((err)=>{
            console.log(err);
         })
        })

    //  }
    //  else {
    //     showToastError(friendData?.firstName+" is not active!");
    //  }
      

    }



    function makeButtons() {
        if(getObjectFromStore('token')===null){
           return  <div className="ff-btn-area viewEventBannerBlk">
                <button className="btn successGreenBtns" onClick={()=>navigate('/login')} >{t('form.superAdmin_heading')} </button>
            </div>
        }
        else{
       if(role==='user'){
        return <>
            {current_user?._id === friendData?._id ? '' : <>
            <div className="ff-btn-area viewEventBannerBlk friendsBtnBox">
                            <div className="col-md-12 banner-btn-area d-flex justify-content-end">
                            {!friendData?.hasOwnProperty('isBlockByMe') &&(!friendData?.isFriend && !friendData?.isFriendsRequestSent && !friendData?.isFriendsRequestReceived) ? <div> <button className=""  title={t('friendsMsg.ADD_FRIEND')} onClick={(e) => handleUserRequests('sendRequest')} ><img src={addfrndIcon} className='img-fluid ' /> </button> </div> : ''}
                            {friendData?.isFriend && !userBlocked ? <>
                                <div className="friendsBtn1"> <button className='btn btn-danger text-white bg-danger' title={t('button.block_text')} onClick={(e) => handleUserRequests('blockUserRequest')} >{t('button.block_text')}</button> </div>
                                <div className="friendsBtn2"> <button className='btn btn-danger text-white bg-danger' title={t('friendsMsg.UNFRIEND_USER')} onClick={(e) => handleUserRequests('unFriendRequest')} >{t('friendsMsg.UNFRIEND_USER')}</button>  </div> </> : ''}
                            {friendData?.isFriendsRequestSent ?
                                <div> <button   title={t('friendsMsg.CANCEL_FRIEND_REQUEST')} onClick={(e) => handleUserRequests('cancelRequest')} ><img src={removefrndIcon} className='img-fluid ' /></button> </div> : ''}
                            {userBlocked?
                                <div> <button title={t('button.unblock')} className='btn btn-danger text-white bg-danger'  onClick={(e)=>handleUserRequests('unBlockUserRequest')}>{t('button.unblock')}</button > </div>:''}
                            {friendData?.isFriendsRequestReceived? 
                            
                            <NavDropdown className='iconRight' title={
                                <span className="pull-left">
                                    <img className="thumbnail-image" 
                                        src={respondfrndIcon} 
                                        alt="user pic"
                                    />
                                </span>
                            }  id="navbarScrollingDropdown">
                            <NavDropdown.Item >
                            <button onClick={(e) => handleUserRequests('acceptRequest')} >{t('Confirm')}</button>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item >
                            <button className="friendDeleteBtn" onClick={(e) => handleUserRequests('deleteRequest')} >{t('button.delete')}</button>
                            </NavDropdown.Item>
                            </NavDropdown>:''}
                            </div>
            </div></>}
        </>
       }
       else{
        return <>
        <div className="ff-btn-area viewEventBannerBlk">
                        <div className="col-md-12 banner-btn-area">
                        {friendData?.isVerifiedEmployee && !userBlocked ? <button className="btn btn-danger text-white bg-danger"   onClick={(e) => handleOrgRequests('remove')} >{t('roles.Remove_Employee')}</button> : ''}
                        {friendData?.isEmployeeRequested && !userBlocked ? <> <button className='btn successGreenBtns' title={t('Approve_Employee_Request')} onClick={(e) => handleOrgRequests('approve')} >{t('button.Approve')}</button>   <button className='btn btn-danger text-white bg-danger' title={t('Reject_Employee_Request')} onClick={(e) => handleOrgRequests('reject')} >{t('button.Reject')}</button>  </> : ''}
                        {userBlocked?<button title={t('button.unblock_text')} className='btn btn-danger text-white bg-danger'  onClick={(e)=>handleOrgRequests('unBlockUserRequest')}>{t('button.unblock_text')}</button >:''}
                        </div>
        </div>
    </>
       }
    }
    }
    return (
        <>
            <Row> <ProfileHeader coverImg='' img='' detail={friendData} buttons={(CURRENTORGROLE==='admin' ||  role =='user'  )? makeButtons():''} /> </Row>
          <>{getObjectFromStore('token')===null?<div>
            <h4 className='text-center my-2'></h4>
                <div className="form-item friendsLoginBtnBox mb-3 px-5 mt-5">
                            <button className="login-account-btn friendLoginBtn1" onClick={()=>navigate('/login')}>
                            {t('Login_to_Sprede')}
                            </button>
                    </div>

                    <div className="orText">{t('OR')}</div>
                    
                    <div className="form-item friendsLoginBtnBox mb-5 px-4">
                            <button className="login-account-btn friendLoginBtn2" onClick={()=>{navigate('/register')}}>
                            {t('Join_Sprede')}
                        </button>
                    </div>
            </div>:<>
           {!friendData?.hasOwnProperty('isBlockByMe') &&    
            <div md={12}>
                <Row className="grid grid-3-6-3 mobile-prefer-content">
                    <Col md={3} className="grid-column">
                        <AboutMe userDet={friendData} viewForMore={false} me={false}
                        />

                    </Col>
                    
                    <Col md={6} className="grid-column">
                        <InterestLists userDet={friendData} />
                        <UserEmployementInfo userDet={friendData} />
                        <UserEducationInfo userDet={friendData} />
                        <PostList type="user" id={friendData?._id} />
                    </Col>

                    <Col md={3} className="grid-column">
                        {(friendData?.dob || friendData?.email  || friendData?.email || friendData?.organization?.length>0) &&
                        <PersonalInfo userDet={friendData}
                        />
                         }
                    </Col>

                </Row>
            </div>
           }
            <Row>
          {friendData?.hasOwnProperty('isBlockByMe')&& <Col className='my-3'>{friendData?.isBlockByMe?t('This_User_Blocked_By_You'):t('This_User_Has_Blocked_You')}</Col>}
           </Row>
           </>}
           </>
        </>);
}

export default FriendProfile;
