import React from "react";
import HomeBanner from "../../components/homepage/banner";
import PanchTatva from "../../components/homepage/panchTatva";
import HowItWorks from "../../components/homepage/howItWorks";
import AboutUs from "../../components/homepage/about";
import Features from "../../components/homepage/features";
import Blogs from "../../components/homepage/blogs";
import Testimonials from "../../components/homepage/testimonials";
import NewsLetter from "../../components/homepage/newsletter";
import Footer from "../../components/footer/footer";
import { useEffect, useState } from "react";
import i18next from "i18next";
import { getBlogsService, getCmsServiceList, getTestimonialsService } from "../../services/cms.services";
import { LanguageList } from "../../config/constants";
const Home = () => {
    const defaultLanguage = i18next?.language?.split('-')[0];
    const find = LanguageList?.find(a => a.value === defaultLanguage);

    const [language, setLanguage] = useState((find?.name) ? find : LanguageList[0])
    const [cmsData, setcmsData] = useState([])
    const [blogs, setBlogs] = useState([]);
    const [currentBlogPage, setCurrentBlogPage] = useState(0);
    const limit = 3;
    const length = 20;
    const [testimonials , setTestimonials] = useState([]);
    const [currentTestimonialPage, setCurrentTestimonialPage] = useState(0);

    useEffect(() => {
        let filter = LanguageList?.find(a => a.value === defaultLanguage);

        setLanguage((filter?.name) ? filter : LanguageList[0])
    }, [defaultLanguage])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    useEffect(() => {
        const handleLanguageChange = () => {

            let defaultLanguage1 = i18next?.language?.split('-')[0];

            let filter = LanguageList?.find(a => a.value === defaultLanguage1);

            setLanguage((filter?.name) ? filter : LanguageList[0])

        };

        i18next.on("languageChanged", handleLanguageChange);

        return () => {
            i18next.off("languageChanged", handleLanguageChange);
        };
    }, []);

    useEffect(() => {
        if (language !== null) { getCmsData(); }
    }, [language]);
    useEffect(() => {
        if (language !== null) { getBlogs(); }
    }, [language]);
    useEffect(() => {
        if (language !== null) { getTestimonials(); }
    }, [language]);



    const getCmsData = async () => {
        getCmsServiceList(language?.value)
            .then((data) => {
                if (data?.data?.status === 200) {
                    const _data = data.data.data;
                    setcmsData(_data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getBlogs = () => {
        getBlogsService(currentBlogPage, limit, language?.value)
            .then((response) => {
                if (response.data.status === 200) {
                    setBlogs(response?.data?.data?.res)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getTestimonials = () => {
        getTestimonialsService(currentTestimonialPage, length, language?.value)
        .then((response) => {
            if (response.data.status === 200) {
                setTestimonials(response?.data?.data?.res)
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    return (<>
        <HomeBanner cmsData={cmsData}/>
        <PanchTatva cmsData={cmsData}/>
        <HowItWorks cmsData={cmsData}/>
        <AboutUs cmsData={cmsData} />
        <Features cmsData={cmsData} />
        {blogs.length>0 && <Blogs blogs={blogs} cmsData={cmsData}/>}
        {(testimonials?.length>6 || cmsData?.partners?.length>0) && <Testimonials testimonials={testimonials}cmsData={cmsData}/>}
        <NewsLetter />
        <Footer cmsData={cmsData} />
    </>
    );
}

export default Home;