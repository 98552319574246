import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const GlobalSideBar = () => {
  const { t } = useTranslation();
  const path_name = window.location.pathname;
  return (
    <div className="sidebar-box no-padding">
      <div className="sidebar-menu">
        <Link to="/allGlobalSearchComp" className={`sidebar-menu-item ${path_name === '/allGlobalSearchComp' ? " selected" : ""}`}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <img src="/Assets/All_Search.png" alt="" className="sidebar-menu-header-icon icon-profile" />
            <p className="sidebar-menu-header-title">{t('roles.All')}</p>
          </div>
        </Link>

        <Link to="/allGlobalUserSearchComp" className={`sidebar-menu-item ${path_name === '/allGlobalUserSearchComp' ? " selected" : ""}`}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <img src="/Assets/User_search.png" alt="" className="sidebar-menu-header-icon icon-profile" />
            <p className="sidebar-menu-header-title">{t('form.Users')}</p>
          </div>


        </Link>
        <Link to="/allGlobalOrgSearchComp" className={`sidebar-menu-item ${path_name === '/allGlobalOrgSearchComp' ? " selected" : ""}`}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <img src="/Assets/Search-ORG.png" alt="" className="sidebar-menu-header-icon icon-profile" />
            <p className="sidebar-menu-header-title">{t('form.Organization')}</p>
          </div>
        </Link>
        <Link to="/allGlobalEventSearchComp" className={`sidebar-menu-item ${path_name === '/allGlobalEventSearchComp' ? " selected" : ""}`}>
          <div className="sidebar-menu-header accordion-trigger-linked">
            <img src="/Assets/Search_Event.png" alt="" className="sidebar-menu-header-icon icon-profile" />
            <p className="sidebar-menu-header-title">{t('form.Events')}</p>
          </div>
        </Link>
      </div>

    </div>
  );
}

export default GlobalSideBar;