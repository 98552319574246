import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import GreenCheckBox from "../../commonComponents/greenCheckbox/greenCheckbox";
import { LoginService, getUserDetailsService, getIPAddressService } from "../../services/auth.services";
import { showToastError, showToastSuccess } from "../../utils/helpers";
import { useDispatch } from "react-redux";
import { setCurrentUser, setCurrentRole } from "../../Reducer/user/user.action";
import { removeStoreItem, setObjectInStore, getObjectFromStore } from "../../storage/Storage";
import { validEmail, passwordRegexValidate } from "../../utils/formatValidator";
import { browserName, isMobile } from "react-device-detect";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import SocialLogins from "../socialLogins/socialLogins";
import ReCAPTCHA from "react-google-recaptcha";
const LogInForm = () => {
    const captchaRef = useRef(null);    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginFormRef = useRef(null)
    const [formValues, setFormValues] = useState({ email: '', password: '' })
    const [ipData, setIpData] = useState({});
    const [error, setError] = useState(false);
    const [isLoading, setLoader] = useState(false);
    const [remember, setRemember] = useState(false);
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(false);

    useEffect(() => {
        loginFormRef.current.scrollIntoView()
    }, [])

    useEffect(() => {
        getIpData();
    }, [])

    const getIpData = () => {
        try{
            getIPAddressService()
            .then((data) => {
                setIpData(data?.data)
            })
            .catch((error) => {
                console.log(error);
            })
        }
        catch(e){
            console.log(e);
        }   
    }



    useEffect(() => {
        let rememberMeObj = getObjectFromStore('rememberMe');
        if (rememberMeObj !== null && rememberMeObj !== undefined) {
            setFormValues({ ...formValues, email: rememberMeObj?.email, password: rememberMeObj?.password });
            setRemember(true);
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formValues.email === '' || formValues.password === '') { setError(true); return; }
        else if (!validEmail(formValues.email)) { setError(true); return }
        else if (formValues.password.length < 8) { setError(true); return }
        else if (!formValues.siteKey || formValues.siteKey == '') {setError(true); return}

        setLoader(true)
        try {
            let params = {
                "loginId": formValues.email,
                "password": formValues.password,
                "ipAddress": ipData?.IPv4,
                "location": ipData?.country_name,
                "browser": browserName,
                "siteKey": formValues.siteKey,
                "device": (isMobile) ? 'Mobile' : 'Desktop'
            }
            setFormValues({...formValues, siteKey:''});
            captchaRef.current.reset();
            LoginService(params)
                .then(async (data) => {
                    if (data.status === 200) {
                        let rememberMeObj = {
                            email: formValues.email.toLowerCase(),
                            password: formValues.password,
                            rememberMe: remember,
                            token: data?.data?.data?.accessToken
                        }
                        setObjectInStore("token", data?.data?.data?.accessToken);
                        setObjectInStore('refresh_token', data?.data?.data?.refreshToken);
                        getLoginUserDetails();
                        if (remember) {
                            setObjectInStore("rememberMe", rememberMeObj)
                        } else {
                            removeStoreItem("rememberMe")
                        }
                    } else {
                        setError(true);
                        showToastError(t(`apiMessages.${data.data.data}`))
                    }
                    setLoader(false)
                })
                .catch((error) => {
                    setError(true);
                    if (error?.data?.data !== undefined) {
                        showToastError(t(`apiMessages.${error?.data?.data}`))
                        console.log("Error::",error?.data)
                    }
                    else {
                        showToastError(t(`errors.some_error`))
                    }
                });
        }
        catch (error) {
            console.log(error);
        }
        setLoader(false)
    }

    const getLoginUserDetails = async () => {
        const response = await getUserDetailsService();
        if (response?.data?.data) {
            const user = response?.data?.data
            dispatch(setCurrentUser(user));
            dispatch(setCurrentRole('user'));
            navigate('/voxbox');
        }
    }

    const handlePassView = () => {
        if (type === 'password') {
            setIcon(true);
            setType('text');
        } else {
            setIcon(false);
            setType('password');
        }
    }
    const handleCaptchaChange = (e) =>{
        setFormValues({...formValues, siteKey: e});
    }
    return (
        <div id="loginForm" className="landing-form" ref={loginFormRef}>

            <div className="form-box login-register-form-element">
                <h2 className="form-box-title">{t('form.login_form_heading')}</h2>


                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '98%' },
                    }}
                    noValidate
                    autoComplete="off"
                    className="form"
                    onSubmit={handleSubmit}
                >
                    <div className="login-input-boxes homePageLoginInputs">
                        <div id="loginInputUserName" className="loginUsernameBox">
                            <TextField
                                error={(error && formValues.email === '') ? true : (error && !validEmail(formValues.email)) ? true : false}
                                helperText={(error && formValues.email === '') ? t('errors.email_required') : (error && !validEmail(formValues.email)) ? t('errors.email_format') : ''}
                                value={formValues.email}
                                onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                label={t('form.label_email')} />
                        </div>
                        <div id="loginInputPass" className="loginPasswordBox">
                            <TextField
                                error={(error && formValues.password === '') ? true : (error && formValues.password.length < 8) ? true : false}
                                helperText={(error && formValues.password === '') ? t('errors.password_required') : (error && formValues.password.length < 8) ? t('errors.password_length') : ''}
                                type={type}
                                value={formValues.password}
                                onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                                InputProps={{
                                    endAdornment: <span className="passwordEye" onClick={handlePassView} style={{cursor:'pointer'}}>
                                        {icon?<VisibilityIcon/>:<VisibilityOffIcon/>}
                                    </span>
                                }}
                                label={t('form.label_password')} />

                        </div>
                    </div>

                    <div className="form-row space-between">
                        <GreenCheckBox
                            label={t('form.label_remember_me')}
                            onChange={(e) => setRemember(e.target.checked)}
                            checked={(remember) ? true : false}
                        />

                        <div className="form-item">
                            <Link to="/forgot-password" className="form-link pe-4">{t('form.forgot_password')}</Link>
                        </div>
                    </div>
                    {
                        ((error && !formValues.siteKey) || (error && formValues.siteKey == ''))?<div className="mt-2 error-text">{'Please check captcha'}</div>:''
                    }
                    <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
                    onChange={handleCaptchaChange}
                    ref={captchaRef}
                    />
                    <div className="form-item mb-0">
                        <button className="button medium secondary login-account-btn">
                            {t('form.login_account')}
                            {isLoading &&
                                <div style={{ marginLeft: "60%" }}>
                                    <div className="spinner-border" role="status" />
                                </div>
                            }
                        </button>

                        <p className="sign-up-text"><Link to="/register">{t('form.sign_in')}</Link></p>
                    </div>

                </Box>

                <SocialLogins />
            </div>
        </div>
    )
}

export default LogInForm;