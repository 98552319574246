import React from "react";
import { useTranslation } from "react-i18next";
import { Link , useNavigate} from "react-router-dom";
import { shortDayDateFormat, timeFormat12Hr, AmPm12Hr, timeFormat, dateTimeEventFormat } from "../utils/helpers";
const TodayEventList = ({date, events}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const viewEventHandler = (id) => {
      navigate('/view-event', {state:{eventId:id}})
    }
    return (<div className="event-page-preview">
    <p className="calendar-events-preview-title">{shortDayDateFormat(date)}</p>

    <div className="calendar-event-preview-list">
      {events && events.length > 0 ? <>
        {events.map((item, index)=>{
          return (<div className="calendar-event-preview secondary" onClick={()=>viewEventHandler(item?._id)} key={index}>
                    <div className="calendar-event-preview-start-time">
                      <p className="calendar-event-preview-start-time-title">{timeFormat12Hr(dateTimeEventFormat(item?.startDate,item?.startTime))}</p>
                      <p className="calendar-event-preview-start-time-text">{AmPm12Hr(dateTimeEventFormat(item?.startDate,item?.startTime))}</p>
                    </div>
                    <div className="calendar-event-preview-info">
                      <p className="calendar-event-preview-title popup-event-information-trigger">{item?.title}</p>
                      <p className="calendar-event-preview-text">{item?.description.substring(0, 20)+'..'}</p>
                      {/* <p className="calendar-event-preview-time"><span className="bold">{timeFormat12Hr(item?.startTime)}</span> {AmPm12Hr(item?.startTime)}</p> */}
                    </div>
                  </div>)
        })}
      </>:
        <div className="calendar-event-preview secondary">          
          <div className="calendar-event-preview-info">
            <p className="calendar-event-preview-title popup-event-information-trigger">{t('no_event')}</p>
          </div>
        </div>
      }
    </div>
  </div>)
}

export default TodayEventList;