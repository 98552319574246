import React, {useEffect, useState} from "react";
import { GetPostByIdService } from "../../../services/post.services";
import PostBox from "../../../commonDashboardComp/postBox";
import { useLocation } from "react-router-dom";
import OrgFewList from "../../../commonDashboardComp/orgFewList";
import EventCalender from "../../../commonDashboardComp/eventCalender";
import TodayEventList from "../../../commonDashboardComp/todayEventsList";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../../Reducer/user/user.selector";
import TargetBox from "../../../commonDashboardComp/targetBox";
import { getEventDetailByDateService } from "../../../services/event.services";
import { changeDateFormatYYYY } from "../../../utils/helpers";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PostView = () => {
    const { t } = useTranslation();
    const role = useSelector(selectCurrentUserRole);
    const [eventByDate, setEventsByDate] = useState({});
    const [date, setDate] = useState(changeDateFormatYYYY(new Date()));
    const [eventDate, setEventDate] = useState(new Date())
    const location = useLocation();
    const postId = location?.state?.postId;
    const [postData, setPostData] = useState({})

    useEffect(()=>{
        getPostData();
    }, [postId])

    const getPostData = () => {
        if(postId !==  null || postId !== undefined)
        {
            GetPostByIdService(postId)
            .then((data)=>{
                if(data?.data?.status === 200){
                    setPostData(data?.data?.data[0]);
                }
            })
            .catch((error)=>{
                console.log(error);
            })
        }
    }

    useEffect(()=>{
      getEventByDate();
    }, [date])

    const getEventByDate = () => {
      getEventDetailByDateService(date)
      .then((data)=>{
        if(data?.data?.status === 200){
          setEventsByDate(data?.data?.data);
        }
      })
    }

    const setEventDateHandler = (d) => {
      setEventDate(d);
      setDate(changeDateFormatYYYY(d))
    }

    return (
        <Row className="grid grid-3-6-3 mobile-prefer-content updateColWidth">
            <Col lg={3} className="col-md-12 mobile_margin_btm">
                {(role === 'organization' ||  role==='superAdmin')&& <TargetBox/>}
                {(role === 'user' ) && 
                <>
                <EventCalender date={eventDate} setDate={setEventDateHandler}/>
                <TodayEventList date={date} events={eventByDate}/>
                </>}
            </Col>
      

            <Col lg={6} className="col-md-12">
                {postData?._id && <PostBox post={postData} />}   

                <Row className="mt-4">                
                    <Link to="/voxbox" className=" button secondary">{t('View_All_Posts')}</Link>
                </Row> 
            </Col>

            <Col lg={3} className="col-md-12 mobile_margin_top">
                {(role === 'organization' || role==='superAdmin') && <>
                <EventCalender date={eventDate} setDate={setEventDateHandler}/>
                <TodayEventList  date={date} events={eventByDate}/></>}
                {(role === 'user') && <OrgFewList/>}
            </Col>
        </Row>
    )
}

export default PostView;