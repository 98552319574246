import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Row } from "react-bootstrap";
import { t } from "i18next";
import { restrictForwardAndBackwardSlash } from "../utils/helpers";
const TabNavigation = ({tabs, 
    setTab, activeTab,
    tabClass,
    onChangeSearchText,value, 
    inputPlaceholder ,showInput=true, 
    button, sectionMenuClasses, 
    sectionMenuItemsClasses,
    moreTabs, id
}) => {
    return(
        <div className = {tabClass}>
            <nav className="section-navigation">
                <div className="search_menu_control newSearchIcon friendsSearch">
                    {showInput && <div className="search-icon invitePoupBlock">
                    <TextField type='text' label={inputPlaceholder}
                         onChange={(e)=>onChangeSearchText(restrictForwardAndBackwardSlash(e.target.value))}
                         value={value}
                         InputProps={{endAdornment: <Button className="button secondary"><SearchIcon style={{fill:"#fff"}}/></Button>}}/>
                    </div>}
                    <div className="">
                    <div className="tns-outer" id="section-navigation-slider-ow">
                    <div id="section-navigation-slider-mw" className="tns-ovh">
                        <div className="tns-inner likesPopupSlider dropdown show" id="section-navigation-slider-iw">
                            <div id="section-navigation-slider" className={`section-menu tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal ${sectionMenuClasses}`}>
                            {tabs.length >0 && tabs.map((tab, index) => {
                                return <Link to="#" key={index} className={`section-menu-item tns-item ${(activeTab === index+1)?"active":""} ${sectionMenuItemsClasses}`} onClick={(e)=>{e.preventDefault(); setTab(index==0?1:index+1)}}>
                                    <p className="friendsTabs likesPopupEmoji">
                                        {tab}
                                    </p>
                                </Link>
                            })}

                            {moreTabs && moreTabs?.length > 0 && <>
                                    <Link to="#" className={`dropdown-toggle section-menu-item tns-item  ${(activeTab > tabs.length+1)?"active":""} ${sectionMenuItemsClasses}`} id={id} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <p className="friendsTabs likesPopupEmoji">
                                            {t('More')}
                                        </p>
                                    </Link> 

                                    <div className="dropdown-menu" aria-labelledby={id} >
                                        {moreTabs.map((mtab, i) => {
                                            return (
                                                <a className="dropdown-item" href="#" key={i}
                                                onClick={(e)=>{e.preventDefault(); setTab(tabs.length+i+1)}}
                                                >{mtab}</a>
                                            )
                                        })}
                                    </div>
                                </>
                            }

                           
                            </div>
                        </div>
                    </div>
                </div>
                    </div>

                    {button &&
                        <div className="target_add_new_btn">
                            {button}
                        </div>
                    }
                </div>   
            </nav>
        </div>
    );
}

export default TabNavigation;

TabNavigation.defaultProps={sectionMenuItemsClasses:"", sectionMenuClasses:""}