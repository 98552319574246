import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import Popup from "../commonComponents/popup";
import { useTranslation } from "react-i18next";
import { GetPostByIdService, EditPostService, getTagSuggestionsService } from "../services/post.services";
import { uploadFileService } from "../services/fileUpload.services";
import BarsLoader from "../commonComponents/barsLoader";
import { getImageUrl } from "../utils/helpers";
import Video from "../commonComponents/video";
import { showSingleToastSuccess, showToastError } from "../utils/helpers";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Row, Col } from "react-bootstrap";

import { EditorState, convertToRaw, ContentState, convertFromRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
    defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';
import { AvatarImg } from "../commonComponents/Images";
import editorStyles from './SimpleMentionEditor.module.css';
import '@draft-js-plugins/mention/lib/plugin.css';

const EditPostPopup = ({ open, handleClose, postId, loadPostData }) => {
    const max_length = 1000;
    const [formSaving, setFormSaving] = useState(false);
    const [postData, setPostData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [originalData, setOriginalData] = useState();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [error, setError] = useState(false);
    const { t } = useTranslation();
    const [tags, setTags] = useState([])

    //DRAFT JS 
    const ref = useRef(null);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );


    useEffect(() => {
        let raw = convertToRaw(editorState.getCurrentContent())
        setPostData({ ...postData, post: raw.blocks[0].text.trim() })
    }, [editorState])

    const [optionsOpen, setOptionsOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin();
        // eslint-disable-next-line no-shadow
        const { MentionSuggestions } = mentionPlugin;
        // eslint-disable-next-line no-shadow
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const onOpenChange = useCallback((_open) => {
        setOptionsOpen(_open);
    }, []);
    const onSearchChange = useCallback(({ value }) => {
        if (!value) return;
        getTagSuggestionsService(value)
            .then((res) => res?.data?.data?.data)
            .then((res) => {
                let data = []
                res.map((user) => data.push({
                    _id: user?._id,
                    name: `${user.firstName} ${user.lastName}`,
                    link: `/profile/` + window.btoa(user?._id),
                    avatar: user?.profilePicture !== '' ? getImageUrl(user?._id, 'userProfile', user?.profilePicture) : AvatarImg
                }))
                setSuggestions(data);
            })
    }, []);
    //********* */

    useEffect(() => {
        getPostDataById();
    }, [open])

    const getPostDataById = () => {
        setIsLoading(true)
        GetPostByIdService(postId)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setPostData(data?.data?.data[0]);
                    setTags(data?.data?.data[0]?.tags)
                    setOriginalData(data?.data?.data[0]);
                    if (JSON.stringify(data?.data?.data[0]?.postMentions) === '{}') {
                        setEditorState(EditorState.createWithContent(ContentState.createFromText(data?.data?.data[0]?.post)))
                    }
                    else {
                        setEditorState(EditorState.createWithContent(convertFromRaw(data?.data?.data[0]?.postMentions)))
                    }
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const fetchTagSuggestions = (query, callback) => {
        if (!query) return;
        getTagSuggestionsService(query)
            .then((res) => res?.data?.data?.data)
            .then((res) =>
                res.map((user) => ({ display: `${user.firstName} ${user.lastName}`, id: `${user._id}` }))
            )
            .then(callback);
    }

    const addTag = (id) => {

        //Check if ID is already mentioned
        let filter = tags.filter((item) => item === id)
        if (filter.length > 0) { return; }
        let arr = tags;
        arr.push(id);
        setTags(arr)
    }

    const uploadFiles = async () => {
        await Promise.all(selectedFiles.map(async (item) => {
            if (item.type.includes('video')) {
                await uploadFileService('voxBoxVideo', 'voxBox', item.lastModified, item, postData?._id)
            }
            if (item.type.includes('image')) {
                await uploadFileService('voxBoxImage', 'voxBox', item.lastModified, item, postData?._id)
            }
        }))
        return true;
    }

    const setPhoto = async (param) => {
        if (param.length !== 0) {
            setSelectedFiles([...selectedFiles, ...param]);
        }
    }

    const deleteSelectedFile = (e, i) => {
        e.preventDefault();
        let arr = selectedFiles;
        let filter = arr.filter((item, index) => { return index !== i });
        setSelectedFiles(filter)
    }

    const deleteMedia = (e, i, type) => {
        e.preventDefault();
        let arr = postData[type];
        let filter = arr.filter((item) => { return item !== i });
        if (type === 'image') {
            setPostData({ ...postData, image: filter })
        }
        else {
            setPostData({ ...postData, video: filter })
        }
    }
    const changeDetection = (param) => {
        const propertyNames = Object.keys(param);
        for (let i = 0; i < propertyNames?.length; i++) {
            if (JSON.stringify(originalData[propertyNames[i]]) !== JSON.stringify(param[propertyNames[i]])) {
                return true;
            }
        }
        return false;
    }
    const editPostSubmitHandler = (e) => {
        setError(false)
        if (postData?.post?.trim() === '') {
            setError(true); return;
        }
        setFormSaving(true);
        let raw = convertToRaw(editorState.getCurrentContent())
        let mentioned_users = []

        for (let key in raw.entityMap) {
            const ent = raw.entityMap[key]
            if (ent.type === 'mention') {
                mentioned_users.push(ent.data.mention._id)
            }
        }

        try {
            let photos = (postData?.image !== null && postData?.image?.length > 0) ? postData?.image : []
            let videos = (postData?.video !== null && postData?.video?.length > 0) ? postData?.video : []

            if (selectedFiles.length > 0) {
                selectedFiles.map((item, index) => {
                    if (item.type.includes('video')) {
                        videos.push(item.lastModified)
                    }
                    if (item.type.includes('image')) {
                        photos.push(item.lastModified)
                    }
                })
            }
            let params = {
                postId: postData?._id,
                post: postData?.post,
                postVisibility: postData?.postVisibility,
                image: photos,
                video: videos,
                tags: mentioned_users,
                postMentions: raw,
            }

            EditPostService(params)
                .then(async (data) => {
                    if (data?.data?.status === 200) {
                        if (selectedFiles.length > 0) {
                            let result = await uploadFiles(data?.data?.data?.insertedId);
                            if (result) {
                                // showToastSuccess(t('apiMessages.TARGET_UPDATE_SUCCESS'));
                                showSingleToastSuccess(t('apiMessages.POST_UPDATE_SUCCESS'));

                            }
                        }
                        else {
                            if (changeDetection(postData)) {
                                showSingleToastSuccess(t(`apiMessages.POST_UPDATE_SUCCESS`))

                            }
                            else {
                                showSingleToastSuccess("No changes have been made")
                            }
                        }
                        loadPostData();
                    }
                    else {
                        showToastError(t(`apiMessages.${data?.data?.data}`))
                    }

                })
                .catch((error) => {
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        catch (error) {
            setFormSaving(false);
            console.log(error);
        }
    }

    return (<Popup open={open} handleClose={handleClose} modalHeadingClass={"editPostHeading"} modalBodyClasses={"editPostModal"} heading={t('edit_post')} className="targetPopup">
        {
            isLoading ? <BarsLoader /> :
                <>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '98%' },
                        }}
                        noValidate
                        autoComplete="off"
                        className="quick-post scrollBar"
                    >

                        <Row>
                            <Col lg={4} className="editPostVisibilityInput">
                                <FormControl fullWidth className="comment">
                                    <InputLabel id="co-country-select-label">
                                        {t('postVisibility')}
                                    </InputLabel>
                                    <Select
                                        labelId="co-country-select-label"
                                        value={postData.postVisibility}
                                        label={t('postVisibility')}
                                        sx={{
                                            textAlign: 'left',
                                        }}
                                        onChange={(e) => setPostData({ ...postData, postVisibility: e.target.value })}
                                    >
                                        <MenuItem value="everyone">{t('everyone_public')}</MenuItem>
                                        <MenuItem value="friends">{t('friends')}</MenuItem>
                                        <MenuItem value="private">{t('private')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col lg={12} className="editPostTextArea">
                                <div
                                    className={editorStyles.editor}
                                    onClick={() => {
                                        ref.current.focus();
                                    }}
                                >
                                    <Editor
                                        editorKey={'editor'}
                                        editorState={editorState}
                                        onChange={setEditorState}
                                        plugins={plugins}
                                        ref={ref}
                                        placeholder={t('Enter_some_post_text')}
                                    />
                                    <MentionSuggestions
                                        open={optionsOpen}
                                        onOpenChange={onOpenChange}
                                        suggestions={suggestions}
                                        onSearchChange={onSearchChange}
                                        onAddMention={() => {
                                            // get the mention object selected
                                        }}
                                    />
                                </div>
                                {error && postData?.post === '' && <div className="error-text"> {t('errors.post_desc_required')} </div>}
                            </Col>
                        </Row>

                        <div className="quickPostContainer">
                            {postData?.image !== null && postData?.image?.length > 0 &&
                                postData?.image?.map((img, i) => {
                                    return (
                                        <div className="quickPostItems editPostItems" key={i}>
                                            <div className="photo-preview quickPost">
                                                <img src={getImageUrl(postData?._id, 'voxBoxImage', img)} alt="" />
                                                <button className="deleteImgBtn"
                                                    onClick={(e) => deleteMedia(e, img, 'image')}
                                                >&times;</button>
                                            </div>
                                        </div>)
                                })
                            }

                            {postData?.video !== null && postData?.video?.length > 0 &&
                                postData?.video?.map((vid, i) => {
                                    return (
                                        <div className="quickPostItems" key={i}>
                                            <div className="photo-preview quickPost">
                                                <video src={getImageUrl(postData?._id, 'voxBoxVideo', vid)} alt="" />
                                                <button className="deleteImgBtn"
                                                    onClick={(e) => deleteMedia(e, vid, 'video')}
                                                >&times;</button>
                                            </div>
                                        </div>)
                                })
                            }

                            {selectedFiles.map((item, index) => {
                                return (
                                    <div className="quickPostItems" key={index}>
                                        <div className="photo-preview quickPost">
                                            {
                                                item.type.includes('video') ?
                                                    <video src={URL.createObjectURL(item)} alt="" />
                                                    :
                                                    <img src={URL.createObjectURL(item)} alt="" />
                                            }

                                            <button className="deleteImgBtn"
                                                onClick={(e) => deleteSelectedFile(e, index)}
                                            >&times;</button>
                                        </div>
                                    </div>)
                            })}
                        </div>
                    </Box>

                    <hr />
                    <div className="editPostBtn">
                        <div>
                            <button className="inviteOkBtn editPostUpdateBtn" onClick={editPostSubmitHandler} disabled={formSaving}>
                                {t('update')}
                                {formSaving && <span className="spinner-border" />}
                            </button>
                        </div>
                        <div className="editPostBtnBox">
                            <label className="uploadChooseBtn editPostChooseBtn">
                                <input type="file" id="upload-more" multiple
                                    accept="image/*,image/heif,image/heic,video/*,video/mp4,video/x-m4v,video/x-matroska,.mkv"
                                    onChange={(event) => {
                                        setPhoto(event.target.files)
                                    }}
                                />
                            </label>
                        </div>


                    </div>

                </>
        }
    </Popup>)
}

export default EditPostPopup;