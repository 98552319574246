import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAllEvents } from "../services/onBoarding.services";
import BarsLoader from "../commonComponents/barsLoader";
import RunningEvents from "./runningEvents";
import AllEvents from "./allEvents";
import ReactPagination from "../commonComponents/reactPagination";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const EventsListing = ({ searchText = '', tab, isPaid, role, current_user, userOrg, search = 'event', reload, AcceptRejectEventHandler, AcceptRejectPaidEventHandler, acceptValidationRequest, rejectValidationRequest }) => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([])
  const { t } = useTranslation();

  const limit = 10;
  const [isLoading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const [Listtype, setType] = useState('');

  useEffect(() => {
    setEvents([]);
    getEventsListing();
  }, [tab, isPaid, searchText, page, reload])
  const getEventDateTime = (data) => {
    let startDateTime = new Date(data?.startDate);
    let startHour, startMinutes;
    [startHour, startMinutes] = data?.startTime.split(':');
    startDateTime.setHours(startHour);
    startDateTime.setMinutes(startMinutes);
    return startDateTime;

  }

  const getEventsListing = () => {
    let type = ''
    if (search === 'event') {
      if (tab === 1) { type = 'allEvent'; }
      else if (tab === 2) { type = (role === 'user' && (current_user?.pages?.length > 0 || userOrg?.organizationDetail && userOrg?.isVerified)) ? 'userOrganizationEvent' : 'myEvent' }
      else if (tab === 3) { type = (role === 'user' && (current_user?.pages?.length > 0 || userOrg?.organizationDetail && userOrg?.isVerified)) ? 'organizationParticipantEvent' : 'myParticipatedEvent' }
      else if (tab === 4) { type = (role === 'user' && (current_user?.pages?.length > 0 || userOrg?.organizationDetail && userOrg?.isVerified)) ? 'myEvent' : 'myCompletedEvent' }
      else if (tab === 5) { type = (role === 'user' && (current_user?.pages?.length > 0 || userOrg?.organizationDetail && userOrg?.isVerified)) ? 'myParticipatedEvent' : 'upComing' }
      else if (tab === 6) { type = (role === 'user' && (current_user?.pages?.length > 0 || userOrg?.organizationDetail && userOrg?.isVerified)) ? 'myCompletedEvent' : (role === 'organization') ? 'pendingOrganizationInvites' : 'pendingUserInvites' }
      else if (tab === 7) { type = 'upComing' }
      else if (tab === 8) { type = 'pendingUserInvites' }
    }
    else {
      if (tab === 1) { type = 'validatorRunningEventList'; }
      else if (tab === 2) { type = 'validatorUpcomingEventList' }
      else if (tab === 3) { type = 'validatorCompletedEventList' }
      else if (tab === 4) { type = 'pendingEventRequestList' }
    }
    setType(type);
    setLoading(true)
    getAllEvents(page, limit, type, searchText, isPaid)
      .then((response) => {
        if (response.data.status === 200) {
          setEvents(response.data.data.data)
          let pageCount = (response?.data?.data?.totalCount) / limit;
          setTotalPage(Math.ceil(pageCount));
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const handlePageClick = (data) => {
    let Page = data.selected;
    setPage(Page);
    // getEventsListing();
  }
  return <>
    {isLoading ? <BarsLoader /> : events.length > 0 ?
      <Row className="">
        {

          events.length > 0 && <>
            {events.map((item, i) => (
              <div className="col-lg-12 col-md-6 col-12 m-0 mb-2" key={i}>

                {Listtype === 'allEvent' || Listtype === 'userOrganizationEvent' || (Listtype === 'upComing' && (getEventDateTime(item) > new Date())) || Listtype === 'organizationParticipantEvent' || Listtype === 'myParticipatedEvent' ?

                  <AllEvents
                    AllList={item}
                    className='widget-box'
                  />
                  :
                  Listtype !== 'upComing' ?
                    ((Listtype === 'validatorUpcomingEventList' && (getEventDateTime(item) > new Date())) || Listtype === 'validatorRunningEventList' || Listtype === 'validatorCompletedEventList' || Listtype === 'pendingEventRequestList' || Listtype === 'myEvent' || Listtype === 'myCompletedEvent' || Listtype === 'pendingOrganizationInvites' || Listtype === 'pendingUserInvites') ?
                      <RunningEvents
                        myEventList={item}
                        isLoading={isLoading}
                        className='widget-box'
                        actionBtn={(search !== 'event' && tab === 4) ?
                          <>
                            <button
                              onClick={(e) => acceptValidationRequest(e, item?._id)}
                              className="button primary view-event-btn">{t('button.accept')}
                            </button>

                            <button
                              onClick={(e) => rejectValidationRequest(e, item?._id)}
                              className="button primary view-event-btn delete-btn">{t('button.Reject')}
                            </button>
                          </>
                          : ((role === 'user' && (current_user?.pages?.length > 0 || (userOrg?.organizationDetail && userOrg?.isVerified))) && tab === 8) ||
                              ((role === 'organization' && tab === 6)) ?
                            <>
                          
                              <button
                                onClick={(e) => (item.isPaid === true) ? AcceptRejectPaidEventHandler(e, item?._id) : AcceptRejectEventHandler(e, item?._id, 'accept')}
                                className="button primary view-event-btn">{t('button.accept')}
                              </button>

                              <button
                                onClick={(e) => AcceptRejectEventHandler(e, item?._id, 'cancel')}
                                className="button primary view-event-btn delete-btn">{t('button.Reject')}
                              </button>

                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate('/view-event', { state: { eventId: item?._id } })
                                }}
                                className="button primary view-event-btn">{t('button.view_event')}
                              </button>
                            </>
                            : false

                        }
                      />
                      : null
                    : null
                }
              </div>
            ))

            }
          </>
        }
      </Row> :
      <>
        {events?.length === 0 &&
          <>
            <h6 className="nopeMsg">{t('no_event')}</h6>
          </>
        }
      </>
    }
    <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />

  </>
}

export default EventsListing;