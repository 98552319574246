import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RoundCheckbox from "../commonComponents/roundCheckbox";
import { Row, Col } from "react-bootstrap";
import { getUserDetailServices } from "../services/auth.services";
import { GetInterestsListService } from "../services/onBoarding.services";
import Box from '@mui/material/Box';
import { getAnyUserData, getUserInterest, postAnyUserData } from "../services/friends.service";
import { selectCurrentOrg, selectCurrentUserRole } from "../Reducer/user/user.selector";
import { useSelector } from "react-redux";
import { getOrgInterestsService } from "../services/orgAuth.services";
import BarsLoader from "../commonComponents/barsLoader";

const InterestLists = ({ edit, disabled, userDet }) => {
    const { t } = useTranslation();
    const [loggedInUserDet, setLoggedInUserDet] = useState(null);
    const role = useSelector(selectCurrentUserRole);
    const CURRENTORG = useSelector(selectCurrentOrg);
    const [userInterestsList, setUserInterestsList] = useState([]);
    const [allInterests, setAllInterests] = useState([]);
    const [orgInterest, setOrgIntersts] = useState(null);
    const [interests, setInterests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [orgTatva,setOrgTatwa]=useState([]);
    const [roleNow, setRoleNow] = useState((userDet?.name) ? 'organization' : (userDet?.firstName) ? 'user' : role);

    const setUserRole = () => {
        let roleCal = '';
        roleCal = userDet && userDet?.name ?
            'organization' :
            userDet && (userDet?.firstName) ?
                'user' :
                role
        setRoleNow(roleCal);
    }

    useEffect(() => {
        setUserRole();
        getInterestsLists();
    }, [userDet, roleNow])

    const getInterestsLists = async () => {
        setLoading(true);
        if (roleNow === 'user') {
           await postAnyUserData(userDet?._id)
                .then((data) => {
                    if (data?.data?.status === 200) {
                        let spCopy = data?.data?.data?.spredeInterest;
                        let intCopy = data?.data?.data?.interestCategory;
                        intCopy =spCopy?intCopy?.filter((a) => {
                            let fnd = spCopy?.find((item) => item?.spredeInterestId === a?._id);
                            return fnd;
                        }
                        ):[]
                        setAllInterests(intCopy);
                        setUserInterestsList(spCopy);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                })
        }
        else {
            let id = userDet ? userDet?._id : CURRENTORG?._id;
            let currentData=userDet?userDet:CURRENTORG;
           if(currentData?.spredeInterest?.length>0){
           await getOrgInterestsService(id)
                .then((data) => {
                    if (data?.data?.status === 200) {
                        let arr=data?.data?.data;
                        if(arr?.length ){
                          let newArr=[];
                           arr=arr?.filter((int,index)=>{
                               let fnd= int?.option?.filter((opt)=>currentData?.spredeInterest?.indexOf(opt?._id)>-1);
                               newArr.push(fnd);
                              return fnd;
                           });
                           setInterests(arr);
                           setOrgTatwa(newArr);
                        }
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                })

        }
        else{
            setLoading(false);
        }
    }
    }

    return (
        <div className="interestListComp widget-box">
            <h5 className='my-3'>{t('spread_interest')}</h5>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className=""
            >
                <Row>
                    {loading ? <><BarsLoader /></> : <>
                        {roleNow === 'user' ? <>
                            {userInterestsList?.length > 0 ? <>
                                {allInterests.length > 0 &&
                                    allInterests.map((item, index) => {

                                        return (<Fragment  key={index}>
                                            <div className="Int-Set-title" >
                                                <img src={`/Assets/${item.spredeInterest}.png`} alt="Earth" />
                                                <h3 className="my-2">
                                                    {t(`${item.spredeInterest}`)}
                                                </h3>
                                            </div>
                                            <Col md={10} className="inv-set-opt">
                                                {userInterestsList.length > 0 &&
                                                    <>{(userInterestsList?.find(a => item?._id === a?.spredeInterestId)) ?
                                                        <>
                                                            {userInterestsList.map((option_item, idx) => {

                                                                return (item?._id === option_item.spredeInterestId ? <><RoundCheckbox
                                                                    checked={true}
                                                                    key={idx}
                                                                    label={t(`${option_item.spredeInterestOptions}`)}
                                                                    value={option_item._id}
                                                                    disabled="disabled"

                                                                /></> : '')
                                                            })}
                                                        </>
                                                        : <h6 className='pl-2'>{t('INTEREST_CATEGORY_NOT_ADDED')}</h6>}

                                                    </>
                                                }
                                            </Col>
                                        </Fragment>)
                                    })
                                }
                            </> : <h6>{t('USER_INTEREST_NOT_ADDED')}</h6>}

                        </> : <>
                            {interests?.length>0?<>
                            {interests.length > 0 && 
                                interests.map((item, index) => { 
                                    let tatwa=orgTatva[index];  
                                    return (tatwa?.length>0 &&<Fragment key={index}>
                                        <div className="Int-Set-title left-set-title-area">
                                            <img src={`/Assets/${item.spredeInterest}.png`} alt="Earth" />
                                            <h3 className="">
                                                {t(`${item.spredeInterest}`)}
                                            </h3>
                                        </div>
                                        <div className="inv-set-opt left-inv-set-opt">
                                            {item.option.length > 0 &&
                                                <>
                                                    {item.option.map((option_item, idx) => { 
                                                       let orgNow=userDet?userDet:CURRENTORG;
                                                       let data= orgNow?.spredeInterest?.indexOf(option_item._id) > -1; 
                                                        return (data &&<RoundCheckbox
                                                            disabled={true}
                                                            checked={
                                                                (orgNow?.spredeInterest?.indexOf(option_item._id) > -1) ? true : false}
                                                            key={idx}
                                                            label={t(`${option_item.spredeInterestOptions}`)}
                                                            value={option_item._id}

                                                        />)
                                                    })}


                                                </>
                                            }
                                        </div>
                                    </Fragment>)

                                })
                            }
                            </>:<><h6>{t('USER_INTEREST_NOT_ADDED')}</h6></>}
                        </>}
                    </>}
                </Row>
            </Box>
        </div>
    )
}

export default InterestLists;