import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import './footer.css'
import { Container, Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {FooterFb, FooterIn, FooterTw, FooterGoogle, FooterEmail, FooterPhone, FooterLocation } from "../../commonComponents/Images";
import { getImageUrl } from "../../utils/helpers";
const Footer = ({ cmsData }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const pathName = location.pathname;
    
    useEffect(()=> {
        if(pathName.includes("/login")){
            window.location.href = "#loginForm";    
        } 
        if(pathName.includes("/about")){
            window.location.href = "#aboutUsSection";    
        }
        if(pathName.includes("/panchTatva")){
            window.location.href = "#panchTatva";    
        }
    },[location])

    return (<footer>
        <Container className="footer-container">
            <Row>
                <Col md={12} className="col-lg-3 footer-logo-area">
                    <p><img id="footerLogo" src={getImageUrl(cmsData?._id, 'cms', cmsData?.footerLogo)} alt="" /></p>
                    <ul className="footer_social_links">
                        {/* TODO - Add Links for social anchors */}
                        <li> <a
                            href={cmsData?.socialFacebook}
                            // href="https://www.facebook.com"
                            rel="noopener" target='_blank'><img src={FooterFb} alt="" /></a> </li>
                        <li><a
                            href={cmsData?.socialTwitter}
                            // href="https://www.twitter.com"
                            rel="noopener" target='_blank'><img src={FooterTw} alt="" /></a></li>
                        <li><a
                            href={cmsData?.socialLinkedin}
                            // href="https://www.linkedin.com"
                            rel="noopener" target='_blank'><img src={FooterIn} alt="" /></a></li>
                        <li><a
                            href={cmsData?.socialGoogle}
                            // href="https://www.google.com"
                            rel="noopener" target='_blank'><img src={FooterGoogle} alt="" /></a></li>
                    </ul>
                </Col>

                <Col md={4} className="col-lg-3 col-sm-4">
                    <h3 className="footer_heading">{t('footer.links')}</h3>
                    <ul className="footer_ul">
                        {/* TODO - Add Links for all links */}
                        <li>{pathName == "/login"? <a href="#loginForm">{t('footer.home')}</a>:<Link to="/login">{t('footer.home')}</Link>}</li>
                        <li><Link to="/aboutus">{t('footer.about')}</Link></li>
                        <li><a href="#panchTatva">{t('footer.our_impact')}</a></li>
                    </ul>
                </Col>

                <Col md={4} className="col-lg-3 col-sm-4">
                    <h3 className="footer_heading">{t('footer.support')}</h3>
                    <ul className="footer_ul">
                        {/* TODO - Add Links for all links */}
                        <li><Link to="/faq">{t('footer.faq')}</Link></li>
                        <li><Link to="/termsandconditions">{t('footer.term_of_use')}</Link></li>
                        <li><Link to="/privacypolicy">{t('footer.privacy_policy')}</Link></li>
                        <li><Link to="/paymentpolicy">{t('footer.payment_policy')}</Link></li>
                    </ul>
                </Col>

                <Col md={4} className="col-lg-3 col-sm-4">
                    <h3 className="footer_heading">{t('footer.contact_us')}</h3>
                    <ul className="footer_ul">
                        <li><img src={FooterPhone} alt="" />  <span>{cmsData?.contactUs?.phoneNo} </span></li>
                        <li><img src={FooterEmail} alt="" /> <span>{cmsData?.contactUs?.Email} </span></li>
                        <li><img src={FooterLocation} alt="" /> <span className="footer-address">{cmsData?.contactUs?.Address} </span></li>
                    </ul>
                </Col>
            </Row>
        </Container>
        <div className="py-3">
            <div className="text-center btm-footer">
                <span>{t('footer.copyright')}</span>
                <span>@{t('homepage.banner.title')}</span>
                <span>{t('footer.all_rights_reserved')}</span>
                <span>{t('footer.brought_by')}</span>
            </div>
        </div>

    </footer>);
}

export default Footer;