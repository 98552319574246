import React from "react";
import UserLayout from "../../components/dashboard-layout";
import ManageUsers from "../../components/dashboard/superAdmin/manageUsers";

const ManageUsersPage = () => {
    return <UserLayout>
                <ManageUsers/>
            </UserLayout>;
}

export default ManageUsersPage;