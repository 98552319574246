import React, {useState, useEffect} from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getNotificationListService } from "../../../../services/onBoarding.services";
import BarsLoader from "../../../../commonComponents/barsLoader";
import AvatarImgBox from "../../../../commonComponents/AvatarImgBox/avatarImgBox";
import { AvatarImg } from "../../../../commonComponents/Images";
const UnauthorisedAlertInfo = () => {
    const { t } = useTranslation();
    const [notificationList, setNotificationList] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        getNotificationList()
    }, [])
   
    const getNotificationList = () => {
        setIsLoading(true);
        getNotificationListService(0, 3, 'loginUnAuthorized')
            .then((data) => {
                if (data?.data?.status === 200) {
                    setNotificationList(data?.data?.data?.data);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    return (<>
        <Row className="grid">
            <div className="grid-column">
                <div className="widget-box area-widget">
                    <p className="widget-box-title">{t('Unauthorised_Login_Alert')}</p>
                    <div className="widget-box-content">
                        <Row className="grid">
                            <Col md={12} className=" grid-column">
                                {isLoading ?
                                    <BarsLoader /> : notificationList.length > 0 ?
                                        <>
                                            {notificationList.map((e, i) => (
                                                <div className="widget-box area-widget blocked_user_area" key={i}>
                                                    <div className="">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center gray_plate">
                                                                <div className="hexagon-image-30-32">
                                                                    <AvatarImgBox 
                                                                    img={AvatarImg}
                                                                    />
                                                                </div>
                                                                <div className="">
                                                                    <h5 className="bold">{t(`notification.${e.subject}`)}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <div className="action-request-list justify-content-end">
                                                                    {/* <button onClick={() => onAction(e._id)} className="button unblock_btn">{t('button.unblock')}</button> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </>
                                        :
                                        <>
                                            {notificationList.length === 0 && <div>
                                                <h3 style={{ textAlign: 'center' }}>
                                                    {t('no_unauthorized_alerts')}
                                                </h3>
                                            </div>}
                                        </>
                                }

                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            
        </Row></>)
}

export default UnauthorisedAlertInfo;