import React from "react";
import { Row, Col } from "react-bootstrap";
import { AvatarImg } from "../../../commonComponents/Images";
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { useState, useEffect, useRef } from "react";
import { empRejectAcceptRemoveRequest, getfollowerList } from "../../../services/follower.service";
import BarsLoader from "../../../commonComponents/barsLoader";
import { getImageUrl, showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import swal from 'sweetalert';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const EmpList = ({ searchBy, countsOfFriends, callParentCountMethod }) => {

    const { t } = useTranslation();
    const limit = 12;
    const [PageNo, setPageNo] = useState(0);
    const [employeeList, setEmployeeList] = useState([]);
    const [lodaing, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [noReqMsg, setNoReqMsg] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    let defaultUserImage = AvatarImg;
    const navigate = useNavigate();
    const observer = useRef();

    useEffect(() => {
        //setSuggestFriendList(suggestFriendList.splice(0));
        setEmployeeList([]);
        setPageNo(0)
    }, [searchBy]);

    useEffect(() => {
        getEmployeeList(PageNo);
    }, [searchBy, PageNo])


    const lastElement = (node) => {
        if (lodaing) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((enteries) => {
            if (enteries[0].isIntersecting && hasMore) {
                setPageNo((prev) => prev + 1);
            }
        })
        if (node) {
            observer.current.observe(node);
        }
    }

    const getEmployeeList = (currentPage) => {
        setLoading(true);
        getfollowerList(currentPage, limit, 'employeeList', searchBy)
            .then((resp) => {
                const user = resp?.data?.data?.data;
                const user2 = user.map(a => ({ ...a, removeIs: false }));
                let newemployeeList = [...employeeList, ...user2];
                if (PageNo === 0) newemployeeList = [...user2]
                let newArr = newemployeeList?.reduce((acc, curr) => {
                    let fnd = acc.find((a) => a._id === curr?._id);
                    if (!fnd) acc.push(curr);
                    return acc;
                }, []);
                newArr = newArr?.filter(a => !a?.approveIs && !a?.rejectIs);
                if (searchBy) {
                    console.log("")
                }
                setEmployeeList(newArr);
                countsOfFriends(resp?.data?.data?.totalCount);
                setLoading(false);
                setHasMore(resp?.data?.data?.data?.length > 0 && user?.length === limit);
                setNoReqMsg((resp?.data?.data?.totalCount) > 0 ? false : true);

            })
    }

    const removeEmployee = (e, emp, index) => {
        e.preventDefault()
        // if (emp?.status === 'active') {
        let param = {
            action: "remove",
            list: [emp?._id]
        }
        swal({
            text: t('alerts.delete_employee'),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
            .then((val) => {
                if (!val) return;
                empRejectAcceptRemoveRequest(param)
                    .then((resp) => {
                        if (resp?.status == 200) {
                            const nextList = [...employeeList];
                            let index1 = nextList?.findIndex(a => a._id === emp?._id);
                            if (index >= 0) {
                                nextList[index]['removeIs'] = true;
                                setEmployeeList(nextList);
                                let pageNo1 = Math.floor(index1 / limit);
                                getEmployeeList(pageNo1)
                                employeeList?.splice(index, 1);
                                showSingleToastSuccess(t('Employee_Removed'));
                                callParentCountMethod();

                            }
                        }
                    })
                    .catch((error) => {
                        showToastError(t(`apiMessages.SOME_THING_WENT_WRONG`))
                    })

            })


        // else {
        //     showToastError(emp?.firstName+" " + t(`apiMessages.is_not_active`));
        // }
    }

    const showUser = (user) => {
        let encrptedId = window.btoa(user?._id);
        navigate(`/profile/${encrptedId}`, { state: { userId: user?._id } })
    }

    return (
        <Row className="mt-4 orgMobile">
            {employeeList?.length > 0 ? <>
                {employeeList?.length > 0 && employeeList?.map((emp, index) => {
                    if (employeeList?.length === index + 1) {
                        return <Col className="col-md-3 mb-4 col-xl-2 col-sm-4" ref={lastElement}>
                            <div className="widget-box equalPadding friends-list-box organizationBlock h-100">
                                <div className="d-flex flex-column h-100">
                                    <div onClick={() => { if (emp.status === "active") showUser(emp); else showToastError("Account is Inactive.") }} className="m-2 flex1">
                                        <AvatarImgBox img={emp?.profilePicture !== "" ? getImageUrl(emp?._id, 'userProfile', emp?.profilePicture) : defaultUserImage} />
                                        <p className="user-status-title">
                                            <span className="bold">{(`${emp?.firstName} ${emp?.lastName}`) || ''}</span>
                                        </p>
                                        <small>{emp?.userName ? `@${emp?.userName}` : ''}</small>
                                    </div>
                                    {
                                        emp?.removeIs ?
                                            <button className="button white white-tertiary"> {t('Removed')}</button>
                                            :
                                            <button className="button white white-tertiary" onClick={(e) => removeEmployee(e, emp, index)}> {t('Remove')} </button>

                                    }
                                </div>
                            </div>
                        </Col>
                    }
                    else {
                        return <Col className="col-md-3 mb-4 col-xl-2 col-sm-4">
                            <div className="widget-box friends-list-box h-100">
                                <div className="d-flex flex-column h-100">
                                    <div onClick={() => { if (emp.status === "active") showUser(emp); else showToastError("Account is Inactive.") }} className="m-2 flex1">
                                        <AvatarImgBox img={emp?.profilePicture !== "" ? getImageUrl(emp?._id, 'userProfile', emp?.profilePicture) : defaultUserImage} />
                                        <p className="user-status-title">
                                            <span className="bold">{(`${emp?.firstName} ${emp?.lastName}`) || ''}</span>
                                        </p>

                                        <small>{emp?.userName ? `@${emp?.userName}` : ''}</small>
                                    </div>
                                    {
                                        emp?.removeIs ?
                                            <button className="button white white-tertiary"> {t('Removed')}</button>
                                            :
                                            <button className="button white white-tertiary" onClick={(e) => removeEmployee(e, emp, index)}> {t('Remove')} </button>

                                    }
                                </div>
                            </div>
                        </Col>
                    }
                })}
            </> : noReqMsg === true ? <h4 className="nopeMsg">{t('errors.No_Employee_Added')}</h4> : ''}
            {
                lodaing ?
                    <BarsLoader />
                    : ''
            }
        </Row>
    )
}

export default EmpList;