import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { AvatarImg } from "../../../commonComponents/Images";
import { Row, Col } from "react-bootstrap";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { getEmployeeListService } from "../../../services/adminManage.services";
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { getImageUrl, showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import { selectCurrentOrg } from "../../../Reducer/user/user.selector";
import { useSelector } from "react-redux";
import Popup from "../../../commonComponents/popup";
import {
    Box, TextField,
    Button,
    Select,
    MenuItem,
    Checkbox,
    Stack,
    FormControlLabel,
    InputLabel,
    FormHelperText,
    Switch,
    FormControl
} from '@mui/material';
import { assignUserRole, empRejectAcceptRemoveRequest, getAllRoles, removeUserRole } from "../../../services/follower.service";
import BarsLoader from "../../../commonComponents/barsLoader";
import swal from 'sweetalert';
import { data } from "jquery";
import ReactPagination from "../../../commonComponents/reactPagination";


const ManageRole = () => {

    const { t } = useTranslation();
    const [employeeList, setEmployeeList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setLoader] = useState(false);
    let defaultUserImage = AvatarImg;
    const organizationDetail = useSelector(selectCurrentOrg);
    const observer = useRef();
    const limit = 8;
    let page = 0;
    const [pageNo, setPageNo] = useState(0);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [error, setError] = useState(false);
    const intailValues = { userName: '', userRole: '', userRoleEnable: '', userId: '' };
    const [formValues, setFormValues] = useState(intailValues);
    const [roleList, setRoleList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [noReqMsg, setNoReqMsg] = useState(false);
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [totalPage, setTotalPage] = useState(0);


    const lastElement = (node) => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((enteries) => {
            if (enteries[0].isIntersecting && hasMore) {
                setPageNo((prev) => prev + 1);
            }
        })
        if (node) {
            observer.current.observe(node);
        }
    }

    // useEffect(() => {
    //     setEmployeeList([]);
    //     setPageNo(0);
    // }, [searchText]);

    useEffect(() => {
        getEmolyeeData(0);
        getRoleList();
    }, [searchText])

    const getRoleList = () => {
        getAllRoles()
            .then((resp) => {
                if (resp?.status === 200) {
                    let data = resp?.data?.data;
                    data = data?.filter((a) => a==='event_manager'||a==='user_approver' ) //a ==='post_reviewer' || 
                    setRoleList(data);
                }
            })
            .catch((error) => {
                console.log(error);

            })
    }

    const getEmolyeeData = async (pageNo) => {
        setPageNo(pageNo);
        try {
            setLoader(true)
            const response = await getEmployeeListService(pageNo, limit, searchText);
            setLoader(false)
            if (response?.status == 200) {
                    const _data = response?.data?.data;
                    setEmployeeList(_data);
                    let pageCount = (response?.data?.totalCount) / limit;
                    setTotalPage(Math.ceil(pageCount));
                    setLoader(false);
                    setNoReqMsg(true);
                
            }
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    const handlePageClick = (data) => {
        page = data.selected;
        getEmolyeeData(page);
    }

    const handleSwitch = (e, emp, role, index1) => {
        let formValueCopy = { ...formValues };
        if (role) {
            let param = {
                userId: emp?._id,
                role: role
            }
            
            removeUserRole(param).
                then((resp) => {
                    if (resp?.status === 200) {
                        showSingleToastSuccess(t('apiMessages.Manage_role_Delete_Success'));
                        const nextList = [...employeeList];
                        let index = nextList?.findIndex(a => a._id === emp?._id);
                        if (index >= 0) {  
                            setEmployeeList(nextList);
                        }
                        let cntPage = pageNo;
                        if (employeeList?.length == 1) {
                            setPageNo(pageNo - 1);
                            cntPage = cntPage - 1;
                        }
                       
                       getEmolyeeData(cntPage);
                        
                    }
                })
                .catch((error) => {
                    showToastError((`apiMessages.SOME_THING_WENT_WRONG`))
                }
                )
        }
        else {
            handleOpen();
            formValueCopy['userName'] = emp?.firstName + " " + emp?.lastName;
            formValueCopy['userRole'] = '';
            formValueCopy['userRoleEnable'] = false;
            formValueCopy['userId'] = emp?._id;
            setClickedIndex(index1);
        }
        setFormValues(formValueCopy);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formValues.userName === '' || formValues.userRole === '') {
            setError(true); return;
        }
        let param = {
            userId: formValues?.userId,
            role: formValues?.userRole
        }
        assignUserRole(param).
            then((resp) => {
                if (resp?.status == 200) {
                    if (formValues?.userRoleEnable)
                        showSingleToastSuccess(t('apiMessages.Manage_role_Role_Change_Success'));
                    else showSingleToastSuccess(t('apiMessages.Manage_role_Role_added_success'));
                    let index1=clickedIndex;
                    const nextList = [...employeeList];
                    let index = nextList?.findIndex(a => a._id === formValues?.userId);
                    if (index >= 0) {  
                       setEmployeeList(nextList);
                    }
                    let cntPage = pageNo;
                    if (employeeList?.length == 1) {
                        setPageNo(pageNo - 1);
                        cntPage = cntPage - 1;
                    }
                   getEmolyeeData(cntPage);

                }
            })
            .catch((err) => {
                console.log(err);
                if(err?.data){
                    showToastError(t(`apiMessages.${err?.data?.data}`))    
                }
            })
        handleClose();
        setFormValues(intailValues);
        setError(false);
    }
   

    const changeRoleOption = (e, emp, index) => {
        e.preventDefault();
        handleOpen();
        let formValueCopy = { ...formValues };
        formValueCopy['userName'] = emp?.firstName + " " + emp?.lastName;
        formValueCopy['userRole'] = emp?.pages?.[0]?.role;
        formValueCopy['userRoleEnable'] = true;
        formValueCopy['userId'] = emp?._id;
        setFormValues(formValueCopy);

    }

    const removeEmployee = (e, emp, index) => {
        e.preventDefault();
        let param = {
            userId: emp?._id,
            role: emp?.pages?.[0]?.role
        }
        let paramRemove = {
            action: "remove",
            list: [emp?._id]
        }
        swal({
            text: t('alerts.delete_employee'),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
            .then((val) => {
                if (!val) return;
                empRejectAcceptRemoveRequest(paramRemove)
                    .then((resp) => {
                        if (resp?.status == 200) {
                            const nextList = [...employeeList];
                            let index1 = nextList?.findIndex(a => a._id === emp?._id);
                            showSingleToastSuccess(t('Employee_Removed'));
                            if (index1 >= 0) {
                                setEmployeeList(nextList);
                               
                            }
                            let cntPage = pageNo;
                            if (employeeList?.length == 1) {
                                setPageNo(pageNo - 1);
                                cntPage = cntPage - 1;
                            }
                           getEmolyeeData(cntPage);
                        }
                    })
                    .catch((error) => {
                        showToastError(('apiMessages.SOME_THING_WENT_WRONG'))
                    })

            })
       
    }
   

    return <>
        {/* <div className="col-md-12"> <ProfileHeader coverImg='' img='' /> </div> */}
        <h2 className='mb-4 mt-1'> {t('superAdmin.manage_role_heading2')} </h2>
        <div className="widget-box search_export_btn_area">
            <div className="search_area_export_area">
                <div>
                    <div className="manageRoleSearch friendsSearch">
                        <div className="search-icon invitePoupBlock">
                            <TextField
                                type='text'
                                label={t('friendsMsg.Search_Employee')}
                                onChange={(e) => setSearchText(e.target.value)}
                                value={searchText ? searchText : ''}
                                InputProps={{ endAdornment: <Button className="button secondary"><SearchOutlinedIcon style={{ fill: "#fff" }} /></Button> }} />
                        </div>
                    </div>

                </div>
                <div>
                    {/* <Button className='btn export-btn'>{t('export_all')}</Button> */}
                </div>
            </div>
        </div>
        {employeeList?.length > 0 ? <>
            <div className='col-md-12'>
                <Row>

                    {
                        employeeList?.map((emp, index) => {
                            let organization = emp?.pages?.find((org) => org?.organizationId === organizationDetail?._id);
                            let role = organization?.role || ''
                                return <div className="col-lg-12 col-md-4 mt-2 col-sm-6 parentBox" key={index} >
                                    <div className="manage_box_area h-100">
                                        <Row className="align-items-center h-100">
                                            <Col lg={4}>
                                                <div className="d-flex align-items-center flex-lg-row flex-column">
                                                    <div className="manage_box_pic_area">
                                                        <AvatarImgBox img={emp?.profilePicture !== "" ? getImageUrl(emp?._id, 'userProfile', emp?.profilePicture) : defaultUserImage} />
                                                    </div>
                                                    <div className="mamage_pro_area">
                                                        {`${emp?.firstName || ''} ${emp?.lastName}`}
                                                    </div>
                                                </div>

                                            </Col>

                                            <Col lg={2} className="">
                                                {role &&
                                                    <h6 className="employ_role text-start">{t('roles.Role')}:<span>{role && t(`roles.${role}`)}</span></h6>
                                                }
                                            </Col>

                                            <Col lg={3} className="col-md-12 text-lg-end text-center enable_role_margin">
                                                
                                                {!role && role !== 'admin' && <><span>{role?t('roles.Disable_Role'):t('roles.Enable_Role')}</span> <Switch checked={role ? true : false} onChange={(e) => handleSwitch(e, emp, role, index)} /></> }
                                                {role && role !== 'admin' &&  <><span>{role?t('roles.Disable_Role'):t('roles.Enable_Role')}</span> <Switch checked={role ? true : false} onChange={(e) => handleSwitch(e, emp, role, index)} /></>}
                                            </Col>
                                            <Col lg={3} className="manageRoleControlsCont text-end manage_r_btn_area mt-3 mt-lg-0 mb-0">
                                                {role && role !== 'admin' && <button className='btn change_role_btn' onClick={(e) => changeRoleOption(e, emp, index)}>{t('superAdmin.manage_role_changeText')}</button>}
                                                {role !== 'admin' && <button className=' btn change_emp' onClick={(e) => removeEmployee(e, emp, index)}>{t('roles.Remove_Employee')}</button>}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                        })
                    }
                </Row>
            </div>
        </> : noReqMsg === true ? <h4 className='my-3'>{searchText ? t('roles.No_Employee_Found_in_search') : t('roles.No_Employee_Found')}</h4> : ''}
        {
            isLoading ?
                <BarsLoader />
                : ''
        }
         <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />
      

        
        <Popup heading={t('roles.Manage_Role')} handleClose={handleClose} modalHeadingClass={"manageRolePopupHeading"}modalContentClasses={"manageRolePopupContent"} modalBodyClasses={"manageRolePopupModal"} open={open}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': {width: '100%' },
                }}
                noValidate
                autoComplete="off"
                className="form "
                onSubmit={handleSubmit}
            >

                <TextField
                    error={(error && formValues.userName === '') ? true : false}
                    type='text'
                    helperText={(error && formValues.userName === '') ? t('errors.name_required') : ''}
                    value={formValues.userName}
                    disabled
                    // onChange={(e) => { setFormValues({ ...formValues, userName: e.target.value }) }}
                    label={t('form.label_name')} />
                <FormControl fullWidth className="manageRolePopupForm">
                    <InputLabel id="country-select-label">{t('superAdmin.manage_role_text')}</InputLabel>
                    <Select
                        labelId="country-select-label"
                        value={formValues.userRole}
                        label={t('manager_role_dd_option')}
                        onChange={(e) => { setFormValues({ ...formValues, userRole: e.target.value }) }}
                    >
                        <MenuItem value="" defaultValue={true}>{t('superAdmin.manager_role_dd_option')}</MenuItem>
                        {roleList?.map((r) => {
                            return <MenuItem value={r}>{t(`roles.${r}`)}</MenuItem>
                        })}

                    </Select>
                    {error && formValues.userRole === '' ? <FormHelperText error >{t('errors.superAdmin_role_required')}</FormHelperText> : ''}
                </FormControl>
                <div className="form-row">
                    <div className="form-item manageRoleFormItem">
                        <button className="orgChangeRoleBtn">{formValues?.userRole ? t('superAdmin.manage_role_changeText') : t('superAdmin.Add_Role')}</button>
                    </div>
                </div>

            </Box>
        </Popup>
       

    </>
}

export default ManageRole;