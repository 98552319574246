import React from "react";
import { AvatarImg } from "../commonComponents/Images";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TextField, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
const InviteFriendsList = ({heading}) => {
    const {t} = useTranslation();
    const n = 4; 
    return (
        <div className="widget-box">

        <p className="widget-box-title">{heading ? heading : t('friends')}</p>

        <div className="widget-box-content">
       
        <TextField type='text' label={t('friendsMsg.SEARCH_FRIENDS')}
                         InputProps={{endAdornment: <Button className="button secondary" style={{width:"10%"}}><SearchIcon style={{fill:"#fff"}}/></Button>}}/>
        

          <div className="user-status-list">
          {[...Array(n)].map((e, i) =>(
            <div className="user-status request-small" key={i}>
              <div className="user-status-avatar">
                <div className="user-avatar small no-outline">
                  <div className="user-avatar-content">
                    <div className="hexagon-image-30-32 pentagon">
                        <img src={AvatarImg} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
          
              <p className="user-status-title"><div className="bold" to="/friends">{t('Sarah_Diamond')}</div></p>
          
              <p className="user-status-text small">2 {t('friends_in_common')}</p>
          
              <div className="action-request-list">
                <div className="action-request accept">
                    <EmailOutlinedIcon className="action-request-icon"/>
                </div>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
  )
}

export default InviteFriendsList;