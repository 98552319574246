import React from "react";
import UserLayout from "../../components/dashboard-layout";
// import ValidatorAvailibilty from "../../components/dashboard/validator/validatorAvailibilty";
import ValidatorInfo from "../../components/dashboard/validator/validatorInfo";
const ValidatorAvailibiltyPage = () => {
    return <UserLayout>
                <ValidatorInfo/>
            </UserLayout>;
}

export default ValidatorAvailibiltyPage;