import React, { useState, useEffect } from 'react';
import SectionHeader from '../../../commonDashboardComp/sectionHeader';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Box, TextField } from '@mui/material';
import {
  allowOnlyLetters,
  showSingleToastSuccess,
  showToastError,
  showToastSuccess,
  showToastWarning,
} from '../../../utils/helpers';
import { bulkInvite } from '../../../services/follower.service';
import { useCSVDownloader, usePapaParse } from 'react-papaparse';

import { useParams } from 'react-router-dom';
import {
  fetchInviteListService,
  inviteUserToPlatformService,
} from '../../../services/cms.services';
import ReactPagination from '../../../commonComponents/reactPagination';
import { validEmail } from '../../../utils/formatValidator';
const ManageInvites = ({ setEmpCount }) => {
  const { CSVDownloader, Type } = useCSVDownloader();
  const [invitationData, setInvitationData] = useState([]);
  const { t } = useTranslation();
  const { readString } = usePapaParse();
  const [csvFile, setCsvFile] = useState();
  const [error, setError] = useState(false);
  const [singleInviteloading, setSingleInviteLoading] = useState(false);
  const [bulkInviteloading, setBulkInviteLoading] = useState(false);
  const [csvArray, setCsvArray] = useState([]);
  const [recepientName, setRecepientName] = useState('');
  const [recepientEmail, setRecepientEmail] = useState('');
  const [recepientError, setRecepientError] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(0);
  const handlePageChange = (e) => {
    // let val = (e.selected -1);
    // if(val < 0){
    //   val = 0;
    //   setPage(val);
    // }
    // else{
    //   setPage(val);
    // }
    setPage(e.selected);
  };
  const checkingNameValid = (name) => {
    var matches = name.match(/^[a-zA-Z]+$/);
    return matches ? true : false;
  };
  const handleUploadFile = (e) => {
    e.preventDefault();
    let file = csvFile;
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const text = e.target.result;
        readCSV(text);
      };
      reader.readAsText(file);
    } else {
      setError(true);
    }
  };

  const readCSV = (csvData) => {
    readString(csvData, {
      header: true,
      complete: (result) => {
        sendInvites(result.data);
      },
    });
  };
  const sendInvites = async (emailArray, inviteType) => {
    if (emailArray.length != 0) {
      const cleanList = emailArray.filter((item) => {
        if (item.name && item.name !== '' && item.email && item.email !== '') {
          return item;
        } else {
        }
      });
      const payload = [...cleanList];
      try {
        inviteType == 'singleInvite'
          ? setSingleInviteLoading(true)
          : setBulkInviteLoading(true);
        inviteUserToPlatformService(payload).then((response) => {
          if (
            response.data.status === 200 &&
            response.data.message === 'Success'
          ) {
            if (
              response.data.data.length === 1 &&
              response.data.data[0] === 'USER_REGISTER_SUCCESS'
            ) {
              showSingleToastSuccess('User Invited');
              fetchInvites();
              setSingleInviteLoading(false);
              setBulkInviteLoading(false);
            } else if (
              response.data.data.length === 1 &&
              response.data.data[0] === 'USER_ALREADY_REGISTERED'
            ) {
              showToastError('User Already Registered!');
              setSingleInviteLoading(false);
              setBulkInviteLoading(false);
            } else if (
              response.data.data.length === 1 &&
              response.data.data[0] === ''
            ) {
              showToastError('User Already Invited!');
              setSingleInviteLoading(false);
              setBulkInviteLoading(false);
            } else if (
              response.data.data.length > 1 &&
              response.data.data.includes('USER_ALREADY_REGISTERED') &&
              response.data.data.includes('') &&
              response.data.data.includes('USER_REGISTER_SUCCESS')
            ) {
              showToastWarning(
                'Users invited but some users are either already invited or registered.'
              );
              setSingleInviteLoading(false);
              setBulkInviteLoading(false);
              fetchInvites();
            } else if (
              response.data.data.length > 1 &&
              response.data.data.includes('USER_ALREADY_REGISTERED') &&
              response.data.data.includes('USER_REGISTER_SUCCESS')
            ) {
              showToastWarning(
                'Users invited but some users are already registered.'
              );
              setSingleInviteLoading(false);
              setBulkInviteLoading(false);
              fetchInvites();
            } else if (
              response.data.data.length > 1 &&
              response.data.data.includes('') &&
              response.data.data.includes('USER_REGISTER_SUCCESS')
            ) {
              showToastWarning(
                'Users invited but some users are already invited.'
              );
              setSingleInviteLoading(false);
              setBulkInviteLoading(false);
              fetchInvites();
            } else if (
              response.data.data.length > 1 &&
              response.data.data.includes('USER_ALREADY_REGISTERED') &&
              response.data.data.includes('')
            ) {
              showToastError(
                'Users are either already registered or invited.'
              );
              setSingleInviteLoading(false);
              setBulkInviteLoading(false);
            } else if (
              response.data.data.length > 1 &&
              response.data.data.includes('USER_REGISTER_SUCCESS')
            ) {
              showToastSuccess('Users invited.');
              setSingleInviteLoading(false);
              setBulkInviteLoading(false);
              fetchInvites();
            } else if (
              response.data.data.length > 1 &&
              response.data.data.includes('')
            ) {
              showToastError('Users already invited.');
              setSingleInviteLoading(false);
              setBulkInviteLoading(false);
            } else if (
              response.data.data.length > 1 &&
              response.data.data.includes('USER_ALREADY_REGISTERED')
            ) {
              showToastError('Users are already registerd.');
              setSingleInviteLoading(false);
              setBulkInviteLoading(false);
            }
            else{
              showToastSuccess('Users invited.');
              setSingleInviteLoading(false);
              setBulkInviteLoading(false);
              fetchInvites();
            }
          }
        });
      } catch (e) {
        console.log(e);
        showToastError(t('some_error'));
        setSingleInviteLoading(false);
        setBulkInviteLoading(false);
      }
    }
  };

  const fetchInvites = async () => {
    try {
      fetchInviteListService(page).then((response) => {
        if (response.status == 200) {
          let pageCount = response.data.data.totalCount / 10;
          setTotalPage(Math.ceil(pageCount));
          updateInviteList(response.data.data.data);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const updateInviteList = (data) => {
    const inviteList = data?.map((item) => {
      return {
        invitor:
          item?.userId?.firstName +
          ' ' +
          (item?.userId?.middleName !== null ? item?.userId?.middleName : '') +
          ' ' +
          (item?.userId?.lastName !== null ? item?.userId?.lastName : ''),
        invitedUser: item?.name,
        invitedUserEmail: item?.email,
        invitationDate: new Date(item.createdAt).toLocaleDateString(),
        status: item.registerStatus,
      };
    });
    setInvitationData([...inviteList]);
  };
  const singleInvite = () => {
    if (
      recepientName == '' ||
      recepientEmail == '' ||
      !validEmail(recepientEmail)
    ) {
      setRecepientError(true);
    } else {
      const receipient = [
        {
          name: recepientName,
          email: recepientEmail,
        },
      ];
      sendInvites(receipient, 'singleInvite');
    }
  };
  useEffect(() => {
    fetchInvites();
  }, [page]);
  return (
    <>
      <SectionHeader title={'Manage Invites'} />
      <Row>
        <Col md={6}>
          <div className="widget-box h-100">
            <p className="widget-box-title mb-5">Invite By E-mail</p>
            {recepientError && (
              <div className="mb-3 error-text">
                {'Name or e-mail is incorrect.'}
              </div>
            )}
            <TextField
              type="text"
              label={'Full Name'}
              fullWidth
              className="mb-3"
              onChange={(e) => {
                setRecepientName(allowOnlyLetters(e.target.value));
              }}
            />
            <TextField
              type="text"
              label={'E-mail Address'}
              fullWidth
              onChange={(e) => {
                setRecepientEmail(e.target.value);
              }}
            />
            <button
              className="button primary mt-3 d-flex align-items-center justify-content-center text-center"
              onClick={() => {
                singleInvite();
              }}
            >
              Invite{' '}
              {singleInviteloading && <span className="spinner-border" />}
            </button>
          </div>
        </Col>
        <Col md={6}>
          <div className="widget-box">
            <p className="widget-box-title">Bulk Invite</p>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '98%' },
              }}
              noValidate
              autoComplete="off"
            >
              <Row>
                <Col md={12}>
                  <label className="user_img_upload" htmlFor="upload-photo">
                    <input
                      type="file"
                      accept=".csv"
                      id="upload-photo"
                      onInputCapture={(e) => {
                        setCsvFile(e.target.files[0]);
                        setError(false);
                        e.target.value = null;
                      }}
                    />
                    <div className="content m-0">
                      <img src="/Assets/Icon awesome-file-csv.png" />
                      {csvFile ? (
                        <>
                          <h5 className="my-3">{csvFile?.name}</h5>
                          <h6 className="gray-ligj-color">
                            {t('roles.Change_CSV')}
                          </h6>
                        </>
                      ) : (
                        <>
                          <h5 className="my-3">
                            {t('roles.Select_a_CSV_file_to_upload')}
                          </h5>
                          {/* <h6 className="gray-ligj-color">Or drag and drop it here</h6> */}
                        </>
                      )}
                    </div>
                  </label>
                  {!csvFile && error && (
                    <div className="error-text">
                      {t('roles.Please_Select_CSV_file')}
                    </div>
                  )}
                  <CSVDownloader
                    type={Type.Link}
                    filename={'sprede_refer_and_invite_sample.csv'}
                    bom={true}
                    data={'name,email'}
                    separator=","
                    className="mt-3 text-link d-inline-block"
                  >
                    Here is the sample CSV
                  </CSVDownloader>
                </Col>
                <Col
                  md={{ span: 6, offset: 6 }}
                  lg={{ span: 5, offset: 7 }}
                  className="mb-0 text-right"
                  xs={12}
                >
                  <button
                    className="button primary bulkUploadBtn d-flex align-items-center justify-content-center"
                    onClick={(e) => handleUploadFile(e)}
                  >
                    {' '}
                    {'Upload & Invite'}{' '}
                    {bulkInviteloading && <span className="spinner-border" />}
                  </button>
                </Col>
              </Row>
            </Box>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12}>
          <div className="widget-box">
            <p className="widget-box-title">Invitation History</p>
            <div className="widget-box-content">
              <table className="table">
                <thead>
                  <tr>
                    <td>Invited By</td>
                    <td>Invitated Person</td>
                    <td>E-mail</td>
                    <td>Date</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {invitationData.length === 0 ? (
                    <tr>
                      <td colSpan={5} className="text-center">
                        No data available.
                      </td>
                    </tr>
                  ) : (
                    invitationData.map((item, index) => {
                      return (
                        <tr key={'invitationData' + index}>
                          <td>{item.invitor}</td>
                          <td>{item.invitedUser}</td>
                          <td>{item.invitedUserEmail}</td>
                          <td>{item.invitationDate}</td>
                          <td>{item.status}</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
              <ReactPagination
                pageCount={totalPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default ManageInvites;
