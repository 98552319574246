import React , {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Box ,
    TextField,
   FormControl,
   Autocomplete,
   Switch,
   } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CalendarMonthIcon } from "../../../../commonComponents/Images";
import SaveEditSettingsBtn from "../../../../commonDashboardComp/saveEditSettingsBtn";
import { getAllOrgsService, CheckOrGetOrgService } from "../../../../services/onBoarding.services";
import { allowOnlyLetters } from "../../../../utils/helpers";
import { fromToDateValidator } from "../../../../utils/formatValidator";
import { DesignationList } from "../../../../config/constants";
import GreenCheckBox from "../../../../commonComponents/greenCheckbox/greenCheckbox";
import { Country, City } from 'country-state-city';
import swal from "sweetalert";

const EmployementSetting = ({
        saveUserDetails, 
        initial_emp,formValues, 
        setFormValues ,
        canEdit, 
        onEdit, 
        editEmpDetails,
        addOrgDetail,
        deleteEmpDetails,
        setEmployeeFormValues,
        editUserEmpAddDetails
    }) => {
    const { t } = useTranslation();
    const [empFormValues, setEmpFormValues] = useState(formValues.organization);
    const [showWorking, setShowWorking] = useState(true);
    const newCountryList = [];
    const [errors,setErrors]=useState(false);

    useEffect(()=>{
        setEmployeeFormValues(empFormValues);
    }, [empFormValues])
    
    Country.getAllCountries()?.filter((a) => {
        newCountryList.push(a?.name);
        return a?.name;
    })
   
    const [cityList,setCityList]=useState([]);
    useEffect(()=>{
        setEmpFormValues(formValues.organization.length>0 ? formValues.organization : [initial_emp]);
    }, [formValues.organization])

    useEffect(()=>{
        setShowWorking(true);
        empFormValues.map((item)=>{
            if(item.isCurrentlyWorking === true){
                setShowWorking(false); return;
            }
        })
    }, [empFormValues]);

    const addFormFields = (e) => {
        e.preventDefault();
        let cityArrCopy=[...cityList];
        cityArrCopy.push([]);
        setCityList(cityArrCopy);
        setEmpFormValues([...empFormValues, initial_emp])
        //setFormValues({ ...formValues, organization: empFormValues })
    }
    const [orgData, setOrgData] = useState([]);
    const [orgList, setOrgList] = useState([]);

    useEffect(() => {
        getOrgList();
    }, [])

    useEffect(()=>{
       getIntialCityList();
    },[formValues])

    const getIntialCityList=()=>{
        const cityListSamp = [];
        formValues.organization?.map((a) => {
            let cityArr = [];
            let code = Country.getAllCountries()?.find(b => b?.name === a?.address?.country);
            City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                if(cityArr?.indexOf(a?.name) === -1){ cityArr.push(a?.name); }  
            });
            cityListSamp.push(cityArr);    
        });
        setCityList(cityListSamp?.length===0?[]:cityListSamp);
    }

    const getOrgList = async () => {
        getAllOrgsService()
        .then((data) => {
            if (data?.data?.status === 200) {
                let OrgList = [];
                data?.data?.data.map((item)=>{
                    OrgList.push(item?.name);
                })
                setOrgData(OrgList);
                setOrgList(data?.data?.data)
            }
        })
        .catch((error) => {
            console.log(error);
        })


    }

    const handleChange = (i, e) => {
        let newFormValues = [...empFormValues];
        if (e.target.name === 'city') {
            newFormValues[i]['address'] = {
                'city': allowOnlyLetters(e.target.value),
                'country': newFormValues[i]['address']['country']
            }
        }
        else if (e.target.name === 'country') {
            newFormValues[i]['address'] = {
                'city': newFormValues[i]['address']['city'],
                'country': e.target.value
            }
        }
        else {
            newFormValues[i][e.target.name] = e.target.value;
        }

        setEmpFormValues(newFormValues);
        setFormValues({ ...formValues, organization: empFormValues })
    }

    const handleChangeVal = (i, name, val) => {
        let newFormValues = [...empFormValues];
        newFormValues[i][name] = val;
        setEmpFormValues(newFormValues);
        setFormValues({ ...formValues, organization: empFormValues })
    }

    const handleChangeDesignation = (i, name, val, reason) => {
        var selectedValue = (reason == 'input') ? val : val?.value;
        let newFormValues = [...empFormValues];
        if(selectedValue){
            newFormValues[i][name] = selectedValue;
            setEmpFormValues(newFormValues);
            setFormValues({ ...formValues, organization: empFormValues })
        }
        
        if(reason == "clear"){ 
            newFormValues[i][name] = selectedValue;
            setEmpFormValues(newFormValues);
            setFormValues({ ...formValues, organization: empFormValues })
        }
    }

    const handleCountryChange = (i, val) => {
        let newFormValues = [...empFormValues];
        let cityListCopy=[...cityList];
        let code = Country.getAllCountries()?.find(b => b?.name === val);
        let cityArr=[];
        City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
            if(cityArr?.indexOf(a?.name) === -1){ cityArr.push(a?.name); }  
        });
        cityListCopy[i]=cityArr;
        
        //newFormValues[i]['address']['city'],
        newFormValues[i]['address'] = {
            'city':'',
            'country': val 
        }
        setCityList(cityListCopy);
        
        // newFormValues[i]['address'] = {
        //     'city': newFormValues[i]['address']['city'],
        //     'country': val 
        // }
           
        setEmpFormValues(newFormValues);
        setFormValues({ ...formValues, organization: empFormValues })
    }

    const handledateChange = (i, name, value) => {
        let newFormValues = [...empFormValues];
        newFormValues[i][name] = value;
        setEmpFormValues(newFormValues);
        setFormValues({ ...formValues, organization: empFormValues })
    }

    const handleCheckboxChange = (i, e) => {
        let newFormValues = [...empFormValues];
        newFormValues[i][e.target.name] = e.target.checked;
        newFormValues[i]['endYear'] = null;
        setEmpFormValues(newFormValues);
        setFormValues({ ...formValues, organization: empFormValues })
    }

    const removeFormFields = async(i) => {
        if(empFormValues[i]['_id'])
        {
            await swal({
                text: t('alerts.delete_emp_details'),
                title: '',
                icon: "warning",
                buttons: {
                  Yes: {
                    text: t('yes'),
                    value: true,
                  },
                  No: {
                    text: t('no'),
                    value: false,
                  },
                },
              })
                .then(async (val) => {
                  if (!val) return;          
                  deleteEmpDetails(empFormValues[i]['_id'] , empFormValues[i]['organizationId']);
                });
        }
        else{
            let newFormValues = [...empFormValues];
            newFormValues.splice(i, 1);
            let cityListCopy=[...cityList];
            cityListCopy.splice(i,1);
            setCityList(cityListCopy);
            setEmpFormValues((newFormValues.length > 0) ? newFormValues : [initial_emp])
        }
    }
    const handleCityChange = (i, val) => {
        let newFormValues = [...empFormValues];
        newFormValues[i]['address'] = {
            'city':val,
            'country': newFormValues[i]['address']['country'] 
        }
        setEmpFormValues(newFormValues);
        setFormValues({ ...formValues, organization: empFormValues });
    }

    const onSave = (e) => {
        e.preventDefault();
        setErrors(false);
        let error = false;

        empFormValues.map((item, index)=>{
            if(item?.organizationName === '' || item?.organizationName === null &&
            item?.designation === '' || item?.designation === null &&
            item?.startYear === '' &&
            (!item?.isCurrentlyWorking === '' && item?.endYear === null )
            )
            {
                error = true; return;
            }
        })
        if(error){
            setErrors(true);
            return true;
        }

        empFormValues.map((item, index)=>{
            saveEmpDetails(index)
        })

        let nextParams = {      
            classifyYourJob: formValues.classifyYourJob,
            additionalPrivacy: formValues.additionalPrivacy,
        }
        editUserEmpAddDetails(nextParams);

        let params = {
            organization: empFormValues,
            classifyYourJob: formValues.classifyYourJob,
            additionalPrivacy: formValues.additionalPrivacy,
        }
        saveUserDetails( params, 'employementInfo')
    }

    const saveEmpDetails = async(i) => {
        if( empFormValues[i]['organizationName'] === '' ||
            empFormValues[i]['organizationName'] === null ||
            empFormValues[i]['designation'] === '' ||
            empFormValues[i]['designation'] === null || 
            empFormValues[i]['startYear'] === '' ||
            (empFormValues[i]['endYear'] === '' && !empFormValues[i]['isCurrentlyWorking'])
        ){
            return false;
        }

        let orgId = '';

        let filter = orgList.filter((item)=>item?.name === empFormValues[i]['organizationName']);
        if(filter.length > 0){
            orgId = filter[0]?._id;
        }

        
        if(orgId === ''){
            //create Org and get Org Id
            let response = await CheckOrGetOrgService(empFormValues[i]['organizationName'])
            if(response?.data?.status === 200){
                orgId = response?.data?.data;
            }
        }

        if(orgId === ''){return;}

        let org_params = {
            orgId: orgId,
            designation: empFormValues[i]['designation'],
            startYear: empFormValues[i]['startYear'],
            endYear: empFormValues[i]['endYear'] || "",
            isCurrentlyWorking: empFormValues[i]['isCurrentlyWorking'],
            address: { city: empFormValues[i]['address']['city'], country: empFormValues[i]['address']['country'] }
        }

        if(empFormValues[i]['_id']){
            org_params['_id'] = empFormValues[i]['_id']
            editEmpDetails(org_params);
            return;
        }
        addOrgDetail(org_params)
    }

    return (<Row className="grid empSettingComp">
            <div className="grid-column">
                <div className="widget-box equalPadding">
                    <p className="widget-box-title">{t('Employment')}
                        <SaveEditSettingsBtn canEdit={canEdit} onEdit={onEdit} onSave={onSave}/>
                    </p>
                    <div className="widget-box-content mb-0">
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '98%' },
                        }}
                        noValidate
                        autoComplete="off"
                        className="userdetail-form profile_parent11"
                        onSubmit={(e)=>e.preventDefault()}
                    >

                        <Row>
                            <Col md={12} className="radio-text UserProfileEmpSection pe-0">
                                <div className="empSectionHeading">
                                    <h4>{t('would_you_classify_your_job')}</h4>
                                    <h6>{t('journalist_activist_defence')}</h6>
                                </div>
                                <Switch
                                    disabled={!canEdit}
                                    checked={formValues.classifyYourJob}
                                    onChange={(e) => setFormValues({ ...formValues, classifyYourJob: e.target.checked })}
                                    aria-label='Switch demo' />
                            </Col>
                            {/* <Col md={1} className="ps-0">
                                
                            </Col> */}

                            {(formValues.classifyYourJob) && <><Col md={12} className="radio-text UserProfileEmpSection pe-0">
                                <div className="empSectionHeading">
                                    <h4 className="span_heading1">{t('would_you_like_to_turn_on')}</h4>
                                    <h6>{t('this_would_limit_some_of_the_functionalities')}</h6>
                                </div>
                                <Switch
                                        disabled={formValues.classifyYourJob === false}
                                        checked={formValues.additionalPrivacy}
                                        onChange={(e) => setFormValues({ ...formValues, additionalPrivacy: e.target.checked })}
                                        aria-label='Switch demo' />
                            </Col>
                                <Col md={1} className="ps-0">
                                    
                                </Col></>}


                        </Row>
                    {empFormValues.map((e, index) => (
                        <div key={index} style={{ position: 'relative' }}>
                            {index!==0 ? <hr className="hr_line"/> : ''}
                            {/* <div className="edit_row" onClick={() => saveEmpDetails(index)} title="Save Employement"><SaveIcon/></div> */}
                            {
                                ((e._id || index) && canEdit) ? <>
                                <div className="delete_row_2 delete_row" onClick={() => removeFormFields(index)} title={t('form.Delete_Employment')}><DeleteForeverIcon /></div></> : ''
                            }
                            <Row className="removeMargin profile_subcategory22">
                                <Col md={6} className="empSettingCompField fullWidth target_title88">
                                    {/* <TextField label={t('form.label_org_name')} name="orgId" value={e.orgId || ''} onChange={e => handleChange(index, e)}/> */}
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disabled={!canEdit}
                                            disablePortal
                                            value={e?.organizationName || ''}
                                            options={orgData}
                                            getOptionLabel={(option)=>option}   
                                            onChange={(e,val )=>  handleChangeVal(index,'organizationName', val)}  
                                            inputValue={e.organizationName || ''}
                                            onInputChange={(e, val) => handleChangeVal(index,'organizationName', val)}
                                            renderInput={(params) => <TextField {...params} label={t('form.label_enter_org')}  />}
                                        />
                                        {errors && e.organizationName==='' &&
                                            <div className="error-text">
                                                {t('errors.onboarding_org_required')}
                                            </div>
                                        }
                                        {/* <InputLabel id="co-organization-select-label">{t('form.label_enter_org')} </InputLabel>
                                        <Select
                                             disabled={!canEdit}
                                            labelId="co-organization-select-label"
                                            value={e?.orgId || ''}
                                            // {formValues.permanentAddress?.country}
                                            name="orgId"
                                            onChange={e => {
                                                handleChange(index, e)
                                            }}
                                            label="Name of your organization"
                                        >
                                            {orgData.map((item, idx) => {
                                                return (
                                                    <MenuItem key={idx} value={item?._id}>{item?.name}</MenuItem>
                                                )
                                            })}
                                        </Select> */}
                                    </FormControl>
                                </Col>
                                <Col md={6} className="empSettingCompField fullWidth target_title88"> 
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disabled={!canEdit}
                                            disablePortal
                                            value={DesignationList.find((option) => option.value === e.designation)}
                                            options={DesignationList}
                                            getOptionLabel={(option) => t(option.label)}   
                                            onChange={(e,val )=> handleChangeDesignation(index,'designation', val)}  
                                            inputValue={t(e?.designation) || ''}
                                            onInputChange={(e, val, reason) => handleChangeDesignation(index,'designation', val, reason)}
                                            renderInput={(params) => <TextField {...params} label={t('form.label_your_designation')}  />}
                                        />
                                        {errors && e.designation==='' &&
                                            <div className="error-text">
                                                {t('errors.onboarding_designation_required')}
                                            </div>
                                        }
                                    </FormControl>
                                </Col>

                                {!formValues?.additionalPrivacy && 
                                    <>
                                    <Col md={6} className="mlr-0 fullWidth  mobile-btm-margin target_title88 profileEmpField empSettingCompField ">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                disabled={!canEdit}
                                                disablePortal
                                                name="country"
                                                value={e?.address?.country || ''}
                                                options={newCountryList}
                                                getOptionLabel={(option) => option}
                                                onChange={(e, val) => handleCountryChange(index, val)}
                                                renderInput={(params) => <TextField {...params} label={t('country')} />}
                                            />
                                        </FormControl>


                                    </Col>

                                    <Col md={6} className="empSettingCompField mlr-0 fullWidth  mobile-btm-margin target_title88">
                                        {/* <FormControl fullWidth>
                                        <Autocomplete
                                            disabled={!canEdit}
                                            disablePortal
                                            name="country"
                                            value={e?.address?.country || ''}
                                            options={CountriesList}
                                            getOptionLabel={(option)=>option}                        
                                            onChange={(e, val)=>handleCountryChange(index, val)}
                                            renderInput={(params) => <TextField {...params} label={t('country')} />}
                                        /> */}
                                        {/* <InputLabel id="co-country-select-label">{t('country')} </InputLabel>
                                        <Select
                                             disabled={!canEdit}
                                            labelId="co-country-select-label"
                                            value={e?.address?.country || ''}
                                            onChange={e => handleChange(index, e)}
                                            label={t('country')}
                                            name="country"
                                        >
                                            {CountriesList.map((item, idx) => {
                                                return (
                                                    <MenuItem key={idx} value={item}>{item}</MenuItem>
                                                )
                                            })}
                                        </Select> */}

                                        {/* <TextField 
                                     disabled={!canEdit}
                                    label={t('city')} 
                                    name="city" value={e.address?.city || ''} 
                                    onChange={e => handleChange(index, e)} /> */}

                                        {cityList && cityList[index] && <FormControl fullWidth>
                                            <Autocomplete
                                                disablePortal
                                                disabled={!canEdit}
                                                name="city"
                                                value={e?.address?.city || ''}
                                                options={e?.address?.country ? cityList?.[index] : []}
                                                getOptionLabel={(option) => option}
                                                onChange={(e, val) => handleCityChange(index, val)}
                                                renderInput={(params) => <TextField {...params} label={t('city')} />}
                                            />
                                        </FormControl>}
                                    </Col>
                                </>
                                }
                            </Row>
                            <Row className="empDateRow profile_subcategory99">
                                <Col lg={3} className="empSettingCompField col-md-6 mlr-0 fullWidth empDateFields mobile-btm-margin target_title88">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            closeOnSelect={true}
                                             disabled={!canEdit}
                                            label={t('from')}
                                            disableFuture={true}
                                            value={e.startYear || ''}
                                            onChange={(newDob) => {
                                                handledateChange(index, 'startYear', newDob);
                                            }}
                                            InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{cursor:'pointer'}}/> }}
                                            renderInput={(params) => <TextField {...params}placeholder="DD/MM/YYYY" />}
                                            inputFormat="DD MMM YYYY"
                                        />
                                    </LocalizationProvider>
                                    {errors && e.startYear==='' &&
                                        <div className="error-text">
                                            {t('errors.start_date_required')}
                                        </div>
                                    }
                                </Col>

                                <Col lg={3} className="empSettingCompField col-md-6 mlr-0 fullWidth empDateFields target_title88">
                                {!e.isCurrentlyWorking ?
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            closeOnSelect={true}
                                            label={t('to')}
                                            disableFuture={true}
                                            value={e.endYear || ''}
                                            onChange={(newDob) => {
                                                handledateChange(index, 'endYear', newDob);
                                            }}
                                            InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{cursor:'pointer'}}/> }}
                                            disabled={!canEdit}                                            
                                            renderInput={(params) => <TextField {...params}placeholder="DD/MM/YYYY" />}
                                            inputFormat="DD MMM YYYY"
                                        />
                                    </LocalizationProvider> : 
                                    <TextField 
                                    disabled={true}
                                    label={t('tillDate')}
                                    InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" alt="" style={{cursor:'pointer'}}/> }}
                                    />
                                }
                                {errors && e.endYear==='' && !e?.isCurrentlyWorking &&
                                    <div className="error-text">
                                        {t('errors.end_date_required')}
                                    </div>
                                }
                                {(e.startYear!==null && e.endYear !== null) && !fromToDateValidator(e.startYear, e.endYear) && 
                                    <div className="error-text">
                                        {t('errors.start_end_year')}
                                    </div>}
                                </Col>
                                {(e.isCurrentlyWorking || showWorking) && 
                                <Col md={12} className="isCurrentlyPursuing mt-0">
                                    <GreenCheckBox
                                    disabled={!canEdit}
                                    checked={e.isCurrentlyWorking || false} 
                                    value={1} 
                                    name="isCurrentlyWorking" 
                                    onChange={e => handleCheckboxChange(index, e)}
                                    label={t('form.label_currently_work_here')}/>
                                </Col>}
                            </Row>
                        </div>
                    ))}
                        {!formValues.classifyYourJob &&
                            <Row className="mb-3">
                                <Col className="removeMargin">
                                    <button
                                        disabled={!canEdit}
                                        className="button add_more_record m-0" onClick={addFormFields}> + {t('Add_more_Record')} </button>
                                </Col>
                            </Row>
                        }
                        
                    </Box>
                    </div>                   
                </div>
            </div>
            
        </Row>)
}

export default EmployementSetting;