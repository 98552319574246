import React from "react";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@mui/material";
import FormTextArea from "../../../commonComponents/formTextArea";
import { validEmail, websiteValidator } from "../../../utils/formatValidator";
const FooterCms = ({ formValues, setFormValues, error }) => {
    const { t } = useTranslation();
    return (<>
        <SectionHeader pretitle="" title={t('footer.Footer')} />
        <Row>
            <Col md={6}>
                <Row className="grid">
                    <div className="grid-column myProfileSettings">
                        <div className="">
                            <div className="widget-box-content cms_footer_box px-3 p-4">
                                <Col md={12} className="cmsFooterFields">
                                    <h6 className="mb-0">{t('social_Media')}</h6>
                                    <TextField
                                        label={t('Facebook')}
                                        value={formValues?.socialFacebook}
                                        onChange={(e) => setFormValues({ ...formValues, socialFacebook: e.target.value })}
                                    />
                                    {formValues?.socialFacebook !== '' &&
                                        !websiteValidator(formValues?.socialFacebook) &&
                                        <div className="error-text">
                                            {t('errors.enter_correct_url')}
                                        </div>
                                    }
                                    {error && formValues?.socialFacebook === '' &&
                                        <div className="error-text">
                                            {t('Please_Enter_URL')}
                                        </div>
                                    }
                                    <TextField label={t('Twitter')}
                                        value={formValues?.socialTwitter}
                                        onChange={(e) => setFormValues({ ...formValues, socialTwitter: e.target.value })}
                                    />
                                    {formValues?.socialTwitter !== '' &&
                                        !websiteValidator(formValues?.socialTwitter) &&
                                        <div className="error-text">
                                            {t('errors.enter_correct_url')}
                                        </div>
                                    }
                                    {error && formValues?.socialTwitter === '' &&
                                        <div className="error-text">
                                           {t('errors.enter_correct_url')}
                                        </div>
                                    }
                                    <TextField label={t('Google')}
                                        value={formValues?.socialGoogle}
                                        onChange={(e) => setFormValues({ ...formValues, socialGoogle: e.target.value })}
                                    />
                                    {(formValues?.socialGoogle !== '') &&
                                        !websiteValidator(formValues?.socialGoogle) &&
                                        <div className="error-text">
                                            {t('errors.enter_correct_url')}
                                        </div>
                                    }
                                    {error && formValues?.socialGoogle === '' &&
                                        <div className="error-text">
                                           {t('errors.enter_correct_url')}
                                        </div>
                                    }
                                    <TextField label={t('LinkeIn')}
                                        value={formValues?.socialLinkedin}
                                        onChange={(e) => setFormValues({ ...formValues, socialLinkedin: e.target.value })}
                                    />
                                    {(formValues?.socialLinkedin !== '') &&
                                        !websiteValidator(formValues?.socialLinkedin) &&
                                        <div className="error-text">
                                            {t('errors.enter_correct_url')}
                                        </div>
                                    }
                                    {error && formValues?.socialLinkedin === '' &&
                                        <div className="error-text">
                                            {t('errors.enter_correct_url')}
                                        </div>
                                    }
                                </Col>
                            </div>
                        </div>
                    </div>
                </Row>
            </Col>
            <Col md={6}>
                <Row className="grid">
                    <div className="grid-column myProfileSettings">
                        <div className="">
                            <div className="widget-box-content cms_footer_box p-4">
                                <Col md={12}>
                                    <h6 className="mb-4">{t('form.contact_us_text')}</h6>
                                    <TextField label={t('Phone_No')}
                                        value={formValues?.contactUs?.phoneNo}
                                        onChange={(e) => setFormValues({
                                            ...formValues, contactUs: {
                                                ...formValues?.contactUs,
                                                phoneNo: e.target.value.replace(/[^0-9 +]/g, '')
                                            }
                                        })}
                                    />
                                    <div className="mb-4">
                                        <TextField label={t('Email')}
                                            value={formValues?.contactUs?.Email}
                                            className="mb-0"
                                            onChange={(e) => setFormValues({
                                                ...formValues, contactUs: {
                                                    ...formValues?.contactUs,
                                                    Email: e.target.value
                                                }
                                            })}
                                        />
                                        {formValues?.contactUs?.Email !== '' && formValues?.contactUs?.Email !== null &&
                                            !validEmail(formValues?.contactUs?.Email) &&
                                            <div className="error-text mt-2">
                                                {t('errors.email_format')}
                                            </div>
                                        }
                                    </div>
                                    
                                    <div className="cms_footer_address_comp">
                                    <FormTextArea label="Address"
                                        value={formValues?.contactUs?.Address}
                                        rows={4}
                                        className={"cms_footer_address"}
                                        onChange={(e) => ( e.target.value.length <= 100)?setFormValues({
                                            ...formValues, contactUs: {
                                                ...formValues?.contactUs,
                                                Address: e.target.value
                                            }
                                        }) : ''}
                                    />
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>
                </Row>
            </Col>
        </Row>
    </>)
}
export default FooterCms;
