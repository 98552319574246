import React from "react";
import { Row, Col } from "react-bootstrap";
import { AvatarImg } from "../../../commonComponents/Images";
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { useEffect, useState, useRef } from "react";
import { getfollowerList, orgBlockOrFollowOrUnfollowOrUnblock } from "../../../services/follower.service";
import { getImageUrl, showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import BarsLoader from "../../../commonComponents/barsLoader";
import swal from 'sweetalert';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const AllFollowers = ({ searchBy, countsOfFriends, callParentCountMethod }) => {
    const limit = 18;
    let defaultUserImage = AvatarImg;
    const [loading, setLoading] = useState(false);
    const [noReqMsg, setNoReqMsg] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const observer = useRef();
    const [PageNo, setPageNo] = useState(0);
    const [followersList, setFollowerList] = useState([]);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const lastElement = (node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((enteries) => {
            if (enteries[0].isIntersecting && hasMore) {
                setPageNo((prev) => prev + 1);
            }
        })
        if (node) {
            observer.current.observe(node);
        }
    }

    useEffect(() => {
        //setSuggestFriendList(suggestFriendList.splice(0));
        setFollowerList([]);
        setPageNo(0)
    }, [searchBy]);

    const getAllFollower = (currentPage) => {
        setLoading(true);
        setTimeout(async () => {
            getfollowerList(currentPage, limit, 'followerList', searchBy)
                .then((resp) => {
                    const user = resp?.data?.data?.data;
                    const user2 = user.map(a => ({ ...a, blockIS: false }));
                    let newFollower = [...followersList, ...user2];
                    if (PageNo === 0) newFollower = [...user2]
                    let newArr = newFollower.reduce((acc, curr) => {
                        let fnd = acc.find((a) => a._id === curr?._id);
                        if (!fnd) acc.push(curr);
                        return acc;
                    }, []);
                    newArr = newArr?.filter(a => !a?.followIS);
                    if (searchBy) {
                        // console.log("")
                    }
                    setFollowerList(newArr);
                    countsOfFriends(resp?.data?.data?.totalCount);
                    setLoading(false);
                    setHasMore(resp?.data?.data?.data?.length > 0 && user?.length === limit);
                    setNoReqMsg((resp?.data?.data?.totalCount) > 0 ? false : true);


                })
                .catch((error) => {
                    showToastError(t(`errors.Some_Error_Occurred_Please_try_again_later`));

                })
        }, 700)

    }
    useEffect(() => {
        getAllFollower(PageNo)
    }, [searchBy, PageNo])

    const blockUser = (e, org, index) => {
        e.preventDefault();
        let param = {
            list: [org?._id],
            action: "blockUser"
        }
        swal({
            text: t('alerts.block_employee'),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
            .then((val) => {
                if (!val) return
                orgBlockOrFollowOrUnfollowOrUnblock(param)
                    .then((resp) => {
                        if (resp?.status === 200) {
                            const nextList = [...followersList];
                            let index1 = followersList?.findIndex(a => a._id === org?._id);
                            if (index >= 0) {
                                nextList[index]['blockIS'] = true;
                                setFollowerList(nextList);
                                let pageNo1 = Math.floor(index1 / limit);
                                getAllFollower(pageNo1)
                                followersList?.splice(index, 1);
                                showSingleToastSuccess(`${t('You_Blocked')} ${org?.firstName}`);
                                callParentCountMethod();
                            }
                        }
                    })

            })
    }
    const showUser = (user) => {
        let encrptedId = window.btoa(user?._id);
        //navigate(`/profile/${encrptedId}`)
        navigate(`/profile/${encrptedId}`, { state: { userId: user?._id } })
    }

    return (
        <Row className="mt-4 orgMobile">
            {followersList?.length > 0 ? <>
                {followersList?.length > 0 && followersList?.map((item, index) => {
                    if (followersList?.length === index + 1) {
                        return <div key={index} className="col-md-3 mb-4 col-xl-2 col-sm-4" ref={lastElement}>
                            <div className="widget-box friends-list-box h-100">
                                <div className="d-flex flex-column h-100">

                                    <div onClick={() => {
                                        if (item.status === "active") showUser(item)
                                        else showToastError("User is inactive.")
                                    }} className="m-2 flex1">
                                        <AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : defaultUserImage} />

                                        <p className='bold'>{(`${item?.firstName} ${item?.lastName}`) || ''}</p>
                                        <small>{item?.userName ? `@${item?.userName}` : ''}</small>
                                    </div>
                                    <button className="button white white-tertiary" onClick={(e) => blockUser(e, item, index)}> {t('button.block_text')} </button>
                                </div>
                            </div>
                        </div>
                    }
                    else {
                        return <div key={index} className="col-md-3 mb-4 col-xl-2 col-sm-4">
                            <div className="widget-box friends-list-box h-100">
                                <div className="d-flex flex-column h-100">
                                    <div onClick={() => { if (item.status === "active") showUser(item); else showToastError("User is Inactive.") }} className="m-2 flex1">
                                        <AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : defaultUserImage} />
                                        <p className=''>{(`${item?.firstName} ${item?.lastName}`) || ''}</p>
                                        <small>{item?.userName ? `@${item?.userName}` : ''}</small>
                                    </div>
                                    <button className="button white white-tertiary" onClick={(e) => blockUser(e, item, index)}> {t('button.block_text')} </button>
                                </div>
                            </div>
                        </div>

                    }
                }

                )}
            </> : noReqMsg === true ? <h4 className='nopeMsg'>{searchBy ? t('errors.No_Follower_Found_in_search') : t('errors.No_Followers_Found')}</h4> : ''}
            {
                loading ?
                    <BarsLoader />
                    : ''
            }

        </Row>
    )
}

export default AllFollowers;