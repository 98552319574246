import { t } from "i18next"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { GetMyMemoriesService } from "../services/post.services";
import { Col, Row } from "react-bootstrap";

const Memories = () => {
    const [postList, setPostList] = useState([]);

    useEffect(()=>{
        getMemories();
        window.scrollTo(0, 0)
    }, [])

    const getMemories = async () => {
        try {
            const response = await GetMyMemoriesService()
            if (response?.data) {
                let  newArr = [ ...response?.data];
                setPostList(newArr);
            }
        }catch (error) {
            console.log(error);
        }
    }
    return (<>
        <div className="widget-box memoriesBox">
          <p className="widget-box-title textalign-left">Memories</p>
          <div className="widget-box-content">
              {postList?.length > 0 && postList.map((item, index) => {
                    return <div className="mb-3 d-flex justify-content-between align-items-center">
                        <img className="memoriesIcon" src="/Assets/Memories-icon-with-bg.png" />
                        <span className="memoriesText" key={index}>{item?.post}</span>
                    </div>
              })  
              }
              <Link to="/memories" className="button small primary target-btn pd-none">{t('button.See_All_Memories')}</Link>
          </div>
        </div>
      
    </>
    )
}

export default Memories;