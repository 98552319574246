import '../details.style.css';
import { useTranslation } from 'react-i18next';
import DemographicInfo from './demographicInfo';
import ContactInfo from './contactInfo';
import ProfileInfo from './profileInfo';
import EmploymentInfo from './employementInfo';
import InterestSettings from './interestSetting';
import EducationInfo from './educationInfo';
import Invites from './invites';
import DetailsSideBar from './sidebar';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GetUserDetailsService, 
        GetInterestsListService, 
        getInvitesListService, 
        addNewInterestService,
        searchUsersService,
        addNewUserEmpService,
        updateUserEmpService,
        deleteUserEmpService,
        updateAdditionalEmpService
     } from '../../../services/onBoarding.services';
import { getUserDetailsService } from '../../../services/auth.services';
import { saveUserDetailsService } from "../../../services/onBoarding.services";
import { showSingleToastSuccess, showToastError, showToastSuccess } from "../../../utils/helpers";
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { setCurrentRole, setCurrentUser } from '../../../Reducer/user/user.action';
const UserDetails = () => {
    const {t} = useTranslation();
    const maxDate = moment().subtract(18, "years");
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0); 
    const [interests, setInterests] = useState([]);
    const [invites, setInvites] = useState([]);
   // const [check, setCheck] = useState([])
    const navigate = useNavigate();
    const [additionalInterest, setAdditionalInterest] = useState('');
    const INITIAL_FORM_VALUES = {
            'base_url' : `${window.location.origin}`,
        //demographic details 
            'firstName'  : '',
            'middleName'  : '',
            'lastName'  : '',
            'aboutMe'  : '',
            'dob'  : '',
            'gender'  : '',
            'profilePicture'  : '',
            'coverPicture'  : '',
            'userNameCount': 0,

        //Contact Info
            'user_email' : '',
            'user_mobile' : '',
            'email'  : '',
            'mobile'  : '',
            'isEmailVerify'  : false,
            'isMobileVerify'  : false,
            'currentAddress'  : {city : "", country:""},
            'permanentAddress'  : {city : "", country:""},

        //Username & Profile URL
            'default_userName' : '',
            'userName' : '',
            'url' : '',

        //employement Info 
            'classifyYourJob' : false,
            'additionalPrivacy' : false,
            'organization' : [],
        
        //education Info 
            'education' : [],
        
        //Interests
            'interests' : [],
    }
    const [formValues , setFormValues] = useState(INITIAL_FORM_VALUES);    

    useEffect(() => {
       getData();
    },[])

    useEffect(()=>{
        document.body.classList.remove('dashboard');
    }, [])

    const getData = async() => {
        await getUserDetails();
        await getInterestsLists();
        await getInvitesList();
    }

    const getUserDetails = async() => {
        GetUserDetailsService()
        .then((data)=>{
            if(data?.data?.status === 200){
                let FORM_VALUES = {
                        'base_url' : `${window.location.origin}`,
                    //demographic details 
                        'firstName'  : (data?.data?.data?.firstName)?data?.data?.data?.firstName:'',
                        'middleName'  : (data?.data?.data?.middleName)?data?.data?.data?.middleName:'',
                        'lastName'  : (data?.data?.data?.lastName)?data?.data?.data?.lastName:'',
                        'aboutMe'  : (data?.data?.data?.aboutMe)?data?.data?.data?.aboutMe:'',
                        'dob'  : (data?.data?.data?.dob)?data?.data?.data?.dob:'',
                        'gender'  : (data?.data?.data?.gender)?data?.data?.data?.gender:'',
                        'profilePicture'  : (data?.data?.data?.profilePicture)?data?.data?.data?.profilePicture:'',
                        'coverPicture'  : (data?.data?.data?.coverPicture)?data?.data?.data?.coverPicture:'',
            
                    //Contact Info
                        'user_email' : (data?.data?.data?.email)?data?.data?.data?.email:'',
                        'user_mobile' : (data?.data?.data?.mobile)?data?.data?.data?.mobile:'',
                        'email'  : (data?.data?.data?.email)?data?.data?.data?.email:'',
                        'mobile'  : (data?.data?.data?.mobile)?data?.data?.data?.mobile:'',
                        'isEmailVerify'  : (data?.data?.data?.isEmailVerify)?data?.data?.data?.isEmailVerify:false,
                        'isMobileVerify'  : (data?.data?.data?.isMobileVerify)?data?.data?.data?.isMobileVerify:false,
                        //'currentAddress'  : (data?.data?.data?.currentAddress)?data?.data?.data?.currentAddress:{city : "", country:""},
                        'currentAddress'  : {
                            city:(data?.data?.data?.currentAddress?.city)?data?.data?.data?.currentAddress?.city:'',
                            country:(data?.data?.data?.currentAddress?.country)?data?.data?.data?.currentAddress?.country:'',
                                                },
                        'permanentAddress'  : {
                            city:(data?.data?.data?.permanentAddress?.city)?data?.data?.data?.permanentAddress?.city:'',
                            country:(data?.data?.data?.permanentAddress?.country)?data?.data?.data?.permanentAddress?.country:'',
                                                },
            
                    //Username & Profile URL
                        'default_userName' : (data?.data?.data?.userName)?data?.data?.data?.userName.toLowerCase():'',
                        'userName' : (data?.data?.data?.userName)?data?.data?.data?.userName.toLowerCase():'',
                        'userNameCount':(data?.data?.data?.userNameCount)?data?.data?.data?.userNameCount:false,
                        'url' : (data?.data?.data?.userName)?formValues.base_url+'/u/'+data?.data?.data?.userName:'',
            
                    //employement Info 
                        'classifyYourJob' : (data?.data?.data?.classifyYourJob)?data?.data?.data?.classifyYourJob: false,
                        'additionalPrivacy' : (data?.data?.data?.additionalPrivacy)?data?.data?.data?.additionalPrivacy: false,
                        'organization' : (data?.data?.data?.organization)?data?.data?.data?.organization:false,
                    
                    //education Info 
                        'education' : data?.data?.data?.education?.length ? data?.data?.data?.education : [],
                    
                    //interests 
                        'interests' : (data?.data?.data?.spredeInterest)?data?.data?.data?.spredeInterest:[],
                }
                setFormValues(FORM_VALUES)
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    const getInterestsLists = async() => {
        GetInterestsListService()
        .then((data)=>{
            if(data?.data?.status === 200){
                setInterests(data?.data?.data);
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }
    const getInvitesList = async() => {
        getInvitesListService()
        .then((data)=>{
            if(data?.data?.status === 200){
                setInvites(data?.data?.data);
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const searchInviteList = async(keyword) => {
        searchUsersService(keyword, 20,0)
        .then(async(data)=>{
            if(data?.data?.status === 200){
                setInvites({'basedOnSearch':data?.data?.data?.data});
            }
        })
        .catch((error)=>{
            showToastError(t(`apiMessages.${error?.data?.data}`))
        })
    }

    const skipClick = (e) => {
        e.preventDefault();
        (activeIndex === 6) ? navigate('/voxbox') :  setActiveIndex(activeIndex+1);
    }

    const skip = () => {
        (activeIndex === 6) ? navigate('/voxbox') :  setActiveIndex(activeIndex+1);
    }

    const backClick = (e) => {
        e.preventDefault();
        setActiveIndex(activeIndex-1);
    }

    const saveNewInterest = (id, value) => {
        let params = {
            "spredeInterestId" : id,
            "spredeInterestOptions" : value
        }
        addNewInterestService(params)
        .then(async(data)=>{
            if (data.status === 200) {
                await getInterestsLists();
                setAdditionalInterest(data?.data?.data?.insertedId)
            } else {
                showToastError(t(`apiMessages.${data?.data?.data}`))
            }
        })
        .catch((error)=>{
            showToastError(t(`apiMessages.${error?.data?.data}`))
        })
    }

    const addOrgDetail = (params) => {
        try{
            addNewUserEmpService(params)
            .then((data)=>{
                if (data.status === 200) {
                    // showToastSuccess(t('apiMessages.EMP_SAVED_SUCCESSFULLY'));
                    showSingleToastSuccess(t('apiMessages.EMP_SAVED_SUCCESSFULLY'));
                    updateProfileDetails();
                } else {
                    setError(true);
                    showToastError(t(`apiMessages.${data.data.data}`))
                }
            })
            .then(()=>{ getUserDetails(); })
            .catch((error)=>{
                showToastError(t(`apiMessages.${error?.data?.data}`))
            })
        }
        catch(error){
            console.log(error);
        }
    }

    const editEmpDetails = (params) => {
        try{
            updateUserEmpService(params)
            .then((data)=>{
                if (data.status === 200) {
                    // showToastSuccess(t('apiMessages.EMP_UPDATED_SUCCESSFULLY'));
                    showSingleToastSuccess(t('apiMessages.EMP_UPDATED_SUCCESSFULLY'));
                    updateProfileDetails();
                } else {
                    setError(true);
                    showToastError(t(`apiMessages.${data.data.data}`))
                }
            })
            .then(()=>{ getUserDetails(); })
            .catch((error)=>{
                showToastError(t(`apiMessages.${error?.data?.data}`))
            })
        }
        catch(error){
            console.log(error);
        }
    }

    const editUserEmpAddDetails = (params) => {
        try {
            updateAdditionalEmpService(params)
                .then((data) => {
                    if (data.status === 200) {
                        // showToastSuccess(`Employement Detail Updated Successfully!`);
                        // updateProfileDetails();
                    } else {
                        setError(true);
                        showToastError(t(`apiMessages.${data.data.data}`))
                    }
                })
                .then(() => { getUserDetails(); })
                .catch((error) => {
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const deleteEmpDetails = (uOrgId , orgId) => {
        try{
            deleteUserEmpService(uOrgId , orgId)
            .then((data)=>{
                if (data.status === 200) {
                    // showToastSuccess(t('apiMessages.EMP_DELETED_SUCCESSFULLY'));
                    showSingleToastSuccess(t('apiMessages.EMP_DELETED_SUCCESSFULLY'));
                } else {
                    setError(true);
                    showToastError(t(`apiMessages.${data.data.data}`))
                }
            })
            .then(()=>{ getUserDetails(); })
            .catch((error)=>{
                showToastError(t(`apiMessages.${error?.data?.data}`))
            })
        }
        catch(error){
            console.log(error);
        }
    }

    const saveUserDetails = (params) => {
        try{
            saveUserDetailsService(params)
            .then((data)=>{

                if (data.status === 200) {
                    //showToastSuccess(`Data Saved Successfully!`);
                    if(activeIndex === 2){
                        setFormValues({...formValues, userNameCount:1})
                    }
                    updateProfileDetails();
                    if(activeIndex === 6)
                    { navigate('/voxbox'); }
                    else
                    { setActiveIndex(activeIndex+1) }
                } else {
                    setError(true);
                    showToastError(t(`apiMessages.${data.data.data}`))
                }
            })
            .then(()=>{ getUserDetails(); })
            .catch((error)=>{
                showToastError(t(`apiMessages.${error?.data?.data}`))
            })
        }
        catch(error){
            console.log(error);
        }
    }

    const updateProfileDetails = async() => {
        const response = await getUserDetailsService();
        if (response?.data?.data) {
            const user = response?.data?.data
            dispatch(setCurrentUser(user));
            dispatch(setCurrentRole('user'));
        }
    }

    const handleStepperClick = (index) => {
        if(index < activeIndex){
            setActiveIndex(index)
        }
    }


    return (
            <div className="userdetails_row main-content-area">   
                <div className="userdetails-sidebar">             
                    <DetailsSideBar activeIndex={activeIndex} handleStepperClick={handleStepperClick}/>
                </div>
                <div className="userdetails-div">
                    {activeIndex === 0 && <DemographicInfo  
                                            error={error} 
                                            setError={setError}
                                            getUserDetails={getUserDetails} 
                                            formValues={formValues} 
                                            setFormValues={setFormValues} 
                                            activeIndex={activeIndex} 
                                            setActiveIndex={setActiveIndex}
                                            saveUserDetails={saveUserDetails}
                                            skipClick={skipClick}
                                            backClick={backClick}
                                            maxDate={maxDate}/>}
                    {activeIndex === 1 && <ContactInfo 
                                            error={error} 
                                            setError={setError} 
                                            formValues={formValues} 
                                            setFormValues={setFormValues} 
                                            activeIndex={activeIndex}  
                                            setActiveIndex={setActiveIndex}
                                            saveUserDetails={saveUserDetails}
                                            skipClick={skipClick}
                                            backClick={backClick}/>}
                    {activeIndex === 2 && <ProfileInfo 
                                            error={error} 
                                            skip={skip}
                                            setError={setError} 
                                            formValues={formValues} 
                                            setFormValues={setFormValues} 
                                            activeIndex={activeIndex}  
                                            setActiveIndex={setActiveIndex}
                                            saveUserDetails={saveUserDetails}
                                            skipClick={skipClick}
                                            backClick={backClick}/>}
                    {activeIndex === 3 && <EmploymentInfo 
                                            error={error} 
                                            setError={setError} 
                                            formValues={formValues} 
                                            setFormValues={setFormValues} 
                                            activeIndex={activeIndex}  
                                            setActiveIndex={setActiveIndex}
                                            saveUserDetails={saveUserDetails}
                                            skipClick={skipClick}
                                            backClick={backClick}
                                            maxDate={maxDate}
                                            editEmpDetails={editEmpDetails}
                                            editUserEmpAddDetails={editUserEmpAddDetails}
                                            addOrgDetail={addOrgDetail}
                                            deleteEmpDetails={deleteEmpDetails}
                                            />}
                    {activeIndex === 4 && <InterestSettings interests={interests} 
                                            error={error} 
                                            setError={setError} 
                                            formValues={formValues} 
                                            setFormValues={setFormValues} 
                                            activeIndex={activeIndex}  
                                            saveNewInterest={saveNewInterest}
                                            setActiveIndex={setActiveIndex}
                                            saveUserDetails={saveUserDetails}
                                            skipClick={skipClick}
                                            additionalInterest={additionalInterest}
                                            backClick={backClick}/>
                                            }
                    {activeIndex === 5 && <EducationInfo 
                                            error={error} 
                                            setError={setError} 
                                            formValues={formValues} 
                                            setFormValues={setFormValues} 
                                            activeIndex={activeIndex}  
                                            setActiveIndex={setActiveIndex}
                                            saveUserDetails={saveUserDetails}
                                            skipClick={skipClick}
                                            backClick={backClick}
                                            maxDate={maxDate}/>}
                    {activeIndex === 6 && <Invites 
                                            error={error} 
                                            setError={setError} 
                                            formValues={formValues} 
                                            setFormValues={setFormValues} 
                                            activeIndex={activeIndex} 
                                            invites={invites} 
                                            searchInviteList={searchInviteList}
                                            setActiveIndex={setActiveIndex}
                                            saveUserDetails={saveUserDetails}
                                            getInvitesList={getInvitesList}
                                            skipClick={skipClick}
                                            backClick={backClick}/>}
                </div>
            </div>
    );
}

export default UserDetails;