import React, {useEffect, useState} from 'react';
import { Row,Col } from 'react-bootstrap';
import SectionHeader from '../../../commonDashboardComp/sectionHeader';
import { TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useTranslation } from 'react-i18next';
import OrgBox from '../../../commonDashboardComp/orgBox';
import { getAllOrganizationListServices } from '../../../services/adminManage.services';
import { useSelector } from 'react-redux';
import { selectCurrentUserRole } from '../../../Reducer/user/user.selector';
import { loginUserType } from '../../../config/constants';
import BarsLoader from '../../../commonComponents/barsLoader';
import ReactPagination from '../../../commonComponents/reactPagination';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { showToastError } from '../../../utils/helpers';

const ManageOrgs=({})=>{  
  const limit = 50;
  const { t } = useTranslation();
  const role = useSelector(selectCurrentUserRole);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const [exportData, setExportData] = useState('');
  const headers = [
     { label: "Name", key: "name" },
     { label: "Active", key: "isActive" },
     {label:'Email',key:"email"},
     {label:'WebSite',key:'webSite'},
     {label:'Joined',key:'joined'},
     {label:"Address",key:'corporateAddress'},
     {label:'Registration No',key:'regNo'},
     {label:'Admin',key:'admin'},
     {label:'Post Reviewer',key:'post_reviewer'},
     {label:'Event Manager',key:'event_manager'},
     {label:'Total Followers',key:'totalFollowers'}

 
   
   ];

  useEffect(() => {
    getList();
  }, [page, searchText])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    const handlePageClick = (data)=>{
        let Page = data.selected
        setPage(Page)
    }

    const getList = async () => {
        try {
            if (role === loginUserType.SUPER_ADMIN) {
                setLoading(true);
                const response = await getAllOrganizationListServices(page, limit, searchText);
                setLoading(false);
                if (response?.data) {
                    setData(response.data?.data);
                    let pageCount = (response?.data?.totalCount) / (limit);
                    setTotalPage(Math.ceil(pageCount));
                    if (response?.status == 200) {
                        let resp = await getAllOrganizationListServices(0, response?.data?.totalCount, searchText);
                        if(resp?.status==200){
                            let newList = resp?.data?.data?.map((a, index) => {
                
                                let obj={ name: a?.name, isActive: a?.isActive?t('yes'):t('no'),email:a?.email,webSite:a?.webSite,regNo:a?.regNo,totalFollowers:a?.totalFollowers}
                                if(a?.createdAt){
                                   let selectedDate = moment(a?.createdAt);
                                   let currentDate = selectedDate.format('DD MMM,YYYY');
                                   obj['joined']=currentDate;
                                }
                                if(a?.corporateAddress?.[0]?.country ||a?.corporateAddress?.[0]?.country ){
                                  obj['corporateAddress']=a?.corporateAddress?.[0]?.country+"("+a?.corporateAddress?.[0]?.city+")";
                                }
                                if(a?.pageRoles){
                                  let admin=a?.pageRoles?.find(r=>r?.role==='admin');
                                  if(admin)obj['admin']=admin?.userId?.firstName+" "+admin?.userId?.lastName;
                                  let event_manager=a?.pageRoles?.find(r=>r?.role==='event_manager');
                                  if(event_manager)obj['event_manager']=admin?.userId?.firstName+" "+admin?.userId?.lastName;
                                  let post_reviewer=a?.pageRoles.filter((b)=>b?.role==='post_reviewer');
                                  if(post_reviewer?.length>0){
                                    let post_reviewers=post_reviewer?.map((a)=>a?.userId?.firstName+" "+a?.userId?.lastName);
                                    obj['post_reviewer']=post_reviewers?.join();
                                  }
                                }
                                return obj;
                              })
                              setExportData(newList);
                        }
                    
                    }
                }else{
                  showToastError(t(`apiMessages.Some_Error_Occured_Please_try_again_later`))
                }
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const renderList = () => {
      return (        
        <>
            {isLoading ? <BarsLoader/> : <>
                {data.map((item, index) => {
                return (<OrgBox orgData={item} key={index}/>)
                })}
            </>}

            <ReactPagination pageCount={totalPage} onPageChange={handlePageClick}/>
        </>
      )
    }

    const renderEmptyMessage = () => {
        return (
            <>
                {data?.length === 0 && !isLoading &&
                    <>
                        <Row className="notification">
                            <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center">
                                <span className="bold">
                                    {t('no_organizations')}
                                </span>
                            </Col>
                        </Row>
                    </>}
            </>
        )
    }

return (
    <>
      <SectionHeader title={t('superAdmin.manage_org_heading2')} />

      <div className="section-filters-bar v6">
          <div className="section-filters-bar-actions">
              <form className="form manageOrgSearch eventSearch">
              <div className="form-item split">
                  <TextField type='text' label={t('superAdmin.Search_Organizations')}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  />
                  <button className="button primary"
                  onClick={(e) => e.preventDefault()}
                  >
                  <SearchOutlinedIcon className="icon-magnifying-glass" />
                  </button>
              </div>
              </form>
          </div>

          {exportData?.length > 0 && <div className="section-filters-bar-actions">
          <button type="button" className="exportBtn popup-event-creation-trigger" to="#" >
            <CSVLink data={exportData} headers={headers} filename='organization-List'> {t('export_all')}</CSVLink>
          </button>
        </div>
        }
      </div>

      <Row className='grid'>
          <Col md={12} className="grid-column">
              {renderList()}
          </Col>
      </Row>

      {renderEmptyMessage()}
    </>
)

}
export default ManageOrgs;