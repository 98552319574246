import { useTranslation } from 'react-i18next';
import Popup from '../../../../commonComponents/popup';
import DownloadProfile from '../downloadProfile';
const DownloadProfileDialogueBox= ({ open, handleClose}) => {
  const {t} = useTranslation();
  return (
    <Popup open={open} handleClose={handleClose} modalBodyClasses="downloadProfileModal" modalContentClasses="downloadProfileModalContent">
        <p>{t('download_profile_prompt')}</p>
        <div className="downloadProfileBtn">
            <DownloadProfile handleClick={()=>{handleClose()}}/>
        </div>
    </Popup>
  )
}
export default DownloadProfileDialogueBox;