import ApiConfig from "../config/ApiConfig";
import instance, { instanceOrg } from "../AppInterceptor";

export const getVendorService = (page, limit, search) => {
    let finalUrl = ApiConfig.getVendors + '?page=' + page + "&limit=" + limit;
    if (search) finalUrl += '&searchText=' + search;
    return instanceOrg.get(finalUrl);
}

export const postVendorService = (payload) => {
    return instanceOrg.post(ApiConfig.postVendors, payload)
}

export const deleteVendorService = (id) => {
    return instanceOrg.delete(ApiConfig.deleteVendor + id);

}
export const updateVendorService = (payload) => {
    return instanceOrg.post(ApiConfig.updateVendorStatus, payload);

}