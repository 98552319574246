import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { AvatarImg } from "../../../commonComponents/Images";
import { Box, TextField } from "@mui/material";
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { getfollowerList, sendInvitesOrgUser } from "../../../services/follower.service";
import { getImageUrl, showSingleToastSuccess } from "../../../utils/helpers";
import BarsLoader from "../../../commonComponents/barsLoader";
import { useTranslation } from "react-i18next";
const ReferAndInviteFollowers = ({ searchBy, countsOfFriends }) => {
    const n = 3;
    const { t } = useTranslation();
    const [inviteList, setInviteList] = useState([]);
    const limit = 20;
    let defaultUserImage = AvatarImg;
    const [loading, setLoading] = useState(false);
    const [noReqMsg, setNoReqMsg] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [inviteLoading, setInviteLoading] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [error, setError] = useState(false);
    const observer = useRef();
    const [PageNo, setPageNo] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);

    const lastElement = (node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((enteries) => {
            if (enteries[0].isIntersecting && hasMore) {
                setPageNo((prev) => prev + 1);
            }
        })
        if (node) {
            observer.current.observe(node);
        }
    }


    useEffect(() => {
        //setSuggestFriendList(suggestFriendList.splice(0));
        setInviteList([]);
        setPageNo(0)
    }, [searchBy]);

    useEffect(() => {
        getInvitesList(PageNo);
    }, [searchBy, PageNo])

    const getInvitesList = (currentPage) => {
        setLoading(true)
        getfollowerList(currentPage, limit, 'userInvites', searchBy)
            .then((resp) => {
                const user = resp?.data?.data?.data;
                const user2 = user?.map(a => ({ ...a, inviteReqIS: false }));
                let newInvite = [...inviteList, ...user2];
                if (PageNo === 0) newInvite = [...user2]
                let newArr = newInvite?.reduce((acc, curr) => {
                    let fnd = acc.find((a) => a._id === curr?._id);
                    if (!fnd) acc.push(curr);
                    return acc;
                }, []);
                newArr = newArr?.filter(a => !a?.inviteReqIS);
                if (searchBy) {
                    console.log("")
                }

                setInviteList(newArr);
                countsOfFriends(resp?.data?.data?.totalCount);
                setLoading(false);
                setHasMore(resp?.data?.data?.data?.length > 0 && user?.length === limit);
                setNoReqMsg((resp?.data?.data?.totalCount) > 0 ? false : true);

            })
    }

    const sendInvite = (e, org, index) => {
        e.preventDefault();
        let param = {
            invites: [org?._id],
            inviteType: "inviteByUserId"
        }
        sendInvitesOrgUser(param).
            then((resp) => {
                if (resp?.status == 200) {


                    const nextList = [...inviteList];
                    let index1 = nextList?.findIndex(a => a._id === org?._id);
                    if (index >= 0) {
                        nextList[index]['inviteReqIS'] = true;
                        setInviteList(nextList);
                        let pageNo1 = Math.floor(index1 / limit);
                        getInvitesList(pageNo1)
                        inviteList?.splice(index, 1);
                        showSingleToastSuccess(t(`friendsMsg.Invite_Sent`));
                    }
                }
            })
    }

    const sendinviteViaMail = (e) => {
        e.preventDefault();
        setError(false)
        if (!checkingEmailValid(emailAddress)) {
            setError(true); return;
        }
        if (emailAddress) {
            if (!error) {
                let param = {
                    invites: [emailAddress],
                    inviteType: "inviteByUserEmail"
                }
                sendInvitesOrgUser(param)
                    .then((resp) => {
                        if (resp?.status == 200) {
                            showSingleToastSuccess(t(`friendsMsg.Invite_Sent`));
                            setEmailAddress("");
                        }
                    })

            }
        }



    }

    const handleChange = (e) => {
        e.preventDefault();
        setEmailAddress(e.target.value);
        // if (e.target.value) {
        //     if (!checkingEmailValid(e.target.value)) {
        //         setError(true);
        //     }
        //     else {
        //         setError(false);

        //     }
        // }

    }
    const checkingEmailValid = (email) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{3})+$/.test(email)) return true
        return false;
    }

    return (
        <Row className="grid">
            <Col md={7} lg={6} className=" grid-column">
                {inviteList?.length > 0 ? <>

                    {inviteList?.length > 0 && inviteList?.map((item, index) => {
                        if (inviteList?.length === index + 1) {
                            return <div className="widget-box pa-20" key={index} ref={lastElement} >
                                <div className="target_box_area m-none">
                                    <div className="avtarImgBlck"><AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : defaultUserImage} /></div>
                                    <div className='fullWidthRef'>
                                        <p className="user-status-title">
                                            <span className="bold">{(`${item?.firstName} ${item?.lastName}`) || ''}</span>
                                        </p>
                                        <small>{item?.userName? `@${item?.userName}`:''}</small>
                                    </div>
                                        <div>
                                            {
                                                item?.inviteReqIS ?
                                                    <button className="btn invite_btn_only">{t(`friendsMsg.Invite_Sent`)}</button>
                                                    : <button className="btn invite_btn_only" onClick={(e) => sendInvite(e, item, index)}>{t(`invite`)}</button>
                                            }
                                        </div>
                                </div>
                            </div>
                        }
                        else {
                            return <div className="widget-box pa-20" key={index}>
                                <div className="target_box_area m-none">
                                    <div className="refer_invite_area_inner">
                                    <div className="avtarImgBlck"><AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : defaultUserImage} /></div>
                                    <div className="refer_invite_heading_area">
                                        <h6 className="">{(`${item?.firstName} ${item?.lastName}`) || ''}</h6>
                                        <p>{item?.userName? `@${item?.userName}`:''}</p>
                                    </div>
                                    </div>
                                    
                                        <div className="">
                                            {
                                                item?.inviteReqIS ?
                                                    <button className="btn invite_btn_only">{t(`friendsMsg.Invite_Sent`)}</button>
                                                    : <button className="btn invite_btn_only" onClick={(e) => sendInvite(e, item, index)}>{t(`invite`)}</button>
                                            }

                                        </div>
                                </div>
                            </div>
                        }
                    }

                    )}
                </> : noReqMsg === true ? <h4>{t(`friendsMsg.No_users_in_Invite_List`)}</h4> : ''}
                {
                    loading ?
                        <BarsLoader />
                        : ''
                }
            </Col>

            <Col md={5} lg={6} className=" grid-column">
                <div className="widget-box area-widget">
                    <p className="widget-box-title">{t(`errors.Refer_Invite_Via_Email`)}</p>
                    <div className="widget-box-content m-none">
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Row>

                                <Col md={12}>
                                    <TextField type='text' label={t(`form.label_email`)} id='email' value={emailAddress} onChange={(e) => handleChange(e)} />
                                    {error && !checkingEmailValid(emailAddress) &&
                                        <div className="error-text mt-20">
                                            {t(`errors.Email_Not_Correct`)}
                                        </div>
                                    }
                                    <button className="button primary mt-20" disabled={error && emailAddress === ''} onClick={(e) => sendinviteViaMail(e)}> {t(`errors.Send_Invite`)} </button>
                                </Col>
                            </Row>
                        </Box>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default ReferAndInviteFollowers;