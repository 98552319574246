import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
    FeaturesProfileImg,
    FeaturesCommImg,
    FeaturesEngageImg,
    HowItWorksDivImg,
} from "../../commonComponents/Images";
import { getImageUrl } from "../../utils/helpers";
const Features = ({ cmsData }) => {
    const { t } = useTranslation();
    const features_slider_settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: (cmsData?.features?.length >= 3 ) ? 3 : cmsData?.features?.length,
        arrows: false,
        slidesToScroll: 1,
        className: 'slides',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <Container fluid className="feat-div">
            <div className="">
                <h1> {t('homepage.features.title')} </h1>
            </div>

            <Row className="slider-width-area feature_slider pb-4">
                <Slider {...features_slider_settings}>
                    {cmsData?.features?.length > 0 && cmsData?.features?.map((item, index) => (
                        <div className="features-box-area" key={index}>
                            <img src={item?.image !== '' &&
                                getImageUrl(cmsData?._id, 'cms', item?.image)} className="user_attach_upload" />
                            <h3 className="heading"> {item?.Title} </h3>
                            <p className="desc">{item?.Text}</p>
                        </div>
                    ))}
                </Slider>
            </Row>

        </Container>
    );
}

export default Features;