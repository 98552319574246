import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../Reducer/user/user.selector";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RoundRadio from "../../../commonComponents/roundCheckbox/roundRadio";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { recurringTypeList, weekDaysList } from "../../../config/constants";
import {
    Box, TextField,
    Select,
    MenuItem,
    FormControlLabel,
    InputLabel,
    FormControl, Button, OutlinedInput, ImageList
} from '@mui/material';
const EventConfiguration = ({ event }) => {
    const {t} = useTranslation();
    const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };
    const [unitType, setUnitType] = useState((t(`${event?.unitType?.replace(/\s+/g, '_').toLowerCase()}`) !== undefined) ? 
    t(`${event?.unitType?.replace(/\s+/g, '_').toLowerCase()}`) :
    `${event?.unitType}` );
    return (
        <>
            <div className="widget-box">

                <p className="widget-box-title evidenceHeading">{t('event.Event_Configuration')} </p>
                <Row className="grid">
                    <div className="grid-column">
                        <div className="widget-box orgBlock">
                            <div className="widget-box-content newTargetTag">
                                <Row className="grid eventConfigContainer">
                                    <Col lg={3} className="col-sm-6">
                                        <h6 className="mb-3">{t('event.Contribution')}</h6>
                                    {event?.modeOfParticipation &&
                                        <RoundRadio name="modeOfParticipation"
                                            label={event?.modeOfParticipation}
                                            checked={true}
                                            readOnly
                                            id='modeofParticpant'
                                        />
                                    }
                                    </Col>
                                    <Col lg={3} className="col-sm-6 eventIncentiveBox">
                                        <h6 className="mb-3">{t('incentive')}</h6>
                                        {event?.incentive && <>                                    
                                            {event?.incentive !== 'unincentivized' ?
                                                <RoundRadio name="incentivePrize"
                                                    // label={event?.incentivePrize}
                                                    label={(event.incentivePrize !="")?event.incentivePrize:event.incentive}
                                                    checked={true}
                                                />:
                                                <RoundRadio name="incentive"
                                                label={event?.incentive}
                                                checked={true}
                                            />
                                            }
                                        </>
                                        }
                                    </Col>
                                    <Col lg={12} className="eventDate col-sm-12 eventFreqBox" >
                                        <h6 className="mb-3">{t('Frequency')}</h6>
                                    {event?.frequency &&
                                        <div className='mb-3'>
                                            <RoundRadio name="frequency"
                                                label={t(`${event?.frequency}` )}
                                                checked={true}
                                            />
                                        </div>
                                        }

                                        <Row className="EventConfigFrequency">
                                            <Col md={6} className="startDateBlck mb-20" >
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        closeOnSelect={true}
                                                        label={t('superAdmin.Start_Date')}
                                                        disabled={true}
                                                        value={new Date(event.startDate).toLocaleDateString('en-US', dateOptions)}
                                                        renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                                                        inputFormat="DD MMM YYYY"
                                                    />
                                                </LocalizationProvider>
                                            </Col>
                                            <Col md={6} className="startDateBlck FreqDateBox">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        closeOnSelect={true}
                                                        label={t('superAdmin.End_Date')}
                                                        disabled={true}
                                                        value={new Date(event.endDate).toLocaleDateString('en-US', dateOptions)}
                                                        renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                                                        inputFormat="DD MMM YYYY"
                                                    />
                                                </LocalizationProvider>

                                            </Col>

                                            {event?.frequency === 'recurring' ? <>
                                                <Col md={6} className="mb-20">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="target-type">{t('Recurring_Type')}</InputLabel>
                                                        <Select
                                                            labelId="target-type"
                                                            value={event?.recurringType}
                                                            label={t('superAdmin.Target_Type')}
                                                            disabled={true}
                                                        >
                                                            {recurringTypeList.map((item, idx) => {
                                                                return (
                                                                    <MenuItem key={idx} value={item.value}>{item.name}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Col>

                                                {event.recurringType === 'weekly' &&
                                                    <Col md={6}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="target-type">{t('superAdmin.Weekday')}</InputLabel>
                                                            <Select
                                                                labelId="target-type"
                                                                value={event?.weekday}
                                                                label={t('superAdmin.Target_Type')}
                                                                disabled={true}
                                                            >
                                                                {weekDaysList.map((item, idx) => {
                                                                    return (
                                                                        <MenuItem key={idx} value={item.value}>{item.label}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                }

                                                {/* {(event.recurringType !== 'weekly' && event.recurringType !== 'daily') &&
                                                    <Col md={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="target-type">{t('superAdmin.Recurring_date')}</InputLabel>
                                                            <Select
                                                                labelId="target-type"
                                                                // value={validate?.eventdate}
                                                                value={1}
                                                                label={t('superAdmin.Target_Type')}
                                                                disabled={true}
                                                            >
                                                                {[...Array(31)].map((item, idx) => {
                                                                    return (
                                                                        <MenuItem key={idx} value={idx + 1}>{idx + 1}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                } */}


                                            </>
                                                : ''}
                                        </Row>
                                    </Col>
                                    <Col lg={12} className="col-sm-12 eventIncentiveBox">
                                        <h6 className="mb-3">{t('units')}</h6>
                                        {event?.incentive &&
                                        <RoundRadio name="units"
                                            label={`${event?.unit} ${(t(`${event?.unitType?.replace(/\s+/g, ' ').toLowerCase()}`) !== undefined) ? 
                                            t(`${event?.unitType?.replace(/\s+/g, ' ').toLowerCase()}`) :
                                            `${event?.unitType}`}`}
                                            checked={true}
                                        />
                                        }
                                  
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </div>
                </Row>
            </div>
        </>
    )

}

export default EventConfiguration;