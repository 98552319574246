import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../commonComponents/Loader'
import { getAllglobalList } from '../../../services/onBoarding.services'
import AllEventsSerchList from './allEventsSearchList'
import AllOrgSearchList from './allOrgSearchList'
import AllUserSearchList from './allUserSearchList'
import { useSelector } from 'react-redux'
import { selectSearchText } from '../../../Reducer/user/user.selector'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

function AllGlobalSearchList() {
    const { t } = useTranslation();
    let data = useSelector(state => state.user)
    //Search data
    const searchText = useSelector(selectSearchText)

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(searchText!==""){
            setIsLoading(true)
            allData();
        }else {setIsLoading(false)}
       
    }, [searchText])



    const allData = async () => {
        await getUsersList();
        await getOrganizationList();
        await getEventList();
    }
    const count = 4

    //Users List
    const [usersList, setUsersList] = useState([])
    const getUsersList = async () => {
        let searchKey = !!searchText ? searchText : 'i'
        getAllglobalList(searchKey, 'user', '', count)
            .then((response) => {
                if (response.data.status === 200) {
                    const _data = response.data.data.data;
                    setUsersList(_data)
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    //Organization List
    const [organizationList, setOrganizationList] = useState([])
    const getOrganizationList = async () => {
        let searchKey = !!searchText ? searchText : 'i'
        getAllglobalList(searchKey, 'organization', 'page', 4)
            .then((response) => {
                if (response.data.status === 200) {
                    const _data = response.data.data.data;
                    setOrganizationList(_data);
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    //Event List
    const [eventList, setEventList] = useState([])
    const getEventList = async () => {
        let searchKey = !!searchText ? searchText : 'i'
        getAllglobalList(searchKey, 'event', 'page', 4)
            .then((response) => {
                if (response.data.status === 200) {
                    const _data = response.data.data.data;
                    setEventList(_data)
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    return (
        <div>
            <Row className="grid">
                <div className="grid-column">
                    <h6 className="widget-box-title" style={{marginTop: 10}}>{t('form.Users')}</h6>
                    <div className="widget-box">
                        <>
                            {isLoading ? <Loader /> : usersList.length > 0 ?
                                <>
                                    <AllUserSearchList
                                        usersList={usersList}
                                        isLoading={isLoading}
                                    />
                                </> :
                                <>
                                    {usersList.length === 0 &&
                                        <>
                                            <div style={{ display: 'flex', marginTop: 10 }}>
                                                {t('form.There_is_no_Users')}
                                            </div>
                                        </>
                                    }
                                </>
                            }

                        </>
                        {
                            usersList.length > 0 &&
                            <div className="col-12">
                                <div className="globalSearchViewBtn mt-3">
                                    <button
                                        // onClick={() => onAction(e._id)}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate('/allGlobalUserSearchComp')
                                        }}
                                        className="button primary view-event-btn">{t('button.view_more')}
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Row>
            <Row className="grid">
                <div className="grid-column">
                    <h6 className="widget-box-title" style={{ marginTop: 10 }}>{t('form.Organization')}</h6>
                    <div className="widget-box">
                        <>
                            {isLoading ? <Loader /> : organizationList.length > 0 ?
                                <>
                                    <AllOrgSearchList
                                        organizationList={organizationList}
                                        isLoading={isLoading}
                                    />
                                </> :
                                <>
                                    {organizationList.length === 0 &&
                                        <>
                                            <div style={{ display: 'flex', marginTop: 10 }}>
                                                {t('form.There_is_no_Organization')}
                                            </div>
                                        </>
                                    }
                                </>
                            }

                        </>
                        {
                            organizationList.length > 0 &&
                            <div className="col-12">
                                <div className="globalSearchViewBtn mt-3">
                                    <button
                                        // onClick={() => onAction(e._id)}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate('/allGlobalOrgSearchComp')
                                        }}
                                        className="button primary view-event-btn">{t('button.view_more')}
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Row>
            <Row className="grid">
                <div className="grid-column">
                    <h6 className="widget-box-title" style={{ marginTop: 10 }}>{t('form.Events')}</h6>
                    <div className="widget-box">
                        <>
                            {isLoading ? <Loader /> : eventList.length > 0 ?
                                <>
                                    <AllEventsSerchList
                                        eventList={eventList}
                                        isLoading={isLoading}
                                    />
                                </> :
                                <>
                                    {eventList.length === 0 &&
                                        <>
                                            <div style={{ display: 'flex', marginTop: 10 }}>
                                            {t('errors.There_is_no_Events')}
                                            </div>
                                        </>
                                    }
                                </>
                            }

                        </>
                        {
                            eventList.length > 0 &&
                            <div className="col-12">
                                <div className="globalSearchViewBtn mt-3">
                                    <button
                                        // onClick={() => onAction(e._id)}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate('/allGlobalEventSearchComp')
                                        }}
                                        className="button primary view-event-btn">{t('button.view_more')}
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Row>
        </div>
    )
}

export default AllGlobalSearchList
