import React from "react";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import FormTextArea from "../../../commonComponents/formTextArea";
import { getImageUrl } from "../../../utils/helpers";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
const HowItWorksCms = ({formValues, howItWorksFiles, setPhoto, removeImage, addInstance, removeInstance, handleFeatureChange}) => {
    const {t} = useTranslation();
    return (<>
        <SectionHeader pretitle="" title={t('homepage.howItWorks.title')}/>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '98%' },
            }}
            noValidate
            autoComplete="off"
            className="userdetail-form mobile_profile_margin row p-0"
            >
                {howItWorksFiles?.length > 0 && howItWorksFiles?.map((item, index)=>{
                    return (                        
                        <Row key={index}>   
                            {/* <Col md={12} lg={12}>
                                {
                                    index ? <><div className="delete_row" onClick={(e) => removeInstance(e, 'howItWorks', index)}><DeleteForeverIcon /></div></> : ''
                                }   
                            </Col>    */}
                                        
                            <Col lg={4} md={12} className="d-flex justify-content-center">
                                <div className="cms_feature_btn_area">
                                    <span>{index+1}</span>
                                    {
                                        index ? <><span className="delete_row" onClick={(e) => removeInstance(e, 'howItWorks', index)}><CloseIcon /></span></> : ''
                                    }   
                               
                                    {index+1 === howItWorksFiles.length && 
                                        <button className="add-more-btn" onClick={(e)=> addInstance(e, 'howItWorks')}> <AddIcon/></button>
                                    }
                                </div>
                                <div>
                                <div className="position-relative">
                                        {
                                            ((item?.image !=='' && formValues?._id) || item?.selected !==null) && 
                                            <span className="cross_cms_btn" onClick={()=>removeImage('howItWorks', index)}><CloseIcon/></span>}
                                        <label htmlFor={`upload-photo-howitWorks-${index}`}>
                                            <input type="file" id={`upload-photo-howitWorks-${index}`}
                                            style={{display:"none"}}
                                            accept="image/png, image/gif, image/jpeg"
                                                onChange={(event) => {
                                                    setPhoto(event.target.files[0], 'howItWorks', index);
                                                }}
                                                />
                                            <div className="content">
                                            <div className="upload-box cms_feature_area">
                                            
                                                {((item?.image !=='' && formValues?._id) || item?.selected !==null) ?
                                                    <img src={item?.selected!==null ?
                                                            URL.createObjectURL(item?.selected) : 
                                                            getImageUrl(formValues?._id, 'cms', item?.image)} className="user_attach_upload" /> : 
                                                    // <img src="/Assets/Member_Icon.png" alt="" className="upload-box-icon icon-members"/>    
                                                    <span className="up_load_btn">{t('errors.Upload')}</span>
                                                    }
                                                    <p className="upload-box-title">{t('Image')}</p>
                                                    <p className="upload-box-text">{t('onboarding.size_minimum')}</p>
                                                </div>
                                                
                                            </div>
                                        </label> 
                                    </div>
                                    <Row className="grid">
                                    <div className="grid-column myProfileSettings">
                                        <div className="widget-box area-widget">
                                            <div className="widget-box-content">
                                                <Col md={12}>
                                                    <TextField label={t('superAdmin.Title')}
                                                    value={item?.Title}
                                                    onChange={(e)=>handleFeatureChange(e.target.value,  index, 'Title', 'howItWorks')}
                                                    />
                                                </Col>
                                            </div>
                                        </div>
                                    </div>
                                </Row> 
                                </div>
                                   
                            </Col>
                            <Col lg={8} md={12}>
                                <Row className="grid">
                                    <div className="grid-column myProfileSettings">
                                        <div className="widget-box area-widget">
                                            <div className="widget-box-content">
                                                <Col md={12} className="cms_feature_textarea">
                                                    <FormTextArea label={t('Text')} 
                                                    value={item?.Text}
                                                    onChange={(e)=>( e.target.value.length < 500)?handleFeatureChange(e.target.value,  index, 'Text' , 'howItWorks'):''}
                                                    />
                                                </Col>
                                            </div>
                                        </div>
                                    </div>
                                </Row>   
                            </Col>
                        </Row>
                    )
                })}
            <div>
            </div> 
        </Box>
    </>)
}
export default HowItWorksCms;
