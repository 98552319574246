import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import {TextField } from "@mui/material";
import { CalendarMonthIcon } from "../../../commonComponents/Images";
import { deleteAvailibiltyTime, validatorAvailibiltyCheck } from "../../../services/validatorPost.service";
import moment from "moment";
import {showSingleToastSuccess, changeDateFormatmmddyy } from "../../../utils/helpers";
import BarsLoader from "../../../commonComponents/barsLoader";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditAvailibityPopUp from "./editAvailibiltyPopup";
import { addDays } from 'date-fns';

const ValidatorAvailibilty = () => {
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(addDays(new Date(), 30));
  const [show, setShow] = useState(false)
  const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };

  //addMonths(6)
  const [availibiltyData, setAvailibiltyData] = useState([]);
  const [tab, setTab] = useState(1);
  const tabs = [t('My_Availibilty'), t('Manage_Availibilty'), t('My_Validator_Details')];
  const [availibiltyId, setAvailibiltyId] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dateForEdit, setDateForEdit] = useState();


  const HandleSearchResult = (e) => {
    e.preventDefault();
  }

  // function addMonths(numOfMonths, date = new Date()) {
  //   date.setMonth(date.getMonth() + numOfMonths);
  //   return date;
  // }

  useEffect(() => {
    validatorInformationAccordingToDate();
  }, [toDate, fromDate, tab]);

  const editAvailabiltyDetail = (e, avail) => {
    let selectedDate = moment(avail?.date);
    setDateForEdit(new Date(avail?.date).toLocaleDateString('en-US', dateOptions));
    handleOpen();

  }

  const validatorInformationAccordingToDate = async () => {

    if (toDate && fromDate) {
      setLoading(true);

      let from = changeDateFormatmmddyy(new Date(fromDate));
      let to = changeDateFormatmmddyy(new Date(toDate));
      await validatorAvailibiltyCheck(from, to)
        .then((resp) => {
          if (resp?.status === 200) {
            setAvailibiltyData(resp?.data?.data?.validatorDetail);
            setAvailibiltyId(resp?.data?.data?._id);
            setLoading(false);
          }
        })
    }

  }
  // const setDates=(type,date)=>{
  //    if(type=='to')setToDate(date);
  //    if(type==='from')setFromDate(date);
  //    validatorInformationAccordingToDate();
  // }

  const handleDeleteVailibilty = (e, availObject) => {
    e.preventDefault();
    let param = {
      availabilityId: availibiltyId,
      availability: availObject
    }
    swal({
      text: t("Are You Sure,You want to remove Your Availibilty?"),
      title: '',
      icon: "warning",
      buttons: {
        Yes: {
          text: t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    })
      .then((val) => {
        if (!val) return;
        deleteAvailibiltyTime(param)
          .then((resp) => {
            if (resp?.status === 200) {
              showSingleToastSuccess(t('Availibilty_deleted_successfully'));
              validatorInformationAccordingToDate();
            }
          })
          .catch((err) => {
            console.log(err);
          })
      })

  }

  const handleAgainAfterUpdate = () => {
    validatorInformationAccordingToDate();
  }
  return (<>

    <div className="section-filters-bar v6 myProfileSettings">
      <div className="section-filters-bar-actions">
        <form className="form">
          <div className="form-item split paymentDate validatorAvailDateRange">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                closeOnSelect={true}
                label={t('form.From_Date')}
                value={fromDate}
                onChange={(newDate) => {
                  setFromDate(newDate);
                  //validatorInformationAccordingToDate('from',newDate)
                }}
                InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                renderInput={(params) => <TextField {...params} />}
                inputFormat="DD MMM YYYY"
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                closeOnSelect={true}
                label={t('form.To_Date')}
                value={toDate}
                onChange={(newDate) => {
                  setToDate(newDate);
                  //validatorInformationAccordingToDate('to',newDate)
                }}
                InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                renderInput={(params) => <TextField {...params} />}
                inputFormat="DD MMM YYYY"
              />
            </LocalizationProvider>
          </div>
        </form>
      </div>

    </div>
    <Row className="grid">
      <Col md={12} className="grid-column">
        <div className="widget-box">
          <p className="widget-box-title">{t('Availibilty_Detail')}</p>
          {loading ? <BarsLoader /> : <>
            {availibiltyData?.length > 0 ?
              <div className="widget-box-content overflow-auto">
                <div id="availabilityDetailTableContainer">
                  <table className="table availibilty_detail_table">
                    <thead>
                      <tr>
                        <th>{t('wallet.Date')}</th>
                        <th>{t('superAdmin.Start_Time')}</th>
                        <th>{t('superAdmin.End_Time')}</th>
                        <th>{t('superAdmin.Time_Zone')}</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {availibiltyData?.length && availibiltyData.map((avail, index) => {
                        return <>
                          <tr>
                            <td>{avail?.date && moment(new Date(avail?.date).toLocaleDateString('en-US', dateOptions)).format('DD MMM, YYYY')}</td>
                            <td>{avail?.startTime && dayjs(avail?.startTime).format('h:mm A  ')}</td>
                            <td>{avail?.endTime && dayjs(avail?.endTime).format('h:mm A  ')}</td>
                            <td>{avail?.timezone ? avail?.timezone : ""}</td>

                            <td className="table_design_icons availabilityIcons"><DeleteIcon sx={{ color: 'red' }} onClick={(e) => handleDeleteVailibilty(e, avail)} /><ModeEditIcon onClick={(e) => editAvailabiltyDetail(e, avail)} />  </td>

                            {/* <button className='btn av_detail_btn' onClick={(e)=>handleDeleteVailibilty(e,avail)}>{t('Delete_Availibilty')}</button></td> */}
                          </tr>
                        </>
                      })
                      }
                    </tbody>
                  </table>
                </div>


              </div> :
              <div className="widget-box-content">
                <div className="table-wrap paymentHistory" data-simplebar="init">
                  <div className="simplebar-wrapper">
                    <div className="simplebar-height-auto-observer-wrapper">
                      <div className="simplebar-height-auto-observer"> {t('No_Time_Slot_Available')}</div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </>}
        </div>
      </Col>

    </Row>
    {open &&
      <EditAvailibityPopUp open={open} handleClose={handleClose} dateForEdit={dateForEdit} handleAgainAfterUpdate={handleAgainAfterUpdate} />
    }
  </>);
}

export default ValidatorAvailibilty;