import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row , Col } from "react-bootstrap";
import { TextField, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ReviewBox from "./reviewBox";
const ReviewList = () => {
    const {t} = useTranslation();
    const reviewPostList=[
        {userName:'NekoBepop',img:`/Assets/Small Profile Info green.png`,postText:'Hi to everyone! Check out my latest video of the outlaws expansion for Court Striker Go....',viewMore:false},
        {userName:'NekoBepop',img:`/Assets/Small Profile Info green.png`,postText:'Hi to everyone! Check out my latest video of the outlaws expansion for Court Striker Go....',viewMore:false},
        {userName:'NekoBepop',img:`/Assets/Small Profile Info green.png`,postText:'Hi to everyone! Check out my latest video of the outlaws expansion for Court Striker Go....',viewMore:false},
        {userName:'NekoBepop',img:`/Assets/Small Profile Info green.png`,postText:'Hi to everyone! Check out my latest video of the outlaws expansion for Court Striker Go....',viewMore:false},
        {userName:'NekoBepop',img:`/Assets/Small Profile Info green.png`,postText:'Hi to everyone! Check out my latest video of the outlaws expansion for Court Striker Go....',viewMore:false},
        {userName:'NekoBepop',img:`/Assets/Small Profile Info green.png`,postText:'Hi to everyone! Check out my latest video of the outlaws expansion for Court Striker Go....',viewMore:false},

    ]
   
    
   
   
    return (
    <div>
     <div className='text-secondary superAdminheading1 mt-3'> {t('superAdmin.manage_org_heading1')} </div>
     <h2 className='mb-4 mt-1'> {t('superAdmin.post_review_text')} </h2>

    <div className="widget-box ">  
    
      {/* <p className="widget-box-title">Interests</p> */}
      <Row>
        <Col md={6}>
         <TextField type='text' label={t('friendsMsg.SEARCH_FRIENDS')}
                         InputProps={{endAdornment: <Button className="button secondary" style={{width:"10%"}}><SearchIcon style={{fill:"#fff"}}/></Button>}}/>
        </Col>
      
     </Row>
     
    </div>
    <Row>
        <Col md={12}>
        {reviewPostList.map((item,index)=>{
        return (<ReviewBox postDet={item} key={index}/>)
    })}
        </Col>
    </Row>
   
    </div>
  )
}

export default ReviewList;