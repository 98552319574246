import React from "react";
import UserLayout from "../../components/dashboard-layout";
import ReportUserList from "../../components/dashboard/superAdmin/reportedUserList";

const ReportedUser = () => {
    return <UserLayout>
                <ReportUserList/>
            </UserLayout>;
}

export default ReportedUser;