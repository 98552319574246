import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import './events.style.css';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { TextField } from '@mui/material';
import TodayEventList from "../../../commonDashboardComp/todayEventsList";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ManageEventParticipantsService, getEventDetailByDateService, getEventDetailService, getEventsByDatesService } from "../../../services/event.services";
import { changeDateFormatYYYY, dateTimeEventFormat, restrictForwardAndBackwardSlash } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import { selectCurrentUser, selectCurrentUserRole, selectCurrentOrg } from "../../../Reducer/user/user.selector";
import TabNavigation from "../../../commonDashboardComp/tabNavigation";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import { acceptRejectEventService } from "../../../services/event.services";
import { selectUserWorkingOrg } from "../../../Reducer/user/user.selector";
import RoundRadio from "../../../commonComponents/roundCheckbox/roundRadio";
import EventsListing from "../../../commonDashboardComp/eventsListing";
import { event } from "jquery";
import PaymentPopUp from "../../../commonDashboardComp/paymentPopUp";

const Events = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(1);
  const navigate = useNavigate();
  const role = useSelector(selectCurrentUserRole);
  const CURRENTUSER = useSelector(selectCurrentUser);
  const CURRENTORG = useSelector(selectCurrentOrg);
  const userOrg = useSelector(selectUserWorkingOrg)

  let current_user = {};
  if (role === 'organization') { current_user = CURRENTORG }
  else { current_user = CURRENTUSER }

  let tabs = [];
  if (role === 'user' && (current_user?.pages?.length > 0 || userOrg?.organizationDetail && userOrg?.isVerified)) {
    tabs = [t('event.All_Events'), t('event.My_ORG_Events'), t('event.My_ORG_Participated_Events'), t('event.My_Events'), t('event.participatedEvents'), t('event.completedEvents'), t('event.upcomingEvents')];
  }
  else {
    tabs = [t('event.All_Events'), t('event.My_Events'), t('event.participatedEvents'), t('event.completedEvents'), t('event.upcomingEvents')]
  }

  if (role !== 'superAdmin') {
    tabs.push(t('event.pendingInvitesEvents'));
  }

  const [reload, setReload] = useState(true);

  const [searchText, setSearchText] = useState('');
  const [isPaid, setIsPaid] = useState('all');

  const [eventByDate, setEventsByDate] = useState({});
  const [date, setDate] = useState(changeDateFormatYYYY(new Date()));
  const [eventDate, setEventDate] = useState(new Date())
  const [eventList, setEventList] = useState([]);

  const [startDate, setDateStart] = useState(null);
  const [endDate, setDateEnd] = useState(null);
  const [event, setEvent] = useState(false);
  const [eventData, setEventData] = useState();

  const [firstTymVisit, setFirstTimeVisit] = useState(true);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const handlePaymentOpen = () => setPaymentOpen(true);
  const handlePaymentClose = () => setPaymentOpen(false);

  useEffect(() => {
    getEventByDate();
  }, [date, role])

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      getEventsByRange()
    }
  }, [startDate, endDate, role, searchText, isPaid])

  useEffect(() => {
    if (!firstTymVisit) { executeScroll(); }
    else {
      setFirstTimeVisit(false);
    }
  }, [isPaid])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getEventsByRange = () => {
    getEventsByDatesService(startDate, endDate, searchText, isPaid)
      .then((data) => {
        if (data?.data?.status === 200) {
          let searchedEvents = [];
          if (data?.data?.data?.length > 0) {
            data?.data?.data?.map((item) => {
              let eventDates = item?.eventDates;
              eventDates.map((eventDateItem) => {
                searchedEvents.push({
                  id: item._id,
                  title: item.title,
                  start: dateTimeEventFormat(eventDateItem, item.startTime),
                  end: dateTimeEventFormat(eventDateItem, item.startTime),
                })
              })

            })
          }
          setEventList(searchedEvents);
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getEventByDate = () => {
    getEventDetailByDateService(date)
      .then((data) => {
        if (data?.data?.status === 200) {
          setEventsByDate(data?.data?.data);
        }
      })
  }

  const getEventDetail = async (id) => {
    getEventDetailService(id)
      .then((data) => {
        if (data?.data?.status === 200) {
          setEvent(data?.data?.data)

        }
        // else {
        //     showToastError(t('No_Event_Found'));
        //     navigate('/add-event');
        // }
      })
  }

  const handleSubmbitRegitration = () => {
    handlePaymentClose();
    let params = {
      eventId: event?._id,
      userId: current_user?._id,
      action: (role === 'organization') ? 'addOrganization' : 'add'
    }
    ManageEventParticipantsService(params)
      .then((resp) => {
        if (resp?.status === 200) {
          // showToastSuccess("You have successfully registered")
          showSingleToastSuccess("You have successfully registered")
          getEventDetail(event?._id);
        }
      })
      .error = () => {
        showToastError(t('apiMessages.SOME_THING_WENT_WRONG'))
      }
  }

  const AcceptRejectPaidEventHandler = (e, id) => {
      getEventDetailService(id)
          .then((data) => {
            if (data?.data?.status === 200) {
              setEventData(data?.data?.data);
              handlePaymentOpen();
            }
          })       
  }

  const handlePaidEventInvite = () => {
      handlePaymentClose();
      let param = {
        eventId: eventData._id,
        status: 'accept'
      }
      acceptRejectEventService(param)
            .then((data) => {
              if (data?.data?.status === 200) {
                showSingleToastSuccess(t('apiMessages.EVENT_ACCEPT_SUCCESS'));
                setReload(!reload)
              }
              else {
                showToastError(t('errors.some_error'));
              }
            })
            .catch((error) => {
              console.log(error);
            })
  }

  const AcceptRejectEventHandler = (e, id, status) => {
    e.preventDefault();
    if(status === 'accept') {
      swal({
        text: (status === 'accept') ? t('alerts.accept_event') : t('alerts.reject_event'),
        title: '',
        icon: "warning",
        buttons: {
          Yes: {
            text: t('yes'),
            value: true,
          },
          No: {
            text: t('no'),
            value: false,
          },
        },
      })
        .then(async (val) => {
          let param = {
            eventId: id,
            status: status
          }
          acceptRejectEventService(param)
            .then((data) => {
              if (data?.data?.status === 200) {
                showSingleToastSuccess((status === 'accept') ? t('apiMessages.EVENT_ACCEPT_SUCCESS') : t('apiMessages.EVENT_REJECT_SUCCESS'));
                setReload(!reload)
              }
              else {
                showToastError(t('errors.some_error'));
              }
            })
            .catch((error) => {
              console.log(error);
            })
        });
    }
    else if(status === 'cancel'){
      swal({
        text: (status === 'accept') ? t('alerts.accept_event') : t('alerts.reject_event'),
        title: '',
        icon: "warning",
        buttons: {
          Yes: {
            text: t('yes'),
            value: true,
          },
          No: {
            text: t('no'),
            value: false,
          },
        },
      })
        .then(async (val) => {
          let param = {
            eventId: id,
            status: status
          }
          acceptRejectEventService(param)
            .then((data) => {
              if (data?.data?.status === 200) {
                showSingleToastSuccess((status === 'accept') ? t('apiMessages.EVENT_ACCEPT_SUCCESS') : t('apiMessages.EVENT_REJECT_SUCCESS'));
                setReload(!reload)
              }
              else {
                showToastError(t('errors.some_error'));
              }
            })
            .catch((error) => {
              console.log(error);
            })
        });
    } 
  }

  const handleDateClick = (date) => {
    setEventDate(date?.date);
    setDate(changeDateFormatYYYY(date?.date))
  }

  //For Smooth Scroll
  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()

  return (<>
    <SectionHeader pretitle="" title={t('event.Events_Calendar')} />

    <div className="section-filters-bar v6">
      <div className="section-filters-bar-actions">
        <form className="form eventSearch eventSearchBox">
          <div className="form-item split">
            <TextField type='text' label={t('event.Search_Events')}
              value={searchText}
              onChange={(e) => setSearchText(restrictForwardAndBackwardSlash(e.target.value))}
            />

            <button className="button primary" onClick={(e) => { e.preventDefault(); executeScroll() }} >
              <SearchOutlinedIcon className="icon-magnifying-glass" />
            </button>
            {/* <div id="eventSearchToggleCont">
            <RoundRadio 
              name="isPaid" 
              label='All'
              value=''
              checked={isPaid === ''?true:false}
              onChange={(e)=>(e.target.checked) ?  setIsPaid('') :  '' }
              />

            <RoundRadio 
              name="isPaid" 
              label='Paid'
              value={true}
              checked={isPaid === true?true:false}
              onChange={(e)=>(e.target.checked) ?  setIsPaid(true) :  '' }
              />

            <RoundRadio 
              name="isPaid" 
              label='Free'
              value={false}
              checked={isPaid === false?true:false}
              onChange={(e)=>(e.target.checked) ?  setIsPaid(false) :  '' }
              />
            </div> */}
          </div>
        </form>
      </div>

      <div className="section-filters-bar-actions">
        <Link className="button secondary popup-event-creation-trigger" to="/add-event">+ {t('Add_New_Event')}</Link>
      </div>
    </div >

    <div className="calendar-widget eventCalendar">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          center: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        events={eventList}
        dateClick={handleDateClick}
        eventClick={(info) => navigate("/view-event", { state: { eventId: info.event.id } })}
        datesSet={(dateInfo) => {
          setDateStart(changeDateFormatYYYY(dateInfo.start)) //start of the range the calendar date
          setDateEnd(changeDateFormatYYYY(dateInfo.end)) //end of the range the calendar date
        }}
      />

      <TodayEventList date={date} events={eventByDate} />
    </div>

    <div ref={myRef}>
      <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab} showInput={false} />
      <div id="" className="eventFilterDropdown">
        <FormControl fullWidth className="comment">
          <InputLabel id="co-country-select-label" shrink={true}>
            {t('Filter')}
          </InputLabel>
          <Select
            // MenuProps={{
            //   disableScrollLock: true
            // }}
            labelId="co-country-select-label"
            value={isPaid}
            label={t('filter')}
            sx={{
              maxWidth: '125px',
            }}
            onChange={(e) => setIsPaid(e.target.value)}
          >
            <MenuItem value="all">{t('All')}</MenuItem>
            <MenuItem value="paid">{t('Paid')} </MenuItem>
            <MenuItem value="free">{t('Free')}</MenuItem>
            <MenuItem value="earth">{t('Earth')}</MenuItem>
            <MenuItem value="water">{t('Water')} </MenuItem>
            <MenuItem value="air">{t('Air')}</MenuItem>
            <MenuItem value="fire">{t('Fire')} </MenuItem>
            <MenuItem value="soul">{t('Soul')}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <EventsListing
        searchText={searchText}
        tab={tab}
        isPaid={isPaid}
        role={role}
        current_user={current_user}
        userOrg={userOrg}
        reload={reload}
        AcceptRejectEventHandler={AcceptRejectEventHandler}
        AcceptRejectPaidEventHandler={AcceptRejectPaidEventHandler}
      />
    </div>
    <PaymentPopUp
      eventData={eventData}
      open={paymentOpen}
      handleClose={handlePaymentClose}
      handlePaidEvents={handlePaidEventInvite}
    />
  </>)
}

export default Events;