import React from "react";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { getImageUrl } from "../../../utils/helpers";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
const BannerCms = ({formValues, bannerFiles, setPhoto, removeImage, addInstance, removeInstance}) => {
    const {t} = useTranslation();
    return (<>
        <SectionHeader pretitle="" title={t('Banner')}/>
        <Row className="cmsBannerBlock">
            {bannerFiles?.length > 0 && bannerFiles?.map((item, index)=>{
                return (<Col lg={4} md={6} key={index} className="cmsBannerBlockComp">
                            <div className="position-relative">
                                {
                                    ((item?.banner !=='' && formValues?._id) || item?.selected !==null) &&
                                    <span className="cross_cms_btn" onClick={()=>removeImage('banner', index)}><CloseIcon/></span>
                                 }
                                <label htmlFor={`upload-photo-banner-${index}`}>
                                    <input type="file" id={`upload-photo-banner-${index}`}
                                    style={{display:"none"}}
                                    accept="image/png, image/gif, image/jpeg"
                                        onChange={(event) => {
                                            setPhoto(event.target.files[0], 'banner', index);
                                        }}
                                        />
                                    <div className="content">
                                        <div className="upload-box cms_banner_area">
                                        
                                            {((item?.banner !=='' && formValues?._id) || item?.selected !==null) ?
                                            <img src={item?.selected!==null ?
                                            URL.createObjectURL(item?.selected) : 
                                            getImageUrl(formValues?._id, 'cms', item?.banner)} className="user_attach_upload" /> : 
                                            <span className="up_load_btn">{t('errors.Upload')}</span>  }

                                            <p className="upload-box-title">{t('Banner')} {index+1}</p>
                                            <p className="upload-box-text">{t('onboarding.size_minimum')}</p>
                                        </div>                                        
                                    </div>
                                </label> 
                                {
                                    index ? <><div className="closeIconUpload" onClick={(e) => removeInstance(e, 'banner', index)}><DeleteForeverIcon /></div></> : ''
                                } 
                               
                            </div>
                            
                </Col>)
            })}

            <div className="cms_feature_btn_area mt-2">
                {bannerFiles?.length < 5 &&
                <button className="add-more-btn" onClick={(e)=> addInstance(e, 'banner')}> <AddIcon style={{fontSize:"xx-large"}}/></button>}
            </div>
        </Row>
        
    </>)
}
export default BannerCms;
