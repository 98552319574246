import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import AllGlobalSearchList from "./allGlobalSearchList";
import GlobalSideBar from "./globalSideBar";



const AllGlobalSearch = () => {


    return (<Row className="grid grid-3-6-3 mobile-prefer-content">
        <Col md={4} className="grid-column">
            <GlobalSideBar />
        </Col>

        <Col md={8} className="grid-column myProfileSettings">
            <>
                <AllGlobalSearchList/>
            </>
        </Col>
    </Row>)
}

export default AllGlobalSearch;

