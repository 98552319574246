import React, { useEffect, useState } from 'react';
import { LanguageList } from '../../config/constants';
import HomeBanner from './banner';
import i18next from 'i18next';
import { getCmsServiceList } from '../../services/cms.services';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BarsLoader from '../../commonComponents/barsLoader';
//For Draft JS
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from 'draft-js';
import DOMPurify from 'dompurify';
//For Draft JS
const AboutUsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const defaultLanguage = i18next?.language?.split('-')[0];
  const find = LanguageList?.find((a) => a.value === defaultLanguage);
  const [cmsData, setcmsData] = useState([]);
  const [language, setLanguage] = useState(find?.name ? find : LanguageList[0]);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    let filter = LanguageList?.find((a) => a.value === defaultLanguage);

    setLanguage(filter?.name ? filter : LanguageList[0]);
  }, [defaultLanguage]);
  React.useEffect(() => {
    const handleLanguageChange = () => {
      let defaultLanguage1 = i18next?.language?.split('-')[0];

      let filter = LanguageList?.find((a) => a.value === defaultLanguage1);

      setLanguage(filter?.name ? filter : LanguageList[0]);
    };

    i18next.on('languageChanged', handleLanguageChange);

    return () => {
      i18next.off('languageChanged', handleLanguageChange);
    };
  }, []);
  useEffect(() => {
    setLoading(true);
    if (language !== null) {
      getCmsData();
      //   getBlogs();
    }
  }, [language]);

  const getCmsData = async () => {
    getCmsServiceList(language?.value)
      .then((data) => {
        if (data?.data?.status === 200) {
          const _data = data.data.data;
          setcmsData(_data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //For Draft JS
  const [convertedData, setConvertedData] = useState();
  useEffect(() => {
    if (cmsData?.aboutUsPage) {
      let htmlData = convertToHTML(
        convertFromRaw(cmsData?.aboutUsPage)
      );
      setConvertedData(htmlData);
    }
  }, [cmsData]);
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }
  //For Draft JS
  return (
    <>
      <HomeBanner cmsData={cmsData} onlyMenu={true} />
      <Container fluid className="blogs-div">
        <div className="blog-container">
          <Row className="panchtatva-row1 mb-4">
            <h1 className="heading"> {t('aboutUsPage.mainTitle')} </h1>
          </Row>
          <Row>
            <Col xs={12}>
              {isLoading ? (
                <BarsLoader />
              ) : (
                <>
                  {cmsData?.aboutUsPage ? (
                    <Col xs={12}>
                      <div
                        className='cmsOutput'
                        dangerouslySetInnerHTML={createMarkup(convertedData)}
                      ></div>
                    </Col>
                  ) : (
                    <p>{t('aboutUsPage.noData')}</p>
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default AboutUsPage;
