import React, { useState, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RoundCheckbox from './roundCheckbox';
import { Col } from 'react-bootstrap';

const EditUserInterests = ({ canEdit = true, interests, formValues, setFormValues, saveUserDetails, saveNewInterest, additionalInterest }) => {
    const { t } = useTranslation();

    const [newInterest, setNewInterest] = useState([])
    useEffect(() => {
        if (additionalInterest !== '') {
            addInterestToArray(additionalInterest)
        }
    }, [additionalInterest])

    const removeInterestToArray = (element) => {
        // Removing the specified element by value from the array 
        let arr = formValues.interests;
        let filter = arr.filter((item) => { return item !== element });
        setFormValues({ ...formValues, interests: filter })
        // setNewInterest(arr)

    }

    const addInterestToArray = (element) => {
        let arr = formValues.interests;
        arr.push(element);
        setFormValues({ ...formValues, interests: arr })
        // setNewInterest(arr)
    }

    const handleInterestChange = (e) => {
        let newInterestVal = [...newInterest];
        newInterestVal[e.target.name] = e.target.value.replace(/[^a-zA-Z ]/g, "");
        setNewInterest(newInterestVal)
    }

    const handleInterestKeyUp = (event, id, trimmedId) => {
        if (trimmedId !== '' && trimmedId !== undefined && event.which === 13) {
            saveNewInterest(id, trimmedId)
            setNewInterest([]);
        }
    }

    return (
        <>
            {interests.length > 0 &&
                interests.map((item, index) => {
                    return (<Fragment key={index}>
                        <div className="Int-Set-title">
                            <img src={`/Assets/${item.spredeInterest}.png`} alt="Earth" />
                            <h3 className="">
                                {t(`${item.spredeInterest}`)}
                            </h3>
                        </div>
                        <div className="inv-set-opt">
                            {item.option.length > 0 &&

                                <>
                                    {item.option.map((option_item, idx) => {
                                        //     (formValues.interests.indexOf(option_item._id) > -1),(addNewInterest.indexOf(option_item._id)>0),'77')
                                        return (<RoundCheckbox
                                            disabled={!canEdit}
                                            checked={
                                                (formValues.interests.indexOf(option_item._id) > -1) ? true : false}
                                            key={idx}
                                            label={(t(`${option_item.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) !== `${option_item.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) ? t(`${option_item.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) : option_item.spredeInterestOptions}

                                            value={option_item._id}
                                            onChange={(e) => {
                                                (e.target.checked) ?
                                                    addInterestToArray(option_item._id) :
                                                    removeInterestToArray(option_item._id)
                                            }
                                            }
                                        />)
                                    })}

                                    {canEdit && <RoundCheckbox
                                        label={t('other')}
                                        other={true}
                                        // checked={(formValues.interests.indexOf(newInterest['newInterest_' + item._id]) > 0) ? true : false}
                                        input={
                                            <input type="text"
                                                value={newInterest['newInterest_' + item._id] || ''}
                                                // defaultChecked={check}
                                                name={`newInterest_${item._id}`}
                                                onChange={(e) => handleInterestChange(e)}
                                                onKeyUp={(e) => handleInterestKeyUp(e, item._id, newInterest['newInterest_' + item._id]?.trim())}
                                                className="checkbox_text_feild" placeholder={t('Enter_other_interest')} />}
                                    />}
                                </>
                            }
                        </div>
                    </Fragment>)
                })
            }
        </>
    )
}

export default EditUserInterests;