import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { FormControl, Button, OutlinedInput, InputLabel } from "@mui/material";
import { allowOnlyLetters } from "../../utils/helpers";
import { validEmail } from "../../utils/formatValidator";
import { SearchAddEventIcon } from "../../commonComponents/Images";
import UserSelector from "../userSelectBox";
import GreenCheckBox from "../../commonComponents/greenCheckbox/greenCheckbox";
import { eventChargingTypeList } from "../../config/constants";
import { validatorRequiredNeccessary } from "../../utils/helpers";
const EventValidatorVendorStep = ({
    values,
    setFormValues,
    validatorError,
    validatorSearch,
    setValidatorSearch,
    getValidatorHandler,
    valEmailError,
    validatorEmail,
    role,
    vendorSearchKey,
    handleVendorPopupOpen,
    showValidators,
    eventValidators,
    onSelectValidatorHandler,
    validators,
    setValidatorEmail,
    sendValidatorEmailHandler,
    setVendorSearchKey,
    validatorRequired,
    setValidatorRequired,
    handleValidatorCheck,
    valEmailSuccess,
    categoryVal,
    error,
    sameUser
}) => {
    const { t } = useTranslation();
    const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };

    return (<Row>
        <Col lg={12} className="col-md-12 newEvents eventValidateInput target_title88 event_fix_height">
            <GreenCheckBox
                label={t("Want_validator_to_validate_event?")}
                checked={validatorRequired}
                onChange={(e) => {
                    handleValidatorCheck(e.target.checked);
                }}
            />
        </Col>

        {validatorRequired && <>
            <Col lg={4} className="col-md-12 radioButton mt-2 newEvents">
                <div className="gender-text-area2">
                    <label className="gender_text2">{t('form.preffred_validator_charging_mode')} </label>
                    <div className="gender-MF eve_radio_area eveRadioBox">
                        <ul>
                            {eventChargingTypeList.length > 0 && eventChargingTypeList.map((item, index) =>

                                <li key={`eventChargingTypeList` + index}>
                                    <input type="radio" id={item.value} name="validator_type"
                                        checked={(values.chargingType === item.value) ? true : false}
                                        onChange={(e) => (e.target.checked) ? setFormValues(prev => ({ ...prev, chargingType: item.value })) : ''}
                                    />
                                    <label htmlFor={item.value}>{item.label}</label>
                                </li>

                            )}
                        </ul>
                    </div>
                </div>
                {((error || validatorError) && values.chargingType === '' && !valEmailSuccess) ?
                    <div className="error-text" >{t('superAdmin.Please_select_validator_charge_mode')} </div> : ''}
            </Col>
            <Col lg={4} className="col-md-12 newEvents eventValidateInput target_title88 event_fix_height">
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{t('superAdmin.Event_Validator')}</InputLabel>
                    <OutlinedInput
                        label={t('superAdmin.Event_Validator')}
                        id={'searchIconButtonValidator'}
                        value={validatorSearch}
                        onChange={(e) => { setValidatorSearch(allowOnlyLetters(e.target.value)); }}
                        endAdornment={<img src={SearchAddEventIcon}
                            onClick={(e) => getValidatorHandler(e)} className="searchIconBtn" style={{ cursor: "pointer", color: '#ffffff' }} />}
                    />
                </FormControl>
                {
                    error && validatorRequired && eventValidators.length === 0 && !valEmailSuccess &&
                    <div className="error-text"> Please select validator to validate event </div>
                }
            </Col>


            <Col lg={4} className="col-md-12 newEvents eventValidateInput eventValidateInputText target_title88 event_fix_height">
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{t('superAdmin.Invite_Validator_Email_optional')}</InputLabel>
                    <OutlinedInput
                        error={(valEmailError && validatorEmail === '') ? true : (valEmailError && !validEmail(validatorEmail)) ? true : false}
                        label={t('superAdmin.Validator_Email_optional')}
                        value={validatorEmail}
                        onChange={(e) => setValidatorEmail(e.target.value.toLowerCase())}
                        endAdornment={<Button className="addEve_send_btn"
                            onClick={(e) => sendValidatorEmailHandler(e)}
                        >{t('superAdmin.Send')}</Button>}
                    />
                    {(valEmailError && validatorEmail === '') ?
                        <div className="error-text" >{t('errors.email_required')}</div> :
                        (valEmailError && !validEmail(validatorEmail)) ?
                            <div className="error-text" > {t('errors.email_format')} </div> : ''}
                </FormControl>
            </Col>
        </>}

        {(showValidators && validators.length > 0) &&
            <Row>
                <Col md={12}>
                    {
                        (sameUser)?<div className="error py-0 ms-4 mb-2">Invited user cannot be an event validator.</div>:''
                    }
                    <div className="scrollBar popup_friend_boxes">
                        
                        <Row className="ms-2">
                            
                            {validators.length > 0 && validators.map((item, index) => {
                                let chk = eventValidators.filter((val) => {
                                    return val?.userId === item?._id
                                })
                                let checked = (chk.length > 0) ? true : false;
                                return (
                                    <UserSelector
                                        key={`validators` + index}
                                        user={item}
                                        checked={checked}
                                        onChange={onSelectValidatorHandler}
                                        validator={true}
                                    />)
                            })}
                            
                        </Row>
                    </div>
                </Col>
            </Row>
        }

        {showValidators && validatorRequired && validators?.length === 0 && <div className="error-text" >{t('superAdmin.No_Validators_Found')}</div>}

        {role === 'organization' &&
            <Col lg={4} className="col-md-12 newEvents eventValidateInput target_title88 event_fix_height">
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{t('superAdmin.Select_Vendor')}</InputLabel>
                    <OutlinedInput
                        label={t('superAdmin.Select_Vendor')}
                        value={vendorSearchKey}
                        onChange={(e) => setVendorSearchKey(e.target.value)}
                        endAdornment={<img src={SearchAddEventIcon}
                            onClick={(e) => handleVendorPopupOpen(e)} className="searchIconBtn" style={{ cursor: "pointer", color: '#ffffff' }} />}
                    />
                </FormControl>
            </Col>
        }
    </Row>)
}

export default EventValidatorVendorStep;