import ApiConfig from "../config/ApiConfig";
import instance from "../AppInterceptor";

export const getLanguageListService = () => {
    return instance.get(ApiConfig.getLanguageList)
}

export const getCmsService = (code) => {
    let url = `${ApiConfig.getCmsData}` 
    if(code !== ''){
        url += `?langCode=${code}`
    }
    return instance.get(url)
}

export const getCmsServiceList = (code) => {
    let url = `${ApiConfig.getCmsList}` 
    if(code !== ''){
        url += `?langCode=${code}`
    }
    return instance.get(url)
}

export const postCmsService = (payload) => {
    return instance.post(ApiConfig.postCmsData, payload)
}

export const getBlogsService = (page, limit,code) => {
    let url = `${ApiConfig.blogsApi}?page=${page}&limit=${limit}` 
    if(code !== ''){
        url += `&langCode=${code}`
    }
    return instance.get(url)
}

export const getBlogService = (id) => {
    let url = `${ApiConfig.getBlog}?id=${id}` 
    return instance.get(url)
}

export const addBlogsService = (payload) => {
    return instance.post(ApiConfig.blogsApi ,  payload)
}

export const editBlogsService = (payload) => {
    return instance.put(ApiConfig.blogsApi ,  payload)
}

export const deleteBlogsService = (id) => {
    return instance.delete(ApiConfig.blogsApi+'?id='+id)
}

export const getTestimonialsService = (page, limit,code) => {
    let url = `${ApiConfig.testimonialsApi}?page=${page}&limit=${limit}` 
    if(code !== ''){
        url += `&langCode=${code}`
    }
    return instance.get(url)
}

export const getFaqService = (code) => {
    let url = `${ApiConfig.faqApi}` 
    if(code !== ''){
        url += `?langCode=${code}`
    }
    return instance.get(url)
}

export const getTestimonialService = (id) => {
    let url = `${ApiConfig.getTestimonial}?id=${id}` 
    return instance.get(url)
}

export const addTestimonialsService = (payload) => {
    return instance.post(ApiConfig.testimonialsApi ,  payload)
}

export const addFaqService = (payload) => {
    return instance.post(ApiConfig.faqApi ,  payload)
}

export const editFaqService = (payload) => {
    return instance.post(ApiConfig.faqApi ,  payload)
}

export const deletefaqService = (id) => {
    return instance.delete(ApiConfig.faqApi+'?id='+id)
}

export const editTestimonialsService = (payload) => {
    return instance.put(ApiConfig.testimonialsApi ,  payload)
}

export const deleteTestimonialsService = (id) => {
    return instance.delete(ApiConfig.testimonialsApi+'?id='+id)
}

export const inviteUserToPlatformService = (payload) =>{
    return instance.post(ApiConfig.inviteUserToPlatform, payload)
}
export const fetchInviteListService = (page) =>{
    return instance.get(ApiConfig.invitedUserList+'?limit=10&page='+page)   
}

