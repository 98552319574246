import React, {useState} from "react";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, TextField } from "@mui/material";
import FormTextArea from "../../../commonComponents/formTextArea";
import BarsLoader from "../../../commonComponents/barsLoader";
import CmsBlogTestimonialBox from "../../../commonDashboardComp/cmsBlogTestimonialBox";
import ReactPagination from "../../../commonComponents/reactPagination";
import TabNavigation from "../../../commonDashboardComp/tabNavigation";
import Popup from "../../../commonComponents/popup";
import {Autocomplete} from "@mui/material";
const TestimonialCms = ({list, handlePageClick, totalPage, isLoading, addBlogTestimonial}) => {
    const {t} = useTranslation();
    const [tab, setTab] = useState(1);
    const tabs = [t('homepage.testimonials.title')];
    const [error, setError] = useState(false)
    const [loader, setLoad] = useState(false)
    const stars = ['1','2','3','4','5']
    const [testimonial, setTestimonial] = useState({
                                        name : '',
                                        company : '',
                                        rating : '',
                                        content : ''
                                    })
    const [selectedFile, setSelectedFile] = useState(null);

    const [open, setOpen] = useState(false);
    const handleOpen = (e) => {e.preventDefault(); setOpen(true)}
    const handleClose = (e) => {setOpen(false)}


    const makeButton = () => {
        return <Link className="button secondary popup-event-creation-trigger" to="#" onClick={handleOpen}>+ {t('Add_Testimonial')}</Link>
    }

    const setPhoto = (param) => {
        if(param !== undefined){ setSelectedFile(param)}
        else{ setSelectedFile(null) }    
    }

    const removeImage = () => {
        setSelectedFile(null)
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        setError(false)

        if(selectedFile === null || testimonial?.content === '' || testimonial?.rating === '' || testimonial?.name === ''  || testimonial?.company === ''){
            setError(true); return;
        }

        let param = {
            image : selectedFile,
            name : testimonial?.name,
            company : testimonial?.company,
            rating : testimonial?.rating,
            content : testimonial?.content,
        }
        setLoad(true)
        await addBlogTestimonial(param, '');
        setLoad(false)
        handleClose();
        setTestimonial({ name : '', company : '',  rating : '',  content : '' })
        setSelectedFile(null)
    }

    const onEdit = (e, item) => {

    }

    const onDelete = (e, item) => {

    }

    return (<>
        <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab} showInput={false} button={makeButton()} />

        <Row className="grid">
            <div className="grid-column">
                <div className="">
                <>
                    {isLoading ? <BarsLoader /> : list?.length > 0 ?
                    <Row>
                        {list?.map((item, i) => 
                            {
                                return (
                                    <div className="widget-box-content col-lg-12 col-md-6 col-12 m-0 mb-2" key={i}>
                                        <CmsBlogTestimonialBox
                                        item={{...item, type:'testimonial' }}
                                        onEdit={onEdit}
                                        />
                                    </div>
                                    )
                            })
                        }
                    </Row> :
                    <>
                        {list?.length === 0 &&
                        <>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                            {t('no_testimonials')}
                            </div>
                        </>
                        }
                    </>
                    }

                </>
                <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />

                </div>
            </div>

            <Popup heading={t('Add_Testimonial')} open={open} handleClose={handleClose} className="AddTestimonial-popup targetPopup">
                <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className="userdetail-form mobile_profile_margin row"
                onSubmit={handleSubmit}
                >
                    <Row>
                        <Col lg={4} md={6}>
                        <div className="position-relative">
                                    <label htmlFor={`upload-photo-testimonial`}>
                                        <input type="file" id={`upload-photo-testimonial`}
                                        style={{display:"none"}}
                                        accept="image/png, image/gif, image/jpeg"
                                            onChange={(event) => {
                                                setPhoto(event.target.files[0]);
                                            }}
                                            />
                                        <div className="content">

                                            <div className="upload-box">
                                                {(selectedFile !==null) ?
                                                <img src={selectedFile!==null &&
                                                        URL.createObjectURL(selectedFile)} className="user_attach_upload" /> : 
                                                <img src="/Assets/Member_Icon.png" alt="" className="upload-box-icon icon-members"/>    }
                                                <p className="upload-box-title">{t('Image')}</p>
                                                <p className="upload-box-text">{t('onboarding.size_minimum')}</p>
                                            </div>

                                            
                                        </div>
                                    </label> 
                                    {(selectedFile !==null) &&
                                    <img src='/Assets/Close.png' className="closeIconUpload" onClick={()=>removeImage()} />}

                                    {error && selectedFile === null && 
                                                <div className="error-text"> {t('Select_Image')} </div>
                                            }
                                </div>

                            <Row className="grid mt-3">
                                <div className="grid-column myProfileSettings">
                                    <div className="widget-box area-widget">
                                        <div className="widget-box-content">
                                            <Col md={12}>
                                                <Autocomplete
                                                    disablePortal
                                                    value={testimonial?.rating}
                                                    options={stars}
                                                    getOptionLabel={(option) => option || ""}
                                                
                                                    onChange={(e, val)=>{ setTestimonial({...testimonial, rating:val}); }}
                                                    renderInput={(params) => <TextField {...params} label={t('form.Stars')} />}
                                                />

                                                {error && testimonial?.rating === '' && 
                                                    <div className="error-text"> {t('Enter_Rating')}</div>
                                                }
                                            </Col>
                                        </div>
                                    </div>
                                </div>
                            </Row>              
                        </Col>
                        <Col md={8}>
                            <Row className="grid">
                                <div className="grid-column myProfileSettings">
                                    <div className="widget-box area-widget">
                                        <div className="row widget-box-content">
                                            <Col lg={6} md={6} className="mb-4">
                                                <TextField label={t('form.label_name')}
                                                    value={testimonial?.name}
                                                    onChange={(e)=>setTestimonial({...testimonial, name:e.target.value})}
                                                />
                                                {error && testimonial?.name === '' && 
                                                    <div className="error-text"> {t('Enter_Name')} </div>
                                                }
                                            </Col>
                                            <Col lg={6} md={6} className="mb-4">
                                                <TextField label={t('Company')}
                                                    value={testimonial?.company}
                                                    onChange={(e)=>setTestimonial({...testimonial, company:e.target.value})}
                                                />
                                                {error && testimonial?.company === '' && 
                                                    <div className="error-text"> {t('Enter_Company_Name')} </div>
                                                }
                                            </Col>
                                            <Col md={12}>
                                                <FormTextArea label={t('Text')} 
                                                value={testimonial?.content}
                                                onChange={(e)=>( e.target.value.length < 200)?setTestimonial({...testimonial, content:e.target.value}):''}
                                                />
                                                {error && testimonial?.content === '' && 
                                                    <div className="error-text"> {t('Enter_Testimonial_Content')} </div>
                                                }
                                            </Col>
                                        </div>
                                    </div>
                                </div>
                            </Row>   

                            <button type="" className="button" onClick={handleSubmit}>{t('Submit')}
                            {loader && <span className="spinner-border" />}
                            </button>
                        </Col>
                    </Row>
            </Box>
            </Popup>
        </Row>
    </>)
}
export default TestimonialCms;

