import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import RoundCheckbox from "../../../commonComponents/roundCheckbox";
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch } from "react-redux";
import CoverImagePopUp from "../../../commonDashboardComp/coverImagePopUp";
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete
} from '@mui/material';

import { useSelector } from "react-redux";
import { selectCurrentUser, selectValidatorChange } from "../../../Reducer/user/user.selector";
import { getImageRequestService } from "../../../services/auth.services";
import { getEventDetailByDate, postValidatorInfo } from "../../../services/event.services";
import { useNavigate } from "react-router-dom";
import { IdTypes, currencyName, rateOptions } from "../../../config/constants";
import { allowOnlyLetters, getImageUrl, showSingleToastSuccess } from "../../../utils/helpers";
import { Country, City } from 'country-state-city';

import BankInfo from "./bankInfo";

const ValidatorAllDetails = () => {
    const dispatch = useDispatch();
    const validatorChange = useSelector(selectValidatorChange)
    const [allErrors, setAllErrors] = useState(null);
    const [imageGetData, setImageGetdata] = useState(null);
    const user = useSelector(selectCurrentUser);
    const [photoOpen, setPhotoOpen] = useState(false);
    const handlePhotoOpen = () => setPhotoOpen(true);
    const handlePhotoClose = () => setPhotoOpen(false);
    const newCountryList = [];
    Country.getAllCountries()?.filter((a) => {
        if(City.getCitiesOfCountry(a?.isoCode)?.length>0){
            newCountryList.push(a?.name);
        if (newCountryList?.indexOf(a?.name) === -1) { newCountryList.push(a?.name); }
        }
        return a?.name;
    
    })
    //const CURRENTUSER = useSelector(selectCurrentUser);
    const [cityList, setCityList] = useState([]);
    const navigate = useNavigate();
    const INITIAL_FORM_VALUES = {
        "region": "",
        "nationalId": "",
        "nationalProof": "",
        "country": "",
        "city": "",
        "cv": "",
        "rate_per_hour": "",
        "rate_per_participant": "",
        "fixed_rate": "",
        "currency": "",
        "validatorDetail": [
            {
                "date": "",
                "startTime": "",
                "endTime": "",
                "timezone": "",
                "currency": "",
                "region": "",
                "rate_per_hour": "",
                "rate_per_participant": "",
                "fixed_rate": "",
            }
        ]
    }
    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);
    const { t } = useTranslation();
    useEffect(() => {
        getPrepopulatedData()
    }, [])
    const loadCityList = (data) =>{
        const cityListSamp = [];
        if(data.country !=''){
            let code=Country.getAllCountries()?.find(a=>a?.name===data.country);
            City.getCitiesOfCountry(code?.isoCode)?.filter(a=>{
                if(cityListSamp.indexOf(a?.name) === -1){
                    cityListSamp.push(a?.name);
                }
            });
        }
        setCityList(cityListSamp?.length>0?cityListSamp:[]);
    }
    const getPrepopulatedData = () => {
        let dateToday = moment(new Date, 'DD-MM-YYYY').format();
        getEventDetailByDate(dateToday).
            then((resp) => {
                if (resp?.status === 200) {
                    setFormValues(resp?.data?.data);
                    loadCityList(resp?.data?.data);
                }
            })
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();

        const FinalObj = { ...formValues };
        if (allValidatorsErrors()) {
            postValidatorInfo(FinalObj).
                then((resp) => {
                    if (resp.status === 200) {
                        showSingleToastSuccess(t('Validator_Detail_Submitted'));
                        // navigate('/validator-availibility');
                    }
                })
                .catch((error) => console.log(error))
        }
        else {
            console.log("Errors");
        }

    }
    const allValidatorsErrors = () => {
        let errors = {};
        let cnt = 0;
        // if (formValues?.region == '') {
        //     errors['region'] = true; cnt = cnt + 1;
        // }
        if (formValues?.country == '' || formValues?.country == null) {
            errors['country'] = true; cnt = cnt + 1;
        }
        if (formValues?.city == '' || formValues?.city == null) {
            errors['city'] = true; cnt = cnt + 1;
        }
        if (formValues?.nationalId == '') {
            errors['nationalId'] = true;
            cnt = +1;
        }
        // if (formValues?.cv == '') {
        //     errors['cv'] = true;
        //     cnt += 1;
        // }
        if (formValues?.nationalProof == '') {
            errors['nationalProof'] = true;
            cnt += 1;
        }
        if (formValues?.currency == '' || formValues?.currency == null) {
            errors['currency'] = true;
            cnt += 1;
        }
        if (!formValues?.rate_per_hour && !formValues?.rate_per_participant && !formValues?.fixed_rate) {
            errors['rate'] = true;
            cnt += 1;
        }
        setAllErrors(errors);
        return cnt == 0;

    }

    const containsStringOnly = (value) => {
        let contains = true;
        for (let i = 0; i < value.length; i++) {
            if ((value[i] >= 'a' && value <= 'z') || (value[i] >= 'A' && value <= 'Z') || value[i] == ' ')
                contains = true;
            else return false;
        }
        return true;

    }

    const handleChange = (e) => {
        const formValuesNew = { ...formValues };
        if (e.target.name === 'region') {
            if (containsStringOnly(e.target.value)) {
                formValuesNew[e.target.name] = e.target.value;
                setAllErrors({ ...allErrors, region: '' });
                formValuesNew[e.target.name] = e.target.value;

            }
        }
        if (e.target.name == 'nationalId') {
            formValuesNew[e.target.name] = e.target.value;
            setAllErrors({ ...allErrors, nationalId: '' })

        }
        setFormValues(formValuesNew);
    }

    const handleFileIdUpload = (param, typeOf, label) => {
        let category = 'validatorInfo';
        let type = typeOf;
        let fileName = Date.now();
        let defaultId = user?._id;
        fetchImageData(category, type, `${fileName}`, defaultId, param, label);
    }

    const fetchImageData = async (category, type, fileName, defaultId, param, label) => {
        try {
            let params = {
                fileName: param?.name,
                category: category,
                type: type,
                defaultId: defaultId
            }
            var response = await getImageRequestService(params);
            if (response?.status === 200) {
                let formsValCopy = { ...formValues };
                formsValCopy[type] = param?.name;
                setFormValues(formsValCopy);
                let allErrorscpy = { ...allErrors };
                allErrorscpy[label] = '';
                setAllErrors(allErrorscpy);
                putImageRequest(response?.data?.data, param, type, param?.name, label);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const putImageRequest = async (url, actParam, type, fileName) => {
        const response = await fetch(url + '&x-ms-blob-type=BlockBlob', {
            method: 'PUT',
            headers: {
                'x-ms-blob-type': `BlockBlob`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data'
            },
            body: actParam
        })
            .then(res => {
                if (res.ok) {
                    const formValuesNew = { ...formValues }
                    if (type === 'validatorId') {
                        formValuesNew['nationalProof'] = fileName;
                    }
                    if (type === 'validatorCv') {
                        formValuesNew['cv'] = fileName;
                    }
                    setFormValues(formValuesNew);

                }
                return res;
            })
            .catch((error) => {
                console.error(error);
            });

        return response;
    }


    const handleChangeAmounts = (e) => {
        if (!isNaN(e.target.value)) {
            setAllErrors({ ...allErrors, rate: '' })
            const formValuesNew = { ...formValues };
            formValuesNew[e.target.id] = e.target.value;
            setFormValues(formValuesNew)
        }
    }
    const handleChangeCurrency = (e, id) => {
        const formValuesNew = { ...formValues };
        formValuesNew[id] = e.target.innerText;
        setAllErrors({ ...allErrors, currency: '' })
        setFormValues(formValuesNew);
    }

    const viewIngProof = () => {
        let url = getImageUrl(user?._id, 'validatorId', formValues?.nationalProof);
        setImageGetdata(url)
        setTimeout(() => {
            handlePhotoOpen();
        }, 500)

    }

    const viewCV = () => {
        let url = getImageUrl(user?._id, 'validatorCv', formValues?.cv);
    }
    const onDownloadClick = (e) => {
        e.preventDefault();
        fetch(getImageUrl(user?._id, 'validatorCv', formValues?.cv)).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                //formValues?.cv
                alink.href = fileURL;
                alink.download = `my-CV.pdf`;
                alink.click();
            })
        })
    }
    const handleCountryChange = (e, val) => {

        //e.preventDefault();
        const cityListSamp = [];
        if (formValues?.country || val) {
            let code = Country.getAllCountries()?.find(a => a?.name === val);
            City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                if (cityListSamp.indexOf(a?.name) === -1) { cityListSamp.push(a?.name); }
            });
        }
        setCityList(cityListSamp?.length > 0 ? cityListSamp : []);
        setFormValues({ ...formValues, country: (val !== null) ? allowOnlyLetters(val) : null, city: "" });
        setAllErrors({ ...allErrors, country: '' })


    }
    return (<>
        <Row className="grid">
            <div className="grid-column myProfileSettings">
                <div className="widget-box">
                    <div className="widget-box-content">
                        <Box
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%', zIndex: '699' },
                            }}
                            noValidate
                            autoComplete="off"
                            className="userdetail-form targetvalidator validation_popup_box"

                            style={{ zIndex: '10000' }}
                        >

                            <div className='my-3'>
                                <h3 className='validatorForm_header'><span className='text-danger'>*</span>{t('validators_info.validator_header')}</h3>
                                <Row>
                                    {/* <Col lg={3} className="col-md-6 popup_input mb_input">
                                        <TextField type='text' id="region" label={t('region')}
                                            name="region"
                                            value={formValues?.region}
                                            sx={{
                                                ".MuiOutlinedInput-root": {
                                                    paddingTop: "1rem",
                                                    flexDirection: "row"
                                                },
                                                img: {
                                                    paddingRight: "1rem"
                                                }
                                            }}
                                            InputProps={{
                                                endAdornment: <img src="/Assets/Pin-Icon-target.png" className="" />
                                            }}

                                            onChange={(e) => handleChange(e)}

                                        />
                                        {allErrors?.region &&
                                            <div className="error-text">
                                                {t('validators_info.Please_Enter_Region')}
                                            </div>
                                        }
                                        {formValues?.region?.length > 15 &&
                                            <div className="error-text">
                                                {t('validators_info.Name_is_too_long')}
                                            </div>
                                        }
                                    </Col> */}

                                    <Col lg={4} className="validatorFormInputs">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                disablePortal
                                                value={formValues?.country}
                                                options={newCountryList}
                                                getOptionLabel={(option) => { return option }}
                                                onChange={(e, val) => {
                                                    //      setFormValues({
                                                    //     ...formValues,
                                                    //         country: (val !== null) ? allowOnlyLetters(val) : null,
                                                    //         city: ''
                                                    // });
                                                    handleCountryChange(e, val)
                                                }}

                                                renderInput={(params) => <TextField {...params} label={t('country')} />}
                                            />
                                        </FormControl>
                                        {allErrors?.country &&
                                            <div className="error-text">
                                                Please Enter Country
                                            </div>
                                        }
                                    </Col>
                                    <Col lg={4} className="validatorFormInputs">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                disablePortal
                                                value={formValues.city || ''}
                                                options={cityList}
                                                onChange={(e, val) => { setFormValues({ ...formValues, city: val }); setAllErrors({ ...allErrors, city: '' }) }}
                                                renderInput={(params) => <TextField {...params} label={t('city')} />}
                                            />
                                        </FormControl>
                                        {allErrors?.city &&
                                            <div className="error-text">
                                                Please Enter City
                                            </div>
                                        }
                                    </Col>
                                    <Col lg={4} className="col-md-12 uploadCvInput">
                                    <TextField
                                            id="outlined-basic1"
                                            variant="outlined"
                                            type={"file"}
                                            className='fileUpload custom-file-input2'
                                            onChange={(e) => handleFileIdUpload(e.target.files[0], 'validatorCv', 'cv')}
                                            inputProps={{
                                                accept: "application/pdf",
                                                endAdornment: <img src="/Assets/upload-inactive-icon.png" className="popup_upload_icon" />
                                            }}
                                        />
                                        {formValues?.cv && <button onClick={onDownloadClick} target="_blank" className="btn viewProof small_submit_btn" >{t('Download_CV')}</button>
                                        }
                                        {/* <iframe
    src="https://webapp-cnd86568587ojklj.azureedge.net/user/63bc1377724f3567389a573a/validatorCv/1673270686399"
    frameBorder="0"
    scrolling="auto"
    height="100%"
    width="100%"
></iframe> */}

                                        {/* <TextField 
                                             type={"file"} 
                                             className='fileUpload custom-file-input2' 
                                             onChange={(e) => handleFileIdUpload(e.target.files[0], 'validatorCv', 'cv')} 
                                             inputProps={{
                                                   accept: "application/pdf,application/docx,application.doc", 
                                                   endAdornment: <img src="/Assets/upload-inactive-icon.png" className="popup_upload_icon" />
                                        }} /> */}
                                        {/* {allErrors?.cv &&
                                            <div className="error-text">
                                                {t('validators_info.Please_Upload_Your_CV')}
                                            </div>
                                        } */}
                                    </Col>

                                    <Col lg={6} className="col-md-12 drop_menu validatorDetailInputs2">
                                        <FormControl fullWidth>
                                                <InputLabel id="nationalId-select-label">{t('validators_info.ID_Type')}</InputLabel>
                                                <Select
                                                    labelId="nationalId-select-label"
                                                    id="nationalId"
                                                    name="nationalId"
                                                    value={formValues?.nationalId}
                                                    label={t('validators_info.ID_Type')}
                                                    onChange={(e) => handleChange(e)}
                                                    InputProps={{
                                                        startAdornment: <img src="/Assets/Pin-Icon-target.png" className="" />
                                                    }}
                                                >
                                                    {IdTypes.map((item, idx) => {
                                                        return (
                                                            <MenuItem key={idx} value={item}>{item}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                        </FormControl>
                                        {allErrors?.nationalId &&
                                            <div className="error-text">
                                                {t('validators_info.Please_Select_National_Id')}
                                            </div>
                                        }   
                                    </Col>

                                    <Col lg={6} id="customFileInputContainerMaster" className="col-md-12 popup_file validatorDetailInputs3">
                                        <div id="customFileInputContainer">
                                                <label id="customFileInputLabel" className="">{formValues?.nationalId ? formValues?.nationalId : t('validators_info.national_id')}</label>
                                                <input id="customFileInput" className="custom-file-input" title={formValues?.nationalProof} type="file" accept="image/*" onChange={(e) => handleFileIdUpload(e.target.files[0], 'validatorId', 'nationalProof')} />
                                                {allErrors?.nationalProof &&
                                                    <div className="error-text inp_file_error">
                                                        {t('validators_info.Please_Upload_National_Proof')}
                                                    </div>
                                                }
                                            </div>
                                        {formValues?.nationalProof && <span className="viewProof small_submit_btn me-0" onClick={() => viewIngProof()}>{t('view_proof')}</span>}
                                        
                                    </Col>
                                </Row>
                                <Row>
                                    <h6 className='mb-3 ms-lg-2'>{t('validators_info.fees_charging_modal')}</h6>
                                    <Col lg={3} className="validatorCurrencyInput">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                disablePortal
                                                value={formValues?.currency}
                                                options={currencyName}
                                                getOptionLabel={(option) => option}
                                                onChange={(e) => handleChangeCurrency(e, 'currency')}
                                                renderInput={(params) => <TextField {...params} label={t('superAdmin.Currency')} />}
                                            />
                                        </FormControl>
                                        {allErrors?.currency &&
                                            <div className="error-text">
                                                {t('validators_info.Please_Select_currency')}
                                            </div>
                                        }

                                    </Col>
                                    <Col lg={9} className='col-md-12 validatorCheckboxes popup_text_area mt-lg-0'>
                                        <div className="fee_charging_box">
                                            {rateOptions.map((item, index) => {
                                                return <div>
                                                    <RoundCheckbox label={item?.label} key={index} newClassName='activeClass' checked={false} other={true} input={<input type="text" id={item?.chargesMode} value={formValues[item?.chargesMode]} onChange={(e) => { !isNaN(e.target.value) && handleChangeAmounts(e) }} className="checkbox_text_feild" placeholder={t('validators_info.Amount')} checked={true} />} />
                                                </div>
                                            })}
                                        </div>
                                        {allErrors?.rate &&
                                            <div className="error-text">
                                                {t('validators_info.Please_Enter_atleast_one_Charging_Model_Amount')}
                                            </div>
                                        }

                                    </Col>
                                </Row>

                                {allErrors?.allFeild &&
                                    <div className="error-text">
                                        {t('validators_info.Please_Choose_your_Availabiltity_Details')}
                                    </div>
                                }
                                <div className="text-center my-2">
                                    <button className='button table_desin_btn' onClick={(e) => { handleSubmitForm(e) }}>{t('form.submit')}</button>
                                </div>
                            </div>
                            <BankInfo />
                        </Box>
                    </div>
                </div>
            </div>
        </Row>
        <CoverImagePopUp
            imageShow={imageGetData}
            open={photoOpen}
            handleClose={handlePhotoClose}

        /></>
    )
}
export default ValidatorAllDetails;