import ApiConfig from "../config/ApiConfig";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import instance, {instanceOrg} from "../AppInterceptor";
import { getObjectFromStore } from "../storage/Storage";

export const getOrderIdService = (payload) => {
    return instance.post(ApiConfig.getOrderId, payload);  
}

export const orderSuccess = (payload) => {
    return instance.post(ApiConfig.paymentSessionResponse, payload);
}


export const LoadRazorpaySDK = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
        toast.error("Razorpay SDK failed to load. Are you online?", {
          autoClose: 2000,
        });
      };
      document.body.appendChild(script);
    });
  };