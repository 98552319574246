import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { Box, TextField } from '@mui/material'
import SectionHeader from "../../../../commonDashboardComp/sectionHeader";
import { passwordRegexValidate } from "../../../../utils/formatValidator";
import Popup from "../../../../commonComponents/popup";
import { Link } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const ChangePasswordInfo = ({
    passwordFormValues,
    setPasswordFormValues,
    updatePassword,
    PasswordPopupOpen,
    counter,
    onClickForgotPassword,
    handleClose,
    resetPassword
}) => {
    const { t } = useTranslation();
    //for current pass
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(false);
    //forPass
    const [pType, setPtype] = useState('password');
    const [pIcon, setPicon] = useState(false);
    //for Fpass
    const [fpType, setFptype] = useState('password');
    const [fpIcon, setFpicon] = useState(false);


    //for current pass
    const handleOTPView = () => {
        if (type === 'password') {
            setIcon(true);
            setType('text');
        } else {
            setIcon(false);
            setType('password');
        }
    }
    //for Passsword
    const handlePasswordView = () => {
        if (pType === 'password') {
            setPicon(true);
            setPtype('text');
        } else {
            setPicon(false);
            setPtype('password');
        }
    }
    //for Fpasssword
    const handleFpasswordView = () => {
        if (fpType === 'password') {
            setFpicon(true);
            setFptype('text');
        } else {
            setFpicon(false);
            setFptype('password');
        }
    }
    return (<>
        <SectionHeader title={t('form.change_pwd_now')} style={{ marginTop: '0px' }} />
        <Row className="grid">
            <div className="grid-column myProfileSettings">
                <div className="widget-box area-widget">
                    <div className="widget-box-content">
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '98%' },
                            }}
                            noValidate
                            autoComplete="off"
                            onSubmit={(e) => { e.preventDefault(); updatePassword() }}
                            className="userdetail-form account_info_org_area userChangePassword profile_parent11"
                        >
                            <Row className="profile_subcategory22">
                                <Col md={12} className="userAccountChangePassword target_title88">
                                    <TextField
                                        error={
                                            (passwordFormValues.error && passwordFormValues.currentPassword === '') ? true : false}
                                        helperText={
                                            (passwordFormValues.error && passwordFormValues.currentPassword === '') ? t('errors.current_password_required') : ''}
                                        value={passwordFormValues.currentPassword}
                                        onChange={
                                            (e) => setPasswordFormValues(
                                                { ...passwordFormValues, currentPassword: e.target.value }
                                            )}
                                        type={type}
                                        InputProps={{
                                            endAdornment: <span className="passwordEye" onClick={handleOTPView} style={{ cursor: 'pointer' }}>
                                                {icon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </span>
                                        }}
                                        label={t('form.confirm_current_password')} />
                                </Col>
                                <Col md={6} className="userAccountChangePassword target_title88">
                                    <TextField
                                        error={
                                            (passwordFormValues.error && passwordFormValues.newPassword === '') ? true :
                                                (passwordFormValues.error && passwordFormValues.newPassword.length < 8) ? true :
                                                    (passwordFormValues.error && !passwordRegexValidate(passwordFormValues.newPassword)) ? true :
                                                        (passwordFormValues.error && passwordFormValues.currentPassword === passwordFormValues.newPassword) ? true : false}
                                        helperText={
                                            (passwordFormValues.error && passwordFormValues.newPassword === '') ? t('errors.new_password_required') :
                                                (passwordFormValues.error && passwordFormValues.newPassword.length < 8) ? t('errors.password_length') :
                                                    (passwordFormValues.error && !passwordRegexValidate(passwordFormValues.newPassword)) ? t('errors.password_format') :
                                                        (passwordFormValues.error && passwordFormValues.currentPassword === passwordFormValues.newPassword) ? t('errors.password_currentpwd_cant_same') : ''}
                                        value={passwordFormValues.newPassword}
                                        onChange={
                                            (e) => setPasswordFormValues(
                                                { ...passwordFormValues, newPassword: e.target.value }
                                            )}
                                        type={pType}
                                        InputProps={{
                                            endAdornment: <span className="passwordEye" onClick={handlePasswordView} style={{ cursor: 'pointer' }}>
                                                {pIcon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </span>
                                        }}
                                        inputProps={{ maxLength: 25, minLength: 8 }}
                                        label={t('form.new_password')} />
                                </Col>
                                <Col md={6} className="userAccountChangePassword target_title88">
                                    <TextField
                                        error={
                                            (passwordFormValues.error && passwordFormValues.confirmNewPassword === '') ? true :
                                                (passwordFormValues.error && passwordFormValues.confirmNewPassword !== passwordFormValues.newPassword) ? true : false}
                                        helperText={
                                            (passwordFormValues.error && passwordFormValues.confirmNewPassword === '') ? t('errors.confirm_password_required') :
                                                (passwordFormValues.error && passwordFormValues.confirmNewPassword !== passwordFormValues.newPassword) ? t('errors.confirm_password_wrong') : ''}
                                        value={passwordFormValues.confirmNewPassword}
                                        onChange={
                                            (e) => setPasswordFormValues(
                                                { ...passwordFormValues, confirmNewPassword: e.target.value }
                                            )}
                                        type={fpType}
                                        InputProps={{
                                            endAdornment: <span className="passwordEye" onClick={handleFpasswordView} style={{ cursor: 'pointer' }}>
                                                {fpIcon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </span>
                                        }}
                                        inputProps={{ maxLength: 25, minLength: 8 }}
                                        label={t('form.confirm_new_password')} />
                                </Col>
                                <Col md={6} className="changePasswordBtn">
                                    <button className="button primary"
                                        onClick={(e) => onClickForgotPassword(e)}>
                                        {t('form.forgot_your_password')} </button>
                                </Col>
                                <Col md={6} className="changePasswordBtn">
                                    <button className="button change_password_now" type="submit"> {t('form.change_pwd_now')} </button>
                                </Col>
                            </Row>
                        </Box>
                    </div>
                </div>
            </div>

        </Row>

        <Popup heading={t('form.submit_otp')} handleClose={handleClose} modalBodyClasses="otpPopup" modalContentClasses="changePasswordPopupContent" open={PasswordPopupOpen}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className="userdetail-form account_info_org_area"
                onSubmit={resetPassword}
            >
                <div className="otp_counter">00:{counter}</div>
                <TextField type={type}
                    className="userDetailsOtpInput"
                    error={(passwordFormValues.popupError && passwordFormValues.otp === '') ? true : (passwordFormValues.popupError && passwordFormValues.otp.length < 6) ? true : false}
                    helperText={(passwordFormValues.popupError && passwordFormValues.otp === '') ? t('errors.Enter_OTP') : (passwordFormValues.popupError && passwordFormValues.otp.length < 6) ? t('errors.OTP_is_of_6digits') : ""}
                    inputProps={{ maxLength: 6 }}
                    value={passwordFormValues.otp}
                    onChange={(e) => setPasswordFormValues(
                        { ...passwordFormValues, otp: e.target.value }
                    )}
                    InputProps={{
                        endAdornment: <span className="passwordEye" onClick={handleOTPView} style={{ cursor: 'pointer' }}>
                            {icon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </span>
                    }}
                    label={t('form.lebel_otp')} />

                <div className="d-flex justify-content-left">
                    {counter === 0 &&
                        <Link className="resendOtpText" to="#"
                            onClick={(e) => onClickForgotPassword(e)}>
                            {t('errors.Resend_Otp')}
                        </Link>
                    }
                </div>

                <TextField
                    error={
                        (passwordFormValues.popupError && passwordFormValues.password === '') ? true :
                            (passwordFormValues.popupError && passwordFormValues.password.length < 8) ? true :
                                (passwordFormValues.popupError && !passwordRegexValidate(passwordFormValues.password)) ? true : false}
                    helperText={
                        (passwordFormValues.popupError && passwordFormValues.password === '') ? t('errors.new_password_required') :
                            (passwordFormValues.popupError && passwordFormValues.password.length < 8) ? t('errors.password_length') :
                                (passwordFormValues.popupError && !passwordRegexValidate(passwordFormValues.password)) ? t('errors.password_format') : ''}
                    value={passwordFormValues.password}
                    onChange={
                        (e) => setPasswordFormValues(
                            { ...passwordFormValues, password: e.target.value }
                        )}
                    type={pType}
                    InputProps={{
                        endAdornment: <span className="passwordEye" onClick={handlePasswordView} style={{ cursor: 'pointer' }}>
                            {pIcon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </span>
                    }}
                    inputProps={{ maxLength: 25, minLength: 8 }}
                    className="userDetailsPasswordInput"
                    label={t('form.new_password')} />

                <TextField
                    error={
                        (passwordFormValues.popupError && passwordFormValues.confirmPassword === '') ? true :
                            (passwordFormValues.popupError && passwordFormValues.confirmPassword !== passwordFormValues.newPassword) ? true : false}
                    helperText={
                        (passwordFormValues.popupError && passwordFormValues.confirmPassword === '') ? t('errors.confirm_password_required') :
                            (passwordFormValues.popupError && passwordFormValues.confirmPassword !== passwordFormValues.newPassword) ? t('errors.confirm_password_wrong') : ''}
                    value={passwordFormValues.confirmPassword}
                    onChange={
                        (e) => setPasswordFormValues(
                            { ...passwordFormValues, confirmPassword: e.target.value }
                        )}
                    type={fpType}
                    InputProps={{
                        endAdornment: <span className="passwordEye" onClick={handleFpasswordView} style={{ cursor: 'pointer' }}>
                            {fpIcon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </span>
                    }}
                    className="userDetailsConfirmPass"
                    inputProps={{ maxLength: 25, minLength: 8 }}
                    label={t('form.confirm_new_password')} />

                <button type="submit" className="spredeSubmitBtn mt-4"> {t('form.submit')}</button>
            </Box>
        </Popup>
    </>)
}

export default ChangePasswordInfo;