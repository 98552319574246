import React, { useState, useEffect, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { GetMyPostsService, GetAllPostsService, eventPostsService , getUserFeeds, getOrgFeeds, GetPublicInterestPostsService} from "../services/post.services";
import BarsLoader from "../commonComponents/barsLoader";
import InfiniteScroll from "react-infinite-scroller";
import PostBox from "./postBox";
import { useSelector } from "react-redux";
import { selectLoadNewPosts, selectCurrentUserRole } from "../Reducer/user/user.selector";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
const PostList = ({myPosts=false, eventId=false, type, id}) => {
  
  const {t} = useTranslation();
  const [posts, setPosts] = useState([]);
  const [publicPosts, setPublicPosts] = useState([]);
  const [loadPublicPosts, setLoadPublicPosts] = useState(false);
  const role = useSelector(selectCurrentUserRole);
  const loadNewPosts = useSelector(selectLoadNewPosts);
  const limit=20;
  const [loading, setLoading] = useState(false);
  const [page, setPageNo] = useState(0)
  const [hasMore, SetHasMore] = useState(true);

  const [publicpage, setPublicPageNo] = useState(0)
  const [publichasMore, SetPublicHasMore] = useState(true);

  const [offset , setOffset] = useState(0);
  const path_name=window.location.pathname;
  const observer = useRef();
  const publicObserver = useRef();

  const lastElement = (node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((enteries) => {
        if (enteries[0].isIntersecting && hasMore) {
            setPageNo((prev) => prev + 1);
        }
    })
    if (node) {
        observer.current.observe(node);
    }
  }

  const lastPublicElement = (node) => {
    if (loading) return;
    if (publicObserver?.current) publicObserver?.current.disconnect();
    observer.current = new IntersectionObserver((enteries) => {
        if (enteries[0].isIntersecting && hasMore) {
            setPublicPageNo((prev) => prev + 1);
        }
    })
    if (node) {
      publicObserver?.current?.observe(node);
    }
  }

  useEffect(()=>{
    setPosts([]);
    setPageNo(0)
    setOffset(0);
    setPublicPosts([]);
    SetPublicHasMore(true);
    setPublicPageNo(0);
    SetHasMore(true)
  }, [loadNewPosts, role, eventId])

  useEffect(()=>{ 
    if(loadPublicPosts)
    {
      getPublicPosts();
    }
  }, [loadPublicPosts])

  useEffect(()=>{
    getPostLists();
  }, [page, hasMore, role, loadNewPosts, id])


const getPublicPosts = () => {
  if(loadPublicPosts)
  {
    if(publichasMore)
    {
      setLoading(true);
      GetPublicInterestPostsService(publicpage, limit)
      .then((data)=>{
        if(data?.data?.status === 200){
          if(data?.data?.data?.length === 0 || data?.data?.data?.length < limit){
            SetPublicHasMore(false);
          }
          //setPosts([...posts, ...data?.data?.data]);
          let postList = [...data?.data?.data]
          let newArr = postList.reduce((acc, curr) => {
                  let fnd = acc.find((a) => a?._id === curr?._id);
                  if (!fnd) acc.push(curr);
                  return acc;
              }, []);
          setPublicPosts(newArr)
        }
      
        setLoading(false);
      })
      .catch((error)=>{
        console.log(error)
      })
    }
  }
}
 
    const getPostLists = () => {
      if(hasMore)
      {
        setLoading(true);
        if(eventId){
          eventPostsService(page, limit, eventId)
            .then((data)=>{
              if(data?.data?.status === 200){
                if(data?.data?.data?.length === 0 || data?.data?.data?.length < limit){
                  SetHasMore(false);
                }
                setPosts([...posts, ...data?.data?.data]);
              }
            
              setLoading(false);
            })
            .catch((error)=>{
              console.log(error);
            })
        }
        else if(type === 'user' || type === 'org'){
          if(id !== undefined)
          {
            getUserFeeds(page, limit, id)
              .then((data)=>{
                if(data?.data?.status === 200){
                  if(data?.data?.data?.length === 0 || data?.data?.data?.length < limit){
                    SetHasMore(false);
                  }
                  //setPosts([...posts, ...data?.data?.data]);
                  let postList = [...posts, ...data?.data?.data]
                  let newArr = postList.reduce((acc, curr) => {
                          let fnd = acc.find((a) => a?._id === curr?._id);
                          if (!fnd) acc.push(curr);
                          return acc;
                      }, []);
                  setPosts(newArr)
                }
              
                setLoading(false);
              })
              .catch((error)=>{
                console.log(error);
              })
          }
        }
        else{
          if(myPosts){
            GetMyPostsService(page, limit)
            .then((data)=>{
              if(data?.data?.status === 200){
                if(data?.data?.data?.length === 0 || data?.data?.data?.length < limit){
                  SetHasMore(false);
                }
                //setPosts([...posts, ...data?.data?.data]);
                let postList = [...posts, ...data?.data?.data]
                let newArr = postList.reduce((acc, curr) => {
                        let fnd = acc.find((a) => a?._id === curr?._id);
                        if (!fnd) acc.push(curr);
                        return acc;
                    }, []);
                setPosts(newArr)
              }
            
              setLoading(false);
            })
            .catch((error)=>{
              console.log(error);
            })
          }
          else{
            GetAllPostsService(page, limit)
            .then((data)=>{
              if(data?.data?.status === 200){
                if(data?.data?.data?.length === 0 || data?.data?.data?.length < limit){
                  SetHasMore(false); setLoadPublicPosts(true);
                }
                //setPosts([...posts, ...data?.data?.data]);
                let postList = [...posts, ...data?.data?.data]
                let newArr = postList.reduce((acc, curr) => {
                        let fnd = acc.find((a) => a?._id === curr?._id);
                        if (!fnd) acc.push(curr);
                        return acc;
                    }, []);
                setPosts(newArr)
              }
            
              setLoading(false);
            })
            .catch((error)=>{
              console.log(error);
            })
          }
        }
      }
      
    }
    
    return (<div className="postListDiv">
        {
          posts.length > 0 && posts.map((item, index)=>{
            return (<Fragment key={index}>
              {index+1 === posts.length ?
                <div  ref={lastElement}  className="grid-column">
                  <PostBox post={item} eventId={eventId} />
                </div> : 
                <div  className="grid-column">
                  <PostBox post={item} eventId={eventId} />
                </div>
              } 
            </Fragment>)
          })
        }

        {
          publicPosts.length > 0 && publicPosts.map((item, index)=>{
            return (<Fragment key={index}>
              {index+1 === publicPosts.length ?
                <div  ref={lastPublicElement}  className="grid-column">
                  <PostBox post={item} eventId={eventId} />
                </div> : 
                <div  className="grid-column">
                  <PostBox post={item} eventId={eventId} />
                </div>
              } 
            </Fragment>)
          })
        }

        {loading && <BarsLoader/>}

        {!loading && posts.length === 0 && path_name !== '/voxbox' && (type !== 'user' && type !== 'org') &&
          <div className="widget-box">
            <Row>                
                <Link to="/voxbox" className="mb-3 button secondary">{t('start_posting')}</Link>
            </Row> 
        </div>
        }
    </div>)
}

export default PostList;