import axios from 'axios';
import ApiConfig from './config/ApiConfig';
import getRefreshToken from '../src/services/getRefreshToken';
import UserAxios from './services/userAxios';
import { getObjectFromStore, storageKeys, getItemFromStorage, clearStorage } from './storage/Storage';


const instance = axios.create({});
const instance2 = axios.create({});
const instanceOrg = axios.create({});  //Axios Instance for organization

instance.interceptors.request.use(request => requestHandler(request));

instance.interceptors.response.use(
  response => {
    return response;
  },
  (error) => errorHandler(error),
);

const requestHandler = async request => {
  let token = ApiConfig.token;
  let role = await getObjectFromStore('role');

  if (!token) {
    // if(role === 'organization')
    // {
    //   token = await getObjectFromStore(storageKeys.orgToken);
    // }
    // else{
    token = await getObjectFromStore(storageKeys.token);
    // }
  }
  request.headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    Accept: '*/*',
    // 'Access-Control-Allow-Origin': "*/*"
  };
  return request;
};

const errorHandler = async error => {
  try{
    const originalConfig = error.config;
    if(error.response?.status == 401) {
      // clearStorage();
      // window.location.href = '/';
    }else if (error.response?.data?.message === "jwt expired") {
      const refToken = getObjectFromStore('refresh_token')
      originalConfig._retry = true;
      await getRefreshToken();
      const token1 = await getObjectFromStore('token')
      try {
        return instance(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
    throw error.response;
  }
  catch(e){
    return e;
  }
};

instanceOrg.interceptors.request.use(request => requestHandlerOrg(request));

instanceOrg.interceptors.response.use(
  response => {
    return response;
  },
  (error) => errorHandlerOrg(error),
);

const requestHandlerOrg = async request => {
  let token = ApiConfig.token;
  let role = await getObjectFromStore('role');

  if (!token) {
    token = await getObjectFromStore(storageKeys.orgToken);
  }
  request.headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    Accept: '*/*',
  };
  return request;
};

const errorHandlerOrg = async error => {
  try{
    const originalConfig = error.config;
    if(error.response?.status == 401) {
      // clearStorage();
      // window.location.href = '/';
    }else if(error.response?.status === 403){
      console.log("Your Organization has been disabled");
    }
    else if (error.response?.data?.message === "jwt expired") 
    {
        const refToken = getObjectFromStore('refresh_token')
        originalConfig._retry = true;
        await getRefreshToken('organization');
        const token1 = await getObjectFromStore('token')
        try {
          return instanceOrg(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
    }
    throw error.response;
  } catch(e){
    return e;
  }
  
};

export default instance
export { instance2, instanceOrg };
