import React,{useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { TextField, Button } from "@mui/material";
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import FewFriendsList from "../../../commonDashboardComp/FewFriendsList";
import EventCalender from "../../../commonDashboardComp/eventCalender";
import TodayEventList from "../../../commonDashboardComp/todayEventsList";
import InviteFriendsList from "../../../commonDashboardComp/inviteFriendsBox";
import EventDetailBox from "../../../commonDashboardComp/eventDetailBox";
import { getImageUrl, showSingleToastSuccess } from "../../../utils/helpers";
import swal from 'sweetalert';
import { getEventDetailService, deleteEventService } from "../../../services/event.services";
import { deleteNotificationsService } from "../../../services/onBoarding.services";
import { showToastError, showToastSuccess } from "../../../utils/helpers";
import EventBox from "../../../commonDashboardComp/eventBox";
import TabNavigation from "../../../commonDashboardComp/tabNavigation";
import { useSelector } from "react-redux";
import { selectCurrentOrg, selectCurrentUser, selectCurrentUserRole } from "../../../Reducer/user/user.selector";
import ActiveEventParticipants from "./activeEventParticipants";
import BlockedEventParticipants from "./blockedEventParticipants";
import OrganizationEventParticipants from "./organizationEventParticipants";
import { t } from "i18next";
const ManageEvent = () => {

    const role = useSelector(selectCurrentUserRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);

    let current_user = {};
    if(role === 'organization'){   current_user = CURRENTORG     }
    else{ current_user = CURRENTUSER }

    const [showEventHeader,setEventHeader]=useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const eventId = location?.state?.eventId;
    const [event, setEvent] = useState(false);
    const [tab, setTab] = useState(1);
    let tabs = [];
    if(role === 'organization')
    {
        tabs = [t('active_participants'), t('blocked_participants'), t('Participanted_org')];
    }
    else{
        tabs = [t('active_participants'), t('blocked_participants')];
    }

    useEffect(()=>{
        if(eventId === undefined){ navigate('/add-event') }
        else{ 
            getEventDetail()
        }
    },[])

    useEffect(()=>{
        if(event?.spredeId && (event?.spredeId !== current_user?._id))
        {
            navigate('/event')
        }
    },[role, event])
   
    const getEventDetail = () => {
        getEventDetailService(eventId)
        .then((data)=>{
            if(data?.data?.status === 200){
                setEvent(data?.data?.data)
            }
            else{
                showToastError(t('No_Event_Found'));
                navigate('/add-event');
            }
        })
    }

    const editEventHandler = (e) => {
        e.preventDefault();
        navigate('/add-event', { state: {eventData:event, targetId:event?.targetId}})
    }

    const viewEventHandler = (e) => {
        e.preventDefault();
        navigate('/view-event' , {state:{eventId : eventId}})
    }

    const deleteEventHandler = (e) => {
        e.preventDefault();
        swal({
            text: t('alerts.delete_event'),
            title: '',
            icon: "warning",
            buttons: {
              Yes: {
                text:t('yes'),
                value: true,
              },
              No: {
                text: t('no'),
                value: false,
              },
            },
          })
          .then(async (val) => {
                if(!val) return;
                deleteEventService(eventId)
                .then((data)=>{
                    if(data?.data?.status === 200){
                        // showToastSuccess("Event Deleted Successfully");
                        deleteNotificationsService('eventDelete', eventId)
                        showSingleToastSuccess(t('Event_Deleted_Successfully'));
                        navigate('/events')
                    }
                    else
                    {
                        showToastError(t('errors.some_error'));
                    }
                })
                .catch((error)=>{
                    console.log(error);
                })
            });
    }

    function makeButtons(){
        return <>
        <div className="col-md-12 banner-btn-area event-btn">
        <button  className="btn btn-success active">{t("manage_participants")}</button>
        <button className="btn btn-secondary green" onClick={ (e)=>viewEventHandler(e)} >{t("button.view_event")}</button>
        <button  className="btn btn-secondary green" onClick={(e)=>editEventHandler(e)} >{t("superAdmin.edit_event_text")}</button>
        <button className='btn btn-danger red' onClick={(e)=>deleteEventHandler(e)} >{t("superAdmin.delete_event")}</button>
        </div>
        </>
       
    }
    return(<>
        <Row> {event && <ProfileHeader 
                buttons={makeButtons()} 
                showEventHeader={true}  
                imageContainer={false} 
                eventDetail={event}
                cover={getImageUrl(event?._id, 'event', event?.photo)}
                />} </Row>
        
        {/* <div className='text-secondary superAdminheading1 mt-3'> {t('superAdmin.manage_event_heading1')} </div> */}
        <h2 className='mb-3 mt-3'> {t('manage_participants')} </h2>

        <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab} showInput={false} />

        {tab === 1 && <ActiveEventParticipants eventId={eventId}/>}
        {tab === 2 && <BlockedEventParticipants eventId={eventId}/>}
        {tab === 3 && <OrganizationEventParticipants eventId={eventId}/>}
    </>)
}

export default ManageEvent;