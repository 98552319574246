import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentRole, setCurrentUser, setCurrentOrg, setUserWorkingOrgData, setNotificationCount } from "../Reducer/user/user.action";
import { setObjectInStore } from "../storage/Storage";
import { useEffect } from "react";
const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(()=>{
        reset()
    }, [])

    const reset = () => {
        dispatch(setCurrentUser(null));
        dispatch(setCurrentRole(null));
        dispatch(setCurrentOrg(null));
        dispatch(setUserWorkingOrgData({}))
        dispatch(setNotificationCount(0));
        setObjectInStore('refresh_token',null);
        setObjectInStore('token',null);
        setObjectInStore('role',null);
        setObjectInStore('orgToken',null);
        setObjectInStore('org_refresh_token',null);
        setObjectInStore('postlink','');
        navigate('/');
    }
}
export default Logout;