import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { FacebookImg, LinkedInImg, GoogleImg } from "../../commonComponents/Images";
import { useGoogleLogin } from "@react-oauth/google";
import { getUserDetailsService, getReq, socialLogin , getIPAddressService} from "../../services/auth.services";
import { showToastError } from "../../utils/helpers";
import { useDispatch } from "react-redux";
import { setCurrentUser, setCurrentRole } from "../../Reducer/user/user.action";
import { setObjectInStore } from "../../storage/Storage";
import { Link, useNavigate } from "react-router-dom";
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { LinkedInDetailsService } from "../../services/auth.services";
import FacebookLogin from 'react-facebook-login';
import { browserName, isMobile } from "react-device-detect";
const SocialLogins = () => {
    const {t}=useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ipData, setIpData] = useState({});

    useEffect(()=>{
        getIpData();
    }, [])

    const getIpData = () => {
        try{
            getIPAddressService()
            .then((data)=>{
            setIpData(data?.data)
        })
        .catch((error) => {
            console.log(error);
        })
        }
        catch(e) {
            console.log(e);
        }
        
    }
   
    const getLoginUserDetails = async() => {
        const response = await getUserDetailsService();
        if (response?.data?.data) {
            const user = response?.data?.data
            dispatch(setCurrentUser(user));
            dispatch(setCurrentRole('user'));
            navigate('/voxbox');
        }
    }    

    //Google Login Handler
    const GoogleLoginHandler = useGoogleLogin({
        onSuccess: tokenResponse => {
            setObjectInStore("token", tokenResponse?.access_token)
            getReq('https://www.googleapis.com/oauth2/v3/userinfo')
            .then(res => {
                setObjectInStore("token", tokenResponse?.access_token)
                let params = {
                    email: res.data.email,
                    firstName: res.data.given_name,
                    lastName: res.data.family_name?res.data.family_name:res.data.given_name,
                    socialId: res.data.sub,
                    signupType: "google",
                    ipAddress : ipData?.IPv4,
                    location : ipData?.country_name,
                    browser : browserName,
                    device : (isMobile)?'Mobile':'Desktop'
                }

                try{
                    SocialLoginService(params)
                }
                catch(error){
                    console.log(error);
                }
            })
            .catch((error) => {
                console.log(error)
            });
        },
    });

    //LinkedIn Login 
    const { linkedInLogin } = useLinkedIn({
        clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
        redirectUri: `${window.location.origin}/linkedin`, 
        scope : `r_liteprofile r_emailaddress`,
        onSuccess: async(code) => {
          LinkedInSuccessHandler(code);
        },
        onError: (error) => {
          console.log(error);
          //showToastError('Some Error Occurred. Please try again later');
        },
      });

    const LinkedInSuccessHandler = async(code)=>{
    try{
        LinkedInDetailsService(code)
        .then(res =>{
            if(res?.data?.status === 200)
            {
                let params = {
                    email: res?.data?.data?.emailInfo.elements[0]['handle~']['emailAddress'],
                    firstName:res?.data?.data?.personalInfo['localizedFirstName'],
                    lastName: res?.data?.data?.personalInfo['localizedLastName'],
                    socialId: res?.data?.data?.personalInfo['id'],
                    signupType: "linkedin",
                    ipAddress : ipData?.IPv4,
                    location : ipData?.country_name,
                    browser : browserName,
                    device : (isMobile)?'Mobile':'Desktop'
                }

                try{
                    SocialLoginService(params)
                }
                catch(error){
                    console.log(error);
                }
            }
        })
        .catch((error)=>{
            console.log(error);
        })
        }
        catch(error){
        console.log(error)
        }
    }

    //Facebook Login 
    const onSuccessFacebook=(data)=>{
       let params = {
            email: data.email,
            firstName:data.first_name,
            lastName: data.last_name,
            socialId: data.id        ,
            signupType: "facebook",
            ipAddress : ipData?.IPv4,
            location : ipData?.country_name,
            browser : browserName,
            device : (isMobile)?'Mobile':'Desktop'
        }
        try{
            SocialLoginService(params)
          
        }
        catch(error){
            console.log(error);
        }
    }

    const onFailureFacebook=(data)=>{
        console.log(data);
    }

    //Social Login 
    const SocialLoginService = (params) => {
        socialLogin(params)
        .then(async(data)=>{
            if (data.status === 200) {
                setObjectInStore("token", data?.data?.data?.accessToken);
                setObjectInStore('refresh_token',data?.data?.data?.refreshToken);
                getLoginUserDetails();
            } else {
                showToastError(t(`apiMessages.${data.data.data}`))
            }
        })
        .catch((error) => {
            showToastError(t(`apiMessages.${error?.data?.data}`))
        });
    }

    return (<>
        <p className="lined-text before_tag-remove">{t('form.social_media_login')}</p>

        <div className="social-links">
            <Link className="social-link" to="/">   
                <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                    autoLoad={false}
                    fields="name,email,picture,first_name,last_name"
                    scope="public_profile, email"
                    callback={onSuccessFacebook}
                    onFailure={onFailureFacebook}
                    icon={<img src={FacebookImg} alt="" className="social-link facebook" />}
                    textButton=""
                    cssClass="social-media-buttons facebook"
                />
            </Link>

            {/* <Link className="social-link" to="/">
                <img src={TwitterImg} alt=""  className="social-link twitter"/>
            </Link> */}

            <Link className="social-link" to="/" onClick={(e)=>{e.preventDefault(); linkedInLogin()}}>
                <img src={LinkedInImg} alt=""  className="social-link youtube"/>
            </Link>

            <Link className="social-link" to="/" onClick={(e) => {e.preventDefault(); GoogleLoginHandler()}}>
                <img src={GoogleImg} alt=""  className="social-link twitch"/>
            </Link>
           
        </div>
    </>)
}

export default SocialLogins