import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import RoundCheckbox from "../../../commonComponents/roundCheckbox";
import EditUserInterests from "../../../commonComponents/editUserInterests";
const InterestSettings = ({ canEdit, onEdit, orgId, saveNewInterest, skipClick, backClick, formValues, setFormValues, saveOrgDetails, interests, additionalInterest }) => {
    const { t } = useTranslation();
    const [newInterest, setNewInterest] = useState([])

    const removeInterestToArray = (element) => {
        // Removing the specified element by value from the array 
        let arr = formValues.interests;
        let filter = arr.filter((item) => { return item !== element });
        setFormValues({ ...formValues, interests: filter })
    }

    const addInterestToArray = (element) => {
        let arr = formValues.interests;
        arr.push(element);
        setFormValues({ ...formValues, interests: arr })
    }

    const nextClick = (e) => {
        e.preventDefault();
        let params = {
            organizationId: orgId,
            "interest": formValues.interests,
            "spredeInterest": formValues.interests
        }
        saveOrgDetails(params)
    }

    const handleInterestChange = (e) => {
        let newInterestVal = [...newInterest];
        newInterestVal[e.target.name] = e.target.value.replace(/[^a-zA-Z ]/g, "");
        setNewInterest(newInterestVal)
    }
    const handleInterestKeyUp = (event, id, trimmedId) => {
        if (trimmedId !== '' && trimmedId !== undefined && event.which === 13) {
            saveNewInterest(id, trimmedId)
            setNewInterest([]);
        }
    }

    return (
        <>
            <div className="heading">
                <h1>{t('onboarding.interestSettings')}</h1>
                <h3>{t('onboarding.select_your_interest_based_on_below_topics')}</h3>
            </div>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className=""
            >
                <Row>
                    <EditUserInterests
                        interests={interests}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        saveOrgDetails={saveOrgDetails}
                        saveNewInterest={saveNewInterest}
                        additionalInterest={additionalInterest}
                    />
                </Row>
            </Box>

            <Row>
                <div className="back-next-btn-area contact-back-next-btn-area">
                    <Stack spacing={2} direction="row">
                        <button className="button green back-btn-new" onClick={backClick}> {t('back')} </button>
                        <button className="button secondary next-btn-new" onClick={nextClick}> {t('next')} </button>
                        {/* <button className="button back-btn-new skip-color" onClick={skipClick}> {t('skip')} </button> */}
                    </Stack>
                </div>
            </Row>
        </>
    )
}

export default InterestSettings;