import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './header.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../Reducer/user/user.selector';
import { getImageUrl } from '../../utils/helpers';
import { Logo } from '../../commonComponents/Images';
const Header = ({cmsData, headerClasses}) => {
    const {t} = useTranslation();
    const currentUser = useSelector(selectCurrentUser);
    const path_name=window.location.pathname?.toLowerCase();
   
    return (
        <Navbar expand="lg">
        <Container className={` ${headerClasses}`}>
            <Link to="/" className='navbar-brand'>
                <img className="headerImg" src={(cmsData?.headerLogo && cmsData?.headerLogo!=='')?getImageUrl(cmsData?._id, 'cms', cmsData?.headerLogo):Logo}/>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {path_name!=='/superlogin'  && <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                    <Link to="/" className="navbarLink" >{t('footer.home')}</Link>
                    {/* <Link to="/#panchTatva" className="navbarLink" >{t('footer.our_impact')}</Link> */}
                    <a href='/#panchTatva' className='navbarLink'>{t('footer.our_impact')}</a>
                    <Link to="/faq" className="navbarLink" >{t('faq')}</Link>
                    {currentUser === null ? <>
                        {/* <Link to="/login" className="navbarLink" >Login</Link>
                        <Link to="/register" className="navbarLink" >Register</Link> */}
                        </>: <Link to="/dashboard" className="navbarLink" >{t('Dashboard')}</Link>
                    }
                    
                </Nav>
            </Navbar.Collapse>}
        </Container>
        </Navbar>
    )
}

export default Header;