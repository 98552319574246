import instance, { instanceOrg } from "../AppInterceptor";
import ApiConfig from "../config/ApiConfig";
import { getObjectFromStore } from "../storage/Storage";

export const generateOrgAuthTokenService = (payload) => {
    return instance.post(ApiConfig.generateOrgToken, payload);
}

export const getOrgDetailsService = (orgId) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.orgDetails+'?organizationId='+orgId);
    }
    else{
        return instance.get(ApiConfig.orgDetails+'?organizationId='+orgId);
    }
}

export const getOrgDetailsByUserNameService = (userName) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.orgDetails+'?organizationUserName='+userName);
    }
    else{
        return instance.get(ApiConfig.orgDetails+'?organizationUserName='+userName);
    }
}

export const updateOrgDetailsService = (payload) => {
    return instanceOrg.put(ApiConfig.orgDetails, payload);
}

export const getOrgUsernameSuggestionsService = (orgId) => {    
    return instanceOrg.get(ApiConfig.orgUsernameSuggestions+'?organizationId='+orgId);
}

export const checkOrgUsernameAvailabilityService = (username, orgId) => {    
    return instanceOrg.get(ApiConfig.orgUsernameAvailability+'?organizationId='+orgId+'&username='+username);
}

export const getLegalServiceTypeService = () => {
    return instanceOrg.get(ApiConfig.getLegalServiceType);
}

export const saveNewLegalStructureService = (payload) => {
    return instanceOrg.post(ApiConfig.newLegalStructure, payload);
}

export const saveNewServiceTypeService = (payload) => {
    return instanceOrg.post(ApiConfig.newServiceType, payload);
}
 
export const OrgVerifyEmailMobileOtpService = (payload) => {
    return instanceOrg.post(ApiConfig.orgVerifyEmailMobileOtp, payload);
}

export const OrgCheckEmailMobileAvailabilityService = (payload) => {
    return instanceOrg.post(ApiConfig.orgEmailMobileAvailable, payload);
}

export const getOrgInterestsService = (orgId, isOnlyDefault) => {
    let url = ApiConfig.OrgInterests+'?organizationId='+orgId
    if(isOnlyDefault){
        url += '&onlyDefault='+isOnlyDefault
    }
    if(getObjectFromStore('role') === 'organization')
    {
        return instanceOrg.get(url)
    }
    else{
        return instance.get(url)
    }
}

export const saveNewOrgInterestsService = (payload) => {
    return instanceOrg.post(ApiConfig.OrgInterests , payload)
}

export const getOrgGeneralSettingService = (orgId) => {
    return instanceOrg.get(ApiConfig.OrgGeneralSettings+'?organizationId='+orgId)
}

export const updateOrgGeneralSettingService = (payload) => {
    return instanceOrg.post(ApiConfig.OrgGeneralSettings, payload)
}

export const getOrganzationListingService = (page, limit, searchText, searchByOrg = false, isOnlyIds) => {
    let url = `${ApiConfig.organizationList}?page=${page}&limit=${limit}&byOrganization=${searchByOrg}`;
    if(searchText){
        url += `&searchText=${searchText}`;
    }
    if(isOnlyIds)
    {
        url += `&isOnlyIds=${isOnlyIds}`;
    }

    return instanceOrg.get(url);
}