import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const AboutMe = ({ userDet,viewForMore,me,aboutMeClasses}) => {
  const { t } = useTranslation()
  //MMMM Do, YYYY
  return (
    <div className={`widget-box ${aboutMeClasses}`}>
      <div className="widget-box-settings">
        <div className="post-settings-wrap" style={{ position: 'relative' }}>  
           {viewForMore?<Link to="/my-profile">{t('button.view_more')}<span className='arrow-icon-forward'><ArrowForwardIosIcon sx={{ fontSize: '12px' }}  /></span></Link>:''}
        </div>
      </div>
      
      <p className="widget-box-title">{me?t('about_me'):t('about')}</p>
      <div className="widget-box-content">
       {userDet?.aboutMe &&<p className="paragraph">{viewForMore? `${userDet?.aboutMe?.substring(0,200)}...`:userDet?.aboutMe || ''}</p>}

        <div className="information-line-list">
          <div className="information-line">
            <p className="information-line-title">{t('joined')}</p>
            <p className="information-line-text">
             {userDet?.createdAt &&<Moment format="Do MMM YYYY">{userDet?.createdAt}</Moment>}
            </p>
          </div>
          {userDet?.currentAddress?.city &&
          <div className="information-line">
            <p className="information-line-title">{t('city')}</p>
            <p className="information-line-text">{userDet?.currentAddress?.city || '_'}</p>
          </div>
            }
         {userDet?.currentAddress?.country &&
          <div className="information-line">
            <p className="information-line-title">{t('country')}</p>
            <p className="information-line-text">{userDet?.currentAddress?.country || '_'}</p>
          </div>
          }
          {userDet?.dob &&
          <div className="information-line">
            <p className="information-line-title">{t('age')}</p>
            <p className="information-line-text">{userDet?.dob?<Moment fromNow ago>{userDet?.dob}</Moment>:"_"}</p>
          </div>
          }
        </div>
      </div>
    </div>
  )
}

export default AboutMe;