import ApiConfig from "../config/ApiConfig";
import instance,{instanceOrg} from "../AppInterceptor";
import { getObjectFromStore } from "../storage/Storage";

export const getAllImpacts=(id)=>{
    let finalUrl=ApiConfig.ImpactsAre
    if(id)finalUrl+='?spredeId='+id;
    if(getObjectFromStore('role') === 'organization'){
        return  instanceOrg.get(finalUrl);
    }
    else{
        return instance.get(finalUrl);
    }
 }

export const getOverAllImpactsService=()=>{
    return instance.get(ApiConfig.OverAllImpact);
}

export const getParticipationImpactService = () =>{
    if(getObjectFromStore('role') === 'organization'){
        return  instanceOrg.get(ApiConfig.participantImpact);
    }
    else{
        return instance.get(ApiConfig.participantImpact);
    }
    
}