import React from "react";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import FormTextArea from "../../../commonComponents/formTextArea";
import { TextField, Box } from "@mui/material";
import { allowOnlyLetters,getImageUrl } from "../../../utils/helpers";
const PanchTatvaCms = ({formValues, setFormValues, selectedFiles, setPhoto, removeImage}) => {
    const {t} = useTranslation();
    return (<>
        <SectionHeader pretitle="" title={t('homepage.panchtatva.title')}/>
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '98%' },
            }}
            noValidate
            autoComplete="off"
            className="userdetail-form mobile_profile_margin p-0 panchtatvaCmsComp"
            >
                <Row>
                    <Col lg={4} md={6}>
                        <Row>
                        
                            <div className="position-relative">
                            {((formValues?.panchTatva?.image && formValues?._id) || selectedFiles?.panchtatva!==null) &&
                                <span className="cross_cms_btn" onClick={()=>removeImage('panchtatva')} ><CloseIcon/></span>}
                                <label htmlFor="upload-photo-panchtatva">
                                    <input type="file" id="upload-photo-panchtatva"
                                    style={{display:"none"}}
                                    accept="image/png, image/gif, image/jpeg"
                                        onChange={(event) => {
                                            setPhoto(event.target.files[0], 'panchtatva');
                                        }}
                                        />
                                    <div className="content position-relative">
                                        <div className="upload-box cms_about_area">
                                        

                                        {((formValues?.panchTatva?.image && formValues?._id) || selectedFiles?.panchtatva!==null) ?
                                        <img src={selectedFiles?.panchtatva!==null ? URL.createObjectURL(selectedFiles?.panchtatva) : getImageUrl(formValues?._id, 'cms', formValues?.panchTatva?.image)} className="user_attach_upload" /> :
                                            <span className="up_load_btn">{t('errors.Upload')}</span> }
                                            <p className="upload-box-title">{t('Image')}</p>
                                            <p className="upload-box-text">{t('onboarding.size_minimum')}</p>
                                        </div>
                                        
                                    </div>
                                </label>  
                              
                            </div>
                            
                        </Row>

                        <Row className="grid">
                            <div className="grid-column myProfileSettings">
                                <div className="widget-box area-widget">
                                    <div className="widget-box-content">
                                        <Col md={12}>
                                            <TextField label={t('superAdmin.Title')}
                                            value={formValues?.panchTatva?.title}
                                            onChange={(e)=>setFormValues({...formValues, panchTatva : {
                                                ...formValues?.panchTatva,
                                                title : e.target.value
                                            }})}
                                            />
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </Row>              
                    </Col>
                    <Col lg={8} md={12}>
                        <Row className="grid">
                            <div className="grid-column myProfileSettings">
                                <div className="widget-box area-widget">
                                    <div className="widget-box-content">
                                        <Col md={12} className="cms_about_textarea">
                                            <FormTextArea label={t('description')}
                                                rows = {2}
                                                value={formValues?.panchTatva?.description}
                                                onChange={(e)=>( e.target.value.length < 200)?setFormValues({...formValues, panchTatva : {
                                                    ...formValues?.panchTatva,
                                                    description : e.target.value
                                                }}): ''}
                                                style={{minHeight : '100px'}}
                                            />

                                            <FormTextArea label={t('Sub_Description')}
                                                rows = {2}
                                                value={formValues?.panchTatva?.subDescription}
                                                onChange={(e)=>( e.target.value.length < 200)?setFormValues({...formValues, panchTatva : {
                                                    ...formValues?.panchTatva,
                                                    subDescription : e.target.value
                                                }}):''}
                                                style={{minHeight : '100px'}}
                                            />
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </Row>   
                    </Col>
                </Row>
        </Box>
    </>)
}
export default PanchTatvaCms;
