import React, { useState } from "react";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Row, Col,} from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import { runMark } from "../commonComponents/Images";
import { AvatarImg, FireImg } from "../commonComponents/Images";
import { useTranslation } from "react-i18next";
import { dateTimeEventFormat, getImageUrl } from "../utils/helpers";
import { changeDateFormatddmmyyyy, timeFormat } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import InvitePopup from "./InvitePopUp";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../Reducer/user/user.selector";
import moment from 'moment/moment';
import ReportPopup from "./ReportPopUp";
const RunningEvents = ({ myEventList, className='', actionBtn=false}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [popupOpen, setPopupOpen] = useState(false);
    const handlePopupOpen = () => setPopupOpen(true);
    const handlePopupClose = () => setPopupOpen(false);
    var startDateTime = new Date(myEventList?.startDate);
    var startHour, startMinutes;
    [startHour,startMinutes] = myEventList?.startTime.split(':');
    startDateTime.setHours(startHour);
    startDateTime.setMinutes(startMinutes);

    const getCurrentDate = () => {
        let d = new Date();
        d.setHours(0,0,0,0);
        return d;
    }
    let impactCategory = "";
    let impactCatImg ="";
    switch(myEventList.targetId.spredeInterestCategory?.spredeInterest){  
        case 'water':
            impactCategory = "Water";
            impactCatImg = "water";
            break;
        case 'earth':
            impactCategory = "Earth";
            impactCatImg = "Earth-icon";
            break;
        case 'air':
            impactCategory = "Air";
            impactCatImg = "air";
            break;
        case 'fire':
            impactCategory = "Fire";
            impactCatImg = "fire";
            break;
        case 'soul':
            impactCategory = "Soul";
            impactCatImg = "Soul - Copy";
            break;        
    }

    let eventUnitCompleted;
    if(myEventList.unitCompleted !=0){
        eventUnitCompleted = myEventList.unitCompleted
    }
    else if(myEventList.eventEvidence.length>0){
        if(myEventList.eventEvidence[0].verified === true){
            eventUnitCompleted = myEventList.eventEvidence[0].unit;
        }
        else if(myEventList.eventEvidence[0].completed === true){
            eventUnitCompleted = myEventList.eventEvidence[0].unit;
        }
        else{
            eventUnitCompleted = 0;
        }
    }
    else{
        eventUnitCompleted = 0;
    }
    
    return (

        <>
                <div className={`${className} all-event-box px-0 mb-0`}>
                        <Row className="align-items-center mx-0">
                            <div className="col-lg-2 col-12 text-center eventImgBox">
                                <img src={(myEventList?.photo !== '') ? getImageUrl(myEventList?._id, 'event', myEventList?.photo) : AvatarImg} alt="" className="img-fluid rounded" />
                            </div>
                            <div className="col-lg-2 col-12 py-2 d-flex align-items-start flex-column">
                                <div className="running-online hideBullet">
                                    <h5 className="bold">{myEventList.title}</h5>
                                    <p className="user-status-text">
                                        {changeDateFormatddmmyyyy(myEventList.startDate)} <span>{timeFormat(dateTimeEventFormat(myEventList?.startDate,myEventList.startTime))}</span>
                                        <span>{new Date() <= startDateTime ?
                                        <img className="dot" src="/Assets/gray.png" /> :
                                        (getCurrentDate() > new Date(myEventList?.endDate) || myEventList?.status == "verified") ?
                                            <img className="dot" src="/Assets/red.png" /> :
                                            <img className="dot" src="/Assets/green.png" />}
                                    </span>
                                    </p>
                                </div>
                                {
                                myEventList.createrDetail?.firstName?<div className="">
                                    <p className="mt-2">Invited By: {myEventList?.createrDetail?.firstName} {myEventList?.createrDetail?.lastName}</p>
                                </div>:""
                                }
                                {
                                    myEventList?.createrDetail?.name?<div className="">
                                    <p className="mt-2">Invited By: {myEventList?.createrDetail?.name}</p>
                                    </div>:""
                                }
                                
                            </div>
                            <div className="col-lg-2 mt-2">
                                <p>Category: <img src={"/Assets/"+impactCatImg+".png"} className="" style={{ height: "24px", marginLeft: "5px" }} />
                                    {<span className="action-request-text text-capitalize"> {myEventList.targetId.spredeInterestCategory.spredeInterest}</span>}    
                                </p>
                                <p className="mt-3">Sub Category:</p>
                                <p>{myEventList?.category}</p>
                            </div>
                            {/* <div className="col-lg-3 mt-2">
                                
                            </div>                                     */}
                            {/* <div className="col-lg-1 col-12 pb-2 ps-lg-0 location">
                                
                            </div> */}
                            <div className="col-lg-2 mt-2">
                                <p>Target: {myEventList?.unit}</p>
                                <p>Completed: {eventUnitCompleted}</p>
                            </div>

                            <div className="col-lg-1 col-12 pb-2 location">
                                <p className="user-status-text small-space locat_icons">
                                    <LocationOnOutlinedIcon />
                                    <Tooltip title={myEventList.location} placement="bottom-start">        
                                        <span className="action-request-text">{myEventList.location}</span>
                                    </Tooltip>
                                    
                                </p>
                            </div>

                            <div className="col-lg-3 col-12 pb-2 col-offset-sm-6">
                                <div className="action-request-list justify-content-end d-lg-block text-lg-end">
                                    {!actionBtn ? <>
                                        {myEventList.status == "verified" ?
                                            <button className=" report-btn report-btn-color" onClick={(e) => { e.preventDefault(); handlePopupOpen() }}>{t('report')}</button>
                                            : null
                                        }
                                        <button className="button primary view-event-btn evidence-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate('/validate-event', { state: { eventId: myEventList?._id, creater: true } })
                                            }}
                                        >{t('completion_evidence')} </button>
                                        {/* <span>2</span> */}
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate('/view-event', { state: { eventId: myEventList?._id } })
                                            }}
                                            className="button primary view-event-btn">{t('button.view_event')}
                                        </button></>
                                        :
                                        actionBtn
                                    }
                                </div>
                            </div>
                            
                            
                        </Row>
                </div>
            <ReportPopup
                popupOpen={popupOpen}
                handleClose={handlePopupClose}
                eventId={myEventList?._id}
            />
        </>
    );

}

export default RunningEvents