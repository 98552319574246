import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CmsSidebar from "./cmsSidebar";
import { Row, Col } from "react-bootstrap";
import LogoCms from "./logoCms";
import BannerCms from "./bannerCms";
import PanchTatvaCms from "./panchtatvaCms";
import AboutCms from "./aboutCms";
import HowItWorksCms from "./howItWorks";
import FeatureCms from "./featureCms";
import BlogCms from "./blogCms";
import TestimonialCms from "./testimonialCms";
import PartnerCms from "./partnerCms";
import FooterCms from "./footerCms";
import {
    getLanguageListService,
    getCmsService, postCmsService,
    getBlogsService, addBlogsService, 
    getTestimonialsService, addTestimonialsService, getFaqService, addFaqService
} from "../../../services/cms.services";
import { Autocomplete, TextField } from "@mui/material";
import swal from 'sweetalert';
import { showSingleToastSuccess } from "../../../utils/helpers"
import { uploadFileService } from "../../../services/fileUpload.services";
import { LanguageList } from "../../../config/constants";
import TermsConditionsCms from "./termsConditionCms";
import PrivacyPolicyCms from "./privacyPolicyCms";
import FAQCms from "./faqCms";
import PaymentPolicyCms from "./paymentPolicyCms";
const CMS = () => {
    const { t } = useTranslation()
    const LogoRef = useRef(null)
    const BannerRef = useRef(null)
    const PanchtatvaRef = useRef(null)
    const AboutRef = useRef(null)
    const HowItWorksRef = useRef(null)
    const FeatureRef = useRef(null)
    const PartnerRef = useRef(null)
    const FooterRef = useRef(null)
    const TermCondition = useRef(null)
    const PrivacyPolicy = useRef(null)
    const PaymentPolicy = useRef(null)

    const [isLoading, setLoading] = useState(false);


    const [languages, setLanguages] = useState(LanguageList)
    const [language, setLanguage] = useState(null)
    const [showBlogs, setShowBlogs] = useState(false)
    const [showTestimonials, setShowTestimonials] = useState(false)
    const [showFaq, setShowFaq] = useState(false);
    const [error, setError] = useState(false);

    const limit = 20;

    //For All Events 
    const [blogsLoader, setBlogsLoader] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const [totalBlogPage, setTotalBlogPage] = useState(0);
    const [currentBlogPage, setCurrentBlogPage] = useState(0);

    //For running Events
    const [testimonialLoader, setTestimonialLoader] = useState(false);
    const [faqLoader, setFaqLoader] = useState(false);
    const [testimonials, setTestimonials] = useState([]);
    const [faqs, setFaqs] = useState([]);
    const [totalTestimonialPage, setTotalTestimonialage] = useState(0);
    const [totalFaqPage, setTotalFaqPage] = useState(0);
    const [currentTestimonialPage, setCurrentTestimonialPage] = useState(0);
    const [currentFaqPage, setCurrentFaqPage] = useState(0);


    const INITIAL_SELECTED_FILES = {
        headerLogo: null,
        footerLogo: null,
        feviconLogo: null,
        aboutUs: null,
        aboutUsVideo:null,
        panchtatva : null,
        partners: []
    }

    const [selectedFiles, setSelectedFiles] = useState(INITIAL_SELECTED_FILES)
    const [bannerFiles, setSelectedBannerFiles] = useState([{ selected: null, banner: '' }])
    const [partnerFiles, setSelectedPartnerFiles] = useState([{ selected: null, partner: '' }])
    const [featuresFiles, setSelectedFeaturesFiles] = useState([{ selected: null, image: '', Title: '', Text: '' }])
    const [howItWorksFiles, setSelectedHowItWorksFiles] = useState([{ selected: null, image: '', Title: '', Text: '' }])

    const [active, setActive] = useState(1);

    const onDiscard = () => {
        swal({
            text: t('alerts.discard_changes'),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
            .then(async (val) => {
                if (!val) return;
                getCmsData()
            });
    }

    const INITAL_CMS_DATA =
    {
        "headerLogo": "",
        "footerLogo": "",
        "feviconLogo": "",
        "banners": [],
        "aboutUs": {
            "aboutUsImage": '',
            "aboutUsTitle": '',
            "aboutUsText": ''
        },
        "panchTatva" : {
            "image" : "",
            "title" : "",
            "description": "",
            "subDescription" : "" 
        },
        "howItWorks": [],
        "features": [],
        "partners": [],
        "socialFacebook": '',
        "socialTwitter": '',
        "socialLinkedin": '',
        "socialGoogle": '',
        "contactUs": {
            "phoneNo": '',
            "Email": '',
            "Address": ''
        }
    }

    const [formValues, setFormValues] = useState(INITAL_CMS_DATA);

    const scrollToError = () => {
        const element = document.getElementsByClassName('error-text');
        if (element[0]) {
             // 👇 Will scroll smoothly to the top of the next section
            element[0].scrollIntoView({ behavior: 'smooth' });
        }
    }

    const onSave = async (e) => {
        e.preventDefault();
        setError(false);
        if (formValues.socialFacebook === '' || formValues.socialTwitter === '' || formValues.socialLinkedin === '' || formValues.socialGoogle === '' ||
            formValues.headerLogo === ''
        ) {
            setError(true); scrollToError(); return;
        }
        saveCmsData()
    }

    useEffect(() => {
        if (language !== null) {
            setFormValues({
                ...formValues,
            })
        }
    }, [language])

    // useEffect(() => {
    //     getLanguageList();
    // }, []);    
 
    //BLOGS AND TESTIMONIALS USE EFFECT
    useEffect(() => {
        if (language !== null && !showBlogs && !showTestimonials && !showFaq) { getCmsData(); }
    }, [language, showBlogs, showTestimonials, showFaq]);

    useEffect(() => {
        if (showBlogs) {
            setBlogs([]);
        }
        if (showTestimonials) {
            setTestimonials([])
        }
        if(showFaq){
            setFaqs([]);
        }
    }, [language])

    useEffect(() => {
        if (showBlogs) {
            getBlogs();
        }
    }, [showBlogs, language, currentBlogPage])

    useEffect(() => {
        if (showTestimonials) {
            getTestimonials();
        }
    }, [showTestimonials, language, currentTestimonialPage])

    useEffect(() => {
        if (showFaq) {
            getFaqs();
        }
    }, [showFaq, language, currentFaqPage])

    const handleBlogsPageClick = (data) => {
        let Page = data.selected
        setCurrentBlogPage(Page)
    }

    const handleTestimonialsPageClick = (data) => {
        let Page = data.selected
        setCurrentTestimonialPage(Page)
    }

    const handleFaqsPageClick = (data) => {
        let Page = data.selected
        setCurrentFaqPage(Page)
    }
    //************************************************ */

    // const getLanguageList = () => {
    //     getLanguageListService()
    //         .then((data) => {
    //             if (data?.data?.status === 200) {
    //                 setLanguages(data?.data?.data)
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    // }

    const getCmsData = async () => {
        getCmsService(language?.value)
            .then((data) => {
                if (data?.data?.status === 200) {
                    if (data?.data?.data !== null) {
                        setFormValues({
                            ...formValues, ...data?.data?.data,
                            langId: language?._id,
                            langCode: language?.value
                        });
                        let banners = [];
                        if (data?.data?.data?.banners?.length > 0) {
                            data?.data?.data?.banners?.map((item) => {
                                banners.push({ selected: null, banner: item })
                            })
                        }
                        else {
                            banners.push({ selected: null, banner: '' })
                        }
                        let partners = [];
                        if (data?.data?.data?.partners?.length > 0) {
                            data?.data?.data?.partners?.map((item) => {
                                partners.push({ selected: null, partner: item })
                            })
                        }
                        else {
                            partners.push({ selected: null, partner: '' })
                        }

                        let features = [];
                        if (data?.data?.data?.features?.length > 0) {
                            data?.data?.data?.features?.map((item) => {
                                features.push({ selected: null, image: item?.image, Title: item?.Title, Text: item?.Text })
                            })
                        }
                        else {
                            features.push({ selected: null, image: '', Title: '', Text: '' })
                        }

                        let howItWorks = [];
                        if (data?.data?.data?.howItWorks?.length > 0) {
                            data?.data?.data?.howItWorks?.map((item) => {
                                howItWorks.push({ selected: null, image: item?.image, Title: item?.Title, Text: item?.Text })
                            })
                        }
                        else {
                            howItWorks.push({ selected: null, image: '', Title: '', Text: '' })
                        }

                        setSelectedHowItWorksFiles(howItWorks);
                        setSelectedFeaturesFiles(features);
                        setSelectedPartnerFiles(partners);
                        setSelectedBannerFiles(banners);
                    }
                    else {
                        setFormValues({
                            ...formValues,
                            langId: language?._id,
                            langCode: language?.value
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getBlogs = () => {
        setBlogsLoader(true)
        getBlogsService(currentBlogPage, limit, language?.value)
            .then((response) => {
                if (response.data.status === 200) {
                    //setBlogs(response?.data?.data)
                    setBlogs(response?.data?.data?.res)
                    let pageCount = (response?.data?.data?.totalCount) / limit;
                    setTotalBlogPage(Math.ceil(pageCount));
                }
                setBlogsLoader(false)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getTestimonials = () => {
        setTestimonialLoader(true)
        getTestimonialsService(currentTestimonialPage, limit, language?.value)
            .then((response) => {
                if (response.data.status === 200) {
                    setTestimonials(response?.data?.data?.res)
                    let pageCount = (response?.data?.data?.totalCount) / limit;
                    setTotalTestimonialage(Math.ceil(pageCount));
                }
                setTestimonialLoader(false)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getFaqs = () => {
        setFaqLoader(true);
        getFaqService(language?.value)
            .then((response) => {
                if (response?.data?.status === 200) {
                    setFaqs(response?.data?.data)
                    // let pageCount = (response?.data?.data?.totalCount) / limit;
                    // setTotalFaqPage(Math.ceil(pageCount));
                }
                setFaqLoader(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const saveCmsData = async () => {
        setLoading(true);
        let params = { ...formValues }
        //check images and upload      
        let response = await uploadFiles();
        params = { ...params, ...response };

        try {
            postCmsService(params)
                .then((data) => {
                    if (data?.data?.status === 200) {
                        showSingleToastSuccess(t('apiMessages.CMS_DATA_SAVED'))
                        setSelectedFiles(INITIAL_SELECTED_FILES)
                        getCmsData();
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const uploadFiles = async () => {
        let params = {};
        let partners = [];
        let banners = [];
        let features = [];
        let howItWorks = [];
        if (selectedFiles?.headerLogo !== null) {
            await uploadFileService('cms', 'cms', selectedFiles?.headerLogo?.lastModified, selectedFiles?.headerLogo, formValues?._id)
            params['headerLogo'] = selectedFiles?.headerLogo?.lastModified
        }

        if (selectedFiles?.footerLogo !== null) {
            await uploadFileService('cms', 'cms', selectedFiles?.footerLogo?.lastModified, selectedFiles?.footerLogo, formValues?._id)
            params['footerLogo'] = selectedFiles?.footerLogo?.lastModified
        }

        if (selectedFiles?.feviconLogo !== null) {
            await uploadFileService('cms', 'cms', selectedFiles?.feviconLogo?.lastModified, selectedFiles?.feviconLogo, formValues?._id)
            params['feviconLogo'] = selectedFiles?.feviconLogo?.lastModified
        }

        if (selectedFiles?.aboutUs !== null) {
            await uploadFileService('cms', 'cms', selectedFiles?.aboutUs?.lastModified, selectedFiles?.aboutUs, formValues?._id)
            params['aboutUs'] = { ...formValues.aboutUs, aboutUsImage: selectedFiles?.aboutUs?.lastModified }
        }
        if (selectedFiles?.aboutUsVideo !== null) {
            await uploadFileService('cms', 'cms', selectedFiles?.aboutUsVideo?.lastModified, selectedFiles?.aboutUsVideo, formValues?._id)
            params['aboutUsVideo'] = {video: selectedFiles?.aboutUsVideo?.lastModified }
        }

        if (selectedFiles?.panchtatva !== null) {
            await uploadFileService('cms', 'cms', selectedFiles?.panchtatva?.lastModified, selectedFiles?.panchtatva, formValues?._id)
            params['panchTatva'] = { ...formValues.panchTatva, image: selectedFiles?.panchtatva?.lastModified }
        }

        if (partnerFiles?.length > 0) {
            await Promise.all(partnerFiles?.map(async (item) => {
                if (item?.selected !== null) {
                    await uploadFileService('cms', 'cms', item?.selected?.lastModified, item?.selected, formValues?._id)
                    partners.push(item?.selected?.lastModified)
                }
                else if (item?.partner !== '') {
                    partners.push(item?.partner)
                }
            }))
            params['partners'] = partners;
        }
        if (bannerFiles?.length > 0) {
            await Promise.all(bannerFiles?.map(async (item) => {
                if (item?.selected !== null) {
                    await uploadFileService('cms', 'cms', item?.selected?.lastModified, item?.selected, formValues?._id)
                    banners.push(item?.selected?.lastModified)
                }
                else if (item?.banner !== '') {
                    banners.push(item?.banner)
                }
            }))
            params['banners'] = banners;
        }

        if (featuresFiles?.length > 0) {
            await Promise.all(featuresFiles?.map(async (item) => {
                if (item?.selected !== null) {
                    await uploadFileService('cms', 'cms', item?.selected?.lastModified, item?.selected, formValues?._id)
                    features.push({ ...item, image: item?.selected?.lastModified })
                }
                else if (item?.image !== '') {
                    features.push({ ...item, image: item?.image })
                }
                else {
                    features.push({ ...item })
                }
            }))
            params['features'] = features;
        }

        if (howItWorksFiles?.length > 0) {
            await Promise.all(howItWorksFiles?.map(async (item) => {
                if (item?.selected !== null) {
                    await uploadFileService('cms', 'cms', item?.selected?.lastModified, item?.selected, formValues?._id)
                    howItWorks.push({ ...item, image: item?.selected?.lastModified })
                }
                else if (item?.image !== '') {
                    howItWorks.push({ ...item, image: item?.image })
                }
                else {
                    howItWorks.push({ ...item })
                }
            }))
            params['howItWorks'] = howItWorks;
        }


        return params;
    }


    const executeScroll = (e, tab) => {
        e.preventDefault();
        setActive(tab)
        if (tab === 1 || tab === 2 || tab === 3 || tab === 4 || tab === 5 || tab === 6 || tab === 9 || tab === 10) {
            setShowBlogs(false); 
            setShowTestimonials(false);
            setShowFaq(false);
        }
        if (tab === 1) { if (!LogoRef.current) return; LogoRef?.current.scrollIntoView() }
        else if (tab === 2) {if (!BannerRef.current) return; BannerRef?.current.scrollIntoView({block: "center"}) }
        else if (tab === 3) {if (!PanchtatvaRef.current) return; PanchtatvaRef?.current.scrollIntoView({block: "center"}) }
        else if (tab === 4) {if (!AboutRef.current) return; AboutRef?.current.scrollIntoView({block: "center"}) }
        else if (tab === 5) {if (!HowItWorksRef.current) return; HowItWorksRef?.current.scrollIntoView() }
        else if (tab === 6) {if (!FeatureRef.current) return; FeatureRef?.current.scrollIntoView({block: "center"}) }
        else if (tab === 7) {
            setShowBlogs(true); 
            setShowTestimonials(false)
            setShowFaq(false);    
        }
        else if (tab === 8) {
            setShowBlogs(false); 
            setShowTestimonials(true);
            setShowFaq(false);
        }
        else if (tab === 9) {if (!TermCondition.current) return; TermCondition?.current.scrollIntoView({block: "center"})  }
        else if (tab === 10) {if (!PrivacyPolicy.current) return; PrivacyPolicy?.current.scrollIntoView({block: "center"}) }
        else if (tab === 11) {if (!PaymentPolicy.current) return; PaymentPolicy?.current.scrollIntoView({block: "center"}) }
        else if (tab === 12) {
            setShowBlogs(false);
            setShowTestimonials(false);
            setShowFaq(true);
        }
        else if (tab === 13) {if (!PartnerRef.current) return; PartnerRef?.current.scrollIntoView({block: "center"}) }
        else if (tab === 14) {if (!FooterRef.current) return; FooterRef?.current.scrollIntoView({block: "center"}) }
    }

    const setPhoto = (param, type, i) => {
        if (type === 'banner') {
            if (param !== undefined) {
                let banners = [...bannerFiles];
                banners[i] = { selected: param, banner: '' }
                setSelectedBannerFiles(banners);
            }
            else {
                let banners = [...bannerFiles];
                banners[i] = { selected: null, banner: '' }
                setSelectedBannerFiles(banners);
            }
        }
        else if (type === 'partner') {
            if (param !== undefined) {
                let partners = [...partnerFiles];
                partners[i] = { selected: param, partner: '' }
                setSelectedPartnerFiles(partners);
            }
            else {
                let partners = [...partnerFiles];
                partners[i] = { selected: null, partner: '' }
                setSelectedPartnerFiles(partners);
            }
        }
        else if (type === 'feature') {
            if (param !== undefined) {
                let features = [...featuresFiles];
                features[i]['selected'] = param;
                features[i]['image'] = '';
                setSelectedFeaturesFiles(features);
            }
            else {
                let features = [...featuresFiles];
                features[i]['selected'] = null;
                features[i]['image'] = '';
                setSelectedFeaturesFiles(features);
            }
        }
        else if (type === 'howItWorks') {
            if (param !== undefined) {
                let howItWorks = [...howItWorksFiles];
                howItWorks[i]['selected'] = param;
                howItWorks[i]['image'] = '';
                setSelectedHowItWorksFiles(howItWorks);
            }
            else {
                let howItWorks = [...howItWorksFiles];
                howItWorks[i]['selected'] = null;
                howItWorks[i]['image'] = '';
                setSelectedHowItWorksFiles(howItWorks);
            }
        }
        else {
            if (param !== undefined) { setSelectedFiles({ ...selectedFiles, [type]: param }) }
            else { setSelectedFiles({ ...selectedFiles, [type]: null }) }
        }
    }

    const removeInstance = (e, type, i) => {
        e.preventDefault()
        if (type === 'banner') {
            let filter = bannerFiles.filter((item, index) => index !== i);
            setSelectedBannerFiles(filter);
        }
        else if (type === 'partner') {
            let filter = partnerFiles.filter((item, index) => index !== i);
            setSelectedPartnerFiles(filter);
        }
        else if (type === 'feature') {
            let filter = featuresFiles.filter((item, index) => index !== i);
            setSelectedFeaturesFiles(filter);
        }
        else if (type === 'howItWorks') {
            let filter = howItWorksFiles.filter((item, index) => index !== i);
            setSelectedHowItWorksFiles(filter);
        }
    }

    const addInstance = (e, type) => {
        e.preventDefault()
        if (type === 'banner') {
            setSelectedBannerFiles([...bannerFiles, { selected: null, banner: '' }]);
        }
        else if (type === 'partner') {
            setSelectedPartnerFiles([...partnerFiles, { selected: null, partner: '' }]);
        }
        else if (type === 'feature') {
            setSelectedFeaturesFiles
                ([...featuresFiles, { selected: null, image: '', Title: '', Text: '' }]);
        }
        else if (type === 'howItWorks') {
            setSelectedHowItWorksFiles
                ([...howItWorksFiles, { selected: null, image: '', Title: '', Text: '' }]);
        }
    }

    const removeImage = (type, i) => {
        if (type === 'banner') {
            let banners = [...bannerFiles];
            banners[i] = { selected: null, banner: '' }
            setSelectedBannerFiles(banners);
        }
        else if (type === 'partner') {
            let partners = [...partnerFiles];
            partners[i] = { selected: null, partner: '' }
            setSelectedPartnerFiles(partners);
        }
        else if (type === 'aboutUs') {
            setSelectedFiles({ ...selectedFiles, [type]: null })
            setFormValues({ ...formValues, aboutUs: { ...formValues?.aboutUs, aboutUsImage: '' } })
        }else if (type === 'aboutUsVideo') {
            setSelectedFiles({ ...selectedFiles, [type]: null })
            setFormValues({ ...formValues, aboutUs: { ...formValues?.aboutUs, aboutUsVideo: '' } })
        } else {
            setSelectedFiles({ ...selectedFiles, [type]: null })
            setFormValues({ ...formValues, [type]: '' })
        }
    }

    const handleFeatureChange = (value, i, type, sec) => {
        if (sec === 'howItWorks') {
            let howItWorks = [...howItWorksFiles];
            howItWorks[i][type] = value;
            setSelectedHowItWorksFiles(howItWorks);
        }
        else {
            let features = [...featuresFiles];
            features[i][type] = value;
            setSelectedFeaturesFiles(features);
        }

    }

    const addBlogTestimonial = (params, type) => {
        if (type === 'blog') {

            let param = {
                image: params?.image?.lastModified,
                title: params?.title,
                description: params?.description,
                langCode: language?.value
            }

            addBlogsService(param)
                .then(async (data) => {
                    if (data?.data?.status === 200) {
                        //upload blog file
                        await uploadFileService('cms', 'cms', params?.image?.lastModified, params?.image, data?.data?.data)
                        showSingleToastSuccess(t(`apiMessages.BLOG_CREATED_SUCCESSFULLY`));
                        getBlogs()
                    }
                })
            return true;
        }
        else {
            let param = {
                name: params?.name,
                company: params?.company,
                rating: params?.rating,
                content: params?.content,
                image: params?.image?.lastModified,
                langCode: language?.value
            }

            addTestimonialsService(param)
                .then(async (data) => {
                    if (data?.data?.status === 200) {
                        //upload blog file
                        await uploadFileService('cms', 'cms', params?.image?.lastModified, params?.image, data?.data?.data)
                        showSingleToastSuccess(t(`apiMessages.TESTIMONIAL_CREATED_SUCCESSFULLY`));
                        getTestimonials()
                    }
                })
            return true;
        }
    }

    const addFaqs = (params) => {
        let param = {
            categoryId: params?.category_id,
            question: params?.ques,
            answer: params?.answer,
            langCode: language?.value
        }

        addFaqService(param)
                .then(async (data) => {
                    if (data?.data?.status === 200) {
                        showSingleToastSuccess(t(`apiMessages.FAQ_ADDED_SUCCESSFULLY`));
                        getFaqs();
                    }
                })
            return true;
    }

    const handleScroll = () => {
        window.scrollTo({
          top:120,
          left: 0,
          behavior: "smooth",
        });
      };
      

    return (
        <Row className="grid grid-3-6-3 mobile-prefer-content">
            <Col md={3} className="grid-column">
                <CmsSidebar active={active} setActive={executeScroll} onSave={onSave} onDiscard={onDiscard} 
                    isLoading={isLoading} formValues={formValues} LogoRef={LogoRef} BannerRef={BannerRef}
                    PanchtatvaRef={PanchtatvaRef} AboutRef={AboutRef} HowItWorksRef={HowItWorksRef}
                    FeatureRef={FeatureRef} TermRef={TermCondition} PartnerRef={PartnerRef} handleScroll={handleScroll} />
            </Col>

            <Col md={9} className="cmsMainSec grid-column cms_label_adjust">

                <Autocomplete
                    disablePortal
                    value={language}
                    options={languages}
                    getOptionLabel={(option) => option?.name?.props?.children || ""}

                    onChange={(e, val) => { setLanguage(val); }}
                    renderInput={(params) => <TextField {...params} label={t('language')} />}
                />
                {language !== null && !showBlogs && !showTestimonials && !showFaq && <>
                    <div ref={LogoRef}>
                        <LogoCms formValues={formValues}
                            setFormValues={setFormValues}
                            setPhoto={setPhoto}
                            selectedFiles={selectedFiles}
                            removeImage={removeImage}
                            error={error}
                        />
                    </div>
                    <div ref={BannerRef}>
                        <BannerCms formValues={formValues}
                            setFormValues={setFormValues}
                            bannerFiles={bannerFiles}
                            setPhoto={setPhoto}
                            removeImage={removeImage}
                            addInstance={addInstance}
                            removeInstance={removeInstance}
                        />
                    </div>
                    <div ref={PanchtatvaRef}>
                        <PanchTatvaCms 
                            formValues={formValues}
                            setFormValues={setFormValues}
                            setPhoto={setPhoto}
                            selectedFiles={selectedFiles}
                            removeImage={removeImage}
                        />
                    </div>
                    <div ref={AboutRef}>
                        <AboutCms formValues={formValues}
                            setFormValues={setFormValues}
                            setPhoto={setPhoto}
                            selectedFiles={selectedFiles}
                            removeImage={removeImage}
                        />
                    </div>
                    <div ref={HowItWorksRef}>
                        <HowItWorksCms formValues={formValues}
                            setFormValues={setFormValues}
                            howItWorksFiles={howItWorksFiles}
                            setPhoto={setPhoto}
                            removeImage={removeImage}
                            addInstance={addInstance}
                            removeInstance={removeInstance}
                            handleFeatureChange={handleFeatureChange}
                        />
                    </div>
                    <div ref={FeatureRef}>
                        <FeatureCms formValues={formValues}
                            setFormValues={setFormValues}
                            featuresFiles={featuresFiles}
                            setPhoto={setPhoto}
                            removeImage={removeImage}
                            addInstance={addInstance}
                            removeInstance={removeInstance}
                            handleFeatureChange={handleFeatureChange}
                        />
                    </div>
                    <div ref={TermCondition}>
                        <TermsConditionsCms
                            formValues={formValues}
                            setFormValues={setFormValues}
                        />
                    </div>
                    <div ref={PrivacyPolicy}>
                        <PrivacyPolicyCms
                            formValues={formValues}
                            setFormValues={setFormValues}
                        />
                    </div>
                    <div ref={PaymentPolicy}>
                        <PaymentPolicyCms
                            formValues={formValues}
                            setFormValues={setFormValues}
                        />
                    </div>
                    <div ref={PartnerRef}>
                        <PartnerCms formValues={formValues}
                            setFormValues={setFormValues}
                            partnerFiles={partnerFiles}
                            setPhoto={setPhoto}
                            removeImage={removeImage}
                            addInstance={addInstance}
                            removeInstance={removeInstance}
                        />
                    </div>
                    <div ref={FooterRef}>
                        <FooterCms formValues={formValues}
                            setFormValues={setFormValues}
                            error={error}
                        />
                    </div>
                </>}
                {language !== null && showBlogs &&
                    <div>
                        <BlogCms
                            list={blogs}
                            handlePageClick={handleBlogsPageClick}
                            totalPage={totalBlogPage}
                            isLoading={blogsLoader}
                            addBlogTestimonial={addBlogTestimonial}
                        />
                    </div>
                }
                {language !== null && showTestimonials &&
                    <div>
                        <TestimonialCms
                            list={testimonials}
                            handlePageClick={handleTestimonialsPageClick}
                            totalPage={totalTestimonialPage}
                            isLoading={testimonialLoader}
                            addBlogTestimonial={addBlogTestimonial}
                        />
                    </div>
                }
                {language !== null && showFaq &&
                    <div>
                        <FAQCms
                            list={faqs}
                            handlePageClick={handleFaqsPageClick}
                            totalPage={totalFaqPage}
                            isLoading={faqLoader}
                            addFaqs={addFaqs}
                            getFaqs={getFaqs}
                            language={language?.value}
                        />
                    </div>
                }
            </Col>
        </Row>
    )
}

export default CMS;