import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import BarsLoader from "../../../commonComponents/barsLoader";
import TargetDescriptionBox from "../../../commonDashboardComp/targetDescriptionBox";
import { getTargetAllList } from "../../../services/onBoarding.services";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentOrg, selectCurrentUser, selectCurrentUserRole } from "../../../Reducer/user/user.selector";
const AllTargets = ({getAllCount, filter}) => {

    const role = useSelector(selectCurrentUserRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);

    let current_user = {};
    if(role === 'organization'){   current_user = CURRENTORG     }
    else{ current_user = CURRENTUSER }

    const limit = 20;
    const {t} = useTranslation();
    const [targetData, setTargetData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [hasMore, SetHasMore] = useState(true);
    const [offset , setOffset] = useState(0);

    useEffect(() => {
        setOffset(0)
        setTargetData([])
        SetHasMore(true);
    }, [role, filter])

    // useEffect(()=>{
    //     setCount(targetData.length)
    // },[targetData])

    const getTargets = async () => {
        setLoading(true);
        let page = offset/limit;
        let newOffset = offset+limit;
        getTargetAllList(page, limit, 'allTarget',filter)
            .then((response) => {
                if (response?.data?.status === 200) {
                    if(response?.data?.data?.data?.length === 0 || response?.data?.data?.data?.length < limit){
                        SetHasMore(false);
                    }
                    const _data = response?.data?.data?.data;
                    setTargetData([...targetData, ..._data]);
                }                
                setLoading(false)
                setOffset(newOffset);
            })
            .catch((error) => {
                console.log(error, "error");
            })

    }
    
    return (
        <>
            <InfiniteScroll
                pageStart={0}
                loadMore={getTargets}
                hasMore={hasMore}
                loader={<BarsLoader key={0} />}
                useWindow={false}
            >
                <div className="target_page_main">
                        <div className="row">
                            {targetData.length > 0 && <>
                                {targetData.map((e, i) => (
                                    <div key={i} className="allTargetColumn col-lg-12 col-sm-6 mt-2">
                                        <TargetDescriptionBox
                                            targetData={e}
                                            canChangeDetail={(current_user?._id !== e?.spredeId)?false:true}
                                            getAllCount={getAllCount}
                                        />
                                    </div>
                                ))}
                            </>}
                        </div>
                </div>

            </InfiniteScroll>
            
            {!isLoading && targetData.length === 0 &&
                <>
                    <h4 className="nopeMsg mt-4">{t('no_targets')}</h4>

                </>}
        </>
    )
}

export default AllTargets;