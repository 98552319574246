import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import swal from 'sweetalert';
import { measureUnits } from '../config/constants';

const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };
export const AlertConfirmation = (text) => {
  swal({
    text: text,
    title: '',
    icon: "info",
    buttons: {
      Yes: {
        text: "Yes",
        value: true,
      },
      No: {
        text: "No",
        value: false,
      },
    },
  })
    .then(async (val) => {
      return val
    })
}

export const getWeekDay = (weekDay) => {
  if(weekDay === '0'){ return 'Sunday' }
  else if(weekDay === '1'){ return 'Monday' }
  else if(weekDay === '2'){ return 'Tuesday' }
  else if(weekDay === '3'){ return 'Wednesday' }
  else if(weekDay === '4'){ return 'Thursday' }
  else if(weekDay === '5'){ return 'Friday' }
  else if(weekDay === '6'){ return 'Saturday' }
}

export const showToastSuccess = (msg) => {
  return toast.success(msg);
}
export const showSingleToastSuccess = (msg) => {
  toast.dismiss()
  return toast.success(msg);
}

export const showSingleToastSucces2 = (msg) => {
  return toast(msg, { toastId: 1 });
}

export const showToastWarning = (msg) => {
  return toast.warning(msg);
}

export const showToastError = (msg) => {
  toast.dismiss()
  return toast.error(msg);
}

export const allowOnlyLetters = (text) => {
  return text.replace(/[^a-zA-Z ]/g, "");
}

export const allowOnlyLettersNumbers = (text) => {
  return text.replace(/[^a-zA-Z0-9 ]/g, "");
}

export const restrictForwardAndBackwardSlash=(text)=>{
  return text.replace(/[/\\]/g,"");
}

export const restrictSpecialCharacter = (text) => {
   return text.replace(/[^a-zA-Z0-9@ ]/g, '').replace(/@{2,}/g, '@').replace(/ {2,}/g, ''); 
}

// export const allowOnlyNumbers = (text) => {
//   return text.replace(/^[0-9\b]+$/g, "");
// }

export const getTimeGap = (date) => {
  return moment.utc(date).local().startOf('seconds').fromNow()
}

export const numberKFormatter = (num) => {
  return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}

export const getImageUrl = (userId, type, fileName) => {
  const uploadedUrl = 'https://webapp-cnd86568587ojklj.azureedge.net/';
  let url = `${uploadedUrl}`
  if (type === fileUploadService.fileUrlType.userProfile) {
    url = url + `${containerCategory.user}/${userId}/${blobPathType.profile}/${fileName}`
  } else if (type === fileUploadService.fileUrlType.userCover) {
    url = url + `${containerCategory.user}/${userId}/${blobPathType.cover}/${fileName}`
  } else if (type === fileUploadService.fileUrlType.target) {
    url = url + `${containerCategory.target}/${userId}/${blobPathType.target}/${fileName}`
  }
  else if (type === fileUploadService.fileUrlType.event) {
    url = url + `${containerCategory.event}/${userId}/${blobPathType.event}/${fileName}`
  }
  else if (type === fileUploadService.fileUrlType.voxBoxImage) {
    url = url + `${containerCategory.voxBox}/${userId}/${blobPathType.voxBoxImage}/${fileName}`
  }
  else if (type === fileUploadService.fileUrlType.voxBoxVideo) {
    url = url + `${containerCategory.voxBox}/${userId}/${blobPathType.voxBoxVideo}/${fileName}`
  }
  else if (type == fileUploadService.fileUrlType.eventEvidence) {
    url = url + `${containerCategory.event}/${userId}/${blobPathType.eventEvidence}/${fileName}`
  }
  else if (type == fileUploadService.fileUrlType.cms) {
    url = url + `${containerCategory.cms}/${userId}/${blobPathType.cms}/${fileName}`
  }
  else if (type == fileUploadService.fileUrlType.validatorId) {
    url = url + `${containerCategory.validatorInfo}/${userId}/${blobPathType.validatorId}/${fileName}`
  }
  else if (type == fileUploadService.fileUrlType.validatorCv) {
    url = url + `${containerCategory.validatorInfo}/${userId}/${blobPathType.validatorCv}/${fileName}`
  }
  else if (type == fileUploadService.fileUrlType.feedback) {
    url = url + `${containerCategory.feedback}/${userId}/${blobPathType.feedback}/${fileName}`
  }

  return url;
}
export const capitalizeFirstLetter=(string)=> {
  return string?string[0].toUpperCase() + string.slice(1):'';
}
export const capitalAllFirstLetter=(string)=>{
  let arr=string?.length>0?string?.split(' ')?.map((a)=>a?.length>1?a[0].toUpperCase()+a?.substring(1,a?.length):a[0].toUpperCase())?.join(" "):'';
  return arr;

}

const containerCategory = {
  'user': 'user',
  'target': 'user',
  'event': 'user',
  'voxBox': 'user',
  'cms': 'user',
  'validatorInfo' : 'user',
  'feedback': 'user'
}

const blobPathType = {
  profile: 'profile',
  cover: 'cover',
  target: 'target',
  event: 'event',
  voxBoxImage: 'voxBoxImage',
  voxBoxVideo: 'voxBoxVideo',
  eventEvidence: 'eventEvidence',
  cms: 'cms',
  validatorId : 'validatorId',
  validatorCv : 'validatorCv',
  feedback: 'feedback'
}

const fileUrlType = {
  'userProfile': 'userProfile',
  'userCover': 'userCover',
  'target': 'target',
  'event': 'event',
  'voxBoxImage': 'voxBoxImage',
  'voxBoxVideo': 'voxBoxVideo',
  'eventEvidence': 'eventEvidence',
  'cms': 'cms',
  'validatorId' : 'validatorId',
  'validatorCv' : 'validatorCv',
  'Complaint' : 'Complaint',
  'feedback' : 'feedback'
}

const fileUploadService = {
  fileUrlType
};

const chooseBlobPath = (type, _id) => {
  let blobPath = blobPathType[type];
  return `${_id}/${blobPath}/`;
}

const getContainerName = (category) => {
  let containerName = containerCategory[category];
  return containerName;
}

export const changeDateFormatmmddyy = (date) => moment(date).format('DD-MMM-YYYY')
export const changeDateFormatYYYY = (date) => moment(date).format('YYYY-MM-DD')
export const changeDateFormatddmmyyyy = (date) => moment(new Date(date).toLocaleDateString('en-US', dateOptions)).format('DD-MMM-YYYY')
export const changeDateFormatmmddyyyy = (date) => moment(new Date(date).toLocaleDateString('en-US', dateOptions)).format('MM-DD-YYYY')

export const dateTimeFormat = (date) => {
  return moment(new Date(date).toLocaleDateString('en-US', dateOptions)).format('DD-MM-YYYY hh:mm A')+' '+moment(date).format('hh:mm A')
  //moment(new Date(date).toLocaleDateString('en-US', dateOptions)).format('DD-MM-YYYY hh:mm A')
}
export const timeFormat = (date) => {
  return moment(date).format("hh:mm A")
}
export const dayDateFormat = (date) => moment(new Date(date).toLocaleDateString('en-US', dateOptions)).format("dddd, DD MMM YYYY")
export const shortDayDateFormat = (date) => moment(new Date(date).toLocaleDateString('en-US', dateOptions)).format("ddd, DD-MMM-YYYY")
export const dayFormat = (date) => moment(new Date(date).toLocaleDateString('en-US', dateOptions)).format("DD")
export const monthFormat = (date) => moment(new Date(date).toLocaleDateString('en-US', dateOptions)).format("MMM")

export const timeFormat12Hr = (date) => moment(date).format("hh:mm")
export const AmPm12Hr = (date) => moment(date).format("A")

export const DateTimeEventFormat = (date, time) => {
  return moment(new Date(date).toLocaleDateString('en-US', dateOptions)).format("YYYY-MM-DD") + 'T' + moment(time).format("hh:mm") + ':00.000Z';
}

export const dateTimeEventFormat = (date, time)=>{
  return moment(new Date(date).toLocaleDateString('en-US', dateOptions)).format("YYYY-MM-DD") + 'T' + time + ':00.000';
}
export const DateFormat = (date) => {
  return moment(new Date(date).toLocaleDateString('en-US', dateOptions)).format("DD-MMM-YYYY") + 'T00:00:00.000Z';
}

export const checkDateRange = (startDate, endDate, minDate, maxDate) => {
  if (changeDateFormatYYYY(new Date(minDate).toLocaleDateString('en-US', dateOptions)) <= changeDateFormatYYYY(new Date(startDate).toLocaleDateString('en-US', dateOptions)) &&
    changeDateFormatYYYY(new Date(maxDate).toLocaleDateString('en-US', dateOptions)) >= changeDateFormatYYYY(new Date(endDate).toLocaleDateString('en-US', dateOptions))) {
    return true;
  }
  else {
    return false;
  }
}

export const eventDateRange = (startDate, endDate, frequency, date, weekday, repeatEvery) => {
  let dates = [];
  let start_Date = changeDateFormatYYYY(new Date(startDate).toLocaleDateString('en-US', dateOptions))
  let end_Date = changeDateFormatYYYY(new Date(endDate).toLocaleDateString('en-US', dateOptions))
  let start_date = new Date(startDate);
  let end_date = new Date(endDate);

  if (frequency === 'daily') {
    while (start_date <= end_date) {
      dates.push(moment(start_date).format("MM-DD-YYYY"));
      start_date.setDate(start_date.getDate() + repeatEvery);
    }
  }
  else if (frequency === 'weekly') {
    while (start_date <= end_date) {
      if (start_date.getDay() == weekday) {
        dates.push(moment(start_date).format("MM-DD-YYYY"));
        start_date.setDate(start_date.getDate() + 7*repeatEvery);
      } else {
        start_date.setDate(start_date.getDate() + 1);
      }
    }
  }
  else if (frequency === 'monthly') {
    while (start_date <= end_date) {
      if (moment(start_date).format("D") == date) {
        dates.push(moment(start_date).format("MM-DD-YYYY"));
        start_date.setMonth(start_date.getMonth() + repeatEvery);
      }
      else {
        start_date.setDate(start_date.getDate() + 1);
      }
    }
  }
  else if (frequency === 'quartely') {
    while (start_date <= end_date) {
      if (moment(start_date).format("D") == date) {
        dates.push(moment(start_date).format("MM-DD-YYYY"));
        start_date.setMonth(start_date.getMonth() + 3);
      }
      else {
        start_date.setDate(start_date.getDate() + 1);
      }
    }
  }
  else if (frequency === 'half-yearly') {
    while (start_date <= end_date) {
      if (moment(start_date).format("D") == date) {
        dates.push(moment(start_date).format("MM-DD-YYYY"));
        start_date.setMonth(start_date.getMonth() + 6);
      }
      else {
        start_date.setDate(start_date.getDate() + 1);
      }
    }
  }
  else if (frequency === 'yearly') {
    while (start_date <= end_date) {
      if (moment(start_date).format("D") == date) {
        dates.push(moment(start_date).format("MM-DD-YYYY"));
        start_date.setMonth(start_date.getMonth() + 12);
      }
      else {
        start_date.setDate(start_date.getDate() + 1);
      }
    }
  }

  return dates;
}

export const getMeasuringUnits = (key) => {
  let keyword = key.replace(/\s+/g, '_').toLowerCase();
  return (measureUnits[keyword])?measureUnits[keyword]:'#';
}
export const dateFormatTofollow=(date)=>{
  
}

export const checkEventFrequencyAvailable = (type) => {
  if(type.replace(/\s+/g, '_').toLowerCase() === 'electricity_consumption_reduction' ||
    type.replace(/\s+/g, '_').toLowerCase() === 'fossil_fuel_consumption_reduction' ||
    type.replace(/\s+/g, '_').toLowerCase() === 'cooking_gas_consumption_reduction' ||
    type.replace(/\s+/g, '_').toLowerCase() === 'renewable_electricity_consumption_hydro' ||
    type.replace(/\s+/g, '_').toLowerCase() === 'renewable_electricity_consumption_solar' ||
    type.replace(/\s+/g, '_').toLowerCase() === 'renewable_electricity_consumption_wind' ||
    type.replace(/\s+/g, '_').toLowerCase() === 'water_consumption_reduction' ||
    type.replace(/\s+/g, '_').toLowerCase() === 'women_in_workforce' 
    )
  {
    return 'monthly';
  }
  else{
    return 'daily';
  }
}

export const validatorRequiredNeccessary = (type) => {
  if(type.replace(/\s+/g, '_').toLowerCase() === 'trees_planted' ||
    type.replace(/\s+/g, '_').toLowerCase() === 'plogging' ||
    type.replace(/\s+/g, '_').toLowerCase() === 'area_covered_by_trees' ||
    type.replace(/\s+/g, '_').toLowerCase() === 'water_body_rejuvenation' ||
    type.replace(/\s+/g, '_').toLowerCase() === 'beach_cleanup' ||
    type.replace(/\s+/g, '_').toLowerCase() === 'payment_to_ngos' ||
    type.replace(/\s+/g, '_').toLowerCase() === 'wildlife_conservation' 
    )
  {
    return true;
  }
  else{
    return false;
  }
} 

export const convertToBool = (data) => {
  let datatype = typeof data;
  if(datatype == 'boolean'){
    return data
  }else if(datatype == 'string'){
    if(data == 'true'){
      return true
    }
  }
  return false
} 
