import React, { useEffect, useState } from "react";
import { TestimonialGoogle } from "../commonComponents/Images";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { followTheOrganization, getOrganizationSugg, OrgFollowingList } from "../services/onBoarding.services";
import { IndeterminateCheckBoxTwoTone } from "@mui/icons-material";
import { getImageUrl, showSingleToastSuccess } from "../utils/helpers";
import { Col, Row } from "react-bootstrap";
const OrgFewList = () => {
  const [followOrgList, setFollowOrgList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const limit = 4;
  const { t } = useTranslation();
  const [pageNo, setPageNo] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const navigate=useNavigate();

  useEffect(() => {
    getFollowList(0);
  }, [])

  const getFollowList = async (currentPage) => {
    setLoading(true);
    getOrganizationSugg(currentPage, limit)
      .then((response) => {
        if (response?.data?.status === 200) {
          const _data = response?.data?.data?.data;
          setFollowOrgList(_data);
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error, "error");
      })
  }
  const unFollowOrg = (e, org) => {
    e.preventDefault();
    if (org?.status) {
      let param = {
        list: [org?._id],
        action: "followOrganization"
      }
      followTheOrganization(param)
        .then((resp) => {
          if (resp?.status === 200) {
            showSingleToastSuccess(t(`apiMessages.ORG_FOLLOW_REQUEST`));
            getFollowList(0);
          }
        })
    }
  }
  const showOrg = (org) => { 
    let encrptedId = window.btoa(org?._id);
    navigate(`/organization/${encrptedId}`, { state: {orgId:org?._id}})

}
  return (
    <div className="widget-box orgBlock orgFewWidgetBox">

      <p className="widget-box-title">{t('organizations')}  <span className="widget-box-title-count">{followOrgList?.totalCount}</span></p>
      <div className="widget-box-content mb-none">
        {followOrgList?.length >= 0 ? <>
          {followOrgList?.map((item, index) => (
            <Row key={index} className=''>
              <Col md={12}>
                <div className="organizationsVoxbox">
                  <div className="orgImgBlk" onClick={(e)=>showOrg(item)}>
                  <img src={item?.profilePicture ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : '/Assets/org1@2x.png'} alt="" className="orgFewImg" />
                  </div>
                  <div className="orgContentBlk">
                    <p className="userNameText" >{item?.name}</p>
                    <p className="userStatusText">{item?.followers} {t('followers')} </p>
                    <p className="userStatusText" onClick={(e) => unFollowOrg(e, item)}>
                      <img src="/Assets/Add-Friend-Icon-inactive.png" />
                      <span className='text-success'>{t('follow')}</span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          ))}
          <Link to="/organizations" className="button small primary mt-3">{t('see_all_organizations')}</Link>
        </> :
          <h6>{t('no_org_following')}</h6>}
      </div>
    </div>
  )
}

export default OrgFewList;