import React, { useState, useEffect } from 'react';
import SectionHeader from '../../../commonDashboardComp/sectionHeader';
import { useTranslation } from 'react-i18next';
import ReportedUserBox from '../../../commonDashboardComp/reportedUserBox';
import BarsLoader from '../../../commonComponents/barsLoader';
import { Col, Row } from 'react-bootstrap';
import ReactPagination from '../../../commonComponents/reactPagination';
import swal from 'sweetalert';
import { TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { deleteUserAccountService, reportUserlist } from '../../../services/adminManage.services';
import { showSingleToastSuccess, showToastError } from '../../../utils/helpers';

const ReportUserList = ({ }) => {
    const { t } = useTranslation();
    // const searchText = useSelector(selectSearchText)
    const limit = 20;
    const [isLoading, setLoading] = useState(true);
    const [ReportedList, setReportedList] = useState([]);
    const [page, setPage] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [totalPage, setTotalPage] = useState(0);
    const [show, setShow] = useState(true);
    // const [data, setData] = useState(ReportedList);


    // useEffect(() => {
    //     setData(ReportedList);
    //   }, [ReportedList])

    useEffect(() => {
        setLoading(true);
        getAllReportedUserList();
    }, [page, searchText])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const handlePageClick = (data) => {
        let Page = data.selected
        setPage(Page)
    }

    const getAllReportedUserList = async () => {
        reportUserlist(page, limit, searchText)
            .then((response) => {
                if (response.data.status === 200) {
                    const _data = response.data.data.data;
                    setReportedList(_data)
                    let pageCount = (response?.data?.data?.totalCount) / (limit);
                    setTotalPage(Math.ceil(pageCount));
                }
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const deleteUserHandler = (e,item) => {
        e.preventDefault()
        swal({
          text: t('alerts.delete_user'),
          title: '',
          icon: "warning",
          buttons: {
            Yes: {
              text: t('yes'),
              value: true,
            },
            No: {
              text: t('no'),
              value: false,
            },
          },
        })
          .then(async (val) => {
            if (!val) return;
    
            try {
              deleteUserAccountService(item?._id)
                .then((res) => {
                  if (res?.data?.status === 200) {
                    showSingleToastSuccess(t(`apiMessages.USER_DELETE_SUCCESS`))
                  }
                  else {
                    showToastError(t('errors.some_error'))
                  }
                })
                .catch((error) => {
                  showToastError(t('errors.some_error'))
                })
            }
            catch (error) {
              console.log(error)
            }
          });
      }


    const renderEmptyMessage = () => {
        return (
            <>
                {ReportedList?.length === 0 && !isLoading &&
                    <>
                        <Row className="notification">
                            <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center">
                                <span className="bold">
                                    {t('no_users')}
                                </span>
                            </Col>
                        </Row>
                    </>}
            </>
        )
    }

    return (
        <>
            <SectionHeader title={t('superAdmin.reported_user')} />
            <div className="reportedUserComp section-filters-bar v6">
                <div className="section-filters-bar-actions">
                    <form className="form reportedUserSearch eventSearch">
                        <div className="form-item split">
                            <TextField type='text' label={t('Search_Users')}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <button className="button primary"
                                onClick={(e) => e.preventDefault()}
                            >
                                <SearchOutlinedIcon className="icon-magnifying-glass" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Row>

                    {isLoading ? <BarsLoader /> : <>
                        {ReportedList.map((item, index) => {
                            return (
                                <ReportedUserBox
                                    reportData={item}
                                    key={index}
                                    deleteUserHandler={(e)=>deleteUserHandler(e,item)}
                                />
                            )
                        })}
                    </>}

                    <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />

            </Row>
            {renderEmptyMessage()}
        </>
    )

}
export default ReportUserList;