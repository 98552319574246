import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import SettingsSidebar from "../../../commonDashboardComp/settingsSidebar";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import ContactPrivacy from "./privacySettings/contactPrivacy";
import EducationPrivacy from "./privacySettings/educationPrivacy";
import EmpPrivacy from "./privacySettings/empPrivacy";
import SpredeProfilePrivacy from "./privacySettings/spredeProfilePrivacy";
import { getGeneralSettingService, updateGeneralSettingService } from "../../../services/userSettings.services";
import { showSingleToastSuccess, showToastError, showToastSuccess } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import DialogBox from "../../../commonComponents/dialogBox";
import { setCanSwitch } from "../../../Reducer/user/user.action";
import { useDispatch } from "react-redux";
const defaultSettingKeys = {
    contactInfo: 'contactInfo',
    educationInfo: 'educationInfo',
    employmentInfo: 'employmentInfo',
    profileInfo: 'profileInfo',
}

const editOptionDefault = {
    contactInfo: false,
    educationInfo: false,
    employmentInfo: false,
    profileInfo: false,
}
const PrivacySettings = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [setting, setSetting] = useState();
    const [dataDupCopy, setDupCopy] = useState('');
    const [editOption, setEditOption] = useState(editOptionDefault);

    const [showDialog, setShowDialog] = useState(false)
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

    useEffect(() => {
        getGeneralSettings();
    }, [])

    useEffect(()=>{
        setShowDialog(!(JSON.stringify(setting) === JSON.stringify(dataDupCopy)))
    }, [setting, dataDupCopy])

    useState(()=>{
        dispatch(setCanSwitch(!showDialog))
   }, [showDialog])


    const getGeneralSettings = async () => {
        try {
            const response = await getGeneralSettingService()
            if (response?.data) {
                setSetting(response.data)
                setDupCopy(response.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onChangeSetting = (key, value) => {
        setSetting({ ...setting, [key]: value })
    }

    const onChangeEditOption = (key, value) => {
        setEditOption({ ...editOption, [key]: value })
    }
  
    const onSaveSetting = async (option, key) => {
        if(showDialog){
            try {
                const response = await updateGeneralSettingService(option)
                if (response) {
                    onChangeEditOption(key, false)
                    getGeneralSettings();
                    // showToastSuccess(t(`apiMessages.SETTING_UPDATE_SUCCESS`))
                    showSingleToastSuccess(t(`apiMessages.SETTING_UPDATE_SUCCESS`))
                }
            } catch (error) {
                console.log(error)
            }
        }
        else{
            onChangeEditOption(key, false)
            showSingleToastSuccess(t('errors.No_change_have_been_made'));            
        }
        
    }

    const getSettingOptionValue = (key) => {
        let option = ''
        if (defaultSettingKeys.contactInfo === key) {
            option = {
                settings: {
                    "whoCanSeeMobile": setting.whoCanSeeMobile,
                    "whoCanSeeEmail": setting.whoCanSeeEmail,
                    "whoCanSeeAddress": setting.whoCanSeeAddress,
                }
            }
        } else if (defaultSettingKeys.educationInfo === key) {
            option = {
                settings: {
                    "whoCanSeeCollegeName": setting.whoCanSeeCollegeName,
                }
            }
        } else if (defaultSettingKeys.employmentInfo === key) {
            option = {
                settings: {
                    "whoCanSeeOrganization": setting.whoCanSeeOrganization,
                    "whoCanSeeDesignation": setting.whoCanSeeDesignation,
                    "whoCanSeeOrganizationAddress": setting.whoCanSeeOrganizationAddress,
                }
            }
        }
        else if (defaultSettingKeys.profileInfo === key) {
            option = {
                settings: {
                    "whoCanSeeInterest": setting.whoCanSeeInterest,
                    "whoCanSeeCurrentEvent": setting.whoCanSeeCurrentEvent,
                    "whoCanSeeCurrentTarget": setting.whoCanSeeCurrentTarget,
                    "whoCanSeeFollowingOrganization": setting.whoCanSeeFollowingOrganization,
                    "whoCanSharePost": setting.whoCanSharePost,
                }
            }
        }
        return option
    }


    return (<Row className="grid grid-3-6-3 mobile-prefer-content">

        <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
        />

        <Col md={3} className="grid-column">
            <SettingsSidebar />
        </Col>

        <Col md={9} className="grid-column">
            <SectionHeader pretitle={t('My_Privacy')} title={t('Privacy_Info')} style={{ marginTop: '0px' }} />
            <ContactPrivacy
                setting={setting}
                onEdit={() => onChangeEditOption(defaultSettingKeys.contactInfo, true)}
                canEdit={editOption.contactInfo}
                onSave={() =>
                    onSaveSetting(getSettingOptionValue(defaultSettingKeys.contactInfo), defaultSettingKeys.contactInfo)}
                onChangeSetting={onChangeSetting} />

            <EducationPrivacy
                setting={setting}
                onEdit={() => onChangeEditOption(defaultSettingKeys.educationInfo, true)}
                canEdit={editOption.educationInfo}
                onSave={() => onSaveSetting(getSettingOptionValue(defaultSettingKeys.educationInfo), defaultSettingKeys.educationInfo)}
                onChangeSetting={onChangeSetting} />

            <EmpPrivacy
                setting={setting}
                onEdit={() => onChangeEditOption(defaultSettingKeys.employmentInfo, true)}
                canEdit={editOption.employmentInfo}
                onSave={() => onSaveSetting(getSettingOptionValue(defaultSettingKeys.employmentInfo), defaultSettingKeys.employmentInfo)}
                onChangeSetting={onChangeSetting} />

            <SpredeProfilePrivacy
                setting={setting}
                onEdit={() => onChangeEditOption(defaultSettingKeys.profileInfo, true)}
                canEdit={editOption.profileInfo}
                onSave={() => onSaveSetting(getSettingOptionValue(defaultSettingKeys.profileInfo), defaultSettingKeys.profileInfo)}
                onChangeSetting={onChangeSetting} />
        </Col>
    </Row>)
}

export default PrivacySettings;

