import axios from "axios";
import instance, {instanceOrg} from "../AppInterceptor";
import ApiConfig from "../config/ApiConfig";
import { getObjectFromStore } from "../storage/Storage";
// import UserAxios from "./userAxios";

export const getIPAddressService = () => {
    try {
        return axios.get(ApiConfig.getIPAddress);
    }
    catch(e){
        console.log(e);
    }
}
export const RegisterService = (payload) => {
    return instance.post(ApiConfig.register, payload);
}

export const ResendRegisterOtp = (email)=>{
    var url = ApiConfig.resendRegisterOtp+'?email='+email
    return instance.get(url);
}

export const verifyEmailOtpService = (payload) => {
    return instance.post(ApiConfig.otpVerify, payload);
}

export const LoginService = (payload) => {
    return instance.post(ApiConfig.login, payload);
}

export const ForgotPasswordService = (email, siteKey) => {
    var url = ApiConfig.forgotPasswordOTP+'?email='+email+'&siteKey='+siteKey
    return instance.get(url);
}

export const ResetPasswordService = (payload) => {
    return instance.post(ApiConfig.resetPassword, payload);
}

export const getUserDetailsService = () => {
    return instance.get(ApiConfig.getUserByToken);
}

export const getUserOrgStatusService = () => {
    return instance.get(ApiConfig.userOrganizationStatus);
}

export const getUserDetailServices=()=>{
    return instance.get(ApiConfig.getUserDetails)
}

export const socialLogin = (payload) => {
    return instance.post(ApiConfig.socialLogin, payload)
}

export const LinkedInDetailsService = (code) => {    
    var url = ApiConfig.linkedInUserDetails+'?code='+code
    return instance.get(url);
}

export const getReq = (url) => {
    return instance.get(url);
}

export const postReq = (url , payload) => {
    return instance.post(url, payload);
}

export const subscriptionService = (payload) => {
    return instance.post(ApiConfig.setSubscription , payload);
}

export const unSubscribeNewsletterService = (email) => {
    return instance.get(ApiConfig.unSubscribe+'?email='+email)
}

export const getImageRequestService=(payload)=>{
    let url=`${ApiConfig.imageRequestIs}?category=${payload.category}&type=${payload.type}&fileName=${payload.fileName}`
    if(payload?.defaultId){
        url += '&defaultId='+payload?.defaultId
    }
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}
export const putImageRequestService=(url,payload,headers)=>{
     return  axios.put(url,payload,headers);
}


export const SuperLoginService = (payload) => {
    return axios.post(ApiConfig.superLogin , payload)
}
