import instance, {instanceOrg} from "../AppInterceptor";
import ApiConfig from "../config/ApiConfig";
import { getObjectFromStore } from "../storage/Storage";

export const getLoginActivityService=(page,limit)=>{
    let finalUrl=ApiConfig.loginActivityList+"?page="+page+"&limit="+limit;
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(finalUrl);
    }
    else{
        return instance.get(finalUrl);
    }
}

