import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { AvatarImg } from "../../../commonComponents/Images";
import { Box, TextField } from "@mui/material";
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { getfollowerList, sendInvitesOrgUser } from "../../../services/follower.service";
import { getImageUrl, showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import BarsLoader from "../../../commonComponents/barsLoader";
import { useTranslation } from "react-i18next";
import { inviteFriendByMailPostService, postUserCancelRequest } from "../../../services/friends.service";
const InviteFriends = ({ }) => {
    const { t } = useTranslation();
    const [inviteData, setInviteData] = useState('');
    let defaultUserImage = '/Assets/Img.png';
    const [loading, setLoading] = useState(false);
    const [inviteLoading, setInviteLoading] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [error, setError] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [userBlocked, setUserBlocked] = useState(false);

    const sendinviteViaMail = async (e) => {
        setButtonLoading(true)
        e.preventDefault();
        if (emailAddress) {
            if (!error) {
                let param = {
                    email: emailAddress
                }
                await inviteFriendByMailPostService(param)
                    .then((resp) => {
                        if (resp?.status == 200) {
                            if (resp?.data?.data === 'REQUEST_SEND_SUCCESS') {
                                showSingleToastSuccess(t(`friendsMsg.Invite_Sent`));
                            }
                            else {
                                setInviteData(resp?.data?.data);
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }
        }
    }

    const handleChange = (e) => {
        e.preventDefault();
        setEmailAddress(e.target.value);
        if (e.target.value) {
            if (!checkingEmailValid(e.target.value)) {
                setError(true);
            }
            else {
                setError(false);

            }
        }

    }
    const checkingEmailValid = (email) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{3})+$/.test(email)) return true
        return false;
    }

    const handleUserRequests = async (action) => {
        if (inviteData?.status === 'active') {
            const params = {
                action: action,
                list: [inviteData?._id]
            }
            setTimeout(async () => {
                await postUserCancelRequest(params)
                    .then((res) => {
                        if (res) {
                            let SuccessMsg = action === 'cancelRequest' ? t('apiMessages.FRIEND_REQUEST_CANCEL')
                                : action === 'acceptRequest' ? t('apiMessages.FRIEND_REQUEST_ACCEPTED')
                                    : action === 'deleteRequest' ? t('apiMessages.FRIEND_REQUEST_DELETED')
                                        : action === 'unFriendRequest' ? t('apiMessages.FRIEND_REMOVED')
                                            : action === 'sendRequest' ? t('apiMessages.FRIEND_REQUEST_SENT')
                                                : action === 'blockUserRequest' ? t('User_Blocked')
                                                    : action === 'unBlockUserRequest' ? t('User_unBlocked')
                                                        : "";

                            showSingleToastSuccess(t(SuccessMsg));
                            setEmailAddress('');
                            setInviteData('');

                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error?.response)
                        //showToastError("Some Error Occurred. Please try again later");

                    })
            }, 1000)
        }
        else {
            showToastError(inviteData?.firstName + t('apiMessages.is_not_active'));
        }


    }

    return (
        <div>
            <Row className="grid">
                <Col md={6} className=" grid-column">
                    <div className="widget-box pa-20">
                        <p className="widget-box-title">{t(`errors.Invite_Via_Email`)}</p>
                        <div className="widget-box-content m-none">
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '100%' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <Row>

                                    <Col md={12} className="inviteFriendsInput">
                                        <TextField type='text' label={t(`form.label_email`)} id='email' value={emailAddress} onChange={(e) => handleChange(e)} />
                                        {error &&
                                            <div className="error-text">
                                                {t(`errors.Email_Not_Correct`)}
                                            </div>
                                        }
                                        <button className="button primary mt-20" disabled={error && emailAddress === ''} onClick={(e) => sendinviteViaMail(e)}>{t('invite_friends')} </button>
                                    </Col>
                                </Row>
                            </Box>
                        </div>
                    </div>
                </Col>

                {inviteData &&
                    <Col md={6} className=" grid-column">

                        <div className="widget-box pa-20" >
                            <div className="target_box_area m-none">
                                <div className="avtarImgBlck"><AvatarImgBox img={inviteData?.profilePicture !== "" ? getImageUrl(inviteData?._id, 'userProfile', inviteData?.profilePicture) : defaultUserImage} /></div>

                                <div className='fullWidthRef'>
                                    <p className="user-status-title">
                                        <span className="bold">{(`${inviteData?.firstName} ${inviteData?.lastName}`) || ''}</span>
                                    </p>
                                    <small>{inviteData?.userName ? `@${inviteData?.userName}` : ''}</small>
                                </div>
                                <div className="inviteBtnBlock">
                                    <div className="action-request-list">

                                        {(!inviteData?.isFriend && !inviteData?.isFriendsRequestSent && !inviteData?.isFriendsRequestReceived && !inviteData?.isBlockedUser) ? <button className="button primary refer-btn" onClick={(e) => handleUserRequests('sendRequest')} >{t('friendsMsg.ADD_FRIEND')}</button> : ''}
                                        {inviteData?.isFriend && !userBlocked ? <> <button className='btn btn-danger text-white bg-danger' title={t('button.block_text')} onClick={(e) => handleUserRequests('blockUserRequest')} >{t('button.block_text')}</button>   <button className='btn btn-danger text-white bg-danger' title={t('friendsMsg.UNFRIEND_USER')} onClick={(e) => handleUserRequests('unFriendRequest')} >{t('friendsMsg.UNFRIEND_USER')}</button>  </> : ''}
                                        {inviteData?.isFriendsRequestSent ? <button className='btn btn-danger text-white bg-danger' onClick={(e) => handleUserRequests('cancelRequest')} >{t('friendsMsg.Cancel_Sent_Request')}</button> : ''}
                                        {inviteData?.isBlockedUser && inviteData?.isBlockByMe ? <button title={t('button.unblock_text')} className='btn btn-danger text-white bg-danger' onClick={(e) => handleUserRequests('unBlockUserRequest')}>{t('button.unblock_text')}</button > : ''}
                                        {inviteData?.isBlockedUser && !inviteData?.isBlockByMe ? <button title={t('blocked_by_this_user')} className='btn btn-danger text-white bg-danger'>{t('You_are_Blocked')}</button > : ''}

                                        {inviteData?.isFriendsRequestReceived && <>
                                            <button className="button primary refer-btn mx-3" title={t('Accept_friend_Request')} onClick={(e) => handleUserRequests('acceptRequest')} >{t('Confirm')}</button>
                                            <button className='btn btn-danger text-white bg-danger' title={t('Delete_friend_Request')} onClick={(e) => handleUserRequests('acceptRequest')} >{t('delete')}</button>
                                        </>}


                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            loading ?
                                <BarsLoader />
                                : ''
                        }
                    </Col>
                }
            </Row>
        </div>
    )
}

export default InviteFriends;