import { t } from 'i18next';
import React from 'react'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AvatarImgBox from '../../../commonComponents/AvatarImgBox/avatarImgBox'
import { AvatarImg } from '../../../commonComponents/Images';
import Loader from '../../../commonComponents/Loader';
import ReactPagination from '../../../commonComponents/reactPagination';
import { getImageUrl } from '../../../utils/helpers';

function AllUserSearchList({ usersList, isLoading, totalPageForUser, handlePageClick }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const showUser = (user) => {
        let encrptedId = window.btoa(user?._id);
        //navigate(`/profile/${encrptedId}`)
        navigate(`/profile/${encrptedId}`, { state: { userId: user?._id } })
        console.log(user)
    }
    return (
        <div className="widget-box">
            <>
                {
                    isLoading ? <Loader /> : usersList.length > 0 ?
                        <>
                            {
                                usersList.length > 0 &&
                                <>
                                    {
                                        usersList.map((item, i) => (
                                            <div className="widget-box blocked_user_area" style={{marginTop:10,marginBottom:10,cursor:'pointer'}} key={i} onClick={() => showUser(item)} >
                                                <div className="">
                                                    <Row className="align-items-center" style={{ justifyContent: 'space-between' }}>
                                                        <div className="col-lg-7 col-md-6 d-flex align-items-center gray_plate">
                                                            <div className="hexagon-image-30-32" onClick={() => showUser(item)} >
                                                                <AvatarImgBox
                                                                    avatarClasses="globalSeachAvatar"
                                                                    img={(item?.profilePicture !== '') ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : AvatarImg}
                                                                />
                                                            </div>
                                                            <div style={{ marginLeft: '12px' }} className="">
                                                                <h5 className="bold">{`${item?.firstName} ${item?.middleName} ${item?.lastName}`}</h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-lg-5 col-md-6">
                                                            <div className="globalSearchViewBtn globalSearchList justify-content-end">
                                                                <button onClick={() => showUser(item)} className="button primary view-event-btn">{t('button.view')}</button>
                                                            </div>
                                                        </div> */}
                                                    </Row>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                            }
                        </> :
                        <>
                            <>
                                {usersList.length === 0 &&
                                    <>
                                        <div style={{ display: 'flex', marginTop: 10 }}>
                                        {t('form.There_is_no_Users')}
                                        </div>
                                    </>
                                }
                            </>
                        </>
                }
            </>
            <ReactPagination pageCount={totalPageForUser} onPageChange={handlePageClick} />
        </div>
    )
}

export default AllUserSearchList
