import React, {useEffect, useState} from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GetUserLoginLogService } from "../../../../services/onBoarding.services";
import { shortDayDateFormat } from "../../../../utils/helpers";
const RecentLoginInfo = () => {
    const { t } = useTranslation();
    const [logs, setLogs] = useState([]);

    useEffect(()=>{
        getLogs()
    }, [])

    const getLogs = () => {
        GetUserLoginLogService(4)
        .then((data)=>{
            if(data?.data?.status === 200){
                setLogs(data?.data?.data?.data);
            }
        })
    }

    return (
        <Row className="grid">
            <div className="grid-column">
                <div className="widget-box area-widget">
                    <p className="widget-box-title">{t('Recent_Login')}</p>
                    <div className="widget-box-content">
                        <div className="table-wrap">
                            <div className="simplebar-wrapper" >
                                <table className="login_table">
                                    <tr>
                                        <th>{t('Time_Stamps')}</th>
                                        <th>{t('Location')}</th>
                                        <th>{t('Ip_Address')}</th>
                                        <th>{t('Browsers')}</th>
                                        <th>{t('Devices')}</th>
                                    </tr>

                                    {logs.length > 0 && logs?.map((item, index)=>{
                                        return (<tr key={index}>
                                            <td>{shortDayDateFormat(item?.createdAt)}</td>
                                            <td>{item?.location}</td>
                                            <td>{item?.ipAddress}</td>
                                            <td>{item?.browser}</td>
                                            <td>{item?.device}</td>
                                        </tr>)
                                    })}

                                    {logs.length === 0 &&
                                        <tr>
                                            <td colspan={5}>{t('No_Logs')}</td>
                                        </tr>
                                    }
                                </table>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </Row>)
}

export default RecentLoginInfo;