import { getImageRequestService } from "./auth.services";

export const uploadFileService = async (type, category, fileName, file, id) => {
  try {
      let params = {
          fileName: fileName,
          category: category,
          type: type,
          defaultId:id
      }
      var response = await getImageRequestService(params);
      if(response)
      {
        const result = await uploadFileonAwsService(response?.data?.data, file, type, fileName);
        return result;
      }

  } catch (error) {
      console.error(error);
  }
}

const uploadFileonAwsService = async (url, actParam, type, fileName) => {
  const response = await fetch(url + '&x-ms-blob-type=BlockBlob', {
      method: 'PUT',
      headers: {
          'x-ms-blob-type': `BlockBlob`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data'
      },
      body: actParam
  })
  return response;
}