import instance, { instanceOrg } from "../AppInterceptor";
import ApiConfig from "../config/ApiConfig";
import { getObjectFromStore } from "../storage/Storage";



export const uploadEvidenceService = (payload) => {
    //Both org and user can upload evidence
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.post(ApiConfig.evidencePost, payload);
    }
    else {
        return instance.post(ApiConfig.evidencePost, payload);
    }
}

export const markValidatorService = (payload) => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.post(ApiConfig.markValidator, payload)
    }
    else {
        return instance.post(ApiConfig.markValidator, payload)
    }

}

export const submitReportService = (payload) => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.post(ApiConfig.submitValidatorReport, payload);
    }
    else {
        return instance.post(ApiConfig.submitValidatorReport, payload);
    }

}

export const validatorAvailibiltyCheck = (startDate, endDate) => {
    let finalUrl = `${ApiConfig.validatorDates}?startDate=${startDate}&endDate=${endDate}`;
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.get(finalUrl);
    }
    else {
        return instance.get(finalUrl);
    }


}

export const deleteAvailibiltyTime = (payload) => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.post(ApiConfig?.deleteAvailibilty, payload);
    }
    else {
        return instance.post(ApiConfig?.deleteAvailibilty, payload);
    }
}

export const deleteEvidenceService=(payload)=>{
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.delete(ApiConfig?.deleteEvidence,{data:payload})
    }
    else{
        return instance.delete(ApiConfig?.deleteEvidence,{data:payload})
    }
  
}

