import React,{useState} from "react";
import { showSingleToastSuccess, showToastError, showToastSuccess } from "../utils/helpers";
import { useTranslation } from "react-i18next";
import { Row , Col } from "react-bootstrap";
import './commonStyle.css';
import AvatarImgBox from "../commonComponents/AvatarImgBox/avatarImgBox";
import { getImageUrl } from "../utils/helpers";
import { ManageEventParticipantsService } from "../services/event.services";
import swal from 'sweetalert';
import { AvatarImg } from "../commonComponents/Images";
import { useSelector } from "react-redux";
import { selectCurrentOrg, selectCurrentUser, selectCurrentUserRole } from "../Reducer/user/user.selector";
const EventBox = ({userDet, btnText, action, eventId}) => {
   const [show, setShow] = useState(true);
   let defaultUserImage = AvatarImg;
    const {t} = useTranslation();

    const role = useSelector(selectCurrentUserRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);

    let current_user = {};
    if(role === 'organization'){   current_user = CURRENTORG     }
    else{ current_user = CURRENTUSER }

    const btnHandler = (e) => {
      e.preventDefault();

      if(action === 'block'){
        swal({
          text: t('alerts.block_participants'),
          title: '',
          icon: "warning",
          buttons: {
            Yes: {
              text:t('yes'),
              value: true,
            },
            No: {
              text: t('no'),
              value: false,
            },
          },
        })
        .then(async (val) => {
              if(!val) return;
              let params = {
                eventId : eventId,
                userId : userDet?._id,
                action : action
              }
              ManageEventParticipantsService(params)
              .then((data)=>{
                  if(data?.data?.status === 200){
                      // showToastSuccess(t(`apiMessages.USER_BLOCKED_SUCCESSFULLY`));
                      showSingleToastSuccess(t(`apiMessages.USER_BLOCKED_SUCCESSFULLY`));               
                      setShow(false)
                  }
                  else
                  {
                      showToastError(t(`errors.some_error`));
                  }
              })
              .catch((error)=>{
                  console.log(error);
              })
          });
      }
      else{
        swal({
          text: (action === 'block') ? t('alerts.block_participants') : t('alerts.unblock_participant'),
          title: '',
          icon: "warning",
          buttons: {
            Yes: {
              text:t('unblock_and_invite'),
              value: 1,
            },
            No: {
              text: t('unblock_and_remove'),
              value: 2,
            },
            Cancel:{
              text : t('cancel'),
              value: 3
            }

          },
        })
        .then(async (val) => {
              if(val === 3) return;
              let params = {
                eventId : eventId,
                userId : userDet?._id,
                action : action
              }
              ManageEventParticipantsService(params)
              .then(async(data)=>{
                  if(data?.data?.status === 200){
                      if(val === 1){
                        let param = {
                          eventId : eventId,
                          userId : userDet?._id,
                          action : "add"
                        }
                        await ManageEventParticipantsService(param);
                      }                      
                      // showToastSuccess(t(`apiMessages.USER_UNBLOCKED_SUCCESSFULLY`)); 
                      showSingleToastSuccess(t(`apiMessages.USER_UNBLOCKED_SUCCESSFULLY`));                 
                      setShow(false)
                  }
                  else
                  {
                      showToastError(t(`errors.some_error`));
                  }
              })
              .catch((error)=>{
                  console.log(error);
              })
          });
      }
      
    }

    return (
      <>
        {show && <div className="mt-4 parentBox" >
          <div className="widget-box pd-none">  
          <div className="bg-white borderRadius">
            <div className="target_box_area mr-none">
                    <div className="eventParticipantArea">
                        <div className="target_box_profile_area">
                            <div className="your_target_avatar text-center manageParticipantsAvatar">
                            <AvatarImgBox img={userDet?.profilePicture !== "" ? getImageUrl(userDet?._id, 'userProfile', userDet?.profilePicture) : defaultUserImage} />
                            </div>
                        </div>
                    </div>
                    <div className="target-box-content participants">
                      {userDet?.name ? userDet?.name : `${userDet?.firstName} ${userDet?.lastName}`}
                    </div>
                    <div className="participantsBtn">
                      {action !== '' && (current_user?._id !== userDet?._id) && 
                      <button type='button' className='target-btn view-btn' onClick={btnHandler}>{btnText}</button>}
                    </div>
                </div>
            </div>
          </div>
        </div>}
      </>
  )
}

export default EventBox;