import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AvatarImg } from "../../src/commonComponents/Images";
import { Row, Col } from "react-bootstrap";
import './commonStyle.css';
import { Switch } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentOrg } from "../Reducer/user/user.selector";
import AvatarImgBox from "../commonComponents/AvatarImgBox/avatarImgBox";
import { getImageUrl } from "../utils/helpers";

const RoleBox = ({ data }) => {
  let defaultUserImage = AvatarImg;
  const { t } = useTranslation();
  const organizationDetail = useSelector(selectCurrentOrg);
  const [role,setRole]=useState('');
  useEffect(()=>{
      getRole();
  },[data])

  const { firstName = '', lastName = ``, pages = [],profilePicture='',_id } = data;

  const getRole = () => {
    const organization = pages?.find((org) => org?.organizationId === organizationDetail?._id);
    setRole(organization?.role || '');
  }

  return (
    <div className="mt-4 parentBox" >
      <div className="widget-box  ">
        <Row>
          <Col md={2}>
                    <AvatarImgBox img={profilePicture !== "" ? getImageUrl(_id, 'userProfile',profilePicture) : defaultUserImage} /> 
          </Col>
          <Col md={1}>
              {firstName} {lastName}
          </Col>
          <Col md={3} className='text-right'>
            {t('superAdmin.manage_role_text')}:<span className='colorInCommonIs'>{role}</span>
          </Col>
          <Col md={2} className='text-right'>
             {(role!=='admin'&& !role) ?<>
            {t('superAdmin.manange_role_toggle')}
            <Switch disabled />
            </>
             :"" }
          </Col>

          <Col md={2} className='text-right'>
            {(role!=='admin'&&  role)?<>
            <button type='button' className='btn  changeRoleButtonorgBox  '>{t('superAdmin.manage_role_changeText')}</button>
            </>:"" }
            </Col>
          <Col md={2} className='text-right'>
            {role!=='admin' &&
            <button type='button' className='btn  deleteButtonorgBox '>{t('superAdmin.deleteRole')}</button>
            }
            </Col>
        </Row>
      </div>
    </div>
  )
}

export default RoleBox;