import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import { capitalAllFirstLetter, capitalizeFirstLetter, dateTimeEventFormat, dateTimeFormat, getImageUrl, showSingleToastSuccess, showToastSuccess } from "../../../utils/helpers";
import './validate.style.css';
import { getEventDetailService, getEvidenceListById } from "../../../services/event.services";
import { t } from "i18next";
import { showToastError } from "../../../utils/helpers";
import { changeDateFormatYYYY } from "../../../utils/helpers";
import { getEventDetailByDateService } from "../../../services/event.services";
import Moment from "react-moment";
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import moment from "moment";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { recurringTypeList, weekDaysList } from "../../../config/constants";
import RoundRadio from "../../../commonComponents/roundCheckbox/roundRadio";

import {
    Box, TextField,
    Select,
    MenuItem,
    FormControlLabel,
    InputLabel,
    FormControl, Button, OutlinedInput, ImageList
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { CalendarMonthIcon } from "../../../commonComponents/Images";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import GreenCheckBox from "../../../commonComponents/greenCheckbox/greenCheckbox";
import PhotosPopup from "../../../commonDashboardComp/photoesPopup";
import { uploadFileService } from "../../../services/fileUpload.services";
import { deleteEvidenceService, markValidatorService, submitReportService, uploadEvidenceService } from "../../../services/validatorPost.service";
import { useSelector } from "react-redux";
import { selectCurrentOrg, selectCurrentUser, selectCurrentUserRole } from "../../../Reducer/user/user.selector";
import SubmitReportPopup from "../../../commonDashboardComp/submitReportPopUp";
import ShowImagePopUp from "../../../commonDashboardComp/showImagePopUp";
import ShowVideoPopUp from "../../../commonDashboardComp/showVideoPopUp";
import EventEvidenceUpload from "../events/eventEvidenceUpload";
import PostList from "../../../commonDashboardComp/postList";
import TabNavigation from "../../../commonDashboardComp/tabNavigation";
import EventConfiguration from "../events/eventConfiguration";
import EventActivityList from "../events/eventEvidenceUpload";
import ShowPdfPopUp from "../../../commonDashboardComp/showPdfPopUp";
import PdfDocument from "../../../commonComponents/pdfDocument";
import swal from "sweetalert";

const ValidateEvent = () => {

    const role = useSelector(selectCurrentUserRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);

    let current_user = {};
    if (role === 'organization') { current_user = CURRENTORG }
    else { current_user = CURRENTUSER }


    const todayDate = new Date();
    const [validate, setValidate] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [someEventInfo, setSomeEventInfo] = useState(0);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openSubmit, setSubmitOpen] = useState(false);
    const handleSubmitopen = () => setSubmitOpen(true);
    const handleSubmitClose = () => setSubmitOpen(false);
    const [photoOpen, setPhotoOpen] = useState(false);
    const handlePhotoOpen = () => setPhotoOpen(true);
    const handlePhotoClose = () => setPhotoOpen(false);
    const [videoOpen, setVideoOpen] = useState(false);
    const handleVideoOpen = () => setVideoOpen(true);
    const handleVideoClose = () => setVideoOpen(false);
    const [pdfOpen, setPdfOpen] = useState(false);
    const handlePdfOpen = () => setPdfOpen(true);
    const handlePdfClose = () => setPdfOpen(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [evidencePerEventTime, setEvidencePerEventTime] = useState([]);
    const [date, setDate] = useState(dateTimeEventFormat(new Date()));
    const [evidenceSelectedWithdateAndTime, setEvidenceSelectedWithDateAndTime] = useState([]);
    const [selectValidate, setSeletedValidate] = useState('');
    const user = useSelector(selectCurrentUser);
    const [submitShow, setSubmitShow] = useState(false);
    const dataOfSubmitReport = { comment: "", eventId: "", impact: false, unitCompleted: "" }
    const [formsValues, setFormsValues] = useState(dataOfSubmitReport);
    const [errors, setErrors] = useState(false);
    const [imageGetData, setImageGetdata] = useState(null);
    const eventId = location?.state?.eventId;
    const [photoPopUpError, setphotoPopUpError] = useState(false);
    const [creater, setCreater] = useState(true);
    const [loading, SetLoading] = useState(false);
    const [tab, setTab] = useState(1);
    const tabs = [t("Event_Completion_Evidence"), t('Tagged_Post')]
    const [selcetdEventEvidenceId, setSelectedEventEvidenceId] = useState('');
    const [eventStartDate, setEventStartDate] = useState('');
    const [activity, setActivity] = useState(null);
    const [fndData, setFndData] = useState(null);
    const [compUnit, setCompUnit] = useState(0);
    const [newCompUnit, setNewCompUnit] = useState(0);
    const [updated, setUpdated] = useState(true);
    const [unitError, setUnitError] = useState(false);

    useEffect(() => {
        getAllEventData();
    }, [eventId, submitShow]);

    const getAllEventData = async () => {
        await getEventDetail();
        await getEventEvidenceDetail();
    }


    useEffect(() => {
        handleFinalSubmitButton();
    }, [evidencePerEventTime])

    useEffect(() => {
        if (validate) {
            setCreater((validate?.spredeId === current_user?._id) ? true : false)
            if (validate?.spredeId !== current_user?._id) {
                //Check if validator else redirect to events page
                let filter = validate?.eventValidators.map((item) => item?.userId?._id === current_user?._id);
                if (filter.length === 0) {
                    navigate('/events')
                }
            }
        }
    })

    const getEventDateTime = (data) => {
        let startDateTime = new Date(data?.startDate);
        let startHour, startMinutes;
        [startHour, startMinutes] = data?.startTime.split(':');
        startDateTime.setHours(startHour);
        startDateTime.setMinutes(startMinutes);
        return startDateTime;
    }

    const eventDateTimeRecurring = (data) => {
        let startDateTime = new Date(data);
        let startHour, startMinutes;
        [startHour, startMinutes] = validate?.startTime.split(':');
        startDateTime.setHours(startHour);
        startDateTime.setMinutes(startMinutes);
        return startDateTime;
    }

    const getEventEvidenceDetail = async () => {
        await getEvidenceListById(eventId)
            .then((resp) => {
                if (resp.status === 200) {
                    setEvidencePerEventTime(resp?.data?.data);
                    let UnitAre = 0;
                    let TotalUnitComplete = resp?.data?.data?.map((eviTotal) => {
                        UnitAre += Number(eviTotal?.unit);
                    })
                    setCompUnit(Number(UnitAre));
                    // setNewCompUnit(Number(UnitAre))
                }
            })
            .catch((error) => { console.log(error) })
    }
    const setPhoto = async (param) => {
        if (param.length !== 0) {
            setSelectedFiles([...selectedFiles, ...param]);
        }
    }

    const deleteSelectedFile = (e, i) => {
        e.preventDefault();
        let arr = selectedFiles;
        let filter = arr.filter((item, index) => { return index !== i });
        setSelectedFiles(filter)
    }

    const getEventDetail = async () => {
        await getEventDetailService(eventId)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setValidate(data?.data?.data); 
                }
                else {
                }
            })
    }
    const checkingForEvidencedet = (type, dateOfEvent) => {
        if (evidencePerEventTime?.length > 0) {
            let fnd = evidencePerEventTime?.find((a) => a.date === dateOfEvent);
            if (fnd) {
                if (fnd[type]) { return true; }
                else { return false; }
            }
            return false;
        }
        else return false;

    }

    const handleText = (e) => {
        const dataColledted = { ...formsValues };
        dataColledted[e.target.id] = e.target.value;
        setFormsValues(dataColledted);
    }
    const textValidations = () => {
        let str = formsValues?.comment;
        let newStr = '';
        for (let i = 0; i < str?.length; i++) {
            if ((str[i] >= 'A' && str[i] <= 'Z') || (str[i] >= 'a' && str[i] <= 'z') || str[i] == ' ' || str[i] == '.')
                newStr += str[i]
        }
        let val = newStr === str;
        return val;

    }
    const handleCheckbox = (e) => {
        if (e.target.checked) {
            const dataColledted = { ...formsValues };
            dataColledted[e.target.id] = e.target.checked;
            setFormsValues(dataColledted)
        }
    }

    const handleUnit = (e) => {
            let val = parseInt(e.target.value);
            let completedUnit = parseInt(compUnit); 
            const dataColledted = { ...formsValues };
            if(val > completedUnit){
                dataColledted[e.target.id] = val;
                setFormsValues(dataColledted);
                setErrors(true);
                setUnitError(true);
            }
            else{
                const dataColledted = { ...formsValues };
                dataColledted[e.target.id] = e.target.value;
                setFormsValues(dataColledted);
                setErrors(false);
                setUnitError(false);
            }
            
    }


    const handleReportSubmit = (e) => {
        //&& formsValues?.comment?.length > 15 && textValidations()
        e.preventDefault();
        if (formsValues?.comment && (formsValues?.comment?.split(" ")?.length >= 100 && formsValues?.comment?.split(" ")?.length < 500) || formsValues?.unitCompleted && parseInt(formsValues?.unitCompleted) <= parseInt(compUnit)) {
            const param = {
                comment: formsValues?.comment,
                impact: formsValues?.impact,
                eventId: eventId,
                unitCompleted: formsValues?.unitCompleted
            }
            handleSubmitClose()
            submitReportService(param)
                .then((resp) => {
                    if (resp?.status == 200) {
                        // showToastSuccess(t(`apiMessages.REPORT_SUBMIT_SUCCESS`))
                        showSingleToastSuccess(t(`apiMessages.REPORT_SUBMIT_SUCCESS`));
                        setSubmitShow(false);

                    }

                })
                .catch((error) => {
                    console.log(error);
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        else {
            setErrors(true);
            setUnitError(true);
        }
    }

    const handleFinalSubmitButton = () => {
        let arr1 = [];
        if (validate?.validatorRequired === true) {
            arr1 = evidencePerEventTime?.filter((eve) => eve?.completed && eve?.verified);
        }
        else {
            arr1 = evidencePerEventTime?.filter((eve) => eve?.completed);
        }

        if (validate?.status === 'pending' && arr1?.length === validate?.eventDates?.length) {
            setSubmitShow(true);
        }
        else if (validate?.status === 'verified') {
            setSubmitShow(false);
        }
        else {
            setSubmitShow(false);
        }
    }

    const setValueForPopUp = (data, fnd, eve) => {
        setEventStartDate(eve);
        setSelectedFiles([]);
        setFndData(fnd||null)
        let UnitAre = 0;
        let TotalUnitComplete = evidencePerEventTime?.map((eviTotal) => {
            UnitAre += Number(eviTotal?.unit);
        })
        setCompUnit(Number(UnitAre)); 
        if (new Date(getEventDateTime(data)).getTime() <= new Date().getTime()) {
            handleOpen();
            setSeletedValidate(data);
            if (fnd) {
                setSelectedEventEvidenceId(fnd?._id);
            }
        }
        else {
            showToastError(t("apiMessages.EVIDENCE_UPLOAD_ERROR"));
        }
    }

    const closeAndSubmitPhotoes = () => {
        if (selectedFiles?.length > 0 && activity) {
            try {
                SetLoading(true)
                const totalEvidence = [];
                if (selectedFiles.length > 0) {
                    selectedFiles.map((item, index) => {
                        let obj = {};
                        obj['activityId'] = activity?._id;
                        if (item.type.includes('video')) {
                            obj['attachment'] = item?.lastModified;
                            obj['attachmentType'] = 'video';
                        }
                        if (item.type.includes('image')) {
                            obj['attachment'] = item?.lastModified;
                            obj['attachmentType'] = 'image';
                        }
                        if (item.type.includes('pdf')) {
                            obj['attachment'] = item?.lastModified;
                            obj['attachmentType'] = 'application/pdf';
                        }
                        totalEvidence.push(obj);
                    })
                }

                let Params = {
                    date: eventStartDate,
                    eventId: eventId,
                    evidence: totalEvidence,
                    unit: Number(newCompUnit)
                }
                if(fndData){
                    Params = {
                        ...Params,
                        unit: Number(fndData?.unit || 0) + Number(newCompUnit) || 0
                    }
                }
                if (selcetdEventEvidenceId) {
                    Params['evidenceId'] = selcetdEventEvidenceId;
                }
                if (selectedFiles?.length > 0) {
                    uploadEvidenceService(Params)
                        .then(async (data) => {
                            if (data?.data?.status === 200) {
                                if (selectedFiles.length > 0) {
                                    let result = await uploadFiles(data?.data?.data?.insertedId);
                                }
                                // showToastSuccess(t(`apiMessages.EVIDENCE_UPLOADED_SUCCESS`));
                                getEventEvidenceDetail();
                                setOpen(false);
                                setCompUnit(0);
                                setActivity(null);
                                setNewCompUnit(0);
                                showSingleToastSuccess(t(`apiMessages.EVIDENCE_UPLOADED_SUCCESS`));
                            }
                            else {
                                showToastError(t(`apiMessages.${data?.data?.data}`))
                            }
                            SetLoading(false)
                        })
                        .catch((error) => {
                            showToastError(t(`apiMessages.${error?.data?.data}`))
                            setNewCompUnit(0);
                            SetLoading(false)
                        })
                }
            }
            catch (error) { showToastError(t(`apiMessage.SOME_THING_WENT_WRONG`)) }
        }
        else{
            setphotoPopUpError(true);
        }
    }

    const uploadFiles = async (id) => {
        await Promise.all(selectedFiles.map(async (item) => {
            if (item.type.includes('video')) {
                await uploadFileService('eventEvidence', 'event', item.lastModified, item, eventId)
            }
            if (item.type.includes('image')) {
                await uploadFileService('eventEvidence', 'event', item.lastModified, item, eventId)
            }
            if (item.type.includes('application/pdf')) {
                await uploadFileService('eventEvidence', 'event', item.lastModified, item, eventId)
            }
        }))

        return true;
    }
    const makingVerified = async (type, date, fnd) => {
        let check = validate?.activityId?.activity?.filter(a => a?.isCompleted);
        if (check?.length === validate?.activityId?.activity?.length) {
            const param = {
                "date": date,
                "status": type,
                "eventId": eventId
            }
            if (fnd) {
                if (fnd?.evidence?.length > 0)
                    param['evidenceId'] = fnd?._id;
            }
            if (date && type) {
                await markValidatorService(param)
                    .then((resp) => {
                        if (resp?.status == 200) {
                            getEventEvidenceDetail();
                        }
                    })
            }
        }
        else {
            showToastError("Activity is not marked")
        }

    }

    const makingCompleted = async (type, date, fnd) => {
        let check = validate?.activityId?.activity?.filter(a => a?.isCompleted);
        if(date && (new Date(validate?.eventDates?.length > 1 ? eventDateTimeRecurring(date): getEventDateTime(validate)).getTime() <= new Date().getTime())){
            swal({
                text: t('alerts.complete_event'),
                title: '',
                icon: "warning",
                buttons: {
                    Yes: {
                        text: t('yes'),
                        value: true,
                    },
                    No: {
                        text: t('no'),
                        value: false,
                    },
                },
            })
                .then(async (val) => {
                    if (!val) return;
                    if (check?.length === validate?.activityId?.activity?.length) {
                        const param = {
                            "date": date,
                            "status": type,
                            "eventId": eventId
                        }
                        if (fnd) {
                            if (fnd?.evidence?.length > 0){
                                param['evidenceId'] = fnd?._id;

                                if (date && type) {
                                    await markValidatorService(param)
                                        .then((resp) => {
                                            if (resp?.status == 200) {
                                                getEventEvidenceDetail();
                                            }
                                        })
                                        .catch ((e) => {
                                            if(e.status === 400) {
                                                showToastError(`apiMessages.${e?.data?.data}`)
                                            }
                                        })
                                }
                            }else {
                                showToastError(t(`apiMessages.UPLOAD_EVIDENCE_ERROR`));
                            }     
                        }
                       
                    }
                    else {
                        showToastError("Activity is not marked")
                    }
                })
        
        }
        else {
            showToastError(t(`apiMessages.EVENT_COMPLETION_ERROR`));
        }
    }

    const handleButtonSubmit = (e) => {
        e.preventDefault();

        if (validate?.activityId) {
            let check = validate?.activityId?.activity?.filter(a => a?.isCompleted);
            if (check?.length === validate?.activityId?.activity?.length) {
                handleSubmitopen();
                let UnitAre = 0;
                let TotalUnitComplete = evidencePerEventTime?.map((eviTotal) => {
                    UnitAre += eviTotal?.unit;

                })
                // setCompUnit(UnitAre);
                //    const dataColledted = { ...formsValues };
                //    dataColledted['unitCompleted'] = UnitAre;
                //   setFormsValues(dataColledted)
                setSomeEventInfo(UnitAre);


            }
            else {
                showToastError("Activity is not marked")
            }
        }

    }

    const openPhotoHandler = (id, type, param, contentType) => {
        let imageObj = { id: id, type: type, param: param, contentType: contentType };
        setImageGetdata(imageObj);
        if (contentType === 'image') {
            handlePhotoOpen();
        }
        else if (contentType === 'application/pdf') {
            handlePdfOpen();
        }
        else if (contentType === 'video') {
            handleVideoOpen();
        }
    }
    const handleCloseAllData = () => {
        handleClose();
        setSelectedFiles([]);
        setphotoPopUpError(false);

    }

    const viewEventHandler = (e) => {
        e.preventDefault();
        navigate('/view-event', { state: { eventId: eventId } })
    }
    const removeEvidence = async (e, evi) => {
        let obj = { "eventId": eventId, "evidenceId": evi?._id }
        await deleteEvidenceService(obj)
            .then((resp) => {
                if (resp?.status === 200) {
                    getEventEvidenceDetail();
                    showSingleToastSuccess(t('Image_Removed'));
                }
            })
    }
    // const handleSetValidate=async(data)=>{
    //    setValidate(data)
    // }

    // useEffect(() => {
    //     getEventDetail();
    // }, [validate, creater])

    return (<>
        <Row> {validate && <ProfileHeader
            showEventHeader={true}
            imageContainer={false}
            eventDetail={validate}
            cover={getImageUrl(validate?._id, 'event', validate?.photo)}
            buttons={
                <div className="col-md-12 banner-btn-area event-btn">
                    <button className="btn btn-secondary green" onClick={(e) => viewEventHandler(e)} >{t("button.view_event")}</button>
                </div>}
        />} </Row>

        <SectionHeader pretitle="" title={t('Event_Validation')} />
        <EventConfiguration event={validate} />
        <Row className="mobileContainer">
            <Col md={3}>
                <Row className="grid">
                    <EventActivityList creater={creater} event={validate} updated={updated} setUpdated={setUpdated} getEventDetail={getEventDetail} />
                    {/* <EventActivityList creater={creater} event={validate} updated={updated} setUpdated={setUpdated} getAllEventData={getAllEventData}/> */}
                </Row>

            </Col>

            <Col md={9}>
                <Row className="grid">
                    <div className="grid-column">
                        <div className="widget-box">
                            <h6 className="widget-box-title">{t('event_template')}</h6>
                            <div className="widget-box-content">
                                <Row className="grid">
                                    <h5 className='evidenceSubHeading'>
                                        <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab} showInput={false} />
                                        {/* <span onClick={() => setTab(1)} style={{ cursor: 'pointer' }}> Event Completion Evidence</span> <span className='highlighted' onClick={() => setTab(2)} style={{ cursor: 'pointer' }}>Tagged Post</span> */}
                                    </h5>
                                    {tab === 1 ? <>

                                        <h4> {capitalAllFirstLetter(validate?.category)}: {compUnit}</h4>
                                        {
                                            validate?.eventDates?.map((eve, index) => {
                                                const fnd = evidencePerEventTime?.find((a => a?.date == eve));
                                                return <>
                                                    <div key={index}>
                                                        <div className='eventCompletionImg'>

                                                            {fnd ? <>

                                                                {fnd?.evidence.length > 0 && fnd?.evidence.map((evi, idx) => {
                                                                    return <div className='newEventActivity' key={idx}>
                                                                        {/* <img src="/Assets/close-button.png" className="='img-fluid"  onClick={(e)=>removeEvidence(e,evi)}></img> */}
                                                                        {evi.attachmentType == 'image' ?
                                                                            <img src={getImageUrl(eventId, 'eventEvidence', evi?.attachment)} alt="" className='img-fluid' onClick={() => openPhotoHandler(eventId, 'eventEvidence', evi?.attachment, 'image')} />
                                                                        : (evi.attachmentType == 'application/pdf') ?
                                                                            <PdfDocument url={getImageUrl(eventId, 'eventEvidence', evi?.attachment)} openPhotoHandler={() => openPhotoHandler(eventId, 'eventEvidence', evi?.attachment, 'application/pdf')}/>
                                                                        : <video poster src={getImageUrl(eventId, 'eventEvidence', evi?.attachment)} alt="" className='img-fluid' onClick={() => openPhotoHandler(eventId, 'eventEvidence', evi?.attachment, 'video')} />}
                                                                        {creater && !checkingForEvidencedet('completed', eve) && <span onClick={(e) => removeEvidence(e, evi)}>{t('Remove')}</span>}
                                                                        {evi?.activityId && evi?.activityId !== '' &&
                                                                            <span className="validateActivityName"> {t('activity')} : {evi?.activityId}</span>
                                                                        }
                                                                    </div>

                                                                })}
                                                            </>
                                                                : ''}
                                                            {/* {!fnd || !fnd?.verified || !fnd?.completed ? <div className='img-flex'>
                                                            <img src='/Assets/Verification-img.png' onClick={() => { setValueForPopUp(eve) }} />
                                                        </div> : ""} */}
                                                            {creater && !checkingForEvidencedet('completed', eve) && <div className='cursorPointer d-flex align-items-center ps-3 pt-2'>
                                                                {validate?.status !== 'verified' && <div className="cms_feature_btn_area">
                                                                    <button className="add-more-btn m-0" title={t('Click_here_For_Upload_Evidence')} onClick={() => { setValueForPopUp(validate, fnd, eve) }}> <AddIcon style={{ fontSize: "xx-large" }} /></button>
                                                                </div>}

                                                            </div>}

                                                        </div>
                                                        <div className="steper">
                                                            <ul>
                                                                <li>
                                                                    <div className="steperContent">{eve && <Moment format="DD/MM/YYYY">{eve}</Moment>}</div>
                                                                </li>
                                                                <li>
                                                                    {creater && !checkingForEvidencedet('completed', eve) ?
                                                                        <div className="steperContent"><GreenCheckBox label={t('Completed')} disabled={((creater !== false) ? false : true)} checked={checkingForEvidencedet('completed', eve)} onChange={(e) => makingCompleted('completed', eve, fnd )} /></div> : ''
                                                                    }
                                                                </li>
                                                                <li>

                                                                    {
                                                                        creater && !checkingForEvidencedet('verified', eve) ? ''
                                                                            : <div className="steperContent"><GreenCheckBox label={t('verified')} disabled={creater} checked={checkingForEvidencedet('verified', eve)} onChange={(e) => makingVerified('verified', eve, fnd)} /></div>
                                                                    }
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </>
                                            })

                                        }
                                    </> : <PostList myPosts={true} eventId={eventId} />}
                                </Row>
                            </div>
                        </div>
                    </div>
                </Row>
            </Col>
        </Row>
        {
            submitShow && !creater ?

                <div className='buttonRight my-4'>
                    <button className="btn validateSubmit btn-lg float-right" type="submit" onClick={(e) => handleButtonSubmit(e)}>{t('Submit_Report')}</button>
                </div>

                :
                submitShow && creater && validate?.validatorRequired != 'true' ?
                    <div className='buttonRight my-4'>
                        <button className="btn validateSubmit btn-lg float-right" type="submit" onClick={(e) => handleButtonSubmit(e)}>{t('Complete Event')}</button>
                    </div>
                    : ''
        }
        <PhotosPopup
            photoPopClasses={"validatePhotoUpload"}
            event={validate}
            compUnit={compUnit}
            newCompUnit={newCompUnit}
            setCompUnit={setNewCompUnit}
            activity={activity}
            setActivity={setActivity}
            open={open}
            evidencePerEventTime={evidencePerEventTime}
            handleClose={handleCloseAllData}
            setPhoto={setPhoto}
            loading={loading}
            photoPopUpError={photoPopUpError}
            selectedFiles={selectedFiles}
            deleteSelectedFile={deleteSelectedFile}
            closeAndSubmitPhotoes={closeAndSubmitPhotoes}
        />
        <SubmitReportPopup
            open={openSubmit}
            handleClose={handleSubmitClose}
            handleReportSubmit={handleReportSubmit}
            formsValues={formsValues}
            errors={errors}
            eventUnitMentionAsComplete={someEventInfo}
            handleCheckbox={handleCheckbox}
            handleUnit={handleUnit}
            handleText={handleText}
            validatorRequired={validate?.validatorRequired}
            unitError={unitError}
        />
        <ShowImagePopUp
            imageGetData={imageGetData}
            open={photoOpen}
            handleClose={handlePhotoClose}

        />
        <ShowVideoPopUp
            videoGetData={imageGetData}
            open={videoOpen}
            handleClose={handleVideoClose}
        />

        <ShowPdfPopUp
            pdfGetData={imageGetData}
            open={pdfOpen}
            handleClose={handlePdfClose}
        />      

    </>
    )
}

export default ValidateEvent;