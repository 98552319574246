import React from "react";
import UserLayout from "../../components/dashboard-layout";
import OrganizationProfile from "../../components/dashboard/profile/organizationProfile";
import HomeBanner from "../../components/homepage/banner";
import { getObjectFromStore } from "../../storage/Storage";
const OrganizationPage = () => {
    const layout=()=>{
        if(getObjectFromStore('token')===null){
            return<><HomeBanner onlyMenu={true}/><OrganizationProfile/></> 
         }
        else {
            return  <UserLayout>
            <OrganizationProfile/>
        </UserLayout>;
        }

    }
    return layout();
}
export default OrganizationPage;