import i18next from "i18next";
import { useState } from "react";
import './switchLanguage.style.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../Reducer/user/user.selector";
import { LanguageList } from "../../config/constants";
const SwitchLanguage = () => {
    const defaultLanguage = i18next?.language?.split('-')[0];
    const find = LanguageList?.find(a => a.value === defaultLanguage);
    const current_user = useSelector(selectCurrentUser)

    const [language, setLanguage] = useState((find?.name) ? find : LanguageList[0])
    return (<>{current_user === null && <div className='langParent'>
        <NavDropdown title={language.name} id="basic-nav-dropdown" className="switch_dd" >
            {/* <NavDropdown title={find?.name} id="basic-nav-dropdown" className="switch_dd" > */}
            {LanguageList.map((item, index) =>
                <NavDropdown.Item
                    key={index}
                    value={item?.value}
                    onClick={(e) => {
                        i18next.changeLanguage(item?.value); setLanguage(item);
                    }}>{item?.name}
                </NavDropdown.Item>)}
        </NavDropdown>
    </div>}</>)
}

export default SwitchLanguage;