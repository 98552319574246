import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Col, Row } from "react-bootstrap";
// import {
//     HowItWorksImg1,
//     HowItWorksImg2,
//     HowItWorksImg3,
//     HowItWorksDivImg,
// } from "../../commonComponents/Images";
import { getImageUrl } from "../../utils/helpers";
import Slider from "react-slick";
const HowItWorks = ({ cmsData }) => {
    const { t } = useTranslation();
    const howIt_slider_settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        arrows: false,
        slidesToScroll: 1,
        className: 'slides',
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <Container fluid className="howitworks-div">
            <div className="howitworks-row1">
                <h1 className="heading"> {t('homepage.howItWorks.title')} </h1>
            </div>

            <Container className="howItWord-container">
                <span className="how-it-work-bg-tl"></span>
                <div className="d-flex flex-column flex-md-row" >
                    {/* <Slider {...howIt_slider_settings}> */}
                        {cmsData?.howItWorks?.length > 0 && cmsData?.howItWorks?.map((item, index) => (

                            <div className="howitworks-row2" key={index}>
                                <img src={getImageUrl(cmsData?._id, 'cms', item?.image)} alt="HowItWorksImg" className="img" />
                                <p className="heading"> {item?.Title} </p>
                                <p className="desc">{item?.Text}</p>
                            </div>

                        ))}
                    {/* </Slider> */}
                </div>

                <span className="how-it-work-bg-rb"></span>
            </Container>



        </Container >
    );
}

export default HowItWorks;