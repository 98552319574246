import instance, {instanceOrg} from "../AppInterceptor";
import ApiConfig from "../config/ApiConfig";
import { getObjectFromStore } from "../storage/Storage";

export const CreatePostService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.createPost, payload);
    }
    else{
        return instance.post(ApiConfig.createPost, payload);
    }
}

export const GetMyPostsService = (page, limit) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.getMyPosts+'?page='+page+'&limit='+limit);
    }
    else{
        return instance.get(ApiConfig.getMyPosts+'?page='+page+'&limit='+limit);
    }
}

export const GetAllPostsService = (page, limit) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.getAllPosts+'?page='+page+'&limit='+limit);
    }
    else{
        return instance.get(ApiConfig.getAllPosts+'?page='+page+'&limit='+limit);
    }
}

export const GetPublicInterestPostsService = (page, limit) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.publicInterestFeeds+'?page='+page+'&limit='+limit);
    }
    else{
        return instance.get(ApiConfig.publicInterestFeeds+'?page='+page+'&limit='+limit);
    }
}

export const getUserFeeds = (page, limit, userId) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.userOrgFeeds+'?page='+page+'&limit='+limit+'&userId='+userId);
    }
    else{
        return instance.get(ApiConfig.userOrgFeeds+'?page='+page+'&limit='+limit+'&userId='+userId);
    }
}

export const getOrgFeeds = (page, limit, orgId) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.userOrgFeeds+'?page='+page+'&limit='+limit+'&orgId='+orgId);
    }
    else{
        return instance.get(ApiConfig.userOrgFeeds+'?page='+page+'&limit='+limit+'&orgId='+orgId);
    }
}

export const GetPostsWithTargetEvenetsService = (page, limit, targetId, eventId,ordID) => {
    let url = `${ApiConfig.orgReviewFeeds}?page=${page}&limit=${limit}`;
    if(targetId){ url += `&targetId=${targetId}`}
    if(eventId){ url += `&eventId=${eventId}`}
    if(ordID){ url += `&organizationId=${ordID}`}
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}

export const hidePostByReviewerService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.hidePostByReviewer, payload);
    }
    else{
        return instance.post(ApiConfig.hidePostByReviewer, payload);
    }
}

export const GetPostByIdService = (id) =>{
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.getPost+'?postId='+id);
    }
    else{
        return instance.get(ApiConfig.getPost+'?postId='+id);
    }
}

export const GetMyMemoriesService = async () =>{
    const response = await instance.get(`${ApiConfig.getMyMemories}`,);
    if (response?.data?.status === 200) {
    return response.data || '';
  }
}

export const GetSelectedPostByIdService = (id) =>{
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.getSelectedPost+'?postId='+id);
    }
    else{
        return instance.get(ApiConfig.getSelectedPost+'?postId='+id);
    }
}

export const CreatePostCommentService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.createComment, payload);
    }
    else{
        return instance.post(ApiConfig.createComment, payload);
    }
}

export const ReplyOnCommentService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.commentReply, payload);
    }
    else{
        return instance.post(ApiConfig.commentReply, payload);
    }
}

export const DeletePostService = (postId) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.delete(ApiConfig.deletePost+'?postId='+postId);
    }
    else{
        return instance.delete(ApiConfig.deletePost+'?postId='+postId);
    }
}

export const DeleteCommentService = (postId, commentId) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.delete(ApiConfig.deleteComment+'?postId='+postId+'&commentId='+commentId);
    }
    else{
        return instance.delete(ApiConfig.deleteComment+'?postId='+postId+'&commentId='+commentId);
    }
}

export const DeleteCommentReplyService = (commentId, replyId) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.delete(ApiConfig.deleteReply+'?commentId='+commentId+'&replyId='+replyId);
    }
    else{
        return instance.delete(ApiConfig.deleteReply+'?commentId='+commentId+'&replyId='+replyId);
    }
}

export const PostReactionService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.postReacttion, payload);
    }
    else{
        return instance.post(ApiConfig.postReacttion, payload);
    }
}

export const CommentReactionService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.commentReacttion, payload);
    }
    else{
        return instance.post(ApiConfig.commentReacttion, payload);
    }
}

export const ReplyReactionService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.replyReacttion, payload);
    }
    else{
        return instance.post(ApiConfig.replyReacttion, payload);
    }
}

export const EditPostService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.editPost, payload);
    }
    else{
        return instance.post(ApiConfig.editPost, payload);
    }
}

export const EditCommentService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.editComment, payload);
    }
    else{
        return instance.post(ApiConfig.editComment, payload);
    }
}

export const EditReplyService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.editReply, payload);
    }
    else{
        return instance.post(ApiConfig.editReply, payload);
    }
}

export const HidePostService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.hidePost, payload);
    }
    else{
        return instance.post(ApiConfig.hidePost, payload);
    }
}

export const ReportPostService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.reportPost, payload);
    }
    else{
        return instance.post(ApiConfig.reportPost, payload);
    }
}

export const FeaturedPostService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.featurePost, payload);
    }
    else{
        return instance.post(ApiConfig.featurePost, payload);
    }
}

export const eventPostsService = (page, limit,eventId) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.eventPosts+'?page='+page+'&limit='+limit+'&eventId='+eventId);
    }
    else{
        return instance.get(ApiConfig.eventPosts+'?page='+page+'&limit='+limit+'&eventId='+eventId);
    }
}

export const externalLinkDataService = (url) => {

    return instance.get(ApiConfig.getLinkData+'?url='+url);

}

export const sharePostsService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.sharePost, payload);
    }
    else{
        return instance.post(ApiConfig.sharePost, payload);
    }
}

export const whoCanCommentOnPostService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.whoCanCommentPost, payload);
    }
    else{
        return instance.post(ApiConfig.whoCanCommentPost, payload);
    }
}

export const postLikesListService = (page, limit, postId, type) => {
    let url = ApiConfig.postLikeList+'?page='+page+'&limit='+limit+'&postId='+postId;
    if(type !== '')
    {
        url += '&type='+type;
    }
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}

export const getReportedPostSercice=(page,limit,searchText)=>{
    let url=ApiConfig.getReportPost+"?page="+page+'&limit='+limit;
    if(searchText)url+='&searchText='+searchText;
    return instance.get(url);
}

export const getTagSuggestionsService = (searchText) =>{
    let url = `${ApiConfig.getTagSuggestions}?searchText=${searchText}&searchType=userName&page=0&limit=10`;
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}

export const voxBoxProfileService=(type,id)=>{
    let url=`${ApiConfig.getVoxboxProfile}?id=${id}&role=${type}`;
    if(getObjectFromStore('role')==='organization'){
        return instanceOrg.get(url)
    }
    else {
        return instance.get(url);
    }

}