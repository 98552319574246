import React,{useState, useRef} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AvatarImgBox from "../commonComponents/AvatarImgBox/avatarImgBox";
import { AvatarImg } from "../commonComponents/Images";
import { getTimeGap , getImageUrl, allowOnlyLettersNumbers, showToastSuccess, AlertConfirmation, showToastError, showSingleToastSuccess} from "../utils/helpers";
import Video from "../commonComponents/video";
import PostLikeListPopup from "./postLikeListPopup";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../Reducer/user/user.selector";
import { hidePostByReviewerService, DeletePostService } from "../services/post.services";
import swal from 'sweetalert';
import RoundCheckbox from "../commonComponents/roundCheckbox";
import { Row , Col,input } from "react-bootstrap";
import './commonStyle.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TextField,Button,Switch,IconButton} from "@mui/material";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SearchIcon from '@mui/icons-material/Search';

import FavoriteIcon from '@mui/icons-material/Favorite';

const ReviewBox = ({post}) => {
   
    const {t} = useTranslation();
    const role = useSelector(selectCurrentUserRole)
    const [postData, setPostData] = useState(post);
    const [viewMore, setViewMore] = useState(false);
    const [showComments, setShowComments] = useState(false)
    const commentWrapper = useRef(null)
    const [show, setShow] = useState(true);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const [likeListPopup, setLikeListPopup] = useState(false);
    const handleLikeListPopupShow = () => { setLikeListPopup(true)}
    const handlelikeListPopupClose = () => setLikeListPopup(false)

    const hidePostHandler = (e, status) => {
      e.preventDefault();
        swal({
            text: (!status)?t('alerts.hide_post'):t('alerts.unhide_post'),
            title: '',
            icon: "warning",
            buttons: {
              Yes: {
                text:t('yes'),
                value: true,
              },
              No: {
                text: t('no'),
                value: false,
              },
            },
          })
          .then(async (val) => {
            if(!val) return;
            try{

                let params = {
                  postId: postData?._id, 
                  eventId: postData?.eventId, 
                  targetId: postData?.targetId, 
                  visibility:  status
                }
                let response = await hidePostByReviewerService(params);
                setPostData({...postData, visibility: status});
                showSingleToastSuccess(t('apiMessages.POST_HIDE_STATUS_UPDATED'))
            }
            catch(error){
                console.log(error)
            }
        });
    }

    const DeletePostHandler = (e) => {
      e.preventDefault();
        swal({
            text: t('alerts.delete_post'),
            title: '',
            icon: "warning",
            buttons: {
              Yes: {
                text:t('yes'),
                value: true,
              },
              No: {
                text: t('no'),
                value: false,
              },
            },
          })
          .then(async (val) => {
            if(!val) return;
            try{
                let response = await DeletePostService(postData?._id);
                setShow(false);
                showSingleToastSuccess(t('apiMessages.POST_DELETED_SUCCESS'))
            }
            catch(error){
                console.log(error)
            }
        });
    }

    const getOverLay = () => {
      return (<div className="overlay"> 
                  <div className="text">
                      + {(postData?.image?.length + postData?.video?.length) -5} 
                  </div>  
              </div>)
    }

  
   
    return (
      <>
          {show && <div className="bg-white borderRadius">
            <div className="row align-items-center">
                        <div className="col-lg-4 postReviewProfileInfo">
                            <div className="">
                                <AvatarImgBox
                                avatarClasses = {"profileAvatarBox"}
                                img={(postData?.createdBy?.profilePicture !== '') ? getImageUrl(postData?.createdBy?._id, 'userProfile', postData?.createdBy?.profilePicture):AvatarImg}
                                />
                            </div>
                            <div className="target-box">
                                <h5 className="">{(postData?.createdBy?.name) ? `${postData?.createdBy?.name}` :`${postData?.createdBy?.firstName} ${postData?.createdBy?.lastName}`}</h5>
                                <p>{getTimeGap(postData.createdAt)}</p>
                            </div>
                        </div>
                    <div className="col-lg-4">
                        <div className="information-line target-para post_rev_box_desc userInformation">
                            <p className="">
                              {postData?.post.substring(0, 30) + '...'}
                              <span className="postReactionsBox">
                            <div className="content-action">
                                <div className="meta-line" onClick={()=>handleLikeListPopupShow()}  style={{cursor:"pointer"}}>
                                <div className="meta-line-list reaction-item-list">
                                    <div className="reaction-item" style={{position: 'relative'}}>
                                        <img className="reaction-image reaction-item-dropdown-trigger" src="/img/reaction/wow.png" alt="reaction-wow"/>
                                    </div>

                                    <div className="reaction-item" style={{position: 'relative'}}>
                                        <img className="reaction-image reaction-item-dropdown-trigger" src="/img/reaction/like.png" alt="reaction-like"/>
                                    </div>
                                    <div>
                                    <p className="mx-2 my-1">{postData.likes.length}</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </span>
                        </p>
                            
                    </div>
                   </div>
                    <div className="col-lg-4 col-md-12 post_rev_viewMore">
                       {!viewMore && <Link to="#" className="target-btn view-btn" onClick={(e) => { e.preventDefault(); setViewMore(true) }}>{t('button.view_more')}</Link>}
                    </div>
            </div>

            {viewMore && <>
                <div className="">
                    <div className="row">
                            <div className="col-lg-2 col-xl-3 col-md-2"></div>
                            <div className="col-12 col-md-7 col-lg-6">
                                <div className="widget-box-status">
                                  <div className="widget-box-status-content">
                                    <div className={postData?.sharedPostId?._id ? 'shared m-2': 'm-2'}>
                                        <p className="widget-box-status-text">{postData?.post}</p>
                                        {(postData.image !== null || postData.video !== null) &&
                                            <div className="voxbox_post_area_" onClick={()=>handleOpen()}>
                                                <div className="voxbox_post_upline">
                                                    {(postData?.image !== null && postData?.image?.length>0) && postData?.image?.slice(0,2).map((item, index)=>{
                                                        return (
                                                        <figure key={index}>
                                                            {postData?.sharedPostId?._id ?
                                                                <img src={getImageUrl(postData?.sharedPostId?._id, 'voxBoxImage', item)} alt="" />
                                                            :
                                                                <img src={getImageUrl(postData._id, 'voxBoxImage', item)} alt="" />
                                                            }                                
                                                        </figure>)
                                                    })}
                                                    { ((postData.image === null || postData?.image?.length===0) &&  postData.video !== null && postData?.video?.length > 0) &&
                                                        postData.video.slice(0, 2).map((item, index)=>{
                                                            return (
                                                                <figure key={index}>
                                                                    {postData?.sharedPostId?._id ?
                                                                        <Video src={getImageUrl(postData?.sharedPostId?._id, 'voxBoxVideo', item)}/>
                                                                    :
                                                                        <Video src={getImageUrl(postData?._id, 'voxBoxVideo', item)}/>
                                                                    }
                                                                </figure>)
                                                        })
                                                    }
                                                </div>
                                                {((postData?.image !== null && postData?.image?.length > 2) || (postData?.video !== null && postData?.video?.length > 0)) &&
                                                        <div className="voxbox_post_bottomline">
                                                            {(postData?.image !== null && postData?.image?.length>2) && postData?.image?.slice(2,5).map((item, index)=>{
                                                                return (
                                                                <figure key={index}>
                                                                    {postData?.sharedPostId?._id ?
                                                                        <img src={getImageUrl(postData?.sharedPostId?._id, 'voxBoxImage', item)} alt="" />
                                                                    :
                                                                        <img src={getImageUrl(postData._id, 'voxBoxImage', item)} alt="" />
                                                                    }
                                                                    {index === 2 && (postData?.image?.length + postData?.video?.length) > 5 && getOverLay() }     
                                                                </figure>)
                                                            })}
                                                            {((postData?.image !== null && postData?.image?.length<5 && postData?.image?.length !== 0) && postData.video !== null && postData?.video?.length > 0) &&
                                                                postData?.video?.slice(0,5-postData?.image?.length).map((item, index)=>{
                                                                    return (
                                                                    <figure key={index}>
                                                                        {postData?.sharedPostId?._id ?
                                                                            <Video src={getImageUrl(postData?.sharedPostId?._id, 'voxBoxVideo', item)}/>
                                                                        :
                                                                            <Video src={getImageUrl(postData?._id, 'voxBoxVideo', item)}/>
                                                                        } 

                                                                        {index === 2 && (postData?.image?.length + postData?.video?.length) > 5 && getOverLay() }                                                
                                                                    </figure>)
                                                                })
                                                            }
                                                            { ((postData.image === null || postData?.image?.length===0) && postData.video !== null && postData?.video?.length > 2) &&
                                                                postData.video.slice(2,5).map((item, index)=>{
                                                                    return (
                                                                        <figure key={index}>
                                                                            {postData?.sharedPostId?._id ?
                                                                                <Video src={getImageUrl(postData?.sharedPostId?._id, 'voxBoxVideo', item)}/>
                                                                            :
                                                                                <Video src={getImageUrl(postData?._id, 'voxBoxVideo', item)}/>
                                                                            }
                                                                            {index === 2 && (postData?.image?.length + postData?.video?.length) > 5 && getOverLay() } 
                                                                        </figure>)
                                                                })
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        }

                                        {postData?.sharedPostId?._id && 
                                            <div className="user-status customPolygon">
                                                <Link className="user-status-avatar" to="#">
                                                    <div className="user-short-description-avatar user-avatar profilePage">
                                                        <AvatarImgBox
                                                        img={(postData?.sharedPostId?.createrDetail?.profilePicture !== '') ? getImageUrl(postData?.sharedPostId?.createrDetail?._id, 'userProfile', postData?.sharedPostId?.createrDetail?.profilePicture):AvatarImg}
                                                        />
                                                    </div>
                                                </Link>
                                    
                                                <p className="user-status-title medium">
                                                    <Link className="bold" to="#">
                                                        {(postData?.sharedPostId?.createrDetail?.name) ? `${postData?.sharedPostId?.createrDetail?.name}` :`${postData?.sharedPostId?.createrDetail?.firstName} ${postData?.sharedPostId?.createrDetail?.lastName}`}
                                                    </Link>
                                                </p>

                                                <p className="user-status-text small">{getTimeGap(postData?.sharedPostId?.createdAt)}</p>
                                            </div>
                                        }
                                    </div>
                                          
                                    <div className="content-actions post_rev_likeArea">
                                      <div className="content-action">
                                          <div className="meta-line" onClick={()=>handleLikeListPopupShow()}  style={{cursor:"pointer"}}>
                                          <div className="meta-line-list reaction-item-list">
                                              <div className="reaction-item" style={{position: 'relative'}}>
                                                  <img className="reaction-image reaction-item-dropdown-trigger" src="/img/reaction/wow.png" alt="reaction-wow"/>
                                              </div>

                                              <div className="reaction-item" style={{position: 'relative'}}>
                                                  <img className="reaction-image reaction-item-dropdown-trigger" src="/img/reaction/like.png" alt="reaction-like"/>
                                              </div>
                                              <div>
                                              <p className="mx-2 my-1">{postData.likes.length}</p>
                                              </div>
                                          </div>
                                          </div>
                                      </div>

                                      <div className="content-action">
                                          <div className="meta-line" onClick={()=>setShowComments(!showComments)} style={{cursor:"pointer"}}>
                                              <p className="meta-line-link">{postData.comments.length} {t('comments')}</p>
                                          </div>

                                          {/* <div className="meta-line">
                                              <p className="meta-line-text">0 {t('share')}</p>
                                          </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {showComments && <div className="widget-box-status" ref={commentWrapper}>
                                    <div className="commentListBox">
                                        {postData?.comments.length > 0 && postData?.comments.map((commentItem, i) => {
                                            return (
                                                <div key={i}>
                                                    <Row>
                                                        <>
                                                            <div className="d-flex">
                                                                <div className="cmt_profile" >
                                                                <AvatarImgBox
                                                                img={(commentItem?.profilePicture !== '') ? getImageUrl(commentItem?.userId, 'userProfile', commentItem?.profilePicture) : AvatarImg}
                                                                />
                                                                </div>
                                                                <div>
                                                                <p>{commentItem?.name ? commentItem?.name : `${commentItem?.firstName} ${commentItem?.lastName}`}</p>
                                                                <p>{commentItem?.comment}</p>
                                                                {commentItem?.updatedAt && <span className="updatedAtText">{t('edited')}</span>}
                                                                <span>{getTimeGap(commentItem?.createdAt)}</span>
                                                                </div>
                                                            </div>
                                                            {/* <div className="reply-delete-area">
                                                                
                                                                <button data-bs-toggle="collapse"
                                                                data-bs-target={`#collapseComment_${postData?._id}_${commentItem?._id}`}
                                                                aria-expanded="false"
                                                                aria-controls={`collapseComment_${postData?._id}_${commentItem?._id}`}>{t('reply')}</button>
                                                                </div> */}

                                                            {/* Show Image or video if any in comment */}
                                                                {(commentItem?.image!== null && commentItem?.image.length > 0) && commentItem?.image.map((imgItem, imgIdx) => {
                                                                        return (<Col md={3} className="" key={imgIdx}>
                                                                            <div className="photo-preview">
                                                                                <img src={getImageUrl(postData._id, 'voxBoxImage', imgItem)} alt=""/>
                                                                            </div>
                                                                        </Col>)
                                                                })}

                                                                {(commentItem?.video!== null && commentItem?.video.length > 0) && commentItem?.video.map((imgItem, videoIdx) => {
                                                                        return (<Col md={3} className="" key={videoIdx}>
                                                                            <div className="photo-preview">
                                                                                <video poster src={getImageUrl(postData._id, 'voxBoxVideo', imgItem)} alt=""/>
                                                                            </div>
                                                                        </Col>)
                                                                })}
                                                            {/* ************************************** */}
                                                        </>
                                                    </Row>
                                                    {/* <div className="collapse cmt_reply_area" id={`collapseComment_${postData?._id}_${commentItem?._id}`}>
                                                    {commentItem?.repDetailedComments?.length > 0 && commentItem?.repDetailedComments?.map((replyItem, idx)=>{
                                                        return(<Row key={idx}>
                                                            <>
                                                              
                                                                    <div className="d-flex align-items-center cmt_reply_area">
                                                                        <div className="cmt_profile" >
                                                                            <AvatarImgBox
                                                                            img={(replyItem?.profilePicture !== '') ? getImageUrl(replyItem?.userId, 'userProfile', replyItem?.profilePicture): AvatarImg}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <p>{replyItem?.name ? replyItem?.name : `${replyItem?.firstName} ${replyItem?.lastName}`}</p>
                                                                            <p>{replyItem?.commentReply}</p>
                                                                            {replyItem?.updatedAt && <span className="updatedAtText">{t('edited')}</span>}
                                                                            <span>{getTimeGap(replyItem?.createdAt)}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="reply-delete-area">
                                                                        
                                                                    </div>

                                                                        {(replyItem?.image!== null && replyItem?.image.length > 0) && replyItem?.image.map((imgItem, imgIdx) => {
                                                                                return (<><Col md={2}></Col><Col md={3} className="" key={imgIdx}>
                                                                                    <div className="photo-preview">
                                                                                        <img src={getImageUrl(postData._id, 'voxBoxImage', imgItem)} alt=""/>
                                                                                    </div>
                                                                                </Col></>)
                                                                        })}

                                                                        {(replyItem?.video!== null && replyItem?.video.length > 0) && replyItem?.video.map((imgItem, videoIdx) => {
                                                                                return (<><Col md={2}></Col><Col md={3} className="" key={videoIdx}>
                                                                                    <div className="photo-preview">
                                                                                        <video poster src={getImageUrl(postData._id, 'voxBoxVideo', imgItem)} alt=""/>
                                                                                    </div>
                                                                                </Col></>)
                                                                        })}
                                                            </>
                                                        </Row>)
                                                    })}
                                                    </div> */}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>}
                            </div>

                            <div className="col-12 col-md-3 d-md-flex align-items-md-end">
                                    <div className="open_target_inner_right_box">
                                        <div className="buttons-area post_rev_main_btn_area postReviewBtnBox">
                                                <button to="#" className="button target-btn view-less-btn" onClick={(e) => { e.preventDefault(); setViewMore(false) }}>{t('button.view_less')}</button>
                                                <button to="#" onClick={(e) => hidePostHandler(e, !postData?.visibility)} className="button target-btn edit-btn ">
                                                    {postData?.visibility ? t('button.Hide') : t('button.unHide')}
                                                </button>
                                                {role !== 'organization' && <button to="#" onClick={(e) => DeletePostHandler(e)} className="button target-btn delete-btn">{t('button.delete')}</button>}
                                        </div>
                                    </div>

                            </div>
                    </div>
                </div>
            </>}

            {postData.likes.length > 0 && 
              <PostLikeListPopup open={likeListPopup} handleClose={handlelikeListPopupClose} postId={postData?._id} />
            }
          </div>}
      </>
      )
}

export default ReviewBox;