import React, { useState } from "react";
import Popup from "../commonComponents/popup";
import { getImageUrl } from "../utils/helpers";
const ShowImagePopUp= ({open, handleClose,imageGetData,imageShow}) => {
    return(
        <Popup open={open}  handleClose={ handleClose} >
         {imageGetData && <>{
            imageGetData?.contentType==='image'?
            <img src={getImageUrl(imageGetData?.id, imageGetData?.type,imageGetData?.param)} alt="photo-preview-10" className='img-fluid' />
            :
            <video poster src={getImageUrl(imageGetData?.id, imageGetData?.type,imageGetData?.param)} alt="" className='img-fluid' />
             }
            </>
         
         } 
         {imageShow &&
          <img src={imageShow} />
         }     
        </Popup>
    )
}

export default ShowImagePopUp;