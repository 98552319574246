import React from "react";
import UserLayout from "../../components/dashboard-layout";
import ManageRoles from "../../components/dashboard/superAdmin/manageRoles";

const ManageRolesPage = () => {
    return <UserLayout>
                <ManageRoles/>
            </UserLayout>;
}

export default ManageRolesPage;