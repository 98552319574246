import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  showSingleToastSuccess,
  showToastError,
} from '../../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../../Reducer/user/user.selector';
import { deleteUserAccountService } from '../../../../services/adminManage.services';
import swal from 'sweetalert';
import DownloadProfileDialogueBox from './downloadProfileDialogueBox';
import GreenCheckBox from '../../../../commonComponents/greenCheckbox/greenCheckbox';
import Popup from '../../../../commonComponents/popup';
const AcceptanceBlock = ({ policyStatus }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const current_user = useSelector(selectCurrentUser);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rejectionModalOpen, setRejectionModalOpen] = useState(false);
  const [currentPolicyStatus, setCurrentPolicyStatus] = useState('');
  useEffect(() => {
    setCurrentPolicyStatus(policyStatus);
  }, [policyStatus]);
  const rejectionModalClose = () => {
    setRejectionModalOpen(false);
    setCurrentPolicyStatus(true);
  };

  const handleDownloadPopupClose = () => {
    handleClose();
    deleteAccountHandler();
  };

  const downloadProfileDetails = (e) => {
    e.preventDefault();

    swal({
      text: t('alerts.download_profile_prompt'),
      title: '',
      icon: 'warning',
      buttons: {
        Yes: {
          text: t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    }).then(async (val) => {
      if (val) {
        handleOpen();
      } else {
        swal({
          text: t('alerts.delete_account'),
          title: '',
          icon: 'warning',
          buttons: {
            Yes: {
              text: t('yes'),
              value: true,
            },
            No: {
              text: t('no'),
              value: false,
            },
          },
        }).then(async (val) => {
          if (!val) return;

          try {
            deleteUserAccountService(current_user?._id)
              .then((res) => {
                if (res?.data?.status === 200) {
                  showSingleToastSuccess(
                    t(`apiMessages.ACCOUNT_DELETE_SUCCESS`)
                  );
                  navigate('/logout');
                } else {
                  showToastError(t('errors.some_error'));
                }
              })
              .catch((error) => {
                showToastError(t('errors.some_error'));
              });
          } catch (error) {
            console.log(error);
          }
        });
      }
    });
  };

  const deleteAccountHandler = () => {
    swal({
      text: t('alerts.delete_account'),
      title: '',
      icon: 'warning',
      buttons: {
        Yes: {
          text: t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    }).then(async (val) => {
      if (!val) return;

      try {
        deleteUserAccountService(current_user?._id)
          .then((res) => {
            if (res?.data?.status === 200) {
              showSingleToastSuccess(t(`apiMessages.ACCOUNT_DELETE_SUCCESS`));
              navigate('/logout');
            } else {
              showToastError(t('errors.some_error'));
            }
          })
          .catch((error) => {
            showToastError(t('errors.some_error'));
          });
      } catch (error) {
        console.log(error);
      }
    });
  };
  const acceptanceChangeHandler = (val) => {
    if (val !== true) {
      setRejectionModalOpen(true);
    }
  };
  return (
    <>
      <Row className="grid deleteAccountComp">
        <div className="grid-column">
          <div className="widget-box">
            <p className="widget-box-title">{t('accepted_conditions')}</p>
            <div className="widget-box-content">
              <Row>
                <Col md={8}>
                  <GreenCheckBox
                    checked={currentPolicyStatus}
                    onChange={(e) => {
                      acceptanceChangeHandler(e.target.checked);
                    }}
                    label={
                      <>
                        I accept to the{' '}
                        <a
                          style={{ color: '#a9ca4b' }}
                          href="/termsandconditions"
                        >
                          terms and conditions
                        </a>{' '}
                        as well as{' '}
                        <a style={{ color: '#a9ca4b' }} href="/privacypolicy">
                          privacy policy
                        </a>{' '}
                        of Sprede.
                      </>
                    }
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>
      <Popup open={rejectionModalOpen} handleClose={rejectionModalClose}>
        <h2>Rejection of Terms and conditions and privacy policy</h2>
        <p className="mt-3">
          By unchecking the option you deny to the terms and conditions as well
          as privacy policy conditions of the platform, hence you cannot/want-to
          use the platform and as a result your account will be deleted.
        </p>
        <button
          className="btn mt-3 mx-2 bg-danger"
          onClick={downloadProfileDetails}
        >
          Confirm and Delete Account
        </button>
        <button className="btn bg-gray mt-3" onClick={rejectionModalClose}>
          Cancel
        </button>
      </Popup>
      <DownloadProfileDialogueBox
        open={open}
        handleClose={handleDownloadPopupClose}
      />
    </>
  );
};

export default AcceptanceBlock;
