import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import dayjs from 'dayjs';
import { CalendarMonthIcon } from "../../../commonComponents/Images";
import moment from "moment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { fromToDateValidator } from "../../../utils/formatValidator";
import { CountriesList } from "../../../config/constants";
import { Country, State, City } from 'country-state-city';
import {
    Box,
    TextField,
    Stack,
    Switch,
    Checkbox,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { useEffect } from "react";
import { getAllOrgsService, CheckOrGetOrgService } from "../../../services/onBoarding.services";
import { DesignationList } from "../../../config/constants";
import { allowOnlyLetters, showToastError } from "../../../utils/helpers";
import GreenCheckBox from "../../../commonComponents/greenCheckbox/greenCheckbox";
const EmploymentInfo = ({ editEmpDetails, addOrgDetail, deleteEmpDetails, skipClick, backClick, formValues, setFormValues, saveUserDetails, editUserEmpAddDetails }) => {
    const { t } = useTranslation();
    const initial_emp = {
        organizationId: '',
        designation: '',
        startYear: '',
        endYear: '',
        organizationName: '',
        isCurrentlyWorking: false,
        address: { city: '', country: '' }
    };

    const [empFormValues, setEmpFormValues] = useState((formValues.organization.length > 0) ?
        formValues.organization :
        [initial_emp]);
    const newCountryList = [];

    Country.getAllCountries()?.filter((a) => {
        newCountryList.push(a?.name);
        return a?.name;
    })

    const cityListSamp = [];
    empFormValues?.map((a) => {
        let cityArr = [];
        let code = Country.getAllCountries()?.find(b => b?.name === a?.address?.country);
        City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
            if(cityArr.indexOf(a?.name) === -1){ cityArr.push(a?.name); }    
        });
        cityListSamp.push(cityArr);    
    });
    const [cityList,setCityList]=useState(cityListSamp);
    const [showWorking, setShowWorking] = useState(true);
    useEffect(()=>{
        setEmpFormValues((formValues.organization.length > 0) ?
            formValues.organization :
            [initial_emp])
    }, [formValues])


    useEffect(() => {
        setShowWorking(true);
        empFormValues.map((item) => {
            if (item.isCurrentlyWorking === true) {
                setShowWorking(false); return;
            }
        })
    }, [empFormValues]);

    const addFormFields = (e) => {
        e.preventDefault()
        setEmpFormValues([...empFormValues, initial_emp]);
        let cityArrCopy=[...cityList];
        cityArrCopy.push([]);
        setCityList(cityArrCopy);
        //setFormValues({ ...formValues, organization: empFormValues });

    }
    const [orgData, setOrgData] = useState([]);
    const [orgList, setOrgList] = useState([]);
    useEffect(() => {
        getOrgList();
    }, [])

    const getOrgList = async () => {
        getAllOrgsService()
            .then((data) => {
                if (data?.data?.status === 200) {
                    let OrgList = [];
                    data?.data?.data.map((item) => {
                        OrgList.push(item?.name);
                    })
                    setOrgData(OrgList);
                    setOrgList(data?.data?.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleChange = (i, e) => {
        let newFormValues = [...empFormValues];
        if (e.target.name === 'city') {
            newFormValues[i]['address'] = {
                'city': allowOnlyLetters(e.target.value),
                'country': newFormValues[i]['address']['country']
            }
        }
        else if (e.target.name === 'country') {
            newFormValues[i]['address'] = {
                'city': newFormValues[i]['address']['city'],
                'country': e.target.value
            }
        }
        else {
            newFormValues[i][e.target.name] = e.target.value;
        }

        setEmpFormValues(newFormValues);
        setFormValues({ ...formValues, organization: empFormValues })
    }

    const handleChangeVal = (i, name, val) => {
        let newFormValues = [...empFormValues];
        newFormValues[i][name] = val;
        setEmpFormValues(newFormValues);
        setFormValues({ ...formValues, organization: empFormValues })
    }

    const handleChangeDesignation = (i, name, val, reason) => {
        var selectedValue = (reason == 'input') ? val : val?.value;
        let newFormValues = [...empFormValues];
        if(selectedValue){
            newFormValues[i][name] = selectedValue;
            setEmpFormValues(newFormValues);
            setFormValues({ ...formValues, organization: empFormValues })
        }
        
        if(reason == "clear"){ 
            newFormValues[i][name] = selectedValue;
            setEmpFormValues(newFormValues);
            setFormValues({ ...formValues, organization: empFormValues })
        }
    }

    const handleCountryChange = (i, val) => {
        let newFormValues = [...empFormValues];
        let cityListCopy=[...cityList];
        let code = Country.getAllCountries()?.find(b => b?.name === val);
        let cityArr=[];
        City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
            // cityArr.push(a?.name);
            if(cityArr.indexOf(a?.name) === -1){ cityArr.push(a?.name); }

        });
        cityListCopy[i]=[...cityArr];
        //newFormValues[i]['address']['city'],
        newFormValues[i]['address'] = {
            'city':'',
            'country': val 
        }
        setCityList(cityListCopy); 
        setEmpFormValues(newFormValues);
        setFormValues({ ...formValues, organization: empFormValues })
    }

    const handleCityChange = (i, val) => {
        let newFormValues = [...empFormValues];
        newFormValues[i]['address'] = {
            'city':val,
            'country': newFormValues[i]['address']['country'] 
        }
        setEmpFormValues(newFormValues);
        setFormValues({ ...formValues, organization: empFormValues });
    }

    const handledateChange = (i, name, value) => {
        let newFormValues = [...empFormValues];
        newFormValues[i][name] = value;
        setEmpFormValues(newFormValues);
        setFormValues({ ...formValues, organization: empFormValues })
    }

    const handleCheckboxChange = (i, e) => {
        let newFormValues = [...empFormValues];
        newFormValues[i][e.target.name] = e.target.checked;
        newFormValues[i]['endYear'] = "";
        setEmpFormValues(newFormValues);
        setFormValues({ ...formValues, organization: empFormValues })
    }

    const removeFormFields = (i) => {
        if (empFormValues[i]['_id']) {
            deleteEmpDetails(empFormValues[i]['_id'], empFormValues[i]['organizationId']);
        }
        let newFormValues = [...empFormValues];
        newFormValues.splice(i, 1);
        let cityListCopy=[...cityList];
        cityListCopy.splice(i,1);
        setCityList(cityListCopy);
        setEmpFormValues((newFormValues.length > 0) ? newFormValues : [initial_emp])
    }

    const saveEmpDetails = async (i) => {
        if (empFormValues[i]['organizationName'] === '' ||
            empFormValues[i]['organizationName'] === null ||
            empFormValues[i]['designation'] === '' ||
            empFormValues[i]['designation'] === null ||
            empFormValues[i]['startYear'] === '' ||
            (empFormValues[i]['endYear'] === '' && !empFormValues[i]['isCurrentlyWorking'])
        ) {
            showToastError(t('Please_enter_the_organization_detail'))
            return false;
        }
        let orgId = '';

        let filter = orgList.filter((item) => item?.name === empFormValues[i]['organizationName']);
        if (filter.length > 0) {
            orgId = filter[0]?._id;
        }


        if (orgId === '') {
            //create Org and get Org Id
            let response = await CheckOrGetOrgService(empFormValues[i]['organizationName'])
            if (response?.data?.status === 200) {
                orgId = response?.data?.data;
            }
        }
        let nextParams = {
            orgId: orgId,       
            classifyYourJob: formValues.classifyYourJob,
            additionalPrivacy: formValues.additionalPrivacy,
        }
        editUserEmpAddDetails(nextParams);

        if (orgId === '') { return; }
        
        let org_params = {
            orgId: orgId,//empFormValues[i]['organizationId'],
            designation: empFormValues[i]['designation'],
            startYear: empFormValues[i]['startYear'],
            endYear: empFormValues[i]['endYear'] || "",
            isCurrentlyWorking: empFormValues[i]['isCurrentlyWorking'],
            address: { city: empFormValues[i]['address']['city'], country: empFormValues[i]['address']['country'] }
        }

        if (empFormValues[i]['_id']) {
            org_params['_id'] = empFormValues[i]['_id']
            editEmpDetails(org_params);
            return;
        }
        addOrgDetail(org_params)
    }

    const nextClick = (e) => {
        e.preventDefault();
        let obj = empFormValues.filter((i,index)=> {
            return i.isCurrentlyWorking
        })

        let params = {
            classifyYourJob: formValues.classifyYourJob,
            additionalPrivacy: formValues.additionalPrivacy,
        }

        if(obj.length){
        params = {
            classifyYourJob: formValues.classifyYourJob,
            additionalPrivacy: formValues.additionalPrivacy,
            orgId:obj[0]['organizationId'],
            designation: obj[0]['designation'],
            startYear: obj[0]['startYear'],
            endYear: obj[0]['endYear'] || "",
            isCurrentlyWorking: obj[0]['isCurrentlyWorking'],
            address: { city: obj[0]['address']['city'], country: obj[0]['address']['country'] }
        }
        }
        else{
            params = {
                classifyYourJob: formValues.classifyYourJob,
                additionalPrivacy: formValues.additionalPrivacy,
                orgId:empFormValues[0]['organizationId'],
                designation: empFormValues[0]['designation'],
                startYear: empFormValues[0]['startYear'],
                endYear: empFormValues[0]['endYear'] || "",
                isCurrentlyWorking: empFormValues[0]['isCurrentlyWorking'],
                address: { city: empFormValues[0]['address']['city'], country: empFormValues[0]['address']['country'] }
            } 
        }
        saveUserDetails(params);
    }
    return (
        <>
            <div className="heading mainHeading">
                <h1>{t('onboarding.employmentInfo')}</h1>
                <h3>{t('onboarding.enter_your_education_info')}</h3>
            </div>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className="userdetail-form"
            >
                <Row>
                    <Col md={11} className="radio-text">
                        <h4>{t('would_you_classify_your_job')}</h4>
                        <h6>{t('journalist_activist_defence')}</h6>
                    </Col>
                    <Col md={1}>
                        <Switch
                        // disabled={formValues.additionalPrivacy}
                            checked={formValues.classifyYourJob}
                            onChange={(e) => {setFormValues({ ...formValues, classifyYourJob: e.target.checked })}}
                            aria-label='Switch demo' />
                    </Col>
                    
                    { formValues.classifyYourJob && <><Col md={11} className="radio-text">
                        <h4 className="span_heading1">{t('would_you_like_to_turn_on')}</h4>
                        <h6>{t('this_would_limit_some_of_the_functionalities')}</h6>
                    </Col>
                       <Col md={1}>
                            <Switch
                                disabled={formValues.classifyYourJob===false}
                                checked={formValues.additionalPrivacy}
                                onChange={(e) => setFormValues({ ...formValues, additionalPrivacy: e.target.checked })}
                                aria-label='Switch demo' />
                        </Col></>}
                

                </Row>
                <hr />
                {empFormValues.map((e, index) => {
                    return (
                    <div key={index} style={{ position: 'relative' }}>
                        <div className="edit_row empSaveIcon" onClick={() => saveEmpDetails(index)} title={t('form.Save_Employment')}><SaveIcon/></div>
                        {
                            (e._id || index) ? <>
                            <div className="delete_row_2 delete_row" onClick={() => removeFormFields(index)} title={t('form.Delete_Employment')}><DeleteForeverIcon /></div></> : ''
                        }
                        <Row>
                            <Col md={7} className="emp-input-box empInput1">
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        value={e?.organizationName || ''}
                                        options={orgData?.length ? orgData : []}
                                        getOptionLabel={(option)=>option}   
                                        onChange={(e,val )=>  handleChangeVal(index,'organizationName', val)}  
                                        inputValue={e.organizationName || ''}
                                        onInputChange={(e, val) => handleChangeVal(index,'organizationName', val)}
                                        renderInput={(params) => <TextField {...params} label={t('form.label_enter_org')}  />}
                                    />
                                </FormControl>
                            </Col>
                            <Col md={7} className="emp-input-box empInput2">
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        value={DesignationList.find((option) => option.value === e.designation)}
                                        options={DesignationList?.length ? DesignationList : []}
                                        getOptionLabel={(option) => t(option.label)}   
                                        onChange={(e,val )=> handleChangeDesignation(index,'designation', val)}  
                                        inputValue={t(e?.designation) || ''}
                                        onInputChange={(e, val, reason) => handleChangeDesignation(index,'designation', val, reason)}
                                        renderInput={(params) => <TextField {...params} label={t('form.label_your_designation')}  />}
                                    />
                                </FormControl>
                            </Col>
                        </Row>
                        <Row className="empDateBox">
                            <Col md={4} className="contact-info-box empDateInputs">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        closeOnSelect={true}
                                        label={t('from')}
                                        disableFuture={true}
                                        value={e.startYear|| ''}
                                        onChange={(newDob) => {
                                            handledateChange(index, 'startYear', newDob);
                                        }}
                                        InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" alt="" style={{cursor:'pointer'}}/> }}
                                        renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                                        inputFormat="DD MMM YYYY"
                                    />
                                </LocalizationProvider>
                            </Col>
                            <Col md={4} className="contact-info-box empDateInputs">
                                {!e.isCurrentlyWorking ?
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        closeOnSelect={true}
                                        label={t('to')}
                                        disableFuture={true}
                                        value={(!e.currently_working)?e.endYear:''}
                                        onChange={(newDob) => {
                                            handledateChange(index, 'endYear', newDob);
                                        }}
                                        InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" alt="" style={{cursor:'pointer'}}/> }}
                                        renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY"/>}
                                        inputFormat="DD MMM YYYY"
                                    />
                                </LocalizationProvider> : 
                                <TextField 
                                    disabled={true}
                                    label={t('tillDate')}
                                    InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" alt="" style={{cursor:'pointer'}}/> }}
                                    />
                                }
                                {(e.startYear!==null && e.endYear !== null) && !fromToDateValidator(e.startYear, e.endYear) && 
                                    <div className="error-text">
                                        {t('errors.start_end_year')}
                                    </div>
                                }
                            </Col>
                            {(e.isCurrentlyWorking || showWorking) && 
                            <Col md={12} className="ps-4">
                                <GreenCheckBox checked={e.isCurrentlyWorking || false}
                                name="isCurrentlyWorking" onChange={e => handleCheckboxChange(index, e)} inputProps={{ 'aria-label': 'controlled' }} 
                                label={t('form.label_currently_work_here')}/>
                            </Col>}
                                {!formValues?.additionalPrivacy  &&
                                    <>
                                        <Col md={12} className="mt-4">
                                            <span className="formInsideHeading"> {t('form.label_org_address')} </span>
                                        </Col>
                                        <Col md={4} className="org-add-input-box demographicAddOrgInput">
                                            {/* <TextField 
                         label={t('city')} 
                         name="city" value={e.address?.city || ''} 
                         onChange={e => handleChange(index, e)} /> */}
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    disablePortal
                                                    name="country"
                                                    value={e?.address?.country || ''}
                                                    options={newCountryList?.length ? newCountryList : []}
                                                    getOptionLabel={(option) => option}
                                                    onChange={(e, val) => handleCountryChange(index, val)}
                                                    renderInput={(params) => <TextField {...params} label={t('country')} />}
                                                />
                                            </FormControl>
                                        </Col>

                                        <Col md={4} className="org-add-input-box demographicAddOrgInput">
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    disablePortal
                                                    name="city"
                                                    value={e?.address?.city || ''}
                                                    options={cityList.length ? cityList[index] : []}
                                                    getOptionLabel={(option) => option}
                                                    onChange={(e, val) => handleCityChange(index, val)}
                                                    renderInput={(params) => <TextField {...params} label={t('city')} />}
                                                />
                                            </FormControl>
                                        </Col>
                                    </>
                                }
                               
                        </Row>
                            {!formValues.classifyYourJob && <div>
                                <button className="add-more-btn" onClick={addFormFields}> {t('add_more')} </button>
                            </div>}
                    </div>
                )})}    
            </Box>

            <Row>
                <div className="contact-back-next-btn-area emp">
                    <Stack spacing={2} direction="row">
                        <button className="button green back-btn-new" onClick={backClick}> {t('back')} </button>
                        <button className="button secondary next-btn-new" onClick={nextClick}> {t('next')} </button>
                        <button className="button back-btn-new skip-color" onClick={skipClick}> {t('skip')} </button>
                    </Stack>
                </div>
            </Row>
        </>
    )
}

export default EmploymentInfo;