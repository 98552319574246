import React, { Component, useRef, useState } from 'react';
import { EditorState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
// import editorStyles from './editorStyles.module.css';

const linkifyPlugin = createLinkifyPlugin({ target: '_blank' });
const plugins = [linkifyPlugin];

const LinkifyContent= () => {

  const [editor, seteditor] = useState('');

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const onChange = (editorState) => {
    setEditorState(editorState);
  };

  // const focus = () => {
  //   editor.current.focus();
  // };

    return (
      <div className="editor">
        <Editor
          editorState={editorState}
          onChange={onChange}
          plugins={plugins}
          // ref={(element) => {
          //   seteditor(element)
          // }}
        />
      </div>
    );
}

// export default class LinkifyContent extends Component {
//   state = {
//     editorState: EditorState.createEmpty(),
//   };

//   onChange = (editorState) => {
//     this.setState({
//       editorState,
//     });
//   };

//   focus = () => {
//     this.editor.focus();
//   };

//   render() {
//     return (
//       <div className="editor" onClick={this.focus}>
//         <Editor
//           editorState={this.state.editorState}
//           onChange={this.onChange}
//           plugins={plugins}
//           ref={(element) => {
//             this.editor = element;
//           }}
//         />
//       </div>
//     );
//   }
// }

export default LinkifyContent;
