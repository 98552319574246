import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import FewFriendsList from "../../../commonDashboardComp/FewFriendsList";
import EventCalender from "../../../commonDashboardComp/eventCalender";
import TodayEventList from "../../../commonDashboardComp/todayEventsList";
import InviteFriendsList from "../../../commonDashboardComp/inviteFriendsBox";
import EventDetailBox from "../../../commonDashboardComp/eventDetailBox";
import { getImageUrl, showSingleToastSuccess, showToastSuccess, dayDateFormat } from "../../../utils/helpers";
import {
    getEventDetailService,
    deleteEventService,
    InterestedForEventService,
    validatorEventStatusService,
    ManageEventParticipantsService,
    remindEventService,
    removeRemindEventService
} from "../../../services/event.services";
import { deleteNotificationsService } from "../../../services/onBoarding.services";
import { t } from "i18next";
import { showToastError } from "../../../utils/helpers";
import { changeDateFormatYYYY } from "../../../utils/helpers";
import { getEventDetailByDateService } from "../../../services/event.services";
import swal from 'sweetalert';
import { useSelector } from "react-redux";
import { selectCurrentUserRole, selectCurrentOrg, selectCurrentUser } from "../../../Reducer/user/user.selector";
import InterestedPeople from "../../../commonDashboardComp/interestedPeople";
import InvitedPeople from "../../../commonDashboardComp/invitedPeople";
import PaymentPopUp from "../../../commonDashboardComp/paymentPopUp";
// import EventEvidenceUpload from "./eventEvidenceUpload";
import EventConfiguration from "./eventConfiguration";
import PostList from "../../../commonDashboardComp/postList";
import { useTranslation } from "react-i18next";
import EventActivityList from "./eventEvidenceUpload";
import { acceptRejectEventService } from "../../../services/event.services";
import { fromToDateValidator } from "../../../utils/formatValidator";
import { getOrgDetailsService } from "../../../services/orgAuth.services";
const ViewEvent = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const role = useSelector(selectCurrentUserRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);

    let current_user = {};
    if (role === 'organization') { current_user = CURRENTORG }
    else { current_user = CURRENTUSER }

    //const current_user = useSelector(selectCurrentUser);
    const eventId = location?.state?.eventId;
    const [eventByDate, setEventsByDate] = useState({});
    const [date, setDate] = useState(changeDateFormatYYYY(new Date()));
    const [eventDate, setEventDate] = useState(new Date())
    const [event, setEvent] = useState(false);
    const [userName, setUserName] = useState("");
    const [eventData, setEventData] = useState(false);
    const [reload, setReload] = useState(true);
    const [paymentOpen, setPaymentOpen] = useState(false);
    const handlePaymentOpen = () => setPaymentOpen(true);
    const handlePaymentClose = () => setPaymentOpen(false);
    const [paymentInviteOpen, setPaymentInviteOpen] = useState(false);
    const handleInvitePaymentOpen = () => setPaymentInviteOpen(true);
    const handleInvitePaymentClose = () => setPaymentInviteOpen(false);
    const [invitePaymentPopup, setInvitePaymentPopup] = useState(false);
    const [registerPaymentPopup, setRegisterPaymentPopup] = useState(false);

    const { t } = useTranslation();

    const [updated, setUpdated] = useState(true)

    useEffect(() => {
        getEventByDate();
    }, [date])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getCurrentDate = () => {
        let d = new Date();
        d.setHours(0, 0, 0, 0);
        return d;
    }

    const getEventByDate = () => {
        getEventDetailByDateService(date)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setEventsByDate(data?.data?.data);
                }
            })
    }

    const handlePaidEventInvite = () => {
        handleInvitePaymentClose();
        let param = {
            eventId: eventData._id,
            status: 'accept'
        }
        acceptRejectEventService(param)
            .then((data) => {
                if (data?.data?.status === 200) {
                    showSingleToastSuccess(t('apiMessages.EVENT_ACCEPT_SUCCESS'));
                    getEventDetail();
                    setReload(!reload)
                }
                else {
                    showToastError(t('errors.some_error'));
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const setEventDateHandler = (d) => {
        setEventDate(d);
        setDate(changeDateFormatYYYY(d))
    }

    const handleManageParticipants = () => {
        navigate('/manage-event', { state: { eventId: eventId } });
    }

    const editEventHandler = (e) => {
        e.preventDefault();
        navigate('/add-event?type=edit', { state: { eventData: event, targetId: event?.targetId } })
    }

    const deleteEventHandler = (e) => {
        e.preventDefault();
        swal({
            text: t('alerts.delete_event'),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
            .then(async (val) => {
                if (!val) return;
                deleteEventService(eventId)
                    .then((data) => {
                        if (data?.data?.status === 200) {
                            // showToastSuccess("Event Deleted Successfully");
                            deleteNotificationsService(t('event_Delete'), eventId)
                            showSingleToastSuccess(t('Event_Deleted_Successfully'));
                            navigate('/events')
                        }
                        else {
                            showToastError(t('errors.some_error'));
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            });
    }

    useEffect(() => {
        if (eventId === undefined) { navigate('/add-event') }
        else {
            getEventDetail()
        }
    }, [eventId])

    const getEventDetail = () => {
        getEventDetailService(eventId)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setEvent(data?.data?.data)
                }
                else {
                    showToastError(t('No_Event_Found'));
                    navigate('/add-event');
                }
            })
    }

    useEffect(() => {
        if (eventId !== undefined) {
            getEventDetail();
            // getOrgDetail();
        }
    }, [updated])

    const checkIfValidator = () => {
        let filter = event?.eventValidators?.filter((item) => item?.userId[0]?._id === current_user?._id)
        return (filter.length > 0) ? true : false;
    }

    const checkIfPending = () => {
        let filter = event?.eventValidators?.filter((item) => item?.userId[0]?._id === current_user?._id)
        if (filter.length > 0 && filter[0]?.status === 'accepted') {
            return false;
        }
        return true;
    }

    const checkIfAlreadyMember = () => {
        if (role === 'organization') {
            let filter = event?.organizationInvites?.filter((item) => item?._id === current_user?._id);
            return (filter?.length > 0) ? true : false;
        }
        else {
            let filter = event?.invites?.filter((item) => item?._id === current_user?._id);
            return (filter?.length > 0) ? true : false;
        }
    }

    const checkIfAlreadyInRemindMeList = () => {
        let filter = event?.remindMe?.filter((item) => item?.userId === current_user?._id)
        return (filter?.length > 0) ? true : false;
    }

    const checkIfAlreadyInPendingInvited = () => {
        if (role === 'organization') {
            let filter = event?.pendingOrganizationInvites?.filter((item) => item?._id === current_user?._id);
            return (filter?.length > 0) ? true : false;
        }
        else {
            let filter = event?.pendingUserInvites?.filter((item) => item?._id === current_user?._id);
            return (filter?.length > 0) ? true : false;
        }
    }

    const checkIfAlreadyInteredtedAndValidator = () => {
        let validatorArray = event?.eventValidators?.filter((item) => item?.userId[0]?._id === current_user?._id)
        let interestedArray = event?.interestedUser?.filter((item) => item === current_user?._id);
        return validatorArray?.length === 0 && interestedArray?.length === 0
    }
    const clickedForInterested = (e) => {
        e.preventDefault();
        InterestedForEventService(event?._id, "interested").
            then((resp) => {
                if (resp?.status === 200) {
                    // showToastSuccess("Interest Request sent Successfully");
                    showSingleToastSuccess(t('Interest_Request_sent_Successfully'));
                    getEventDetail();
                }
            })
            .error = () => {
                showToastError(t('apiMessages.SOME_THING_WENT_WRONG'))
            }
    }
    const clickedForRemoveInterest = (e) => {
        e.preventDefault();
        InterestedForEventService(event?._id, "notInterested").
            then((resp) => {
                if (resp?.status === 200) {
                    // showToastSuccess("Removed From Interest Successfully");
                    showSingleToastSuccess(t('Removed_From_Interest_Successfully'));
                    getEventDetail();
                }
            })
            .error = () => {
                showToastError(t('apiMessages.SOME_THING_WENT_WRONG'))
            }
    }

    const clickedForRemindMe = (e) => {
        e.preventDefault();
        let params = { eventId: event?._id }
        remindEventService(params).
            then((resp) => {
                if (resp?.status === 200) {
                    // showToastSuccess("Interest Request sent Successfully");
                    showSingleToastSuccess(t('Remind_Me_Request_sent_Successfully'));
                    getEventDetail();
                }
            })
            .error = () => {
                showToastError(t('apiMessages.SOME_THING_WENT_WRONG'))
            }
    }
    const clickedForRemoveRemindMe = (e) => {
        e.preventDefault();
        removeRemindEventService(event?._id).
            then((resp) => {
                if (resp?.status === 200) {
                    // showToastSuccess("Removed From Interest Successfully");
                    showSingleToastSuccess(t('Removed_From_Remind_Me_Successfully'));
                    getEventDetail();
                }
            })
            .error = () => {
                showToastError(t('apiMessages.SOME_THING_WENT_WRONG'))
            }
    }

    const clickedForRegister = (e) => {
        e.preventDefault();
        if (event?.amount == 0) {
            handleSubmbitRegitration(e);
        }
        else {
            setRegisterPaymentPopup(true);
            handlePaymentOpen();
        }
    }

    const handleSubmbitRegitration = () => {
        handlePaymentClose();
        let params = {
            eventId: event?._id,
            userId: current_user?._id,
            action: (role === 'organization') ? 'addOrganization' : 'add'
        }
        ManageEventParticipantsService(params)
            .then((resp) => {
                if (resp?.status == 200) {
                    // showToastSuccess("You have successfully registered")
                    showSingleToastSuccess("You have successfully registered")
                    getEventDetail();
                }
            })
            .error = () => {
                showToastError(t('apiMessages.SOME_THING_WENT_WRONG'))
            }


    }

    const acceptValidationRequest = (e) => {
        e.preventDefault();
        swal({
            text: t('alerts.accept_validate_event'),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
            .then(async (val) => {
                if (!val) return;
                try {
                    let response = await validatorEventStatusService(eventId, 'accepted');
                    if (response?.data?.status === 200) {
                        // showToastSuccess(t('apiMessages.EVENT_VALIDATE_ACCEPT_SUCCESS'))
                        showSingleToastSuccess(t('apiMessages.EVENT_VALIDATE_ACCEPT_SUCCESS'))
                        getEventDetail();
                    }
                    else {
                        showToastError(t('errors.some_error'))
                    }
                }
                catch (error) {
                    console.log(error)
                }
            });
    }
    const getOrgDetail = async () => {
        getOrgDetailsService(event?.spredeId)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setUserName(data?.data?.data);
                }
            })
    }


    const AcceptRejectPaidEventHandler = (e, id) => {
        getEventDetailService(id)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setEventData(data?.data?.data);
                    setInvitePaymentPopup(true);
                    handleInvitePaymentOpen();
                }
            })
    }

    const AcceptRejectEventHandler = (e, id, status) => {
        e.preventDefault();
        swal({
            text: (status === 'accept') ? t('alerts.accept_event') : t('alerts.reject_event'),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
            .then(async (val) => {
                if (!val) return;
                let param = {
                    eventId: id,
                    status: status
                }

                acceptRejectEventService(param)
                    .then((data) => {
                        if (data?.data?.status === 200) {
                            showSingleToastSuccess((status === 'accept') ? t('apiMessages.EVENT_ACCEPT_SUCCESS') : t('apiMessages.EVENT_REJECT_SUCCESS'));
                            getEventDetail();
                        }
                        else {
                            showToastError(t('errors.some_error'));
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            });

    }

    function makeButtons() {
        return <>
            <div className="col-md-12 banner-btn-area event-btn viewEventBtnBox">
                {event?.status === 'verified' ?
                    <button className="btn btn-secondary green" >{t('Event_Completed')}</button>
                    :
                    ((new Date(event?.endDate)) <= getCurrentDate() && (checkIfValidator()) != true) ?
                        <button className='btn btn-danger red' onClick={(e) => deleteEventHandler(e)} >{t("superAdmin.delete_event")}</button>
                        :
                        (event?.spredeId === current_user?._id) ? <>
                            <button className="btn btn-secondary green" onClick={() => handleManageParticipants()} >{t("superAdmin.manage_participant_btn_text")}</button>
                            <button className="btn btn-secondary green" onClick={(e) => editEventHandler(e)} >{t("superAdmin.edit_event_text")}</button>
                            <button className='btn btn-danger red' onClick={(e) => deleteEventHandler(e)} >{t("superAdmin.delete_event")}</button>
                        </> :
                            <>{checkIfValidator() ?
                                <>
                                    {checkIfPending() ?
                                        <button className="btn btn-secondary green"
                                            onClick={(e) => acceptValidationRequest(e)}
                                        >{t("event.accept_validation_request")}</button> :
                                        <button className="btn btn-secondary green"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate('/validate-event', { state: { eventId: eventId } })
                                            }}
                                        >{t("event.validate")}</button>
                                    }

                                </> :
                                <>
                                    {
                                        checkIfAlreadyMember() ?
                                            <>
                                                <button className="btn btn-secondary green">{t("event.already_registered")}</button>
                                                {/* <button className='btn btn-danger red' >{t("event.remove_from_calendar")}</button> */}
                                            </>
                                            :
                                            <>
                                                {checkIfAlreadyInRemindMeList() ?
                                                    <button className="btn btn-secondary red" onClick={clickedForRemoveRemindMe}>{t("event.remove_remind_me")}</button>
                                                    :
                                                    <button className="btn btn-secondary green" onClick={clickedForRemindMe}>{t("event.remind_me")}</button>
                                                }

                                                {checkIfAlreadyInPendingInvited() ?
                                                    <>
                                                        <button className="btn btn-secondary green" onClick={(e) => (event?.isPaid === true) ? AcceptRejectPaidEventHandler(e, event?._id) : AcceptRejectEventHandler(e, event?._id, 'accept')}>{t("button.accept")}</button>
                                                        <button className="btn btn-secondary red" onClick={(e) => AcceptRejectEventHandler(e, event?._id,'cancel')}>{t("button.reject")}</button>
                                                    </> :
                                                    <>
                                                        <button className="btn btn-secondary green" onClick={(e) => clickedForRegister(e)}>{t("event.register_now")}</button>
                                                        {/* <button className='btn btn-danger red' >{t("event.remove_from_calendar")}</button> */}
                                                        {role !== 'organization' && <>
                                                            {checkIfAlreadyInteredtedAndValidator() ?
                                                                <button className="btn btn-secondary green" onClick={(e) => clickedForInterested(e)}>{t('Interested')}</button>
                                                                :
                                                                <button className="btn btn-danger red" onClick={(e) => clickedForRemoveInterest(e)}>{t('Remove_from_Interest')}</button>
                                                            }
                                                        </>}
                                                    </>
                                                }


                                            </>
                                    }

                                </>}
                            </>
                }

            </div>
        </>

    }

    return (<>
        <Row> {event && <ProfileHeader
            buttons={makeButtons()}
            showEventHeader={true}
            imageContainer={false}
            eventDetail={event}
            cover={getImageUrl(event?._id, 'event', event?.photo)}
        />} </Row>

        {(checkIfAlreadyMember() && event?.spredeId !== current_user?._id) ? <>
            <Row className="grid grid-9-3 mobile-prefer-content">
                <Col md={9} className="grid-column">
                    <Row className="grid grid-9-3 mobile-prefer-content">
                        <Col md={12}>
                            <EventConfiguration event={event} />
                        </Col>
                    </Row>
                    <Row className="grid grid-9-3 mobile-prefer-content">
                        <Col md={4}>

                            <>{!fromToDateValidator(event?.endDate, new Date()) &&

                                event?.spredeId === current_user?._id ? <>
                                <InvitedPeople heading={t('Invited_People')} eventId={eventId} />
                            </> : ''

                            }
                            </>


                            <InterestedPeople heading={t('Interested_People')} eventId={eventId} toShowInviteIcon={event?.spredeId === current_user?._id} />

                        </Col>
                        <Col md={8}>
                            <PostList myPosts={true} eventId={eventId} />
                            {/* <EventDetailBox event={event} /> */}
                        </Col>
                    </Row>

                </Col>
                <Col md={3} className="grid-column">
                    <EventCalender date={eventDate} setDate={setEventDateHandler} />
                    <TodayEventList date={date} events={eventByDate} />
                    {(event?.spredeId === current_user?._id || checkIfAlreadyMember()) && <EventActivityList event={event} creater={event?.spredeId === current_user?._id} updated={updated} setUpdated={setUpdated} getAllEventData={getEventDetail}></EventActivityList>}

                </Col>
            </Row>
        </> :
            <>
                {event?.status === 'verified' ?
                    <Row className="grid grid-9-3 mobile-prefer-content">

                        <Col md={9} className="grid-column">
                            <PostList myPosts={true} eventId={eventId} />
                            {/* <EventDetailBox event={event} /> */}
                        </Col>

                        <Col md={3} className="grid-column">
                            <EventCalender date={eventDate} setDate={setEventDateHandler} />
                            <TodayEventList date={date} events={eventByDate} />
                            {event?.spredeId === current_user?._id && <>
                                <EventActivityList event={event} creater={event?.spredeId === current_user?._id} updated={updated} setUpdated={setUpdated} getAllEventData={getEventDetail}></EventActivityList>
                                {event?.spredeId === current_user?._id &&
                                    <button className="button primary uploadEvidence" onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/validate-event', { state: { eventId: event?._id, creater: true } })
                                    }}> {t('event.Upload_Evidence')} <img src="/Assets/upload-inactive-icon.png" className='imageInfindFriend' /> </button>
                                }
                            </>}
                        </Col>
                    </Row>
                    : <Row className="grid grid-3-6-3 mobile-prefer-content">
                        <Col md={3} className="grid-column">
                            {
                                event?.spredeId === current_user?._id && (getCurrentDate() <= new Date(event?.endDate)) ? <>
                                    <InvitedPeople heading={t('event.Invite_People')} eventId={eventId} />
                                </> : ''
                            }

                            <InterestedPeople heading={t('event.Interested_People')} eventId={eventId} toShowInviteIcon={event?.spredeId === current_user?._id} />
                        </Col>

                        <Col md={6} className="grid-column">
                            <PostList myPosts={true} eventId={eventId} />
                            {/* <EventDetailBox event={event} /> */}
                        </Col>

                        <Col md={3} className="grid-column">
                            <div className="bg-white mb-3 p-3 rounded-3">
                                <h5 className="mb-3">{t('Additional_Info')}</h5>
                                <p className="mb-2"><span className=" fw-bold">Category:</span> {event?.category}</p>
                                <p className="mb-2"><span className="fw-bold">End Date:</span> {dayDateFormat(event?.endDate)}</p>
                                <p className="mb-2"><span className="fw-bold">Prize:</span> {(event?.incentivePrize != "") ? event.incentivePrize : "N.A"}</p>
                                <p className="mb-2"><span className="fw-bold">Target:</span> {event?.unit}</p>
                                <p className="mb-2"><span className="fw-bold">Created by:</span> {(event?.createdBy === 'organization') ? event?.userDetail?.name : `${event?.userDetail?.firstName} ${event?.userDetail?.lastName}`}</p>
                            </div>
                            <EventCalender date={eventDate} setDate={setEventDateHandler} />
                            <TodayEventList date={date} events={eventByDate} />
                            {event?.spredeId === current_user?._id && <>
                                <EventActivityList event={event} creater={event?.spredeId === current_user?._id} updated={updated} setUpdated={setUpdated} getEventDetail={getEventDetail}></EventActivityList>
                                {event?.spredeId === current_user?._id &&
                                    <button className="button primary uploadEvidence" onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/validate-event', { state: { eventId: event?._id, creater: true } })
                                    }}> {t('event.Upload_Evidence')} <img src="/Assets/upload-inactive-icon.png" className='imageInfindFriend' /> </button>
                                }
                            </>}
                        </Col>
                    </Row>
                }
            </>}

        {registerPaymentPopup && <PaymentPopUp
            eventData={event}
            open={paymentOpen}
            handleClose={handlePaymentClose}
            handlePaidEvents={handleSubmbitRegitration}
        />}

        {invitePaymentPopup && <PaymentPopUp
            eventData={event}
            open={paymentInviteOpen}
            handleClose={handleInvitePaymentClose}
            handlePaidEvents={handlePaidEventInvite}
        />}


    </>)
}

export default ViewEvent;