import React from "react";
import ReactPaginate from "react-paginate";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
const ReactPagination = ({pageCount, onPageChange}) => {
    return (
        <>
            {pageCount === 1 ? '' :
            pageCount > 1 ?
                <ReactPaginate
                    previousLabel={<NavigateBeforeIcon/>}
                    nextLabel={<NavigateNextIcon/>}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={onPageChange}
                    containerClassName={`pagination justify-content-center`}
                    pageClassName={'page-link'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-link'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-link'}
                    initialPage={0}
                    nextLinkClassName={'page-link'}
                    breakLinkClassName={'page-link'}
                    breakClassName={'page-link'}
                    activeClassName={'active'}
                    style={{ padding: '0px' }}
                />
             : ""}
        </>
    )
}

export default ReactPagination;