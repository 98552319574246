import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Col, Row } from "react-bootstrap";
import { AboutUsImg, 
} from "../../commonComponents/Images";
import { getImageUrl } from "../../utils/helpers";
const AboutUs = ({cmsData}) => {
    const {t} = useTranslation();
    return (
        <Container fluid className="aboutus-div" id="aboutUsSection">
            <Row className="align-items-center">
                <Col md={12}  className="col-md-12 col-lg-6">
                    <div className="aboutus-col">
                    <span className="heading"> {cmsData?.aboutUs?.aboutUsTitle} </span>
                    <p className="desc">{cmsData?.aboutUs?.aboutUsText}</p> 
                    </div>
                </Col>
                <Col md={12} className="col-md-12 col-lg-6">
                    {/* <img src={cmsData?.aboutUs?.aboutUsImage !== '' ? getImageUrl(cmsData?._id, 'cms', cmsData?.aboutUs?.aboutUsImage) : AboutUsImg} className="aboutus-img" alt=""/>                */}
                    {
                        (cmsData?.aboutUsVideo?.video && cmsData?.aboutUsVideo?.video !== '')?
                        <video width={'100%'} src={getImageUrl(cmsData?._id, 'cms', cmsData?.aboutUsVideo?.video)}
                        className="" controls alt="" />
                        :(cmsData?.aboutUs?.aboutUsImage !== '')?
                        <img src={getImageUrl(cmsData?._id, 'cms', cmsData?.aboutUs?.aboutUsImage)} className="aboutus-img" alt=""/>
                        :
                        <img src={AboutUsImg} className="aboutus-img" alt=""/>
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default AboutUs;