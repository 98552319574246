import React, { useEffect, useState } from "react";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import { TextField } from "@mui/material";
import { CalendarMonthIcon } from "../../../commonComponents/Images";
import { PaymentSearchIcon } from "../../../commonComponents/Images";
import { TotalEarnigsIcon } from "../../../commonComponents/Images";
import { TotalEarnigsIcon2 } from "../../../commonComponents/Images";
import { walletGetService } from "../../../services/walletService";
import { useSSR, useTranslation } from "react-i18next";
import ApiConfig from "../../../config/ApiConfig";
import instance, { instanceOrg } from "../../../AppInterceptor";
import { useSelector } from "react-redux";
import { selectCurrentUserRole, selectCurrentUser, selectCurrentOrg } from "../../../Reducer/user/user.selector";
import { CSVLink, CSVDownload } from "react-csv";
const Wallet = () => {
  const date = new Date();
  const nextMonthDate = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
  const { t } = useTranslation();
  const [toDate, setToDate] = useState(nextMonthDate);
  const [show, setShow] = useState(false)
  const [walletData, setWalletData] = useState([]);
  const headers = [
    { label: 'Transaction ID', key: 'transactionId' },
    { label: 'User', key: 'user' },
    { label: 'Username', key: 'userName' },
    { label: 'Transaction ID', key: 'transactionId' },
    { label: 'Amount', key: 'amount' },
    { label: 'Currency', key: 'currency' },
    { label: 'Event', key: 'event' },
    { label: 'Date', key: 'Date' },
    { label: 'Time', key: 'time' },
    { label: 'Payment Status', key: 'status' }
  ];
  const [paymentData, setPaymentData] = useState([]);

  const role = useSelector(selectCurrentUserRole);
  const CURRENTUSER = useSelector(selectCurrentUser);
  const CURRENTORG = useSelector(selectCurrentOrg);

  const fetchhWalletData = () => {
    let url;
    switch (role) {
      case 'user':
        url = ApiConfig.transactionList + '?page=0&limit=20';
        return instance.get(url);
        break;

      case 'superAdmin':
        url = ApiConfig.adminTransactionList;
        return instance.get(url);
        break;

      case 'organization':
        url = ApiConfig.transactionList + '?page=0&limit=20';
        return instance.get(url);
        break;
    }
  }

  const callWalletData = () => {
    fetchhWalletData().then((data) => {
      loadData(data?.data?.data);
    })
  }
  const loadData = (data) => {
    setWalletData(data);
    sortData(data);
  };
  const createPaymentCSV = (data) => {
    let paymentList = [];
    if (data.length > 0) {
      paymentList = data.map((item) => {
        let paymentObj = {};
        paymentObj['transactionId'] = item?.transactionId;
        paymentObj['user'] = item?.userId?.firstName + " " + item?.userId?.lastName;
        paymentObj['userName'] = item?.userId?.userName;
        paymentObj['transactionId'] = item?.transactionId;
        paymentObj['amount'] = item?.amount / 100;
        paymentObj['currency'] = item?.eventId?.currency;
        paymentObj['event'] = item?.eventId?.title;
        paymentObj['Date'] = new Date(item?.createdAt).toLocaleDateString();
        paymentObj['time'] = new Date(item?.createdAt).toLocaleTimeString();
        if (item?.paymentStatus) {
          if (item?.paymentStatus === true) {
            paymentObj['status'] = 'Completed';
          }
          else {
            paymentObj['status'] = 'Failed';
          }
        }
        else {
          paymentObj['status'] = 'N.A';
        }

        return paymentObj;
      })
      setPaymentData(paymentList);
    }
    else{
      setPaymentData([]);
    }
  }
  const sortData = (data) =>{
    let sortedData = data.sort((date1, date2)=>{
      let d1 = new Date(date1.createdAt);
      let d2 = new Date(date2.createdAt);
      return d2-d1;
    });
    // setWalletData(sortedData);
    setFilteredData(sortedData);
    createPaymentCSV(sortedData);
  }
  useEffect(callWalletData, [])
  const [dateError, setDateError] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [searched, setSearched] = useState(false);
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  useEffect(()=>{
    if(!searched && filteredData.length>0){
      setFromDate(new Date(filteredData[filteredData.length - 1]?.createdAt));  
      setToDate(new Date(filteredData[0]?.createdAt));  
      setMinDate(new Date(filteredData[filteredData.length - 1]?.createdAt));
      setMaxDate(new Date(filteredData[0]?.createdAt));
    }
  },[filteredData, searched]);
  const filterData = (e) => {
    setSearched(true);
    e.preventDefault();
    if (fromDate == '' || toDate == '') {
      setDateError(true);
      return;
    }
    setDateError(false);
    let fromDateWithoutTime = new Date(fromDate).toDateString();
    let toDateWithoutTime = new Date(toDate).toDateString();
    const fromDateval = new Date(fromDateWithoutTime).getTime();
    const toDateVal = new Date(toDateWithoutTime).getTime();
    if (walletData.length > 0) {
      let filteredPaymentData = walletData.filter((item) => {
        let paymentDate = new Date(new Date(item?.createdAt).toDateString()).getTime();
        if (paymentDate >= fromDateval && paymentDate <= toDateVal) {
          return item;
        }
      })
      sortData(filteredPaymentData);
    }

  }
  return (<>
    <SectionHeader pretitle={t('wallet.MY_STORE')} title={t('wallet.My_Payments')} />

    <div className="section-filters-bar v6 myProfileSettings">
      <div className="section-filters-bar-actions">
        <form className="form">
          <div className="form-item split paymentDate">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                closeOnSelect={true}
                label={t('form.From_Date')}
                value={fromDate}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(newDate) => {
                  setFromDate(newDate);
                }}
                InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                renderInput={(params) => <TextField {...params} />}
                inputFormat="DD MMM YYYY"
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                closeOnSelect={true}
                label={t('form.To_Date')}
                value={toDate}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(newDate) => {
                  setToDate(newDate);
                }}
                InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                renderInput={(params) => <TextField {...params} />}
                inputFormat="DD MMM YYYY"
              />
            </LocalizationProvider>

            <button className="button secondary" onClick={filterData}>
              <img src={PaymentSearchIcon} />
            </button>
            {
              (dateError) ?
                <div className="error">
                  Please select date.
                </div>
                : <></>
            }
          </div>
        </form>
      </div>

      <div className="section-filters-bar-actions">
        <CSVLink className="button primary popup-event-creation-trigger" headers={headers} filename="payment-list" data={paymentData}>{t('wallet.Download_Statement')}</CSVLink>
      </div>
    </div>

    <Row className="grid">
      <Col md={12} className="grid-column">
        <div className="widget-box">
          <p className="widget-box-title">{t('wallet.Payments_History')}</p>
          {filteredData?.length > 0 ?
            <table className="table">
              <thead>
                <tr>
                  <td>Transaction ID</td>
                  <td>By User</td>
                  <td>User Name</td>
                  <td>Amount</td>
                  <td>Currency</td>
                  <td>Event</td>
                  <td>Date</td>
                  <td>Time</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                {
                  filteredData.map(function (item, index) {
                    return (<tr key={'walletData' + index}>
                      <td>{item?.transactionId}</td>
                      <td>{item?.userId?.firstName + " " + item?.userId?.lastName}</td>
                      <td>{item?.userId?.userName}</td>
                      <td>{item?.amount / 100}</td>
                      <td>{(item?.eventId?.currency) ? item?.eventId?.currency : 'N.A'}</td>
                      <td>{(item?.eventId?.title) ? item?.eventId?.title : 'N.A'}</td>
                      <td>{new Date(item?.createdAt).toLocaleDateString()}</td>
                      <td>{new Date(item?.createdAt).toLocaleTimeString()}</td>
                      <td>{(item?.paymentStatus) ? (item?.paymentStatus === true) ? 'Success' : 'Failed' : 'N.A.'}</td>
                    </tr>)
                  })
                }
              </tbody>
            </table> :
            <div className="widget-box-content">
              <div className=" paymentHistory" data-simplebar="init">
                <div className="simplebar-wrapper">
                  <div className="simplebar-height-auto-observer-wrapper">
                    <h6 className="nopeMsg">{t('wallet.No_Payments')}</h6>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </Col>
    </Row>
  </>);
}

export default Wallet;