import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BarsLoader from '../commonComponents/barsLoader';
import { getParticipationImpactService } from '../services/impactServices';
import { Col, Row } from 'react-bootstrap';
const ParticipationImpact = () => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [noData, setNoData] = useState(false);
  const [impactData, setImpactData] = useState('');
  const [categoryWiseData, setCategoryWiseData] = useState({});
  useEffect(() => {
    getParticipationImapct();
  }, []);
  const impactCalculation = (data) => {
    let impactArray = data?.map((a, index) => {
      let spredeInterest = { category: a?.categoryDetail?.spredeInterest };
      let subCategory;
      let eventLength = 0;
      let eventsCompleted;
      let eventUnits;
      let eventUnitsCompleted;
      let inviteLength;
      if (a?.subCategoryResult?.length > 0) {
        subCategory = a?.subCategoryResult.map((category, index) => {
          let subObj = {};
          subObj['completedEvents'] = category?.subCategoryCompletedEventLength;
          subObj['totalEvents'] = category?.subCategoryTotalEventLength;
          eventsCompleted = category?.subCategoryCompletedEventLength;
          eventLength += category?.subCategoryTotalEventLength;
          subObj['subCategory'] =
            category?.subCategoryDetail?.spredeInterestOptions;
          subObj['eventUnit'] = category?.eventUnit;
          let eventEvidenceUnit = 0;
          category?.eventEvidence.map((item)=>{
            if(item.length !=0){
              eventEvidenceUnit += item[0]?.unit;
            }
          });
          subObj['eventUnitCompleted'] = eventEvidenceUnit;
          subObj['inviteLength'] = category?.inviteLength;
          subObj['eventAverage'] = ((category?.inviteLength)?eventEvidenceUnit/category?.inviteLength:eventEvidenceUnit/category?.organizationInviteLength).toFixed(1);
          return subObj;
        });
      }
      spredeInterest['subCat'] = subCategory;
      spredeInterest['eventLength'] = eventLength;
      return spredeInterest;
    });
    let finalObj = {};
    if (impactArray?.length > 0) {
      impactArray?.map((a, index) => {
        if (a?.category === 'air') {
          finalObj = { ...finalObj, air: true, airData: a };
        } else if (a?.category === 'fire') {
          finalObj = { ...finalObj, fire: true, fireData: a };
        } else if (a?.category === 'soul') {
          finalObj = { ...finalObj, soul: true, soulData: a };
        } else if (a?.category === 'water') {
          finalObj = { ...finalObj, water: true, waterData: a };
        } else if (a?.category === 'earth') {
          finalObj = { ...finalObj, earth: true, earthData: a };
        }
      });

      let catDataLength = Object.keys(finalObj).length;
      let Data = 0;
      if (catDataLength != 0) {
        for (const key in finalObj) {
          if (finalObj[key].eventLength && key.eventLength != 0) {
            Data++;
          }
        }
      }
      if (Data != 0) {
        setNoData(false);
      } else {
        setNoData(true);
      }
      setCategoryWiseData(finalObj);
    }
    setImpactData(impactArray);
  };
  const getParticipationImapct = async () => {
    setLoader(true);
    let resp;
    try {
      resp = await getParticipationImpactService();
    } catch (err) {
      console.log(err);
    }
    if (resp?.status == 200) {
      if (resp?.data?.data.length > 0) {
        impactCalculation(resp.data.data);
        setLoader(false);
      } else {
        setNoData(true);
      }
    } else {
      setLoader(false);
      setServerError(true);
    }
  };

  return (
    <div className="widget-box mt-3 participationImpactComp">
      {loader ? (
        <>
          <BarsLoader />
        </>
      ) : serverError ? (
        <>
          <p className="text-danger fs-3">
            There was some server error. Please try again later.
          </p>
        </>
      ) : (
        <>
          <p className="widget-box-title">Participation Impact</p>
          {noData ? (
            <p className="fs-5">
              No participation data available at the moment. Please make sure that you have participated in an event and it is complete.
            </p>
          ) : (
            <>
              <div>
                {impactData?.length > 0 ? (
                  <>
                    {categoryWiseData?.earth &&
                      categoryWiseData?.earthData?.eventLength != 0 && (
                        <Row>
                          <div className="widget-box mb-3 mt-4">
                            <div className="d-flex align-items-center">
                              <img
                                src="/Assets/Earth-icon.png"
                                className=""
                                style={{ height: '39px', marginRight: '12px' }}
                              />
                              <p className="our-impect-title">
                                <a className="bold" to="/">
                                  {t('earth')}
                                </a>
                              </p>
                            </div>
                            <Row>
                              {categoryWiseData?.earthData?.subCat?.map(
                                (sub, index) => {
                                  if (sub?.totalEvents != 0) {
                                    return (
                                      <Col md={6}>
                                        <div className="impect_earth_box mb-3">
                                          {sub?.subCategory ===
                                          'tree plantation' ? (
                                            <>
                                              <img
                                                src="/Assets/tree.png"
                                                className=""
                                              />
                                              <p className="col-md-8">
                                                <span className="g-color">
                                                  {sub?.eventUnit}
                                                </span>{' '}
                                                {t('trees_planted')}
                                              </p>
                                            </>
                                          ) : sub?.subCategory ===
                                            'solid waste management' ? (
                                            <>
                                              <img
                                                src="/Assets/plogging.png"
                                                className=""
                                              />
                                              <p className="col-md-8">
                                                <span className="g-color">
                                                  {sub?.eventUnit}
                                                  <sup>Kg</sup>
                                                </span>
                                                {sub?.subCategory}
                                              </p>
                                            </>
                                          ) : sub?.subCategory ===
                                            'consumption management' ? (
                                            <>
                                              <img
                                                src="/Assets/Compos Created.png"
                                                className=""
                                              />
                                              <p className="col-md-8">
                                                <span className="g-color">
                                                  {sub?.eventUnit}
                                                  <sup>Kg</sup>
                                                </span>{' '}
                                                {sub?.subCategory}
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <div>
                                                <p className="mb-2" key={index}>
                                                  {sub?.subCategory}
                                                </p>
                                                {/* <p>
                                                      Total Events
                                                      Participated:{' '}
                                                      {sub?.totalEvents}
                                                    </p>
                                                    <p>
                                                      Total Target To Be
                                                      Completed:{' '}
                                                      {sub?.eventUnit}
                                                    </p>
                                                    <p>
                                                      Total Target
                                                      Completed:{' '}
                                                      {
                                                        sub?.eventUnitCompleted
                                                      }
                                                    </p> */
                                                    }
                                                    <p>
                                                      You Completed:{' '}
                                                      {sub?.eventAverage}
                                                      {' out of '}
                                                      {sub?.eventUnit}
                                                    </p>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </Col>
                                    );
                                  }
                                }
                              )}
                            </Row>
                          </div>
                        </Row>
                      )}
                    <Row>
                      <Col md={12}>
                        <Row>
                          {categoryWiseData?.water &&
                            categoryWiseData?.waterData?.eventLength != 0 && (
                              <Col md={12} className="mb-3 p-0">
                                <div className="widget-box">
                                  <div className="widget-box-content">
                                    <div className="d-flex align-items-center">
                                      <img
                                        src="/Assets/water.png"
                                        className=""
                                        style={{
                                          height: '39px',
                                          marginRight: '12px',
                                        }}
                                      />
                                      <p className="our-impect-title">
                                        <a className="bold" to="/">
                                          {t('water')}
                                        </a>
                                      </p>
                                    </div>

                                    <Row>
                                      {categoryWiseData?.waterData?.subCat?.map(
                                        (sub, index) => {
                                          if (sub?.totalEvents != 0) {
                                            return (
                                              <Col md={6}>
                                                <div
                                                  className="user-status-list users-status-box h-auto"
                                                  key={index}
                                                >
                                                  <div className="mt-3">
                                                    <p className="mb-2">
                                                      {sub?.subCategory}
                                                    </p>
                                                    {sub?.totalEvents != 0 ? (
                                                      <>
                                                        {/* <p>
                                                          Total Events
                                                          Participated:{' '}
                                                          {sub?.totalEvents}
                                                        </p>
                                                        <p>
                                                          Total Target To Be
                                                          Completed:{' '}
                                                          {sub?.eventUnit}
                                                        </p>
                                                        <p>
                                                          Total Target
                                                          Completed:{' '}
                                                          {
                                                            sub?.eventUnitCompleted
                                                          }
                                                        </p> */}
                                                        <p>
                                                          You Completed:{' '}
                                                          {sub?.eventAverage}
                                                          {' out of '}
                                                          {sub?.eventUnit}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <p>
                                                        Data Not Available At
                                                        the Moment.
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              </Col>
                                            );
                                          }
                                        }
                                      )}
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                            )}
                          {categoryWiseData?.air &&
                            categoryWiseData?.airData?.eventLength != 0 && (
                              <Col md={12} className="mb-3 p-0">
                                <div className="widget-box">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="/Assets/air.png"
                                      className=""
                                      style={{
                                        height: '39px',
                                        marginRight: '12px',
                                      }}
                                    />
                                    <p className="our-impect-title">
                                      <a className="bold" to="/">
                                        {t('air')}
                                      </a>
                                    </p>
                                  </div>

                                  <Row>
                                    {categoryWiseData?.airData?.subCat?.map(
                                      (sub, index) => {
                                        if (sub?.totalEvents != 0) {
                                          return (
                                            <Col md={6}>
                                              <div
                                                className="widget-box-content"
                                                key={index}
                                              >
                                                <div className="user-status-list users-status-box h-auto">
                                                  <div className="mt-3">
                                                    <p className="mb-2">
                                                      {sub?.subCategory}
                                                    </p>
                                                    {sub?.totalEvents != 0 ? (
                                                      <>
                                                        {/* <p>
                                                          Total Events
                                                          Participated:{' '}
                                                          {sub?.totalEvents}
                                                        </p>
                                                        <p>
                                                          Total Target To Be
                                                          Completed:{' '}
                                                          {sub?.eventUnit}
                                                        </p>
                                                        <p>
                                                          Total Target
                                                          Completed:{' '}
                                                          {
                                                            sub?.eventUnitCompleted
                                                          }
                                                        </p> */}
                                                        <p>
                                                          You Completed:{' '}
                                                          {sub?.eventAverage}
                                                          {' out of '}
                                                          {sub?.eventUnit}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <p>
                                                        Data Not Available At
                                                        the Moment.
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </Col>
                                          );
                                        }
                                      }
                                    )}
                                  </Row>
                                </div>
                              </Col>
                            )}

                          <Col md={12} className="mb-3 p-0">
                            {categoryWiseData?.fire &&
                              categoryWiseData?.fireData?.eventLength != 0 && (
                                <div className="widget-box">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="/Assets/fire.png"
                                      className=""
                                      style={{
                                        height: '39px',
                                        marginRight: '12px',
                                      }}
                                    />
                                    <p className="our-impect-title">
                                      <a className="bold" to="/">
                                        {t('fire')}
                                      </a>
                                    </p>
                                  </div>

                                  <div className="widget-box-content">
                                    <Row>
                                      {categoryWiseData?.fireData?.subCat?.map(
                                        (sub, index) => {
                                          if (sub?.totalEvents != 0) {
                                            return (
                                              <Col md={6}>
                                                <div
                                                  className="user-status-list users-status-box h-auto"
                                                  key={index}
                                                >
                                                  <div className="mt-3">
                                                    <p className="px-1 mb-2">
                                                      {sub?.subCategory}
                                                    </p>
                                                    {sub?.totalEvents != 0 ? (
                                                      <>
                                                        {/* <p>
                                                          Total Events
                                                          Participated:{' '}
                                                          {sub?.totalEvents}
                                                        </p>
                                                        <p>
                                                          Total Target To Be
                                                          Completed:{' '}
                                                          {sub?.eventUnit}
                                                        </p>
                                                        <p>
                                                          Total Target
                                                          Completed:{' '}
                                                          {
                                                            sub?.eventUnitCompleted
                                                          }
                                                        </p> */}
                                                        <p>
                                                          You Completed:{' '}
                                                          {sub?.eventAverage}
                                                          {' out of '}
                                                          {sub?.eventUnit}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <p>
                                                        Data Not Available At
                                                        the Moment.
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              </Col>
                                            );
                                          }
                                        }
                                      )}
                                    </Row>
                                  </div>
                                </div>
                              )}
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} className="mb-3 p-0">
                        {categoryWiseData?.soul &&
                          categoryWiseData?.soulData?.eventLength != 0 && (
                            <div className="widget-box">
                              <Row>
                                <Col md={12}>
                                  <div className="widget-box-content">
                                    <div className="">
                                      <div className="col-md-12">
                                        <Row className="align-items-center our-impect-title mb-3">
                                          <div className="d-flex align-items-center">
                                            <img
                                              src="/Assets/Soul - Copy.png"
                                              className=""
                                              style={{
                                                height: '39px',
                                                marginRight: '12px',
                                              }}
                                            />
                                            <p className="our-impect-title">
                                              <a className="bold" to="/">
                                                {t('soul')}
                                              </a>
                                            </p>
                                          </div>
                                        </Row>
                                      </div>
                                    </div>
                                    <Row>
                                      {categoryWiseData?.soulData?.subCat?.map(
                                        (sub, index) => {
                                          if (sub?.totalEvents != 0) {
                                            return (
                                              <Col md={6}>
                                                <div
                                                  className="user-status-list users-status-box h-auto"
                                                  key={index}
                                                >
                                                  {sub?.subCategory ===
                                                  'animal life conservation' ? (
                                                    <>
                                                      <div className="soul-boxes">
                                                        <p className="user-status-title">
                                                          <a
                                                            className="bold"
                                                            to="/"
                                                          >
                                                            {sub?.eventUnit}
                                                          </a>
                                                        </p>
                                                        <p className="parag">
                                                          {sub?.subCategory}
                                                        </p>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="mt-3">
                                                        <p className="mb-2">
                                                          {sub?.subCategory}
                                                        </p>
                                                        {sub?.totalEvents !=
                                                        0 ? (
                                                          <>
                                                            {/* <p>
                                                              Total Events
                                                              Participated:{' '}
                                                              {sub?.totalEvents}
                                                            </p>
                                                            <p>
                                                              Total Target To Be
                                                              Completed:{' '}
                                                              {sub?.eventUnit}
                                                            </p>
                                                            <p>
                                                              Total Target
                                                              Completed:{' '}
                                                              {
                                                                sub?.eventUnitCompleted
                                                              }
                                                            </p> */}
                                                            <p>
                                                          You Completed:{' '}
                                                          {sub?.eventAverage}
                                                          {' out of '}
                                                          {sub?.eventUnit}
                                                        </p>
                                                          </>
                                                        ) : (
                                                          <p>
                                                            Data Not Available
                                                            At the Moment.
                                                          </p>
                                                        )}
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </Col>
                                            );
                                          }
                                        }
                                      )}
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <div className="widget-box mb-3 mt-4">
                      <h5 className="mb-3">{t('no_impact')}</h5>
                    </div>
                  </Row>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ParticipationImpact;
