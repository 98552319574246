import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../Reducer/user/user.selector";
const SettingsSidebar = () => {
  const {t} = useTranslation();
    const role = useSelector(selectCurrentUserRole);
    const path_name=window.location.pathname;
    return(
        <div className="sidebar-box no-padding">
          <div className="sidebar-menu">
            <Link to="/profile-settings" className={`sidebar-menu-item ${path_name === '/profile-settings'?" selected":""}`}>
              <div className="sidebar-menu-header accordion-trigger-linked">
                <img src="/Assets/Profile sett.png" alt=""  className="sidebar-menu-header-icon icon-profile"/>
                <p className="sidebar-menu-header-title">{t('my_profile')}</p>
                <p className="sidebar-menu-header-text">{t('my_profile_desc')}</p>
              </div>
            </Link>

            <Link to="/account-settings" className={`sidebar-menu-item ${path_name === '/account-settings'?" selected":""}`}>
              <div className="sidebar-menu-header accordion-trigger-linked">
              <img src="/Assets/Setting.png" alt=""  className="sidebar-menu-header-icon icon-profile"/>
                <p className="sidebar-menu-header-title">{t('account')}</p>
                <p className="sidebar-menu-header-text">{t('account_desc')}</p>
              </div>

              
            </Link>
            {role !== 'organization' && <Link to="/privacy-settings" className={`sidebar-menu-item ${path_name === '/privacy-settings'?" selected":""}`}>
              <div className="sidebar-menu-header accordion-trigger-linked">
                <img src="/Assets/Privacy_Settings.png" alt=""  className="sidebar-menu-header-icon icon-profile"/>
                <p className="sidebar-menu-header-title">{t('privacy_setting')}</p>
                <p className="sidebar-menu-header-text">{t('privacy_desc')}</p>
              </div>
            </Link>}            
          </div>

          {/* <div className="sidebar-box-footer">
            <p className="button primary">Save Changes!</p>
            <p className="button white small-space">Discard All</p>
          </div> */}
      </div>
    );
}

export default SettingsSidebar;