import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserLayout from "../../components/dashboard-layout";
import AllGlobalEventsSearch from "../../components/dashboard/globalSearch/allGlobalEventSearch";
import { selectSearchText } from "../../Reducer/user/user.selector";
import { getAllglobalList } from "../../services/onBoarding.services";
const AllGlobalEventSearchComp = () => {
    //Event List
    const [isLoading, setIsLoading] = useState(false);
    const searchText = useSelector(selectSearchText)

    useEffect(() => {
        if(searchText!==""){
            allData();
        }else {setIsLoading(false)}
        
    }, [searchText])


    const allData = async () => {
        await getEventList();
    }

    const limit = 10;
    const [totalPageForEvent, setTotalPageForEvent] = useState(0);
    const [currentPageNoForEvent, setCurrentPageNoForEvent] = useState(0);

    const handlePageClick = (data) => {
        let Page = data.selected
        getEventList(Page)
      }

    const [eventList, setEventList] = useState([])
    const getEventList = async (countForEvent = 0) => {
        setCurrentPageNoForEvent(countForEvent);
        let searchKey=!!searchText?searchText:'i'
        getAllglobalList(searchKey, 'event', countForEvent, limit)
            .then((response) => {
                if (response.data.status === 200) {
                    const _data = response.data.data.data;
                    setEventList(_data)
                    let pageCount = (response?.data?.data?.totalCount) / limit;
                    setTotalPageForEvent(Math.ceil(pageCount));
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    return <UserLayout>
        <AllGlobalEventsSearch eventList={eventList} isLoading={isLoading}totalPageForEvent={totalPageForEvent}handlePageClick={handlePageClick} />
    </UserLayout>;
}

export default AllGlobalEventSearchComp;