import React from "react";
import { useTranslation } from "react-i18next";
import Moment from 'react-moment';
const PersonalInfo = ({ userDet }) => {
  let orgObj=userDet?.organization?.find((oc)=>oc?.isCurrentlyWorking);
  const {t} = useTranslation();
    return (
    <div className="widget-box">  
      <p className="widget-box-title">{t('personal_info')}</p>
  
      <div className="widget-box-content">
  
        <div className="information-line-list">
          {userDet?.email &&
          <div className="information-line">
            <p className="information-line-title">{t('form.label_email')}</p>
  
            <p className="information-line-text text-break">{userDet?.email?userDet?.email:'_'}</p>
          </div>
           }
           {userDet?.mobile &&
          <div className="information-line">
            <p className="information-line-title">{t('Mobile')}</p>
  
            <p className="information-line-text">{userDet?.mobile?userDet?.mobile:'_'}</p>
          </div>
           }
          {userDet?.dob &&
          <div className="information-line">
            <p className="information-line-title">{t('form.label_bday')}</p>
  
            <p className="information-line-text">{userDet?.dob? <Moment format="MMMM Do, YYYY">{userDet?.dob}</Moment>:'_'}</p>
          </div>
           }
           { orgObj?.designation &&
          <div className="information-line">
            <p className="information-line-title">{t('occupation')}</p>
  
            <p className="information-line-text text-break">{orgObj?.designation?(orgObj?.designation):'_'}</p>
          </div>
          }
        </div>
      </div>
    </div>
  )
}

export default PersonalInfo;