import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { Box } from "@mui/material";
import EditOrgLegalServiceType from "../../../../commonComponents/editOrgLegalServiceType";
import SaveEditSettingsBtn from "../../../../commonDashboardComp/saveEditSettingsBtn";
import { getLegalServiceTypeService } from "../../../../services/orgAuth.services";

const OrgBusinessTypeSetting = ({ canEdit, onEdit, saveUserDetails, formValues, setFormValues, legalServiceType, additionServiceType, saveNewLegalType, saveNewServiceType }) => {
    const { t } = useTranslation();
    const onSave = (e) => {
        e.preventDefault();
        let params = {
            legalStructure: formValues.legalStructure,
            serviceType: formValues.serviceType
        }
        saveUserDetails(params, 'businessTypeInfo')
    }

    return (<div className="widget-box">
        <p className="widget-box-title my-2">
            <SaveEditSettingsBtn canEdit={canEdit} onEdit={onEdit} onSave={onSave} />
            <span className='mb-3'>{t('About_Your_Business')}</span>
        </p>

        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '98%' },
            }}
            noValidate
            autoComplete="off"
            className=""
        >
            <Row>
                <EditOrgLegalServiceType
                    canEdit={canEdit}
                    legalServiceType={legalServiceType}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    additionServiceType={additionServiceType}
                    saveNewLegalType={saveNewLegalType}
                    saveNewServiceType={saveNewServiceType}
                />
            </Row>
        </Box>
    </div>)
}

export default OrgBusinessTypeSetting;