import React, {Suspense} from 'react';
import AuthRoutes from './routes/AuthRoutes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CommonProvider } from './context/common.context';
import { ToastContainer } from 'react-toastify';
import SwitchLanguage from './components/switchLanguage/switchLanguage';
import './AppInterceptor.js';
import './i18n';
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Gill Sans'
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={null}>
        {/* <SwitchLanguage/> */}
        <CommonProvider>
          <AuthRoutes/>
          <ToastContainer position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
        </CommonProvider>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
