import React from "react";
import { Row } from "react-bootstrap";
import {Box,  
    Switch } from '@mui/material'
import SectionHeader from "../../../../commonDashboardComp/sectionHeader";
import { useTranslation } from "react-i18next";
const GeneralSettingInfo = ({setGeneralSettingsValues, generalSettingsValues, saveGeneralSettings}) => {
    const { t } = useTranslation();
    return (<>
        <SectionHeader  title={t('form.General_Settings')}  style={{marginTop:'0px'}}/>
        <Row className="grid">
            <div className="grid-column">
                <div className="widget-box area-widget">
                    <p className="widget-box-title">{t('form.General_Settings')}</p>
                    <div className="widget-box-content">
                    <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '98%' },
                            }}
                            noValidate
                            autoComplete="off"
                            >
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <div className="span_heading">
                                    <h3 className="">{t('form.Search')}</h3>
                                    <p>{t('form.People_search_for')}</p>
                                </div>
                            </div>
                            <div className="text-end margin-left">
                                <Switch 
                                    checked={generalSettingsValues.search}
                                    onChange={(e)=>
                                                {setGeneralSettingsValues({...generalSettingsValues, search : e.target.checked}); 
                                                saveGeneralSettings({"settings": {'search':e.target.checked}})}}
                                    aria-label='Switch demo' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div md={10}>
                                <div className="span_heading">
                                    <h3 className="">{t('form.Tagging')}</h3>
                                    <p>{t('form.People_tag_me_on_post')}</p>
                                </div>
                            </div>
                            <div md={2} className="text-end">
                                <Switch 
                                    checked={generalSettingsValues.tagging}
                                    onChange={(e)=>{setGeneralSettingsValues({...generalSettingsValues, tagging : e.target.checked}); 
                                    saveGeneralSettings({"settings": {'tagging':e.target.checked}})}}
                                    aria-label='Switch demo' />
                            </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                            <div md={10}>
                                <div className="span_heading">
                                    <h3 className="">{t('form.Facts_Tips')}</h3>
                                    <p>{t('form.Facts_Tips')}</p>
                                </div>
                            </div>
                            <div md={2} className="text-end">
                                <Switch 
                                    checked={generalSettingsValues.factAndTips}
                                    onChange={(e)=>{setGeneralSettingsValues({...generalSettingsValues, factAndTips : e.target.checked}); 
                                    saveGeneralSettings({"settings": {'factAndTips':e.target.checked}})
                                }}
                                    aria-label='Switch demo' />
                            </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                            <div md={10}>
                                <div className="span_heading">
                                    <h3 className="">{t('form.Events')}</h3>
                                    <p>{t('form.Youll_be_notified')}</p>
                                </div>
                            </div>
                            <div md={2} className="text-end">
                                <Switch 
                                    checked={generalSettingsValues.events}
                                    onChange={(e)=>{setGeneralSettingsValues({...generalSettingsValues, events : e.target.checked}); 
                                                    saveGeneralSettings({"settings": {'events':e.target.checked}})
                                    }}
                                    aria-label='Switch demo' />
                            </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                            <div md={10}>
                                <div className="span_heading">
                                    <h3 className="">{t('form.Email_notifications')}</h3>
                                    <p>{t('form.notifications_on_off')}</p>
                                </div>
                            </div>
                            <div md={2} className="text-end">
                                <Switch 
                                    checked={generalSettingsValues.emailNotifications}
                                    onChange={(e)=>{setGeneralSettingsValues({...generalSettingsValues, emailNotifications : e.target.checked}); 
                                    saveGeneralSettings({"settings": {'emailNotifications':e.target.checked}})
                                }}
                                    aria-label='Switch demo' />
                            </div>
                            </div>
                    </Box>
                    </div>
                </div>
            </div>
            
        </Row></>)
}

export default GeneralSettingInfo;