import React from "react";
import { useTranslation } from "react-i18next";
import { eventActivityTypeList } from "../../config/constants";
import RoundRadio from "../../commonComponents/roundCheckbox/roundRadio";
import { Row, Col } from "react-bootstrap";
import { Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Remove } from "@mui/icons-material";
import { allowOnlyLettersNumbers} from "../../utils/helpers";

const EventActivityStep = ({activity, setActivityType, 
    activityType, onChangeActivityHandler, 
    addActivityHandler, removeActivityHandler, 
    AddTaskStepHandler, RemoveTaskStepHandler,
    error
}) => {
    const {t} = useTranslation();
    return (<Row>

        <Col md={12}> 
            <p className="formInsideHeading1 event_config_heading widget-box-title"> 
            {t('event_has')}:  
            </p>

            {eventActivityTypeList.length >0 && eventActivityTypeList.map((item, index) => 
            <RoundRadio name="activityType" 
            key={`eventAct`+index} 
            label={item.label} 
            value={item.value}
            checked={(activityType===item.value) ? true : false}
            onChange={(e)=>(e.target.checked) ? setActivityType(item.value):''}
            />
            )}
        </Col>
        {activityType === 'single' && <>
            {activity.length > 0 && <Col lg={3} className="col-md-4 eventHaveContainer">
                    <ul className="eventHave">
                        <li>
                            <TextField 
                                type='text' 
                                label={t('superAdmin.Activity')}
                                onChange={(e)=>
                                    onChangeActivityHandler(
                                       e.target.value,
                                       0
                                    )}
                                value={activity[0].title}
                            />
                            {error && activity[0].title === '' && <div className="error-text" >{t('errors.required_field')}. </div>}
                        
                        
                            <ul className="actTasks">
                                {activity[0].tasks.length > 0 && activity[0].tasks.map((task,taskIndex) => {
                                    let task_btn = (taskIndex===0)?
                                    <Button className="button secondary addEventBtn" onClick={(e)=>AddTaskStepHandler(e,'tasks', 0)}>
                                        <AddIcon style={{fill:"#fff"}} /></Button>:
                                    <Button className="button secondary addEventBtn" onClick={(e)=>RemoveTaskStepHandler(e, 'tasks' ,0, taskIndex)}>
                                        <Remove style={{fill:"#fff"}}/></Button>

                                    return (<li key={`task`+taskIndex}>
                                        <TextField
                                        type='text' 
                                        label={t('superAdmin.Task')}
                                        className="addEventInput"
                                        onChange={(e)=>
                                            onChangeActivityHandler(
                                            allowOnlyLettersNumbers(e.target.value),
                                            0,
                                            'tasks',
                                            taskIndex
                                            )}
                                        value={task.title}
                                        InputProps={{endAdornment: task_btn}}/>
                                        {/* {error && task.title === '' && <div className="error-text"  ref={scollToRef}>{t('errors.required_field')}. </div>} */}

                                        <ul className="taskSteps">
                                            {task.steps.length > 0 && task.steps.map((step,stepIndex) => {
                                                let step_btn = (stepIndex===0)?
                                                <Button className="button secondary addEventBtn" onClick={(e)=>AddTaskStepHandler(e,'steps', 0, taskIndex)}>
                                                    <AddIcon style={{fill:"#fff"}} /></Button>:
                                                <Button className="button secondary addEventBtn" onClick={(e)=>RemoveTaskStepHandler(e, 'steps' ,0, stepIndex, taskIndex)}>
                                                    <Remove style={{fill:"#fff"}}/></Button>

                                                return (<li className="addEventInput" key={`step`+stepIndex}>
                                                    <TextField
                                                    type='text' 
                                                    label={t('superAdmin.Step')}
                                                    onChange={(e)=>
                                                        onChangeActivityHandler(
                                                        allowOnlyLettersNumbers(e.target.value),
                                                        0,
                                                        'steps',
                                                        stepIndex,
                                                        taskIndex
                                                        )}
                                                    value={step.title}
                                                    InputProps={{endAdornment: step_btn}}/>
                                                    {/* {error && step.title === '' && <div className="error-text"  ref={scollToRef}>{t('errors.required_field')}. </div>} */}
                                                </li>)}
                                            )}
                                        </ul>
                                    </li>)}
                                )}
                            </ul>
                        </li>
                    </ul>
                </Col>}
        </>}

        {activityType === 'multiple' && <>
            {activity.length > 0 && activity.map((item, index)=>{
                let btn = (index !== 0 )?<Button className="button secondary addEventBtn" onClick={(e)=>removeActivityHandler(e, index)}>
                    <Remove style={{fill:"#fff"}}/>
                </Button>:
                <Button className="button secondary addEventBtn" onClick={(e)=>addActivityHandler(e)}>
                    <AddIcon style={{fill:"#fff"}}/>
                </Button>

                return (<Col md={3} key={`act`+index}>
                    <ul className="eventHave">
                        <li className="addEventInput">
                            <TextField 
                                type='text' 
                                label={t('superAdmin.Activity')}
                                onChange={(e)=>
                                    onChangeActivityHandler(
                                       allowOnlyLettersNumbers(e.target.value),
                                       index,
                                    )}
                                value={item.title}
                                InputProps={{endAdornment: btn}}/>
                                {/* {error && item.title === '' && <div className="error-text"  ref={scollToRef}>{t('errors.required_field')}. </div>} */}

                                <ul className="actTasks">
                                    {item.tasks.length > 0 && item.tasks.map((task,taskIndex) => {
                                        let task_btn = (taskIndex===0)?
                                        <Button className="button secondary addEventBtn" onClick={(e)=>AddTaskStepHandler(e,'tasks', index)}>
                                            <AddIcon style={{fill:"#fff"}} /></Button>:
                                        <Button className="button secondary addEventBtn" onClick={(e)=>RemoveTaskStepHandler(e, 'tasks' ,index, taskIndex)}>
                                            <Remove style={{fill:"#fff"}}/></Button>

                                        return (<li className="addEventInput" key={`task`+taskIndex}>
                                            <TextField
                                            type='text' 
                                            label={t('superAdmin.Task')}
                                            onChange={(e)=>
                                                onChangeActivityHandler(
                                                allowOnlyLettersNumbers(e.target.value),
                                                index,
                                                'tasks',
                                                taskIndex
                                                )}
                                            value={task.title}
                                            InputProps={{endAdornment: task_btn}}/>
                                            {/* {error && task.title === '' && <div className="error-text"  ref={scollToRef}>{t('errors.required_field')}. </div>} */}

                                            <ul className="taskSteps">
                                                {task.steps.length > 0 && task.steps.map((step,stepIndex) => {
                                                    let step_btn = (stepIndex===0)?
                                                    <Button className="button secondary addEventBtn" onClick={(e)=>AddTaskStepHandler(e,'steps', index, taskIndex)}>
                                                        <AddIcon style={{fill:"#fff"}} /></Button>:
                                                    <Button className="button secondary addEventBtn" onClick={(e)=>RemoveTaskStepHandler(e, 'steps' ,index, stepIndex, taskIndex)}>
                                                        <Remove style={{fill:"#fff"}}/></Button>

                                                    return (<li className="addEventInput" key={`step`+stepIndex}>
                                                        <TextField
                                                        type='text' 
                                                        label={t('superAdmin.Step')}
                                                        onChange={(e)=>
                                                            onChangeActivityHandler(
                                                            allowOnlyLettersNumbers(e.target.value),
                                                            index,
                                                            'steps',
                                                            stepIndex,
                                                            taskIndex
                                                            )}
                                                        value={step.title}
                                                        InputProps={{endAdornment: step_btn}}/>
                                                        {/* {error && step.title === '' && <div className="error-text"  ref={scollToRef}>{t('errors.required_field')}. </div>} */}
                                                    </li>)}
                                                )}
                                            </ul>
                                        </li>)}
                                    )}
                                </ul>
                        </li>                        
                    </ul>
                </Col>)
            })
            }
        </>
        }
        
        
    </Row>)
}

export default EventActivityStep;