import React , {useEffect, useState} from "react";
import './friends.style.css';
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import AllFriends from "./allFriends";
import FriendReqReceived from "./FriendsReqReceived";
import FriendReqSent from "./FriendsReqSent";
import FindFriends from "./findFriends";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import TabNavigation from "../../../commonDashboardComp/tabNavigation";
import { useTranslation } from "react-i18next";
import InviteFriends from "./inviteFriends";
import { FriendOrgCount } from "../../../services/friends.service";
//import AllUsers from "./allUsers";

const Friends = () => {
    const { t } = useTranslation();
    const [tab, setTab] = useState(1);
    const [searchRes,setSearchRes]=useState('');
    const [totalFriends,setTotalfriends]=useState(0);
    const [countsFriends,setCountsFriends]=useState();
    const tb=useState([t('Friends_List'),t('Friends_Request_Received'),t('Friends_Request_Sent'),t('Find_Friends'),t('invite_friends')]);
    const tabs = [`${t('Friends_List')} ${ countsFriends?.friends>0 ?`(${(countsFriends?.friends)||0})`:''}` 
                  ,`${ t('Friends_Request_Received')} ${countsFriends?.receivedRequests>0?`(${countsFriends?.receivedRequests||0})`:''}`, 
                  `${t('Friends_Request_Sent')} ${countsFriends?.sentRequests>0?`(${countsFriends?.sentRequests ||0})`:''}`,
                   t('Find_Friends'),t('invite_friends')];

    useEffect(()=>{
       getFriends();
    },[])

    const getFriends=async()=>{
       let resp= await FriendOrgCount()
       if(resp?.status===200){
           setCountsFriends(resp?.data?.data);
       }
    }
   
    const onKeyDownSearchText=(search)=>{
        setSearchRes(search);        
    }

    const searchUpdate = search => {
        setSearchRes(search);
    };

   //count={totalFriends}
   return (<>

        {/* <div className="col-md-12"> <ProfileHeader coverImg='' img=''/> </div> */}

        {/* {tab===5?
        <SectionHeader pretitle="" title={t('invite_friends')} />
       : <SectionHeader pretitle="" title={tabs[tab-1]} />
        } */}
        <SectionHeader pretitle="" title={t('Friend')}/>

        <TabNavigation tabs={tabs} setTab={setTab} tabClass={'friendsNavigation'} activeTab={tab} inputPlaceholder={t('friendsMsg.SEARCH_FRIENDS')} onChangeSearchText={searchUpdate}   onKeyUpSearch={onKeyDownSearchText} value={searchRes} />

        {tab === 1 && <AllFriends searchBy={searchRes} countsOfFriends={setTotalfriends} callParentCountMethod={getFriends}/>}
        {tab === 2 && <FriendReqReceived searchBy={searchRes} countsOfFriends={setTotalfriends} callParentCountMethod={getFriends} />}
        {tab === 3 && <FriendReqSent searchBy={searchRes} countsOfFriends={setTotalfriends} callParentCountMethod={getFriends} />}
        {tab === 4 && <FindFriends searchBy={searchRes} countsOfFriends={setTotalfriends} callParentCountMethod={getFriends}/>}
        {tab===5 && <InviteFriends />}
        
        </>
    )
}

export default Friends 