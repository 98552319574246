import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getImageUrl } from "../../utils/helpers";

const Blogs = ({ blogs }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Container fluid className="blogs-div">
            <div className="blog-container">
                <Row className="panchtatva-row1 mb-4">
                    <h1 className="heading"> {t('homepage.blogs.title')} </h1>
                    <p className="detail"> {t('homepage.blogs.sub_title')}</p>
                </Row>
                <Row>
                    {
                        blogs?.length > 0 &&
                        blogs.map((item, index) => {
                            return (

                                <Col md={6} className="col-lg-4" key={index}>
                                    <div className="blogs-row2">
                                        <img src={getImageUrl(item?._id, 'cms', item?.blogImg)} alt="blogsImg" className="img" />
                                        <div className="blog_detail">
                                            <p className="heading"> {item?.title} </p>
                                            <p className="desc">
                                                {item?.description}
                                            </p>
                                            <p className="blog_link" onClick={(e) => {
                                                e.preventDefault();
                                                navigate('/blogsDetail', { state: { blogId: item?._id } })
                                            }}>
                                                <Link to="" >{t('button.read_more')}</Link>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })

                    }
                </Row>
                <Col className="text-center mt-3">
                {blogs.length < 3 ? null : <button type="" className="target-btn view-btn" style={{width:"123px", margin:"auto"}} onClick={(e) => {
                    e.preventDefault();
                    navigate('/blogs')
                }}  >{t('button.view_more')}</button>}
                </Col>
            </div>

        </Container>
    );
}

export default Blogs;