import { t } from 'i18next';
import React from 'react'
import { Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import AvatarImgBox from '../../../commonComponents/AvatarImgBox/avatarImgBox'
import { AvatarImg } from '../../../commonComponents/Images';
import Loader from '../../../commonComponents/Loader';
import ReactPagination from '../../../commonComponents/reactPagination';
import { getImageUrl } from '../../../utils/helpers';

function AllEventsSerchList({ eventList, isLoading, totalPageForEvent, handlePageClick }) {
    const navigate = useNavigate()
    return (
        <div className="widget-box">
            <>
                {
                    isLoading ? <Loader /> : eventList.length > 0 ?
                        <>
                            {
                                eventList.length > 0 &&
                                <>
                                    {
                                        eventList.map((item, i) => (
                                            <div className="widget-box blocked_user_area" style={{ marginTop: 10,marginBottom: 10, cursor:'pointer' }} key={i}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate('/view-event', { state: { eventId: item?._id } })
                                                }}
                                            >
                                                <div className="">
                                                    <Row className="align-items-center" style={{ justifyContent: 'space-between' }}>
                                                        <div className="col-lg-7 col-md-6 d-flex align-items-center gray_plate">
                                                            <div className="hexagon-image-30-32"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigate('/view-event', { state: { eventId: item?._id } })
                                                                }}
                                                            >
                                                                <AvatarImgBox
                                                                    img={(item?.photo !== '') ? getImageUrl(item?._id, 'event', item?.photo) : AvatarImg}
                                                                />
                                                            </div>
                                                            <div className="">
                                                                <h5 className="bold">{`${item?.title}`}</h5>
                                                                {/* <h5 className="bold">{`${item?.createdBy}`}</h5> */}
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-lg-5 col-md-6">
                                                            <div className="action-request-list globalSearchList justify-content-end">
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        navigate('/view-event', { state: { eventId: item?._id } })
                                                                    }}

                                                                    className="button primary view-event-btn">{t('button.view')}</button>
                                                            </div>
                                                        </div> */}
                                                    </Row>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                            }
                        </> :
                        <>
                            <>
                                {eventList.length === 0 &&
                                    <>
                                        <div style={{ display: 'flex', marginTop: 10 }}>
                                            {t('errors.There_is_no_Events')}
                                        </div>
                                    </>
                                }
                            </>
                        </>
                }
            </>
            <ReactPagination pageCount={totalPageForEvent} onPageChange={handlePageClick} />
        </div>
    )
}

export default AllEventsSerchList