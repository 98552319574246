import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { setCurrentUser, setCurrentRole } from '../../Reducer/user/user.action';
import { useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Row} from 'react-bootstrap';
import { validEmail } from "../../utils/formatValidator";
import { SuperLoginService, getUserDetailsService,getIPAddressService } from '../../services/auth.services';
import Header from '../header/header';
import { setObjectInStore, getObjectFromStore, removeStoreItem } from '../../storage/Storage';
import { showToastError } from '../../utils/helpers';
import GreenCheckBox from '../../commonComponents/greenCheckbox/greenCheckbox';
import './super-admin-login.css';
import { getCmsServiceList } from '../../services/cms.services';
import { LanguageList } from '../../config/constants';
import i18next from 'i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { browserName, isMobile } from "react-device-detect";


const SuperAdminSignin = ({ }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({ email: '', password: '' })
    const [error, setError] = useState(false);
    const [isLoading, setLoader] = useState(false);
    const [remember, setRemember] = useState(false);
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(false);


    const defaultLanguage = i18next?.language?.split('-')[0];
    const find = LanguageList?.find(a => a.value === defaultLanguage);
    const [cmsData, setcmsData] = useState([])


    const [language, setLanguage] = useState((find?.name) ? find : LanguageList[0])
    const [ipData, setIpData] = useState({});

    useEffect(() => {
        getIpData();
    }, [])

    const getIpData = () => {
        try{
            getIPAddressService()
            .then((data) => {
                setIpData(data?.data)
            })
            .catch((error) => {
                console.log(error);
            })
        }
        catch(e){
            console.log(e);
        }  
    }

    //Cms Deta language
    useEffect(() => {
        let filter = LanguageList?.find(a => a.value === defaultLanguage);

        setLanguage((filter?.name) ? filter : LanguageList[0])
    }, [defaultLanguage])

    React.useEffect(() => {
        const handleLanguageChange = () => {

            let defaultLanguage1 = i18next?.language?.split('-')[0];

            let filter = LanguageList?.find(a => a.value === defaultLanguage1);

            setLanguage((filter?.name) ? filter : LanguageList[0])
        };

        i18next.on("languageChanged", handleLanguageChange);

        return () => {
            i18next.off("languageChanged", handleLanguageChange);
        };
    }, []);
    //..............................}


    useEffect(() => {
        let rememberMeObj = getObjectFromStore('rememberMe');
        if (rememberMeObj !== null && rememberMeObj !== undefined) {
            setFormValues({ ...formValues, email: rememberMeObj?.email, password: rememberMeObj?.password });
            setRemember(true);
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formValues.email === '' || formValues.password === '') { setError(true); return; }
        else if (!validEmail(formValues.email)) { setError(true); return }
        else if (formValues.password.length < 8) { setError(true); return }
        //else if(!passwordRegexValidate(formValues.password)){  setError(true); return }

        setLoader(true)

        try {
            let params = {

                "loginId": formValues.email,
                "password": formValues.password,
                "ipAddress": ipData?.IPv4,
                "location": ipData?.country_name,
                "browser": browserName,
                "device": (isMobile) ? 'Mobile' : 'Desktop'
            }
            SuperLoginService(params)
                .then(async (data) => {
                    if (data.status === 200) {
                        let rememberMeObj = {
                            email: formValues.email.toLowerCase(),
                            password: formValues.password,
                            rememberMe: remember,
                            token: data?.data?.data?.accessToken
                        }
                        setObjectInStore("token", data?.data?.data?.accessToken);
                        setObjectInStore('refresh_token', data?.data?.data?.refreshToken);
                        getLoginUserDetails();
                        if (remember) {
                            setObjectInStore("rememberMe", rememberMeObj)
                        } else {
                            removeStoreItem("rememberMe")
                        }
                    } else {
                        setError(true);
                        showToastError(t(`apiMessages.${data?.data?.data}`))
                    }
                    setLoader(false)
                })
                .catch((error) => {
                    setError(true);
                    if (error?.data?.data) {
                        showToastError(t(`apiMessages.${error?.data?.data}`))
                    }
                    else {
                        showToastError(t(`apiMessages.PASSWORD_NOT_CORRECT`))
                    }

                });
        }
        catch (error) {
            console.log(error);
        }
    }

    const getLoginUserDetails = async () => {
        const response = await getUserDetailsService();
        if (response?.data?.data) {
            const user = response?.data?.data
            dispatch(setCurrentUser(user));
            dispatch(setCurrentRole('superAdmin'));
            navigate('/voxbox');
        }
    }

    //{..............get log through CMS
    useEffect(() => {
        if (language !== null) { getCmsData(); }
    }, [language]);

    const getCmsData = async () => {
        getCmsServiceList(language?.value)
            .then((data) => {
                if (data?.data?.status === 200) {
                    const _data = data.data.data;
                    setcmsData(_data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    //..........................}

    const handlePassView = () => {
        if (type === 'password') {
            setIcon(true);
            setType('text');
        } else {
            setIcon(false);
            setType('password');
        }
    }
    return (
        <div className='bg-body1 pb-5'>
            <Header cmsData={cmsData} headerClasses={"superAdminHeader"} />
            <div className='superAdminLoginBanner'>
                <Row>
                    <div className='col-lg-6 col-md-6 position-relative p-0 overflow-hidden'>
                        <img src="../Assets/Super-admin-login-img.png" className="h-100 sl-img" />
                        <h1 className='welcome-heading'><span>{t('superAdmin.Hi')},</span> {t('superAdmin.Welcome')} !</h1>
                    </div>
                    <div className='col-lg-6 col-md-6 bg-white p-0 superAdmin-form'>
                        <Box sx={{
                            '& .MuiTextField-root': { m: 1, width: '98%' },
                        }}
                            component="form"
                            noValidate
                            autoComplete="off"
                            className="form "
                            onSubmit={handleSubmit}
                        >
                            <h1 className="form-box-title">{t('form.superAdmin_heading')}</h1>
                            <div className='superAdminLoginForm'>
                                <TextField
                                    error={(error && formValues.email === '') ? true : false}
                                    helperText={(error && formValues.email === '') ? t('errors.email_required') : ''}
                                    value={formValues.email}
                                    onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                    label={t('form.label_email')} />

                                <TextField
                                    error={(error && formValues.password === '') ? true : (false)}
                                    helperText={(error && formValues.password === '') ? t('errors.password_required') : ''}
                                    type={type}
                                    value={formValues.password}
                                    onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                                    InputProps={{
                                        endAdornment: <span className="passwordEye" onClick={handlePassView}style={{cursor:'pointer'}}>
                                            {icon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </span>
                                    }}
                                    label={t('form.label_password')} />
                            </div>
                            <div className="form-row space-between superAdminCheckBox">
                                <GreenCheckBox
                                    label={t('form.label_remember_me')}
                                    onChange={(e) => setRemember(e.target.checked)}
                                    checked={(remember) ? true : false}
                                />
                            </div>

                            <div className="form-row">
                                <div className="form-item">
                                    <button className="button medium primary web-btn">{t('form.login_account')}</button>
                                </div>
                            </div>

                        </Box>
                    </div>
                </Row>
            </div>

        </div>
    )

}
export default SuperAdminSignin;