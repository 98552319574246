import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import BarsLoader from "../../../commonComponents/barsLoader";
import TargetDescriptionBox from "../../../commonDashboardComp/targetDescriptionBox";
import { getPendingInvitedTargetsService } from "../../../services/onBoarding.services";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../../../Reducer/user/user.selector";

const PendingInvitedTargets = ({getAllCount, filter}) => {

    const role = useSelector(selectCurrentUserRole);

    const limit = 20;
    const { t } = useTranslation();
    const [targetData, setTargetData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [hasMore, SetHasMore] = useState(true);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        setOffset(0)
        setTargetData([])
        SetHasMore(true);
    }, [filter])

    // useEffect(() => {
    //     setCount(targetData.length)
    // }, [targetData])

    const getTargets = async () => {
        setLoading(true);
        let page = offset / limit;
        let newOffset = offset + limit;
        getPendingInvitedTargetsService(page, limit, filter)
            .then((response) => {
                if (response?.data?.status === 200) {
                    if (response?.data?.data?.data?.length === 0 || response?.data?.data?.data?.length < limit) {
                        SetHasMore(false);
                    }
                    const _data = response?.data?.data?.data;
                    setTargetData([...targetData, ..._data]);
                }
                setLoading(false)
                setOffset(newOffset);
            })
            .catch((error) => {
                setLoading(false);
                SetHasMore(false);
            })
    }

    return (
        <>
            <InfiniteScroll
                pageStart={0}
                loadMore={getTargets}
                hasMore={hasMore}
                loader={<BarsLoader key={0} />}
                useWindow={false}
            >
                <div className="container-fluid">
                    <Row>
                    {targetData.length > 0 && <>
                        {targetData.map((e, i) => (
                            <div key={i}  className="pendingInviteTargetCol col-12 col-sm-6 col-lg-12 mt-2">
                                <TargetDescriptionBox
                                    targetData={e}
                                    canChangeDetail={false}
                                    acceptReject={true}
                                    getAllCount={getAllCount}
                                />
                            </div>
                        ))}
                    </>}
                    </Row>
                </div>

            </InfiniteScroll>

            {!isLoading && targetData.length === 0 &&
                <>
                    <h4 className="nopeMsg mt-4">{t('no_targets')}</h4>

                </>}
        </>
    )
}

export default PendingInvitedTargets;