import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import GreenCheckBox from "../../commonComponents/greenCheckbox/greenCheckbox";
import Checkbox from '@mui/material/Checkbox';
import Popup from "../../commonComponents/popup";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { validEmail, firstLastNameValidate, passwordRegexValidate, firstLastNameNumberValidate } from "../../utils/formatValidator";
import { RegisterService, verifyEmailOtpService, ResendRegisterOtp } from "../../services/auth.services";
import { showSingleToastSuccess, showToastError, showToastSuccess } from "../../utils/helpers";
import SocialLogins from "../socialLogins/socialLogins";
import ReCAPTCHA from "react-google-recaptcha";
const SignInForm = ({ setLogin }) => {
    const captchaRef = useRef(null);    
    const { t } = useTranslation();
    const loginFormRef = useRef(null)
    const [open, setOpen] = useState(false);
    const [counter, setCounter] = useState(0);
    const [isLoading, setLoader] = useState(false);
    //forPass
    const [pType, setPtype] = useState('password');
    const [pIcon, setPicon] = useState(false);
    //for Fpass
    const [fpType, setFptype] = useState('password');
    const [fpIcon, setFpicon] = useState(false);

    //for Invitation Code
    const [inviType, setInviType] = useState('password');
    const [inviIcon, setInviIcon] = useState(false);
    const navigate = useNavigate();

    const handleOtpBoxOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setFormValues({ ...formValues, otp: '' }) }

    const [formValues, setFormValues] = useState({ name: '', email: '', password: '', repeat_password: '', otp: '', termsCheck: false, invitationCode: '' });
    const [error, setError] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const [invitationCodeError, setInvitationCodeError] = useState(false);
    const [incorrectOTP, setIncorrectOTP] = useState(false);
    const [incorrectInvitationCode, setIncorrectInvitationCode] = useState(false);

    useEffect(() => {
        loginFormRef.current.scrollIntoView()
    }, [])

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            } else {
                clearInterval(myInterval)
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    }, [counter]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(false);
        if (formValues.name === '' && formValues.email === '' && formValues.password === '' && formValues.repeat_password === '' || formValues.termsCheck === false) {
            setError(true); return
        }
        else if (!firstLastNameValidate(formValues.name)) { setError(true); return }
        else if (!firstLastNameNumberValidate(formValues.name)) { setError(true); return }
        else if (!validEmail(formValues.email)) { setError(true); return }
        else if (formValues.email.length > 50) { setError(true); return }
        else if (formValues.password.length < 8) { setError(true); return }
        else if (!passwordRegexValidate(formValues.password)) { setError(true); return }
        else if (formValues.password !== formValues.repeat_password) { setError(true); return }
        else if (!formValues.siteKey || formValues.siteKey == '') {setError(true); return}
        else {
            setLoader(true)
            let index = formValues.name.indexOf(" ");
            let firstName = formValues.name.substr(0, index); // Gets the first part
            let lastName = formValues.name.substr(index + 1);
            let params = {
                "email": formValues.email.toLowerCase(),
                "firstName": firstName,
                "lastName": lastName,
                "password": formValues.password,
                "otp": +formValues.invitationCode,
                "siteKey": formValues.siteKey
            }
            setFormValues({...formValues, siteKey:''});
            captchaRef.current.reset();
            try {
                RegisterService(params)
                    .then(async (data) => {
                        if (data.status === 200) {
                            // showToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`))
                            showSingleToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`))
                            handleOtpBoxOpen();
                            setCounter(60);
                        } else {
                            setError(true);
                            showToastError(t(`apiMessages.${data?.data?.data}`))
                        }
                    })
                    .catch((error) => {
                        setError(true);
                        if (error?.data?.data !== undefined) {
                            showToastError(t(`apiMessages.${error?.data?.data}`))
                        }
                        else {
                            showToastError(t(`errors.some_error`))
                        }
                    })
            }
            catch (error) {
                console.log(error);
            }
            setLoader(false)
        }

    }

    const resendOtp = () => {
        if (formValues.email === '' || !validEmail(formValues.email)) {
            setError(true); handleClose(); return
        }

        try {
            ResendRegisterOtp(formValues.email)
                .then((data) => {
                    if (data.status === 200) {
                        setCounter(60);
                        // showToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`));
                        showSingleToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`));
                    } else {
                        showToastError(t(`apiMessages.${data?.data?.data}`))
                    }
                })
                .catch((error) => {
                    setError(true);
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const otpSubmit = async (e) => {
        e.preventDefault();
        setOtpError(false);
        if (formValues.otp === '' || formValues.otp.length < 6) {
            setOtpError(true); return;
        }
        setInvitationCodeError(false);
        if(formValues.invitationCode === '' || formValues.invitationCode.length < 6){
            setInvitationCodeError(true); return;
        }
        setLoader(true)
        let params = {
            "loginId": formValues.email.toLowerCase(),
            "otp": formValues.otp,
            "invitationCode": formValues.invitationCode,
        }

        verifyEmailOtpService(params)
            .then(async (data) => {
                if (data.status === 200) {
                    // showToastSuccess(t(`apiMessages.USER_REGISTER_SUCCESS`));
                    showSingleToastSuccess(t(`apiMessages.USER_REGISTER_SUCCESS`));
                    navigate('/login');
                } else {
                    setOtpError(true); setIncorrectOTP(true);setIncorrectInvitationCode(true);setInvitationCodeError(true);
                    showToastError(t(`apiMessages.${data.data.data}`))
                }
            })
            .catch((error) => {
                setError(true);
                showToastError(t(`apiMessages.${error?.data?.data}`))
            })
        setLoader(false);
    }

    //for Passsword
    const handlePasswordView = () => {
        if (pType === 'password') {
            setPicon(true);
            setPtype('text');
        } else {
            setPicon(false);
            setPtype('password');
        }
    }
    //for Fpasssword
    const handleFpasswordView = () => {
        if (fpType === 'password') {
            setFpicon(true);
            setFptype('text');
        } else {
            setFpicon(false);
            setFptype('password');
        }
    }

    //For invitation code
    const handleInvitationCodeView = () => {
        if (inviType === 'password') {
            setInviIcon(true);
            setInviType('text');
        } else {
            setInviIcon(false);
            setInviType('password');
        }
    }

    const handleCaptchaChange = (e) =>{
        setFormValues({...formValues, siteKey: e});
    }

    return (
        <div className="landing-form" ref={loginFormRef}>
            <div className="form-box login-register-form-element">
                <h2 className="form-box-title">{t('form.create_account')}</h2>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '98%' },
                    }}
                    noValidate
                    autoComplete="off"
                    className="form"
                    onSubmit={handleSubmit}
                >
                    <div id="registrationForm" className="login-input-boxes registrationInputBox">
                        <TextField
                            error={(error && formValues.email === '') ? true : (error && !validEmail(formValues.email)) ? true : (error && formValues.email.length > 50) ? true : false}
                            helperText={(error && formValues.email === '') ? t('errors.email_required') : (error && !validEmail(formValues.email)) ? t('errors.email_format') : (error && formValues.email.length > 50) ? t('errors.email_limit') : ''}
                            value={formValues.email}
                            onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                            label={t('form.label_email')} />

                        <TextField
                            error={
                                (error && formValues.name === '') ? true
                                    : (error && !firstLastNameValidate(formValues.name)) ?
                                        true
                                        : (error && !firstLastNameNumberValidate(formValues.name)) ? true : false}
                            helperText={(error && formValues.name === '') ? t('errors.name_required') :
                                (error && !firstLastNameNumberValidate(formValues.name)) ? t('errors.name_format') :
                                    (error && !firstLastNameValidate(formValues.name)) ? t('errors.name_length') : ''}
                            value={formValues.name}
                            onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                            label={t('form.label_fullname')} />

                        <TextField type={pType}
                            error={(error && formValues.password === '') ? true : (error && formValues.password.length < 8) ? true : (error && !passwordRegexValidate(formValues.password)) ? true : false}
                            helperText={(error && formValues.password === '') ? t('errors.password_required') : (error && formValues.password.length < 8) ? t('errors.password_length') : (error && !passwordRegexValidate(formValues.password)) ? t('errors.password_format') : ''}
                            value={formValues.password}
                            onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                            InputProps={{
                                endAdornment: <span className="passwordEye" onClick={handlePasswordView} style={{ cursor: 'pointer' }}>
                                    {pIcon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </span>
                            }}
                            inputProps={{ maxLength: 25, minLength: 8 }}
                            label={t('form.label_password')} />

                        <TextField type={fpType}
                            error={(error && formValues.repeat_password === '') ? true : (error && formValues.repeat_password !== formValues.password) ? true : false}
                            helperText={(error && formValues.repeat_password === '') ? t('errors.repeat_password_required') : (error && formValues.repeat_password !== formValues.password) ? t('errors.repeat_password_wrong') : ''}
                            value={formValues.repeat_password}
                            onChange={(e) => setFormValues({ ...formValues, repeat_password: e.target.value })}
                            InputProps={{
                                endAdornment: <span className="passwordEye" onClick={handleFpasswordView} style={{ cursor: 'pointer' }}>
                                    {fpIcon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </span>
                            }}
                            inputProps={{ maxLength: 25, minLength: 8 }}
                            label={t('form.label_repeat_password')} />
                            <TextField type={inviType}
                                className="mt-3"
                        error={(invitationCodeError && formValues.invitationCode === '') ? true : (invitationCodeError && formValues.invitationCode.length < 6) ? true : (invitationCodeError && incorrectInvitationCode) ? true : false}
                        helperText={(invitationCodeError && formValues.invitationCode === '') ? t('errors.enterInvitationCode') : (invitationCodeError && formValues.invitationCode.length < 6) ? t('errors.invitationSixDigit') : (invitationCodeError && incorrectInvitationCode) ? t('errors.invitationCodeMismatch') : ""}
                        value={formValues.invitationCode}
                        InputProps={{
                            endAdornment: <span className="passwordEye" onClick={handleInvitationCodeView} style={{ cursor: 'pointer' }}>
                                {inviIcon ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </span>
                        }}
                        inputProps={{ maxLength: 6, minLength: 6 }}
                        onChange={(e) => setFormValues({ ...formValues, invitationCode: e.target.value.slice(0, 6) })}
                        label={t('errors.invitationCode')} />

                    </div>

                    <div className="form-row rsgister_check_options regTermsLabels">
                        <GreenCheckBox label={<>
                            {t('form.terms_conditions_label1')}
                            <a href="/termsandconditions">{t('form.terms_conditions_label2')}</a> and <a href="/privacypolicy">{t('footer.privacy_policy')}</a>
                        </>} onChange={(e) => setFormValues({ ...formValues, termsCheck: e.target.checked })} />
                        {(error && formValues.termsCheck === false) ? <div className='error-text ps-4'>
                            {t('errors.required_field')}
                        </div> : ''}
                        <GreenCheckBox label={t('form.news_updates_email')} />
                        {
                        ((error && !formValues.siteKey) || (error && formValues.siteKey == ''))?<div className="mt-2 error-text">{'Please check captcha'}</div>:''
                    }
                    <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
                    onChange={handleCaptchaChange}
                    ref={captchaRef}
                    className="mt-3"
                    />
                    </div>

                    {/* <div className="form-row space-between">
                        
                    </div> */}

                    <div className="form-item mt-3">
                        <button disabled={isLoading} type="submit" className="button medium secondary login-account-btn">
                            {t('form.register_now')}
                            {isLoading &&
                                <div style={{ marginLeft: "45%" }}>
                                    <div className="spinner-border" role="status" />
                                </div>
                            }
                        </button>

                        <p className="form-text register-btm-text">{t('form.register_text')} <Link to="/login">{t('form.contact_us_text')}</Link></p>
                    </div>
                </Box>

                <SocialLogins />

            </div>

            <Popup heading={t('form.submit_otp')} handleClose={handleClose} open={open} modalContentClasses="registerOtpPopupContent">
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '98%' },
                    }}
                    noValidate
                    autoComplete="off"
                    className="userdetail-form"
                    onSubmit={otpSubmit}
                >
                    <div className="otp_counter">00:{(counter < 10) ? `0${counter}` : counter}</div>
                    <TextField type="password"
                        error={(otpError && formValues.otp === '') ? true : (otpError && formValues.otp.length < 6) ? true : (otpError && incorrectOTP) ? true : false}
                        helperText={(otpError && formValues.otp === '') ? t('errors.Enter_OTP') : (otpError && formValues.otp.length < 6) ? t('errors.OTP_is_of_6digits') : (otpError && incorrectOTP) ? t('apiMessages.OTP_MISS_MATCH') : ""}
                        value={formValues.otp}
                        inputProps={{ maxLength: 6 }}
                        onChange={(e) => setFormValues({ ...formValues, otp: e.target.value.slice(0, 6) })}
                        label={t('errors.otp')} />

                    <div className="d-flex justify-content-left mt-1">
                        {counter === 0 &&
                            <Link className="ps-2" to="#"
                                onClick={(e) => { e.preventDefault(); resendOtp() }}>
                                {t('errors.Resend_Otp')}
                            </Link>
                        }
                    </div>


                    <button disabled={isLoading} type="submit mt-3" className="button grey"> {t('form.submit')}</button>
                </Box>
            </Popup>
        </div>
    )
}

export default SignInForm;