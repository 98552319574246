import ApiConfig from "../config/ApiConfig";
import instance,{instanceOrg} from "../AppInterceptor";
import { getObjectFromStore } from "../storage/Storage";


export const getFriendsInSuggestion = (text,page,limit) => {
    let urlNew=ApiConfig.getSuggestedFriends+'?';
    if(text)urlNew+=`searchText=${text}`;
    if(limit &&page>=0)urlNew+=urlNew!==''?`&page=${page}&limit=${limit}`:`page=${page}&limit=${limit}`;
    return instance.get(urlNew);
}
export const postUserSentReqest = (payload) => {
    return instance.post(ApiConfig.userSentRequestAcion, payload);
}

export const getUsersAllRequestSent = (url) => {
    return instance.get(ApiConfig.userAllsentRequest + url);
}
export const getUsersAllRequestReceived=(url)=>{
    return instance.get(ApiConfig.userAllrecRequest+url);
}
export const postUserCancelRequest=(payload)=>{
    return instance.post(ApiConfig.userCalcelFriendRequest,payload);
}
export const getUsersBySearchRequest=(urlType)=>{
    return instance.get(ApiConfig.getUsersBySearchRequest+urlType);
}
export const postDeleteOrCancelRequest=(payload)=>{
    return instance.post(ApiConfig.userAccepOrDeletetFriendRequest,payload);
}
export const getAllFriendsAddedList=(url)=>{
    return instance.get(ApiConfig.getAllFriendsAdded+url)
}
export const getAnyUserData=(id)=>{
   //let finalUrl=`${ApiConfig.getSomeUserData}${id}`;
   return instance.get(ApiConfig.getSomeUserData+id);
}
export const postAnyUserData=(id)=>{
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.postSomeUserData,{userId:id});
    }
    else{
        return instance.post(ApiConfig.postSomeUserData,{userId:id});
    } 
}

export const postAnyUserDataByUserName=(username)=>{
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.postSomeUserData,{userName:username});
    }
    else{
        return instance.post(ApiConfig.postSomeUserData,{userName:username});
    } 
}


export const getUserInterest=(id)=>{
    return instance.post(`${ApiConfig.userInterestList}`,{userId:id});
}

export const searchUserByEmailService = (text,page,limit, isOnlyIds) => {
    let url=ApiConfig.searchUserByEmail+'?';
    if(text) url+=`email=${text}`;
    if(limit &&page>=0) url+=url!==''?`&page=${page}&limit=${limit}`:`page=${page}&limit=${limit}`;
    if(isOnlyIds) { url += '&isOnlyIds='+true }
    return instance.get(url);
}

export const registerUserByEmailService = (email, type) => {
    let url = ApiConfig.registerByEmail+'?email='+email+'&type='+type;
    return instance.get(url);
}

export const getFriendsListService = (type, page, limit, isOnlyIds) => {
    let url = ApiConfig.getFriendsListingApi+'?listType='+type+'&page='+page+'&limit='+limit;
    if (isOnlyIds){
        url += '&isOnlyIds='+true;
    }

    return instance.get(url);
}

export const inviteFriendByMailPostService=(payload)=>{
    return instance.post(ApiConfig.inviteFriendByMail,payload);
}

export const defaultProfileGetService=(type,id)=>{
    let url=type==='user'?'userId='+id:'organizationId='+id;
    return instance.get(ApiConfig.defaultprofileUrl+url);
}

export const deafaultProfileByUserNameService=(type,username)=>{
    let url=type==='user'?'userName='+username:'organizationUserName='+username;
    return instance.get(ApiConfig.defaultprofileUrl+url);
}

export const FriendOrgCount=()=>{
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.OrgAndUserCount);
    }
    else{
        return instance.get(ApiConfig.OrgAndUserCount);
    }

   
}
