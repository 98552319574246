import React from "react";
import ActivityLogin from "../../commonDashboardComp/actvityLogin";
import UserLayout from "../../components/dashboard-layout";

const ActivityLoginPage = () => {
    return <UserLayout>
                <ActivityLogin/>
            </UserLayout>;
}

export default ActivityLoginPage;