import { USER_ACTION_TYPES } from "./user.types";
import { createAction } from "../../utils/reducer/reducer.utils";
export const setCurrentUser = (user) => {
  return createAction(USER_ACTION_TYPES.SET_CURRENT_USER , user);
}

export const setCurrentOrg = (org) => {
  return createAction(USER_ACTION_TYPES.SET_CURRENT_ORG , org);
}

export const setCurrentRole = (role) => {
  return createAction(USER_ACTION_TYPES.SET_CURRENT_ROLE , role);
}

export const setCurrentOrgRole = (role) => {
  return createAction(USER_ACTION_TYPES.SET_CURRENT_ORG_ROLE , role);
}


export const setSearchText = (text) => {
  return createAction(USER_ACTION_TYPES.SET_SEARCH_TEXT , text);
}

export const setLoadNewPosts = (load) => {
  return createAction(USER_ACTION_TYPES.SET_LOAD_NEW_POSTS , load);
}

export const setValidatorChange = (load) => {
  return createAction(USER_ACTION_TYPES.SET_VALIDATOR_CHANGE , load);
}

export const setUserWorkingOrgData = (load) => {
  return createAction(USER_ACTION_TYPES.SET_USER_WORKING_ORG , load);
}

export const setNotificationCount = (load) => {
  return createAction(USER_ACTION_TYPES.SET_NOTIFICATION_COUNT , load);
}

export const setCanSwitch = (load) => {
  return createAction(USER_ACTION_TYPES.SET_CAN_SWITCH , load);
}

export const setSwitchOrgPanel = (load) => {
  return createAction(USER_ACTION_TYPES.SET_SWITCH_ORG_PANEL , load);
}
export const setSwitchUserPanel = (load) => {
  return createAction(USER_ACTION_TYPES.SET_SWITCH_USER_PANEL , load);
}
