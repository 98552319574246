import React from "react";

const FormTextArea = ({id , name , onChange, value, label, rows, ...props}) => {
    return (<div className="form-row">
                <div className="form-item">
                    <div className="form-input full active">
                        <label htmlFor={id}>{label}</label>
                        <textarea id={id} name={name} rows={rows} onChange={onChange} value={value} {...props}/>
                    </div>
                </div>
            </div>
        );
}

export default FormTextArea;