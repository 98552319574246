import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import '../dashboard.style.css';
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import ProfileCompletion from "../../../commonDashboardComp/profileCompletion";
import AboutMe from "../../../commonDashboardComp/aboutMe";
import OrgFewList from "../../../commonDashboardComp/orgFewList";
import FewFriendsList from "../../../commonDashboardComp/FewFriendsList";
import PostList from "../../../commonDashboardComp/postList";
import TargetBox from "../../../commonDashboardComp/targetBox";
import InterestLists from "../../../commonDashboardComp/interestsList";
import AboutOrg from "../../../commonDashboardComp/aboutOrg";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectCurrentOrg, selectCurrentUser, selectCurrentUserRole } from "../../../Reducer/user/user.selector";

import OurImpactBox from "../../../commonDashboardComp/ourImpactBox";
import EventCalender from "../../../commonDashboardComp/eventCalender";
import TodayEventList from "../../../commonDashboardComp/todayEventsList";
import NewsFeedPage from "../../../pages/Dashboard/newsFeedPage.component";
import CreatePostBox from "../../../commonDashboardComp/createPostBox";
import { getUserDetailServices } from "../../../services/auth.services";
import { changeDateFormatYYYY } from "../../../utils/helpers";
import { getEventDetailByDateService } from "../../../services/event.services";
const Profile = () => {
  const role = useSelector(selectCurrentUserRole);
  const detail=useSelector(selectCurrentUser);
  const CURRENTORG = useSelector(selectCurrentOrg);
  const [loggedInUserDet, setLoggedInUserDet] = useState(detail);
  const [isValidatorOff, setIsValidatorOff] = useState(false);
  const [date, setDate] = useState(changeDateFormatYYYY(new Date()));
  const [eventByDate, setEventsByDate] = useState({});
  const [eventDate, setEventDate] = useState(new Date())
  useEffect(() => {
      getLoginUserDetails();
      window.scrollTo(0,0);
  }, [])

  useEffect(()=>{
    getEventByDate();
  }, [date]);

  const getEventByDate = () => {
    getEventDetailByDateService(date)
    .then((data)=>{
      if(data?.data?.status === 200){
        setEventsByDate(data?.data?.data);
      }
    })
  }
  const setEventDateHandler = (d) => {
    setEventDate(d);
    setDate(changeDateFormatYYYY(d))
  }

 const getLoginUserDetails = async () => {
      const response = await getUserDetailServices();
      if (response?.data?.data) {
          const user = response?.data?.data;
         
          setIsValidatorOff(response?.data?.data?.isValidator);
          if (response?.data?.data);
          setLoggedInUserDet(user);  
      }
  }

  return (
    <>
      <Row> <ProfileHeader coverImg='' img='' isValidatorOff={isValidatorOff} /> </Row>

      {role === 'user' && <>
        <Row className="grid grid-3-6-3 mobile-prefer-content">
          <Col lg={3} className="grid-column p-0">
            <AboutMe userDet={loggedInUserDet} viewForMore={true}
            me={true}
            />
            <OrgFewList />
          </Col>
          <Col lg={6} className="grid-column mt-4 mt-lg-0 px-lg-3 px-0">
            <PostList myPosts={true} />
          </Col>

          <Col lg={3} className="grid-column p-0">
            <ProfileCompletion  />
            <FewFriendsList />
          </Col>
        </Row>
      </>}

      {role === 'organization' && <>
        <Row className="grid mobile-prefer-content">
          <Col md={9} className="grid-column">
            <OurImpactBox />

            <Row className="grid mobile-prefer-content">
              <Col md={4}>  <InterestLists />  </Col>
              <Col md={8}>  <PostList myPosts={true}/>  </Col>
            </Row>
          </Col>

          <Col md={3} className="grid-column">
            <ProfileCompletion  />
            <AboutOrg  CURRENTORG={CURRENTORG}/>
            <TargetBox />
          </Col>
        </Row>
      </>}

      {role === 'superAdmin' &&
        <Row className='grid grid-9-3 mobile-prefer-content'>
          <Col md={9}>
            <OurImpactBox 
            widgetClassses="superAdminImpactBox"
            />

            <div>
              <Row className='grid grid-3-9 mobile-prefer-content'>
                <Col md={4}>
                  <TargetBox />

                </Col>
                <Col md={8}>

                  <PostList  myPosts={true}/>
                </Col>

              </Row>
            </div>

          </Col>
          <Col md={3}>
            <AboutMe 
              aboutMeClasses="superAdminAboutMeBox"
              userDet={loggedInUserDet}
            />
            <EventCalender date={eventDate} setDate={setEventDateHandler} />
            <TodayEventList  date={date} events={eventByDate}/>

          </Col>


        </Row>
      }
    </>);
}

export default Profile;
