import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { } from '@mui/material'
import { useTranslation } from "react-i18next";
import SectionHeader from "../../../../commonDashboardComp/sectionHeader";
import BlockedUser from "../../../../commonDashboardComp/blockedUser";
import { blockUserList, userAction } from "../../../../services/onBoarding.services";
import { getfollowerList, orgBlockOrFollowOrUnfollowOrUnblock } from "../../../../services/follower.service";
import { useSelector } from "react-redux";
import { selectCurrentOrg, selectCurrentUser, selectCurrentUserRole } from "../../../../Reducer/user/user.selector";
import ReactPagination from "../../../../commonComponents/reactPagination";
import swal from "sweetalert";
const BlockedUsersInfo = () => {
    const {t} = useTranslation();
    const role = useSelector(selectCurrentUserRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);

    const limit =  5;
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    let current_user = {};
    if(role === 'organization'){   current_user = CURRENTORG     }
    else{ current_user = CURRENTUSER }

    const [block_users, setBlockUser] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        allData();
    }, [role])


    const allData = async () => {
        await getBlockedUsersList();

    }

    const unblockUser = async (id) => {
        swal({
            text: t('alerts.unblock_user'),
            title: '',
            icon: "warning",
            buttons: {
              Yes: {
                text:t('yes'),
                value: true,
              },
              No: {
                text: t('no'),
                value: false,
              },
            },
          })
          .then(async (val) => {
            if(!val) return;
                if(role === 'organization'){
                    let param = {
                        list: [id],
                        action: "unBlockUser"
                    }
                    orgBlockOrFollowOrUnfollowOrUnblock(param)
                    .then((response) => {
                        getBlockedUsersList()
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                }
                else
                {
                    let param = {
                        list: [id],
                        action: "unBlockUserRequest"
                    }        
                    userAction(param)
                    .then((response) => {
                        getBlockedUsersList()
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                }
        });
    }

    useEffect(() => {
        getBlockedUsersList();
    }, [currentPage])

    const handlePageClick = (data) => {
        let Page = data.selected
        setCurrentPage(Page)
    }

    const getBlockedUsersList = async () => {
        try{
            setIsLoading(true);
            if(role === 'organization'){
                getfollowerList(currentPage, limit , 'blockUserList')
                .then((response) => {
                    if (response.data.status === 200) {
                        const _data = response.data.data.data;
                        setBlockUser(_data)
                        let pageCount = (response?.data?.data?.totalCount) / (limit);
                        setTotalPage(Math.ceil(pageCount));
                    }
                    setIsLoading(false)
                })
                .catch((error)=>{{
                    console.log(error)
                }})
            }
            else{
                blockUserList(currentPage, limit, 'blockedUserRequestList')
                .then((response) => {
                    if (response.data.status === 200) {
                        const _data = response.data.data.data;
                        setBlockUser(_data)
                        let pageCount = (response?.data?.data?.totalCount) / (limit);
                        setTotalPage(Math.ceil(pageCount));
                    }
                    setIsLoading(false)
                })
                .catch((error) => {
                    setBlockUser([])
                    console.log(error);
                })
            }
        }
        catch(error){
            console.log(error)
        }
    }
    return (<>
        <SectionHeader  title={t('Blocked_Users')} style={{ marginTop: '0px' }} />
        <Row className="grid">
            <div className="grid-column">
                <div className="widget-box">
                    <div className="widget-box-content">
                        <BlockedUser 
                            data={block_users} 
                            onAction={unblockUser} 
                            isLoading={isLoading} 
                            totalPage={totalPage}
                            handlePageClick={handlePageClick}
                            currentPage={currentPage}
                        />
                    </div>
                </div>
            </div>
        </Row>
    </>)
}

export default BlockedUsersInfo;