import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Col, Row } from "react-bootstrap";
import { PanchTatvaImg, EarthImg, WaterImg, AirImg, SoulImg, FireImg } from "../../commonComponents/Images";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { getImageUrl, numberKFormatter } from "../../utils/helpers";
import { getOverAllImpactsService } from "../../services/impactServices";
import { useRef } from "react";
const PanchTatva = ({ cmsData }) => {
    const [overAllImpacts, setOverAllImpacts] = useState();
    const [loader, setLoader] = useState(false);
    const { t } = useTranslation();
    const [impactData, setImpactData] = useState('');
    const [categoryWiseData, setCategoryWiseData] = useState('');
    const panchTatvaRef = useRef(null)

    useEffect(() => {
        panchTatvaRef.current.scrollIntoView()
    }, [])

    useEffect(() => {
        getOverAllImpacts()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const getOverAllImpacts = async () => {
        let resp = await getOverAllImpactsService();
        if (resp?.status === 200) {
            //setImpactData(resp?.data?.data);
            if (resp?.data?.data?.length > 0) {
                let finalArr = resp?.data?.data?.map((a, index) => {
                    let obj = { category: a?.categoryDetail?.spredeInterest };
                    let subCat = [];
                    let totalEvent = 0;
                    let eventComp = 0;
                    if (a?.subCategoryResult?.length > 0) {
                        subCat = a?.subCategoryResult?.map((sub, ind) => {
                            let subObj = {};
                            subObj['eventCompSub'] = sub?.subCategoryCompletedEventLength;
                            subObj['totalEventSub'] = sub?.subCategoryTotalEventLength;
                            eventComp += sub?.subCategoryCompletedEventLength;
                            totalEvent += sub?.subCategoryTotalEventLength;
                            subObj['subCategory'] = sub?.subCategoryDetail?.spredeInterestOptions;
                            subObj['eventUnit'] = +sub?.eventUnit;
                            subObj['eventUnitComplete'] = sub?.eventUnitCompleted;
                            subObj['targetUnit'] = sub?.targetUnit;
                            let percent = ((sub?.targetUnit == 0) ? 0 : (sub?.eventUnitCompleted / sub?.targetUnit) * 100).toFixed(0);
                            subObj['percentSub'] = isNaN(percent) ? 0 : percent;
                            return subObj;
                        })

                    }
                    obj['totalEvent'] = totalEvent;
                    obj['eventComp'] = eventComp;
                    obj['percent'] = totalEvent === 0 ? 0 : (eventComp / totalEvent) * 100;
                    obj['subCat'] = subCat;
                    return obj;
                })

                let finalObj = {};
                if (finalArr?.length > 0) {
                    finalArr?.map((a, index) => {
                        if (a?.category === 'air') {
                            finalObj = { ...finalObj, air: true, airData: a }
                        }
                        else if (a?.category === 'fire') {
                            finalObj = { ...finalObj, fire: true, fireData: a }
                        }
                        else if (a?.category === 'soul') {
                            finalObj = { ...finalObj, soul: true, soulData: a }
                        }
                        else if (a?.category === 'water') {
                            finalObj = { ...finalObj, water: true, waterData: a }
                        }
                        else if (a?.category === 'earth') {
                            finalObj = { ...finalObj, earth: true, earthData: a }
                        }
                    })
                    setCategoryWiseData(finalObj);
                }
                setImpactData(finalArr);

            }
            setLoader(false);
        }
        else {
            setLoader(false);
        }

    }

    return (
        <Container className="panchtatva-div " id="panchTatva" ref={panchTatvaRef}>
            <Row className="panchtatva-row1 mb-5">
                <img src={cmsData?.panchTatva?.image !== '' ? getImageUrl(cmsData?._id, 'cms', cmsData?.panchTatva?.image) : PanchTatvaImg} alt="panchtatva" />
                <span className="heading"> {cmsData?.panchTatva?.title} </span>
                <h4 className="sub-heading"> {cmsData?.panchTatva?.description} </h4>
                <p className="detail">{cmsData?.panchTatva?.subDescription}</p>
            </Row>

            <Row className="justify-content-center">
                <Col className="col-lg-2 col-sm-6 col-md-4">
                    <div className="tatvaContainer">
                        <div className="tatva-box hover_me">
                            <img src={EarthImg} alt={t('earth')} />
                            <p className="tatva-title">{t('earth')}</p>
                        </div>
                        <div className="tatva-detail-box toggled show_me">
                            {categoryWiseData?.earthData?.subCat?.map((sub, index) => {
                                return <div className="my-3" key={index}>
                                    {sub?.subCategory === 'tree plantation' ? <>
                                        <p className="tatva-detail2">
                                            <span className="text-style-1">{numberKFormatter(+sub?.eventUnit)}</span>
                                            <span className="text-style-2">{sub?.subCategory}</span>
                                        </p>
                                    </>
                                        : sub?.subCategory === 'solid waste management' ? <>
                                            <p className="tatva-detail2">
                                                <span className="text-style-1">{numberKFormatter(sub?.eventUnit)}<sup>Kg</sup></span>
                                                <span className="text-style-2">{sub?.subCategory}</span>
                                            </p>

                                        </>
                                            : sub?.subCategory === 'consumption management' ? <>
                                                <p className="tatva-detail2">
                                                    <span className="text-style-1">{sub?.eventUnit}<sup>Kg</sup></span>
                                                    <span className="text-style-2">{sub?.subCategory}</span>
                                                </p>

                                            </>
                                                : <>
                                                    <div className="tatva-progress">
                                                        <CircularProgressbar
                                                            className="homepageProgress earth-color"
                                                            value={sub?.percentSub}
                                                            text={`${sub?.percentSub}%`}
                                                            styles={{
                                                                text: { fill: '#7aa73e' },
                                                                background: { fill: '#7aa73e' }
                                                            }} />
                                                    </div>
                                                    <p className="tatva-detail1 mt-1">
                                                        {(t(`${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) !== `${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) ? t(`${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) : sub?.subCategory}
                                                    </p>
                                                    <p className="tatva-detail1 mt-1 mb-3">{sub.eventUnitComplete} out of {sub.targetUnit}</p>
                                                </>}
                                    {/* <p className="tatva-detail2">
                                <span className="text-style-1">41</span>
                                <span className="text-style-2">{t('trees_planted')}</span>
                            </p>
                            <p className="tatva-detail2">
                                <span className="text-style-1">41<sup>Kg</sup></span>
                                <span className="text-style-2">{t('panchTatwa.Blogging')}</span>
                            </p>
                            <p className="tatva-detail2">
                                <span className="text-style-1">41<sup>Kg</sup></span>
                                <span className="text-style-2">{t('panchTatwa.compost_Created')}</span>
                            </p> */}
                                </div>
                            })}
                        </div>
                    </div>
                </Col>
                <Col lg={2} className="col-sm-6 col-md-4">
                    <div className="tatvaContainer">
                        <div className="tatva-box sky-blue-after hover_me">
                            <img src={WaterImg} alt={t('water')} />
                            <p className="tatva-title">{t('water')}</p>
                        </div>
                        <div className="tatva-detail-box show_me" >
                            {categoryWiseData?.waterData?.subCat?.map((sub, index) => {
                                return <div className='my-3' key={index}>
                                    <div className="tatva-progress"><CircularProgressbar
                                        className="homepageProgress water-color"
                                        value={sub?.percentSub}
                                        text={`${sub?.percentSub}%`}
                                        styles={{
                                            text: { fill: '#8ab5e3' },
                                            background: { fill: '#8ab5e3' }
                                        }} /></div>
                                    {/* <p className="tatva-heading"> {t('water')} </p> */}
                                    <p className="tatva-detail1"> {(t(`${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) !== `${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) ? t(`${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) : sub?.subCategory} </p>
                                    <p className="tatva-detail1 mt-2 mb-3">{sub.eventUnitComplete} out of {sub.targetUnit}</p>
                                </div>
                            })}
                        </div>
                    </div>
                </Col>
                <Col lg={2} className="col-sm-6 col-md-4">
                    <div className="tatvaContainer">
                        <div className="tatva-box light-yellow-after hover_me">
                            <img src={AirImg} alt={t('air')} />
                            <p className="tatva-title">{t('air')}</p>
                        </div>
                        <div className="tatva-detail-box show_me" >
                            {categoryWiseData?.airData?.subCat?.map((sub, index) => {
                                return <div className='my-3' key={index}>
                                    <div className="tatva-progress"><CircularProgressbar
                                        className="homepageProgress air-color"
                                        value={sub?.percentSub}
                                        text={`${sub?.percentSub}%`}
                                        styles={{
                                            text: { fill: '#e5be60' },
                                            background: { fill: '#e5be60' }
                                        }} /></div>
                                    {/* <p className="tatva-heading"> {t('air')} </p> */}
                                    <p className="tatva-detail1"> {(t(`${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) !== `${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) ? t(`${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) : sub?.subCategory}</p>
                                    <p className="tatva-detail1 mt-2 mb-3">{sub.eventUnitComplete} out of {sub.targetUnit}</p>
                                </div>
                            })}
                        </div>
                    </div>
                </Col>
                <Col lg={2} className="col-sm-6 col-md-4">
                    <div className="tatvaContainer">
                        <div className="tatva-box light-red-after hover_me">
                            <img src={FireImg} alt={t('fire')} />
                            <p className="tatva-title">{t('fire')}</p>
                        </div>
                        <div className="tatva-detail-box show_me" >
                            {categoryWiseData?.fireData?.subCat?.map((sub, index) => {
                                return <div className="my-3" key={index}>
                                    <div className="tatva-progress">
                                        <CircularProgressbar
                                            className="homepageProgress fire-color"
                                            value={sub?.percentSub}
                                            text={`${sub?.percentSub}%`}
                                            styles={{
                                                text: { fill: '#d06368' },
                                                background: { fill: '#d06368' }
                                            }}
                                        />
                                    </div>
                                    <p className="tatva-detail1 my-2"> {(t(`${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) !== `${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) ? t(`${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) : sub?.subCategory} </p>
                                    <p className="tatva-detail1 mt-2 mb-3">{sub.eventUnitComplete} out of {sub.targetUnit}</p>
                                    {/* <div className="tatva-progress"><CircularProgressbar
                                className="homepageProgress fire-color"
                                value={70}
                                text={`${70}%`}
                                styles={{
                                    text: { fill: '#000' },
                                    background: { fill: '#d06368' }
                                }} /></div>
                            <p className="tatva-detail1 my-2"> {t('Area_covered_by_trees')} </p> */}
                                </div>
                            })}
                        </div>
                    </div>
                </Col>
                <Col lg={2} className="col-sm-6 col-md-4">
                    <div className="tatvaContainer">
                        <div className="tatva-box dark-blue-after hover_me">
                            <img src={SoulImg} alt={t('soul')} />
                            <p className="tatva-title">{t('soul')}</p>
                        </div>
                        <div className="tatva-detail-box show_me">
                            {categoryWiseData?.soulData?.subCat?.map((sub, index) => {
                                return <div className="my-3" key={index} >
                                    <div className="tatva-progress">
                                        <CircularProgressbar
                                            className="homepageProgress soul-color"
                                            value={sub?.percentSub}
                                            text={`${sub?.percentSub}%`}
                                            styles={{
                                                text: { fill: '#8688bf' },
                                                background: { fill: '#8688bf' }
                                            }}
                                        />
                                    </div>
                                    <p className="tatva-detail1 my-2"> {(t(`${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) !== `${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) ? t(`${sub?.subCategory.replace(/\s+/g, '_').toLowerCase()}`) : sub?.subCategory} </p>
                                    <p className="tatva-detail1 mt-2 mb-3">{sub.eventUnitComplete} out of {sub.targetUnit}</p>
                                </div>
                            })}
                        </div>

                        {/* <div className="tatva-detail-box">
                            <div className="t-box-details pb-1 soul-para">
                            <p className="tatva-heading"> 6 {t('months')} </p>
                            <p className="tatva-detail1"> {t('time_spent_with_NGOs')} </p>
                            </div>
                            <div className="t-box-details pb-1 soul-para">
                            <p className="tatva-heading"> 23</p>
                            <p className="tatva-detail1">{t('panchTatwa.Changes_in_food_habits')} </p>
                            </div>
                            <div className="t-box-details pb-1 soul-para">
                            <p className="tatva-heading"> 2000$</p>
                            <p className="tatva-detail1">{t('panchTatwa.Payment_to_NGOs')}  </p>
                            </div>
                            <div className="t-box-details pb-1 soul-para">
                            <p className="tatva-heading"> 234</p>
                            <p className="tatva-detail1">{t('panchTatwa.Animals_adopted')} </p>
                            </div>
                        </div> */}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default PanchTatva;