import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Popup from "../commonComponents/popup";
import { Row, Col } from "react-bootstrap";
import { Autocomplete, TextField } from "@mui/material";
import PdfDocument from "../commonComponents/pdfDocument";
const PhotosPopup= ({open, handleClose,photoPopUpError, setPhoto, selectedFiles,evidencePerEventTime, 
    deleteSelectedFile,closeAndSubmitPhotoes, loading, event, setActivity, activity, photoPopClasses, compUnit,
    setCompUnit, newCompUnit}) => {
    const {t} = useTranslation();
    // let TotCnt=0;
    
    // let TotalUnitComplete=evidencePerEventTime?.map((eviTotal)=>{
    //     TotCnt += eviTotal?.unit; 
    //     //    let cnt=eviTotal?.evidence?.map((a)=>{
    //     //     TotCnt+=a?.unit;
    //     //    })
    // })
    const handleUnit=(e)=>{
        if(!isNaN(e.target.value)){
            setCompUnit(Number(e.target.value));
        }
        else{
            setCompUnit(0);
        }

    }
   
   
    return(
        <Popup open={open}  handleClose={handleClose} heading={t('Upload_Photo_Video')} className="targetPopup" modalContentClasses="eventEvidenceContent" modalBodyClasses={"evidenceModalBody"}>
            <Row>
                {selectedFiles.length === 0 ? <div className="upload-area">
                    <Col md={12} lg={12}>
                    <label className="user_cover_upload userUploadBox" htmlFor="upload-cover">
                        <input type="file" id="upload-cover" multiple
                            accept="image/*,image/heif,image/heic,video/*,video/mp4,video/x-m4v,video/x-matroska,.mkv, application/pdf"
                            onChange={(event) => {
                                setPhoto(event.target.files)
                            }}
                        />
                        <div className="content">
                            <p>{t('upload_images_and_videos_pdf')}</p>
                        </div>
                    </label>
                    </Col>
                </div>
                :
                <div>
                    <div className="picture-collage ">     
                        <div className="evidencePopupInputBox row">
                            <div className="col-lg-3 col-md-12">
                                <label className="uploadChooseBtn">
                                    <input type="file" id="upload-more" multiple
                                        accept="image/*,image/heif,image/heic,video/*,video/mp4,video/x-m4v,video/x-matroska,.mkv, application/pdf"
                                        onChange={(event) => {
                                            setPhoto(event.target.files)
                                        }}
                                    />
                                </label>             
                            </div>
                            
                            {event?.activityId?.activity?.length > 0 && 
                                <div className={`${photoPopClasses} col-lg-4 col-md-12 photoPopupActivityField`}>
                                    <Autocomplete
                                        disablePortal
                                        value={activity}
                                        options={event?.activityId?.activity}
                                        getOptionLabel={(option) => option?.title || ""}
                                        onChange={(e, val)=>{ setActivity(val); }}
                                        renderInput={(params) => <TextField {...params} label={t('form.select_activity')} />}
                                    />
                                    {(photoPopUpError && (activity==''|| activity==null)) && <div className="error-text mt-0">
                                        Please Select Activity
                                              
                                     </div>}
                                </div>
                            }
                            <div className="photoPopUpUC col-lg-4 col-md-12">
                            <TextField 
                                type='text' 
                                id="Qty" 
                                label={t('form.unitSubmitted')} 
                                value={newCompUnit}
                                onChange={(e)=>{!isNaN(e.target.value) && handleUnit(e)}} />
                            <span className="mb-3">{compUnit}/{event?.unit}</span>
                            </div>
                        </div>           
                        <div className="picture-collage-row d-flex flex-wrap">
                            {selectedFiles.map((item, index)=>{
                                return (
                                <div className="eventEvidenceUploadBox" key={index}>
                                    <div className="photo-preview event-evidence-upload">   
                                        {
                                            item.type.includes('video') ?
                                            <video poster src={URL.createObjectURL(item)} alt=""/>
                                            :item.type.includes('application/pdf') ?
                                            <PdfDocument url={URL.createObjectURL(item)}/>
                                            :
                                            <img src={URL.createObjectURL(item)} alt=""/>
                                        }                                         
                                        
                                        <button className="deleteImgBtn" 
                                        onClick={(e)=>deleteSelectedFile(e, index)}
                                        >&times;</button>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
                }
                 
            
        </Row>
        {selectedFiles?.length===0 && photoPopUpError &&
                                            <div className="error-text inp_file_error">
                                               {t('Please_choose_Photo_or_Video')}
                                            </div>
                 }
        <div className="evidenceBtnBox">
        
            <button className="evidenceBtn" onClick={(e)=>{closeAndSubmitPhotoes()}} disabled={loading}>
                {t('click_to_upload')}
                {loading && <span className="spinner-border" />}
            </button>
    
        
        </div>
        </Popup>
    )
}

PhotosPopup.defaultProps={photoPopClasses:""}
export default PhotosPopup;