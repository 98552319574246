import React , {useState} from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import TabNavigation from "../../../commonDashboardComp/tabNavigation";
import { useLocation } from "react-router-dom";
import VendorUpload from "./vendorUpload";
import VendorList from "./vendorList";
import { useTranslation } from "react-i18next";
const Vendors = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [tab, setTab] = useState(2);
    const tabs = [t('vendors.Vendorss') , t('vendors.Vendor_Upload') ]
    const [searchRes,setSearchRes]=useState('');
    const [totalFriends,setTotalfriends]=useState(0);
   
    const onKeyDownSearchText=(search)=>{
        setSearchRes(search);        
    }

    const searchUpdate = search => {
        setSearchRes(search);
    };
    return (<>

        {/* <div className="col-md-12"> <ProfileHeader coverImg='' img=''/> </div> */}

        <SectionHeader  title={tabs[tab-1]} count={tab===2?'':totalFriends}/>

        <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab} onChangeSearchText={searchUpdate}   onKeyUpSearch={onKeyDownSearchText}  inputPlaceholder={t('vendors.Search_Vendors')}/>

        {tab === 2 && <VendorUpload searchBy={searchRes} countsOfFriends={setTotalfriends} />}
        {tab === 1 && <VendorList searchBy={searchRes} countsOfFriends={setTotalfriends} />}

        </>
    )
}

export default Vendors;