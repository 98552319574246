import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch } from "react-redux";
import useGeoLocation from "react-ipgeolocation";
import { setValidatorChange } from "../../../Reducer/user/user.action";
import {
    Box,
    TextField,
    Stack,
    Switch,
    Checkbox,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    Button,
    MenuItem,
    FormHelperText,
    Autocomplete
} from '@mui/material';
// import { dltOutline } from '@mui/icons-material/DeleteOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { useSelector } from "react-redux";
import { selectCurrentUser, selectValidatorChange } from "../../../Reducer/user/user.selector";
import { getEventDetailByDate, postValidatorInfo } from "../../../services/event.services";
import { useNavigate } from "react-router-dom";
import { timezone } from "../../../config/constants";
import { dateTimeFormat, showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import dayjs from 'dayjs';
import { addDays } from 'date-fns';


const ValidatorDateByDetail = ({ handleClose, handleValidatorValueChange, isValidatorOff }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const validatorChange = useSelector(selectValidatorChange)
    const [dateCAL, setDateCAL] = useState('');
    const [eventStartTime, setEventStartTime] = useState('');
    const [eventEndTime, setEventEndTime] = useState('');
    const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };
    const [timeZoneRange, setTimeZoneRange] = useState('');
    const rateOptions = [
        { chargesMode: 'rate_per_hour', amount: 0, choosen: false, label: t('Per_hr') },
        { chargesMode: 'rate_per_participant', amount: 0, choosen: false, label: t('Per_Participants') },
        { chargesMode: 'fixed_rate', amount: 0, choosen: false, label: t('fixed_rate') }
    ];
    const [validatorError, setValidatorError] = useState({});
    const [errors, setErrors] = useState(null);
    const [currentIndexChoosen, setCurrentIndexChoosen] = useState(-1);
    const [allErrors, setAllErrors] = useState(null);
    const [toShowAvailibilityDetail, setToShowAvailibilityDetail] = useState(false);
    const [isValidatorDetailToShow, setisValidatorDetailToShow] = useState(false);
    const [rangeState, setRangeState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }
    ]);

    const validatorDataSelected = {
        "date": "",
        "startTime": "",
        "endTime": "",
        "timezone": "",
    }
    const [validatorOneTimeData, setValidatorOneTimeData] = useState(validatorDataSelected);
    const [validatorAvailabiltyTimeArray, setValidatorAvailabiltyTimeArray] = useState([])
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();

    const INITIAL_FORM_VALUES = {
        "region": "",
        "nationalId": "",
        "nationalProof": "",
        "cv": "",
        "rate_per_hour": "",
        "rate_per_participant": "",
        "fixed_rate": "",
        "validatorDetail": [
            {
                "date": "",
                "startTime": "",
                "endTime": "",
                "timezone": "",
                "currency": "",
                "region": "",
                "rate_per_hour": "",
                "rate_per_participant": "",
                "fixed_rate": "",
            }
        ]
    }
    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);
    const [calenderView, setCalenderView] = useState(false);

    const handleRangeSubmitForm = (e) => {
        e.preventDefault();
        let dataCollection3 = getDateArray(rangeState[0].startDate, rangeState[0].endDate);
        const FinalObj = { ...formValues, validatorDetail: dataCollection3 };
        // if (dataCollection3?.length !== validatorAvailabiltyTimeArray?.length) {
        //     showToastError("Please Fill Selected date's Detail")
        // }
        // else {
        if (dataCollection3?.length === 0 && timeZoneRange == '') { showToastError(t('Please_Fill_selected_Date_Details')); return; }
        if (allValidatorsErrors(dataCollection3)) {
            postValidatorInfo(FinalObj).
                then((resp) => {
                    if (resp.status === 200) {
                        showSingleToastSuccess(t('Validator_Availabilty_Updated'));
                        dispatch(setValidatorChange(!validatorChange));
                        navigate('/validator-availibility');
                        setFormValues(INITIAL_FORM_VALUES)
                        // setValidatorOneTimeData(validatorDataSelected)
                        setValidatorAvailabiltyTimeArray([]);
                        setEventStartTime('');
                        setTimeZoneRange('');
                        setEventEndTime('');

                        setRangeState([
                            {
                                startDate: new Date(),
                                endDate: addDays(new Date(), 0),
                                key: 'selection'
                            }
                        ])

                    }
                })
                .catch((error) => console.log(error))
            // }
            // else {
            //     console.log("Errors");
            // }
        }
    }
    const handleSubmitForm = (e) => {
        e.preventDefault();
        let dataCollected2 = validatorAvailabiltyTimeArray?.filter((a => {
            const objVal = a.date && a.startTime && a.endTime && a.timezone
            return objVal;
        }));

        const FinalObj = { ...formValues, validatorDetail: dataCollected2 };
        if (dataCollected2?.length !== validatorAvailabiltyTimeArray?.length) {
            showToastError("Please Fill Selected date's Detail")
        }
        else {
            if (dataCollected2?.length === 0 && calenderView == false) { showToastError(t('Please_Fill_selected_Date_Details')); return; }
            if (allValidatorsErrors(dataCollected2)) {
                postValidatorInfo(FinalObj).
                    then((resp) => {
                        if (resp.status === 200) {
                            showSingleToastSuccess(t('Validator_Availabilty_Updated'));
                            dispatch(setValidatorChange(!validatorChange));
                            navigate('/validator-availibility');
                            setFormValues(INITIAL_FORM_VALUES)
                            setValidatorOneTimeData(validatorDataSelected)
                            setValidatorAvailabiltyTimeArray([]);
                            setEventStartTime('');
                            setEventEndTime('');

                        }
                    })
                    .catch((error) => console.log(error))
            }
            else {
                console.log("Errors");
            }
        }

    }
    const allValidatorsErrors = (arrayOfData) => {
        let errors = {};
        let cnt = 0;
        // if (formValues?.region == '') {
        //     errors['region'] = true; cnt = cnt + 1;
        // }
        if (formValues?.nationalId == '') {
            errors['nationalId'] = true;
            cnt = +1;
        }
        // if (formValues?.cv == '') {
        //     errors['cv'] = true;
        //     cnt += 1;
        // }
        if (formValues?.nationalProof == '') {
            errors['nationalProof'] = true;
            cnt += 1;
        }
        if (arrayOfData?.length === 0) {
            cnt += 1;
            errors['allFeild'] = true;
        }
        setAllErrors(errors);
        return cnt == 0;

    }

    const updateArrayObjectOrShow = (data, currentDate) => {
        const copiedArray = { ...formValues, ...data };
        let { _id, userId, region, nationalId, nationalProof, cv, createdAt, updatedAt, validatorDetail } = data;
        const ObjectCopyOfThatDate = data?.validatorDetail?.length === 0 ? validatorDataSelected : { ...validatorDetail[0], fromServer: true, date: currentDate };
        // ObjectCopyOfThatDate['date']=dayjs.format(currentDate).format('YYYY-MM-DD');
        setValidatorOneTimeData(ObjectCopyOfThatDate);
        const copiedFormData = { ...formValues, ...data };
        if (data?.validatorDetail?.length > 0) {
            let copyOfAvilabiltyArray = [...validatorAvailabiltyTimeArray];
            let findDateIndex = validatorAvailabiltyTimeArray?.findIndex(val => dayjs(val?.date).format('YYYY-MM-DD') === currentDate);
            if (findDateIndex < 0) {
                copyOfAvilabiltyArray.push(ObjectCopyOfThatDate);
                setCurrentIndexChoosen(copyOfAvilabiltyArray?.length - 1)
            }
            else {
                setCurrentIndexChoosen(findDateIndex);
            }
            setValidatorAvailabiltyTimeArray(copyOfAvilabiltyArray);
            setAllErrors(null);
        }
        setEventStartTime(data?.validatorDetail[0]?.startTime);
        setEventEndTime(data?.validatorDetail[0]?.endTime);
        setFormValues(copiedFormData);
    }

    const GetEventByDateDetail = (currentDate) => {
        let newDate = dateConversion(currentDate)
        getEventDetailByDate(newDate).
            then((resp) => {
                if (resp?.status === 400) {
                    createNewdataArray(newDate);
                    const copiedFormData = { ...formValues, ...resp?.data?.data };
                    setFormValues(copiedFormData);
                }
                if (resp?.status === 200) {
                    if (resp?.data?.data?.validatorDetail?.length > 0) {
                        updateArrayObjectOrShow(resp?.data?.data, newDate);
                    }
                    else {
                        createNewdataArray(newDate);
                        const copiedFormData = { ...formValues, ...resp?.data?.data };
                        setFormValues(copiedFormData);
                    }
                }
            })
            .catch((error) => {
                createNewdataArray(newDate);
                return;
            });
    }
    const dateConversion = (date) => {
        return new Date(date).toLocaleDateString('en-US', dateOptions)
    }
    const createNewdataArray = (currentDate) => {
        if (validatorAvailabiltyTimeArray?.length > 0) {
            let findDateIndex = validatorAvailabiltyTimeArray?.findIndex(val => dayjs(val?.date).format('YYYY-MM-DD') === dayjs(currentDate).format('YYYY-MM-DD'));
            if (findDateIndex >= 0) {
                let dataOfThatDate = validatorAvailabiltyTimeArray[findDateIndex];
                const NewValidatorOneTimeData = { ...validatorOneTimeData, ...dataOfThatDate };
                setValidatorOneTimeData(NewValidatorOneTimeData);
                setCurrentIndexChoosen(findDateIndex);
                setEventStartTime(NewValidatorOneTimeData?.startTime);
                setEventEndTime(NewValidatorOneTimeData?.endTime);
                // setEventStartTime('');
                // setEventEndTime('');
            }
            else {
                let newObjectData = { ...validatorDataSelected, date: currentDate };
                setValidatorOneTimeData(newObjectData);
                let validatorAvailabiltyTimeArrayCopy = [...validatorAvailabiltyTimeArray];
                validatorAvailabiltyTimeArrayCopy.push(newObjectData);
                setValidatorAvailabiltyTimeArray(validatorAvailabiltyTimeArrayCopy);
                setCurrentIndexChoosen(validatorAvailabiltyTimeArray?.length);
                setEventStartTime('');
                setEventEndTime('');

            }

        }
        else {
            let newObjectData = { ...validatorDataSelected, date: currentDate };
            setValidatorOneTimeData(newObjectData);
            let validatorAvailabiltyTimeArrayCopy = [...validatorAvailabiltyTimeArray];
            validatorAvailabiltyTimeArrayCopy.push(newObjectData);
            setValidatorAvailabiltyTimeArray(validatorAvailabiltyTimeArrayCopy);
            setCurrentIndexChoosen(validatorAvailabiltyTimeArray?.length);
        }


    }
    const checkSelectedDate = (dateComing) => {
        let selectedDate = dateConversion(dateComing);
        let currentDate = moment(dateComing).format('YYYY-MM-DD');
        setDateCAL(currentDate)
        setValidatorError(null);
        GetEventByDateDetail(currentDate);
    }

    const manageEventTime = (time, id) => {
        const copyValidorOneTimeData = { ...validatorOneTimeData };
        copyValidorOneTimeData[id] = time;
        setValidatorOneTimeData(copyValidorOneTimeData);
        let dt = moment(copyValidorOneTimeData?.startTime?.$d ? copyValidorOneTimeData?.startTime?.$d : copyValidorOneTimeData?.startTime);
        let dt2 = moment(copyValidorOneTimeData?.endTime?.$d ? copyValidorOneTimeData?.endTime?.$d : copyValidorOneTimeData?.endTime);
        const diff = dt.diff(dt2, 'time');
        if (diff < 0) {
            settingValidatorArray(copyValidorOneTimeData);
            let validatorErrorCopy = { ...validatorError, startEndTime: false };
            setValidatorError(validatorErrorCopy);
        }
        else {
            let validatorErrorCopy = { ...validatorError, startEndTime: true };
            setValidatorError(validatorErrorCopy);
        }
    }
    const settingValidatorArray = (dataToBeCopied) => {
        if (currentIndexChoosen >= 0) {
            const validatorAvailabiltyTimeArrayCopy = [...validatorAvailabiltyTimeArray];
            validatorAvailabiltyTimeArrayCopy[currentIndexChoosen] = dataToBeCopied;
            setValidatorAvailabiltyTimeArray(validatorAvailabiltyTimeArrayCopy);
        }
    }
    var getDateArray = (start, end) => {
        var arr = new Array();
        var dt = new Date(start);
        // const date = new Date();
        // const dateAsString = date.toString();
        // const timeZone = dateAsString.match(/\(([^\)]+)\)$/)[1];
        while (dt <= end) {
            arr.push({ date: moment(dt).format('MMMM DD, YYYY'), startTime: new Date(dt.setHours(0, 0, 0, 999)), endTime: new Date(dt.setHours(23, 59, 59, 999)), timezone: timeZoneRange });
            dt.setDate(dt.getDate() + 1);
        }
        return arr

    }
    const handleChangeTimeZone = (val, id) => {
        const validatorOneTimeDataCopy = { ...validatorOneTimeData };
        if (id === 'timezone') {
            validatorOneTimeDataCopy[id] = val
        }
        else {
            validatorOneTimeDataCopy[id] = val;
        }
        setValidatorOneTimeData(validatorOneTimeDataCopy);
        settingValidatorArray(validatorOneTimeDataCopy);
    }

    const setClass = (date) => {
        let selectedDate = moment(date?.date);
        let currentDate = selectedDate.format('YYYY-MM-DD');
        let fndindex = validatorAvailabiltyTimeArray?.findIndex((a) => moment(a?.date).format('YYYY-MM-DD') === currentDate);
        let clickedDate = dateCAL ? moment(dateCAL).format('YYYY-MM-DD') : '';
        if (clickedDate == currentDate) {
            return "selectedDate1"
        }
        if (fndindex >= 0) {
            return "selectedDate"
        }
    }

    const deleteAvailabiltyDetail = (val, index) => {
        let arrCopy = [...validatorAvailabiltyTimeArray]
        arrCopy?.splice(index, 1);
        setCurrentIndexChoosen(-1);
        setValidatorOneTimeData(validatorDataSelected);
        setEventStartTime('');
        setEventEndTime('');
        setDateCAL('');
        setValidatorAvailabiltyTimeArray(arrCopy);
    }

    const editAvailabiltyDetail = (val, index) => {
        setValidatorOneTimeData(val);
        let date1 = val?.date;
        let newDate = new Date(date1);
        setDateCAL(newDate);
        setEventStartTime(val?.startTime);
        setCurrentIndexChoosen(index);
        setEventEndTime(val?.endTime);

    }

    const submitAllValidatorDetSelected = (e) => {
        e.preventDefault();
        if (calenderView) {
            handleRangeSubmitForm(e);
        }
        else {
            handleSubmitForm(e);
        }

    }

    const tileDisabled = ({ activeStartDate, date, view }) => {
        return date < new Date().setHours(0, 0, 0, 0);
    }

    return (
        <Row className="grid">
            <div className="grid-column myProfileSettings">
                <div className="widget-box">
                    <div className="widget-box-content">
                        <Box
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%', zIndex: '699' },
                            }}
                            noValidate
                            autoComplete="off"
                            className="userdetail-form targetvalidator validation_popup_box"

                            style={{ zIndex: '10000' }}
                        >

                            <div className='my-3'>
                                <h3 className='validatorForm_header'><span className='text-success'>*</span> {t('Add_Edit_Validator_Availibilty')}</h3>
                                {/* One Date <Switch aria-label='Switch demo'/>Muliple Date */}
                                <div id="" className="eventFilterDropdown">
                                    <FormControl fullWidth className="comment">
                                        <InputLabel id="co-country-select-label" shrink={true}>
                                            {t('Select_Calendar')}
                                        </InputLabel>
                                        <Select
                                            // MenuProps={{
                                            //   disableScrollLock: true
                                            // }}
                                            labelId="co-country-select-label"
                                            value={calenderView}
                                            label={t('Select_Calendar')}
                                            sx={{
                                                maxWidth: '225px',
                                            }}
                                            onChange={(e) => setCalenderView(e.target.value)}
                                        >
                                            <MenuItem value={true}>{t('Select_by_range')}</MenuItem>
                                            <MenuItem value={false}>{t('Select_by_date')} </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {calenderView ?
                                    <Row> <Col lg={5} className='col-md-6 popup_calender'>
                                        <div className='border rounded p-lg-2 '>

                                            <DateRange
                                                editableDateInputs={true}
                                                className="validatorCalender manageAvailabilityCalendar"
                                                onChange={item => { checkSelectedDate(item.selection.startDate); setRangeState([item.selection]); setDateCAL(item.selection.startDate); setAllErrors(true) }}
                                                moveRangeOnFirstSelection={false}
                                                ranges={rangeState}
                                                minDate={new Date()}
                                                rangeColors={['#7a8a3a']}
                                            />
                                        </div>

                                    </Col>
                                        <Col lg={4} className="col-md-6 border rounded popup_time_area">
                                            <FormControl fullWidth className="mt-4">
                                                <Autocomplete
                                                    disablePortal
                                                    value={timeZoneRange}
                                                    options={timezone}
                                                    getOptionLabel={(option) => option}
                                                    onChange={(e, val) => { setTimeZoneRange(val); setErrors(false) }}
                                                    renderInput={(params) => <TextField {...params} label={t('superAdmin.Time_Zone')} />}
                                                />
                                            </FormControl>
                                            {!timeZoneRange && errors &&
                                                <div className="error-text">
                                                    {t('errors.timezone')}
                                                </div>
                                            }
                                            <button onClick={(e) => {
                                                if (timeZoneRange == '')
                                                    setErrors(true)
                                                else
                                                    submitAllValidatorDetSelected(e)
                                            }} className="button table_desin_btn mt-2"  >{t('form.submit')}</button>
                                        </Col>
                                    </Row> :
                                    <Row className=' '>
                                        <Col lg={3} className='col-md-6 popup_calender'>
                                            <div className='border rounded p-lg-2'>
                                                <Calendar
                                                    className="validatorCalender manageAvailabilityCalendar"
                                                    onChange={(date1) => { checkSelectedDate(date1); setDateCAL(date1) }}
                                                    value={dateCAL}
                                                    calendarType="US"
                                                    //tileDisabled={tileDisabled}
                                                    minDate={new Date()}
                                                    tileClassName={(date) => setClass(date)}
                                                />

                                            </div>
                                        </Col>

                                        <Col lg={3} className="col-md-6 border rounded popup_time_area">

                                            <Row>
                                                {/* <small>{dateCAL && moment(dateCAL).format("DD MMM YYYY")}</small> */}
                                                <Col md={12} className='popup_input validatorAvailabilityFields'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <MobileTimePicker
                                                            label={t('superAdmin.Start_Time')}
                                                            value={eventStartTime}
                                                            id='startTime'
                                                            closeOnSelect='true'
                                                            name='startTime'
                                                            onChange={(newValue) => {
                                                                manageEventTime(newValue, 'startTime')
                                                                setEventStartTime(newValue)
                                                            }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </Col>
                                                <Col md={12} className='popup_input validatorAvailabilityFields mt-3'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <MobileTimePicker
                                                            label={t('superAdmin.End_Time')}
                                                            id='endTime'
                                                            name='endTime'
                                                            value={eventEndTime}
                                                            onChange={(newValue) => {
                                                                manageEventTime(newValue, 'endTime')
                                                                setEventEndTime(newValue)
                                                            }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </Col>

                                                {validatorError?.startEndTime &&
                                                    <div className="error-text">
                                                        {t('errors.validator_start_EndTime')}
                                                    </div>
                                                }
                                                <Col md={12} className='popup_select validatorAvailabilityFields validatorTimeZoneField'>
                                                    {/* <FormControl fullWidth>
                                                    <InputLabel id="timezone-select-label">{t('superAdmin.Time_Zone')}</InputLabel>
                                                    <Select
                                                        labelId="timezone-select-label"
                                                        id="timezone"
                                                        name="timezone"
                                                        value={validatorOneTimeData?.timezone}
                                                        label={t('superAdmin.Time_Zone')}
                                                        onChange={(e) => handleChangeTimeZone(e, 'timezone')}
                                                    >
                                                        {timezone.map((item, idx) => {
                                                            return (
                                                                <MenuItem key={idx} value={item?.label}>{item?.label}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl> */}
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            disablePortal
                                                            value={validatorOneTimeData?.timezone}
                                                            options={timezone}
                                                            getOptionLabel={(option) => option}
                                                            onChange={(e, val) => handleChangeTimeZone(val, 'timezone')}
                                                            renderInput={(params) => <TextField {...params} label={t('superAdmin.Time_Zone')} />}
                                                        />
                                                    </FormControl>
                                                </Col>
                                                <Col md={12} className='popup_select'>
                                                    {/* <FormControl fullWidth>
                                                    <InputLabel id="currency-select-label2">{t('superAdmin.Currency')}</InputLabel>
                                                    <Select
                                                        labelId="currency-select-label2"
                                                        id="_currency"
                                                        name='_currency'
                                                        value={validatorOneTimeData?.currency}
                                                        label={t('superAdmin.Currency')}
                                                        onChange={(e) => handleChangeTimeZone(e, 'currency')}
                                                    >
                                                        {currencyName.map((item, idx) => {
                                                            return (
                                                                <MenuItem key={idx} value={item}>{item}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl> */}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={6} className="col-md-12 mt-3">

                                            {(validatorAvailabiltyTimeArray?.length > 0) &&
                                                <>
                                                    <h6 className="mob_margin_top">{t('validators_info.Validator_Selected_details')}</h6>
                                                    <div className='my-3 border rounded validator_table_area'>
                                                        <table className="table table_design">
                                                            <tr>
                                                                <th>{t('validators_info.Date')}</th>
                                                                <th>{t('superAdmin.Start_Time')}</th>
                                                                <th>{t('superAdmin.End_Time')}</th>
                                                                <th>{t('superAdmin.Time_Zone')}</th>
                                                                <th></th>
                                                            </tr>



                                                            {validatorAvailabiltyTimeArray?.map((val, index) => {
                                                                let startTime = val?.startTime ? moment(val?.startTime).format('hh:mm A') : '';
                                                                let secondWat = val?.startTime ? moment(val?.startTime?.$d).format('hh:mm A') : "";
                                                                return <>
                                                                    <tr key={index}>
                                                                        <td>{val?.date && moment(val?.date).format("DD MMM YYYY")}</td>
                                                                        <td>{val?.startTime && (dayjs(val?.startTime).format('h:mm A  '))}</td>
                                                                        <td>{val?.endTime && dayjs(val?.endTime).format('h:mm A  ')}</td>
                                                                        <td>{val?.timezone}</td>
                                                                        <td className="table_design_icons"><DeleteIcon sx={{ color: 'red' }} onClick={() => deleteAvailabiltyDetail(val, index)} /><ModeEditIcon onClick={() => editAvailabiltyDetail(val, index)} />  </td>
                                                                    </tr>
                                                                </>
                                                            })}
                                                        </table>
                                                        {/* <Button onClick={(e)=>submitAllValidatorDetSelected(e)}>Submit</Button> */}
                                                    </div>
                                                    <button onClick={(e) => submitAllValidatorDetSelected(e)} className="button table_desin_btn"  >{t('form.submit')}</button>

                                                </>
                                            }
                                        </Col>
                                    </Row>
                                }
                                {allErrors?.allFeild &&
                                    <div className="error-text">
                                        {t('validators_info.Please_Choose_your_Availabiltity_Details')}
                                    </div>
                                }
                            </div>

                        </Box>
                    </div>
                </div>
            </div>
        </Row>
    )
}
export default ValidatorDateByDetail;