import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Typography } from '@mui/material';

const StepperSidebar = ({steps , activeIndex , className, handleStepperClick}) => {
    return (<Stepper className={className} activeStep={activeIndex} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={index} onClick={()=>handleStepperClick(index)} style={{cursor: 'pointer'}}>
                        <StepLabel
                            optional={<Typography variant="caption">{step.desc}</Typography>}
                        > {step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>);
}

export default StepperSidebar;