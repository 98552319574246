import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import {
    Box, TextField,
    FormHelperText,
    OutlinedInput,
    InputLabel,
    Autocomplete,
    FormControl
} from '@mui/material';
import { IconInFormImg } from "../../../../commonComponents/Images";
import SectionHeader from "../../../../commonDashboardComp/sectionHeader";
import Popup from "../../../../commonComponents/popup";

import {
    OrgVerifyEmailMobileOtpService,
    OrgCheckEmailMobileAvailabilityService
} from "../../../../services/orgAuth.services";
import { showSingleToastSuccess, showToastError} from "../../../../utils/helpers";
import { Link } from "react-router-dom";
import SaveEditSettingsBtn from "../../../../commonDashboardComp/saveEditSettingsBtn";
import { allowOnlyLetters } from "../../../../utils/helpers";
import { Country, State, City } from 'country-state-city';
import {validEmail } from "../../../../utils/formatValidator";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const OrgInfoSetting = ({ orgId, error, setError, saveAccountInfo, formValues, setFormValues, disabledAccountInfo, setDisabledAccountInfo, isLoading }) => {

    const { t } = useTranslation();
    const [emailError, setEmailError] = useState(false);
    const [open, setOpen] = useState(false);
    const [counter, setCounter] = useState(0);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const [incorrectOTP, setIncorrectOTP] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [cityListAccordingToCountry, setCityAccordingToCountry] = useState({ permanentCity: [], currentCity: [] });

    const newCountryList = [];
    Country.getAllCountries()?.filter((a) => {
        newCountryList.push(a?.name);
        return a?.name;
    })

    const [cityList, setCityList] = useState([]);
    const setFormCityIntialValue = () => {
        const cityListSamp = [];
        if (formValues?.country) {
            let code = Country.getAllCountries()?.find(a => a?.name === formValues?.country);
            City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                if (cityListSamp.indexOf(a?.name) === -1) { cityListSamp.push(a?.name); }
            });
        }
        setCityList(cityListSamp?.length > 0 ? cityListSamp : []);

    }



    const checkEmailAvailability = () => {
        setEmailError(false);
        if (!validEmail(formValues.email)) {
            setEmailError(true); return;
        }

        try {
            let params = { organizationId: orgId, 'email': formValues.email }
            OrgCheckEmailMobileAvailabilityService(params)
                .then((data) => {
                    if (data?.status !== 200) {
                        setEmailError(true); return;
                    }
                    // showToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`))
                    showSingleToastSuccess(t(`apiMessages.OTP_SEND_SUCCESS`))
                    setCounter(60);
                    handleOpen();
                })
                .catch((error) => {
                    setEmailError(true);
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const verifyEmail = (e) => {
        e.preventDefault();
        if (formValues.email === '' || !validEmail(formValues.email)) { return; }
        setOtpError(false);
        if (otp === '' || otp.length < 6) {
            setOtpError(true); return;
        }

        try {
            setLoading(true)
            let params = { organizationId: orgId, email: formValues.email, otp: otp }
            OrgVerifyEmailMobileOtpService(params)
                .then(async (data) => {
                    if (data.status === 200) {
                        setFormValues({ ...formValues, user_email: formValues.email, isEmailVerify: true })
                        handleClose();
                    } else {
                        setOtpError(true); setIncorrectOTP(true);
                        showToastError(t(`apiMessages.${data.data.data}`))
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    setError(true);
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        catch (error) {
            console.log(error);
            setLoading(false)
        }
        setLoading(false)
    }

    const setCountryCityList = (val) => {
        const cityListSamp = [];
        if (formValues?.country) {
            let code = Country.getAllCountries()?.find(a => a?.name === val);
            City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                if (cityListSamp.indexOf(a?.name) === -1) { cityListSamp.push(a?.name); }

            });
        }
        setCityList(cityListSamp);
        setFormValues({ ...formValues, country: val, city: "" });
    }

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            } else {
                clearInterval(myInterval)
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    }, [counter]);

    useEffect(() => {
        setFormCityIntialValue();
    }, [formValues]);

    const setPhoneNum = (value) => {
        setError(formValues.mobile === '' && formValues.mobile.length < 10 && formValues.mobile.length > 13 ? true : false)
        if (formValues.mobile === '' && formValues.mobile.length < 10 && formValues.mobile.length > 13) {
            return;
        }
        setFormValues({ ...formValues, mobile: value })
        // isValid(validatePhoneNumber(event.target.value))
    }

    return (<>
        <SectionHeader pretitle={t('account')} title={t('Account_Info')} style={{ marginTop: '0px' }} />
        <Row className="grid">
            <div className="grid-column myProfileSettings">
                <div className="widget-box area-widget">
                    <p className="widget-box-title">{t('onboarding.organizationInfo')}
                        <SaveEditSettingsBtn
                            isLoading={isLoading}
                            canEdit={!disabledAccountInfo}
                            onEdit={(e) => { setDisabledAccountInfo(false) }}
                            onSave={saveAccountInfo} />
                    </p>
                    <div className="widget-box-content">
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '98%' },
                            }}
                            noValidate
                            autoComplete="off"
                            className="userdetail-form account_info_org_area orgInfoSetting"
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <Row>
                                <Col md={6} className="fullWidth" >
                                    <TextField
                                        // error={(error && formValues.userName === '')?true:
                                        // (error && !firstLastNameValidate(formValues.userName))?true:false}
                                        // helperText={(error && formValues.userName === '')?t('errors.name_required'):
                                        //             (error && !firstLastNameNumberValidate(formValues.userName)) ? t('errors.name_format') :
                                        //             (error && !firstLastNameValidate(formValues.userName))?t('errors.name_length'):''}
                                        disabled={true}
                                        value={formValues.userName}
                                        onChange={(e) => e.target.value.replace(/[^0-9]/g, '').length <= 10 ? setFormValues({ ...formValues, userName: allowOnlyLetters(e.target.value) }) : ''}
                                        label={t('form.user_name')} />
                                </Col>
                                <Col md={6} className="fullWidth">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">{t('form.label_email')}</InputLabel>
                                        <OutlinedInput
                                            disabled={disabledAccountInfo}
                                            error={(formValues.email === '') ? true : (!validEmail(formValues.email)) ? true : false}
                                            label={t('form.label_email')} icon={IconInFormImg}
                                            value={formValues.email}
                                            onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                            endAdornment={((formValues?.email?.toLowerCase() === formValues?.user_email?.toLowerCase() && formValues.isEmailVerify)) ?
                                                <Button disabled={disabledAccountInfo} className="verify_btn verified_btn" >{t('verified')}</Button> :
                                                ((validEmail(formValues.email))) ?
                                                    <Button disabled={disabledAccountInfo} className="verify_btn orgInfoVerifyBtn" onClick={checkEmailAvailability}>{t('verify_now')}</Button> : ''}
                                        />
                                        <FormHelperText>
                                            {
                                                (formValues.email === '') ? t('errors.email_required') :
                                                    (!validEmail(formValues.email)) ? t('errors.email_format') :
                                                        (emailError) ? t('errors.Email_is_already_taken') : ''
                                            }</FormHelperText>
                                    </FormControl>
                                </Col>
                                <Col md={6} className="fullWidth">
                                    <TextField
                                        disabled={true}
                                        value={formValues.url}
                                        onChange={(e) => setFormValues({ ...formValues, secondaryEmail: e.target.value })}
                                        label={t('form.url_user')} />
                                </Col>
                                <Col md={6} className="fullWidth">
                                    {/* <TextField 
                                error={(error && formValues.mobile !== '' && formValues.mobile?.length < 10)?true:false}
                                helperText={(error && formValues.mobile !== '' && formValues.mobile?.length < 10)?t('errors.enter_correct_phone'):''}
                                disabled={disabledAccountInfo}
                                value={formValues.mobile} 
                                onChange={(e)=>setFormValues({...formValues, mobile : e.target.value})} 
                                label={t('Phone_No')}/> */}
                                    <PhoneInput
                                        // error={(error && formValues.mobile !== '' && formValues.mobile?.length < 13) ? true : false}
                                        inputStyle={{ paddingLeft: 50 }}
                                        countryCodeEditable={false}
                                        disabled={disabledAccountInfo}
                                        label={t('Phone_No')}
                                        id="mobile"
                                        name="mobile"
                                        placeholder={t('form.label_your_mobile')}
                                        native={true}
                                        value={formValues.mobile}
                                        defaultCountry="IN"
                                        variant="outlined"
                                        onChange={(newValue) => {
                                            setPhoneNum(newValue)
                                        }}
                                    />
                                    {error && <p style={{ color: 'red' }} className="error-message">{t('Please_entere_minimumm_10_digit_mobile_num')}</p>}


                                </Col>
                                <Col md={6} className="fullWidth">

                                    {/* <InputLabel id="co-country-select-label">{t('country')}</InputLabel>
                                    <Select
                                            disabled={disabledAccountInfo}
                                            labelId="co-country-select-label"
                                            value={formValues.country}
                                            label={t('country')}
                                            onChange={(e)=>setFormValues({...formValues, country:e.target.value})}
                                        >
                                        {CountriesList.map((item,idx)=>{
                                            return (
                                                <MenuItem key={idx} value={item}>{item}</MenuItem>
                                            )
                                        })}
                                    </Select> */}
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            className="orgInfoSelect"
                                            disablePortal
                                            disabled={disabledAccountInfo}
                                            value={formValues.country || ''}
                                            options={newCountryList}
                                            getOptionLabel={(option) => option}
                                            onChange={(e, val) => { setCountryCityList(val) }}
                                            renderInput={(params) => <TextField {...params} label={t('country')} />}
                                        />
                                    </FormControl>

                                </Col>
                                {/* <Col md={6} className="fullWidth">
                                <FormControl fullWidth>
                                    <InputLabel id="co-language-select-label">Language</InputLabel>
                                    <Select
                                            disabled={disabledAccountInfo}
                                            labelId="co-language-select-label"
                                            value={formValues.language}
                                            label="Country"
                                            onChange={(e)=>setFormValues({...formValues, language:e.target.value})}
                                        >
                                        {LanguageList.map((item,idx)=>{
                                            return (
                                                <MenuItem key={idx} value={item.value}>{item.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Col> */}
                                <Col md={6} className="fullWidth">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            className="orgInfoSelect"
                                            disablePortal
                                            disabled={disabledAccountInfo}
                                            value={formValues.city || ''}
                                            options={cityList}
                                            getOptionLabel={(option) => option}
                                            onChange={(e, val) => setFormValues({ ...formValues, city: val })}
                                            renderInput={(params) => <TextField {...params} label={t('city')} />}
                                        />
                                    </FormControl>
                                </Col>
                                <Col md={6} className="fullWidth">
                                    <TextField
                                        error={(error && formValues.secondaryEmail !== '' && !validEmail(formValues.secondaryEmail)) ? true : (error && formValues.secondaryEmail?.toLowerCase() === formValues?.email?.toLowerCase()) ? true : false}
                                        helperText={(error && formValues.secondaryEmail !== '' && !validEmail(formValues.secondaryEmail)) ? t('errors.email_format') : (error && formValues.secondaryEmail?.toLowerCase() === formValues?.email?.toLowerCase()) ? t('errors.secondary_email_diff') : ''}
                                        disabled={disabledAccountInfo}
                                        value={formValues.secondaryEmail}
                                        onChange={(e) => setFormValues({ ...formValues, secondaryEmail: e.target.value.toLowerCase() })}
                                        label={t('Secondary_Email')} />
                                </Col>
                            </Row>
                        </Box>
                    </div>
                </div>
            </div>

            <Popup heading={t('form.submit_otp')} handleClose={handleClose} open={open}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '98%' },
                    }}
                    noValidate
                    autoComplete="off"
                    className="userdetail-form"
                    onSubmit={(e) => verifyEmail(e)}
                >
                    <p>{t('errors.Otp_sent_on_your_email')}</p>
                    <div className="otp_counter">{counter} {t('form.seconds')}</div>
                    <TextField
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        error={(otpError && otp === '') ? true : (otpError && otp.length < 6) ? true : (otpError && incorrectOTP) ? true : false}
                        helperText={(otpError && otp === '') ? t('errors.Enter_OTP') : (otpError && otp.length < 6) ? t('errors.OTP_is_of_6digits') : (otpError && incorrectOTP) ? t('apiMessages.OTP_MISS_MATCH') : ""}
                        inputProps={{ maxLength: 6 }}
                        label={t('errors.otp')} />

                    {counter === 0 &&
                        <Link to="#"
                            onClick={(e) => { e.preventDefault(); checkEmailAvailability() }}>
                            {t('Resend_Otp')}
                        </Link>
                    }

                    <button disabled={loading} className="button grey">
                        {loading ? t('errors.Please_Wait') : t('form.submit')}
                    </button>
                </Box>
            </Popup>

        </Row></>)
}

export default OrgInfoSetting;