import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AvatarImg } from "../commonComponents/Images";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import { getTargetAllList } from "../services/onBoarding.services";
import { changeDateFormatddmmyyyy } from "../utils/helpers";
import { getImageUrl } from "../utils/helpers";
import { useSelector } from "react-redux";
import { selectCurrentUserRole } from "../Reducer/user/user.selector";
const TargetBox = () => {
  const { t } = useTranslation();
  const role = useSelector(selectCurrentUserRole)
  const [targetData, setTargetData] = useState([]);
  useEffect(() => {
    allData();
  }, [role])

  const allData = async () => {
    await getTargets();

  }

  const getTargets = async () => {
    getTargetAllList(0, 1)
      .then((response) => {
        if (response?.data?.status === 200) {
          const _data = response?.data?.data?.data;
          setTargetData(_data);
        }
        // setLoading(false)
      })
      .catch((error) => {
        console.log(error, "error");
      })

  }

  const getTargetCompletePercentage = (target) => {
    let percentage = 0;
    let totalUnits = target?.targetUnit;
    let completedUnits = 0;

    if(target?.events?.length > 0)
    {
        target?.events?.map((item)=>{
          if(item?.eventEvidence.length>0){
            item.eventEvidence.map((evidence)=>{
              completedUnits += evidence.unit;
            })
          }
          else{
            completedUnits += item?.unitCompleted;
          }
            
        })

        percentage = (completedUnits / totalUnits) * 100;
    }
    return percentage.toFixed(1);
}

let taglineText = "";
if(targetData.length != 0){
  switch(targetData[0].spredeInterestSubCategory.spredeInterestOptions){
    //Earth
    case 'Area Covered By Trees':
        taglineText = "Sq.M of Area To Be Covered With Trees";
        break;
    case 'Trees Planted':
        taglineText = "Trees To Be Planted";
        break;
    case 'Waste Recycled':
        taglineText = "KG of Waste To Be Recycled";
        break;
    case 'Plogging':
        taglineText = "KG of Garbage To Be Collected";
        break;
    case 'Compost Created':
        taglineText = "KG of Compost To Be Created";
        break;
    //Water
    case 'Renewable Electricity Consumption Hydro':
        taglineText = "K.W. of Renewable Energy To Be Produced";
        break;
    case 'Water Consumption Reduction':
        taglineText = "KL of Water Consumption To Be Reduced";
        break;
    case 'Beach Cleanup':
        taglineText = "KGs of Garbage To Be Collected";
        break;
    case 'Waste Water Recycled':
        taglineText = "KLs of Water To Be Recycled";
        break;
    case 'Water Body Rejuvenation':
        taglineText = "Water Bodies To Be Rejuvenated";
        break;
    //Fire
    case 'Electricity Consumption Reduction':
        taglineText = "K.W. Of Reduction In Electricity Consumption";
        break;
    case 'Fossil Fuel Consumption Reduction':
        taglineText = "KL Of Reduction In Fossil Fuel Consumption";
        break;
    case 'Renewable Electricity Consumption Solar':
        taglineText = "K.W. of Solar Energy To Be Used";
        break;
    case 'Cooking Gas Consumption Reduction':
        taglineText = "Cc Of Reduction In Cooking Gas Consumption";
        break;
    //Air
    case 'Public Transport Rides Taken':
        taglineText = "Rides of Public Transport To Be Taken";
        break;
    case 'Renewable Electricity Consumption Wind':
        taglineText = "K.W. of Wind Energy To Be Used";
        break;
    //Soul
    case 'Wildlife Conservation':
        taglineText = "Events To Be Hosted";
        break;
    case 'Women in Workforce':
        taglineText = "Women To Be Hired";
        break;
    case 'Training and Awareness':
        taglineText = "Hours To Be Hosted";
        break;
    case 'Vegetarian Meals':
        taglineText = "Vegetarian Meals To Be Eaten";
        break;
    case 'Payment to NGOs':
    taglineText = "Dollars To Be Donated To NGOs";
    break;
    case 'Time Spent with NGOs':
        taglineText = "Hours To Be Spent with NGOs";
        break;
    case 'Persons Hired':
        taglineText = "People To Be Hired";
        break;
  }
}

  return (<>
   
      <div className="widget-box targetBoxComp">
        {
          (role != 'superAdmin')?<div className="widget-box-settings">
          <div className="post-settings-wrap">
            <div className="widget-box-post-settings-dropdown-trigger gray-color">
              <Link to="/target">{t('button.view_more')}</Link>
            </div>
          </div>
        </div>:''
        }
        
        <p className="widget-box-title">{t('target')}</p>
        {targetData.map((item, index) => (
          <div className="widget-box-content" key={index}>
            <div className="user-status-list">

            </div>
            <div className="user-status-list tab-user-list">
                <div >
                  <img src={getImageUrl(item?._id, 'target', item?.photo)} alt="" className="target-top-img" />
                </div>
                <div className="target-box">
                  <h6 className=""><div className="bold">{item?.title}</div></h6>
                  <p className="">{item?.description.substring(0, 20)}</p>
                </div>
            </div>

            <div className="information-line-list">
              <div className="information-line target-para">
                <p className="information-line-title">{t('category')}</p>
                <p className="information-line-text">{t(`${item?.spredeInterestCategory?.spredeInterest}`)}</p>
              </div>

              <div className="information-line target-para">
                <p className="information-line-title">{t('sub_category')}</p>
                <p className="information-line-text">{(t(`${item?.spredeInterestSubCategory?.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) !== `${item?.spredeInterestSubCategory?.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) ? t(`${item?.spredeInterestSubCategory?.spredeInterestOptions.replace(/\s+/g, '_').toLowerCase()}`) : item?.spredeInterestSubCategory?.spredeInterestOptions}</p>
              </div>

              <div className="information-line target-para">
                <p className="information-line-title">{t('from')}</p>
                <p className="information-line-text">{changeDateFormatddmmyyyy(item?.fromDate)}</p>
                <p className="information-line-title">{t('to')}</p>
                <p className="information-line-text">{changeDateFormatddmmyyyy(item?.toDate)}</p>
              </div>

              <div className="information-line target-para">

              </div>

              <div className="information-line mt-2">
                <p className="information-line-title" style={{ width: '80%', fontSize: '15px' }}>#{item?.targetUnit} {taglineText}
                  {
                    ((getTargetCompletePercentage(item))>100)?
                    <LinearProgress value={getTargetCompletePercentage(item)} className="target-progress target-over" style={{ marginTop: '4px'   }} variant="determinate" color="success" title={t('test')} />:
                    <LinearProgress value={getTargetCompletePercentage(item)} className="target-progress" style={{ marginTop: '4px' }} variant="determinate" color="success" title={t('test')} />
                  }
                  
                </p>
                <p className="presentage-text">{getTargetCompletePercentage(item)}%</p>
              </div>
            </div>
          </div>
        ))}
        {
          (role != 'superAdmin')?<div className="widget-box-content">
          <Link to="/add-target" className="button small primary target-btn pd-none">{t('button.add_new_target')} <AddOutlinedIcon /> </Link>
        </div>:''
        }
        
      </div>
    
  </>
  )
}

export default TargetBox;