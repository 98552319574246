export const selectCurrentUser = (state) => state.user.currentUser;
export const selectCurrentUserRole = (state) => state.user.role;
export const selectLoadNewPosts = (state) => state.user.loadNew;
export const selectValidatorChange = (state) => state.user.validatorChange;
export const selectCurrentOrg = (state) => state.user.currentOrg;
export const selectCurrentOrgRole = (state) => state.user.currentOrgRole;
export const selectSearchText = (state) => state.user.searchText;
export const selectUserWorkingOrg = (state) => state.user.userWorkingOrg;
export const selectNotificationCount = (state) => state.user.notificationCount;
export const selectCanSwitch = (state) => state.user.canSwitch;
export const selectSwitchOrgPanel = (state) => state.user.switchOrgPanel;
export const selectSwitchUserPanel = (state) => state.user.switchUserPanel;

