import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import RoundCheckbox from "../../../commonComponents/roundCheckbox";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setValidatorChange } from "../../../Reducer/user/user.action";
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { useSelector } from "react-redux";
import { selectCurrentUser, selectValidatorChange } from "../../../Reducer/user/user.selector";
import { getImageRequestService } from "../../../services/auth.services";
import dayjs from 'dayjs';
import {getEventDetailByDate, postValidatorInfo } from "../../../services/event.services";
import { useNavigate } from "react-router-dom";
import {currencyName, IdTypes, timezone, rateOptions } from "../../../config/constants";
import { allowOnlyLetters, getImageUrl, showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import BankInfo from "./bankInfo";
import CoverImagePopUp from "../../../commonDashboardComp/coverImagePopUp";
import { Country, City } from 'country-state-city';
import GreenCheckBox from "../../../commonComponents/greenCheckbox/greenCheckbox";
const ValidatorForm = ({ handleClose, handleValidatorValueChange, isValidatorOff }) => {
    const dispatch = useDispatch();
    const validatorChange = useSelector(selectValidatorChange)
    const [dateCAL, setDateCAL] = useState('');
    const [eventStartTime, setEventStartTime] = useState('');
    const [eventEndTime, setEventEndTime] = useState('');
    const dateOptions = { timeZone: 'UTC', month: 'long', day: 'numeric', year: 'numeric' };
    const newCountryList = [];

    Country.getAllCountries()?.filter((a) => {
        if (City.getCitiesOfCountry(a?.isoCode)?.length > 0) {
            newCountryList.push(a?.name);
            if (newCountryList?.indexOf(a?.name) === -1) { newCountryList.push(a?.name); }
        }
        return a?.name;
    })

    const [cityList, setCityList] = useState([]);
    const [validatorError, setValidatorError] = useState({});
    const [currentIndexChoosen, setCurrentIndexChoosen] = useState(-1);
    const [allErrors, setAllErrors] = useState(null);
    const [error, setError] = useState(false);
    const [isValidatorDetailToShow, setisValidatorDetailToShow] = useState(false);
    const validatorDataSelected = {
        "date": "",
        "startTime": "",
        "endTime": "",
        "timezone": "",
        "currency": "",
    }
    const [validatorOneTimeData, setValidatorOneTimeData] = useState(validatorDataSelected);
    const [validatorAvailabiltyTimeArray, setValidatorAvailabiltyTimeArray] = useState([])
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const INITIAL_FORM_VALUES = {
        "region": "",
        "nationalId": "",
        "country": "",
        "city": "",
        "nationalProof": "",
        "cv": "",
        "rate_per_hour": "",
        "rate_per_participant": "",
        "fixed_rate": "",
        "currency": "",
        "validatorDetail": [
            {
                "date": "",
                "startTime": "",
                "endTime": "",
                "timezone": "",
                "currency": "",
                "region": "",
                "rate_per_hour": "",
                "rate_per_participant": "",
                "fixed_rate": "",
            }
        ]
    }
    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);
    const { t } = useTranslation();
    const [photoOpen, setPhotoOpen] = useState(false);
    const handlePhotoOpen = () => setPhotoOpen(true);
    const handlePhotoClose = () => setPhotoOpen(false);
    const [validatorTermsCheck, setValidatorTermsCheck] = useState(false);
    const [imageGetData, setImageGetdata] = useState(null);

    useEffect(() => {

        // let selectedDate = moment(dateCAL);
        //let currentDate = selectedDate.format('YYYY-MM-DD');
        // GetEventByDateDetail(currentDate);
        getPrepopulatedData()
    }, [])

    const loadCityList = (data) => {
        const cityListSamp = [];
        if (data.country != '') {
            let code = Country.getAllCountries()?.find(a => a?.name === data.country);
            City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                if (cityListSamp.indexOf(a?.name) === -1) {
                    cityListSamp.push(a?.name);
                }
            });
        }
        setCityList(cityListSamp?.length > 0 ? cityListSamp : []);
    }
    const getPrepopulatedData = () => {
        let dateToday = moment(new Date, 'DD-MM-YYYY').format();
        getEventDetailByDate(dateToday).
            then((resp) => {
                if (resp?.status === 200) {
                    // setFormValues(res);
                    setFormValues(resp?.data?.data);
                    loadCityList(resp?.data?.data);
                }
            })
            .catch((error) => {
                if (error?.data?.data === 'DATA_NOT_FOUND') {
                    setisValidatorDetailToShow(true)
                }
            })
    }
    const handleSubmitForm = (e) => {
        e.preventDefault();
        let dataCollected2 = validatorAvailabiltyTimeArray?.filter((a => {
            const objVal = a.date && a.startTime && a.endTime && a.timezone
            return objVal;
        }));
        const FinalObj = { ...formValues, validatorDetail: dataCollected2 };
        if (dataCollected2?.length !== validatorAvailabiltyTimeArray?.length) {
            showToastError("Please Fill Selected date's Detail")
        }
        if(validatorTermsCheck === false) {
            setError(true);
        }
        else {
            //if (dataCollected2?.length === 0) { showSingleToastSuccess(t('errors.No_change_have_been_made')); return; }
            if (allValidatorsErrors(dataCollected2)) {
                postValidatorInfo(FinalObj).
                    then((resp) => {
                        if (resp.status === 200) {
                            showSingleToastSuccess(t('errors.Validator_Form_Have_been_submitted_Successfully'));
                            dispatch(setValidatorChange(!validatorChange));
                            navigate('/validator-availibility');
                            setValidatorAvailabiltyTimeArray([]);

                        }
                    })
                    .catch((error) => console.log(error))
            }
            else {
                console.log("Errors");
            }
        }

    }
    const allValidatorsErrors = (arrayOfData) => {
        let errors = {};
        let cnt = 0;
        if (formValues?.country == '' || formValues?.country == null) {
            errors['country'] = true; cnt = cnt + 1;
        }
        if (formValues?.city == '' || formValues?.city == null) {
            errors['city'] = true; cnt = cnt + 1;
        }
        if (formValues?.nationalId == '') {
            errors['nationalId'] = true;
            cnt = +1;
        }
        // if (formValues?.cv == '') {
        //     errors['cv'] = true;
        //     cnt += 1;
        // }
        if (formValues?.nationalProof == '') {
            errors['nationalProof'] = true;
            cnt += 1;
        }
        if (arrayOfData?.length === 0) {
            cnt += 1;
            errors['allFeild'] = true;
        }
        if (!formValues?.rate_per_hour && !formValues?.rate_per_participant && !formValues?.fixed_rate) {
            errors['rate'] = true;
            cnt += 1;
        }
        if (!formValues?.currency || formValues?.currency == null) {
            errors['currency'] = true;
            cnt += 1;
        }

        setAllErrors(errors);
        return cnt == 0;

    }

    const containsStringOnly = (value) => {
        let contains = true;
        for (let i = 0; i < value.length; i++) {
            if ((value[i] >= 'a' && value <= 'z') || (value[i] >= 'A' && value <= 'Z') || value[i] == ' ')
                contains = true;
            else return false;
        }
        return true;

    }

    const handleChange = (e) => {
        const formValuesNew = { ...formValues };
        if (e.target.name === 'region') {
            if (containsStringOnly(e.target.value)) {
                formValuesNew[e.target.name] = e.target.value;
                setAllErrors({ ...allErrors, region: '' });
                formValuesNew[e.target.name] = e.target.value;

            }
        }
        if (e.target.name == 'nationalId') {
            formValuesNew[e.target.name] = e.target.value;
            setAllErrors({ ...allErrors, nationalId: '' })

        }
        setFormValues(formValuesNew);
    }

    const updateArrayObjectOrShow = (data, currentDate) => {
        const copiedArray = { ...formValues, ...data };
        let { _id, userId, region, nationalId, nationalProof, cv, createdAt, updatedAt, validatorDetail } = data;
        const ObjectCopyOfThatDate = data?.validatorDetail?.length === 0 ? validatorDataSelected : { ...validatorDetail[0], fromServer: true, date: currentDate };
        // ObjectCopyOfThatDate['date']=dayjs.format(currentDate).format('YYYY-MM-DD');
        setValidatorOneTimeData(ObjectCopyOfThatDate);
        const copiedFormData = { ...formValues, ...data };
        if (data?.validatorDetail?.length > 0) {
            let copyOfAvilabiltyArray = [...validatorAvailabiltyTimeArray];
            let findDateIndex = validatorAvailabiltyTimeArray?.findIndex(val => dayjs(val?.date).format('YYYY-MM-DD') === currentDate);
            if (findDateIndex < 0) {
                copyOfAvilabiltyArray.push(ObjectCopyOfThatDate);
                setCurrentIndexChoosen(copyOfAvilabiltyArray?.length - 1)
            }
            else {
                setCurrentIndexChoosen(findDateIndex);
            }
            setValidatorAvailabiltyTimeArray(copyOfAvilabiltyArray);
            setAllErrors(null);
        }
        setEventStartTime(data?.validatorDetail[0]?.startTime);
        setEventEndTime(data?.validatorDetail[0]?.endTime);
        setFormValues(copiedFormData);
    }

    const GetEventByDateDetail = (currentDate) => {
        let newDate = dateConversion(currentDate)
        getEventDetailByDate(newDate).
            then((resp) => {
                if (resp?.status === 400) {
                    createNewdataArray(newDate);
                    const copiedFormData = { ...formValues, ...resp?.data?.data };
                    setFormValues(copiedFormData);
                }
                if (resp?.status === 200) {
                    if (resp?.data?.data?.validatorDetail?.length > 0) {
                        updateArrayObjectOrShow(resp?.data?.data, newDate);
                    }
                    else {
                        createNewdataArray(newDate);
                        const copiedFormData = { ...formValues, ...resp?.data?.data };
                        setFormValues(copiedFormData);
                    }
                }
            })
            .catch((error) => {
                createNewdataArray(newDate);
                return;
            });
    }

    const createNewdataArray = (currentDate) => {
        if (validatorAvailabiltyTimeArray?.length > 0) {
            let findDateIndex = validatorAvailabiltyTimeArray?.findIndex(val => dayjs(val?.date).format('YYYY-MM-DD') === dayjs(currentDate).format('YYYY-MM-DD'));
            if (findDateIndex >= 0) {
                let dataOfThatDate = validatorAvailabiltyTimeArray[findDateIndex];
                const NewValidatorOneTimeData = { ...validatorOneTimeData, ...dataOfThatDate };
                setValidatorOneTimeData(NewValidatorOneTimeData);
                setCurrentIndexChoosen(findDateIndex);
                setEventStartTime(NewValidatorOneTimeData?.startTime);
                setEventEndTime(NewValidatorOneTimeData?.endTime);
                // setEventStartTime('');
                // setEventEndTime('');
            }
            else {
                let newObjectData = { ...validatorDataSelected, date: currentDate };
                setValidatorOneTimeData(newObjectData);
                let validatorAvailabiltyTimeArrayCopy = [...validatorAvailabiltyTimeArray];
                validatorAvailabiltyTimeArrayCopy.push(newObjectData);
                setValidatorAvailabiltyTimeArray(validatorAvailabiltyTimeArrayCopy);
                setCurrentIndexChoosen(validatorAvailabiltyTimeArray?.length);
                setEventStartTime('');
                setEventEndTime('');

            }

        }
        else {
            let newObjectData = { ...validatorDataSelected, date: currentDate };
            setValidatorOneTimeData(newObjectData);
            let validatorAvailabiltyTimeArrayCopy = [...validatorAvailabiltyTimeArray];
            validatorAvailabiltyTimeArrayCopy.push(newObjectData);
            setValidatorAvailabiltyTimeArray(validatorAvailabiltyTimeArrayCopy);
            setCurrentIndexChoosen(validatorAvailabiltyTimeArray?.length);
        }


    }
    const checkSelectedDate = (dateComing) => {
        let selectedDate = dateConversion(dateComing);
        let currentDate = moment(dateComing).format('YYYY-MM-DD');
        setDateCAL(currentDate)
        setValidatorError(null);
        GetEventByDateDetail(currentDate);
    }

    const handleFileIdUpload = (param, typeOf, label) => {
        let category = 'validatorInfo';
        let type = typeOf;
        let fileName = Date.now();
        let defaultId = user?._id;
        fetchImageData(category, type, `${fileName}`, defaultId, param, label);
    }

    const fetchImageData = async (category, type, fileName, defaultId, param, label) => {
        try {
            let params = {
                fileName: fileName,
                category: category,
                type: type,
                defaultId: defaultId
            }
            var response = await getImageRequestService(params);
            if (response?.status === 200) {
                let formsValCopy = { ...formValues };
                formsValCopy[type] = fileName;
                setFormValues(formsValCopy);
                let allErrorscpy = { ...allErrors };
                allErrorscpy[label] = '';
                setAllErrors(allErrorscpy);
                putImageRequest(response?.data?.data, param, type, fileName, label);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const putImageRequest = async (url, actParam, type, fileName) => {
        const response = await fetch(url + '&x-ms-blob-type=BlockBlob', {
            method: 'PUT',
            headers: {
                'x-ms-blob-type': `BlockBlob`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data'
            },
            body: actParam
        })
            .then(res => {
                if (res.ok) {
                    const formValuesNew = { ...formValues }
                    if (type === 'validatorId') {
                        formValuesNew['nationalProof'] = fileName;
                    }
                    if (type === 'validatorCv') {
                        formValuesNew['cv'] = fileName;
                    }
                    setFormValues(formValuesNew);

                }
                return res;
            })
            .catch((error) => {
                console.error(error);
            });

        return response;
    }

    const manageEventTime = (time, id) => {
        const copyValidorOneTimeData = { ...validatorOneTimeData };
        copyValidorOneTimeData[id] = time;
        setValidatorOneTimeData(copyValidorOneTimeData);
        // let dt = moment(copyValidorOneTimeData?.startTime?.$d);
        // let dt2 = moment(copyValidorOneTimeData?.endTime?.$d);
        let dt = moment(copyValidorOneTimeData?.startTime?.$d ? copyValidorOneTimeData?.startTime?.$d : copyValidorOneTimeData?.startTime);
        let dt2 = moment(copyValidorOneTimeData?.endTime?.$d ? copyValidorOneTimeData?.endTime?.$d : copyValidorOneTimeData?.endTime);
        const diff = dt.diff(dt2, 'time');
        if (diff < 0) {
            settingValidatorArray(copyValidorOneTimeData);
            let validatorErrorCopy = { ...validatorError, startEndTime: false };
            setValidatorError(validatorErrorCopy);
        }
        else {
            let validatorErrorCopy = { ...validatorError, startEndTime: true };
            setValidatorError(validatorErrorCopy);
        }
    }
    const settingValidatorArray = (dataToBeCopied) => {
        if (currentIndexChoosen >= 0) {
            const validatorAvailabiltyTimeArrayCopy = [...validatorAvailabiltyTimeArray];
            validatorAvailabiltyTimeArrayCopy[currentIndexChoosen] = dataToBeCopied;
            setValidatorAvailabiltyTimeArray(validatorAvailabiltyTimeArrayCopy);
        }
    }
    const handleChangeAmounts = (e) => {
        if (!isNaN(e.target.value)) {
            // const validatorOneTimeDataCopy = { ...validatorOneTimeData };
            // validatorOneTimeDataCopy[e.target.id] = e.target.value;
            // setValidatorOneTimeData(validatorOneTimeDataCopy);
            // settingValidatorArray(validatorOneTimeDataCopy);
            setAllErrors({ ...allErrors, rate: '' })
            const formValuesNew = { ...formValues };
            formValuesNew[e.target.id] = e.target.value;
            setFormValues(formValuesNew)
        }

    }

    const handleChangeTimeZone = (val, id) => {
        const validatorOneTimeDataCopy = { ...validatorOneTimeData };
        if (id === 'timezone') {
            validatorOneTimeDataCopy[id] = val
        }
        else {
            validatorOneTimeDataCopy[id] = val;
        }
        setValidatorOneTimeData(validatorOneTimeDataCopy);
        settingValidatorArray(validatorOneTimeDataCopy);
    }

    const setClass = (date) => {
        let selectedDate = moment(date?.date);
        let currentDate = selectedDate.format('YYYY-MM-DD');
        let fndindex = validatorAvailabiltyTimeArray?.findIndex((a) => moment(a?.date).format('YYYY-MM-DD') === currentDate);
        let clickedDate = dateCAL ? moment(dateCAL).format('YYYY-MM-DD') : '';
        if (clickedDate == currentDate) {
            return "selectedDate1"
        }
        if (fndindex >= 0) {
            return "selectedDate"
        }
    }

    const deleteAvailabiltyDetail = (val, index) => {
        let arrCopy = [...validatorAvailabiltyTimeArray]
        arrCopy?.splice(index, 1);
        setCurrentIndexChoosen(-1);
        setValidatorOneTimeData(validatorDataSelected);
        setEventStartTime('');
        setEventEndTime('');
        setValidatorAvailabiltyTimeArray(arrCopy);
    }

    const editAvailabiltyDetail = (val, index) => {
        setValidatorOneTimeData(val);
        let date1 = val?.date;
        let newDate = new Date(date1);
        setDateCAL(newDate);
        setEventStartTime(val?.startTime);
        setCurrentIndexChoosen(index);
        setEventEndTime(val?.endTime);

    }

    const submitAllValidatorDetSelected = (e) => {
        e.preventDefault();
        handleSubmitForm(e);
    }

    const tileDisabled = ({ activeStartDate, date, view }) => {
        return date < new Date();
    }
    const dateConversion = (date) => {
        return new Date(date).toLocaleDateString('en-US', dateOptions)
    }

    const handleChangeCurrency = (e, id) => {
        const formValuesNew = { ...formValues };
        formValuesNew[id] = e.target.innerText;
        setAllErrors({ ...allErrors, currency: '' })
        setFormValues(formValuesNew);
    }
    const viewIngProof = () => {
        let url = getImageUrl(user?._id, 'validatorId', formValues?.nationalProof);
        setImageGetdata(url)
        handlePhotoOpen();
    }
    const handleCountryChange = (e, val) => {
        //e.preventDefault();
        const cityListSamp = [];
        if (formValues?.country || val) {
            let code = Country.getAllCountries()?.find(a => a?.name === val);
            City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                if (cityListSamp.indexOf(a?.name) === -1) { cityListSamp.push(a?.name); }
            });
        }
        setCityList(cityListSamp?.length > 0 ? cityListSamp : []);
        setFormValues({ ...formValues, country: (val !== null) ? allowOnlyLetters(val) : null, city: "" });
        setAllErrors({ ...allErrors, country: '' })


    }

    return (
        <Row className="grid">
            <div className="grid-column myProfileSettings">
                <div className="widget-box">
                    <div className="widget-box-content">
                        <Box
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '100%', zIndex: '699' },
                            }}
                            noValidate
                            autoComplete="off"
                            className="userdetail-form targetvalidator validation_popup_box"

                            style={{ zIndex: '10000' }}
                        >

                            <div className='my-3'>
                                <h3 className='validatorForm_header'><span className='text-danger'>*</span>{t('validators_info.validator_header')}</h3>

                                <Row>
                                    {/* <Col lg={3} className="col-md-6 popup_input mb_input">
                                        <TextField type='text' id="region" label={t('region')}
                                            name="region"
                                            value={formValues?.region}
                                            sx={{
                                                ".MuiOutlinedInput-root": {
                                                    paddingTop: "1rem",
                                                    flexDirection: "row"
                                                },
                                                img: {
                                                    paddingRight: "1rem"
                                                }
                                            }}
                                            InputProps={{
                                                endAdornment: <img src="/Assets/Pin-Icon-target.png" className="" />
                                            }}

                                            onChange={(e) => handleChange(e)}

                                        />
                                        {allErrors?.region &&
                                            <div className="error-text">
                                                {t('validators_info.Please_Enter_Region')}
                                            </div>
                                        }
                                        {formValues?.region?.length > 15 &&
                                            <div className="error-text">
                                                {t('validators_info.Name_is_too_long')}
                                            </div>
                                        }
                                    </Col> */}

                                    <Col lg={4} className="validatorFormInputs">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                disablePortal
                                                value={formValues?.country}
                                                options={newCountryList}
                                                getOptionLabel={(option) => { return option }}
                                                onChange={(e, val) => {
                                                    //      setFormValues({
                                                    //     ...formValues,
                                                    //         country: (val !== null) ? allowOnlyLetters(val) : null,
                                                    //         city: ''
                                                    // });
                                                    handleCountryChange(e, val)
                                                }}

                                                renderInput={(params) => <TextField {...params} label={t('Country')} />}
                                            />
                                        </FormControl>
                                        {allErrors?.country &&
                                            <div className="error-text">
                                                Please Enter Country
                                            </div>
                                        }
                                    </Col>
                                    <Col lg={4} className="validatorFormInputs">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                disablePortal
                                                value={formValues.city || ''}
                                                options={cityList}
                                                onChange={(e, val) => { setFormValues({ ...formValues, city: val }); setAllErrors({ ...allErrors, city: '' }) }}
                                                renderInput={(params) => <TextField {...params} label={t('city')} />}
                                            />
                                        </FormControl>
                                        {allErrors?.city &&
                                            <div className="error-text">
                                                Please Enter City
                                            </div>
                                        }
                                    </Col>
                                    <Col lg={4} className="col-md-12 uploadCvInput">
                                        <TextField
                                            id="outlined-basic1"
                                            variant="outlined"
                                            type={"file"}
                                            className='fileUpload custom-file-input2'
                                            onChange={(e) => handleFileIdUpload(e.target.files[0], 'validatorCv', 'cv')}
                                            
                                            inputProps={{
                                                accept: "application/pdf",
                                            }}
                                        />


                                        {/* <TextField 
                                             type={"file"} 
                                             className='fileUpload custom-file-input2' 
                                             onChange={(e) => handleFileIdUpload(e.target.files[0], 'validatorCv', 'cv')} 
                                             inputProps={{
                                                   accept: "application/pdf,application/docx,application.doc", 
                                                   endAdornment: <img src="/Assets/upload-inactive-icon.png" className="popup_upload_icon" />
                                        }} /> */}
                                        {/* {allErrors?.cv &&
                                            <div className="error-text">
                                                {t('validators_info.Please_Upload_Your_CV')}
                                            </div>
                                        } */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} className="col-md-12 drop_menu validatorFormInputs2">
                                        <FormControl fullWidth>
                                            <InputLabel id="nationalId-select-label">{t('validators_info.ID_Type')}</InputLabel>
                                            <Select
                                                labelId={t('nationalId_select_label')}
                                                id="nationalId"
                                                name="nationalId"
                                                value={formValues?.nationalId}
                                                label={t('validators_info.ID_Type')}
                                                onChange={(e) => handleChange(e)}
                                            >
                                                {IdTypes.map((item, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={item}>{item}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                        {allErrors?.nationalId &&
                                            <div className="error-text">
                                                {t('validators_info.Please_Select_National_Id')}
                                            </div>
                                        }
                                    </Col>

                                    <Col lg={6} id="customFileInputContainerMaster" className="col-md-12 popup_file validatorFormInputs3">
                                        <div id="customFileInputContainer">
                                            <label id="customFileInputLabel" className="">{(formValues?.nationalId.props?.children)?formValues?.nationalId.props?.children: t('validators_info.national_id')}</label>
                                            <input id="customFileInput" className="custom-file-input" type="file" accept="image/*" onChange={(e) => handleFileIdUpload(e.target.files[0], 'validatorId', 'nationalProof')} />
                                            {allErrors?.nationalProof &&
                                                <div className="inp_file_error">
                                                    {t('validators_info.Please_Upload_National_Proof')}
                                                </div>
                                            }
                                        </div>
                                        {formValues?.nationalProof && <span className="viewProof small_submit_btn me-1" onClick={() => viewIngProof()}>View Proof</span>}

                                        {/* {formValues?.nationalProof && <span id="viewProof" onClick={()=>viewIngProof()}>View Proof</span>} */}
                                    </Col>
                                </Row>
                                <Row>
                                    <h6 className='mb-3 ms-lg-2 pricingHeading'>{t('validators_info.fees_charging_modal')}</h6>
                                    <Col lg={3} className="validatorCurrencyInput">
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                disablePortal
                                                value={formValues?.currency}
                                                options={currencyName}
                                                getOptionLabel={(option) => option}
                                                onChange={(e) => handleChangeCurrency(e, 'currency')}
                                                renderInput={(params) => <TextField {...params} label={t('superAdmin.Currency')} />}
                                            />
                                        </FormControl>
                                        {allErrors?.currency &&
                                            <div className="error-text">
                                                {t('validators_info.Please_Select_currency')}
                                            </div>
                                        }

                                    </Col>
                                    <Col lg={9} className='col-md-12 validatorCheckboxes popup_text_area mt-lg-0'>


                                        <div className="fee_charging_box">
                                            {rateOptions.map((item, index) => {
                                                return <div key={'rateoption'+ index}>
                                                    <RoundCheckbox label={item?.label} key={index} newClassName='activeClass' checked={false} other={true} input={<input type="text" id={item?.chargesMode} value={formValues[item?.chargesMode]} onChange={(e) => { !isNaN(e.target.value) && handleChangeAmounts(e) }} className="checkbox_text_feild" placeholder={t('validators_info.Amount')} checked={true} />} />
                                                </div>
                                            })}
                                        </div>
                                        {allErrors?.rate &&
                                            <div className="error-text">
                                                {t('validators_info.Please_Enter_atleast_one_Charging_Model_Amount')}
                                            </div>
                                        }

                                    </Col>

                                </Row>


                                <Row className=''>
                                    <Col lg={3} className='col-md-6 popup_calender mt-3'>
                                        <div className='border rounded'>
                                            <Calendar
                                                className="validatorCalender manageAvailabilityCalendar"
                                                onChange={(date1) => { checkSelectedDate(date1); setDateCAL(date1); setAllErrors({ ...allErrors, allFeild: '' }) }}
                                                value={dateCAL}
                                                calendarType="US"
                                                //tileDisabled={tileDisabled}
                                                minDate={new Date()}
                                                tileClassName={(date) => setClass(date)}

                                            />
                                        </div>
                                    </Col>
                                    <Col lg={3} className="col-md-6 border rounded popup_time_area mt-3">
                                        <Row>
                                            <Col md={12} className='popup_input validatorAvailabilityFields'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileTimePicker
                                                        label={t('superAdmin.Start_Time')}
                                                        value={eventStartTime}
                                                        id='startTime'
                                                        closeOnSelect
                                                        name='startTime'
                                                        onChange={(newValue) => {
                                                            manageEventTime(newValue, 'startTime')
                                                            setEventStartTime(newValue)
                                                            setAllErrors({ ...allErrors, allFeild: '' })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </Col>
                                            <Col md={12} className='popup_input validatorAvailabilityFields mt-3'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileTimePicker
                                                        label={t('superAdmin.End_Time')}
                                                        id='endTime'
                                                        name='endTime'
                                                        value={eventEndTime}
                                                        onChange={(newValue) => {
                                                            manageEventTime(newValue, 'endTime')
                                                            setEventEndTime(newValue)
                                                            setAllErrors({ ...allErrors, allFeild: '' })
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </Col>

                                            {validatorError?.startEndTime &&
                                                <div className="error-text">
                                                    {t('errors.validator_start_EndTime')}
                                                </div>
                                            }
                                            <Col md={12} className='popup_select validatorAvailabilityFields validatorTimeZoneField'>

                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        disablePortal
                                                        value={validatorOneTimeData?.timezone}
                                                        options={timezone}
                                                        getOptionLabel={(option) => option}
                                                        onChange={(e, val) => { handleChangeTimeZone(val, 'timezone'); setAllErrors({ ...allErrors, allFeild: '' }) }}
                                                        renderInput={(params) => <TextField {...params} label={t('superAdmin.Time_Zone')} />}
                                                    />
                                                </FormControl>
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col lg={6} className="col-md-12">

                                        {(validatorAvailabiltyTimeArray?.length > 0) &&
                                            <>
                                                <h6 className="mob_margin_top">{t('validators_info.Validator_Selected_details')}</h6>
                                                <div className='my-2 border rounded validator_table_area'>
                                                    <table className="table table_design">
                                                        <tr>
                                                            <th>{t('validators_info.Date')}</th>
                                                            <th>{t('superAdmin.Start_Time')}</th>
                                                            <th>{t('superAdmin.End_Time')}</th>
                                                            <th>{t('superAdmin.Time_Zone')}</th>
                                                            <th></th>
                                                        </tr>


                                                        {validatorAvailabiltyTimeArray?.map((val, index) => {
                                                            let startTime = val?.startTime ? moment(val?.startTime).format('hh:mm A') : '';
                                                            let secondWat = val?.startTime ? moment(val?.startTime?.$d).format('hh:mm A') : "";
                                                            return <>
                                                                <tr>
                                                                    <td>{val?.date && moment(val?.date).format("DD MMM YYYY")}</td>
                                                                    <td>{val?.startTime && (dayjs(val?.startTime).format('h:mm A  '))}</td>
                                                                    <td>{val?.endTime && dayjs(val?.endTime).format('h:mm A  ')}</td>
                                                                    <td>{val?.timezone}</td>
                                                                    <td className="table_design_icons"><DeleteIcon sx={{ color: 'red' }} onClick={() => deleteAvailabiltyDetail(val, index)} /><ModeEditIcon onClick={() => editAvailabiltyDetail(val, index)} />  </td>
                                                                </tr>
                                                            </>
                                                        })}
                                                    </table>
                                                    {/* <Button onClick={(e)=>submitAllValidatorDetSelected(e)}>Submit</Button> */}
                                                </div>

                                            </>


                                        }
                                        {/* <button onClick={(e) => submitAllValidatorDetSelected(e)} className="button table_desin_btn"  >{t('form.submit')}</button> */}

                                    </Col>
                                    {allErrors?.allFeild &&
                                        <div className="error-text">
                                            {t('validators_info.Please_Choose_your_Availabiltity_Details')}
                                        </div>
                                    }
                                    <Col lg={6} className="col-md-12">
                                        <div className="form-row rsgister_check_options regTermsLabels">
                                            <GreenCheckBox label={<>
                                                {t('form.terms_conditions_label1')}
                                                <a href="/validator-terms-conditions">{t('form.terms_conditions_label2')}</a>
                                            </>} onChange={(e) => setValidatorTermsCheck(e.target.checked)} />
                                            {error && validatorTermsCheck === false ? <div className='error-text ps-4'>
                                                {t('errors.required_field')}
                                            </div> : ''}
                                        </div> 
                                    </Col>
                                </Row>
                                <div className='text-center my-2'>
                                    <button onClick={(e) => {
                                        if (allErrors?.allField !== '')
                                            submitAllValidatorDetSelected(e)
                                    }} className="button table_desin_btn"  >{t('form.submit')}</button>
                                </div>
                                


                            </div>


                            {/* <BankInfo /> */}
                        </Box>
                    </div>
                </div>
            </div>
            <CoverImagePopUp
                imageShow={imageGetData}
                open={photoOpen}
                handleClose={handlePhotoClose}

            />
        </Row>
    )
}
export default ValidatorForm;