import React, { useEffect, useState } from 'react';
import { getGeneralSettingService } from '../../../services/userSettings.services';
import { GetUserDetailsService } from '../../../services/onBoarding.services';
import { getOrgDetailsService } from '../../../services/orgAuth.services';
import { useSelector } from 'react-redux';
import { selectCurrentUserRole } from '../../../Reducer/user/user.selector';
import { selectCurrentUser } from '../../../Reducer/user/user.selector';
import { selectCurrentOrg } from '../../../Reducer/user/user.selector';
import { getOrgInterestsService } from '../../../services/orgAuth.services';
import { GetInterestsListService } from '../../../services/onBoarding.services';
import { CSVLink } from 'react-csv';
import { fi } from 'date-fns/locale';
import { getOrgGeneralSettingService } from '../../../services/orgAuth.services';
import { getLegalServiceTypeService } from '../../../services/orgAuth.services';
const DownloadProfile = ({handleClick=()=>{}}) => {
  const role = useSelector(selectCurrentUserRole);
  const CURRENTUSER = useSelector(selectCurrentUser);
  const CURRENTORG = useSelector(selectCurrentOrg);
  const [userDetails, setUserDetails] = useState();
  const [userSettings, setUserSettings] = useState();
  const [headers, setHeaders] = useState();
  const [csvDataInfo, setCsvDataInfo] = useState([]);
  const [legalServiceType, setLegalServiceType] = useState();  
  const [interests, setInterests] = useState();
  let current_user = {};
  if (role === 'organization') {
    current_user = CURRENTORG;
  } else {
    current_user = CURRENTUSER;
  }

  useEffect(() => {
    getUserDetails();
    getGeneralSettings();
    getInterestsLists();
    if(role === 'organization'){
      getLegalServiceType();
    }
  }, []);
  useEffect(()=>{
    if(role == 'user' && userDetails && interests && userSettings){
      createCSV(userDetails, role);
    }
    if(role == 'organization' && userDetails && interests && userSettings && legalServiceType){
      createCSV(userDetails, role);
    }
    
  },[interests, userDetails, userSettings, legalServiceType]);
 
  //To get legal structure details if the role is organization.
  const getLegalServiceType = () => {
    try {
        getLegalServiceTypeService()
            .then((data) => {
                if (data?.data?.status === 200) {
                    setLegalServiceType(data?.data?.data)
                }
            })
    }
    catch (error) {
        console.log(error);
    }
}
  //To Get Users general info.
  const getUserDetails = () => {
    if (role === 'organization') {
      getOrgDetailsService(current_user?._id)
        .then((data) => {
          if (data?.data?.status === 200) {
            setUserDetails(data?.data?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      GetUserDetailsService()
        .then((data) => {
          if (data?.data?.status === 200) {
            setUserDetails(data?.data?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getInterestsLists = () => {
    if (role === 'organization') {
        getOrgInterestsService(current_user?._id)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setInterests(data?.data?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    else {
        GetInterestsListService()
            .then((data) => {
                if (data?.data?.status === 200) {
                    setInterests(data?.data?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
}

  //To Get Users setting info.
  const getGeneralSettings = () => {
    if(role === 'organization'){
      getOrgGeneralSettingService(current_user?._id)
      .then((data)=>{
          if(data?.data?.status === 200){
              setUserSettings(data?.data?.data);
              return(data?.data?.data);
          }
      })
      .catch((error)=>{
          console.log(error);
      })
  }
  else{
      getGeneralSettingService()
      .then((data)=>{
          if(data?.status === 200){
            setUserSettings(data?.data);
            return(data?.data?.data);
          }
      })                
      .catch((error)=>{
          console.log(error);
      })
  }            
  };
  
  //To create CSV.
  const createCSV = (data, userRole) => {
    let interestList = createInterestList(userDetails?.spredeInterest);
    if(userDetails && userSettings && interests){
      switch (userRole) {
        case 'user':
          let csvHeader = [
            { label: 'User ID', key: '_id' },
            { label: 'First Name', key: 'firstName' },
            { label: 'Middle Name', key: 'middleName' },
            { label: 'Last Name', key: 'lastName' },
            { label: 'D.O.B', key: 'dob' },
            { label: 'Description', key: 'aboutMe' },
            { label: 'Additional Privacy', key: 'additionalPrivacy' },
            { label: 'Classified Job', key: 'classifyYourJob' },
            { label: 'Joining Date', key: 'createdAt' },
            { label: 'Current City', key: 'currentAddressCity' },
            { label: 'Current Country', key: 'currentAddressCountry' },
            { label: 'E-Mail Address', key: 'email' },
            { label: 'Gender', key: 'gender' },
            { label: 'E-Mail Verified', key: 'isEmailVerify' },
            { label: 'Validator Profile', key: 'isValidator' },
            { label: 'Contact Number', key: 'mobile' },
            { label: 'Degree', key: 'educationCourse' },
            { label: 'University', key: 'educationUniversity'},
            { label: 'Currently Pursuing', key: 'educationIsPursuing' },
            { label: 'Joining Date', key: 'educationStartYear'},
            { label: 'Completion Date', key: 'educationEndYear' },
            { label: 'Organization Name', key: 'organizationName' },
            { label: 'Organization City', key: 'organizationCity' },
            { label: 'Organization Country', key: 'organizationCountry'},
            { label: 'Currently Working', key: 'organizationStatus'},
            { label: 'Job Verified', key: 'organizationVerified' },
            { label: 'Joining Date', key: 'organizationJoining' },
            { label: 'Designation', key: 'organizationDesignation' },
            { label: 'Leaving Date', key: 'organizationLeaving' },
            { label: 'Interest Category', key: 'interestCategory' },
            { label: 'Interest Value', key: 'interestValue' },
            { label: 'Permanent Country', key: 'permanentAddress.country' },
            { label: 'Permanent City', key: 'permanentAddress.city' },
            { label: 'Secondary E-Mail', key: 'secondaryEmail' },
            { label: 'Status', key: 'status' },
            { label: 'User Name', key: 'userName' },
            { label: 'Event Notifications', key: 'events' },
            { label: 'Email Notifications', key: 'emailNotification' },
            { label: 'Facts And Tips Visibility', key: 'factAndTips' },
            { label: 'Search', key: 'searchVisibility' },
            { label: 'Tagging', key: 'tagging' },
            { label: 'Address Visibility', key: 'whoCanSeeAddress'},
            { label: 'College Visibility', key: 'whoCanSeeCollegeName'},
            { label: 'Current Event Visibility', key: 'whoCanSeeCurrentEvent'},
            { label: 'Current Target Visibility', key: 'whoCanSeeCurrentTarget'},
            { label: 'Designation Visibility', key: 'whoCanSeeDesignation'},
            { label: 'Email Visibility', key: 'whoCanSeeEmail'},
            { label: 'Following Organization Visibility', key: 'whoCanSeeFollowingOrganization'},
            { label: 'Interest Visibility', key: 'whoCanSeeInterest'},
            { label: 'Mobile Number Visibility', key: 'whoCanSeeMobile'},
            { label: 'Organization Visibility', key: 'whoCanSeeOrganization'},
            { label: 'Organization Address Visibility', key: 'whoCanSeeOrganizationAddress'},
            { label: 'Post Sharing', key: 'whoCanSharePost'},
          ];  
          let csvData = [];
          let csvDataObj = {};
          csvDataObj['_id'] = data?._id;
          csvDataObj['firstName'] = data?.firstName;
          csvDataObj['middleName'] = data?.middleName;
          csvDataObj['lastName'] = data?.lastName;
          csvDataObj['dob'] = new Date(data?.dob).toLocaleDateString();
          csvDataObj['aboutMe'] = data?.aboutMe;
          csvDataObj['additionalPrivacy'] = data?.additionalPrivacy;
          csvDataObj['classifyYourJob'] = data?.classifyYourJob;
          csvDataObj['createdAt'] = new Date(data?.createdAt).toLocaleDateString();
          csvDataObj['currentAddressCity'] = (data?.currentAddress?.city !='')?data?.currentAddress?.city:'N.A';
          csvDataObj['currentAddressCountry'] = (data?.currentAddress?.country !='')?data?.currentAddress?.country:'N.A';
          csvDataObj['email'] = data?.email;
          csvDataObj['gender'] = data?.gender;
          csvDataObj['isEmailVerify'] = data?.isEmailVerify;
          // csvDataObj['isMobileVerify'] = data?.isMobileVerify;
          csvDataObj['isValidator'] = data?.isValidator;
          csvDataObj['mobile'] = data?.mobile;
          csvDataObj['permanentAddress.country'] = data?.permanentAddress?.country;
          csvDataObj['permanentAddress.city'] = data?.permanentAddress?.city;
          csvDataObj['secondaryEmail'] = (data?.secondaryEmail && data?.secondaryEmail !='')?data?.secondaryEmail:'N.A';
          csvDataObj['status'] = data?.status;
          csvDataObj['userName'] = data?.userName;
  
          //If organization detail is available.
          if (data?.organization.length>0) {
              csvDataObj['organizationName'] = data.organization[0].organizationName;
              csvDataObj['organizationCity'] = data.organization[0].address.city;
              csvDataObj['organizationCountry'] = data.organization[0].address.country;
              csvDataObj['organizationDesignation'] = data.organization[0].designation;
              csvDataObj['organizationStatus'] = data.organization[0].isCurrentlyWorking;
              csvDataObj['organizationVerified'] = data.organization[0].isVerified;
              csvDataObj['organizationJoining'] = new Date(data.organization[0].startYear).toLocaleDateString();
              csvDataObj['organizationLeaving'] = (data.organization[0].endYear != '')?new Date(data.organization[0].endYear).toLocaleDateString():'N.A';
          }
          //Pass blank organization details if organization is empty.
          else {
            csvDataObj['organizationName'] = 'N.A';
            csvDataObj['organizationCity'] = 'N.A';
            csvDataObj['organizationCountry'] = 'N.A';
            csvDataObj['organizationDesignation'] = 'N.A';
            csvDataObj['organizationStatus'] = 'N.A';
            csvDataObj['organizationVerified'] = 'N.A';
            csvDataObj['organizationJoining'] = 'N.A';
            csvDataObj['organizationLeaving'] = 'N.A';
          }
  
          //If education is available.
          if (data?.education.length>0) {
            //If there is only one education record.
            csvDataObj['educationCourse'] = data.education[0].course;
            csvDataObj['educationEndYear'] = (data.education[0].endYear != '')?new Date(data.education[0].endYear).toLocaleDateString():'N.A';
            csvDataObj['educationIsPursuing'] = data.education[0].isCurrentlyPursuing;
            csvDataObj['educationStartYear'] = (data.education[0].startYear != '')?new Date(data.education[0].startYear).toLocaleDateString():'N.A';
            csvDataObj['educationUniversity'] = data.education[0].university;
          }
          //Pass blank education details if education is empty.
          else {
            csvDataObj['educationCourse'] = 'N.A';
            csvDataObj['educationEndYear'] = 'N.A';
            csvDataObj['educationIsPursuing'] = 'N.A';
            csvDataObj['educationStartYear'] = 'N.A';
            csvDataObj['educationUniversity'] = 'N.A';
          }
          //For Interest
          if (interestList) {
            //Picking only the first interest value.
            let keyVal = Object.keys(interestList[0]);
            csvDataObj['interestCategory'] = keyVal[0];
            csvDataObj['interestValue'] = interestList[0][keyVal];
          }
          //Pass blank interest details if interest is empty.
          else {
            csvDataObj['interestCategory'] = 'N.A';
            csvDataObj['interestValue'] = 'N.A';
          }
  
          //For settings
          if(userSettings){
            csvDataObj['emailNotification'] = userSettings?.emailNotifications;
            csvDataObj['events'] = userSettings?.events;
            csvDataObj['factAndTips'] = userSettings?.factAndTips;
            csvDataObj['searchVisibility'] = userSettings?.search;
            csvDataObj['tagging'] = userSettings?.tagging;
            csvDataObj['whoCanSeeAddress'] = userSettings?.whoCanSeeAddress;
            csvDataObj['whoCanSeeCollegeName'] = userSettings?.whoCanSeeCollegeName;
            csvDataObj['whoCanSeeCurrentEvent'] = userSettings?.whoCanSeeCurrentEvent;
            csvDataObj['whoCanSeeCurrentTarget'] = userSettings?.whoCanSeeCurrentTarget;
            csvDataObj['whoCanSeeDesignation'] = userSettings?.whoCanSeeDesignation;
            csvDataObj['whoCanSeeEmail'] = userSettings?.whoCanSeeEmail;
            csvDataObj['whoCanSeeFollowingOrganization'] = userSettings?.whoCanSeeFollowingOrganization;
            csvDataObj['whoCanSeeInterest'] = userSettings?.whoCanSeeInterest;
            csvDataObj['whoCanSeeMobile'] = userSettings?.whoCanSeeMobile;
            csvDataObj['whoCanSeeOrganization'] = userSettings?.whoCanSeeOrganization;
            csvDataObj['whoCanSeeOrganizationAddress'] = userSettings?.whoCanSeeOrganizationAddress;
            csvDataObj['whoCanSharePost'] = userSettings?.whoCanSharePost;
          }
          //Creating new rows for Organization, Education and Interest data.
          csvData.push(csvDataObj); 
          let interestListLength = (interestList)?interestList.length:0;
          let organizationLength = (data?.organization)?data.organization.length:0;
          let educationLength = (data?.education)?data.education.length:0;
          let maxVal = Math.max(interestListLength, organizationLength, educationLength);
          
          if(maxVal != 0){
            for(let i=1; i<maxVal; i++){
              let rowObj = {};
              if(data.organization[i]){
                rowObj['organizationName'] = data.organization[i].organizationName;
                rowObj['organizationCity'] = data.organization[i].address.city;
                rowObj['organizationCountry'] = data.organization[i].address.country;
                rowObj['organizationDesignation'] = data.organization[i].designation;
                rowObj['organizationStatus'] = data.organization[i].isCurrentlyWorking;
                rowObj['organizationVerified'] = data.organization[i].isVerified;
                rowObj['organizationJoining'] = new Date(data.organization[i].startYear).toLocaleDateString();
                rowObj['organizationLeaving'] = (data.organization[i].endYear != '')?new Date(data.organization[i].endYear).toLocaleDateString():'N.A';
              }
              if(data.education[i]){
                rowObj['educationCourse'] = data.education[i].course;
                rowObj['educationEndYear'] = (data.education[i].endYear != '')?new Date(data.education[i].endYear).toLocaleDateString():'N.A';
                rowObj['educationIsPursuing'] = data.education[i].isCurrentlyPursuing;
                rowObj['educationStartYear'] = new Date(data.education[i].startYear).toLocaleDateString();
                rowObj['educationUniversity'] = data.education[i].university;
              }
              if(interestList[i]){
                let keyVal = Object.keys(interestList[i]);
                rowObj['interestCategory'] = keyVal[0];
                rowObj['interestValue'] = interestList[i][keyVal];
              }
              csvData.push(rowObj);
            }
          }
          setHeaders(csvHeader);
          setCsvDataInfo(csvData);
          break;
        case 'organization':
          let orgCsvHeader = [
          	{ label: 'Organization Name', key: 'orgName' },
            { label: 'Description', key: 'aboutMe' },
            { label: 'Joining Date', key: 'createdAt' },
            { label: 'Current City', key: 'currentAddressCity' },
            { label: 'Current Country', key: 'currentAddressCountry' },
            { label: 'E-Mail Address', key: 'email' },
            { label: 'E-Mail Verified', key: 'isEmailVerify' },
            { label: 'Mobile Number Verified', key: 'isMobileVerify' },
            { label: 'Contact Number', key: 'mobile' },
            { label: 'Interest Category', key: 'interestCategory' },
            { label: 'Interest Value', key: 'interestValue' },
            { label: 'Secondary E-Mail', key: 'secondaryEmail' },
            { label: 'Status', key: 'status' },
            { label: 'User Name', key: 'userName' },
            { label: 'Legal Structure', key: 'legalStructure' },
            { label: 'Service Type', key: 'serviceList' },
            { label: 'Event Notifications', key: 'eventNotification' },
            { label: 'Email Notifications', key: 'emailNotification' },
            { label: 'Facts And Tips Visibility', key: 'factAndTips' },
            { label: 'Search', key: 'searchVisibility' },
            { label: 'Tagging', key: 'tagging' },
            { label: 'Address Visibility', key: 'whoCanSeeAddress'},
            { label: 'Current Event Visibility', key: 'whoCanSeeCurrentEvent'},
            { label: 'Current Target Visibility', key: 'whoCanSeeCurrentTarget'},
            { label: 'Email Visibility', key: 'whoCanSeeEmail'},
            { label: 'Following Organization Visibility', key: 'whoCanSeeFollowerOrganization'},
            { label: 'Interest Visibility', key: 'whoCanSeeInterest'},
            { label: 'Mobile Number Visibility', key: 'whoCanSeeMobile'},
            { label: 'Organization Address Visibility', key: 'whoCanSeeOrganizationAddress'},
            { label: 'Other Detail Visibility', key: 'whoCanSeeOtherDetail'},
            { label: 'Profile Visibility', key: 'whoCanSeeProfile'},
            { label: 'Post Sharing', key: 'whoCanSharePost'},
          ];
          let orgCsvData = [];
          let orgCsvDataObj = {};
          orgCsvDataObj['orgName'] = data?.name;
          orgCsvDataObj['aboutMe'] = data?.aboutMe;
          orgCsvDataObj['createdAt'] = new Date(data?.createdAt).toLocaleDateString();
          orgCsvDataObj['currentAddressCity'] = (data?.corporateAddress[0]?.city !='')?data?.corporateAddress[0]?.city:'N.A';
          orgCsvDataObj['currentAddressCountry'] = (data?.corporateAddress[0]?.country !='')?data?.corporateAddress[0]?.country:'N.A';
          orgCsvDataObj['email'] = data?.email;
          orgCsvDataObj['isEmailVerify'] = data?.isEmailVerify;
          orgCsvDataObj['isMobileVerify'] = data?.isMobileVerify;
          orgCsvDataObj['mobile'] = data?.mobile;
          orgCsvDataObj['secondaryEmail'] = (data?.secondaryEmail && data?.secondaryEmail !='')?data?.secondaryEmail:'N.A';
          orgCsvDataObj['status'] = data?.status;
          orgCsvDataObj['userName'] = data?.userName;
          
          //Getting the legalstructure name.
          orgCsvDataObj['legalStructure'] = getLegalStructure('legalName', data?.legalStructure[0],'');
          
          //Finding service types
          orgCsvDataObj['serviceList'] = getLegalStructure('serviceList', '', data?.serviceType);
          
          //Filtering and adding the first interest of an organization.
          let orgInterests = createInterestList(data?.spredeInterest);
              
          if(orgInterests != 'N.A'){
            //Picking only the first interest.
            let key = Object.keys(orgInterests[0]);
            orgCsvDataObj['interestCategory'] = key[0];
            orgCsvDataObj['interestValue'] = orgInterests[0][key];
          }
          else{
            orgCsvDataObj['interestCategory'] = 'N.A';
            orgCsvDataObj['interestValue'] = 'N.A';
          }

          //Adding settings of an organization.
          if(userSettings){
            orgCsvDataObj['emailNotification'] = userSettings?.emailNotifications;
            orgCsvDataObj['eventNotification'] = userSettings?.events;
            orgCsvDataObj['factAndTips'] = userSettings?.factAndTips;
            orgCsvDataObj['searchVisibility'] = userSettings?.search;
            orgCsvDataObj['tagging'] = userSettings?.tagging;
            orgCsvDataObj['whoCanSeeAddress'] = userSettings?.whoCanSeeAddress;
            orgCsvDataObj['whoCanSeeCurrentEvent'] = userSettings?.whoCanSeeCurrentEvent;
            orgCsvDataObj['whoCanSeeCurrentTarget'] = userSettings?.whoCanSeeCurrentTarget;
            orgCsvDataObj['whoCanSeeEmail'] = userSettings?.whoCanSeeEmail;
            orgCsvDataObj['whoCanSeeFollowerOrganization'] = userSettings?.whoCanSeeFollowerOrganization;
            orgCsvDataObj['whoCanSeeInterest'] = userSettings?.whoCanSeeInterest;
            orgCsvDataObj['whoCanSeeMobile'] = userSettings?.whoCanSeeMobile;
            orgCsvDataObj['whoCanSeeOrganizationAddress'] = userSettings?.whoCanSeeOrganizationAddress;
            orgCsvDataObj['whoCanSeeOtherDetail'] = userSettings?.whoCanSeeOtherDetail;
            orgCsvDataObj['whoCanSeeProfile'] = userSettings?.whoCanSeeProfile;
            orgCsvDataObj['whoCanSharePost'] = userSettings?.whoCanSharePost;
          }

          //Adding the first row of data to the org data array.
          orgCsvData.push(orgCsvDataObj);
          
          //Adding other pending interest list of an organization.
          if(orgInterests != 'N.A'){
            for(let i=1; i<orgInterests.length; i++){
              let rowObj = {};
              if(orgInterests[i]){
                let keyVal = Object.keys(orgInterests[i]);
                rowObj['interestCategory'] = keyVal[0];
                rowObj['interestValue'] = orgInterests[i][keyVal];
              }
              orgCsvData.push(rowObj);
            }
          }
          setHeaders(orgCsvHeader);
          setCsvDataInfo(orgCsvData);
          break;
      }
    }
    
    
  };

  const getLegalStructure = (requestType, legalStructureId, serviceListCodes) =>{
    switch(requestType){
      case 'legalName':
        let legalStructureName;  
        if(legalServiceType !== "" && legalServiceType !== undefined && legalStructureId){
          legalStructureName= legalServiceType?.legalStructure?.filter(function(item){
            if(item?._id === legalStructureId){
              return(item?.name);
            }
          });
          return(legalStructureName[0].name);
        }
        else{
          return('N.A');
        }
        break;
      case 'serviceList':
        if(serviceListCodes && legalServiceType && serviceListCodes.length !=0){
          let serviceList = [];
          for(let i=0; i<serviceListCodes.length; i++){
            for(let j=0; j<legalServiceType.serviceType.length; j++){
              if(serviceListCodes[i] == legalServiceType.serviceType[j]._id){
                serviceList.push(legalServiceType.serviceType[j].name);
              }
            }
          }
          return(serviceList.join(", "));
        }
        else{
          return('N.A');
        }
        break;
    }

  }

  const createInterestList = (userInterests) =>{
    let addedInterests = {};
    let addedInterestArray = [];
    if(userInterests && userInterests.length!=0 && interests && interests.length!=0){
      //Looping through interests which were in users profile.
      for(let i=0; i<userInterests.length; i++){
        //Looping through main interests category.
        for(let j=0; j<interests.length; j++) {
          //Finding the interest name which matches with the users interest.
          interests[j].option.find((option)=>{
            if(option._id == userInterests[i]){
              //If the main interest category is present and it's array is not empty.
              if(addedInterests[interests[j].spredeInterest] && addedInterests[interests[j].spredeInterest].length !=0){
                addedInterests[interests[j].spredeInterest].push(option.spredeInterestOptions);
              }
              //Adding the main category with a new array and pushing the first value.
              else{
                addedInterests[interests[j].spredeInterest] = [];  
                addedInterests[interests[j].spredeInterest].push(option.spredeInterestOptions);  
              }
            }
          })
        }
      }
      for (const key in addedInterests) {
        if(addedInterests[key].length !=0){
          addedInterests[key].map((item)=>{
            let obj = {};
            obj[key] = item;
            addedInterestArray.push(obj);
          })
        }
      }
      return addedInterestArray;
    }
    else{
      return('N.A');
    }
  }

  return (
    <CSVLink
      className="button primary popup-event-creation-trigger"
      headers={headers}
      filename={"my-profile"+ new Date().getTime()}
      data={csvDataInfo}
      onClick={handleClick}
    >
      Download Profile
    </CSVLink>
  );
};

export default DownloadProfile;
