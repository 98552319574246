import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "../commonComponents/popup";
import { Row, Col } from "react-bootstrap";
import { TextField } from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { getOrderIdService, LoadRazorpaySDK, orderSuccess } from "../services/payment.services";
import { useSelector } from "react-redux";
import { selectCurrentOrg, selectCurrentUser, selectCurrentUserRole } from "../Reducer/user/user.selector";

const PaymentPopUp = ({ open, handleClose, eventData, handlePaidEvents}) => {
  const { t } = useTranslation();

  const role = useSelector(selectCurrentUserRole);
  const CURRENTUSER = useSelector(selectCurrentUser);
  const CURRENTORG = useSelector(selectCurrentOrg);

  let current_user = {};
  if (role === 'organization') { current_user = CURRENTORG }
  else { current_user = CURRENTUSER }
  const displayRazorPay = async () => {
    let params = {
      eventId: eventData?._id
      // amount: eventData?.amount*100,
      // currency: eventData?.currencyCode
    }
    
    const respo = await getOrderIdService(params);
    const res = await LoadRazorpaySDK(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      toast.error("Razorpay SDK failed to load. Are you online?", {
        autoClose: 2500,
      });
      return;
    }

    if (respo !== false) {
      let options = {
        key: process.env.REACT_APP_RAZORPAY_SECRET,
        amount: respo?.data?.data?.amount,
        currency: respo?.data?.data?.currency,
        name: "sprede",
        description: "sprede",
        order_id: respo?.data?.data?.id,
        handler: async (response) => {
          let updateOrderPayload = {
            razorpay_order_id: respo?.data?.data?.id,
            razorpay_payment_id: response?.razorpay_payment_id,
            razorpay_signature: response?.razorpay_signature,
            eventId: eventData?._id,
            amount: respo?.data?.data?.amount,
            paymentStatus: true,
          }
          const responseOfUpdatedOrder = await orderSuccess(updateOrderPayload);
          if (responseOfUpdatedOrder?.status === 200) {
            handlePaidEvents();
          } else {
            toast.error("Something_went_wrong");
          }
        },
        modal: {
          ondismiss: async () => { },
        },
        prefill: {
          name: `${current_user?.firstName} ${current_user?.lastName}`,
          email: `${current_user?.email}`,
          contact: `${current_user?.mobile}`
        },
        retry: false,
        notes: {
          address: "sprede"
        },
        theme: {
          color: "#b2c955",
        },
      };
      let rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", async (responseOfError) => {
        console.log(responseOfError)
      });
      await rzp1.open();
    }
  }
 
  return (
    <Popup open={open} handleClose={handleClose} modalBodyClasses={"paymentModal"} heading={t('proceed_to_pay')} modalHeadingClass="paymentModalHeading" modalContentClasses="paymentModalContent">
      <Row>
        <Col lg={12}>
          <TextField type='text' id="region" label={t('Registration_Fee')}
            name={t('region')}
            value={eventData?.amount}
            readOnly
          />
        </Col>
        <Col lg={12}>
          <button className="button btn btn-sm ProceedPayBtn" onClick={(e) => displayRazorPay()}>{t('proceed')}</button>
        </Col>
      </Row>
    </Popup>
  )
}

export default PaymentPopUp;