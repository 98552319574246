import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { AvatarImg } from "../../../commonComponents/Images";
import BarsLoader from "../../../commonComponents/barsLoader";
import {
    getNotificationListService,
    markNotificationSeenService,
    getNotificationCountService
} from "../../../services/onBoarding.services";
import { getTimeGap, timeFormat, dateTimeEventFormat } from "../../../utils/helpers";
import { Row, Col } from "react-bootstrap";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { getImageUrl } from "../../../utils/helpers";
import { Link } from "react-router-dom";
import ReactPagination from "../../../commonComponents/reactPagination";
import { setNotificationCount } from "../../../Reducer/user/user.action";
import { useDispatch } from "react-redux";

const Notifications = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const limit = 20;
    const [notificationList, setNotificationList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, SetHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPageNo, setCurrentPageNo] = useState(0);
 
    useEffect(()=>{
        getNotificationList()
    }, [currentPageNo])
   
    const getNotificationList = () => {
        setIsLoading(true);
        getNotificationListService(currentPageNo, limit)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setNotificationList(data?.data?.data?.data);
                    let pageCount = (data?.data?.data?.totalCount) / limit;
                    setTotalPage(Math.ceil(pageCount));
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getNotificationCount = () => {
        getNotificationCountService()
            .then((data) => {
                if (data?.data?.status === 200) {
                    dispatch(setNotificationCount(data?.data?.data));
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const markNotificationRead = (id) => {
        let params = {
            id: id,
            seen: true
        }
        markNotificationSeenService(params)
            .then((data) => {
                getNotificationCount();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const navigateNotification = (e, type, noti) => {
        e.preventDefault()
        markNotificationRead(noti?._id);

        if (type === 'user') {
            showUser(noti?.senderId)
        }
        else {
          
            if (noti?.subject === 'userAcceptRequest' || noti?.subject === 'userSentRequest' ||  noti?.subject==='organizationFollow') {
                showUser(noti?.senderId)
            }
            else if (noti?.subject === 'organizationReferAndInvites') {
                let pathName = noti?.organizationInfo?.name.replaceAll(' ', '_');
                navigate(`/organization/${pathName}`, { state: { orgId: noti?.organizationInfo?._id } })
            }
            else if (noti?.subject === 'eventInvites' || noti?.subject === 'eventUpdate'|| noti?.subject==='eventValidatorRequestCancelled' || noti?.subject==='eventValidatorRequestAccept' || noti?.subject==='eventValidate' || noti?.subject==='markValidate') {
                navigate('/view-event', { state: { eventId: noti?.eventInfo?._id } })
            }
            else if (noti?.subject === 'targetInvites' || noti?.subject === 'targetUpdate') {
                navigate('/target', { state: { tab: 'invited' } })
            }
            else if (noti?.subject === 'userOrganizationCurrentWorkingStatus') {
                navigate('/followers', { state: { tab: 'empReq' } })
            }
            else if (noti?.subject === 'PostComment-user' || noti?.subject === 'PostCreate-user' || noti?.subject === 'PostComment-administrator' || noti?.subject === 'PostReplyComment-user' || noti?.subject === 'PostReplyComment-administrator' || noti?.subject === 'PostCreate-administrator' || noti?.subject === 'PostReaction-organization' || noti?.subject === 'PostReaction-user' || noti?.subject === 'PostReaction-administrator' || noti?.subject === 'PostShare-user' || noti?.subject === 'PostShare-organization' || noti?.subject === 'PostShare-administrator' || noti?.subject === 'PostEdit-user' || noti?.subject === 'PostEdit-organization' || noti?.subject === 'PostEdit-administrator') {
                navigate('/post', { state: { postId: noti?.additionalData?.postId } })
            }
            else if(noti?.subject === 'loginUnAuthorized')
            {
                navigate('/account-settings');
            }
            else if(noti?.subject==='eventValidatorValidateEvent'){
                navigate('/events-validate', { state: { tab: 'invited' }});
            }
        }
    }
    const showUser = (id) => {
        let encrptedId = window.btoa(id);
        navigate(`/profile/${encrptedId}`, { state: { userId: id } })
    }
    const handlePageClick = (data) => {
        let Page = data.selected
        setCurrentPageNo(Page)
    }
    return (
        <>
            {/* <Col md={12}><ProfileHeader/></Col> */}
            <SectionHeader pretitle="" title={t('notifications')} />

            <Row className="grid">
                <div className="grid-column">
                    <div className="widget-box">
                        {/* <h6 className="widget-box-title">{t('notifications')}</h6> */}
                        <div className="widget-box-content">

                            {/* <InfiniteScroll
                                pageStart={0}
                                loadMore={getNotificationList}
                                hasMore={hasMore}
                                loader={<BarsLoader key={0} />}
                                useWindow={false}
                            > */}
                                <Row className="grid">
                                    <Col md={12} className=" grid-column">
                                        {notificationList.length > 0 && <>
                                            {
                                                notificationList.map((item, index) => {
                                                    return (
                                                        <div className="widget-box" key={index}>
                                                            <div className="">
                                                                <Row className="align-items-center">
                                                                    <Col md={12} lg={12} className="d-flex align-items-center">
                                                                        <div className="hexagon-image-30-32">
                                                                            <Link className="customPolygon" to="#" onClick={(e) => navigateNotification(e, '', item)}>
                                                                                {(item.subject === 'userSentRequest' || item.subject === 'userAcceptRequest' || item.subject === 'userOrganizationCurrentWorkingStatus' || item.subject === 'organizationFollow' ) ?
                                                                                    <AvatarImgBox img={item?.userInfo?.profilePicture && item?.userInfo?.profilePicture !== '' ? getImageUrl(item?.userInfo?._id, "userProfile", item?.userInfo?.profilePicture) : AvatarImg} />
                                                                                    :
                                                                                    (item.subject === 'targetInvites' || item?.subject === 'targetUpdate' || item?.subject === 'targetInvite-user-accept' || item?.subject === 'targetInvite-user-cancel' || item?.subject === 'targetInvite-organization-accept' || item?.subject === 'targetInvite-organization-cancel') ?
                                                                                        <AvatarImgBox img={item?.targetInfo?.photo && item?.targetInfo?.photo !== '' ? getImageUrl(item?.targetInfo?._id, "target", item?.targetInfo?.photo) : AvatarImg} />
                                                                                        :
                                                                                    (item.subject === 'eventInvites' || item.subject === 'eventRemind' || item?.subject === 'eventUpdate' || item?.subject==='eventValidatorValidateEvent' ||  item.subject === 'eventInvite-user-accept' || item.subject === 'eventInvite-user-cancel' || item.subject === 'eventInvite-organization-accept' || item.subject === 'eventInvite-organization-cancel' || item.subject === 'eventValidate' || item?.subject==='markValidate') ?
                                                                                            <AvatarImgBox img={item?.eventInfo?.photo && item?.eventInfo?.photo !== '' ? getImageUrl(item?.eventInfo?._id, "event", item?.eventInfo?.photo) : AvatarImg} />
                                                                                            :
                                                                                    (item?.subject==='organizationroleupdate-administrator' || item?.subject==='organizationroleupdate-organization' || item?.subject === 'organizationcreate-administrator')?
                                                                                            <AvatarImgBox img={item?.organizationInfo ?.profilePicture && item?.organizationInfo?.profilePicture !== '' ? getImageUrl(item?.organizationInfo?._id, "userProfile", item?.organizationInfo?.profilePicture) : AvatarImg} />

                                                                                            :
                                                                                    (item.subject === 'organizationReferAndInvites' || item.subject === 'organizationEmployeeRequestApprove' || item.subject === 'organizationEmployeeRequestReject' || item.subject === 'organizationEmployeeRequestRemove') ?
                                                                                            <AvatarImgBox img={item?.organizationInfo?.profilePicture && item?.organizationInfo?.profilePicture!==''?getImageUrl(item?.organizationInfo?._id,"userProfile", item?.organizationInfo?.profilePicture):AvatarImg}/>
                                                                                            :
                                                                                    (item?.subject==='eventValidatorRequestCancelled' || item?.subject==='eventValidatorRequestAccept')?
                                                                                            <AvatarImgBox img={item?.eventInfo?.photo && item?.eventInfo?.photo!==''?getImageUrl(item?.eventInfo?._id,"event", item?.eventInfo?.photo):AvatarImg}/>
                                                                                    :
                                                                                    (item.subject === 'PostComment-user'  || item.subject === 'PostCreate-user' || item?.subject==='PostComment-administrator' || item?.subject==='PostReplyComment-user' || item?.subject==='PostReplyComment-administrator' || item?.subject==='PostCreate-administrator' || item?.subject==="PostTagged-administrator" || item?.subject==='PostReaction-user' || item?.subject==='PostReaction-administrator' || item?.subject==='PostShare-user' || item?.subject==='PostShare-administrator' || item?.subject==='PostEdit-user' || item?.subject==='PostEdit-administrator' || item?.subject==='PostHide-administrator' || item?.subject==='PostDelete-administrator' || item?.subject==='PostHide-user' || item?.subject==='PostDelete-user') ? 
                                                                                                <AvatarImgBox img={item?.userInfo?.profilePicture && item?.additionalData?.createdBy?.profilePicture !== '' ? getImageUrl(item?.userInfo?._id, "userProfile", item?.userInfo?.profilePicture) : AvatarImg} />
                                                                                                    :
                                                                                    (item.subject === 'PostComment-organization'|| item?.subject==='PostCreate-organization' || item.subject === 'PostReplyComment-organization'  || item.subject === 'PostReaction-organization' || item?.subject==='PostShare-organization' || item?.subject==='PostEdit-organization' || item?.subject==='PostDelete-organization' || item?.subject==='PostHide-organization') ? 
                                                                                                    <AvatarImgBox img={item?.userInfo?.profilePicture && item?.additionalData?.createdBy?.profilePicture !== '' ? getImageUrl(item?.userInfo?._id, "userProfile", item?.userInfo?.profilePicture) : AvatarImg} />
                                                                                                        :
                                                                                                    <AvatarImgBox img={AvatarImg} />

                                                                                }
                                                                            </Link>
                                                                        </div>
                                                                        <div className="notificationText" style={{ marginLeft: '12px', cursor: 'pointer' }} onClick={(e) => navigateNotification(e, '', item)}>
                                                                            <h5 className="">
                                                                                {(item.subject === 'userSentRequest' || item.subject === 'userAcceptRequest' || item.subject === 'userOrganizationCurrentWorkingStatus' || item.subject === 'organizationFollow') ?
                                                                                    <>{t(`notification.${item.subject}`).replace('${name}', `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`)}</>
                                                                                    :
                                                                                    (item.subject === 'targetInvites') ?
                                                                                    <>{item?.message}</>
                                                                                    :
                                                                                 (item?.subject === 'targetUpdate' || item?.subject==='targetDeleted-organization' || item?.subject==='targetDeleted-user') ?
                                                                                    <>{t(`notification.${item.subject}`).replace('${name}', `${item?.userInfo?.firstName || ''} ${item?.userInfo?.lastName || ''}`).replace('${target}',`${item?.additionalData?.targetMessage}`)}</>
                                                                                        :
                                                                                (item?.subject === 'targetInvite-user-accept' || item?.subject === 'targetInvite-user-cancel' || item?.subject === 'targetInvite-organization-accept' || item?.subject === 'targetInvite-organization-cancel') ?
                                                                                <>{t(`notification.${item.subject}`).replace('${name}',(!item?.organizationInfo?.name)?`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`:item?.organizationInfo?.name).replace('${target}', `${(item?.targetInfo?.title)?item?.targetInfo?.title:item?.additionalData.targetMessage}`)}</>
                                                                                    :
                                                                                (item?.subject==='organizationroleupdate-administrator' || item?.subject==='organizationroleupdate-organization' || item?.subject === 'organizationcreate-administrator')?
                                                                                <>{t(`notification.${item?.subject}`).replace('${name}',item?.organizationInfo?.name).replace("${role}",t(`roles.${item?.additionalData?.role}`))}</>
                                                                                :
                                                                                (item.subject === 'organizationReferAndInvites' || item.subject === 'organizationEmployeeRequestApprove' || item.subject === 'organizationEmployeeRequestReject' || item.subject === 'organizationEmployeeRequestRemove') ? 
                                                                                        <>{t(`notification.${item.subject}`).replace('${name}', `${item?.organizationInfo?.name}`)}</>
                                                                                    :
                                                                                (item.subject === 'eventInvites'  || item?.subject === 'eventUpdate' || item?.subject==='eventValidatorValidateEvent') ?
                                                                                            <>{t(`notification.${item.subject}`).replace('${name}', `${item?.additionalData?.eventMessage}`)} </>
                                                                                            :
                                                                                (item.subject === 'eventInvite-user-accept' || item.subject === 'eventInvite-user-cancel' || item.subject === 'eventInvite-organization-accept' || item.subject === 'eventInvite-organization-cancel') ?
                                                                                <>{t(`notification.${item.subject}`).replace('${name}',(!item?.organizationInfo?.name)?`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`:item?.organizationInfo?.name).replace('${event}', `${item?.additionalData?.eventMessage}`)}</>
                                                                                :
                                                                                (item.subject ==='eventRemind') ?
                                                                                            <>{t(`notification.${item.subject}`).replace('${event}', `${item?.additionalData?.eventMessage}`).replace('${time}',`${timeFormat(dateTimeEventFormat(item?.eventInfo?.startDate,item?.eventInfo?.startTime))}`)}</>
                                                                                            :
                                                                                (item.subject ==='eventRemindMonthly') ?
                                                                                            <>{t(`notification.${item.subject}`).replace('${event}', `${item?.additionalData?.eventMessage}`).replace('${time}',`${timeFormat(dateTimeEventFormat(item?.additionalData?.startDate,item?.additionalData?.startTime))}`)}</>
                                                                                            :
                                                                                
                                                                                (item.subject === 'PostComment-user' || item.subject === 'PostReplyComment-user' || item.subject === 'PostCreate-user'||item?.subject==='PostComment-administrator' || item?.subject==='PostReplyComment-administrator' || item.subject === 'PostCreate-administrator' || item?.subject==='PostReaction-user' || item?.subject==='PostReaction-user' || item?.subject==='PostReaction-administrator' || item?.subject==='PostShare-user'|| item?.subject==='PostShare-administrator' || item?.subject==='PostEdit-user' || item?.subject==='PostEdit-administrator'|| item?.subject==='PostHide-administrator'|| item?.subject==='PostDelete-administrator'|| item?.subject==='PostDelete-user' || item?.subject==='PostHide-user' || item?.subject==='PostFeature-user' ) ? 
                                                                                            <>{t(`notification.${item.subject}`).replace('${name}', `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`).replace('${post}', `${(item?.additionalData?.post) ? item?.additionalData?.post : item?.additionalData?.comment ? item?.additionalData?.comment : item?.additionalData?.content ? item?.additionalData?.content : ""}`?.substring(0, 100) + '..')}</>
                                                                                                    :
                                                                                ( item?.subject==='PostCreate-organization' || item?.subject==="PostComment-organization" || item?.subject==='PostReplyComment-organization' || item?.subject==='PostReaction-organization' || item?.subject==='PostShare-organization' || item?.subject==='PostEdit-organization' || item?.subject==='PostDelete-organization' || item?.subject==='PostHide-organization') ? 
                                                                                            <>{t(`notification.${item.subject}`).replace('${name}', (item?.organizationInfo?.name)).replace('${post}', `${(item?.additionalData?.post) ? item?.additionalData?.post : item?.additionalData?.comment ? item?.additionalData?.comment : item?.additionalData?.content ? item?.additionalData?.content : ""}`?.substring(0, 100) + '..')}</>
                                                                                                        :
                                                                                (item?.subject==='eventValidatorRequestCancelled' || item?.subject==='eventValidatorRequestAccept') ?
                                                                                           <>{t(`notification.${item?.subject}`).replace('${event}',(item?.additionalData?.eventMessage)).replace('${user}',`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`)}</>
                                                                                                        :
                                                                                (item.subject === 'eventValidate' || item?.subject==='markValidate' || item?.subject === 'markValidate-user' || item?.subject === 'markValidate-user') ?
                                                                                            <>{t(`notification.${item.subject}`).replace('${eventName}', `${item?.additionalData?.eventMessage}`).replace('${name}', `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`)}</>
                                                                                                        :
                                                                                (item.subject === 'organizationstatus-administrator' ) ?
                                                                                            <>{t(`notification.${item.subject}`)}{(item?.additionalData?.isVerified)?" 'Active'":" 'Inactive'"}</>
                                                                                            :
                                                                                (item.subject === 'paidEventSubmit') ?
                                                                                            <>{t(`notification.${item.subject}`).replace('${name}', `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`).replace('${event}', `${item?.additionalData?.eventMessage}`)}</>
                                                                                            :
                                                                                (item.subject === 'eventReminderMonthly') ?
                                                                                            <>{t(`notification.${item.subject}`).replace('${name}', `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`).replace('${event}', `${item?.additionalData?.eventMessage}`)}</>
                                                                                            :
                                                                                    <>{t(`notification.${item.subject}`)}</>
                                                                                }
                                                                                
                                                                            </h5>
                                                                            <p className="user-status-text small-space">{getTimeGap(item?.createdAt)} </p>
                                                                        </div>
                                                                    </Col>

                                                                </Row>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </>
                                        }
                                    </Col>
                                </Row>
                            {/* </InfiniteScroll> */}

                            {isLoading && <BarsLoader/>}

                            {!isLoading && notificationList.length === 0 &&
                                <>
                                    <Row className="notification">
                                        <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center">
                                            <span className="bold">
                                                {t('no_notifications')}
                                            </span>
                                        </Col>
                                    </Row>

                                </>
                            }

                            <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />
                        </div>
                    </div>
                </div>
            </Row>
        </>
    )
}

export default Notifications;
