import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import SectionHeader from '../../../commonDashboardComp/sectionHeader';
import ReviewList from '../../../commonDashboardComp/reviewList';
import { useTranslation } from 'react-i18next';
import { GetMyPostsService, GetPostsWithTargetEvenetsService, GetAllPostsService } from '../../../services/post.services';
import { GetTargetListDropDownService, GetEventByTargetService } from '../../../services/event.services';
import BarsLoader from '../../../commonComponents/barsLoader';
import { Autocomplete } from '@mui/material';
import ReviewBox from '../../../commonDashboardComp/reviewBox';
import { useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { selectCurrentUser, selectCurrentUserRole } from '../../../Reducer/user/user.selector';
import { getAllOrganizationListServices, getAllUserListServices, getAllUserListWithPosts } from '../../../services/adminManage.services';
import { loginUserType } from '../../../config/constants';

const PostReview = ({ }) => {
    const { t } = useTranslation();
    const role = useSelector(selectCurrentUserRole);
    const [posts, setPosts] = useState([]);

    const [targets, setTargets] = useState([]);
    const [events, setEvents] = useState([]);
    const [target, setTarget] = useState(null);
    const [event, setEvent] = useState(null);
    const [searchText, setSearchText] = useState('');

    //for selected ORG List
    const [orgList, setOrgList] = useState(null);
    const [orgList1, setOrgList1] = useState(null);
    const [orgTargets, setORGTargets] = useState([]);
    const [userList, setUserList] = useState([]);
    const limit = 20;
    const [loading, setLoading] = useState(false);
    const [page, setPageNo] = useState(0)
    const [hasMore, SetHasMore] = useState(true);
    const [offset, setOffset] = useState(0);
    const path_name = window.location.pathname;
    const observer = useRef();

    const lastElement = (node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((enteries) => {
            if (enteries[0].isIntersecting && hasMore) {
                setPageNo((prev) => prev + 1);
            }
        })
        if (node) {
            observer.current.observe(node);
        }
    }

    useEffect(() => {
        getTargetListing();
    }, [])

    useEffect(() => {
        if (target !== null) { getEventByTarget(); } else (setEvent([]))
    }, [target])

    useEffect(() => {
        setPosts([]);
        setPageNo(0)
        setOffset(0);
        getPostLists();
        getUserList();
    }, [target, event, orgList, orgList1])

    useEffect(() => {
        getPostLists();
        getUserList();
    }, [page, searchText])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // useEffect(()=>{
    //     loadUsers();
    // },[posts]);

    const loadUsersAndOrgs = (data) =>{
        if(data.length !=0){
            let userListArray = [];
            let orgListArray = [];
            for(let i=0;i<data.length; i++){
                if(data[i].firstName){
                    userListArray.push(data[i]);
                }
                else{
                    orgListArray.push(data[i]);
                }
            }
            setUserList([...userListArray]);
            setORGTargets([...orgListArray]);
        }
    }

    const getPostLists = () => {
        setLoading(true);
        // if(role === 'organization'){
        //     if(target?._id){
            let id = (orgList?._id)?orgList._id:(orgList1?._id)?orgList1?._id:'';
        GetPostsWithTargetEvenetsService(page, limit, target?._id, event?._id, id)
            .then((data) => {
                if (data?.data?.status === 200) {
                    if (data?.data?.data?.length === 0 || data?.data?.data?.length < limit) {
                        SetHasMore(false);
                    }
                    else{
                        SetHasMore(true);
                    }
                    let postList = (page === 0)? data?.data?.data : [...posts, ...data?.data?.data]
                    // let newArr = postList.reduce((acc, curr) => {
                    //     let fnd = acc.find((a) => a?._id === curr?._id);
                    //     if (!fnd) acc.push(curr);
                    //     return acc;
                    // }, []);
                    setPosts([...postList]);
                }

                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            })
        // }
        // else{
        //     GetMyPostsService(page, limit)
        //     .then((data)=>{
        //         if(data?.data?.status === 200){
        //         if(data?.data?.data?.length === 0 || data?.data?.data?.length < limit){
        //             SetHasMore(false);
        //         }
        //         let postList = [...posts, ...data?.data?.data]
        //         let newArr = postList.reduce((acc, curr) => {
        //                 let fnd = acc.find((a) => a?._id === curr?._id);
        //                 if (!fnd) acc.push(curr);
        //                 return acc;
        //             }, []);
        //         setPosts(newArr)
        //         }

        //         setLoading(false);
        //     })
        //     .catch((error)=>{
        //         console.log(error);
        //     })
        // }
        // }
        // else{
        //     GetAllPostsService(page, limit)
        //     .then((data)=>{
        //         if(data?.data?.status === 200){
        //         if(data?.data?.data?.length === 0 || data?.data?.data?.length < limit){
        //             SetHasMore(false);
        //         }
        //         let postList = [...posts, ...data?.data?.data]
        //             let newArr = postList.reduce((acc, curr) => {
        //                     let fnd = acc.find((a) => a?._id === curr?._id);
        //                     if (!fnd) acc.push(curr);
        //                     return acc;
        //                 }, []);
        //             setPosts(newArr)
        //         }

        //         setLoading(false);
        //     })
        //     .catch((error)=>{
        //         console.log(error);
        //     })
        // }
    }
    const getUserList = async () => {
        try {
            if (role === loginUserType.SUPER_ADMIN) {
                const response = await getAllUserListWithPosts();
                setLoading(false);
                // if (response?.data) {
                // setUserList(response.data?.data);
                // let pageCount = (response?.data?.totalCount) / (limit);
                // setTotalPage(Math.ceil(pageCount));

                if (response?.status == 200) {
                    // let resp = await getAllUserListServices(0, response?.data?.totalCount, searchText);
                    // if (resp?.status === 200) {
                    //     setUserList(resp.data?.data);
                    // }
                    // setUserList(response?.data);
                    loadUsersAndOrgs(response?.data);
                    
                }
                //         let newList = resp?.data?.data?.map((a, index) => {

                //             let obj = { firstName: a?.firstName, lastName: a?.lastName, status: a?.status, gender: a?.gender, email: a?.email }
                //             if (a?.dob) {
                //                 let selectedDate = moment(a?.dob);
                //                 let currentDate = selectedDate.format('YYYY-MM-DD');
                //                 obj['dob'] = currentDate;
                //             }
                //             if (a?.permanentAddress?.country || a?.permanentAddress?.country) {
                //                 obj['address'] = a?.permanentAddress?.country + "(" + a?.permanentAddress?.city + ")";
                //             }
                //             if (a?.pages) {
                //                 if (a?.pages[0]?.organizationId?.name || a?.pages[0]?.role) {
                //                     obj['orgName'] = a?.pages[0]?.organizationId?.name;
                //                     obj['orgRole'] = t(`roles.${a?.pages[0]?.role}`);
                //                 }
                //             }
                //             return obj;
                //         })
                //         setExportData(newList);

                //     }

                // }
                // setTotalCount(response?.data?.totalCount);
                // }

            }
        } catch (error) {
            setLoading(false);
        }
    }
    const getTargetListing = async () => {
        if (role === 'organization') {
            GetTargetListDropDownService("myTarget")
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setTargets(data?.data?.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        // else{
        //     GetTargetListDropDownService("allTarget")
        //     .then((data)=>{
        //         if(data?.data?.status === 200){
        //             setTargets(data?.data?.data);
        //         }
        //     })
        //     .catch((error)=>{
        //         console.log(error);
        //     })
        // }

    }

    const getEventByTarget = () => {
        if (target?._id) {
            GetEventByTargetService(target?._id)
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setEvents(data?.data?.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        else {
            setEvents([]);
        }
    }

    //ORG... List


    useEffect(() => {
        // getList();
        // getUserList();
    }, [])

    const getList = async () => {
        try {
            if (role === loginUserType.SUPER_ADMIN) {
                setLoading(true);
                const response = await getAllOrganizationListServices(page, limit, searchText);
                setLoading(false);
                if (response?.data) {
                    setORGTargets(response.data?.data);
                }
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const renderEmptyMessage = () => {
        return (
            <>
                {posts?.length === 0 && !loading &&
                    <>
                        <Row className="notification">
                            <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center">
                                <span className="bold">
                                    {t('no_posts')}
                                </span>
                            </Col>
                        </Row>
                    </>}
            </>
        )
    }
    return (
        <>
            <SectionHeader title={t('superAdmin.post_review_text')} />

            <div className="postReviewComp section-filters-bar v6 post_rev_search_area">
                <div className="col-lg-3 col-sm-6 col-12">
                    <form className="form eventSearch " onSubmit={(e)=>{e.preventDefault()}}>
                        <Autocomplete
                            
                            disablePortal
                            value={orgList}
                            options={orgTargets}
                            getOptionLabel={(option) => option?.name || ""}

                            onChange={(e, val) => { setOrgList(val); setOrgList1(null) }}
                            renderInput={(params) => <TextField {...params} label={t('errors.select_ORG')} />}
                        />
                    </form>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                    <form className="form eventSearch " onSubmit={(e)=>{e.preventDefault()}}>
                        <Autocomplete
                            disablePortal
                            value={orgList1}
                            options={userList}
                            getOptionLabel={(option) => option?.firstName + " " + option?.lastName || ""}

                            onChange={(e, val) => {setOrgList1(val); setOrgList(null); setPageNo(0)}}
                            renderInput={(params) => <TextField {...params} label={t('errors.select_User')} />}
                        />
                    </form>
                </div>
            </div>

            {role === 'organization' &&
                <div className="section-filters-bar v6 post_rev_search_area">
                    <div className="col-lg-3 col-sm-6 col-12">
                        <form className="form eventSearch ">
                            <Autocomplete
                                disablePortal
                                value={target}
                                options={targets}
                                getOptionLabel={(option) => option?.title || ""}

                                onChange={(e, val) => { setTarget(val); }}
                                renderInput={(params) => <TextField {...params} label={t('errors.target_required')} />}
                            />
                        </form>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                        <form className="form eventSearch post_rev_search_option">
                            <Autocomplete
                                disablePortal
                                value={event}
                                options={events}
                                getOptionLabel={(option) => option?.title || ""}

                                onChange={(e, val) => { setEvent(val); }}
                                renderInput={(params) => <TextField {...params} label={t('errors.event_required')} />}
                            />
                        </form>
                    </div>

                    <div className="section-filters-bar-actions">
                        {/* <Link className="button secondary popup-event-creation-trigger" to="#">Export All</Link> */}
                    </div>
                </div>
            }


            <Row className='grid postReviewBlock'>
                <Col md={12} className="grid-column">
                    {
                        posts.length > 0 && posts.map((item, index) => {
                            return (<Fragment key={index}>
                                {index + 1 === posts.length ?
                                    <div ref={lastElement} className="grid-column">
                                        <ReviewBox post={item} />
                                    </div> :
                                    <div className="grid-column">
                                        <ReviewBox post={item} />
                                    </div>}
                            </Fragment>)
                        })
                    }

                    {loading && <BarsLoader />}

                    {!loading && posts.length === 0 && renderEmptyMessage()}
                </Col>
            </Row>
        </>
    )

}
export default PostReview;