import React from "react";

const Video = ({src, showPoster=false}) => { 
    return (

        <>
            {!showPoster?
                <video width="320" height="240" controls src={src}>
                <source src={src} type="video/mp4"/>
                <source src={src} type="video/ogg"/>
                Your browser does not support the video tag.
            </video>
            :
            <video poster={true} src={src} alt="" />
            }    
        </>
)}
export default Video;