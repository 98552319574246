import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import './commonStyle.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { Switch } from "@mui/material";
import OurImpactBox from "./ourImpactBox";
import AboutOrg from "./aboutOrg";
import { getImageUrl, showSingleToastSuccess, showToastError } from "../utils/helpers";
import { TestimonialGoogle } from "../commonComponents/Images";
import { getOrgDetailsService } from "../services/orgAuth.services";
import { orgStatusUpdateService, deleteOrgAccountService } from "../services/adminManage.services";
import swal from "sweetalert";

const getOrganizationProfilePicture = (organizationId, fileName) => {
  return getImageUrl(organizationId, 'userProfile', fileName)
}

const OrgBox = ({ orgData }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(orgData)
  const { profilePicture = '', name = '', users = '', _id } = data;
  const [hide, setHide] = useState(false);
  const [show, setShow] = useState(true);
  const orgPic = '/Assets/org1@2x.png';

  const deleteOrgHandler = (e) => {
    e.preventDefault();
    swal({
      text: t('alerts.delete_org'),
      title: '',
      icon: "warning",
      buttons: {
        Yes: {
          text: t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    })
      .then(async (val) => {
        if (!val) return;

        try {
          deleteOrgAccountService(orgData?._id)
            .then((data) => {
              if (data?.data?.status === 200) {
                setShow(false)
                showSingleToastSuccess(t(`apiMessages.ORG_DELETE_SUCCESS`))
              }
              else {
                showToastError(t('errors.some_error'))
              }
            })
            .catch((error) => {
              showToastError(t('errors.some_error'))
            })
        }
        catch (error) {
          console.log(error)
        }
      });
  }

  const orgStatusHandler = (status) => {
    let params = {
      "organizationId": data?._id,
      "isActive": status,
      "purpose": false,

    }

    orgStatusUpdateService(params)
      .then(async (response) => {
        if (response?.data?.status === 200) {
          //await getOrgDetail()
          setData({ ...data, isActive: status });
          showSingleToastSuccess((status) ? t('apiMessages.ORG_ACTIVE_SUCCESS') : t('apiMessages.ORG_INACTIVE_SUCCESS'))
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }

  const getOrgDetail = async () => {
    getOrgDetailsService(data?._id)
      .then((data) => {
        if (data?.data?.status === 200) {
          setData(data?.data?.data);
        }
      })
  }

  const renderAvatar = () => {
    return (
      <img
        alt=""
        className="superOrgImg"
        src={getOrganizationProfilePicture(_id, profilePicture)}
        onError={(e) => {
          e.target.src = `${orgPic}` //replacement image imported above
        }} />
    )
  }



  return (
    <>
      {show && <div className="mt-4 parentBox" >
        <div className="widget-box superAdminManageOrgBox">
          <Row>
            <Col xl={1} md={2} sm={12} className="align-self-center">
              {renderAvatar()}
            </Col>
            <Col xl={4} md={3} sm={12} className="superManageOrgInfo">
              <div>
                <h4 className="superUserName">{name}</h4>
                <p className="superUserCount">{data?.totalFollowers} Followers</p>
              </div>
            </Col>
            <Col xl={5} md={4} sm={12} className='superOrgEnableToggle'>
              {t('superAdmin.manage_org_toggle')}
              <Switch checked={data?.isActive} onChange={(e) => orgStatusHandler(e.target.checked)} />
            </Col>
            <Col xl={2} md={3} xs={6} className='text-end align-self-center d-md-none'>
              <div className="superViewImpactMobile d-md-none">
                {!hide ? <> <button type='button' className='btn bg-white btn-sm orgBoxddUp' onClick={() => {
                  setHide(!hide)
                }}>{t('superAdmin.View_Impacts')}  <KeyboardArrowDownIcon /></button></>
                  : <><button type='button' className='btn bg-white btn-sm orgBoxddDown hideImpact' onClick={() => setHide(!hide)}>{t('superAdmin.Hide_Impact')} <KeyboardArrowUpIcon /> </button>  </>}
              </div>
            </Col>
            <Col xl={2} md={3} xs={6} className='text-end align-self-center manage_r_btn_area'>
              <button type='button' className='btn btn-danger' onClick={deleteOrgHandler}>{t('delete')}</button>
            </Col>
            {hide && <>
              <Row className='grid grid-9-3 mobile-prefer-content'>
                <Col md={9} className='grid-column'>
                  <OurImpactBox Id={data?._id} />

                </Col>
                <Col md={3} className='grid-column'>
                  <AboutOrg
                    CURRENTORG={data}
                  />
                </Col>
              </Row>
            </>}
          </Row>
          <div className="childbox d-md-block d-none">
            {!hide ? <> <button type='button' className='btn bg-white btn-sm orgBoxddUp' onClick={() => {
              setHide(!hide)
            }}>{t('superAdmin.View_Impacts')}  <KeyboardArrowDownIcon /></button></>
              : <><button type='button' className='btn bg-white btn-sm orgBoxddDown ' onClick={() => setHide(!hide)}>{t('superAdmin.Hide_Impact')} <KeyboardArrowUpIcon /> </button>  </>}
          </div>

          {/* <div className="childbox superViewImpactMobile d-md-none">
          {!hide ? <> <button type='button' className='btn bg-white btn-sm orgBoxddUp' onClick={() => {
            setHide(!hide)
          }}>{t('superAdmin.View_Impacts')}  <KeyboardArrowDownIcon /></button></>
            : <><button type='button' className='btn bg-white btn-sm orgBoxddDown hideImpact' onClick={() => setHide(!hide)}>{t('superAdmin.Hide_Impact')} <KeyboardArrowUpIcon /> </button>  </>}
        </div> */}
        </div>
      </div>}
    </>
  )
}

export default OrgBox;