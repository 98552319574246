import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import Popup from "../commonComponents/popup";
import UserSelector from "./userSelectBox";
import TabNavigation from "./tabNavigation.jsx";
import GreenCheckBox from "../commonComponents/greenCheckbox/greenCheckbox";
import { Row , Col} from "react-bootstrap";
import ReactPagination from "../commonComponents/reactPagination";
import { getVendorService } from "../services/vendor.services";
const VendorSearchPopup = ({popupOpen, handleClose, search, vendors, addVendorInList, removeVendorsInList, searchKey, setSearchKey}) => 
{
    const {t} = useTranslation();
    const [tab, setTab] = useState(1);


    const tabs = []

    const limit=30;
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(0);

    useEffect(() => {
        getVendorsList()
    }, [searchKey, page])

    const getVendorsList = () => {
        setLoading(true);
        getVendorService(page, limit, searchKey)
                .then((data)=>{
                    if(data?.data?.status === 200){
                        setList(data?.data?.data?.data);
                        let pageCount = (data?.data?.data?.totalCount) / limit;
                        setTotalPage(Math.ceil(pageCount));
                        setLoading(false);
                    }
                    setLoading(false);
                })
                .catch((error)=>{
                    console.log(error);
                })
    }

    const onChangeHandler = (e , id) => {
        if(e.target.checked){
            addVendorInList(id);
        }
        else{  removeVendorsInList(id)   }
    }

    const handlePageClick = (data) => {
        let page = data?.selected;
        setPage(page);
    }

     return (<Popup handleClose={handleClose} modalBodyClasses="inviteFriendsPopup" modalContentClasses="inviteFriendsPopupContent" open={popupOpen} className="targetPopup">
                <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab} value={searchKey} onChangeSearchText={setSearchKey}/>            
                <>
                        <Row className="ms-2">
                                {/* <div  className="ms-3 d-flex pt-3 pb-3">
                                    {role === 'organization' &&
                                        <GreenCheckBox checked={allEmpInvite} onChange={(e) => inviteAllEmpHandler(e)} label="Invite All Employees" />
                                    }
                                </div> */}
                            
                                <div className="scrollBar popup_friend_boxes">
                                        {list.length > 0 && list.map((item, index)=>{
                                            return (
                                                <UserSelector 
                                                key={index}
                                                user={item}
                                                checked={(vendors.includes(item._id)) ? true : false}
                                                onChange={onChangeHandler}
                                            />)
                                        })}
                                </div>
                            <ReactPagination pageCount={totalPage} onPageChange={handlePageClick}/>
                        </Row>
                    
                    {list.length === 0 && 
                        <>
                            <Col md={{span:6, offset:3}} className="user-status-title text-center">
                                <span className="bold">
                                    {t('friendsMsg.RECORD_NOT_FOUND')}
                                </span>
                            </Col>                            
                        </>}
                </>
                {
                    (list.length !=0)?
                    <button className="button primary inviteOkBtn" onClick={handleClose}> {t('proceed')} </button>
                    :
                    ''
                }
                
            </Popup>)


}

export default VendorSearchPopup;