import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/home/home.component";
import ResetPassword from "../pages/reset-password/resetPassword.component";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import SuperAdminSignin from "../components/super-admin/superAdmin-signin";
import BlogsList from "../components/homepage/blogsList";
import BlogDetail from "../components/homepage/blogDetail";
import UnSubscribeNewsLetterPage from "../pages/home/unSubscribeNewsLetterPage.component";
import FriendProfilePage from "../pages/Dashboard/friendProfilePage.component";
import OrganizationPage from "../pages/Dashboard/organizationPage.component";
import ViewPostPage from "../pages/Dashboard/viewPostPage.component";
import TermsConditions from "../components/homepage/termsConditions";
import PrivacyPolicy from "../components/homepage/privacyPolicy";
import PanchTatva from "../components/homepage/panchTatva";
import ValidatorTermsConditions from "../components/homepage/validatorTermsConditions";
import Faq from "../components/homepage/Faq";
import AboutUsPage from "../components/homepage/aboutUsPage";
import PaymentPolicy from "../components/homepage/paymentPolicy";

const LoggedOutRoutes = () => {
    return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Home />} />
            <Route path="/register" element={<Home />} />
            <Route path="/forgot-password" element={<Home />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/linkedin" element={<LinkedInCallback/>}/>
            <Route path="/superLogin" element={<SuperAdminSignin/>}/>
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="/blogs" element={<BlogsList/>} />
            <Route path="/termsandconditions" element={<TermsConditions/>} />
            <Route path="/faq" element={<Faq/>} />
            <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
            <Route path="/paymentpolicy" element={<PaymentPolicy/>} />
            <Route path="/aboutus" element={<AboutUsPage/>} />
            <Route path="/blogsDetail" element={<BlogDetail/>} />
            <Route path="/unsubscribe" element={<UnSubscribeNewsLetterPage/>}/>
            <Route exact path="/profile" element={<FriendProfilePage />}>
                     <Route path=":id" element={<FriendProfilePage />} />
            </Route>
            <Route exact path="/user" element={<FriendProfilePage />}>
            </Route>
            <Route path="/organization/:id" element={<OrganizationPage/>}/>
            <Route path="/post">
                <Route index={true} element={<Navigate to="/" replace />}/>
                <Route path=":postId" element={<ViewPostPage/>}/>
              </Route>
          </Routes>
        </BrowserRouter>
    );
}

export default LoggedOutRoutes;