import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { deletefaqService } from "../../../services/cms.services";
import { showSingleToastSuccess } from "../../../utils/helpers";
import { useState } from "react";
//Draft Js
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from 'draft-js';
import DOMPurify from 'dompurify';
//Draft Js

const FaqCmsBox = ({data, index, handleOpen, setEditedItem, type, setType}) => {
    const {t} = useTranslation();
    const [show, setShow] = useState(true);

    const onDelete = (e, id) => {
        e.preventDefault()
        swal({
            text: t('alerts.delete_faq'),
            title: '',
            icon: "warning",
            buttons: {
              Yes: {
                text:t('yes'),
                value: true,
              },
              No: {
                text: t('no'),
                value: false,
              },
            },
          })
          .then(async (val) => {
                if(!val) return;
                let category_id = id;
                deletefaqService(category_id)
                    .then((data) => {
                        if(data?.data?.status === 200) {
                            showSingleToastSuccess(t('apiMessages.FAQ_DELETED_SUCCESSFULLY'));
                            setShow(false);
                        }
                    })
                    .catch((error)=>{
                        console.log(error);
                    })

          })
    }

//For Draft JS
  const [faqData, setFaqData] = useState({});
  const [convertedData, setConvertedData] = useState();
  useEffect(()=>{
    if(data){
      setFaqData({...data});
    }

  },[data]);
  useEffect(()=>{
    if(Object.keys(faqData).length !==0){
      let htmlData = convertToHTML(convertFromRaw(faqData?.answer));
      setConvertedData(htmlData);
    }
      
  },[faqData]);
  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html?.substring(0, 250) + '...')
    }
  }
//For Draft JS

    return (
      <>
        {show &&
            <div
            className="widget-box area-widget col-lg-12 col-md-6 col-12 m-0 mb-2 my-md-2"
            key={index}
          >
            <div className="widget-box-content">
              <Row>
                <Col lg={12}>
                  <h5>{data?.categoryId?.name}</h5>
                </Col>
                <Col lg={12} className="mt-3">
                  <p className="px-0">{data?.question}</p>
                  {/* <p className="px-0">{data?.answer.substring(0, 100) + ' ...'}</p> */}
                  <div className='cmsOutput' dangerouslySetInnerHTML={createMarkup(convertedData)}></div>
                </Col>
                <Col lg={12}>
                  <div className="d-flex justify-content-end mt-4">
                    <button
                      onClick={(e) => {
                        handleOpen(e, 'edit');
                        setEditedItem({
                          id: data?._id,
                          category_id: data?.categoryId?._id,
                          ques: data?.question,
                          answer: data?.answer});
                      }}
                      className="button primary view-event-btn mx-2"
                    >
                      {t("button.edit")}
                    </button>
  
                    <button
                      onClick={(e) => onDelete(e, data?._id)}
                      className="button primary view-event-btn mx-2"
                    >
                      {t("button.delete")}
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        }
      </>
    );   
}

export default FaqCmsBox;