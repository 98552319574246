import ApiConfig from "../config/ApiConfig";
import instance, {instanceOrg} from "../AppInterceptor";
import { getObjectFromStore } from "../storage/Storage";

export const GetTargetListDropDownService = (type) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.getTargetDropdown+"?targetTypes="+type);
    }
    else{
        return instance.get(ApiConfig.getTargetDropdown+"?targetTypes="+type);
    }
}

export const RegisterValidatorByEmail = (email) =>{
    let url = ApiConfig.registerValidatorByEmail+'?email='+email;
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}

export const searchValidatorService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.searchValidator, payload);
    }
    else{
        return instance.post(ApiConfig.searchValidator, payload);
    }
}

export const addEventService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.eventApi, payload)
    }
    else{
        return instance.post(ApiConfig.eventApi, payload)
    }
}

export const updateEventService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.put(ApiConfig.eventApi, payload)
    }
    else{
        return instance.put(ApiConfig.eventApi, payload)
    }
}

export const updateEventPostIdService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.eventPostUpdateApi, payload)
    }
    else{
        return instance.post(ApiConfig.eventPostUpdateApi, payload)
    }
}

export const deleteEventService = (id) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.delete(ApiConfig.eventApi+'?eventId='+id)
    }
    else{
        return instance.delete(ApiConfig.eventApi+'?eventId='+id)
    }
}

export const getEventsByDatesService = (start, end, search, isPaid='') =>{
    let url = ApiConfig.eventApi+'?listType=eventBasedOnDates&startDate='+start+'&endDate='+end;
    if(search){
        url += '&searchText='+search;
    }
    if(isPaid !== ''  && isPaid !== 'all')
    {
        url += '&filter='+isPaid;
    }
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url)
    }
    else{
        return instance.get(url)
    }
}

export const getEventDetailService = (id) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.eventDetail+'?eventId='+id)
    }
    else{
        return instance.get(ApiConfig.eventDetail+'?eventId='+id)
    }
}

export const getEventDetailByDate=(date)=>{
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(ApiConfig.eventByDate+date);
    }
    else{
        return instance.get(ApiConfig.eventByDate+date);
    }
}

export const postValidatorInfo=(payload)=>{
    return instance.post(ApiConfig.postValidatorInfo,payload);
}

export const postBankDeatils=(payload)=>{
    return instance.post(ApiConfig.postBankData,payload);
}

export const getBankDetails=(id)=>{
    return instance.get(ApiConfig?.getBankData+id);
}

export const getEventDetailByDateService = (date)=>{
    let url;
    if(getObjectFromStore('role') === 'organization'){
        url = ApiConfig.eventDetailByDate+'?date='+date;
        return instanceOrg.get(url);
    }
    else if(getObjectFromStore('role')==='user'){
        url = ApiConfig.eventDetailByDate+'?date='+date;
        return instance.get(url);
    }
    else{
        url = ApiConfig.eventDetailByDateAdmin+'?date='+date;
        return instance.get(url);
    }
}

export const validatorOffForForm=()=>{
    return instance.get(ApiConfig.validatoroff);
}
export const GetEventByTargetService = (id) => {
    let url = ApiConfig.eventByTarget+'?targetId='+id;
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}
export const getEvidenceListById=(id)=>{    
    const url=ApiConfig.getEvidenceByDate+id;
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}
export const GetEventParticipantsService = (id, type, page, limit) => {
    let url = ApiConfig.getEventParticipants+'?eventId='+id+'&listType='+type+'&page='+page+'&limit='+limit;
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}

export const ManageEventParticipantsService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.manageEventParticipants, payload);
    }
    else{
        return instance.post(ApiConfig.manageEventParticipants, payload);
    }
}
export const InterestedForEventService=(evntId,action)=>{
    let url=ApiConfig?.interestedEventUser+"="+evntId+"&action="+action;
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}
export const InterestedListService=(eventId,page,limit,search)=>{
    let url=`${ApiConfig?.interestedList}=${eventId}&page=${page}&limit=${limit}`;
    if(search)url+='&searchText='+search;
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}
export const InvitedListService=(eventId,page,limit,search)=>{
    let url=`${ApiConfig?.invitedList}=${eventId}&page=${page}&limit=${limit}`;
    if(search)url+='&searchText='+search;
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}

export const validatorEventStatusService = (eventId, status) => {
    let url = `${ApiConfig.validatorEventStatus}?eventId=${eventId}&status=${status}`;
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}

export const markingEvent=(payload)=>{
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.eventMarking,payload);
    }
    else{
        return instance.post(ApiConfig.eventMarking,payload);
    }
}

export const remindEventService = (payload) => {
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.post(ApiConfig.remindEventApi,payload);
    }
    else{
        return instance.post(ApiConfig.remindEventApi,payload);
    }
}

export const removeRemindEventService = (eventId) => {
    let url = `${ApiConfig.remindEventApi}?eventId=${eventId}`;
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.delete(url);
    }
    else{
        return instance.delete(url);
    }
}

export const getValidatorBasicInfoService = (id) => {
    let url = `${ApiConfig.validatorBasicInfo}?validatorId=${id}`;
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}

export const getValidatorEventsService = (id, page, limit) => {
    let url = `${ApiConfig.validatorEvents}?validatorId=${id}&page=${page}&limit=${limit}`;
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}

export const getTargetInvitesService = (targetId, page, limit, searchText, listType, isOnlyIds) => {
    let url = `${ApiConfig.eventTargetInvites}?targetId=${targetId}&page=${page}&limit=${limit}&listType=${listType}`
    if(searchText){ url += '&searchText='+searchText }
    if(isOnlyIds){
        url += '&isOnlyIds=true'
    }

    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}

export const getAllTargetCountService = (filter) =>{
    let url= `${ApiConfig.targetCount}?filter=${filter}`
    if(getObjectFromStore('role') === 'organization'){
        return instanceOrg.get(url);
    }
    else{
        return instance.get(url);
    }
}

export const acceptRejectEventService = (payload) => {
    if (getObjectFromStore('role') === 'organization') {
        return instanceOrg.post(ApiConfig.eventStatusApi, payload);
    }
    else {
        return instance.post(ApiConfig.eventStatusApi, payload);
    }
}
