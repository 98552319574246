import ApiConfig from "../config/ApiConfig";
import { clearStorage, getObjectFromStore, setObjectInStore } from "../storage/Storage";
import { instance2 } from "../AppInterceptor";


const getRefreshToken=async(role = '')=>{
    //let role = await getObjectFromStore('role');
    let refreshToken = ''; 
    let refreshTokenUrl = (role === 'organization') ? ApiConfig.OrgrefreshToken : ApiConfig.refreshToken;
    if(role === 'organization')
    {
        refreshToken = await getObjectFromStore('org_refresh_token');
    }
    else{
        refreshToken = await getObjectFromStore('refresh_token');
    }
    
    try{
        if(refreshToken){
            instance2.get(refreshTokenUrl,{
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            })
            .then((data)=>{
                if (data.status === 200) {
                    if(role === 'organization')
                    {
                        setObjectInStore("orgToken", data?.data?.data?.accessToken);
                        setObjectInStore('org_refresh_token',data?.data?.data?.refreshToken);
                    }
                    else{
                        setObjectInStore("token", data?.data?.data?.accessToken);
                        setObjectInStore('refresh_token',data?.data?.data?.refreshToken);
                    }
                }
            }).catch((e)=>{
                if(e.response?.status == 401) {
                    clearStorage();
                    window.location.href = '/';
                }
            });
        }   
    }
    catch(error){console.log(error)}
    
}
export default getRefreshToken;