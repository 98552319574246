import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "../commonComponents/popup";
import { Row, Col } from "react-bootstrap";
import FormTextArea from "../commonComponents/formTextArea";
import GreenCheckBox from "../commonComponents/greenCheckbox/greenCheckbox";
import { TextField } from "@mui/material";
const SubmitReportPopup = ({ open, handleClose,eventUnitMentionAsComplete, handleReportSubmit, formsValues, errors, handleText, handleUnit, unitError, handleCheckbox , validatorRequired }) => {
    const { t } = useTranslation();
    
    const handleUnitCheck=(e)=>{
    }
    // const containSpecial = (val) => {
    //     let newStr = '';
    //     for (let i = 0; i < val?.length; i++) {
    //         if ((val[i] >= 'A' && val[i] <= 'Z') || (val[i] >= 'a' && val[i] <= 'z') || val[i] == ' '||val[i]=='.' || val[i]==='?' || val[i]==='!' || val[i]==='-' || val[i]==='/' || val[i]==='%' || val[i]==='*')
    //             newStr += val[i];
    //     }
    //     return val?.length !== newStr.length;
    // }
    //containSpecial(formsValues?.comment) ? t('errors.event_report_special_character')
    return (
        <Popup open={open} handleClose={handleClose} modalBodyClasses={"submitReportModal"} modalContentClasses={"submitReportModalContent"} modalHeadingClass={"submitReportHeading"} heading={t('submit_report')} >
            <Row>
                <Col lg={12}>
                    <FormTextArea id='comment' name='comment' value={formsValues?.comment} placeholder={t('submit_report_placeholder')} rows={2} onChange={(e) => { handleText(e) }} />
                    {errors &&
                        <div className="submitReportCommentError error-text">
                            {formsValues?.comment.length === 0 
                                ? t('errors.enter_event_report_comment')
                                : (formsValues?.comment.split(" ")?.length < 100 && formsValues?.comment.length > 1)
                                ? t('Minimum_100_words_allowed')
                                :formsValues?.comment.split(" ")?.length>500 ? t('Allowed_500_words_only')
                                 : ""}

                        </div>
                    }
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg={12} className="text-start mt-2">
                <TextField 
                                label='Unit Completed'
                                value={formsValues?.unitCompleted}
                                id='unitCompleted'
                                onChange={e =>{!isNaN(e.target.value) && handleUnit(e)}}
                                />
                               
                                {(errors && !formsValues?.unitCompleted)&&
                                <div className="submitReportUnitError error-text">{t("please_enter_unit")}</div>
                                }
                                {(errors && unitError==true)&&
                                <div className="submitReportUnitError error-text">{t("correct_unit_error")}</div>
                                }
                </Col>
            </Row>
            <Row>
                {validatorRequired &&
                <Col lg={12} className="submitReportCheckBox">
                    <GreenCheckBox onChange={(e) => handleCheckbox(e)} id="impact" name="impact" />
                    <span>{t('event_report_intend')}</span>
                </Col> }
            </Row>
            <div className="d-flex justify-content-center mt-5">
                <button className="submitBtn" onClick={(e) => { handleReportSubmit(e) }}>{t('form.submit')}</button>
            </div>
            
        </Popup>
    )
}

export default SubmitReportPopup;