import React from "react";
import UserLayout from "../../components/dashboard-layout";
import ManageUsers from "../../components/dashboard/superAdmin/manageUsers";
import ManageInvites from "../../components/dashboard/superAdmin/manageInvites";
const ManageInvitesPage = () => {
    return <UserLayout>
                <ManageInvites/>
            </UserLayout>;
}

export default ManageInvitesPage;