import React, {useEffect, useState} from "react";
import {GetSelectedPostByIdService } from "../services/post.services";
import PostBox from "./postBox";
import ProfileHeader from "./profileHeader";
import { useLocation } from "react-router-dom";
import OrgFewList from "./orgFewList";
import EventCalender from "./eventCalender";
import TodayEventList from "./todayEventsList";
import { Row, Col, RowProps } from "react-bootstrap";
import { useSelector } from "react-redux";
import TargetBox from "./targetBox";
import { getEventDetailByDateService } from "../services/event.services";
import { changeDateFormatYYYY, getImageUrl } from "../utils/helpers";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getObjectFromStore } from "../storage/Storage";
import { selectCurrentOrgRole, selectCurrentUserRole } from "../Reducer/user/user.selector";
import HomeBanner from "../components/homepage/banner";

const ShowPostLogout = ({selectedPost_id, setSelectedPost_id}) => {
    const { t } = useTranslation();
    const role = useSelector(selectCurrentUserRole);
    const [eventByDate, setEventsByDate] = useState({});
    const [date, setDate] = useState(changeDateFormatYYYY(new Date()));
    const [eventDate, setEventDate] = useState(new Date())
    const location = useLocation();
    const postId = {selectedPost_id};
    const [postData, setPostData] = useState({})
    const CURRENTORGROLE=useSelector(selectCurrentOrgRole);
    const navigate = useNavigate();

    useEffect(()=>{
        getPostData();
    }, [selectedPost_id])

    const getPostData = () => {
        if(selectedPost_id !==  null || selectedPost_id !== undefined){
            GetSelectedPostByIdService(selectedPost_id)
            .then((data)=>{
                if(data?.data?.status === 200){
                    setPostData(data?.data?.data[0]);
                }
            })
            .catch((error)=>{
                console.log(error);
            })
        }
    }

    return (
        <>
            <HomeBanner onlyMenu={true}/> 
            <div><ProfileHeader coverImg='postData.image' img='postData.profilePicture' detail={postData}/></div>
                <div>
                    <Row className="grid grid-3-6-3 mobile-prefer-content mt-3">
                        <Col lg={6} className="col-md-12 offset-md-3">
                            {postData?._id && <PostBox post={postData}/>}    
                        </Col>
                        <Col lg={3} className="col-md-12">
                        {getObjectFromStore('token')===null?<div>
                            <h4 className='text-center my-2'></h4>
                                <div className="form-item friendsLoginBtnBox mb-3 px-5 mt-5">
                                    <button className="login-account-btn friendLoginBtn1" onClick={()=>navigate('/login')}>
                                        {t('Login_to_Sprede')}
                                    </button>
                                </div>

                                <div className="orText">{t('OR')}</div>
                                    
                                <div className="form-item friendsLoginBtnBox mb-5 px-4">
                                    <button className="login-account-btn friendLoginBtn2" onClick={()=>{navigate('/register')}}>
                                        {t('Join_Sprede')}
                                    </button>
                                </div>
                        </div>:"" }   
                        </Col>
                    </Row>
                    
                </div>
        </> 
    )
};

export default ShowPostLogout;