import React ,{useEffect,useState} from "react";
import { useTranslation } from "react-i18next";
import '../dashboard.style.css';
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import ProfileCompletion from "../../../commonDashboardComp/profileCompletion";
import InterestLists from "../../../commonDashboardComp/interestsList";
import AboutMe from "../../../commonDashboardComp/aboutMe";
import PersonalInfo from "../../../commonDashboardComp/personalInfo";
import UserEmployementInfo from "../../../commonDashboardComp/userEmployementInfo";
import UserEducationInfo from "../../../commonDashboardComp/userEducationInfo";
import { Row, Col } from "react-bootstrap";
import { getUserDetailServices } from "../../../services/auth.services";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../Reducer/user/user.selector";

const MyProfile = () => {
    const detail=useSelector(selectCurrentUser);
    const current_user = useSelector(selectCurrentUser);
    const [loggedInUserDet, setLoggedInUserDet] = useState();
    const [isValidatorOff, setIsValidatorOff] = useState(false);
   
    useEffect(() => {
        getLoginUserDetails(loggedInUserDet);
    }, [])

   const getLoginUserDetails = async () => {
        const response = await getUserDetailServices();
        if (response?.data?.data) {
            const user = response?.data?.data;   
            setIsValidatorOff(response?.data?.data?.isValidator);
            setLoggedInUserDet(user);  
        }
    }
    return (
    <>
        <Row> <ProfileHeader coverImg='' img='' detail={detail} isValidatorOff={isValidatorOff}/> </Row>
        <div md={12}>
            <Row className="grid grid-3-6-3 mobile-prefer-content">
            <Col md={3} className="grid-column my-3">
                <AboutMe   userDet={loggedInUserDet} viewForMore={false}  me={true}
                />
                <PersonalInfo userDet={loggedInUserDet}
                />
            </Col>
        
            <Col md={6} className="grid-column my-3">
                <InterestLists userDet={loggedInUserDet}/>
                <UserEmployementInfo userDet={loggedInUserDet} />
                <UserEducationInfo userDet={loggedInUserDet}/>
            </Col>

            <Col md={3} className="grid-column my-3">
                <ProfileCompletion />
            </Col>
            </Row>
          
        </div>
    </>);
}

export default MyProfile;
