import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SectionHeader from '../../../commonDashboardComp/sectionHeader';
import { Box } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
// Draft Js imports
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, convertToRaw } from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
// Draft Js imports
const PaymentPolicyCms = ({formValues, setFormValues}) => {
  //For Draft JS
  const [editorState, setEditorState] = useState();
    const saveEditorContent =() =>{
        const content = editorState.getCurrentContent();
        setFormValues({
            ...formValues,
            paymentPolicy: {
            description: convertToRaw(content),
            title: 'N.A',
            },
        })
    }
    const [ediorData, setEditorData] = useState(EditorState.createEmpty());
    const [dataFetchCount, setDataFetchCount] = useState(0);
    useEffect(()=>{
        if(formValues?.paymentPolicy?.description){
            setEditorData(formValues?.paymentPolicy?.description);
            setDataFetchCount((prevState)=>{
                return prevState+1;
            });
        }
    },[formValues?.paymentPolicy?.description]);

    useEffect(()=>{
        if(formValues?.paymentPolicy?.description && dataFetchCount == 1){
            setEditorState(EditorState.createWithContent(convertFromRaw(formValues?.paymentPolicy?.description)));    
        }
    },[dataFetchCount])
  //For Draft JS
  const { t } = useTranslation();
  return (
    <>
      <SectionHeader pretitle="" title={t('paymentPolicy')} />
      <Box
        component={'form'}
        noValidate
        autoComplete="off"
        className="userdetail-form mobile_profile_margin p-0 aboutCmsComp"
      >
        <Row>
          <Col lg={12}>
            <Row className="grid">
              <div className="grid-column myProfileSettings">
                <div className="widget-box area-widget">
                  <div className="widget-box-content">
                    <Col md={12} className="cmsEditorContainer cms_about_textarea">
                      <Editor
                        onChange={saveEditorContent}
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                      />
                    </Col>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Box>
    </>
  );
};

export default PaymentPolicyCms;
