import React, { useState,useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setValidatorChange } from "../../../Reducer/user/user.action"; 
import {
    Box,
    TextField,
    Stack,
    Switch,
    Checkbox,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    Button,
    MenuItem,
    FormHelperText,
    Autocomplete
} from '@mui/material';
import { useSelector } from "react-redux";
import { selectCurrentUser,selectValidatorChange } from "../../../Reducer/user/user.selector";
import dayjs from 'dayjs';
import { getBankDetails, getEventDetailByDate, postBankDeatils, postValidatorInfo } from "../../../services/event.services";
import { useNavigate } from "react-router-dom";
import { targetTypeList,eventChargingTypeList,currencyName,bankName,IdTypes,timezone  } from "../../../config/constants"; 
import { showSingleToastSuccess } from "../../../utils/helpers"; 

const BankInfo = () => {
    const dispatch = useDispatch();
    const bankData_intial = {
        "bankName": "",
        "accountNo": "",
        "currency": "",
        "swift": "",
        "san": "",
        "ifsc": ""
    }
    const [bankDetails, setBankDeatils] = useState(bankData_intial);
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const [bankFldEnabled, SetBankFldEnabled] = useState(false);

    const { t } = useTranslation();
    const [editBankDet, setEditBankDet] = useState(false);
    const [bankError, setBankError] = useState(false);

    useEffect(() => {
        getBankDetails_validator();
    }, [])

    const getBankDetails_validator = () => {
        let userId = user?._id;
        getBankDetails(userId)
            .then((resp) => {
                if (resp?.status === 200) {
                    if (resp?.data?.data) {
                        // setBankDeatils(resp?.data?.data?.bankDetails);
                        if (resp?.data?.data?.bankDetails?.accountNo) {
                            setBankDeatils({ ...bankDetails, accountNo: `********${resp?.data?.data?.bankDetails?.accountNo}` })
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
   
    const bankDetailPost_data = () => {

        let bankFinalObj = {
            userType: 'user',
            userId: user?._id,
            walletInfo: bankDetails
        }
        postBankDeatils(bankFinalObj).
            then((resp) => {
                if (resp?.status === 200) {
                    showSingleToastSuccess(t('errors.Bank_Details_have_been_saved'));
                    getBankDetails();
                    setEditBankDet(false);
                }
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const handleBankDetail = (e) => {
        const objCopy = { ...bankDetails };
        if (e.target.id === 'accountNo') {
            if (!isNaN(e.target.value) && e.target.value.length < 12) {
                objCopy[e.target.id] = e.target.value;
            }
        }
        else {
            objCopy[e.target.id] = e.target.value;
        }
        setBankDeatils(objCopy);
    }

    const handleBankDetailDD = (e, id) => {
        const objCopy = { ...bankDetails };
        objCopy[id] =e.target.innerText;
        setBankDeatils(objCopy);
    }
 
    const editBankDetailsMode = (e) => {
        e.preventDefault();
        setEditBankDet(true);
    }

    const saveBankDetailsMode = (e) => {
        e.preventDefault();
        if (!bankDetails?.bankName || !bankDetails?.currency || !ISifscValid(bankDetails?.swift) || !ISifscValid(bankDetails?.ifsc) || !ISifscValid(bankDetails?.san)) {
            setBankError(true);
            return;
        }
        bankDetailPost_data();
    }
    const ISifscValid = (ifsc) => {
        let cnt = 0;
        for (let i = 0; i < ifsc.length; i++) {
            let char = ifsc[i];
            if ((char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z') || (char >= 0 && char <= 9)) {
                cnt += 1;
            }
        }
        if (cnt === ifsc?.length && ifsc?.length === 11) {
            return true;
        }
        return false;

    }

    return (
        <Row className="grid">
            <div className="grid-column myProfileSettings">
                <div className="widget-box pb-0">
                    <div className="widget-box-content">
                            <div className='my-3'>
                                <h6 className='bank_text_left'>
                                    {t('validators_info.bank_detail')}
                                </h6>
                                <div>
                                    <div className='edit-Assign-left'>{editBankDet ? <span title={t('Save_Bank_Info')} onClick={(e) => { saveBankDetailsMode(e) }}> <img src='/Assets/Save.png' /></span> : <span title={t('Edit_Add_Bank_Info')} onClick={(e) => editBankDetailsMode(e)}> <img src='/Assets/Edit.png' /></span>} </div>
                                    <Row className="bank_input_area">
                                        <Col lg={4} className="col-md-4 popup_select bankInputFields">
                                            {/* <FormControl fullWidth>
                                                <InputLabel id="bankName-select-label">{t('validators_info.Bank_Name')}</InputLabel>
                                                <Select
                                                    labelId="bankName-select-label"
                                                    id="bankName"
                                                    value={bankDetails?.bankName}
                                                    disabled={!editBankDet}
                                                    label={t('validators_info.Bank_Name')}
                                                    onChange={(e) => handleBankDetailDD(e, 'bankName')}
                                                >
                                                    {bankName.map((item, idx) => {
                                                        return (
                                                            <MenuItem key={idx} value={item}>{item}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl> */}
                                              <FormControl fullWidth>
                                                <Autocomplete
                                                    disablePortal
                                                    disabled={!editBankDet}
                                                    value={bankDetails?.bankName}
                                                    options={bankName}
                                                    getOptionLabel={(option) => option}
                                                    onChange={(e) => handleBankDetailDD(e, 'bankName')}
                                                    renderInput={(params) => <TextField {...params} label={t('validators_info.Bank_Name')} />}
                                                />
                                            </FormControl>
                                            {bankError &&
                                                <div className="error-text">
                                                    {!bankDetails?.bankName ? t('validators_info.Please_Select_Bank_Name') : ""}
                                                </div>
                                            }

                                        </Col>
                                        <Col lg={4} className="col-md-4 col-sm-6 popup_input bankInputFields bankField1">
                                            <TextField type='text' disabled={!editBankDet} value={bankDetails?.accountNo} id="accountNo" label={t('validators_info.Account_No')} onChange={(e) => handleBankDetail(e)} />
                                            {bankError &&
                                                <div className="error-text">
                                                    {!bankDetails?.accountNo ? t('validators_info.Please_Enter_Account_Number') : ""}
                                                </div>
                                            }
                                        </Col>
                                        <Col lg={4} className="col-md-4 col-sm-6 popup_select bankInputFields bankField1">
                                            {/* <FormControl fullWidth>
                                            <InputLabel id="currency-select-label">{t('superAdmin.Currency')}</InputLabel>
                                            <Select
                                                labelId="currency-select-label"
                                                id="currency"
                                                value={bankDetails?.currency}
                                                label={t('superAdmin.Currency')}
                                                disabled={!editBankDet}
                                                onChange={(e) => handleBankDetailDD(e, 'currency')}
                                            >
                                                {currencyName.map((item, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={item}>{item}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                           </FormControl> */}
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    disablePortal
                                                    disabled={!editBankDet}
                                                    value={bankDetails?.currency}
                                                    options={currencyName}
                                                    getOptionLabel={(option) => option}
                                                    onChange={(e) => handleBankDetailDD(e, 'currency')}
                                                    renderInput={(params) => <TextField {...params} label={t('superAdmin.Currency')} />}
                                                />
                                            </FormControl>
                                            {bankError &&
                                                <div className="error-text">
                                                    {!bankDetails?.currency ? t('validators_info.Please_Select_currency') : ""}
                                                </div>
                                            }
                                        </Col>
                                        <Col lg={2} className="col-md-4 col-sm-6 popup_input bankInputFields bankField1">
                                            <TextField type='text' disabled={!editBankDet} value={bankDetails?.swift} id="swift" label={t('validators_info.SWIFT')} onChange={(e) => handleBankDetail(e)} />
                                            {bankError &&
                                                <div className="error-text">
                                                    {!bankDetails?.swift ? t('validators_info.Please_Enter_Swift') : !ISifscValid(bankDetails?.swift) ? t('validators_info.Should_have_11_Digit_or_Character') : ""}
                                                </div>
                                            }
                                        </Col>
                                        <Col lg={2} className="col-md-4 col-sm-6 popup_input bankInputFields bankField1">
                                            <TextField type='text' disabled={!editBankDet} value={bankDetails?.san} id="san" label={t('SAN')} onChange={(e) => handleBankDetail(e)} />
                                            {bankError &&
                                                <div className="error-text">
                                                    {!bankDetails?.san ? t('validators_info.Please_Enter_SAN') : !ISifscValid(bankDetails?.san) ? t('validators_info.Should_have_11_Digit_or_Character') : ""}
                                                </div>
                                            }
                                        </Col>
                                        <Col lg={2} className="col-md-4 col-sm-6 popup_input bankInputFields bankField1">
                                            <TextField type='text' disabled={!editBankDet} value={bankDetails?.ifsc} id="ifsc" label={t('IFSC')} onChange={(e) => handleBankDetail(e)} />
                                            {bankError &&
                                                <div className="error-text">
                                                    {!bankDetails?.ifsc ? t('validators_info.Please_Enter_IFSC') : !ISifscValid(bankDetails?.ifsc) ? t('validators_info.Should_have_11_Digit_or_Character') : ""}
                                                </div>
                                            }
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </Row>
    )
}
export default BankInfo;