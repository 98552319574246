import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BarsLoader from "../commonComponents/barsLoader";
import TabNavigation from "./tabNavigation";
import { selectCurrentUserRole } from "../Reducer/user/user.selector";
import { useSelector } from "react-redux";
import { loginUserType } from "../config/constants";
import OurImpactBox from "./ourImpactBox";
import ParticipationImpact from "./ParticipationImpact";

const ImpactSection = () =>{
    const [tab, setTab] = useState(1);
    const tabs = ['Our Impact', 'Participation Impact'];
    const role = useSelector(selectCurrentUserRole);
    return(<>
    <div id="ImpactSection">
        <h1 className="widget-box-title fs-4">Impacts</h1>
        {
            (role === loginUserType.USER || role === loginUserType.ORGANIZATION)?
            <>
                <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab} showInput={false} />
                {tab === 1 && <OurImpactBox/>}
                {tab ===2 && <ParticipationImpact/>}
            </>
            :<>
                <OurImpactBox/>
            </>
        }
        
    </div>
    </>);
}

export default ImpactSection;