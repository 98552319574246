import React, { useEffect } from 'react';
import { Row,Col } from 'react-bootstrap';
import ProfileHeader from '../../../commonDashboardComp/profileHeader';
import OurImpactBox from '../../../commonDashboardComp/ourImpactBox';
import ManageOrgFewList from '../../../commonDashboardComp/manageOrgFewList'; 

const SuperAdminImpact=({})=>{
 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
return (
    <>
      <Row> <ProfileHeader coverImg='' img=''/> </Row>
          <Row className='grid grid-9-3 mobile-prefer-content'>
            <Col md={8} lg={9} className='grid-column my-2'>
            <OurImpactBox 
              overAllImpacts={true}
              circularProgressbarLarge={"progressBarLarge"}
            />
            </Col>
            <Col md={4} lg={3} className='grid-column my-2'>
           <ManageOrgFewList/>

            </Col>
             
          </Row>

    </>
)

}
export default SuperAdminImpact;