import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { validatorEventStatusService } from "../../../services/event.services";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import swal from "sweetalert";
import EventsListing from "../../../commonDashboardComp/eventsListing";
import TabNavigation from "../../../commonDashboardComp/tabNavigation";
import { useLocation } from "react-router-dom";
const EventsValidate = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const [tab, setTab] = useState((location?.state?.tab)? 4 :1);
  const tabs = [t('event.runningEventsForValidation'), t('event.upcomingEventsForValidation'), t('event.validatedEvents'), t('event.eventValidationRequest')]

  const [reload, setReload] = useState(true);


  const acceptValidationRequest = (e, eventid) => {
    e.preventDefault();

    swal({
      text: t('alerts.accept_validate_event'),
      title: '',
      icon: "warning",
      buttons: {
        Yes: {
          text:t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    })
    .then(async (val) => {
      if(!val) return;
      try{
          let response = await validatorEventStatusService(eventid, 'accepted');
          if(response?.data?.status === 200){
            // showToastSuccess(t('apiMessages.EVENT_VALIDATE_ACCEPT_SUCCESS'))
            showSingleToastSuccess(t('apiMessages.EVENT_VALIDATE_ACCEPT_SUCCESS'))
            setReload(!reload);
          }
          else{
            showToastError(t('errors.some_error'))
          }
      }
      catch(error){
          console.log(error)
      }
  });
  }

  const rejectValidationRequest = (e, eventid) => {
    e.preventDefault();

    swal({
      text: t('alerts.reject_validate_event'),
      title: '',
      icon: "warning",
      buttons: {
        Yes: {
          text:t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    })
    .then(async (val) => {
      if(!val) return;
      try{
          let response = await validatorEventStatusService(eventid, 'cancelled');
          if(response?.data?.status === 200){
            // showToastSuccess(t('apiMessages.EVENT_VALIDATE_ACCEPT_SUCCESS'))
            showSingleToastSuccess(t('apiMessages.EVENT_VALIDATE_REJECTED_SUCCESS'))
            setReload(!reload);
          }
          else{
            showToastError(t('errors.some_error'))
          }
      }
      catch(error){
          console.log(error)
      }
  });
  }

  return (<>
    <SectionHeader pretitle="" title={t('eventValidator')}  />     

    <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab} showInput={false} />

    <EventsListing 
      tab={tab}
      search="validator"
      acceptValidationRequest={acceptValidationRequest}
      rejectValidationRequest={rejectValidationRequest}
      reload={reload}
    />
  </>)
}

export default EventsValidate;