import React, { useEffect, useState, useRef } from "react";
import { AvatarImg } from "../commonComponents/Images";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAllFriendsAddedList, postDeleteOrCancelRequest } from "../services/friends.service";
import { getImageUrl, showSingleToastSuccess, showToastError, showToastSuccess } from "../utils/helpers";
import AvatarImgBox from "../commonComponents/AvatarImgBox/avatarImgBox";
import { InterestedListService, InvitedListService, ManageEventParticipantsService } from "../services/event.services";
import SearchBox from "./searchBox";

const InvitedPeople = ({ heading, eventId }) => {
    const observer = useRef();
    const { t } = useTranslation();
    const n = 4;
    const [listOfInvited, setListOfInvited] = useState([]);
    const [totalPeople, setTotalPeople] = useState(0);
    let defaultUserImage = '/Assets/Img.png';
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [PageNo, setPageNo] = useState(0);
    const [searchRes, setSearchRes] = useState('');
    const limit = 20;

    const lastElement = (node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((enteries) => {
            if (enteries[0].isIntersecting && hasMore) {
                setPageNo((prev) => prev + 1);
            }
        })
        if (node) {
            observer.current.observe(node);
        }
    }
    useEffect(() => {
        //setListOfInvited([]);
        setPageNo(0);
    }, [searchRes])

    useEffect(() => {
        getInvitesPeople(PageNo);
    }, [searchRes, PageNo])

    const getInvitesPeople = async (currentPage) => {
       // setLoading(true);
        const response = await InvitedListService(eventId, currentPage, limit, searchRes)

        if (response?.data?.data) {
            const user = response?.data?.data?.data;
            const user2 = user.map(a => ({ ...a, inviteIS: false }));
            let newInvited = [];
            if(currentPage === 0)
            {
                newInvited = [...user2];
            }
            else{
                newInvited = [...listOfInvited, ...user2];
            }
            
            let newArr = newInvited.reduce((acc, curr) => {
                let fnd = acc.find((a) => a._id === curr?._id);
                if (!fnd) acc.push(curr);
                return acc;
            }, []);
            newArr = newArr?.filter(a => !a?.inviteIS);
            setHasMore(response?.data?.data?.data?.length > 0);
            setListOfInvited(newArr);
            //setTotalFriends(response?.data?.data?.totalCount);

        }
        setLoading(false);
    }
    const onKeyDownSearchText = (search) => {
        setSearchRes(search);
    }
    const searchUpdate = search => {
        setSearchRes(search);
    };

    const sendInvite = (e, userDet, index1) => {
        e.preventDefault();
        let params = {
            eventId: eventId,
            userId: userDet?._id,
            action: 'request'
        }
        ManageEventParticipantsService(params)
            .then((data) => {
                if (data?.data?.status === 200) {
                    showSingleToastSuccess(t(`apiMessages.INVITE_SENT_SUCCESSFULLY`));
                    const nextList = [...listOfInvited];
                    let index = nextList?.findIndex(a => a._id === userDet?._id);
                    if (index >= 0) {
                        nextList[index]['inviteIS'] = true;
                        setListOfInvited(nextList);
                        let pageNo1 = Math.floor(index1 / limit);
                        setPageNo(pageNo1);
                        getInvitesPeople(pageNo1)
                        listOfInvited?.splice(index, 1);
                        //showSingleToastSuccess(t(`apiMessages.INVITE_SENT_SUCCESSFULLY`));

                    }
                }
                else {
                    showToastError(t(`errors.some_error`));
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    return (

        <div className="widget-box interested_people_area">

            <p className="widget-box-title widget-box-content fewFriendsHeading">{heading ? heading : t('friends')} <span className='friendsCountText'></span></p>
            <div className="invite_search_icon">
                <SearchBox onChangeSearchText={searchUpdate} value={searchRes} onKeyUpSearch={onKeyDownSearchText} />
            </div>
            {listOfInvited?.length > 0 ? <>
                <div className="widget-box-content Interestedscroller ">
                    <div className="user-status-list interestedPeople">

                        {listOfInvited?.map((item, index) => {
                            if (index + 1 === listOfInvited?.length) {
                                return <div className="d-flex align-items-center" key={index} ref={lastElement}>
                                    <div className="invite_people_img">
                                        <AvatarImgBox
                                            img={(item?.profilePicture !== "") ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : defaultUserImage}
                                        />

                                    </div>
                                    <div className="invite_people_text">
                                        <h6 to="/friends">{(`${item?.firstName} ${item?.lastName}`) || ''}</h6>
                                        <p>{item?.totalMutualFriend} {t('friends_in_common')}</p>
                                    </div>
                                    <div className="action-request-list mail_icons_box">
                                        <div className="action-request accept">
                                            {/* <InsertEmoticonIcon className="action-request-icon" /> */}
                                            <button className="box-btn" onClick={(e) => sendInvite(e, item, index)}> <img src="/Assets/invite.png" /></button>
                                        </div>
                                    </div>
                                </div>
                            }
                            else {
                                return <div className="d-flex align-items-center" key={index}>
                                    <div className="invite_people_img">
                                        <AvatarImgBox img={item?.profilePicture !== "" ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : defaultUserImage} />
                                    </div>
                                    <div className="invite_people_text">
                                        <h6 to="/friends">{(`${item?.firstName} ${item?.lastName}`) || ''}</h6>
                                        <p>{item?.totalMutualFriend} {t('friends_in_common')}</p>
                                    </div>
                                    <div className="action-request-list mail_icons_box">
                                        <div className="action-request accept">
                                            {/* <InsertEmoticonIcon className="action-request-icon" /> */}
                                            <button className="box-btn" onClick={(e) => sendInvite(e, item, index)}> <img src="/Assets/invite.png" /></button>
                                        </div>
                                    </div>
                                </div>
                            }
                        }
                        )}


                    </div>
                </div>
            </> : <h6 className="my-2">
                {t('no_users')}
            </h6>}


        </div>

    )
}

export default InvitedPeople;