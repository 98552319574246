import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AllUserSearchList from "./allUserSearchList";
import GlobalSideBar from "./globalSideBar";



const AllGlobalUserSearch = ({ usersList, isLoading, totalPageForUser, handlePageClick }) => {

    const { t } = useTranslation();
    return (<Row className="grid grid-3-6-3 mobile-prefer-content">
        <Col md={3} className="grid-column">
            <GlobalSideBar />
        </Col>

        <Col md={9} className="grid-column myProfileSettings">
            <div>
                <h5 style={{ marginBottom: 10, marginTop: 10 }}>{t('roles.user')}</h5>
                <AllUserSearchList usersList={usersList} isLoading={isLoading} totalPageForUser={totalPageForUser} handlePageClick={handlePageClick} />
            </div>
        </Col>
    </Row>)
}

export default AllGlobalUserSearch;