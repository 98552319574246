import moment from "moment";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import StepperSidebar from "../commonComponents/stepperSidebar";
const UserEducationInfo = ({ userDet }) => {
  const {t} = useTranslation();
  
  const [steps1, setSteps] = useState(null);
  useEffect(() => {
    updateArray();
  }, [userDet]);

  const updateArray = () => {
    try {
      if (userDet?.education && userDet?.education?.length > 0) {
        let neweduList = userDet?.education?.map((item, index) => {
          let eduObject = {};
          let label = ((item?.course) ? `${item?.course} ` : '') + t('from') + ((item?.university) ? ` ${item?.course}` : '')
          eduObject['label'] = label;
          let startDate = item?.startYear?(moment(item?.startYear).format('MMM YYYY')):''
          let endDate =item?.endYear?(moment(item?.endYear).format('MMM YYYY')):'';
          startDate=startDate?startDate:t('not_mentioned');
          endDate=endDate?endDate:t('not_mentioned');
          eduObject['desc'] = startDate+'-'+endDate;
          return (eduObject);
        });
        setSteps(neweduList);
      }

    }
    catch (error) { console.log(error) }

  }


  return (
    <div className="widget-box">
      <p className="widget-box-title">{t('form.label_education')}</p>

      <div className="widget-box-content">
        {steps1 === null ? <h6>{t('no_education_detail')}
          
        </h6> :
          <StepperSidebar className="dashboardStepper" steps={steps1} />
        }

      </div>
    </div>
  )
}

export default UserEducationInfo;