import { useTranslation } from 'react-i18next'
import Popup from './popup'
const DialogBox= ({ showDialog,  cancelNavigation,  confirmNavigation}) => {
  const {t} = useTranslation();
  return (
    <Popup open={showDialog} handleClose={cancelNavigation} modalHeadingClass={"whoCanCommentHeading"}>
        <b>{t('alerts.unsaved_changes')}</b>
        <hr/>
        <div className="whoCommentBtnBox">
            <button className="commentBtn whoCommentCancelBtn"  onClick={cancelNavigation}> {t('no')}</button>
            <button className="commentBtn whoCommentSubmitBtn"  onClick={confirmNavigation}> {t('yes')}</button>
        </div>
    </Popup>
  )
}
export default DialogBox