import React , {useState} from "react";
import { useTranslation } from "react-i18next";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import { getEventsByDatesService } from "../services/event.services";

const EventCalender = ({setDate, date}) => {
    const {t} = useTranslation();
    const [start, setStart] = useState(moment().startOf('month').startOf('week').format('YYYY-MM-DD'));
    const [end, setEnd] = useState(moment().endOf('month').endOf('week').format('YYYY-MM-DD'));
    const [dateArray, setDateArray] = useState([]);

    

    const getEvents = () => {
      getEventsByDatesService(start, end)
      .then((data) => {
        if (data?.data?.status === 200) {
          let searchedEvents = [];
          if (data?.data?.data?.length > 0) {
            data?.data?.data?.map((item) => {
              let eventDates = item?.eventDates;
              eventDates.map((eventDateItem) => {
                searchedEvents.push({
                  date: eventDateItem,
                  colorName : 'event-available'
                })
              })

            })
          }
          setDateArray(searchedEvents);
        }
      })
      .catch((error) => {
        console.log(error)
      })
    }

    useState(()=>{
      getEvents()
    },[start, end])

    const setClass = (date) => {
            const dateobj =
            dateArray.find((x) => {
            return (
                date.getDay() === new Date(x.date).getDay() &&
                date.getMonth() === new Date(x.date).getMonth() &&
                date.getDate() === new Date(x.date).getDate()
            );
            });
        return dateobj ? dateobj.colorName : "";
    }


    return (
    <div className="widget-box">
      <div className="widget-box-content">
        <Calendar 
            className="eventCalender" 
            onChange={setDate} 
            value={date}
            calendarType="US"
            formatMonthYear={(locale, date) => t('Event_Calendar')+date.toLocaleString(locale, { month: 'short', year: 'numeric'} )}
            tileClassName={({ activeStartDate, date, view }) => setClass(date)}
        />
      </div>
    </div>
  )
}

export default EventCalender;