import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StepperSidebar from "../commonComponents/stepperSidebar";
import { selectCurrentUser } from "../Reducer/user/user.selector";
import moment from "moment";
import { getUserDetailServices } from "../services/auth.services";
import { useTranslation } from "react-i18next";
const UserEmployementInfo = ({ userDet }) => {
  const [steps, setSteps] = useState(null);
  const { t } = useTranslation();
  const [loggedInUserDet, setLoggedInUserDet] = useState();
  useEffect(() => {
    updateArray();

  }, [userDet])

  const updateArray = () => {
    try {
      if (userDet?.organization && userDet?.organization?.length > 0) {
        let neworgList = userDet?.organization?.map((item, index) => {
          let orgObject = {};
          orgObject['label'] = item?.organizationName;
          let startDate = item?.startYear ? (moment(item?.startYear).format('MMM YYYY')) : ''
          let endDate = item?.endYear ? (moment(item?.endYear).format('MMM YYYY')) : '';
          startDate = startDate ? startDate : t('not_mentioned');
          endDate = endDate ? endDate : t('not_mentioned');
          orgObject['desc'] = startDate + '-' + endDate;
          return (orgObject);
        });
        setSteps(neworgList);

      }

    }
    catch (error) { console.log(error) }
  }
  return (
    <div className="widget-box">
      <p className="widget-box-title">{t('Employment')}</p>
      <div className="widget-box-content">
        {steps === null ? <h6>{t('no_emp_detail')}</h6> :
          <StepperSidebar className="dashboardStepper" steps={steps} />
        }
      </div>
    </div>
  )
}

export default UserEmployementInfo;