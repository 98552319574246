import React from "react";
import UserLayout from "../../components/dashboard-layout";
import PrivacySettings from "../../components/dashboard/settings/privacySettings";

const PrivacySettingsPage = () => {
  return (
    <UserLayout>
      <PrivacySettings />
    </UserLayout>
  );
};

export default PrivacySettingsPage;
