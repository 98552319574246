import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AllEventsSerchList from "./allEventsSearchList";
import GlobalSideBar from "./globalSideBar";



const AllGlobalEventsSearch = ({ eventList, isLoading, totalPageForEvent, handlePageClick }) => {

    const { t } = useTranslation();
    return (<Row className="grid grid-3-6-3 mobile-prefer-content">
        <Col md={3} className="grid-column">
            <GlobalSideBar />
        </Col>

        <Col md={9} className="grid-column myProfileSettings">
            <div>
                <h5 style={{ marginBottom: 10, marginTop:10 }}>
                    {t('form.Events')}
                </h5>
                <AllEventsSerchList eventList={eventList} isLoading={isLoading} totalPageForEvent={totalPageForEvent} handlePageClick={handlePageClick} />
            </div>
        </Col>
    </Row>)
}

export default AllGlobalEventsSearch;