import React from "react";
import { Row, Col } from "react-bootstrap";
import { passwordResetReqService } from "../../../../services/adminManage.services";
import { showSingleToastSuccess, showToastError } from "../../../../utils/helpers";
import { useTranslation } from "react-i18next";

const RequestResetPassword = () => {
    const {t} = useTranslation();
    const requestResetHandler = (e) => {
        e.preventDefault();

        passwordResetReqService()
        .then((data)=>{
            showSingleToastSuccess(t('apiMessages.PASSWORD_RESET_REQUEST_SENT'));
        })
        .catch((error)=>{
            showSingleToastSuccess(t('errors.some_error'));
        })

    }
    return (
        <Row className="grid">
            <div className="grid-column">
                <div className="widget-box">
                    <p className="widget-box-title">{t('reset_password')}</p>
                    <div className="widget-box-content">
                        <Row>
                            <Col md={9}>
                                <span className="span_heading">
                                    <span className="span_heading1">{t('request_change_pwd')}</span>
                                </span>
                            </Col>
                            <Col md={2}>
                                <button className="button primary" onClick={requestResetHandler}>{t('reset_password')}</button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            
        </Row>)
}

export default RequestResetPassword;