import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import FormTextArea from "../../../commonComponents/formTextArea";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import RoundRadio from "../../../commonComponents/roundCheckbox/roundRadio";
import { GenMaleIcon } from "../../../commonComponents/Images";
import { FemaleIcon } from "../../../commonComponents/Images";
import { useNavigate } from "react-router-dom";
import { CalendarMonthIcon } from "../../../commonComponents/Images";
import { PersonIcon } from "../../../commonComponents/Images";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../Reducer/user/user.selector";
import { getImageRequestService, getUserDetailServices, putImageRequestService } from "../../../services/auth.services";
import { getObjectFromStore, setObjectInStore } from "../../../storage/Storage";
import { allowOnlyLetters } from "../../../utils/helpers";
import { getImageUrl } from "../../../utils/helpers";
const DemographicInfo = ({ maxDate, error, setError, backClick, formValues, setFormValues, saveUserDetails }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const current_user = useSelector(selectCurrentUser);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showSelected, setShowSelected] = useState(false);
    const [selectedImageCover, setSelectedImageCover] = useState(null);
    const [showSelectedCover, setShowSelectedCover] = useState(false);
    const [imageForFormProfile, setImageForFormProfile] = useState(null);
    const [imageForFormCover, setImageForFormCover] = useState(null);
    const [isProfRemove, setIsProfRemove] = useState(false);
    const [isCoverRemove, setIsCoverRemove] = useState(false);
    const [errorCover, setErrorCover] = useState(false);
    const [errorProfile, setErrorProfile] = useState(false);
    const [isLoader, setLoader] = useState(false);

    const maleActiveImage = '/Assets/male.png';
    const femaleActiveImage = '/Assets/Female-active.png'

    const nextClick = (e) => {
        e.preventDefault();
        setError(false);
        if (formValues.firstName === '' ||
            formValues.lastName === ''
        ) { setError(true); return; }

        let params = {
            firstName: formValues.firstName,
            middleName: formValues.middleName,
            lastName: formValues.lastName,
            aboutMe: formValues.aboutMe,
            dob: formValues.dob,
            gender: formValues.gender,
            onBoarding: true,
        }
        if (isProfRemove) params['profilePicture'] = ''
        else params['profilePicture'] = imageForFormProfile;
        if (isCoverRemove) params['coverPicture'] = '';
        else params['coverPicture'] = imageForFormCover;
        saveUserDetails(params);
    }

    // useEffect(() => {
    //     removeProfile();
    // }, [setSelectedImage('')])

    //Upload Image and cover Image
    const setProfileImage = async (param) => {
        // let isValidSize = await readImageFile(param)

        setLoader(true)

        if (param.length !== 0) {
            setErrorProfile(param?.size < 50000 || param?.size > 5120000 ? true : false) //|| !isValidSize

            if (param?.size < 50000 || param?.size > 5120000) { //|| !isValidSize
                setLoader(false);
                return;
            }
            setShowSelected(true);
            setSelectedImage(param);
            const timeStamp = Date.now();
            fetchImageData('profile', `${timeStamp}`, param);
        }
        else {
            setLoader(false)
        }
    }
    const setCoverImage = async (param) => {
        // let isValidSize = await readImageFileCover(param)

        setLoader(true)

        if (param.length !== 0) {
            setErrorCover(param?.size < 50000 || param?.size > 5120000 ? true : false) //|| !isValidSize 
            if (param?.size < 50000 || param?.size > 5120000) { //|| !isValidSize
                setLoader(false);
                return;
            }
            setShowSelectedCover(true);
            setSelectedImageCover(param);
            const timeStamp = Date.now();
            fetchImageData('cover', `${timeStamp}`, param);
        }
        else {
            setLoader(false)
        }
    }

    const getWordCount = (t) => {
        let text = t.split(" ");
        return text.length
    }

    const putImageRequest = async (url, actParam, type, fileName) => {
        const response = await fetch(url + '&x-ms-blob-type=BlockBlob', {
            method: 'PUT',
            headers: {
                'x-ms-blob-type': `BlockBlob`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data'
            },
            body: actParam
        })
            .then(res => {
                if (res.ok) {
                    if (type === 'profile') {
                        setObjectInStore('profilePicture', fileName);
                        setImageForFormProfile(fileName);
                        setIsProfRemove(false);                        
                    }
                    else if (type === 'cover') {
                        setObjectInStore('coverPicture', fileName);
                        setImageForFormCover(fileName);
                        setIsCoverRemove(false);                        

                    }
                }
                setLoader(false);
                return res;
            })
            .catch((error) => {
                console.error(error);
                setLoader(false);
            });

        return response;
    }

    const fetchImageData = async (type, fileName, param) => {
        try {
            let params = {
                fileName: fileName,
                category: "user",
                type: type
            }
            var response = await getImageRequestService(params);
            putImageRequest(response?.data?.data, param, type, fileName);

        } catch (error) {
            console.error(error);
            setLoader(false);
        }
    }

    const backDashboardClick = (e) => {
        e.preventDefault();
        navigate('/voxbox');
    }
    const removeProfile = () => {
        setImageForFormProfile("");
        setProfileImage('');
        setShowSelected(false);
        setIsProfRemove(true);
        setFormValues({ ...formValues, profilePicture: '' });
        setLoader(false);
    }
    const removeCover = () => {
        setImageForFormCover("");
        setCoverImage("");
        setShowSelectedCover(false);
        setIsCoverRemove(true);
        setFormValues({ ...formValues, coverPicture: '' });
        setLoader(false);
    }
    function getCount(str) {
        return str.split(' ').filter(function (num) {
            return num !== ''
        }).length;
    }

    //fro profile image upload check width and height
    function readImageFile(file) {
        const promise = new Promise((resolve, reject) => {
            var reader = new FileReader(); // CREATE AN NEW INSTANCE.
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {
                    var w = this.width;
                    var h = this.height;
                    if (h < 225 || w < 225 || h > 420 || w > 420) {
                        resolve(false)
                    } else
                        resolve(true)


                }
            };
        });
        return promise;

    }

    //for Cover image upload check width and height

    function readImageFileCover(file) {
        const promise = new Promise((resolve, reject) => {
            var reader = new FileReader(); // CREATE AN NEW INSTANCE.
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {
                    var w = this.width;
                    var h = this.height;
                    if (h < 300 || w < 800 || w > 3000 || h > 2500) {
                        resolve(false)
                    } else
                        resolve(true)


                }
            };
        });
        return promise;

    }

    return (
        <>
            <div className="heading mb-5">
                <h1>{t('onboarding.fill_your_demographic_info')}</h1>
                <h3>{t('onboarding.to_continue_sprede')}</h3>
            </div>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className="userdetail-form"
            >
                <Row>
                    <Col md={4} className="demographicNameFields">
                        <TextField type='text' label={t('form.label_first_name')}
                            value={formValues.firstName}
                            onChange={(e) => setFormValues({ ...formValues, firstName: allowOnlyLetters(e.target.value) })}
                            error={(error && formValues.firstName === '') ? true : false}
                            helperText={(error && formValues.firstName === '') ? t('errors.First_Name_is_Required') : ''}
                        />
                    </Col>

                    <Col md={4} className="demographicNameFields">
                        <TextField type='text'
                            value={formValues.middleName}
                            onChange={(e) => setFormValues({ ...formValues, middleName: allowOnlyLetters(e.target.value) })}
                            label={t('form.label_middle_name')} />
                    </Col>

                    <Col md={4} className="demographicNameFields">
                        <TextField type='text' label={t('form.label_last_name')}
                            value={formValues.lastName}
                            onChange={(e) => setFormValues({ ...formValues, lastName: allowOnlyLetters(e.target.value) })}
                            error={(error && formValues.lastName === '') ? true : false}
                            helperText={(error && formValues.lastName === '') ? t('errors.Last_Name_is_Required') : ''}
                        />
                    </Col>
                </Row>
                <div className="aboutDatebox">
                    <div className="about-text-area">
                        <FormTextArea label={t('form.label_about_me')} placeholder={t('form.about_placeholder')}
                            value={formValues.aboutMe}
                            onChange={(e) => {
                                if (getWordCount(e.target.value) <= 200) {
                                    setFormValues({ ...formValues, aboutMe: (e.target.value.replace(/\s+/g, ' ')) })
                                }
                            }}
                        />
                        {getWordCount(formValues?.aboutMe) >= 200 ?
                            <div className="error-text">
                                {t('errors.you_can_not_enter_more_than_200_words')}
                            </div>
                            : ""
                        }
                    </div>

                    <div className="demographicBirthFields">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                                closeOnSelect={true}
                                label={t('form.label_your_dob')}
                                maxDate={maxDate}
                                value={formValues.dob}
                                onChange={(newDob) => {
                                    setFormValues({ ...formValues, dob: newDob });
                                }}
                                InputProps={{
                                    endAdornment:
                                        <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} />
                                }}
                                renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                                inputFormat="DD MMM YYYY"
                            />
                        </LocalizationProvider>

                        <div className="gender-text-area demographicGenderFields">
                            <label className="gender_text">{t('form.label_gender')} </label>
                            <div className="gender-MF">
                                <RoundRadio
                                    checked={(formValues.gender === 'male') ? true : false}
                                    onChange={(e) => (e.target.checked) ? setFormValues({ ...formValues, gender: 'male' }) : ''}
                                    icon={<img src={formValues?.gender == 'male' ? maleActiveImage : GenMaleIcon} />} name="gender" value='male' label={t('form.label_male')} />
                                <RoundRadio
                                    checked={(formValues.gender === 'female') ? true : false}
                                    onChange={(e) => (e.target.checked) ? setFormValues({ ...formValues, gender: 'female' }) : ''}
                                    icon={<img src={formValues?.gender == 'female' ? femaleActiveImage : FemaleIcon} />} name="gender" value='female' label={t('form.label_female')} />
                            </div>
                        </div>
                    </div>
                </div>
                <span className='fa fa-trash'></span>
                <div>
                    <div className="upload-area addSpacing demographicUploadArea">
                        <Col lg={5} className="p-0 col-md-4 position-relative">

                            {
                                (formValues?.profilePicture || showSelected) ?
                                    <>
                                        <div className="">
                                            <label className="user_img_upload" htmlFor="upload-photo">
                                                <input type="file" id="upload-photo"
                                                    accept="image/png, image/gif, image/jpeg"
                                                    onChange={(event) => {
                                                        setProfileImage(event.target.files[0]);

                                                    }} />
                                                <div className="content demographic demographicUploadContent">
                                                    <img src={showSelected ? URL.createObjectURL(selectedImage) : getImageUrl(current_user?._id, 'userProfile', formValues?.profilePicture)} className="user_attach_upload" />
                                                </div>
                                            </label>
                                            <img src='/Assets/Close.png' className="closeIconUpload closeIconDemoUpload" onClick={() => removeProfile()} />
                                        </div>
                                        {errorProfile && <p style={{ color: "red" }} className="error-message">Choose correct file according to mentioned size</p>}
                                    </>
                                    : <>

                                        <label className="user_img_upload" htmlFor="upload-photo">

                                            <input type="file" id="upload-photo"
                                                accept="image/png, image/gif, image/jpeg"
                                                onChange={(event) => {
                                                    setProfileImage(event.target.files[0])
                                                }} />
                                            <div className="content demographic">
                                                {/* <img src={selectedImage ? URL.createObjectURL(selectedImage) : PersonIcon} className="mb" /> */}
                                                <p>{t('form.upload_your_profile_photo')}</p>
                                                <p className="upload-box-text">{t('Size')}: 5KB - 5MB</p>
                                                <p className="upload-box-text">{t('Best_Resolution')}: 225X225 Or 420X420</p>
                                            </div>
                                        </label>
                                        {errorProfile && <p style={{ color: "red" }} className="error-message">{t('Choose_correct_file_according_to_mentioned_size')} </p>}
                                    </>
                            }

                        </Col>

                        <Col lg={7} className="p-0 col-md-8">

                            {
                                (formValues?.coverPicture || showSelectedCover) ?
                                    <>
                                        <div className="positionRelative mt-auto">
                                            <label className="user_cover_upload" htmlFor="upload-cover">
                                                <input type="file" id="upload-cover"
                                                    accept="image/png, image/gif, image/jpeg"
                                                    onChange={(event) => {
                                                        setCoverImage(event.target.files[0])
                                                    }} />
                                                <div className="content demographic">
                                                    <img src={showSelectedCover ? URL.createObjectURL(selectedImageCover) : getImageUrl(current_user?._id, 'userCover', formValues?.coverPicture)} alt="#" />
                                                </div>
                                            </label>
                                            <img src='/Assets/Close.png' className="closeIconUpload right20" onClick={() => removeCover()} />
                                        </div>
                                        {errorCover && <p style={{ color: "red" }} className="error-message">Choose correct file according to mentioned size</p>}
                                    </>
                                    : <><label className="user_cover_upload" htmlFor="upload-cover">
                                        <input type="file" id="upload-cover"
                                            accept="image/png, image/gif, image/jpeg"
                                            onChange={(event) => {
                                                setCoverImage(event.target.files[0])
                                            }}
                                        />
                                        <div className="content demographic d-flex flex-column">
                                            <p>{t('form.upload_your_cover_photo')}</p>
                                            <p className="upload-box-text">{t('Size')}: 5KB - 5MB</p>
                                            <p className="upload-box-text">{t('Best_Resolution')}: 800X300 Or 3000X2500</p>
                                        </div>
                                    </label>
                                        {errorCover && <p style={{ color: "red" }} className="error-message">{t('Choose_correct_file_according_to_mentioned_size')}</p>}
                                    </>
                            }
                        </Col>
                    </div>
                </div>

                <Row>
                    <div className="back-next-btn-area">
                        <Stack spacing={2} direction="row">
                            <button className="button green back-btn-new" onClick={backDashboardClick} disabled={isLoader}> {t('back')} </button>
                            <button className="button secondary next-btn-new" onClick={nextClick} disabled={isLoader}>{isLoader && <span className="spinner-border" />} {t('next')} </button>
                        </Stack>
                    </div>
                </Row>
            </Box>
        </>
    )
}

export default DemographicInfo;