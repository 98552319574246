import React from "react";
import { Row, Col } from "react-bootstrap";
import { AvatarImg } from "../../../commonComponents/Images";
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { useState, useEffect, useRef } from "react";
import BarsLoader from "../../../commonComponents/barsLoader";
import { getImageUrl, showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import swal from 'sweetalert';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { deleteVendorService, getVendorService, updateVendorService } from "../../../services/vendor.services";
const VendorList = ({ searchBy, countsOfFriends }) => {

    const { t } = useTranslation();
    const limit = 5;
    const [PageNo, setPageNo] = useState(0);
    const [vendorsList, setVendorsList] = useState([]);
    const [lodaing, setLoading] = useState(false);
    const [noReqMsg, setNoReqMsg] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [status, setStatus] = useState(true);
    let defaultUserImage = AvatarImg;
    const navigate = useNavigate();
    const observer = useRef();

    useEffect(() => {
        setVendorsList([]);
        setPageNo(0)
    }, [searchBy]);

    useEffect(() => {
        getVendorsList(PageNo);
    }, [searchBy, PageNo])


    const lastElement = (node) => {
        if (lodaing) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((enteries) => {
            if (enteries[0].isIntersecting && hasMore) {
                setPageNo((prev) => prev + 1);
            }
        })
        if (node) {
            observer.current.observe(node);
        }
    }

    const getVendorsList = (currentPage) => {
        setLoading(true);
        getVendorService(currentPage, limit, searchBy)
            .then((resp) => {
                const user = resp?.data?.data?.data;
                const user2 = user.map(a => ({ ...a, removeIs: false }));
                let newVendorList = [...vendorsList, ...user2];
                if (PageNo === 0) newVendorList = [...user2]
                let newArr = newVendorList?.reduce((acc, curr) => {
                    let fnd = acc.find((a) => a._id === curr?._id);
                    if (!fnd) acc.push(curr);
                    return acc;
                }, []);

                newArr = newArr?.filter(a => !a?.approveIs && !a?.rejectIs);
                setVendorsList(newArr);
                countsOfFriends(resp?.data?.data?.totalCount);
                setLoading(false);
                setHasMore(resp?.data?.data?.data?.length > 0 && user?.length === limit);
                setNoReqMsg((resp?.data?.data?.totalCount) > 0 ? false : true);

            })
    }

    const enableDisableVendors = (e, emp, index, status) => {
        e.preventDefault()
        // if (emp?.status === 'active') {
        updateVendorService({
            "vendorId": emp?._id,
            "status": status
        })
            .then((resp) => {
                if (resp?.status == 200) {
                    const nextList = [...vendorsList];
                    let index1 = nextList?.findIndex(a => a._id === emp?._id);
                    if (index >= 0) {
                        nextList[index]['removeIs'] = true;
                        setVendorsList(nextList);
                        let pageNo1 = Math.floor(index1 / limit);
                        getVendorsList(pageNo1)
                        vendorsList?.splice(index, 1);
                        showSingleToastSuccess(t('Vendor_Status_Updated'));
                    }
                }
            })
            .catch((error) => {
                showToastError(t(`apiMessages.SOME_THING_WENT_WRONG`))
            })
    }
    const handleRemoveVendors = (e, emp, index) => {
        e.preventDefault()
        // if (emp?.status === 'active') {
        swal({
            text: t('alerts.delete_vendor'),
            title: '',
            icon: "warning",
            buttons: {
                Yes: {
                    text: t('yes'),
                    value: true,
                },
                No: {
                    text: t('no'),
                    value: false,
                },
            },
        })
            .then((val) => {
                if (!val) return;
                deleteVendorService(emp?._id)
                    .then((resp) => {
                        if (resp?.status == 200) {
                            const nextList = [...vendorsList];
                            let index1 = nextList?.findIndex(a => a._id === emp?._id);
                            if (index >= 0) {
                                nextList[index]['removeIs'] = true;
                                setVendorsList(nextList);
                                let pageNo1 = Math.floor(index1 / limit);
                                getVendorsList(pageNo1)
                                vendorsList?.splice(index, 1);
                                showSingleToastSuccess(t('Vendor_Removed'));

                            }
                        }
                    })
                    .catch((error) => {
                        showToastError(t(`apiMessages.SOME_THING_WENT_WRONG`))
                    })

            })
    }
    const showUser = (user) => {
        let encrptedId = window.btoa(user?._id);
        //navigate(`/profile/${encrptedId}`)
        navigate(`/profile/${encrptedId}`, { state: { userId: user?._id } })
    }

    return (
        <Row className='vendorListComp grid'>
            {vendorsList?.length > 0 ? <>
                <Col md={12} className=" grid-column">
                    {vendorsList?.length > 0 && vendorsList?.map((item, index) => {
                        if (vendorsList?.length === index + 1) {
                            return <div className="widget-box pa-20" key={index} ref={lastElement} >
                                <div className="target_box_area m-none">
                                    <div className="container-fluid">
                                        <div className="row align-items-lg-center">
                                            <div className="col-12 col-sm-2 col-lg-2 col-xl-1 mb-2 text-start">
                                                <div className="avtarImgBlck"><AvatarImgBox img={item?.profilePicture ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : defaultUserImage} /></div>
                                            </div>
                                            <div className="col-12 col-sm-2 col-lg-1 mb-2 text-start">
                                                <div className="fullWidthRef">
                                                    <p className="user-status-title">
                                                        <span className="bold">{(`${item?.name}`) || ''}</span>
                                                    </p>
                                                    <small>{item?.userName ? `@${item?.userName}` : ''}</small>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-2 col-lg-2 col-xl-2 mb-2 text-start">
                                                <div className='fullWidthRef'>
                                                    <p className="user-status-title">
                                                        <span className="bold">{item?.email ? (`Email:` + "  " + `${item?.email}`) : ""}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-2 col-lg-2 mb-2 text-start">
                                                <div className='fullWidthRef'>
                                                    <p className="user-status-title">
                                                        <span className="bold">{item?.mobile ? (`Mobile:` + "  " + `${item?.mobile}`) : ""}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-2 col-lg-2 mb-2 text-start">
                                                <div className='fullWidthRef'>
                                                    <p className="user-status-title">
                                                        <span className="bold">{item?.location ? (`Location:` + "  " + ` ${item?.location}`) : ""}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-2 col-lg-2 mb-2 text-start">
                                                <div className='fullWidthRef'>
                                                    <p className="user-status-title"><span className="bold">   {
                                                        item?.supplies ? (`Supplies:` + " " + `${item?.supplies}`) : ""
                                                    }</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-2 mb-2 text-start">
                                                <div className="inviteBtnBlock">
                                                    <div className="action-request-list">
                                                        {/* {item?.status ?
                                                            <button className="button removeBtn me-2" onClick={(e) => enableDisableVendors(e, item, index, false)}>{t('Disable')}</button>
                                                            : <button className="button primary removeBtn1" onClick={(e) => enableDisableVendors(e, item, index, true)}>{t('Enable')}</button>} */}
                                                        {
                                                            item?.inviteReqIS ?
                                                                <button className="button removeBtn">{t('Vendor_Removed')}</button>
                                                                : <button className="button removeBtn" onClick={(e) => handleRemoveVendors(e, item, index)}>{t('Remove')}</button>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        else {
                            return <div className="widget-box pa-20" key={index}>
                                <div className="target_box_area m-none">
                                    <div className="container-fluid">
                                        <div className="row align-items-lg-center">
                                            <div className="col-12 col-sm-2 col-lg-2 col-xl-1 mb-2 text-start">
                                                <div className="avtarImgBlck"><AvatarImgBox img={item?.profilePicture ? getImageUrl(item?._id, 'userProfile', item?.profilePicture) : defaultUserImage} /></div>
                                            </div>
                                            <div className="col-12 col-sm-2 col-lg-1 mb-2 text-start">
                                                <div className="fullWidthRef">
                                                    <p className="user-status-title">
                                                        <span className="bold">{(`${item?.name}`) || ''}</span>
                                                    </p>
                                                    <small>{item?.userName ? `@${item?.userName}` : ''}</small>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-2 col-lg-2 col-xl-2 mb-2 text-start">
                                                <div className='fullWidthRef'>
                                                    <p className="user-status-title">
                                                        <span className="bold">{item?.email ? (`Email:` + "  " + `${item?.email}`) : ""}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-3 col-lg-2 mb-2 text-start">
                                                <div className='fullWidthRef'>
                                                    <p className="user-status-title">
                                                        <span className="bold">{item?.mobile ? (`Mobile:` + "  " + `${item?.mobile}`) : ""}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-2 col-lg-2 mb-2 text-start">
                                                <div className='fullWidthRef'>
                                                    <p className="user-status-title">
                                                        <span className="bold">{item?.location ? (`Location: ` + "  " + ` ${item?.location}`) : ""}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-2 col-lg-2 mb-2 text-start">
                                                <div className='fullWidthRef'>
                                                    <p className="user-status-title"><span className="bold">   {
                                                        item?.supplies ? (`Supplies:` + " " + `${item?.supplies}`) : ""
                                                    }</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-2 mb-2 text-start">
                                                <div className="inviteBtnBlock">
                                                    <div className="action-request-list">
                                                        {/* {item?.status ?
                                                            <button className="button removeBtn me-2" onClick={(e) => enableDisableVendors(e, item, index, false)}>{t('Disable')}</button>
                                                            : <button className="button primary removeBtn1" style={{ fontSize: "17px !important", fontWeight: "300" }} onClick={(e) => enableDisableVendors(e, item, index, true)}>{t('Enable')}</button>} */}
                                                        {
                                                            item?.inviteReqIS ?
                                                                <button className="button removeBtn">{t('Vendor_Removed')}</button>
                                                                : <button className="button removeBtn" onClick={(e) => handleRemoveVendors(e, item, index)}>{t('Remove')}</button>

                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    }

                    )}
                </Col>
            </> : noReqMsg === true ? <h4 className='nopeMsg my-2'>{t('No_Vendors')}</h4> : ''}
            {
                lodaing ?
                    <BarsLoader />
                    : ''
            }
        </Row>

    )
}

export default VendorList;