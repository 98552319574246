import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PopUp from '../commonComponents/popup';
import { userStatusUpdateService } from '../services/adminManage.services';
import AvatarImgBox from '../commonComponents/AvatarImgBox/avatarImgBox';
import { AvatarImg } from '../commonComponents/Images';
import {
  allowOnlyLetters,
  allowOnlyLettersNumbers,
  showSingleToastSuccess,
  showToastError,
} from '../utils/helpers';
import { getImageUrl } from '../utils/helpers';
import { Box, Autocomplete, TextField, Switch } from '@mui/material';
import {
  removeUserOrgAdminService,
  changeOrgAdminService,
  createOrgAdminService,
  canChangeOrgAdminService,
  deleteUserAccountService,
} from '../services/adminManage.services';
import swal from 'sweetalert';
import { validEmail } from '../utils/formatValidator';

const ManageUserBox = ({
  userData,
  orgData,
  orgList,
  setListMoreOrgs,
  listMoreOrgs,
  removeOrgFromOtherList,
  loadUsers,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState(userData);
  const [show, setShow] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const handleShowOrgPopup = () => setPopupOpen(true);
  const handleCloseOrgPopup = () => setPopupOpen(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(userData);
    setActiveOrg(userData);
  }, [userData]);
  console.log(data);
  const [currentOrgDetail, setCurrentOrgDetail] = useState('');
  const setActiveOrg = (param) => {
    let currentOrg = '';
    if (param?.userOrganizationId?.organization?.length) {
      let organizationList = param?.userOrganizationId?.organization;
      for (let i = 0; i < organizationList.length; i++) {
        if (
          organizationList[i].isVerified == true &&
          organizationList[i].isCurrentlyWorking == true
        ) {
          currentOrg = organizationList[i].organizationId
            ? organizationList[i].organizationId
            : organizationList[i].orgId;
        }
      }
    }
    if (currentOrg != '') {
      let currentOrgName = orgList.filter((item) => {
        if (item?._id == currentOrg) {
          let name = item.name;
          return name;
        }
      });
      if (currentOrgName.length != 0) {
        setCurrentOrgDetail(currentOrgName[0].name);
        setFormValues({
          ...formValues,
          organizationName: currentOrgName[0].name,
        });
      }
    }
  };
  const [formValues, setFormValues] = useState({
    organizationName: null,
    orgName: '',
    orgEmail: '',
    orgPhone: '',
    isLoading: false,
    error: false,
  });

  const userStatusChangeHandler = (status) => {
    let params = {
      userId: data?._id,
      status: status ? 'active' : 'inactive',
    };

    userStatusUpdateService(params)
      .then(async (response) => {
        if (response?.data?.status === 200) {
          setData({ ...data, status: status ? 'active' : 'inactive' });
          showSingleToastSuccess(
            status
              ? t('apiMessages.USER_ACTIVE_SUCCESS')
              : t('apiMessages.USER_INACTIVE_SUCCESS')
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkOrgAdmin = () => {
    if (data?.pages?.length > 0) {
      let isAdmin = false;

      data?.pages?.map((item) => {
        if (item?.role === 'admin') {
          isAdmin = true;
          return;
        }
      });
      return isAdmin;
    }
    return false;
  };

  const createOrgHandler = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, error: false });

    if (
      formValues?.organizationName !== '' &&
      formValues?.organizationName !== null
    ) {
      setFormValues({ ...formValues, isLoading: true });
      setLoading(true);
      let orgId = '';
      let filter = orgList.filter(
        (item) => item?.name === formValues?.organizationName
      );
      if (filter.length > 0) {
        orgId = filter[0]?._id;
      }

      //Can Change Page Admin
      canChangeOrgAdminService(orgId)
        .then(async (resp) => {
          if (resp?.data?.status === 200) {
            if (resp?.data?.data === 'ORGANIZATION_ADMIN_CAN_BE_CHANGE') {
              await changeOrgAdmin(orgId, filter[0]?.profilePicture);
              handleCloseOrgPopup();
              setLoading(false);
            } else {
              confirmAndChangeOrgId(orgId, filter[0]?.profilePicture);
              setLoading(false);
            }
          } else {
            showToastError(
              t(`apiMessages.Some_Error_Occured_Please_try_again_later`)
            );
            handleCloseOrgPopup();
            setLoading(false);
          }
        })
        .catch((error) => {
          confirmAndChangeOrgId(orgId, filter[0]?.profilePicture);
          setLoading(false);
        });
      setFormValues({ ...formValues, isLoading: false });

      //******************** */
    } else {
      if (
        formValues?.orgName === '' ||
        formValues?.orgName.length < 3 ||
        formValues?.orgEmail === '' ||
        !validEmail(formValues.orgEmail)
      ) {
        setFormValues({ ...formValues, error: true });
        return;
      }

      setFormValues({ ...formValues, isLoading: true });
      setLoading(true);

      let params = {
        userId: data?._id,
        name: formValues?.orgName,
        email: formValues?.orgEmail,
      };

      createOrgAdminService(params)
        .then((response) => {
          if (response?.data?.status === 200) {
            setData({
              ...data,
              pages: [
                {
                  role: 'admin',
                  organizationId: {
                    _id: response?.data?.data,
                    name: formValues?.orgName,
                    profilePicture: '',
                  },
                },
              ],
            });
            showSingleToastSuccess(t('apiMessages.USER_ORG_ADMIN_SET_SUCCESS'));
            setListMoreOrgs(!listMoreOrgs);
            loadUsers();
            handleCloseOrgPopup();
            setLoading(false);
          } else {
            showToastError(t(`apiMessages.${response?.data?.data}`));
            setFormValues({ ...formValues, isLoading: false });
            setLoading(false);
          }
        })
        .catch((error) => {
          showToastError(t(`apiMessages.${error?.data?.data}`));
          setFormValues({ ...formValues, isLoading: false });
          setLoading(false);
        });
    }
  };

  const changeOrgAdmin = async (orgId, profile) => {
    let params = {
      userId: data?._id,
      organizationId: orgId,
    };

    changeOrgAdminService(params)
      .then(async (response) => {
        if (response?.data?.status === 200) {
          await setData({
            ...data,
            pages: [
              {
                role: 'admin',
                organizationId: {
                  _id: orgId,
                  name: formValues?.organizationName,
                  profilePicture: profile,
                },
              },
            ],
          });

          showSingleToastSuccess(t('apiMessages.USER_ORG_ADMIN_SET_SUCCESS'));
          setFormValues({
            organizationName: null,
            orgName: '',
            orgEmail: '',
            orgPhone: '',
            isLoading: false,
            error: false,
          });
          loadUsers();
          handleCloseOrgPopup();
        } else {
          showToastError(t(`apiMessages.${response?.data?.data}`));
          setFormValues({ ...formValues, isLoading: false });
        }
      })
      .catch((error) => {
        showToastError(t(`apiMessages.${error?.data?.data}`));
        setFormValues({ ...formValues, isLoading: false });
      });

    setFormValues({ ...formValues, isLoading: false });
  };

  const confirmAndChangeOrgId = (orgId, profile) => {
    swal({
      text: t('alerts.change_org_admin'),
      title: '',
      icon: 'warning',
      buttons: {
        Yes: {
          text: t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    }).then(async (val) => {
      if (!val) return;
      removeOrgFromOtherList(orgId);
      changeOrgAdmin(orgId, profile);
    });
  };

  const removeOrgAdmin = () => {
    swal({
      text: t('alerts.remove_org_admin'),
      title: '',
      icon: 'warning',
      buttons: {
        Yes: {
          text: t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    }).then(async (val) => {
      if (!val) return;

      let params = {
        userId: data?._id,
        organizationId: data?.pages[0]?.organizationId?._id,
      };

      removeUserOrgAdminService(params)
        .then(async (response) => {
          if (response?.data?.status === 200) {
            setData({ ...data, pages: [] });
            showSingleToastSuccess(t('apiMessages.USER_REMOVED_ORG_ADMIN'));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const deleteUserHandler = (e) => {
    e.preventDefault();
    swal({
      text: t('alerts.delete_user'),
      title: '',
      icon: 'warning',
      buttons: {
        Yes: {
          text: t('yes'),
          value: true,
        },
        No: {
          text: t('no'),
          value: false,
        },
      },
    }).then(async (val) => {
      if (!val) return;

      try {
        deleteUserAccountService(data?._id)
          .then((res) => {
            if (res?.data?.status === 200) {
              setShow(false);
              showSingleToastSuccess(t(`apiMessages.USER_DELETE_SUCCESS`));
            } else {
              showToastError(t('errors.some_error'));
            }
          })
          .catch((error) => {
            showToastError(t('errors.some_error'));
          });
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <>
      {show && (
        <div className="col-lg-12 col-md-6 mt-4 col-sm-6 parentBox">
          <div className="manage_box_area">
            <div className="align-items-center row h-100">
              <div className="col-lg-3">
                <div className="d-flex align-items-start align-items-lg-center flex-lg-row flex-column">
                  <div className="manage_box_pic_area">
                    <AvatarImgBox
                      img={
                        data?.profilePicture !== ''
                          ? getImageUrl(
                              data?._id,
                              'userProfile',
                              data?.profilePicture
                            )
                          : AvatarImg
                      }
                    />
                  </div>
                  <div className="mamage_pro_area">
                    {`${data?.firstName} ${data?.lastName}` || ''}
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="d-flex align-items-start align-items-lg-center flex-lg-row flex-column">
                  <div className="mamage_pro_area">
                    {`${data?.userName}` || ''}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mt-3 mt-lg-0 mb-2 mb-lg-0 text-lg-start text-start">
                <span> {t('superAdmin.manage_make_org_admin_toggle')}</span>
                <span className="make_org_switch">
                  <Switch
                    checked={checkOrgAdmin()}
                    onChange={
                      checkOrgAdmin() ? removeOrgAdmin : handleShowOrgPopup
                    }
                  />
                </span>
                {checkOrgAdmin() && (
                  <div>
                    {data?.pages?.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          {t('form.organization_name')} :{' '}
                          {item?.organizationId?.name}
                        </Fragment>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="col-lg-2 mb-3 mb-lg-0 col-md-12 text-lg-start text-start enableRoleBox enable_role_margin">
                {data?.role !== 'administrator' ? (
                  <>
                    <span>{t('superAdmin.manage_user_status_toggle')}</span>
                    <span>
                      <Switch
                        checked={data?.status === 'active' ? true : false}
                        on
                        Change={(e) =>
                          userStatusChangeHandler(e.target.checked)
                        }
                      />
                    </span>
                  </>
                ) : (
                  ''
                )}
              </div>

              <div className="col-lg-1 text-end manage_r_btn_area mt-auto mb-0 mb-lg-auto">
                {data?.role !== 'administrator' ? (
                  <button
                    className="btn btn-danger"
                    onClick={deleteUserHandler}
                  >
                    {t('button.delete')}
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <PopUp
        open={popupOpen}
        handleClose={handleCloseOrgPopup}
        modalHeadingClass={'editPostHeading'}
        modalBodyClasses={'makeOrgAdminModal'}
        heading={t('superAdmin.makeOrCreateOrg')}
      >
        <Box
          className="manageUsersMakeAdminForm"
          component="form"
          noValidate
          autoComplete="off"
        >
          <Autocomplete
            className="manageUsersFormSelect"
            disablePortal
            value={formValues?.organizationName || currentOrgDetail || ''}
            options={orgData}
            getOptionLabel={(option) => option}
            onChange={(e, val) =>
              setFormValues({ ...formValues, organizationName: val })
            }
            renderInput={(params) => (
              <TextField {...params} label={t('form.select_org')} />
            )}
          />

          <p className="orText pt-2">OR</p>

          <TextField
            className="manageUsersPopupTextField"
            label={t('form.create_org')}
            value={formValues?.orgName}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                orgName: allowOnlyLettersNumbers(e.target.value),
              })
            }
          />

          {formValues?.organizationName === '' ||
          formValues?.organizationName === null ? (
            formValues?.error && formValues?.orgName === '' ? (
              <div className="error-text mb-3">
                {t('errors.org_name_required')}{' '}
              </div>
            ) : formValues?.error && formValues?.orgName.length < 3 === '' ? (
              <div className="error-text mb-3">
                {t('errors.org_name_length')}{' '}
              </div>
            ) : (
              ''
            )
          ) : (
            ''
          )}

          <TextField
            className="manageUsersPopupTextField"
            label={t('form.org_email')}
            value={formValues?.orgEmail}
            onChange={(e) =>
              setFormValues({ ...formValues, orgEmail: e.target.value })
            }
          />

          {formValues?.organizationName === '' ||
          formValues?.organizationName === null ? (
            formValues?.error && formValues?.orgEmail === '' ? (
              <div className="error-text mb-3">
                {t('errors.org_email_required')}{' '}
              </div>
            ) : formValues?.error && !validEmail(formValues?.orgEmail) ? (
              <div className="error-text mb-3">
                {t('errors.org_email_invalid')}
              </div>
            ) : (
              ''
            )
          ) : (
            ''
          )}

          <button
            type="submit"
            className="submitBtn btnPadding"
            disabled={loading}
            onClick={(e) => createOrgHandler(e)}
          >
            {t('form.submit')}
            {loading && <span className="spinner-border" />}
          </button>
        </Box>
      </PopUp>
    </>
  );
};

export default ManageUserBox;
