import React from "react";
import { Col } from "react-bootstrap";
import AvatarImgBox from "../commonComponents/AvatarImgBox/avatarImgBox";
import { AvatarImg } from "../commonComponents/Images";
import GreenCheckBox from "../commonComponents/greenCheckbox/greenCheckbox";
import { getImageUrl } from "../utils/helpers";
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
const UserSelector = ({checked, user, onChange,validator=false}) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    return (
            <div className="widget-box friends-list-box">
                <div>
                    <GreenCheckBox onChange={(e)=>onChange(e, user._id, user)} checked={checked}/>
                    <div>
                        <AvatarImgBox img={user?.profilePicture && user?.profilePicture !== "" ? getImageUrl(user?._id, 'userProfile', user?.profilePicture) : AvatarImg} />
                        <p>{(user?.name) ? user?.name : (user?.firstName)? `${user?.firstName} ${user?.lastName}` : user?.email}
                        </p>
                        {user?.userName && 
                        <small>@{user?.userName}</small>}
                        <p>{user?.place}</p>
                        <p>{user?.city || ''}</p>

                        {validator && 
                            <Link to={'/validator/'+window.btoa(user?._id)} target="_blank" className="button secondary">{t('View_Info')}</Link>
                        }
                    </div>
                </div>
            </div>
    )
}


export default UserSelector;