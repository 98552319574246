import React from "react";
import Popup from "../commonComponents/popup";
import { getImageUrl } from "../utils/helpers";
import PdfDocument from "../commonComponents/pdfDocument";

const ShowPdfPopUp= ({open, handleClose, pdfGetData}) => {

    return(
        <Popup open={open}  handleClose={ handleClose} modalBodyClasses={"evidenceShowPdfModalBody"}>
            <PdfDocument url={getImageUrl(pdfGetData?.id, pdfGetData?.type,pdfGetData?.param)}/>  
        </Popup>
    )
}

export default ShowPdfPopUp;