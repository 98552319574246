import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { unSubscribeNewsletterService } from '../services/auth.services';
import { getCmsServiceList } from '../services/cms.services';
import HomeBanner from './homepage/banner';
import '../pages/page-not-found/pageNotFound.style.css';
import i18next from 'i18next';
import { LanguageList } from '../config/constants';
const UnSubscribeNewsLetter = () => {
    const {t} = useTranslation()
    const [params, setParams] = useSearchParams();
    const defaultLanguage = i18next?.language?.split('-')[0];
    const find = LanguageList?.find(a => a.value === defaultLanguage);
    const [cmsData, setcmsData] = useState([])

    const [language, setLanguage] = useState((find?.name) ? find : LanguageList[0])

    useEffect(() => {
        let filter = LanguageList?.find(a => a.value === defaultLanguage);

        setLanguage((filter?.name) ? filter : LanguageList[0])
    }, [defaultLanguage])

    React.useEffect(() => {
        const handleLanguageChange = () => {

            let defaultLanguage1 = i18next?.language?.split('-')[0];

            let filter = LanguageList?.find(a => a.value === defaultLanguage1);

            setLanguage((filter?.name) ? filter : LanguageList[0])
        };

        i18next.on("languageChanged", handleLanguageChange);

        return () => {
            i18next.off("languageChanged", handleLanguageChange);
        };
    }, []);

    useEffect(() => {
        if (language !== null) { getCmsData(); }
    }, [language]);

    const getCmsData = async () => {
        getCmsServiceList(language?.value)
            .then((data) => {
                if (data?.data?.status === 200) {
                    const _data = data.data.data;
                    setcmsData(_data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(()=>{
        if(params.get('email') !== '' && params.get('email') !== null && params.get('email') !== undefined)
        {
            unSubscribeNewsletterService(params.get('email'))
            .then((data)=>{
                console.log(data)
            })
            .catch((error)=>{
                console.log(error)
            })
        }
    },[])
    return (<>
        <HomeBanner onlyMenu={true} cmsData={cmsData}/>
        <div className="error-section">
            <p className="error-section-title">{t('Unsubscribed_Successfully')}</p>
            <div className="error-section-info">
            <p className="error-section-subtitle">{t('you_are_successfully_unsubscribed_from_sprede_platform')}</p>
            {/* <p className="error-section-text">{t('errors.Seems_that_you_made_a_wrong')}</p> */}
            <Link className='button medium primary' to="/"> {t('home')} </Link>
            </div>
        </div>
    </>)
}

export default UnSubscribeNewsLetter;