import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { subscriptionService } from "../../services/auth.services";
import { validEmail } from "../../utils/formatValidator";
const NewsLetter = () => {
    const {t} = useTranslation();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const SubscribenewsLetter = () => {
        setError(false); setErrorMsg(''); setSuccess(false);
        if(email === '' || !validEmail(email)){
            setError(true); return;
        }

        try{
            let params = {
                email : email
            }

            subscriptionService(params)
            .then((data)=>{
                if(data?.status === 200){
                    setSuccess(true);
                }
                else
                {
                    setError(true);
                    setErrorMsg(data?.data?.data);
                }
            })
            .catch((error)=>{
                setError(true);
                setErrorMsg(error?.data?.data);
            })
        }
        catch(error){
            console.log(error);
        }
    }
    return (
        <div className="newsletter-div">
            <Row className="align-items-center">
                <Col md={6}>
                    <p className="newsletter-heading">{t('homepage.newsletter.title')}</p>
                    <span className="newsletter-detail">{t('homepage.newsletter.desc')}</span>
                </Col>
                <Col md={6}>
                    <form className="newsletter-form">
                            <input 
                            type="text" 
                            id="email" 
                            name="email"
                            value = {email}
                            onChange = {(e)=>{setEmail(e.target.value.toLowerCase());setError(false)}}
                            className="newsletter_input" 
                            placeholder={t('homepage.newsletter.formPlaceholder')}/>
                            <button 
                            onClick={(e)=>{e.preventDefault(); SubscribenewsLetter();}}
                            className="button newsletter_button">{t('homepage.newsletter.title')}</button>

                            {error && 
                                <div className="error-text">
                                    {   email === '' ? 
                                        t('errors.email_required')
                                        : !validEmail(email) ? 
                                        t('errors.email_valid') : 
                                        errorMsg ? 
                                        t(`apiMessages.${errorMsg}`) : ''
                                    }
                                </div>
                            }
                            {success && 
                                <div className="successMsg"> 
                                    {t('homepage.newsletter.successMessage')}
                                </div>
                            }
                    </form>
                </Col>
            </Row>
        </div>
    );
}

export default NewsLetter;