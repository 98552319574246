import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import '../dashboard.style.css';
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import OrgFewList from "../../../commonDashboardComp/orgFewList";
import EventCalender from "../../../commonDashboardComp/eventCalender";
import CreatePostBox from "../../../commonDashboardComp/createPostBox";
import PostList from "../../../commonDashboardComp/postList";
import TodayEventList from "../../../commonDashboardComp/todayEventsList";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectCurrentOrgRole, selectCurrentUserRole } from "../../../Reducer/user/user.selector";
import TargetBox from "../../../commonDashboardComp/targetBox";
import { getEventDetailByDateService } from "../../../services/event.services";
import { changeDateFormatYYYY } from "../../../utils/helpers";
import Memories from "../../../commonDashboardComp/memories";
const NewsFeed = () => {

  const role = useSelector(selectCurrentUserRole);
  const [eventByDate, setEventsByDate] = useState({});
  const [date, setDate] = useState(changeDateFormatYYYY(new Date()));
  const [eventDate, setEventDate] = useState(new Date())
  const OrgRole = useSelector(selectCurrentOrgRole);
  useEffect(() => {
    getEventByDate();
  }, [date])

  const getEventByDate = () => {
    getEventDetailByDateService(date)
      .then((data) => {
        if (data?.data?.status === 200) {
          setEventsByDate(data?.data?.data);
        }
      })
  }

  const setEventDateHandler = (d) => {
    setEventDate(d);
    setDate(changeDateFormatYYYY(d))
  }

  return (
    <>
      {/* <div> <ProfileHeader coverImg='' img=''/> </div> */}


      <Row className="grid grid-3-6-3 mobile-prefer-content updateColWidth">
        <Col lg={3} className="col-md-12 mobile_margin_btm">
          {(role === 'organization' && (OrgRole !== 'user_approver') || role === 'superAdmin') && 
            <>
               <TargetBox /> 
               <Memories/>
            </>}
          {(role === 'user') &&
            <>
              <EventCalender date={eventDate} setDate={setEventDateHandler} />
              <TodayEventList date={date} events={eventByDate} />
              <Memories/>
            </>}
        </Col>


        <Col lg={6} className="col-md-12">
          <CreatePostBox />
          <PostList />
        </Col>

        <Col lg={3} className="col-md-12 mobile_margin_top">
          {(role === 'organization' || role === 'superAdmin') && <>
            <EventCalender date={eventDate} setDate={setEventDateHandler} />
            <TodayEventList date={date} events={eventByDate} /></>}
          {(role === 'user') && <OrgFewList />}
        </Col>


      </Row>
    </>);
}

export default NewsFeed;
