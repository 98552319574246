import React, { useState, useEffect } from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from "react-i18next";
import { getUserDetailServices } from "../services/auth.services";
import { requiredListForUserPercentage,requiredListForOrganizationPercent } from "../config/constants";
import { useSelector } from "react-redux";
import { selectCurrentOrg, selectCurrentUser, selectCurrentUserRole } from "../Reducer/user/user.selector";
import { useNavigate } from "react-router-dom";
const ProfileCompletion = () => {
    const { t } = useTranslation();
    //const detail=useSelector(selectCurrentUser);
    const role = useSelector(selectCurrentUserRole);
    const CURRENTORG = useSelector(selectCurrentOrg);
    const [loggedInUserDet, setLoggedInUserDet] = useState('');
    const [percentile, setPercentile] = useState(0);

    const navigate=useNavigate();
    useEffect(() => {
        getLoginUserDetails();
        //calCulatePercentageComplete(loggedInUserDet);
    }, [])

    const getLoginUserDetails = async () => {
        if(role==='user'){
        const response = await getUserDetailServices();
        if (response?.data?.data) {
            const user = response?.data?.data;
            setLoggedInUserDet(user);
            calCulatePercentageCompleteForUser(user);
        }
      }
      else if(role==='organization') {
        setLoggedInUserDet(CURRENTORG);
        calCulatePercentageCompleteForOrg(CURRENTORG);

      }

    }
    const calCulatePercentageCompleteForOrg = (user) => {
        const allFieldReceived = Object.entries(user);
        let notAdddedField = 0;
        const fieldUpdated = allFieldReceived?.filter((rec) => {
            return requiredListForOrganizationPercent?.findIndex((list) => list === rec[0]) >= 0
        });

        const fieldNotUpdated = requiredListForOrganizationPercent?.filter((needed) => {
            let index = fieldUpdated?.findIndex((a, index) => {
                return a[0] === needed
            })
            return index < 0
        });

        requiredListForOrganizationPercent.map((needed) => {
            allFieldReceived.map((userInfo) => {
                if (userInfo[0] === needed) {
                    if (userInfo[1] === null || userInfo[1] === '' || userInfo[1] === false || userInfo[1]?.length === 0 || (Object.keys(userInfo[1]).length === 0 && userInfo[1].constructor === Object)) {
                        notAdddedField += 1;
                    }
                }
            })
        })
        const requiredButNotAdded = notAdddedField + fieldNotUpdated?.length;
        const percent = (((requiredListForOrganizationPercent?.length)-requiredButNotAdded)/(requiredListForOrganizationPercent?.length)*100);
        setPercentile(Math.round(percent));


    }

    const calCulatePercentageCompleteForUser = (user) => {
        const allFieldReceived = Object.entries(user);
        let notAdddedField = 0;
        const fieldUpdated = allFieldReceived?.filter((rec) => {
            return requiredListForUserPercentage?.findIndex((list) => list === rec[0]) >= 0
        });

        const fieldNotUpdated = requiredListForUserPercentage?.filter((needed) => {
            let index = fieldUpdated?.findIndex((a, index) => {
                return a[0] === needed
            })
            return index < 0
        });

        requiredListForUserPercentage.map((needed) => {
            allFieldReceived.map((userInfo) => {
                if (userInfo[0] === needed) {
                    if (userInfo[1] === null || userInfo[1] === '' || userInfo[1] === false || userInfo[1]?.length === 0 || (Object.keys(userInfo[1]).length === 0 && userInfo[1].constructor === Object)) {
                        notAdddedField += 1;
                    }
                }
            })
        })
        const requiredButNotAdded = notAdddedField + fieldNotUpdated?.length;
        const percent = (((requiredListForUserPercentage?.length)-requiredButNotAdded)/(requiredListForUserPercentage?.length)*100);
        setPercentile(Math.round(percent));


    }
    return (
        <div className="widget-box">
            <div className="progress-arc-summary">
                <div className="progress-arc-wrap">
                    <div className="progress-arc">
                        <CircularProgressbar
                            className="profileProgress"
                            value={percentile}
                            text={`${percentile}%`}
                            styles={{
                                text: { fill: '#7a8a3a' },
                                background: { fill: '#7a8a3a' }
                            }} />
                    </div>
                </div>

                <div className="progress-arc-summary-info">
                    <p className="progress-arc-summary-title profile-circle-header">{t('profile_completion')}</p>
                    <p className="progress-arc-summary-subtitle">{role==='user'?`${loggedInUserDet?.firstName||''}  ${loggedInUserDet?.lastName|| ''}`:CURRENTORG?.name}</p>
                    <p className="progress-arc-summary-text">{percentile<100 ?<button className='button small primary' onClick={()=> navigate('/profile-settings')} >{t('complete_profile_text')}</button>:t('complete_profile_done')}</p>
                </div>
            </div>
        </div>
    )
}

export default ProfileCompletion;