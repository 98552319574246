import React from 'react';
import './roundCheckbox.style.css';

const RoundCheckbox = ({label , value , other, checkBoxClasses, input,...props}) => {
    return (<div className="roundCheckbox">
                {other ? <span className={`other ${checkBoxClasses}`}>
                    {label} {input}
                </span> : <label>
                    <input type="checkbox" value={value}  {...props}/>
                    <span>{label}</span>
                </label>}
                
            </div>)
}

export default RoundCheckbox;