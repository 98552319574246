import { Backdrop, CircularProgress } from "@mui/material";
const Loader = () => {
    return (
    <Backdrop
        sx={{ color: '#b2c955', zIndex: (theme) => theme.zIndex.drawer + 1,position:'relative',backgroundColor:'transparent' }}
        open={true}
    >
        <CircularProgress color="inherit" />
    </Backdrop>)
}

export default Loader;

