import React from "react";

const SectionHeader = ({pretitle , title, count, style}) => {
    return (
        <div className="section-header" style={style}>
            <div className="section-header-info">
                <p className="section-pretitle">{pretitle}</p>
                <h2 className="section-title">{title} <span className="highlighted-success">{count}</span> </h2>
            </div>
        </div>
    )
}

export default SectionHeader;