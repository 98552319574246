import React from "react";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import { getImageUrl } from "../../../utils/helpers";
const LogoCms = ({formValues, setPhoto, removeImage, selectedFiles,error, setFormValues}) => {
    const {t} = useTranslation();

    return (<>
        <SectionHeader pretitle="" title={t('Logo')}/>
        <Row>
            <Col lg={4} className="col-md-6">
            <div className="position-relative"> 
                    {((formValues?.headerLogo && formValues?._id) || selectedFiles?.headerLogo!==null) && 
                        <span className="cross_cms_btn" onClick={()=>removeImage('headerLogo')}><CloseIcon/></span>
                    }
                    <label htmlFor="upload-photo-headerLogo">
                        <input type="file" id="upload-photo-headerLogo"
                        style={{display:"none"}}
                        accept="image/png, image/gif, image/jpeg"
                                onChange={(event) => {
                                    setPhoto(event.target.files[0], 'headerLogo');
                                    setFormValues({ ...formValues, "headerLogo": event.target.files[0] || ""  })
                                }}
                            />
                        <div className="content">
                            <div className="upload-box cms_logo_area">
                            
                            {((formValues?.headerLogo && formValues?._id) || selectedFiles?.headerLogo!==null) ? 
                                <img src={selectedFiles?.headerLogo!==null ? URL.createObjectURL(selectedFiles?.headerLogo) : getImageUrl(formValues?._id, 'cms', formValues?.headerLogo)} className="user_attach_upload" />
                                :
                                <span className="up_load_btn">{t('errors.Upload')}</span>    }
                                <p className="upload-box-title">{t('Change_Header_Logo')}</p>
                                <p className="upload-box-text">{t('onboarding.size_minimum')}</p>
                            </div>
                            {error && formValues?.headerLogo === '' &&
                            <div className="error-text">
                                {t('Please_Upload_Header_Logo')}
                            </div>
                            }
                        </div>
                    </label>  
                        
                    </div>   
                                     
            </Col>

            <Col lg={4} className="col-md-6">            
                    <div className="position-relative">
                        {
                            ((formValues?.footerLogo && formValues?._id) || selectedFiles?.footerLogo!==null) && 
                            <span className="cross_cms_btn" onClick={()=>removeImage('footerLogo')} ><CloseIcon/></span>
                        }
                        <label htmlFor="upload-photo-footerLogo">
                            <input type="file" id="upload-photo-footerLogo"
                            style={{display:"none"}}
                            accept="image/png, image/gif, image/jpeg"
                                onChange={(event) => {
                                    setPhoto(event.target.files[0], 'footerLogo');
                                }}
                                />
                            <div className="content">
                                <div className="upload-box cms_logo_area footer_cms_logo">
                                    
                                    {
                                        ((formValues?.footerLogo && formValues?._id) || selectedFiles?.footerLogo!==null) ? 
                                        <img src={selectedFiles?.footerLogo!==null ? URL.createObjectURL(selectedFiles?.footerLogo) : getImageUrl(formValues?._id, 'cms', formValues?.footerLogo)} className="user_attach_upload" />
                                        :
                                        <span className="up_load_btn up_load_btn_white">{t('errors.Upload')}</span>
                                    }  
                                    <p className="upload-box-title">{t('Change_Footer_Logo')}</p>
                                    <p className="upload-box-text">{t('onboarding.size_minimum')}</p>
                                </div>
                            </div>
                        </label>  
                        
                    </div>           
            </Col>

            <Col lg={4} className="col-md-6">            
                    <div className="position-relative">
                        {
                            ((formValues?.feviconLogo && formValues?._id) || selectedFiles?.feviconLogo!==null) &&
                            <span className="cross_cms_btn" onClick={()=>removeImage('feviconLogo')} ><CloseIcon/></span>
                        }
                        <label htmlFor="upload-photo-feviconLogo">
                            <input type="file" id="upload-photo-feviconLogo"
                            style={{display:"none"}}
                            accept="image/png, image/gif, image/jpeg"
                                onChange={(event) => {
                                    setPhoto(event.target.files[0], 'feviconLogo');
                                }}
                                />
                            <div className="content"><div className="upload-box cms_logo_area">
                            
                            {((formValues?.feviconLogo && formValues?._id) || selectedFiles?.feviconLogo!==null) ?
                                <img src={selectedFiles?.feviconLogo!==null ? URL.createObjectURL(selectedFiles?.feviconLogo) : getImageUrl(formValues?._id, 'cms', formValues?.feviconLogo)} className="user_attach_upload" />:
                                <span className="up_load_btn">{t('errors.Upload')}</span>
                            }
                                <p className="upload-box-title">{t('Change_Favicon_Logo')}</p>
                                <p className="upload-box-text">{t('onboarding.size_minimum')}</p>
                                </div>
                            </div>
                        </label>  
                                                
                    </div>            
            </Col>
        </Row>
        
    </>)
}
export default LogoCms;
