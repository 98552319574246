import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Popup from "../commonComponents/popup";
import { Row, Col } from "react-bootstrap";
import ProfileHeader from "./profileHeader";
import { useLocation } from "react-router-dom";
import { getEventReport } from "../services/onBoarding.services";
import { getImageUrl } from "../utils/helpers";
import jsPDF from "jspdf";
const ReportPopup = ({ popupOpen, handleClose, eventId }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [eventDetail, setEventDetail] = useState(false);

    const [showEventHeader, setEventHeader] = useState(true);

    useEffect(() => {
        allData();
    }, [])

    const allData = async () => {
        await getReport();
    }


    const getReport = async () => {
        getEventReport(eventId)
            .then((response) => {
                if (response.data.status === 200) {
                    
                    const _data = response.data.data;
                    setEventDetail(_data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const generatePdf = () => {
        var doc = new jsPDF('p', 'pt', 'a2', false);
        doc.html(document.querySelector('#content'),
            {
                callback: function (pdf) {
                    pdf.save("Event Report")
                }
            }
        )
    };
    return (
        <div>
            <Popup handleClose={handleClose} open={popupOpen} className="targetPopup" modalBodyClasses={"reportModal"} modalHeadingClass={"reportHeading"}>
                <div id="content">
                    <>
                        <p  className="targetPopup reportPopupHeading">{t('event_report')}</p>   
                            <>
                                
                                    <ProfileHeader 
                                    coverImg='' img=''
                                    imageContainer={false}
                                    showEventHeader={showEventHeader}
                                    eventDetail={eventDetail}
                                    cover={getImageUrl(eventDetail?._id, 'event', eventDetail?.photo)}
                                    profileHeaderClasses="reportContent"
                                    widgetBoxClasses="reportPopupContent"
                                    />
        
                                <div className="widget-box reportForDownload" id="content">
                                    <p>{eventDetail?.eventReport?.comment}</p>
                                </div>
                            </>
                        
                    </>
                    <div>

                    </div>
                    <div id="content" className="reportBottomText">
                        <p>{eventDetail?.eventReport?.impact?t('event_report_intend'):''}</p>
                        <p>{t('validated_by')}</p>
                        <h5>{eventDetail?.eventReport?._id?.firstName} {eventDetail?.eventReport?._id?.lastName}</h5>
                    </div>

                </div>
                <div className="roportBtnBox">
                    <button className="button primary inviteOkBtn" onClick={generatePdf}> {t('download')} </button>
                </div>
                
            </Popup>
        </div>)

}

export default ReportPopup;