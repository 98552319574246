import React, {useEffect, useState} from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BarsLoader from "../commonComponents/barsLoader";
import moment from "moment";
import dayjs from 'dayjs';
import { getLoginActivityService } from "../services/activity.service";
import { getImageUrl } from "../utils/helpers";
import AvatarImgBox from "../commonComponents/AvatarImgBox/avatarImgBox";
import { AvatarImg } from "../commonComponents/Images";
import { AccessTimeFilledTwoTone } from "@mui/icons-material";
import ReactPagination from "../commonComponents/reactPagination";


const ActivityLogin = () => {
 
 const [activityList,setActivityList]=useState('');
 const [page,setPage]=useState(0);
 const limit=10;
 const [loading,setLoading]=useState(false);
 const [currentPageNo,setCurrentPageNo]=useState(0);
 const { t } = useTranslation();
 const [totalPage, setTotalPage] = useState(0);


 useEffect(() => {
    getAllActivityList(0);
}, [])

const getAllActivityList = async (currentPage) => {
  setLoading(true);
    setCurrentPageNo(currentPage);
        const response = await getLoginActivityService(currentPage,limit);
        if(response?.status===200){
          setActivityList(response?.data?.data?.data);
          let pageCount = (response?.data?.data?.totalCount) / limit;
          setTotalPage(Math.ceil(pageCount));
          setLoading(false);
        } 
    }


const handlePageClick = (data) => {
    let page = data?.selected;
    getAllActivityList(page);
}

    return (<>
     
        <div className="activityLoginComp section-filters-bar v6 myProfileSettings">
          <div className="section-filters-bar-actions">
                {t('My_Activity')}
          </div>
        </div>
        <Row id="activityLogComp" className='grid'>
          <Col md={12} className='grid-column'>
            <div className='widget-box'>
             
              {loading?<BarsLoader/>:<>

                            <div className="container-fluid">
                              { activityList?.length>0 ? activityList.map((act,index)=>{
                                   return <div className='row align-items-center pb-2 mx-lg-3' key={index}>
                                    
                                      {/* {console.log("updated by details::",t(`ACTIVITY.${act.subject}`).replace('${name}',  `${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`).replace('${post}', `${(act?.additionalData?.post)?.substring(0, 20) + '..'}`))} */}
                                     <div className='col-12 col-sm-2 col-xxl-1 mb-2 mb-lg-0'>
                                        <AvatarImgBox
                                                      img={(act?.updatedBy?.profilePicture && act?.updatedBy?.profilePicture !='') ? getImageUrl(act?.updatedBy?._id, 'userProfile', act?.updatedBy?.profilePicture) : AvatarImg}
                                                                    />
                                     </div>
                                     <div className='col-12 col-sm-7 col-xxl-8 mb-2 mb-lg-0'>
                                         {(act?.subject==='PostCreate-administrator' || act?.subject==='PostEdit-administrator' || act?.subject==='PostVisibility-administrator' || act?.subject==='PostHide-administrator' || act?.subject==='PostDelete-administrator' || act?.subject==='PostHide-user' || act?.subject==='PostDelete-user' || act?.subject==='PostCreate-organization' || act?.subject==='PostEdit-organization' || act?.subject==='PostDelete-organization')?
                                          <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  `${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`).replace('${post}', `${(act?.additionalData?.post)?.substring(0, 50) + '..'}`)}</>
                                          
                                          :(act?.subject==='targetCreate' || act?.subject==='targetInvites' || act?.subject==='targetUpdate')?
                                          <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  act?.updatedBy?.firstName?`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`:`${act?.updatedBy?.name}`).replace('${target}', `${(act?.additionalData?.targetMessage) }`)}</>
                                        
                                          :(act?.subject==='organizationUserStatusChange' )?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}', act?.updatedBy?.firstName?`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`:`${act?.updatedBy?.name}`).replace('${status}', `${(act?.additionalData?.status) }`)}</>
                                         
                                         :(act?.subject==='organizationstatus-administrator')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  act?.updatedBy?.firstName?`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`:`${act?.updatedBy?.name}`).replace('${status}', `${(act?.additionalData?.isVerified?'active':'inActive') }`)}</>
                                         

                                         :(act?.subject==='organizationcreate-administrator' || act?.subject==='organizationstatus-administrator' || act?.subject==='organizationroleupdate-administrator')?
                                          <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  act?.updatedBy?.firstName?`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`:`${act?.updatedBy?.name}`).replace('${orgName}',act?.organizationInfo?.name)}</>

                                          :(act?.subject==='eventCreate' || act?.subject==='eventUpdate' ||act?.subject==='eventInvites')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  `${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`).replace('${event}', `${(act?.eventInfo?.title)}`)}</>
                                        
                                         :(act?.subject==='Testimonialupdate-administrator' || act?.subject==='Testimonialcreate-administrator' || act?.subject==='Testimonialdelete-administrator'|| act?.subject==='CMSUpdate-administrator' || act?.subject==='CMSInsert-administrator')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  `${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`)}</>
                                        
                                         :(act?.subject==='Blogcreate-administrator' || act?.subject==='Blogupdate-administrator' || act?.subject==='Blogdelete-administrator' )?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  `${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`)}</>

                                         :( act?.subject==='eventRemoveUser' || act?.subject==='eventRemoveOrganization')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  `${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`).replace('${event}', `${(act?.eventInfo?.title)}`).replace('${user}',`${act?.userInfo?.firstName} ${act?.userInfo?.lastName}`)}</>
                                        
                                        //  :(act?.subject==='eventBlockUser' || act?.subject==='eventUnBlockUser' )?
                                        //  <>{t(`ACTIVITY.${act?.organizationInfo? act.subject+'-org':act.subject+"-user"}`).replace('${name}',  `${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`).replace('${event}', `${(act?.eventInfo?.title)}`).replace('${user}',`${act?.userInfo?.firstName} ${act?.userInfo?.lastName}`).replace('${org}',`${act?.organizationInfo?.name}`)}</>
                                         :(act?.subject==='eventBlockUser' || act?.subject==='eventUnBlockUser' )?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  `${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`).replace('${event}', `${(act?.eventInfo?.title)}`).replace('${user}',`${act?.userInfo?.firstName} ${act?.userInfo?.lastName}`)}</>
                                        
                                         :(act?.subject==='organizationInfoUpdate')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  act?.updatedBy?.firstName?`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`:`${act?.updatedBy?.name}`).replace('${orgName}',act?.organizationInfo?.name)}</>

                                         :(act?.subject==='organizationroleupdate-organization')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  act?.updatedBy?.firstName?`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`:`${act?.updatedBy?.name}`).replace('${role}',t(`roles.${act.additionalData?.role}`))}</>

                                         :(act?.subject==='organizationEmployeeRequestApprove' || act?.subject==='organizationEmployeeRequestReject' || act?.subject==='organizationEmployeeRequestRemove' )?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  act?.updatedBy?.firstName?`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`:`${act?.updatedBy?.name}`).replace('${user}',`${act?.userInfo?.firstName} ${act?.userInfo?.lastName}`)}</>
                                        
                                         :( act?.subject==='organizationReferAndInvites')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  act?.updatedBy?.firstName?`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`:`${act?.updatedBy?.name}`).replace('${user}',`${act?.userInfo?.firstName} ${act?.userInfo?.lastName}`)}</>
                                         //For eventValidatorValidateEvent
                                         :( act?.subject==='eventValidatorValidateEvent')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',  act?.eventInfo?.title?`${act?.eventInfo?.title} `:'N.A').replace('${user}', `${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName}`)}</>
                                         //For event reminder
                                         :( act?.subject==='eventRemindMonthly')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${event}',act?.eventInfo?.title?`${act?.eventInfo?.title} `:'N.A').replace('${time}', act?.eventInfo?.startTime)}</>
                                         :( act?.subject==='targetDeleted-organization')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',act?.additionalData?.targetMessage?`${act?.additionalData?.targetMessage} `:'N.A')}</>
                                         
                                         :( act?.subject==='PostReaction-administrator')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',`${act?.userInfo?.firstName} ${act?.userInfo?.lastName} `).replace('${post}', act?.additionalData?.content)}</>
                                         :( act?.subject==='PostFeature-organization')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName} `).replace('${post}', act?.additionalData?.post)}</>
                                         
                                         :( act?.subject==='PostComment-administrator')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',`${act?.userInfo?.firstName} ${act?.userInfo?.lastName} `).replace('${post}', act?.additionalData?.post)}</>
                                         :( act?.subject==='PostShare-administrator')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',`${act?.userInfo?.firstName} ${act?.userInfo?.lastName} `).replace('${post}', act?.additionalData?.post)}</>
                                         :( act?.subject==='PostReplyComment-administrator')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',`${act?.userInfo?.firstName} ${act?.userInfo?.lastName} `)}</>
                                         :( act?.subject==='PostReaction-organization')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName} `).replace('${post}', act?.additionalData?.content)}</>
                                         :( act?.subject==='PostComment-organization')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName} `).replace('${post}', act?.additionalData?.post)}</>
                                         :( act?.subject==='PostShare-organization')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName} `).replace('${post}', act?.additionalData?.post)}</>
                                         :( act?.subject==='PostHide-organization')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName} `).replace('${post}', act?.additionalData?.post)}</>
                                         :( act?.subject==='PostReplyComment-organization')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName} `).replace('${post}', act?.additionalData?.postTitle)}</>
                                         :( act?.subject==='targetInvite-organization-accept' || act?.subject==='targetInvite-organization-cancel' )?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName} `).replace('${target}', act?.additionalData?.targetMessage)}</>
                                         :( act?.subject==='eventInvite-organization-accept' || act?.subject==='eventInvite-organization-cancel' )?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName} `).replace('${event}', act?.eventInfo?.title)}</>
                                         :( act?.subject==='markValidate-organization')?
                                         <>{t(`ACTIVITY.${act.subject}`).replace('${name}',`${act?.updatedBy?.firstName} ${act?.updatedBy?.lastName} `).replace('${event}', act?.eventInfo?.title)}</>
                                         :"N.A"
                                         }
                                         </div>
                                         <div className="col-12 col-sm-3">
                                         {act?.createdAt&& moment(act?.createdAt).format('h:mm A')}, {act?.createdAt&& moment(act?.createdAt).format('DD MMM, YYYY')}
                                         </div>
                                     <div>{act?.startTime && dayjs(act?.startTime).format('h:mm A  ')}</div>
                                     {/* <td>{act?.endTime && dayjs(AccessTimeFilledTwoTone?.endTime).format('h:mm A  ')}</td>
                                     <td>{act?.timezone ? act.timezone:""}</td> */}
                                   </div>
                              
                                  })
                                :<h4>No activity has been performed</h4>}
                               
                            </div>
                </>}
              </div>

          </Col> 
        
            <ReactPagination pageCount={totalPage} onPageChange={handlePageClick} />

        </Row> 
      
    </>);
}

export default ActivityLogin;