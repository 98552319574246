import React from "react";
import { Row, Col } from "react-bootstrap";
import {
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@mui/material'
import { useTranslation } from "react-i18next";
const ContactPrivacy = ({ setting, onEdit, onChangeSetting, canEdit, onSave }) => {
    const { t } = useTranslation();

    return (<Row className="grid">
        <div className="grid-column myProfileSettings">
            <div className="widget-box area-widget">
                <p className="widget-box-title">{t('onboarding.contactInfo')}
                    <span style={{ float: "right" }}>
                        {!canEdit ?
                            <img onClick={onEdit} src="/Assets/Edit.png" alt="" />
                            : <img onClick={onSave} src="/Assets/Save.png" alt="" />
                        }
                    </span>
                </p>
                <div className="widget-box-content">
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '98%' },
                        }}
                        noValidate
                        autoComplete="off"
                        className="userdetail-form account_info_org_area mobile_profile_margin privacy_parent11"
                        >
                        <Row className="privacy_subcategory22">
                            <Col md={7}>
                                <span className="span_heading">
                                    <span className="span_heading1">{t('Who_can_see_your_mobile_number')}</span>
                                </span>
                            </Col>
                            <Col md={5}  className="privacy_setting92">
                                <FormControl fullWidth>
                                    <InputLabel id="co-country-select-label">{t('Who_can_see_your_mobile_number')}</InputLabel>
                                    <Select
                                        labelId="co-country-select-label"
                                        value={setting?.whoCanSeeMobile || ''}
                                        disabled={!canEdit}
                                        label={t('Who_can_see_your_mobile_number')}
                                        onChange={(e) => onChangeSetting('whoCanSeeMobile', e.target.value)}>
                                        <MenuItem value="private">{t('Private_only_me')}</MenuItem>
                                        <MenuItem value="everyone">{t('Everyone')}</MenuItem>
                                        <MenuItem value="friends">{t('friends')}</MenuItem>
                                        <MenuItem value="friends_of_friends">{t('interest.friendOfFriend')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>

                            <Col md={7}>
                                <span className="span_heading">
                                    <span className="span_heading1">{t('Who_can_see_your_email_id')}</span>
                                </span>
                            </Col>
                            <Col md={5} className="privacy_setting92">
                                <FormControl fullWidth >
                                    <InputLabel id="co-country-select-label">{t('Who_can_see_your_email_id')}</InputLabel>
                                    <Select
                                        labelId="co-country-select-label"
                                        value={setting?.whoCanSeeEmail || ''}
                                        disabled={!canEdit}
                                        label={t('Who_can_see_your_email_id')}
                                        onChange={(e) => onChangeSetting('whoCanSeeEmail', e.target.value)}
                                    >
                                        <MenuItem value="private">{t('Private_only_me')}</MenuItem>
                                        <MenuItem value="everyone">{t('Everyone')}</MenuItem>
                                        <MenuItem value="friends">{t('friends')}</MenuItem>
                                        <MenuItem value="friends_of_friends">{t('interest.friendOfFriend')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>

                            <Col md={7}>
                                <span className="span_heading">
                                    <span className="span_heading1">{t('Who_can_see_your_address')}</span>
                                </span>
                            </Col>
                            <Col md={5} className="privacy_setting92">
                                <FormControl fullWidth>
                                    <InputLabel id="co-country-select-label">{t('Who_can_see_your_address')}</InputLabel>
                                    <Select
                                        labelId="co-country-select-label"
                                        value={setting?.whoCanSeeAddress || ''}
                                        disabled={!canEdit}
                                        label={t('Who_can_see_your_address')}
                                        onChange={(e) => onChangeSetting('whoCanSeeAddress', e.target.value)}>
                                        <MenuItem value="private">{t('Private_only_me')}</MenuItem>
                                        <MenuItem value="everyone">{t('Everyone')}</MenuItem>
                                        <MenuItem value="friends">{t('friends')}</MenuItem>
                                        <MenuItem value="friends_of_friends">{t('interest.friendOfFriend')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                    </Box>
                </div>
            </div>
        </div>

    </Row>)
}

export default ContactPrivacy;