import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SectionHeader from '../../../commonDashboardComp/sectionHeader';
import { Box } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
// Draft Js imports
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, convertToRaw } from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
// Draft Js imports
const TermsConditionsCms = ({formValues, setFormValues}) => {
  //editor state for website terms and conditions
  const [editorState, setEditorState] = useState();
  //editor state for organization terms and conditions
  const [orgTCeditorState, setOrgTcEditorState] = useState();
  //editor state for validator terms and conditions
  const [validatorTceditorState, setValidatorTcEditorState] = useState();
  //editor state for report disclaimer
  const [reportDisclaimerEditorState, setReporterDisclaimerEditorState] = useState();
  
  const saveEditorContent =(activeEditor) =>{
    switch(activeEditor){
      case 'web':
        const webcontent = editorState.getCurrentContent();
        setFormValues({
            ...formValues,
            termsCondition: {
            description: convertToRaw(webcontent),
            title: 'N.A',
            },
        })
        break;
      case 'org':
        const orgcontent = orgTCeditorState.getCurrentContent();
        setFormValues({
            ...formValues,
            orgTermsConditions: {
            description: convertToRaw(orgcontent)
            },
        })
        break;
      case 'validator':
        const validatorcontent = validatorTceditorState.getCurrentContent();
        setFormValues({
            ...formValues,
            validatorTermsConditions: {
            description: convertToRaw(validatorcontent)
            },
        })
        break;
      case 'disclaimer':
        const disclaimercontent = reportDisclaimerEditorState.getCurrentContent();
        setFormValues({
            ...formValues,
            reportDisclaimer: {
            description: convertToRaw(disclaimercontent)
            },
        })
        break;
    }
      
  }
  //For website terms conditions editor data
  const [ediorData, setEditorData] = useState(EditorState.createEmpty());
  //For organization terms conditions editor data
  const [orgediorData, setOrgEditorData] = useState(EditorState.createEmpty());
  //For validator terms conditions editor data
  const [validatorEdiorData, setValidatorEditorData] = useState(EditorState.createEmpty());
  //For report disclaimer editor data
  const [reportDisclaimerEdiorData, setReportDisclaimerEditorData] = useState(EditorState.createEmpty());
  
  //For website terms conditions editor data fetch count
  const [dataFetchCount, setDataFetchCount] = useState(0);
  
  //For organization terms conditions editor data fetch count
  const [dataOrgFetchCount, setOrgDataFetchCount] = useState(0);
  
  //For validator terms conditions editor data fetch count
  const [validatorDataFetchCount, setValidatorDataFetchCount] = useState(0);
  
  //For report disclaimer editor data fetch count
  const [reportDisclaimerFetchCount, setReportDisclaimerFetchCount] = useState(0);
  
  //For web terms and conditions editor  
  useEffect(()=>{
        if(formValues?.termsCondition?.description){
            setEditorData(formValues?.termsCondition?.description);
            setDataFetchCount((prevState)=>{
                return prevState+1;
            });
        }
  },[formValues?.termsCondition?.description]);

  useEffect(()=>{
        if(formValues?.termsCondition?.description && dataFetchCount == 1){
            setEditorState(EditorState.createWithContent(convertFromRaw(formValues?.termsCondition?.description)));    
        }
  },[dataFetchCount])
  
  //For org terms and conditions editor  
  useEffect(()=>{
    if(formValues?.orgTermsConditions?.description){
        setOrgEditorData(formValues?.orgTermsConditions?.description);
        setOrgDataFetchCount((prevState)=>{
            return prevState+1;
        });
    }
  },[formValues?.orgTermsConditions]);

  useEffect(()=>{
    if(formValues?.orgTermsConditions?.description && dataOrgFetchCount == 1){
        setOrgTcEditorState(EditorState.createWithContent(convertFromRaw(formValues?.orgTermsConditions?.description)));    
    }
  },[dataOrgFetchCount])

  //For validator terms and conditions editor  
  useEffect(()=>{
    if(formValues?.validatorTermsConditions?.description){
        setValidatorEditorData(formValues?.validatorTermsConditions?.description);
        setValidatorDataFetchCount((prevState)=>{
            return prevState+1;
        });
    }
  },[formValues?.validatorTermsConditions?.description]);

  useEffect(()=>{
    if(formValues?.validatorTermsConditions?.description && validatorDataFetchCount == 1){
        setValidatorTcEditorState(EditorState.createWithContent(convertFromRaw(formValues?.validatorTermsConditions?.description)));    
    }
  },[validatorDataFetchCount])

  //For report disclaimer editor  
  useEffect(()=>{
    if(formValues?.reportDisclaimer?.description){
        setReportDisclaimerEditorData(formValues?.reportDisclaimer?.description);
        setReportDisclaimerFetchCount((prevState)=>{
            return prevState+1;
        });
    }
  },[formValues?.reportDisclaimer?.description]);

  useEffect(()=>{
    if(formValues?.reportDisclaimer?.description && reportDisclaimerFetchCount == 1){
        setReporterDisclaimerEditorState(EditorState.createWithContent(convertFromRaw(formValues?.reportDisclaimer?.description)));    
    }
  },[reportDisclaimerFetchCount])

  const { t } = useTranslation();
  return (
    <>
      {/* Terms and conditions block for website */}
      <SectionHeader pretitle="" title={t('termsConditions_website')} />
      <Box
        component={'form'}
        noValidate
        autoComplete="off"
        className="userdetail-form mobile_profile_margin p-0 aboutCmsComp"
      >
        <Row>
          <Col lg={12}>
            <Row className="grid">
              <div className="grid-column myProfileSettings">
                <div className="widget-box area-widget">
                  <div className="widget-box-content">
                    <Col md={12} className="cmsEditorContainer cms_about_textarea">
                    <Editor
                    onChange={()=>{saveEditorContent('web')}}
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    />
                    </Col>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Box>
      {/* Terms and conditions block for organization */}
      <SectionHeader pretitle="" title={t('termsConditions_organization')} />
      <Box
        component={'form'}
        noValidate
        autoComplete="off"
        className="userdetail-form mobile_profile_margin p-0 aboutCmsComp"
      >
        <Row>
          <Col lg={12}>
            <Row className="grid">
              <div className="grid-column myProfileSettings">
                <div className="widget-box area-widget">
                  <div className="widget-box-content">
                    <Col md={12} className="cmsEditorContainer cms_about_textarea">
                    <Editor
                    onChange={()=>{saveEditorContent('org')}}
                    editorState={orgTCeditorState}
                    onEditorStateChange={setOrgTcEditorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    />
                    </Col>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Box>
      {/* Terms and conditions block for validator */}
      <SectionHeader pretitle="" title={t('termsConditions_validator')} />
      <Box
        component={'form'}
        noValidate
        autoComplete="off"
        className="userdetail-form mobile_profile_margin p-0 aboutCmsComp"
      >
        <Row>
          <Col lg={12}>
            <Row className="grid">
              <div className="grid-column myProfileSettings">
                <div className="widget-box area-widget">
                  <div className="widget-box-content">
                    <Col md={12} className="cmsEditorContainer cms_about_textarea">
                    <Editor
                    onChange={()=>{saveEditorContent('validator')}}
                    editorState={validatorTceditorState}
                    onEditorStateChange={setValidatorTcEditorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    />
                    </Col>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Box>
      {/* Report Disclaimer */}
      <SectionHeader pretitle="" title={t('reportDisclaimer')} />
      <Box
        component={'form'}
        noValidate
        autoComplete="off"
        className="userdetail-form mobile_profile_margin p-0 aboutCmsComp"
      >
        <Row>
          <Col lg={12}>
            <Row className="grid">
              <div className="grid-column myProfileSettings">
                <div className="widget-box area-widget">
                  <div className="widget-box-content">
                    <Col md={12} className="cmsEditorContainer cms_about_textarea">
                    <Editor
                    onChange={()=>{saveEditorContent('disclaimer')}}
                    editorState={reportDisclaimerEditorState}
                    onEditorStateChange={setReporterDisclaimerEditorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    />
                    </Col>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Box>
    </>
  );
};

export default TermsConditionsCms;
