import React, {useEffect, useState} from "react";
import TabNavigation from "../../../commonDashboardComp/tabNavigation";
import { useTranslation } from "react-i18next";
import ValidatorAllDetails from "./validatorAllDetails";
import ValidatorDateByDetail from "./validatorDateByDeatil";
import ValidatorAvailibilty from "./validatorAvailibilty";

const ValidatorInfo = () => {
  const { t } = useTranslation();
  const [tab,setTab]=useState(1);
  const tabs = [t('My_Availibilty'),t('Add_Availibilty'),t('My_Validator_Details')];
 
  useEffect(() => {
   //validatorInformationAccordingToDate();
}, [tab]);

    return (<>
       <TabNavigation tabs={tabs} setTab={setTab} activeTab={tab}  showInput={false}/>
      
       {tab===1 &&
         <ValidatorAvailibilty/>
       }
      
        {tab===2&&
          <ValidatorDateByDetail/>
        }
        {
          tab===3 && 
          <ValidatorAllDetails/>
        }
    </>);
}

export default ValidatorInfo;