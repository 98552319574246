import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { LogoWhite } from "../../../commonComponents/Images";
import './navbar.style.css';
import 'draft-js/dist/Draft.css';
import i18next from "i18next";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { SearchIcon } from "../../../commonComponents/Images";
//import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Link } from "react-router-dom";
import { AvatarImg } from "../../../commonComponents/Images";
import { useSelector } from "react-redux";
import { selectCurrentUser, selectCurrentOrg, selectCurrentOrgRole, selectCurrentUserRole, selectSearchText } from "../../../Reducer/user/user.selector";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getUserDetailsService, getUserOrgStatusService } from "../../../services/auth.services";
import { useDispatch } from "react-redux";
import { setCurrentRole, setCurrentUser, setCurrentOrg, setCurrentOrgRole, setSearchText } from "../../../Reducer/user/user.action";
import BarsLoader from "../../../commonComponents/barsLoader";
import {
    getNotificationCountService,
    getNotificationListService,
    markAllNotificationsSeenServce,
    markNotificationSeenService
} from "../../../services/onBoarding.services";
import { setObjectInStore, removeStoreItem, getObjectFromStore } from "../../../storage/Storage";
import { generateOrgAuthTokenService, getOrgDetailsService } from "../../../services/orgAuth.services";
import { allowOnlyLetters, allowOnlyLettersNumbers, restrictForwardAndBackwardSlash, showSingleToastSuccess, showToastSuccess, timeFormat, dateTimeEventFormat, capitalAllFirstLetter, restrictSpecialCharacter } from "../../../utils/helpers";
import { getTimeGap } from "../../../utils/helpers";
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { getImageUrl } from "../../../utils/helpers";
import { selectUserWorkingOrg, selectNotificationCount } from "../../../Reducer/user/user.selector";
import { setUserWorkingOrgData, setNotificationCount } from "../../../Reducer/user/user.action";
const Navbar = () => {
    const OrgImg = '/Assets/org1@2x.png';
    const navigate = useNavigate();
    const searchText = useSelector(selectSearchText);
    const notificationWrapper = useRef(null)
    const settingWrapper = useRef(null)
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const current_user = useSelector(selectCurrentUser);
    const current_org = useSelector(selectCurrentOrg);
    const role = useSelector(selectCurrentUserRole);
    const orgRole = useSelector(selectCurrentOrgRole);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchKey, setSearchKey] = useState(searchText);
    const userOrg = useSelector(selectUserWorkingOrg);
    const notificationCount = useSelector(selectNotificationCount)
    // const [notificationCounts, setNotificationCounts] = useState(notificationCount);

    const handleClickOutside = (event) => {
        if (notificationWrapper.current && !notificationWrapper.current.contains(event.target)) {
            setShowNotifications(false);
        }
        if (settingWrapper.current && !settingWrapper.current.contains(event.target)) {
            setShowSettings(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            // dispatch(setSearchText(''))
        };
    }, []);
    //For search
    useEffect(() => {
        setSearchKey(searchText)
    }, [searchText])

    useEffect(() => {
        getUserDetail();
    }, [role])
    useEffect(() => {
        getNotificationCount();
    }, [role, notificationCount])
    useEffect(() => {
        document.body.classList.add('dashboard');
    }, []);

    const getUserDetail = async () => {
        if (getObjectFromStore('token') === null) {
            navigate('/logout');
        }
        getUserDetailsService()
            .then(async (data) => {
                if (data?.data?.status === 200) {
                    if (data?.data?.data) {
                        const user = data?.data?.data;
                        await dispatch(setCurrentUser(user));
                        i18next.changeLanguage(data?.data?.data?.language || 'en');
                    }
                }
                else {
                    //navigate('/logout');
                }
            })
            .then(() => {
                getUserOrganizationStatus();
            })
            // .then(() => {
            //     getOrgToken();
            // })
            .catch((error) => {
                if (error?.data?.status !== 200) {
                    //navigate('/logout');
                }
            })
    }
    const getNotificationCount = () => {
        getNotificationCountService()
            .then((data) => {
                if (data?.data?.status === 200) {
                    dispatch(setNotificationCount(data?.data?.data));
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getNotificationList = () => {
        setIsLoading(true);
        getNotificationListService(0, 10)
            .then((data) => {
                if (data?.data?.status === 200) {
                    setNotificationList(data?.data?.data?.data);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const showHideNotificationBox = () => {
        setShowNotifications(!showNotifications);
        setShowSettings(false)
        if (!showNotifications) {
            getNotificationList();
        }
    }

    const markAllNotificationsRead = () => {
        markAllNotificationsSeenServce(0, 10)
            .then((data) => {
                if (data?.data?.status === 200) {
                    // showToastSuccess(t(`apiMessages.ALL_NOTI_MARKED_SEEN`));
                    showSingleToastSuccess(t(`apiMessages.ALL_NOTI_MARKED_SEEN`));
                    getNotificationList();
                    dispatch(setNotificationCount(0))
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const markNotificationRead = (id) => {
        let params = {
            id: id,
            seen: true
        }
        markNotificationSeenService(params)
            .then((data) => {
                getNotificationCount();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const navigateNotification = (e, type, noti) => {
        e.preventDefault()
        markNotificationRead(noti?._id);
        setShowNotifications(false);

        if (type === 'user') {
            showUser(noti?.senderId)
        }
        else {
            if (noti?.subject === 'userAcceptRequest' || noti?.subject === 'userSentRequest' || noti?.subject === 'organizationFollow') {
                showUser(noti?.senderId)
            }
            else if (noti?.subject === 'organizationReferAndInvites') {
                let pathName = noti?.organizationInfo?.name.replaceAll(' ', '_');
                navigate(`/organization/${window.btoa(noti?.organizationInfo?._id)}`, { state: { orgId: noti?.organizationInfo?._id } })
            }
            else if (noti?.subject === 'eventInvites' || noti?.subject === 'eventUpdate' || noti?.subject === 'eventValidatorRequestCancelled' || noti?.subject === 'eventValidatorRequestAccept' || noti?.subject === 'eventValidate') {
                navigate('/view-event', { state: { eventId: noti?.eventInfo?._id } })
            }
            else if (noti?.subject === 'targetInvites' || noti?.subject === 'targetUpdate') {
                navigate('/target', { state: { tab: 'invited' } })
            }
            else if (noti?.subject === 'userOrganizationCurrentWorkingStatus') {
                navigate('/followers', { state: { tab: 'empReq' } })
            }
            else if (noti?.subject === 'PostComment-user' || noti?.subject === 'PostCreate-user' || noti?.subject === 'PostComment-administrator' || noti?.subject === 'PostReplyComment-user' || noti?.subject === 'PostReplyComment-administrator' || noti?.subject === 'PostCreate-administrator' || noti?.subject === 'PostReaction-organization' || noti?.subject === 'PostReaction-user' || noti?.subject === 'PostReaction-administrator' || noti?.subject === 'PostShare-user' || noti?.subject === 'PostShare-organization' || noti?.subject === 'PostShare-administrator' || noti?.subject === 'PostEdit-user' || noti?.subject === 'PostEdit-organization' || noti?.subject === 'PostEdit-administrator') {
                navigate('/post', { state: { postId: noti?.additionalData?.postId } })
            }
            else if (noti?.subject === 'loginUnAuthorized') {
                navigate('/account-settings');
            }
            else if (noti?.subject === 'eventValidatorValidateEvent') {
                navigate('/events-validate', { state: { tab: 'invited' } });
            }
        }
    }

    const showUser = (id) => {
        let encrptedId = window.btoa(id);
        navigate(`/profile/${encrptedId}`, { state: { userId: id } })
    }

    const getOrgToken = async () => {
        //e.preventDefault();
        if (getObjectFromStore('orgToken') !== null) {
            getOrgDetails();
        }
        else {
            let OrgId = current_user?.pages[0]?.organizationId;
            if (OrgId !== null && OrgId !== undefined) {
                //generate Orgnization Token 
                let params = {
                    organizationId: OrgId
                }

                let response = await generateOrgAuthTokenService(params);
                if (response?.data?.status === 200) {
                    await setObjectInStore("orgToken", response?.data?.data?.accessToken);
                    await setObjectInStore('org_refresh_token', response?.data?.data?.refreshToken);
                    await getOrgDetails();
                }
            }
        }
    }

    const getOrgDetails = async () => {
        try {
            let orgId = current_user?.pages[0]?.organizationId;
            let orgRole = current_user?.pages[0]?.role;
            if (orgId !== null || orgId !== undefined) {
                getOrgDetailsService(orgId)
                    .then(async (data) => {
                        if (data?.data?.status === 200) {
                            if (data?.data?.data?._id !== orgId) {
                                setObjectInStore('orgToken', null);
                                setObjectInStore('org_refresh_token', null);
                                // getOrgToken();
                            }
                            else {
                                await dispatch(setCurrentOrg(data?.data?.data));
                                await dispatch(setCurrentOrgRole(orgRole))
                            }
                        }
                        else {
                            // console.log(data);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const getUserOrganizationStatus = () => {
        try {
            getUserOrgStatusService()
                .then((data) => {
                    if (data?.data?.status === 200 && data?.data?.data !== '') {
                        dispatch(setUserWorkingOrgData(data?.data?.data))
                        //setUserOrg(data?.data?.data);
                    }
                    else {
                        dispatch(setUserWorkingOrgData({}))
                    }
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const setUpOrganizationHandler = async () => {
        await getOrgToken();
        await setObjectInStore("role", 'organization');
        await dispatch(setCurrentRole('organization'));
        showSingleToastSuccess(t('Success_You_are_now_viewing_Organization_Panel'))
        navigate('/voxbox');
    }

    const setUpUserPanelHandler = async () => {
        await setObjectInStore("role", '');
        if (current_user?.role === 'administrator') {
            await dispatch(setCurrentRole('superAdmin'));
            showSingleToastSuccess(t('Success_You_are_now_viewing_Admin_Panel'))
        } else {
            await dispatch(setCurrentRole('user'));
            showSingleToastSuccess(t('Success_You_are_now_viewing_User_Panel'))
        }
        navigate('/voxbox');
    }

    return (
        <header className="container-fluid justify-content-md-center header">
            <div className="row align-items-center">
                <div className={role === 'user' ? 'header-actions col-6 col-sm-4 col-md-8 col-lg-5 col-xl-6 order-1' : 'header-actions col-6 col-sm-4 col-md-8 col-lg-6 col-xl-7 order-1'}>
                    <div className="header-brand">
                        <Link to="/">
                            <div className="logo">
                                <img src={LogoWhite} alt="" />
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="header-actions search-bar col-11 col-sm-11 col-md-12 col-lg-4 col-xl-4 offset-1 offset-sm-1 offset-md-0 offset-lg-0 mt-1 mt-lg-2 mb-lg-1 mt-xl-0 mb-2 mb-xl-0 order-3 order-lg-2" style={{ position: 'relative' }}>
                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                    >
                        <InputBase
                            value={searchKey}
                            // value={searchText}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    
                                    if (searchKey !== "") {
                                        dispatch(setSearchText(searchKey))
                                        navigate('/allGlobalSearchComp')
                                        e.preventDefault();
                                    }
                                }
                            }}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder={t('roles.Search_here_for_people_or_groups')}
                            inputProps={{ 'aria-label': t('roles.Search_here_for_people_or_groups') }}
                            id="search-main" name="search_main"
                            onChange={(e) => {
                                let value = restrictSpecialCharacter(e.target.value)
                                if(e.target.value !== " " && e.target.value ) {
                                    setSearchKey(value)
                                } else {
                                    setSearchKey('');
                                }
                                // setSearchKey(e.target.value)
                                //dispatch(setSearchText(e.target.value))
                                e.preventDefault();
                            }
                            }
                        />
                        <IconButton
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(setSearchText(searchKey))
                                if (searchKey !== '') {
                                    navigate('/allGlobalSearchComp')
                                }

                                // if(!!searchText)
                                // navigate('/allGlobalSearchComp')
                            }}
                            type="button" sx={{ p: '10px', width: 'auto' }} aria-label="search">
                            <img src={SearchIcon} />
                        </IconButton>
                    </Paper>
                </div>

                <div className={role === 'user' ? 'col-6 col-sm-8 col-md-4 col-lg-3  col-xl-2 order-2 order-lg-3' : 'col-6 col-sm-8 col-md-4 col-lg-2  col-xl-1 order-2 order-lg-3'}>
                    <div className="row justify-content-end justify-content-lg-center justify-content-xl-end header-actions mobile_nav">


                        <div className="action-list dark col-2 justify-content-end">
                            <div className="action-list-item-wrap" style={{ position: 'relative' }} ref={notificationWrapper}>
                                <div className="action-list-item unread header-dropdown-trigger" title={t('notifications')}
                                    onClick={(e) => showHideNotificationBox()}>
                                    <NotificationsNoneIcon className="action-list-item-icon icon-notification" />
                                    {((role !== 'superAdmin' && role === 'user' && current_user !== null && current_user.onBoarding === false) ||
                                        (role !== 'superAdmin' && role !== 'user' && (current_org !== null && orgRole === 'admin') && current_org?.onBoarding === false)) ?
                                        <span className="notification__badge">{parseInt(notificationCount) + 1}</span>
                                        :
                                        (notificationCount > 0) ?
                                            <span className="notification__badge">{notificationCount}</span>
                                            :
                                            ''
                                    }
                                    {notificationCount > 0 && <span className="notification__badge">{notificationCount}</span>}
                                </div>

                                {showNotifications && <div className="dropdown-box header-dropdown notifications_dropdown">
                                    <div className="dropdown-box-header">
                                        <p className="dropdown-box-header-title">{t('notifications')}</p>

                                        <div className="dropdown-box-header-actions">
                                            <p className="dropdown-box-header-action"
                                                onClick={markAllNotificationsRead}
                                            >{t('mark_all_as_read')}</p>
                                        </div>
                                    </div>

                                    <div className="dropdown-box-list" data-simplebar="init">
                                        <div className="simplebar-wrapper" style={{ margin: '0px' }}>
                                            <div className="simplebar-mask">
                                                <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                                                    <div className="simplebar-content-wrapper" style={{ height: '100%' }}>
                                                        <div className="simplebar-content" style={{ padding: '0px' }}>
                                                            {role !== 'superAdmin' && role === 'user' && current_user !== null && current_user.onBoarding === false && <div className="dropdown-box-list-item unread">
                                                                <Row className="notification">
                                                                    <Col md={9} className="user-status-title">
                                                                        <span className="bold">
                                                                            {t('Please_complete_your_profie_So_that_you_can_use_this_platform_well')}
                                                                        </span>
                                                                    </Col>
                                                                    <Col md={3} className="uuser-status-icon">
                                                                        <Link to="/user/details" className="button green">{t('update')}</Link>
                                                                    </Col>
                                                                </Row>
                                                            </div>}

                                                            {role !== 'superAdmin' && role !== 'user' && (current_org !== null && orgRole === 'admin') && current_org?.onBoarding === false && <div className="dropdown-box-list-item unread">
                                                                <Row className="notification">
                                                                    <Col md={9} className="user-status-title">
                                                                        <span className="bold">
                                                                            {t('Please_complete_your_profie_So_that_you_can_use_this_platform_well')}
                                                                        </span>
                                                                    </Col>
                                                                    <Col md={3} className="uuser-status-icon">
                                                                        <Link to="/org/details" className="button green">{t('update')}</Link>
                                                                    </Col>
                                                                </Row>
                                                            </div>}
                                                            {isLoading ?
                                                                <BarsLoader /> :
                                                                notificationList.length > 0 ? <>
                                                                    {
                                                                        notificationList.map((item, index) => {
                                                                            return (
                                                                                <div key={index} className={(item.seen) ? "dropdown-box-list-item" : "dropdown-box-list-item unread"}>
                                                                                    <div className="user-status notification">

                                                                                        <Link className="user-status-avatar customPolygon" to="#" onClick={(e) => navigateNotification(e, '', item)}>
                                                                                            <div className="user-avatar small no-outline">
                                                                                                <div className="user-avatar-content">
                                                                                                    {(item.subject === 'userSentRequest' || item.subject === 'userAcceptRequest' || item.subject === 'userOrganizationCurrentWorkingStatus' || item.subject === 'organizationFollow') ?
                                                                                                        <AvatarImgBox img={item?.userInfo?.profilePicture && item?.userInfo?.profilePicture !== '' ? getImageUrl(item?.userInfo?._id, "userProfile", item?.userInfo?.profilePicture) : AvatarImg} />
                                                                                                        :
                                                                                                        (item.subject === 'targetInvites' || item?.subject === 'targetUpdate' || item?.subject === 'targetInvite-user-accept' || item?.subject === 'targetInvite-user-cancel' || item?.subject === 'targetInvite-organization-accept' || item?.subject === 'targetInvite-organization-cancel') ?
                                                                                                            <AvatarImgBox img={item?.targetInfo?.photo && item?.targetInfo?.photo !== '' ? getImageUrl(item?.targetInfo?._id, "target", item?.targetInfo?.photo) : AvatarImg} />
                                                                                                            :
                                                                                                            (item.subject === 'eventInvites' || item.subject === 'eventRemind' || item?.subject === 'eventUpdate' || item?.subject === 'eventValidatorValidateEvent' || item.subject === 'eventInvite-user-accept' || item.subject === 'eventInvite-user-cancel' || item.subject === 'eventInvite-organization-accept' || item.subject === 'eventInvite-organization-cancel' || item.subject === 'eventValidate' || item?.subject === 'markValidate') ?
                                                                                                                <AvatarImgBox img={item?.eventInfo?.photo && item?.eventInfo?.photo !== '' ? getImageUrl(item?.eventInfo?._id, "event", item?.eventInfo?.photo) : AvatarImg} />
                                                                                                                :
                                                                                                                (item?.subject === 'organizationroleupdate-administrator' || item?.subject === 'organizationroleupdate-organization' || item?.subject === 'organizationcreate-administrator') ?
                                                                                                                    <AvatarImgBox img={item?.organizationInfo?.profilePicture && item?.organizationInfo?.profilePicture !== '' ? getImageUrl(item?.organizationInfo?._id, "userProfile", item?.organizationInfo?.profilePicture) : AvatarImg} />

                                                                                                                    :
                                                                                                                    (item.subject === 'organizationReferAndInvites' || item.subject === 'organizationEmployeeRequestApprove' || item.subject === 'organizationEmployeeRequestReject' || item.subject === 'organizationEmployeeRequestRemove') ?
                                                                                                                        <AvatarImgBox img={item?.organizationInfo?.profilePicture && item?.organizationInfo?.profilePicture !== '' ? getImageUrl(item?.organizationInfo?._id, "userProfile", item?.organizationInfo?.profilePicture) : AvatarImg} />
                                                                                                                        :
                                                                                                                        (item.subject === 'PostComment-user' || item.subject === 'PostCreate-user' || item?.subject === 'PostComment-administrator' || item?.subject === 'PostReplyComment-user' || item?.subject === 'PostReplyComment-administrator' || item?.subject === 'PostCreate-administrator' || item?.subject === "PostTagged-administrator" || item?.subject === 'PostReaction-user' || item?.subject === 'PostReaction-administrator' || item?.subject === 'PostShare-user' || item?.subject === 'PostShare-administrator' || item?.subject === 'PostEdit-user' || item?.subject === 'PostEdit-administrator' || item?.subject === 'PostHide-administrator' || item?.subject === 'PostDelete-administrator' || item?.subject === 'PostHide-user' || item?.subject === 'PostDelete-user') ?
                                                                                                                            <AvatarImgBox img={item?.userInfo?.profilePicture && item?.additionalData?.createdBy?.profilePicture !== '' ? getImageUrl(item?.userInfo?._id, "userProfile", item?.userInfo?.profilePicture) : AvatarImg} />
                                                                                                                            :
                                                                                                                            (item?.subject === 'eventValidatorRequestCancelled' || item?.subject === 'eventValidatorRequestAccept') ?
                                                                                                                                <AvatarImgBox img={item?.eventInfo?.photo && item?.eventInfo?.photo !== '' ? getImageUrl(item?.eventInfo?._id, "event", item?.eventInfo?.photo) : AvatarImg} />
                                                                                                                                :
                                                                                                                                (item.subject === 'PostComment-organization' || item?.subject === 'PostCreate-organization' || item.subject === 'PostReplyComment-organization' || item.subject === 'PostReaction-organization' || item?.subject === 'PostShare-organization' || item?.subject === 'PostEdit-organization' || item?.subject === 'PostDelete-organization' || item?.subject === 'PostHide-organization') ?
                                                                                                                                    <AvatarImgBox img={item?.userInfo?.profilePicture && item?.additionalData?.createdBy?.profilePicture !== '' ? getImageUrl(item?.userInfo?._id, "userProfile", item?.userInfo?.profilePicture) : AvatarImg} />
                                                                                                                                    :
                                                                                                                                    <AvatarImgBox img={AvatarImg} />
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>

                                                                                        <p className="user-status-title" onClick={(e) => navigateNotification(e, '', item)} style={{ cursor: 'pointer' }}>
                                                                                            {(item?.subject === 'userSentRequest' || item?.subject === 'userAcceptRequest' || item.subject === 'userOrganizationCurrentWorkingStatus' || item.subject === 'organizationFollow') ?
                                                                                                <>{t(`notification.${item.subject}`).replace('${name}', `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`)}</>
                                                                                                :
                                                                                                (item.subject === 'targetInvites') ?
                                                                                                    <>{item?.message}</>
                                                                                                    :
                                                                                                    (item?.subject === 'targetUpdate' || item?.subject === 'targetDeleted-organization' || item?.subject === 'targetDeleted-user') ?
                                                                                                        <>{t(`notification.${item.subject}`).replace('${name}', `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`).replace('${target}', `${item?.additionalData?.targetMessage}`)}</>
                                                                                                        :
                                                                                                        (item?.subject === 'targetInvite-user-accept' || item?.subject === 'targetInvite-user-cancel' || item?.subject === 'targetInvite-organization-accept' || item?.subject === 'targetInvite-organization-cancel') ?
                                                                                                            <>{t(`notification.${item.subject}`).replace('${name}', (!item?.organizationInfo?.name) ? `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}` : item?.organizationInfo?.name).replace('${target}', `${item?.additionalData?.targetMessage}`)}</>
                                                                                                            :
                                                                                                            (item.subject === 'eventInvites' || item?.subject === 'eventUpdate' || item?.subject === 'eventValidatorValidateEvent') ?
                                                                                                                <>{t(`notification.${item.subject}`).replace('${name}', `${item?.additionalData?.eventMessage}`).replace('${event}', `${item?.additionalData?.eventMessage}`)}</>
                                                                                                                :
                                                                                                                (item.subject === 'eventInvite-user-accept' || item.subject === 'eventInvite-user-cancel' || item.subject === 'eventInvite-organization-accept' || item.subject === 'eventInvite-organization-cancel') ?
                                                                                                                    <>{t(`notification.${item.subject}`).replace('${name}', (!item?.organizationInfo?.name) ? `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}` : item?.organizationInfo?.name).replace('${event}', `${item?.additionalData?.eventMessage}`)}</>
                                                                                                                    :
                                                                                                                    (item.subject === 'eventRemind') ?
                                                                                                                        <>{t(`notification.${item.subject}`).replace('${event}', `${item?.additionalData?.eventMessage}`).replace('${time}', `${timeFormat(dateTimeEventFormat(item?.eventInfo?.startDate, item?.eventInfo?.startTime))}`)}</>
                                                                                                                        :
                                                                                                                        (item.subject === 'organizationReferAndInvites' || item.subject === 'organizationEmployeeRequestApprove' || item.subject === 'organizationEmployeeRequestReject' || item.subject === 'organizationEmployeeRequestRemove') ?
                                                                                                                            <>{t(`notification.${item.subject}`).replace('${name}', `${item?.organizationInfo?.name}`)}</>
                                                                                                                            :
                                                                                                                            (item?.subject === 'organizationroleupdate-administrator' || item?.subject === 'organizationroleupdate-organization' || item?.subject === 'organizationcreate-administrator') ?
                                                                                                                                <>{t(`notification.${item?.subject}`).replace('${name}', item?.organizationInfo?.name).replace("${role}", t(`roles.${item?.additionalData?.role}`))}</>
                                                                                                                                :
                                                                                                                                (item.subject === 'PostComment-user' || item.subject === 'PostReplyComment-user' || item.subject === 'PostCreate-user' || item?.subject === 'PostComment-administrator' || item?.subject === 'PostReplyComment-administrator' || item.subject === 'PostCreate-administrator' || item?.subject === 'PostReaction-user' || item?.subject === 'PostReaction-user' || item?.subject === 'PostReaction-administrator' || item?.subject === 'PostShare-user' || item?.subject === 'PostShare-administrator' || item?.subject === 'PostEdit-user' || item?.subject === 'PostEdit-administrator' || item?.subject === 'PostHide-administrator' || item?.subject === 'PostDelete-administrator' || item?.subject === 'PostDelete-user' || item?.subject === 'PostHide-user') ?
                                                                                                                                    <>{t(`notification.${item.subject}`).replace('${name}', `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`).replace('${post}', `${(item?.additionalData?.post) ? item?.additionalData?.post : item?.additionalData?.comment ? item?.additionalData?.comment : item?.additionalData?.content ? item?.additionalData?.content : ""}`?.substring(0, 50) + '...')}</>
                                                                                                                                    :
                                                                                                                                    (item?.subject === 'PostCreate-organization' || item?.subject === "PostComment-organization" || item?.subject === 'PostReplyComment-organization' || item?.subject === 'PostReaction-organization' || item?.subject === 'PostShare-organization' || item?.subject === 'PostEdit-organization' || item?.subject === 'PostDelete-organization' || item?.subject === 'PostHide-organization') ?
                                                                                                                                        <>{t(`notification.${item.subject}`).replace('${name}', (item?.organizationInfo?.name)).replace('${post}', `${(item?.additionalData?.post) ? item?.additionalData?.post : item?.additionalData?.comment ? item?.additionalData?.comment : item?.additionalData?.content ? item?.additionalData?.content : ""}`?.substring(0, 50) + '...')}</>
                                                                                                                                        :
                                                                                                                                        (item?.subject === 'eventValidatorRequestCancelled' || item?.subject === 'eventValidatorRequestAccept') ?
                                                                                                                                            <>{t(`notification.${item?.subject}`).replace('${event}', (item?.additionalData?.eventMessage)).replace('${user}', `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`)}</>
                                                                                                                                            :
                                                                                                                                            (item.subject === 'eventValidate' || item?.subject === 'markValidate-user' || item?.subject === 'markValidate-user') ?
                                                                                                                                                <>{t(`notification.${item.subject}`).replace('${eventName}', `${item?.additionalData?.eventMessage}`).replace('${name}', `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`).replace('${event}', `${item?.additionalData?.eventMessage}`)}</>
                                                                                                                                                :
                                                                                                                                                (item?.subject === 'markValidate-organization' || item?.subject === 'markValidate-organization') ?
                                                                                                                                                <>{t(`notification.${item.subject}`).replace('${eventName}', `${item?.additionalData?.eventMessage}`).replace('${name}', `${item?.organizationInfo?.name}`).replace('${event}', `${item?.additionalData?.eventMessage}`)}</>
                                                                                                                                                :
                                                                                                                                                (item.subject === 'organizationstatus-administrator') ?
                                                                                                                                                    <>{t(`notification.${item.subject}`)}{(item?.additionalData?.isVerified) ? " 'Active'" : " 'Inactive'"}</>
                                                                                                                                                    :
                                                                                                                                                (item.subject === 'paidEventSubmit') ?
                                                                                                                                                    <>{t(`notification.${item.subject}`).replace(('${name}'), `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`).replace('${event}', `${item?.additionalData?.eventMessage}`)}</>
                                                                                                                                                    :
                                                                                                                                                (item.subject === 'eventReminderMonthly') ?
                                                                                                                                                    <>{t(`notification.${item.subject}`).replace('${name}', `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`).replace('${event}', `${item?.additionalData?.eventMessage}`)}</>
                                                                                                                                                    :
                                                                                                                                                (item.subject ==='eventRemindMonthly') ?
                                                                                                                                                    <>{t(`notification.${item.subject}`).replace('${event}', `${item?.additionalData?.eventMessage}`).replace('${time}',`${timeFormat(dateTimeEventFormat(item?.additionalData?.startDate,item?.additionalData?.startTime))}`)}</>
                                                                                                                                                    :
                                                                                                                                                <>{t(`notification.${item.subject}`)}</>
                                                                                            }
                                                                                        </p>

                                                                                        <p className="user-status-timestamp">{getTimeGap(item.createdAt)}</p>

                                                                                        <div className="user-status-icon">
                                                                                            <svg className="icon-comment">
                                                                                                <use xlinkHref="#svg-comment"></use>
                                                                                            </svg>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })

                                                                    }
                                                                </> :
                                                                    <>
                                                                        <Row className="notification">
                                                                            <Col md={{ span: 6, offset: 3 }} className="user-status-title text-center">
                                                                                <span className="bold">
                                                                                    {t('no_notifications')}
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                    </>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="simplebar-track simplebar-vertical" style={{ visibility: 'hidden' }}>

                                        </div>
                                    </div>

                                    <Link to="/notifications" className="dropdown-box-button secondary notificationClass" >{t('View_all_Notifications')}</Link>
                                </div>}
                            </div>
                        </div>


                        <div className={role === 'user' ? 'action-item-wrap col-7 ms-2 col-sm-4 col-md-6 col-lg-6 col-xl-7 me-lg-2 px-0' : 'action-item-wrap col-auto ms-2 me-md-3 me-lg-2 px-0'} style={{ position: 'relative' }} ref={settingWrapper}>
                            <div className="action-item dark header-settings-dropdown-trigger justify-content-lg-end"
                                onClick={(e) => { setShowSettings(!showSettings); setShowNotifications(false) }}>

                                {/* Organization Image in Header */}
                                <div className={role === 'user' && userOrg?.organizationDetail && userOrg?.isVerified && "one_pic"}>
                                    {role === 'user' && userOrg?.organizationDetail && userOrg?.isVerified &&
                                        <img
                                            src={userOrg?.organizationDetail?.profilePicture !== '' ?
                                                getImageUrl(userOrg?.organizationDetail?._id, 'userProfile', userOrg?.organizationDetail?.profilePicture)
                                                : '/Assets/org1@2x.png'}
                                            alt=""
                                            className={`${!userOrg?.isVerified && 'notVerified'} navSettingImg`}
                                        />

                                    }
                                    {/* Organization Image in Header */}
                                    <AccountCircleOutlinedIcon className="action-item-icon icon-settings" />
                                </div>
                            </div>

                            {showSettings && <div className="dropdown-navigation header-settings-dropdown settings_dropdown">
                                <div className="dropdown-navigation-header">
                                    <div className="user-status">
                                        <Link className="user-status-avatar customPolygon" to="/profile">
                                            <div className="user-avatar small no-outline">
                                                <div className="user-avatar-content">
                                                    {role === 'organization' ?
                                                        <img className="navSettingImg" src={current_org?.profilePicture && current_org?.profilePicture !== '' ? getImageUrl(current_org?._id, "userProfile", current_org?.profilePicture) : OrgImg} alt="" />
                                                        :
                                                        (role === 'user') ?
                                                            <AvatarImgBox img={current_user?.profilePicture && current_user?.profilePicture !== '' ? getImageUrl(current_user?._id, "userProfile", current_user?.profilePicture) : AvatarImg} />
                                                            :
                                                            <img className="navSettingImg" src={current_user?.profilePicture && current_user?.profilePicture !== '' ? getImageUrl(current_user?._id, "userProfile", current_user?.profilePicture) : AvatarImg} alt="" />
                                                    }

                                                </div>
                                            </div>
                                        </Link>


                                        <p className="user-status-title"><span className="bold">
                                            Hi {(role === 'organization') ? current_org?.name : `${current_user.firstName} ${current_user.lastName}`}!
                                        </span></p>


                                        <p className="user-status-text small"><Link to="/profile">@{(role === 'organization') ? current_org?.userName : current_user.userName}</Link></p>
                                    </div>
                                </div>

                                <div style={{ marginTop: '20px' }}>
                                    {role !== 'organization' && current_user?.pages && current_user?.pages.length > 0 && current_user?.orgData?.isActive === true && <Link className="dropdown-navigation-link" to="#" onClick={(e) => setUpOrganizationHandler(e)}> {t('Admin_Console')} </Link>}
                                    {role !== 'organization' && current_user?.pages && current_user?.pages.length > 0 && current_user?.orgData?.isActive !== true && <p className="text-danger dropdown-navigation-link">{t('Admin_Console_Locked')}</p>}
                                    {role === 'organization' && <Link className="dropdown-navigation-link" to="#" onClick={(e) => setUpUserPanelHandler(e)}> {t('User_Console')} </Link>}
                                    {((role === 'organization' && orgRole === 'admin') || role === 'user' || role === 'superAdmin') && <>
                                        <Link className="dropdown-navigation-link" to="/profile-settings"> {t('My_Profile')} </Link>
                                        <Link className="dropdown-navigation-link" to="/account-settings"> {t('Settings')} </Link>
                                    </>}

                                    {role !== 'organization' && <Link className="dropdown-navigation-link" to="/privacy-settings"> {t('privacy_setting')} </Link>}


                                    <Link className="dropdown-navigation-button button small secondary" to="/logout">{t('Logout')}</Link>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>


        </header>
    );
}

export default Navbar
