import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserLayout from "../../components/dashboard-layout";
import AllGlobalUserSearch from "../../components/dashboard/globalSearch/allGlobalUserSearch";
import { selectSearchText } from "../../Reducer/user/user.selector";
import { getAllglobalList } from "../../services/onBoarding.services";
const AllGlobalUserSearchComp = () => {

    const [isLoading, setIsLoading] = useState(false);
    const searchText = useSelector(selectSearchText)

    useEffect(() => {
        if(searchText!==''){
            allData();
        }else {setIsLoading(false)}
    }, [searchText])


    const allData = async () => {
        await getUsersList();
    }

    //Users List
    const limit = 10;
    const [totalPageForUser, setTotalPageForUser] = useState(0);
    const [currentPageNoForUser, setCurrentPageNoForUser] = useState(0);
    const [usersList, setUsersList] = useState([])

    const handlePageClick = (data) => {
        let Page = data.selected
        getUsersList(Page)
      }


    const getUsersList = async (countForUser = 0) => {
        setCurrentPageNoForUser(countForUser)
        let searchKey=!!searchText?searchText:'i'
        getAllglobalList(searchKey, 'user', countForUser, limit)
            .then((response) => {
                if (response.data.status === 200) {
                    const _data = response.data.data.data;
                    setUsersList(_data)
                    let pageCount = (response?.data?.data?.totalCount) / limit;
                    setTotalPageForUser(Math.ceil(pageCount));
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    return <UserLayout>
        <AllGlobalUserSearch usersList={usersList} isLoading={isLoading}totalPageForUser={totalPageForUser}handlePageClick={handlePageClick} />
    </UserLayout>;
}

export default AllGlobalUserSearchComp;