import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserLayout from "../../components/dashboard-layout";
import AllGlobalOrgrSearch from "../../components/dashboard/globalSearch/allGlobalOrgSearch";
import { selectSearchText } from "../../Reducer/user/user.selector";
import { getAllglobalList } from "../../services/onBoarding.services";
const AllGlobalOrgSearchComp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const searchText = useSelector(selectSearchText)

    useEffect(() => {
        if(searchText!==""){
            allData();
        }else {setIsLoading(false)}
    }, [searchText])


    const allData = async () => {
        await getOrganizationList();
    }
    
    const limit = 10;
    const [totalPageForOrg, setTotalPageForOrg] = useState(0);
    const [currentPageNoForOrg, setCurrentPageNoForOrg] = useState(0);

    const handlePageClick = (data) => {
        let Page = data.selected
        getOrganizationList(Page)
      }

    //Organization List
    const [organizationList, setOrganizationList] = useState([])
    const getOrganizationList = async (countForOrg=0) => {
        setCurrentPageNoForOrg(countForOrg);
        let searchKey=!!searchText?searchText:'i'
        getAllglobalList(searchKey, 'organization', countForOrg,limit)
            .then((response) => {
                if (response.data.status === 200) {
                    const _data = response.data.data.data;
                    setOrganizationList(_data)
                    let pageCount = (response?.data?.data?.totalCount) / limit;
                    setTotalPageForOrg(Math.ceil(pageCount));
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    return <UserLayout>
                <AllGlobalOrgrSearch organizationList={organizationList}isLoading={isLoading}totalPageForOrg={totalPageForOrg}handlePageClick={handlePageClick} />
            </UserLayout>;
}

export default AllGlobalOrgSearchComp;