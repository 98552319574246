import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import '../dashboard.style.css';
import ProfileHeader from "../../../commonDashboardComp/profileHeader";
import InterestLists from "../../../commonDashboardComp/interestsList";
import { Row, Col } from "react-bootstrap";
import { defaultProfileGetService, getAnyUserData, postAnyUserData, postUserCancelRequest } from "../../../services/friends.service";
import { useSelector } from "react-redux";
import { selectCurrentOrgRole, selectCurrentUser, selectCurrentUserRole , selectCurrentOrg} from "../../../Reducer/user/user.selector";
import { useParams, useLocation } from "react-router-dom";
import { showSingleToastSucces2, showSingleToastSuccess, showToastError, showToastSuccess } from "../../../utils/helpers";
import { getOrgDetailsService , getOrgDetailsByUserNameService} from "../../../services/orgAuth.services";
import { useNavigate } from "react-router-dom";
import AboutOrg from "../../../commonDashboardComp/aboutOrg";
import { followTheOrganization } from "../../../services/onBoarding.services";
import { getObjectFromStore } from "../../../storage/Storage";
import PostList from "../../../commonDashboardComp/postList";

const OrganizationProfile = () => {
    const location = useLocation();
    const detail = useSelector(selectCurrentUser);
    const [orgData, setOrgData] = useState();
    const CURRENTORGROLE=useSelector(selectCurrentOrgRole);
    const role = useSelector(selectCurrentUserRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);

    let current_user = {};
    if (role === 'organization') { current_user = CURRENTORG }
    else { current_user = CURRENTUSER }

    const navigate=useNavigate();
    const { id } = useParams();
    const { userName } = useParams();
    const { t } = useTranslation();
    const orgId = id?window.atob(id):'';
    const addfrndIcon = '/Assets/Add Friend Button.png';
    const removefrndIcon = '/Assets/Cancel request.png';
    const respondfrndIcon = '/Assets/Respond.png';
    //const orgId = location?.state?.orgId;
   
    

    useEffect(() => {
        getOrganizationDetails();
        window.scrollTo(0,0);
    }, []);

    const getOrganizationDetails = async () => {
        if(getObjectFromStore('token')===null){
            const response=await defaultProfileGetService('org',orgId);
            if(response?.status===200){
                const user = response?.data?.data;
                setOrgData(user);
            }
        }
        else{
            if(userName)
            {
                const response = await getOrgDetailsByUserNameService(userName);
                if (response?.data?.data) {
                    const user = response?.data?.data;
                    setOrgData(user);
                }
            }
            else{
                const response = await getOrgDetailsService(orgId);
                if (response?.data?.data) {
                    const user = response?.data?.data;
                    setOrgData(user);
                }
            }        
       }
    }

    const handleOrgRequests = async (actionType) => {
        if (orgData?.isActive) {
            let param = {
                list: [orgData?._id],
                action: actionType
            }
            await followTheOrganization(param)
                .then((resp) => {
                    if (resp?.status == 200) {
                        let successMessage = actionType === 'followOrganization' ? t('You_started_Following')
                            : actionType === 'unFollowOrganization' ? t('You_Unfollowed')
                                : ""
                        showSingleToastSuccess(successMessage);
                        getOrganizationDetails();
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
        else {
            showToastError(t('Organization_is_not_active'))
        }

    }
    function makeButtons() {
        return <>
           
            <div className="ff-btn-area orgBtnControl viewEventBannerBlk">
            <div className="col-md-12 banner-btn-area">
               {getObjectFromStore('token')===null?
                <button className="btn successGreenBtns" onClick={()=>navigate('/login')} >{t('login')} </button>  
               :<>
                    {current_user?._id === orgData?._id ? '' : <>
                        {(!orgData?.isFollowing) ? <button className="btn successGreenBtns" onClick={(e) => handleOrgRequests('followOrganization')} >{t('follow')} </button> : ''}
                        {(orgData?.isFollowing) ? <button className="btn btn-success text-white bg-danger" onClick={(e) => handleOrgRequests('unFollowOrganization')} >{t('unfollow')} </button> : ''}
                        {/* {friendData?.isFriend && !userBlocked ? <> <button className='btn btn-danger text-white bg-danger' title="Block" onClick={(e) => handleUserRequests('blockUserRequest')} >Block</button>   <button className='btn btn-danger text-white bg-danger' title="UnFriend" onClick={(e) => handleUserRequests('unFriendRequest')} >Unfriend</button>  </> : ''}
                                {friendData?.isFriendsRequestSent ? <button   title="Cancel Request" onClick={(e) => handleUserRequests('cancelRequest')} ><img src={removefrndIcon} className='img-fluid ' /></button> : ''}
                                {userBlocked?<button title='Unblock' className='btn btn-danger text-white bg-danger'  onClick={(e)=>handleUserRequests('unBlockUserRequest')}>UnBlock</button >:''}
                                */}
                    </>}
           
            </>}
            </div>
            </div>
        </>

    }

    return (
        <>
            <Row> <ProfileHeader coverImg='' img='' detail={orgData} buttons={(role==='user' ||getObjectFromStore('token')===null) ?makeButtons():''} /> </Row>
            <>{getObjectFromStore('token')===null?<div>
            <h4 className='text-center my-2'></h4>
               
                <div className="form-item mb-0">
                        <button className="button  secondary login-account-btn" onClick={()=>navigate('/login')}>
                          {t('Login_to_Sprede')}
                       </button>
                 </div>

                 <div className="text-center">{t('OR')}</div>
                
                 <div className="form-item mb-0">
                        <button className="button  secondary login-account-btn" onClick={()=>{navigate('/register')}}>
                           {t('Join_Sprede')}
                       </button>
                 </div>
               
            </div>:<>

            <div md={12}>
                <Row className="grid grid-3-6-3 mobile-prefer-content">
                    <Col md={9} className="grid-column">
                        <InterestLists userDet={orgData} />
                        <PostList type="org" id={orgData?._id} />
                    </Col>

                    <Col md={3} className="grid-column">
                        <AboutOrg CURRENTORG={orgData}
                        />
                    </Col>
                </Row>
            </div>
            </>}
            </>
        </>);
}

export default OrganizationProfile;
