import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import SettingsSidebar from "../../../commonDashboardComp/settingsSidebar";
import SectionHeader from "../../../commonDashboardComp/sectionHeader";
import AboutProfile from "./profileSettings/aboutProfile";
import AddressSetting from "./profileSettings/addressSetting";
import EducationSetting from "./profileSettings/educationSetting";
import EmployementSetting from "./profileSettings/empSetting";
import OrgBusinessTypeSetting from "./profileSettings/orgBusinessTypeSetting";
import OrgProfileSetting from "./profileSettings/orgProfileSetting";
import InterestSettings from "./profileSettings/interestSetting";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUserRole, selectCurrentUser, selectCurrentOrg } from "../../../Reducer/user/user.selector";
import DownloadProfile from "./downloadProfile";
import {
    getUserDetailsService,
    getImageRequestService
} from "../../../services/auth.services";
import {
    GetUserDetailsService,
    saveUserDetailsService,
    GetInterestsListService,
    addNewInterestService,
    addNewUserEmpService,
    updateUserEmpService,
    deleteUserEmpService,
    updateAdditionalEmpService
} from "../../../services/onBoarding.services";
import {
    getOrgDetailsService,
    updateOrgDetailsService,
    getOrgInterestsService,
    saveNewOrgInterestsService,
    getLegalServiceTypeService,
    saveNewLegalStructureService,
    saveNewServiceTypeService
} from "../../../services/orgAuth.services";
import { showSingleToastSuccess, showToastError } from "../../../utils/helpers";
import { setCurrentOrg, setCurrentUser } from "../../../Reducer/user/user.action";
import { getImageUrl } from "../../../utils/helpers";
import { AvatarImg } from "../../../commonComponents/Images";
import AvatarImgBox from "../../../commonComponents/AvatarImgBox/avatarImgBox";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import DialogBox from "../../../commonComponents/dialogBox";
import { setCanSwitch } from "../../../Reducer/user/user.action";
import Loader from "../../../commonComponents/Loader";
const MyProfileSettings = () => {
    const role = useSelector(selectCurrentUserRole);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const CURRENTORG = useSelector(selectCurrentOrg);

    let current_user = {};
    if (role === 'organization') { current_user = CURRENTORG }
    else { current_user = CURRENTUSER }

    //const current_user = useSelector(selectCurrentUser);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedImage, setSelectedImage] = useState(null);
    const [showSelected, setShowSelected] = useState(false);
    const [selectedImageCover, setSelectedImageCover] = useState(false);
    const [showSelectedCover, setShowSelectedCover] = useState(false);
    const [imageForFormProfile, setImageForFormProfile] = useState(null);
    const [imageForFormCover, setImageForFormCover] = useState(null);
    const [error, setError] = useState(false);
    const [errorCover, setErrorCover] = useState(false);
    const [errorPofile, setErrorProfile] = useState(false);
    const [interests, setInterests] = useState([]);
    const [isCoverRemove, setIsCoverRemove] = useState(false);
    const [legalServiceType, setLegalServiceType] = useState([]);
    const initial_emp = {
        organizationId: '',
        designation: '',
        startYear: '',
        endYear: '',
        organizationName: '',
        isCurrentlyWorking: false,
        address: { city: '', country: '' }
    };
    const initial_education = {
        course: '',
        university: '',
        startYear: '',
        endYear: '',
        isCurrentlyPursuing: false
    };
    const [additionalInterest, setAdditionalInterest] = useState('');
    const [additionServiceType, setAdditionServiceType] = useState('');
    const INITIAL_FORM_VALUES = {
        'base_url': `${window.location.origin}`,
        //demographic details 
        'firstName': '',
        'middleName': '',
        'lastName': '',
        'aboutMe': '',
        'dob': '',
        'gender': '',
        'profilePicture': AvatarImg,
        'coverPicture': '',
        'userNameCount': 0,

        'name': "",
        'webSite': "",
        "taxId": "",
        "regNo": "",

        //Contact Info
        'user_email': '',
        'user_mobile': '',
        'email': '',
        'mobile': '',
        'isEmailVerify': false,
        'isMobileVerify': false,
        'currentAddress': { city: "", country: "" },
        'permanentAddress': { city: "", country: "" },

        //Username & Profile URL
        'default_userName': '',
        'userName': '',
        'url': '',

        //employement Info 
        'classifyYourJob': false,
        'additionalPrivacy': false,
        'organization': [initial_emp],

        //education Info 
        'education': [initial_education],

        //Interests
        'interests': [],
        'spredeInterest': [],
        'occupation': "",
    }

    const editOptionDefault = {
        aboutInfo: false,
        aboutBusinessInfo: false,
        addressInfo: false,
        educationInfo: false,
        employementInfo: false,
        interestInfo: false,
        businessTypeInfo: false,
    }
    const [orgDup, setOrgDup] = useState([initial_emp]);
    const [interestsDup, setInterestsDup] = useState([]);
    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);
    const [employeeFormValues, setEmployeeFormValues] = useState([]);
    const [dataDupCopy, setDupCopy] = useState(INITIAL_FORM_VALUES);
    const [editOption, setEditOption] = useState(editOptionDefault);
    const [showDialog, setShowDialog] = useState(false)
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        getUserDetails();
        getInterestsLists();
        if (role === 'organization') {
            getLegalServiceType();
        }
    }, [])

    const getUserDetails = async () => {
        if (role === 'organization') {
            getOrgDetailsService(current_user?._id)
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setIsLoading(false);
                        let FORM_VALUES = {
                            'base_url': `${window.location.origin}`,
                            //demographic details 
                            "name": (data?.data?.data?.name) ? data?.data?.data?.name : "",
                            "taxId": (data?.data?.data?.taxId) ? data?.data?.data?.taxId : "",
                            "webSite": (data?.data?.data?.webSite) ? data?.data?.data?.webSite : "",
                            "regNo": (data?.data?.data?.regNo) ? data?.data?.data?.regNo : "",
                            "aboutMe": (data?.data?.data?.aboutMe) ? data?.data?.data?.aboutMe : "",
                            'profilePicture': (data?.data?.data?.profilePicture) ? data?.data?.data?.profilePicture : '',
                            'coverPicture': (data?.data?.data?.coverPicture) ? data?.data?.data?.coverPicture : '',

                            //Contact Info
                            'user_email': (data?.data?.data?.email) ? data?.data?.data?.email : '',
                            'user_mobile': (data?.data?.data?.mobile) ? data?.data?.data?.mobile : '',
                            'email': (data?.data?.data?.email) ? data?.data?.data?.email : '',
                            'mobile': (data?.data?.data?.mobile) ? data?.data?.data?.mobile : '',
                            'isEmailVerify': (data?.data?.data?.isEmailVerify) ? data?.data?.data?.isEmailVerify : false,
                            'isMobileVerify': (data?.data?.data?.isMobileVerify) ? data?.data?.data?.isMobileVerify : false,

                            //legal Structure
                            'legalStructure': (data?.data?.data?.legalStructure) ? data?.data?.data?.legalStructure : [],
                            'serviceType': (data?.data?.data?.serviceType) ? data?.data?.data?.serviceType : [],

                            //interests 
                            'interests': (data?.data?.data?.spredeInterest) ? data?.data?.data?.spredeInterest : []
                        }
                        setFormValues({ ...formValues, ...FORM_VALUES })
                        setInterestsDup((data?.data?.data?.spredeInterest) ? data?.data?.data?.spredeInterest : [])
                        setDupCopy({ ...dataDupCopy, ...FORM_VALUES });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        else {
            GetUserDetailsService()
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setIsLoading(false);
                        let FORM_VALUES = {
                            'base_url': `${window.location.origin}`,
                            //demographic details 
                            'firstName': (data?.data?.data?.firstName) ? data?.data?.data?.firstName : '',
                            'middleName': (data?.data?.data?.middleName) ? data?.data?.data?.middleName : '',
                            'lastName': (data?.data?.data?.lastName) ? data?.data?.data?.lastName : '',
                            'aboutMe': (data?.data?.data?.aboutMe) ? data?.data?.data?.aboutMe : '',
                            'dob': (data?.data?.data?.dob) ? data?.data?.data?.dob : '',
                            'gender': (data?.data?.data?.gender) ? data?.data?.data?.gender : '',
                            'profilePicture': (data?.data?.data?.profilePicture) ? data?.data?.data?.profilePicture : '',
                            'coverPicture': (data?.data?.data?.coverPicture) ? data?.data?.data?.coverPicture : '',

                            //Contact Info
                            'user_email': (data?.data?.data?.email) ? data?.data?.data?.email : '',
                            'user_mobile': (data?.data?.data?.mobile) ? data?.data?.data?.mobile : '',
                            'email': (data?.data?.data?.email) ? data?.data?.data?.email : '',
                            'mobile': (data?.data?.data?.mobile) ? data?.data?.data?.mobile : '',
                            'isEmailVerify': (data?.data?.data?.isEmailVerify) ? data?.data?.data?.isEmailVerify : false,
                            'isMobileVerify': (data?.data?.data?.isMobileVerify) ? data?.data?.data?.isMobileVerify : false,
                            'currentAddress': (data?.data?.data?.currentAddress) ? data?.data?.data?.currentAddress : '',
                            'permanentAddress': (data?.data?.data?.permanentAddress) ? data?.data?.data?.permanentAddress : '',

                            //Username & Profile URL
                            'default_userName': (data?.data?.data?.userName) ? data?.data?.data?.userName.toLowerCase() : '',
                            'userName': (data?.data?.data?.userName) ? data?.data?.data?.userName.toLowerCase() : '',
                            'userNameCount': (data?.data?.data?.userNameCount) ? data?.data?.data?.userNameCount : false,
                            'url': (data?.data?.data?.userName) ? formValues.base_url + '/' + data?.data?.data?.userName.toLowerCase() : '',

                            //employement Info 
                            'classifyYourJob': (data?.data?.data?.classifyYourJob) ? data?.data?.data?.classifyYourJob : false,
                            'additionalPrivacy': (data?.data?.data?.additionalPrivacy) ? data?.data?.data?.additionalPrivacy : false,
                            'organization': (data?.data?.data?.organization) ? data?.data?.data?.organization : [initial_emp],

                            //education Info 
                            'education': (data?.data?.data?.education) ? data?.data?.data?.education : [initial_education],

                            //interests 
                            'interests': (data?.data?.data?.spredeInterest) ? data?.data?.data?.spredeInterest : [],
                            'occupation': (data?.data?.data?.occupation) ? data?.data?.data?.occupation : "",
                        }
                        setFormValues({ ...formValues, ...FORM_VALUES });
                        setInterestsDup((data?.data?.data?.spredeInterest) ? [...data?.data?.data?.spredeInterest] : [])
                        setOrgDup((data?.data?.data?.organization) ? [...data?.data?.data?.organization] : [initial_emp]);
                        setDupCopy({ ...dataDupCopy, ...FORM_VALUES });

                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    const getInterestsLists = async () => {
        if (role === 'organization') {
            getOrgInterestsService(current_user?._id)
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setInterests(data?.data?.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        else {
            GetInterestsListService()
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setInterests(data?.data?.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    const getLegalServiceType = () => {
        try {
            getLegalServiceTypeService()
                .then((data) => {
                    if (data?.data?.status === 200) {
                        setLegalServiceType(data?.data?.data)
                    }
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const saveNewInterest = (id, value) => {
        let params = {
            "spredeInterestId": id,
            "spredeInterestOptions": value
        }

        if (role === 'organization') {
            params['organizationId'] = current_user?._id;
            saveNewOrgInterestsService(params)
                .then(async (data) => {
                    if (data.status === 200) {
                        await getInterestsLists();
                        //await getUserDetails();
                        setAdditionalInterest(data?.data?.data?.insertedId)
                    } else {
                        showToastError(t(`apiMessages.${data?.data?.data}`))
                    }
                })
                .catch((error) => {
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        else {
            addNewInterestService(params)
                .then(async (data) => {
                    if (data.status === 200) {
                        await getInterestsLists();
                        //await getUserDetails();
                        setAdditionalInterest(data?.data?.data?.insertedId)
                    } else {
                        showToastError(t(`apiMessages.${data?.data?.data}`))
                    }
                })
                .catch((error) => {
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }

    }

    const saveNewLegalType = (value) => {
        let params = {
            "legalStructure": value
        }
        saveNewLegalStructureService(params)
            .then(async (data) => {
                if (data.status === 200) {
                    getLegalServiceType()
                    setFormValues({ ...formValues, 'legalStructure': [data?.data?.data?.insertedId] })
                } else {
                    showToastError(t(`apiMessages.${data?.data?.data}`))
                }
            })
            .catch((error) => {
                showToastError(t(`apiMessages.${error?.data?.data}`))
            })
    }

    const saveNewServiceType = (value) => {
        let params = {
            "serviceType": value
        }
        saveNewServiceTypeService(params)
            .then(async (data) => {
                if (data.status === 200) {
                    await getLegalServiceType()
                    setAdditionServiceType(data?.data?.data?.insertedId)
                } else {
                    showToastError(t(`apiMessages.${data?.data?.data}`))
                }
            })
            .catch((error) => {
                showToastError(t(`apiMessages.${error?.data?.data}`))
            })
    }

    const onChangeEditOption = (key, value) => {
        setEditOption({ ...editOption, [key]: value })
    }

    const chnageDetection = (param) => {
        const propertyNames = Object.keys(param);
        for (let i = 0; i < propertyNames?.length; i++) {
            if (dataDupCopy[propertyNames[i]] !== param[propertyNames[i]]) {
                return true;
            }
        }
        return false;
    }

    const checkUnsavedChanges = () => {

    }

    useEffect(() => {
        setShowDialog(!(JSON.stringify(formValues) === JSON.stringify(dataDupCopy)))
        if ((JSON.stringify(formValues) === JSON.stringify(dataDupCopy))) {
            setShowDialog(!(JSON.stringify(formValues?.interests) === JSON.stringify(interestsDup)))

            // if ((JSON.stringify(formValues?.interests) === JSON.stringify(interestsDup)) && role === 'user') {
            //     setShowDialog(!(JSON.stringify(employeeFormValues) === JSON.stringify(orgDup)))
            // }
        }
    }, [formValues, employeeFormValues])

    useState(() => {
        dispatch(setCanSwitch(!showDialog))
    }, [showDialog])

    const saveUserDetails = (params, section = '') => {
        if (section === 'employementInfo') {
            // showToastSuccess(t('apiMessages.DATA_SAVED_SUCCESSFULLY'));
            if (chnageDetection(params)) {
                showSingleToastSuccess(t('apiMessages.DATA_SAVED_SUCCESSFULLY'));
            }
            else {
                showSingleToastSuccess(t('errors.No_change_have_been_made'));

            }

            onChangeEditOption(section, false);
            updateProfileDetails();
            return;
        }
        try {
            if (role === 'organization') {
                params['organizationId'] = current_user?._id
                updateOrgDetailsService(params)
                    .then((data) => {
                        if (data.status === 200) {
                            // showToastSuccess(t('apiMessages.DATA_SAVED_SUCCESSFULLY'));
                            showSingleToastSuccess(t('apiMessages.DATA_SAVED_SUCCESSFULLY'));
                            getLegalServiceType();
                            updateProfileDetails();
                            if (section !== '') { onChangeEditOption(section, false); }
                        } else {
                            setError(true);
                            showToastError(t(`apiMessages.${data.data.data}`))
                        }
                    })
                    .then(() => { getUserDetails(); })
                    .catch((error) => {
                        showToastError(t(`apiMessages.${error?.data?.data}`))
                    })
            }
            else {
                saveUserDetailsService(params)
                    .then((data) => {
                        if (data.status === 200) {
                            // showToastSuccess(t('apiMessages.DATA_SAVED_SUCCESSFULLY'));
                            if (chnageDetection(params)) {
                                showSingleToastSuccess(t('apiMessages.DATA_SAVED_SUCCESSFULLY'));
                            }
                            else {
                                showSingleToastSuccess(t('errors.No_change_have_been_made'));

                            }
                            updateProfileDetails();

                            if (section !== '') { onChangeEditOption(section, false); }
                        } else {
                            setError(true);
                            showToastError(t(`apiMessages.${data.data.data}`));
                        }
                    })
                    .then(() => { getUserDetails(); })
                    .catch((error) => {
                        showToastError(t(`apiMessages.${error?.data?.data}`))
                    })
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const updateProfileDetails = async () => {
        if (role === 'organization') {
            const response = await getOrgDetailsService(current_user?._id);
            if (response?.data?.data) {
                const org = response?.data?.data
                dispatch(setCurrentOrg(org));
            }
        }
        else {
            const response = await getUserDetailsService();
            if (response?.data?.data) {
                const user = response?.data?.data
                dispatch(setCurrentUser(user));
            }
        }

    }

    //Upload Image and cover Image
    const setProfileImage = async (param) => {
        let isValidSize = await readImageFile(param)

        if (param?.length !== 0) {
            setErrorProfile(param?.size < 5000 || param?.size > 5120000 ? true : false) //|| !isValidSize

            if (param?.size < 5000 || param?.size > 5120000) { //||!isValidSize
                return;
            }
            setShowSelected(true);
            setSelectedImage(param);
            const timeStamp = Date.now();
            fetchImageData('profile', `${timeStamp}`, param);
        }
    }



    const setCoverImage = async (param) => {
        if (param?.length !== 0) {
            setErrorCover(param?.size < 5000 || param?.size > 5120000 ? true : false) //|| !isValidSize
            if (param?.size < 5000 || param?.size > 5120000) { //||!isValidSize
                return;
            }
            setShowSelectedCover(true);
            setSelectedImageCover(param);
            const timeStamp = Date.now();
            fetchImageData('cover', `${timeStamp}`, param);
        }
    }

    const putImageRequest = async (url, actParam, type, fileName) => {
        const response = await fetch(url + '&x-ms-blob-type=BlockBlob', {
            method: 'PUT',
            headers: {
                'x-ms-blob-type': `BlockBlob`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data'
            },
            body: actParam
        })
            .then(res => {
                if (res.ok) {
                    let params = {};
                    if (type === 'profile') {
                        params['profilePicture'] = fileName;
                    }
                    else if (type === 'cover') {
                        if (isCoverRemove) params['coverPicture'] = '';
                        else params['coverPicture'] = fileName;
                    }
                    saveUserDetails(params);
                }
                return res;
            })
            .catch((error) => {
                console.error(error);
            });
        return response;
    }

    const fetchImageData = async (type, fileName, param) => {
        try {
            let params = {
                fileName: fileName,
                category: "user",
                type: type
            }
            var response = await getImageRequestService(params);
            putImageRequest(response?.data?.data, param, type, fileName);

        } catch (error) {
            console.error(error);
        }
    }

    //Org Details Update
    const addOrgDetail = (params) => {
        try {
            addNewUserEmpService(params)
                .then((data) => {
                    if (data.status === 200) {
                        //showToastSuccess(`Employement Detail Added Successfully!`);
                        //updateProfileDetails();
                    } else {
                        setError(true);
                        showToastError(t(`apiMessages.${data.data.data}`))
                    }
                })
                .then(() => { getUserDetails(); })
                .catch((error) => {
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const editEmpDetails = (params) => {
        try {
            updateUserEmpService(params)
                .then((data) => {
                    if (data.status === 200) {
                        // showToastSuccess(`Employement Detail Updated Successfully!`);
                        // updateProfileDetails();
                    } else {
                        setError(true);
                        showToastError(t(`apiMessages.${data.data.data}`))
                    }
                })
                .then(() => { getUserDetails(); })
                .catch((error) => {
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const editUserEmpAddDetails = (params) => {
        try {
            updateAdditionalEmpService(params)
                .then((data) => {
                    if (data.status === 200) {
                        // showToastSuccess(`Employement Detail Updated Successfully!`);
                        // updateProfileDetails();
                    } else {
                        setError(true);
                        showToastError(t(`apiMessages.${data.data.data}`))
                    }
                })
                .then(() => { getUserDetails(); })
                .catch((error) => {
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    const deleteEmpDetails = (uOrgId, orgId) => {
        try {
            deleteUserEmpService(uOrgId, orgId)
                .then((data) => {
                    if (data.status === 200) {
                        // showToastSuccess(t('apiMessages.EMP_DELETED_SUCCESSFULLY'));
                        showSingleToastSuccess(t('apiMessages.EMP_DELETED_SUCCESSFULLY'));
                    } else {
                        setError(true);
                        showToastError(t(`apiMessages.${data.data.data}`))
                    }
                })
                .then(() => { getUserDetails(); })
                .catch((error) => {
                    showToastError(t(`apiMessages.${error?.data?.data}`))
                })
        }
        catch (error) {
            console.log(error);
        }
    }
    const removeImage = () => {
        setCoverImage("");
        setShowSelectedCover(false);
        setSelectedImageCover(null);
        setIsCoverRemove(true);
        setFormValues({ ...formValues, coverPicture: '' })
        let params = {
            ...formValues
        }
        params['coverPicture'] = '';
        saveUserDetails(params);
    }
    //fro profile image upload check width and height
    function readImageFile(file) {
        const promise = new Promise((resolve, reject) => {
            try {
                if (file) {
                    var reader = new FileReader(); // CREATE AN NEW INSTANCE.
                    reader.readAsDataURL(file);
                    reader.onload = function (e) {
                        var img = new Image();
                        img.src = e.target.result;

                        img.onload = function () {
                            var w = this.width;
                            var h = this.height;
                            if (h < 225 || w < 225 || h > 420 || w > 420) {
                                resolve(false)
                            } else
                                resolve(true)
                        }
                    };
                } else {
                    resolve(false)
                }
            }
            catch (e) {
                reject(false);
            }
        });
        return promise;

    }

    //for Cover image upload check width and height

    function readImageFileCover(file) {
        const promise = new Promise((resolve, reject) => {
            var reader = new FileReader(); // CREATE AN NEW INSTANCE.
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {
                    var w = this.width;
                    var h = this.height;
                    if (h < 300 || w < 800 || w > 3000 || h > 2500) {
                        resolve(false)
                    } else
                        resolve(true)


                }
            };
        });
        return promise;

    }
    return (
        <div>
            {
                (isLoading) ? <div className="mt-5"><Loader /></div> : <>
                    <Row className="grid grid-3-6-3 mobile-prefer-content">
                        <DialogBox
                            showDialog={showPrompt}
                            confirmNavigation={confirmNavigation}
                            cancelNavigation={cancelNavigation}
                        />

                        <Col md={3} className="grid-column">
                            <SettingsSidebar />
                            <DownloadProfile/>
                        </Col>

                        <Col md={9} className="grid-column myProfileSettings profile_dob99">
                            <SectionHeader pretitle={t('My_Profile')} title={(role === 'user') ? t('form.Profile_Info') : t('onboarding.organizationInfo')} style={{ marginTop: '0px' }} />
                            <Row>
                                <Col lg={4} className="col-md-12 userPreviewBox">
                                    <div className="user-preview small fixed-height">
                                        <div className="position-relative" >
                                            <figure className="user-preview-cover liquid" >
                                                <img src={showSelectedCover && selectedImageCover ? URL.createObjectURL(selectedImageCover) : (formValues?.coverPicture !== '') ? getImageUrl(current_user?._id, 'userCover', formValues?.coverPicture) : '/Assets/Cover Image.png'} alt="" />

                                            </figure>
                                            <img src='/Assets/Close.png' className="closeIconUpload" style={{ top: "0px", right: "0px" }}
                                                onClick={() => removeImage()}
                                            />
                                        </div>
                                        <div className="user-preview-info">
                                            <div className="user-short-description small">
                                                <div className="user-short-description-avatar user-avatar profilePage small_profie_info_area">
                                                    <AvatarImgBox
                                                        img={showSelected && selectedImage ? URL.createObjectURL(selectedImage) : (formValues?.profilePicture === null || formValues?.profilePicture !== '') ? getImageUrl(current_user?._id, 'userProfile', formValues?.profilePicture) : AvatarImg} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </Col>

                                <Col lg={4} className="col-md-6 orgChangeLogoBox">
                                    <label htmlFor="upload-photo">
                                        <input type="file" id="upload-photo" accept="image/*"
                                            onChange={(event) => {
                                                setProfileImage(event.target.files[0])
                                            }}
                                            style={{ display: "none" }}
                                        />
                                        <div className="content">
                                            <div className="upload-box">
                                                <img src={(role === 'user') ? "/Assets/Member_Icon.png" : "/Assets/Member_Icon.png"} alt="" className="upload-box-icon icon-members" />
                                                <p className="upload-box-title">{(role === 'user') ? t('Change_Profile_Picture') : t('Change_Logo')}</p>
                                                {/* <p className="upload-box-text">{t('onboarding.size_minimum')}</p> */}
                                                <p className="upload-box-text">{t('Size')}: 5KB - 5MB</p>
                                                <p className="upload-box-text">{t('Best_Resolution')}: </p>
                                                <p className="upload-box-text">225x225 Or 420x420</p>
                                                {errorPofile && <p style={{ color: "red" }} className="error-message uploadCoverOrgErrorMsg">{t('Choose_correct_file_according_to_mentioned_size')}</p>}
                                            </div>
                                        </div>
                                    </label>

                                </Col>

                                <Col lg={4} className="col-md-6 orgUploadCoverBox">
                                    <label htmlFor="upload-cover">
                                        <input type="file" id="upload-cover" accept="image/*"
                                            // accept="image/png, image/jpeg"
                                            onChange={(event) => {
                                                setCoverImage(event.target.files[0])
                                            }}
                                            style={{ display: "none" }}
                                        />
                                        <div className="content">
                                            <div className="upload-box">
                                                <img src="/Assets/Photos_Icon-green.png" alt="" className="upload-box-icon icon-photos" />
                                                <p className="upload-box-title">{t('onboarding.Change_Cover')}</p>

                                                {/* <p className="upload-box-text">{t('onboarding.max_size_minimum')}</p> */}
                                                <p className="upload-box-text">{t('Size')}: 5KB - 5MB</p>
                                                <p className="upload-box-text">{t('Best_Resolution')}: </p>
                                                <p className="upload-box-text">800x300 Or 3000x2500</p>
                                                {errorCover && <p style={{ color: "red" }} className="error-message uploadCoverOrgErrorMsg">{t('Choose_correct_file_according_to_mentioned_size')}</p>}
                                            </div>
                                        </div>
                                    </label>

                                </Col>
                            </Row>

                            {role !== 'organization' && <>
                                <AboutProfile
                                    onEdit={() => onChangeEditOption('aboutInfo', true)}
                                    canEdit={editOption.aboutInfo}
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    error={error}
                                    setError={setError}
                                    saveUserDetails={saveUserDetails}
                                />
                                <AddressSetting
                                    onEdit={() => onChangeEditOption('addressInfo', true)}
                                    canEdit={editOption.addressInfo}
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    initial_emp={initial_emp}
                                    error={error}
                                    setError={setError}
                                    saveUserDetails={saveUserDetails}
                                />

                                {role === 'user' && <>
                                    <EducationSetting
                                        onEdit={() => onChangeEditOption('educationInfo', true)}
                                        canEdit={editOption.educationInfo}
                                        formValues={formValues}
                                        setFormValues={setFormValues}
                                        error={error}
                                        setError={setError}
                                        saveUserDetails={saveUserDetails}
                                        initial_education={initial_education}
                                    />
                                    <EmployementSetting
                                        onEdit={() => onChangeEditOption('employementInfo', true)}
                                        canEdit={editOption.employementInfo}
                                        formValues={formValues}
                                        setFormValues={setFormValues}
                                        error={error}
                                        setError={setError}
                                        saveUserDetails={saveUserDetails}
                                        initial_emp={initial_emp}
                                        editEmpDetails={editEmpDetails}
                                        editUserEmpAddDetails={editUserEmpAddDetails}
                                        addOrgDetail={addOrgDetail}
                                        deleteEmpDetails={deleteEmpDetails}
                                        setEmployeeFormValues={setEmployeeFormValues}
                                    />
                                </>}
                            </>}

                            {role === 'organization' && <>
                                <OrgProfileSetting
                                    orgId={current_user?._id}
                                    onEdit={() => onChangeEditOption('aboutBusinessInfo', true)}
                                    canEdit={editOption.aboutBusinessInfo}
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    error={error}
                                    setError={setError}
                                    saveUserDetails={saveUserDetails}
                                />
                                <OrgBusinessTypeSetting
                                    edit={true}
                                    disabled={true}
                                    onEdit={() => onChangeEditOption('businessTypeInfo', true)}
                                    canEdit={editOption.businessTypeInfo}
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    saveUserDetails={saveUserDetails}
                                    legalServiceType={legalServiceType}
                                    additionServiceType={additionServiceType}
                                    saveNewLegalType={saveNewLegalType}
                                    saveNewServiceType={saveNewServiceType}
                                />
                            </>}

                            {/* {role === 'superAdmin' && <SuperAdminProfileSetting/> } */}

                            {/* {(role === 'user' || role === 'organization') &&  */}
                            <InterestSettings
                                edit={true}
                                disabled={true}
                                onEdit={() => onChangeEditOption('interestInfo', true)}
                                canEdit={editOption.interestInfo}
                                formValues={formValues}
                                setFormValues={setFormValues}
                                saveUserDetails={saveUserDetails}
                                saveNewInterest={saveNewInterest}
                                interests={interests}
                                additionalInterest={additionalInterest}
                            />
                            {/* } */}
                        </Col>
                    </Row>
                </>
            }
        </div>


    )
}

export default MyProfileSettings;

