import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarMonthIcon } from "../../../commonComponents/Images";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { fromToDateValidator } from "../../../utils/formatValidator";
import {
    Box,
    TextField,
    Stack,
    Switch,
    Checkbox,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
    FormHelperText
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { EducationList, UniversityList } from "../../../config/constants";
import GreenCheckBox from "../../../commonComponents/greenCheckbox/greenCheckbox";
const EducationInfo = ({ error, setError, skipClick, backClick, formValues, setFormValues, saveUserDetails }) => {
    const { t } = useTranslation();

    const initial_education = {
        course: '',
        university: '',
        startYear: '',
        endYear: '',
        isCurrentlyPursuing: false
    };
    const [errors, setErrors] = useState(false);
    //const [educationFormValues, setEducationFormValues] = useState([initial_education])
    const [educationFormValues, setEducationFormValues] = useState(
        (formValues.education.length > 0) ?
            formValues.education :
            [initial_education])
    const [showPursuing, setShowPursuing] = useState(false);
    useEffect(() => {
        setShowPursuing(true);
        educationFormValues.map((item) => {
            if (item.isCurrentlyPursuing === true) {
                setShowPursuing(false); return;
            }
        })
    }, [educationFormValues]);
    const addFormFields = (e) => {
        e.preventDefault()
        setEducationFormValues([...educationFormValues, initial_education])
        setFormValues({ ...formValues, education: educationFormValues })
    }

    const handleChange = (i, e) => {
        let newFormValues = [...educationFormValues];
        newFormValues[i][e.target.name] = e.target.value;
        setEducationFormValues(newFormValues);
        setFormValues({ ...formValues, education: educationFormValues })
    }

    const handleChangeVal = (i, name, val, reason) => {
        var selectedValue = (reason == 'input') ? val : val?.value;
        let newFormValues = [...educationFormValues];
        if(selectedValue){
            newFormValues[i][name] = selectedValue;
            setEducationFormValues(newFormValues);
            setFormValues({ ...formValues, education: newFormValues })
        }
        
        if(reason == "clear"){ 
            newFormValues[i][name] = selectedValue;
            setEducationFormValues(newFormValues);
            setFormValues({ ...formValues, education: newFormValues })
        }
    }

    const handledateChange = (i, name, value) => {
        let newFormValues = [...educationFormValues];
        newFormValues[i][name] = value;
        setEducationFormValues(newFormValues);
        setFormValues({ ...formValues, education: educationFormValues })
    }

    const handleCheckboxChange = (i, e) => {
        let newFormValues = [...educationFormValues];
        newFormValues[i][e.target.name] = e.target.checked;
        newFormValues[i]['endYear'] = null;
        setEducationFormValues(newFormValues);
        setFormValues({ ...formValues, education: educationFormValues })
    }
    const removeFormFields = (i) => {
        let newFormValues = [...educationFormValues];
        newFormValues.splice(i, 1);
        setEducationFormValues(newFormValues)
        setFormValues({ ...formValues, education: educationFormValues })
    }

    const nextClick = (e) => {
        e.preventDefault();
        setErrors(false);
        //DON'T REMOVE MAYBE VALIDATION WILL BE REQUIRED IN FUTURE
        // let loop_error = false
        // educationFormValues.map((item)=>{
        //     if((item.startYear!==null && item.endYear!==null) && !fromToDateValidator(item.startYear, item.endYear))
        //     {
        //         loop_error = true;
        //     }
        //     if(item.course === '' || item.university === '' || item.startYear === '' || item.endYear === '')
        //     {
        //         loop_error = true;
        //     }
        // })
        // if(loop_error){ setErrors(true);return;}
        let params = {
            education: formValues.education
        }
        saveUserDetails(params);
    }
    return (
        <>
            <div className="heading mb-4">
                <h1>{t('onboarding.educationInfo')}</h1>
                <h3>{t('onboarding.fill_your_education_info')}</h3>
            </div>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' },
                }}
                noValidate
                autoComplete="off"
                className="userdetail-form"
            >
                {educationFormValues.map((e, index) => (
                    <div key={index} style={{ position: 'relative' }}>
                        {
                            index ? <><hr /><div className="delete_row_2 delete_row" onClick={() => removeFormFields(index)}><DeleteForeverIcon /></div></> : ''
                        }

                        <Row>
                            <Col md={7} className="emp-input-box demographicEduInput">
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        value={EducationList.find((option) => option.value === e.course)}
                                        options={EducationList}
                                        freeSolo
                                        getOptionLabel={(option) => t(option.label)}
                                        onChange={(e, val) => handleChangeVal(index, 'course', val)}
                                        inputValue={t(e.course) || ''}
                                        onInputChange={(e, val, reason) => handleChangeVal(index, 'course', val, reason)}
                                        renderInput={(params) => <TextField {...params} label={t('form.label_education')} />}
                                    />
                                    {errors && e.course === '' &&
                                        <div className="error-text">
                                            {t('errors.onboarding_education_required')}
                                        </div>
                                    }
                                </FormControl>
                            </Col>
                            <Col md={7} className="emp-input-box demographicEduInput">
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        value={UniversityList.find((option) => option.value === e.university)}
                                        options={UniversityList}
                                        freeSolo
                                        getOptionLabel={(option) => t(option.label)}
                                        onChange={(e, val) =>{handleChangeVal(index, 'university', val)}}
                                        inputValue={ t(e.university) || ''}
                                        onInputChange={(e, val, reason) =>{handleChangeVal(index, 'university', val, reason)}}
                                        renderInput={(params) => {
                                            
                                            return <TextField {...params} label={t('form.label_uni_ins')} />}}
                                    />
                                    {errors && e.university === '' &&
                                        <div className="error-text">
                                            {t('errors.onboarding_university_required')}
                                        </div>
                                    }
                                </FormControl>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} className="educationFields">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        closeOnSelect={true}
                                        label={t('from')}
                                        disableFuture={true}
                                        value={e.startYear || ''}
                                        onChange={(newDob) => {
                                            handledateChange(index, 'startYear', newDob);
                                        }}
                                        InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                                        renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                                        inputFormat="DD MMM YYYY"
                                    />
                                </LocalizationProvider>
                                {errors && e.startYear === '' &&
                                    <div className="error-text">
                                        {t('errors.start_date_required')}
                                    </div>
                                }
                            </Col>
                            <Col md={4} className="educationFields2">
                                {!e.isCurrentlyPursuing ?
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            closeOnSelect={true}
                                            label={t('to')}
                                            disableFuture={true}
                                            value={e.endYear || ''}
                                            onChange={(newDob) => {
                                                handledateChange(index, 'endYear', newDob);
                                            }}
                                            InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                                            renderInput={(params) => <TextField {...params} placeholder="DD/MM/YYYY" />}
                                            inputFormat="DD MMM YYYY"
                                        />
                                    </LocalizationProvider> :
                                    <TextField
                                        disabled={true}
                                        label={t('tillDate')}
                                        InputProps={{ endAdornment: <img src={CalendarMonthIcon} className="calender_icons" style={{ cursor: 'pointer' }} /> }}
                                    />
                                }

                                {errors && e.startYear === '' &&
                                    <div className="error-text">
                                        {t('errors.end_date_required')}
                                    </div>
                                }

                                {(e.startYear !== null && e.endYear !== null) && !fromToDateValidator(e.startYear, e.endYear) &&
                                    <div className="error-text">
                                        {t('errors.start_end_year')}
                                    </div>
                                }
                            </Col>
                            {(e.isCurrentlyPursuing || showPursuing) &&
                                <Col md={12} className="check_b_align">
                                    <GreenCheckBox checked={e.isCurrentlyPursuing}
                                        name="isCurrentlyPursuing"
                                        onChange={e => handleCheckboxChange(index, e)}
                                        label={t('form.label_currently_pursuing')} />
                                </Col>}
                        </Row>
                    </div>
                ))}

                <div>
                    <button className="add-more-btn" onClick={addFormFields}> {t('add_more')} </button>
                </div>

                <Row>
                    <div className="back-next-btn-area contact-back-next-btn-area">
                        <Stack spacing={2} direction="row">
                            <button className="button green back-btn-new" onClick={backClick}> {t('back')} </button>
                            <button className="button secondary next-btn-new" onClick={nextClick}> {t('next')} </button>
                            <button className="button back-btn-new skip-color" onClick={skipClick}> {t('skip')} </button>
                        </Stack>
                    </div>
                </Row>
            </Box>
        </>
    )
}

export default EducationInfo;