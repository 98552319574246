  
 export const USER_ACTION_TYPES = {
      SET_CURRENT_USER : 'SET_CURRENT_USER',
      SET_CURRENT_ROLE : 'SET_CURRENT_ROLE',
      SET_LOAD_NEW_POSTS : 'SET_LOAD_NEW_POSTS',
      SET_CURRENT_ORG : 'SET_CURRENT_ORG',
      SET_CURRENT_ORG_ROLE : 'SET_CURRENT_ORG_ROLE',
      SET_SEARCH_TEXT : 'SET_SEARCH_TEXT',
      SET_VALIDATOR_CHANGE : 'SET_VALIDATOR_CHANGE',
      SET_USER_WORKING_ORG : 'SET_USER_WORKING_ORG',
      SET_NOTIFICATION_COUNT : 'SET_NOTIFICATION_COUNT',
      SET_CAN_SWITCH : 'SET_CAN_SWITCH',
      SET_SWITCH_ORG_PANEL : 'SET_SWITCH_ORG_PANEL',
      SET_SWITCH_USER_PANEL : 'SET_SWITCH_USER_PANEL'
  }
  