import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Row, Col } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import { AvatarImg, FireImg } from "../commonComponents/Images";
import { buildingMark } from "../commonComponents/Images";
import { useTranslation } from "react-i18next";
import { dateTimeEventFormat, getImageUrl } from "../utils/helpers";
import { changeDateFormatddmmyyyy, timeFormat } from "../utils/helpers";
import moment from 'moment/moment';
import { Navigate } from "react-router-dom";

const getCurrentDate = () => {
    let d = new Date();
    d.setHours(0,0,0,0);
    return d;
}

const AllEvents = ({ AllList, className}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    let impactCategory = "";
    let impactCatImg ="";
    switch(AllList?.targetId?.spredeInterestCategory?.spredeInterest){
        case 'water':
            impactCategory = "Water";
            impactCatImg = "water";
            break;
        case 'earth':
            impactCategory = "Earth";
            impactCatImg = "Earth-icon";
            break;
        case 'air':
            impactCategory = "Air";
            impactCatImg = "air";
            break;
        case 'fire':
            impactCategory = "Fire";
            impactCatImg = "fire";
            break;
        case 'soul':
            impactCategory = "Soul";
            impactCatImg = "Soul - Copy";
            break;        
    }

    var startDateTime = new Date(AllList?.startDate);
    var startHour, startMinutes;
    [startHour,startMinutes] = AllList?.startTime.split(':');
    startDateTime.setHours(startHour);
    startDateTime.setMinutes(startMinutes);
    let eventUnitCompleted;
    if(AllList.unitCompleted !=0){
        eventUnitCompleted = AllList.unitCompleted
    }
    else if(AllList.eventEvidence.length>0){
        eventUnitCompleted = AllList.eventEvidence[0].unit;
    }
    else{
        eventUnitCompleted = 0;
    }
    return (
            
        
            // <div className="grid-column col-lg-12 col-md-6 col-xs-12">
                <div className={`${className} all-event-box px-0`}>
                    <Row className="align-items-center mx-0">
                            <div className="col-lg-2 col-12 text-center eventImgBox">  
                                <img src={(AllList?.photo !== '') ? getImageUrl(AllList?._id, 'event', AllList?.photo) : AvatarImg} alt="" className="img-fluid rounded" />
                            </div>
                <div className="col-lg-2 col-12 py-2 d-flex align-items-center">
                    <div className="running-online hideBullet">
                        <h5 className="bold">{AllList.title}</h5>
                        <p className="user-status-text">
                            {changeDateFormatddmmyyyy(AllList.startDate)} <span>{timeFormat(dateTimeEventFormat(AllList?.startDate, AllList.startTime))}</span>
                            <span>{new Date() <= startDateTime ?
                                <img className="dot" src="/Assets/gray.png" /> :
                                (getCurrentDate() > new Date(AllList?.endDate) || AllList?.status == "verified") ?
                                    <img className="dot" src="/Assets/red.png" /> :
                                    <img className="dot" src="/Assets/green.png" />}
                            </span>
                        </p>
                    </div>
                </div>


                            <div className="col-lg-2 pb-2 col-12">
                                <p className="user-status-text small-space locat_icons eventCat">Category: <img src={"/Assets/"+impactCatImg+".png"} className="" style={{ height: "24px", marginLeft: "5px" }} />
                                    {
                                    
                                    <span className="action-request-text text-capitalize"> {AllList?.targetId?.spredeInterestCategory?.spredeInterest}</span>
                                    }
                                </p>
                                <p className="user-status-text small-space locat_icons mt-2 eventCat">Sub Category:
                                    {
                                    <span className="action-request-text"> {AllList.category}</span>
                                    }
                                </p>
                            </div>
                            <div className="col-lg-2 mt-2">
                                <p className="eventCat">Target: <span>{AllList?.unit}</span></p>
                                <p className="eventCat">Completed: <span>{eventUnitCompleted}</span></p>
                            </div>

                            <div className="col-lg-1 col-12 pb-2 allEventLocation">
                                <p className="user-status-text small-space locat_icons">
                                    <LocationOnOutlinedIcon />
                                    <Tooltip title={AllList.location} placement="bottom-start">        
                                            <span className="action-request-text">{AllList.location}</span>
                                    </Tooltip>
                                    
                                </p>
                            </div>


                            <div className="col-lg-3 col-12 col-offset-sm-6">
                                <div className="action-request-list justify-content-end">
                                    <button 
                                    onClick={(e)=>{e.preventDefault();
                                                navigate('/view-event' , {state:{eventId : AllList?._id}})
                                                }} 
                                    className="button primary view-event-btn">{t('button.view_event')}</button>
                                </div>
                            </div>
                    </Row>    
                </div>
            // </div>
       )
}

export default AllEvents