import React, { useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import SectionHeader from '../../../commonDashboardComp/sectionHeader';
import './cfStyle.css';
import { getImageUrl } from '../../../utils/helpers';
import { addComplaintFeedback } from '../../../services/onBoarding.services';
import { showSingleToastSuccess } from '../../../utils/helpers';
import { uploadFileService } from '../../../services/fileUpload.services';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../Reducer/user/user.selector';
const ComplaintFeedback = () => {
    const [isLoading, setLoading] = useState(false);
    const CURRENTUSER = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({
        requestType: '',
        requestTitle: '',
        requestDecription: '',
        photo: ''
    });
    const [selectedValue, setSelectedValue] = useState('');
    const { t } = useTranslation();
    const [error, setError] = useState(false);
    const [showSelected, setShowSelected] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [photoVideoError, setPhotoVideoError] = useState(false);
    const [fileError, setFileError] = useState(false);
    const handleChange = (e) => {
        let val = e.target.value;
        if (val != '') {
            setSelectedValue(val);
            setFormValues({ ...formValues, requestType: e.target.value })
        }
    }

    const setPhoto = (param) => {
        if (param !== null && param !== undefined) {
            if((param.type.includes('image')) == true || (param.type.includes('video')) == true){
                setFileError(false);
            }
            else{
                setError(true);
                setFileError(true);
                return;
            }
            if (param?.size < 5000 || param?.size > 5120000) { //|| !isValidSize
                setPhotoVideoError(true);
                setError(true);
                return;
            }            
            else {
                setPhotoVideoError(false);
                setError(false);
                setShowSelected(true);
                setSelectedImage(param);
            }

        }

    }
    const removeImage = () => {
        setShowSelected(false);
        setSelectedImage(null);
        setFormValues({ ...formValues, photo: '' })
    }

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        if (formValues.requestTitle == '' || formValues.requestDecription == '' || formValues.requestType == '') {
            setError(true);
            setLoading(false);
            return;
        }
        const timeStamp = Date.now();
        let params = {
            description: formValues.requestDecription,
            request_type: formValues.requestType,
            issue: formValues.requestTitle
        };

        if (selectedImage !== null) {
            if (selectedImage.type.includes('video')) {
                params = { ...params, video: timeStamp };
            }
            else {
                params = { ...params, image: timeStamp };
            }

        }
        try {
            addComplaintFeedback(params).then(async (data) => {
                if (data?.data?.status === 200) {
                    const result = await uploadFileService('feedback', 'feedback', timeStamp, selectedImage, CURRENTUSER?._id);
                    if (result) {
                        // showToastSuccess(t('apiMessages.TARGET_ADD_SUCCESS'));
                        showSingleToastSuccess(t('apiMessages.COMPLAINT_FEEDBACK_RAISED'));
                        navigate('/voxbox');
                    }
                }
            })
        }
        catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <SectionHeader pretitle="" title={t('complaintAndFeedback')} />
            <div id="cfContainer" className='mt-3 p-5 rounded-4'>
                <Row>
                    <Col lg={12}>
                        <p>{t('complaintFeedback.infoLine')}</p>
                        <form>
                            <label htmlFor="#requestType" className='mt-3'>{t('complaintFeedback.headingOption1')}</label>
                            <select id='requestType' className='mt-2' onChange={handleChange}>
                                <option value='' selected>Select</option>
                                <option value={'Complaint'}>{t('complaintFeedback.complaintOption')}</option>
                                <option value={'Feedback'}>{t('complaintFeedback.feedbackOption')}</option>
                                <option value={'Refund Request'}>{t('complaintFeedback.refundRequest')}</option>
                            </select>
                            {error && formValues.requestType === '' &&
                                <div className="mt-2 error-text">{t('complaintFeedback.errorText1')}</div>
                            }
                            <label className='mt-3'>{(selectedValue == 'Feedback') ? t('complaintFeedback.headingOption2') : t('complaintFeedback.headingOption3')}</label>
                            <input
                                className='border mt-2'
                                type="text"
                                placeholder={(selectedValue == 'Feedback') ? t('complaintFeedback.placeHolder1') : t('complaintFeedback.placeHolder2')} required
                                value={formValues.requestTitle}
                                maxLength={100}
                                onChange={e => setFormValues({ ...formValues, requestTitle: e.target.value })}
                            />
                            {error && formValues.requestTitle === '' &&
                                <div className="mt-2 error-text">{t('complaintFeedback.errorText2')}</div>
                            }
                            <label className='mt-3'>Description</label>
                            <textarea
                                className='mt-2'
                                placeholder={(selectedValue == 'Feedback') ? t('complaintFeedback.placeHolder3') : t('complaintFeedback.placeHolder4')} required
                                value={formValues.requestDecription}
                                maxLength={200}
                                onChange={e => setFormValues({ ...formValues, requestDecription: e.target.value })}
                            >
                            </textarea>
                            {error && formValues.requestDecription === '' &&
                                <div className="mt-2 error-text">{t('complaintFeedback.errorText3')}</div>
                            }
                            <label className='mt-3'>{t('complaintFeedback.imageVideoHeading')}</label>
                            {
                                (showSelected) ? <>
                                    <div className="mt-2 position-relative">
                                        <label className="user_img_upload uploadFile" htmlFor="upload-photo">
                                            <input type="file" id="upload-photo"
                                                accept="image/png, image/gif, image/jpeg"
                                                onChange={(event) => {
                                                    setPhoto(event.target.files[0]);
                                                }} />
                                                {
                                                    (selectedImage.type.includes('video')) ? 
                                                    <div className='content'>
                                                        <video src={URL.createObjectURL(selectedImage)} alt="" />
                                                    </div>
                                                    :
                                                    <div className='content'>
                                                        <img src={showSelected ? URL.createObjectURL(selectedImage) : getImageUrl(formValues?._id, 'target', formValues?.photo)} className="user_attach_upload" />
                                                    </div>
                                                }
                                        </label>
                                        <img src='/Assets/Close.png' className="closeIconUpload" onClick={() => removeImage()} />
                                    </div>
                                    
                                </> : <>
                                    {
                                        (error && (photoVideoError === true)) ? <div className="mt-2 error-text">{t('Choose_correct_file_according_to_mentioned_size')}</div> : ''
                                    }
                                    {
                                        (error && (fileError === true)) ? <div className="mt-2 error-text">{t('Choose_correct_file')}</div> : ''
                                    }
                                    <label className="mt-2 user_img_upload uploadFile" htmlFor="upload-photo">
                                        <input type="file" id="upload-photo"
                                            accept="image/*,image/heif,image/heic,video/*,video/mp4,video/x-m4v,video/x-matroska,.mkv"
                                            onChange={(event) => {
                                                setPhoto(event.target.files[0]);
                                            }}
                                        />
                                        <div className="content addEvent_cover uploadBox">
                                            <p>{t('upload_images_and_videos')}</p>
                                            <img src="/Assets/Photos Icon-green@2x.png" />
                                        </div>
                                    </label>
                                </>
                            }

                            <button className='mt-3 rounded-4' type="submit" onClick={handleSubmit}>
                            {t('submit')}
                                {isLoading && <span className="spinner-border" />}
                            </button>
                        </form>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default ComplaintFeedback;
