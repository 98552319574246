import React, { useEffect, useState } from "react";
import { GetMyMemoriesService } from "../services/post.services";
import PostBox from "./postBox";
import { Row, Col } from "react-bootstrap";
import { t } from "i18next";
import BarsLoader from "../commonComponents/barsLoader";

const MemoriesLayout = () => {
    const [postList, setPostList] = useState([]);  
    const [ loading, setLoading ] = useState(false);

    useEffect(()=>{
        getMemories();
        window.scrollTo(0, 0)
    }, [])

    const getMemories = async () => {
        setLoading(true)
        try {
            const response = await GetMyMemoriesService()
            if (response?.data) {
                setLoading(false);
                let  newArr = [ ...response?.data];
                setPostList(newArr);
            }
        }catch (error) {
            console.log(error);
        }
    }

    return (<>
                <div md={12}>
                    <Row className="grid grid-3-6-3 mobile-prefer-content"> 
                    {loading && <BarsLoader/>} 
                        {postList?.length > 0 ? postList.map((item, index) => {
                            return <Col key={index} lg={6} className="col-md-12 offset-md-3 mb-3">
                                {item?._id && <PostBox post={item}/>}   
                            </Col>
                        }):
                        <Col lg={6} className="col-md-12 offset-md-3 mb-3">
                            <div className="widget-box memoryDefaultImage">
                                <img className="" src="/Assets/No-Memories-Default-img.png" /> 
                                <h4 className="memoryHeading">{t('no_memories_today')}</h4>
                                <p>No post in Memories to see or share today.</p>  
                            </div>
                        </Col>
                        }
                    </Row>
                </div>
    </>
    )
};

export default MemoriesLayout;