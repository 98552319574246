import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import {
    Box, TextField,
    Select,
    MenuItem,
    FormControlLabel,
    InputLabel,
    Checkbox,
    FormControl,
    Autocomplete
} from '@mui/material'
import { allowOnlyLetters, showToastError } from "../../../../utils/helpers";
import { CountriesList } from "../../../../config/constants";
import SaveEditSettingsBtn from "../../../../commonDashboardComp/saveEditSettingsBtn";
import { Country, State, City } from 'country-state-city';
const AddressSetting = ({ saveUserDetails, formValues, setFormValues, canEdit, onEdit }) => {
    const [cityListAccordingToCountry, setCityAccordingToCountry] = useState({ permanentCity: [], currentCity: [] });
    const [error, setError] = useState(false);
    // const cityListSamp = []; 
    // if(formValues?.country){
    //     let code=Country.getAllCountries()?.find(a=>a?.name===formValues?.country);
    //     City.getCitiesOfCountry(code?.isoCode)?.filter(a=>{
    //         cityListSamp.push(a?.name);
    //     });
    // }
    const newCountryList = [];
    Country.getAllCountries()?.filter((a) => {
        newCountryList.push(a?.name);
        if (newCountryList?.indexOf(a?.name) === -1) { newCountryList.push(a?.name); }
        return a?.name;
    })
    const onSave = (e) => {
        e.preventDefault();
        setError(false);
        if ((formValues?.permanentAddress?.city === '' || formValues?.permanentAddress?.country === '')
        ) { setError(true); return showToastError('Please choose Hometown country and the city'); }

        let params = {
            currentAddress: formValues.currentAddress,
            permanentAddress: formValues.permanentAddress,

        }
        saveUserDetails(params, 'addressInfo');
    }

    useEffect(() => {
        setCityListFunc('none')
    }, [formValues]);


    const setCityListFunc = (type, val) => {
        if (type === 'none') {
            let CityCopy = { ...cityListAccordingToCountry };
            if (formValues?.permanentAddress?.country) {
                let code = Country.getAllCountries()?.find(a => a?.name === formValues?.permanentAddress?.country);
                let newCityPermanentList = [];
                City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                    if (newCityPermanentList?.indexOf(a?.name) === -1) { newCityPermanentList.push(a?.name); }
                });
                CityCopy['permanentCity'] = newCityPermanentList;
            }
            if (formValues?.currentAddress?.country) {
                let code = Country.getAllCountries()?.find(a => a?.name === formValues?.currentAddress?.country);
                let newCityPermanentList = [];
                City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                    newCityPermanentList.push(a?.name);
                });
                CityCopy['currentCity'] = newCityPermanentList;

            }
            setCityAccordingToCountry(CityCopy)

        }
        if (type === 'permanent') {

            if (val) {
                let code = Country.getAllCountries()?.find(a => a?.name === val);
                let newCityPermanentList = [];
                City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                    //newCityPermanentList.push(a?.name);
                    if (newCityPermanentList?.indexOf(a?.name) === -1) { newCityPermanentList.push(a?.name); }

                });
                setCityAccordingToCountry({ currentCity: cityListAccordingToCountry?.currentCity, permanentCity: newCityPermanentList })
            }

        }
        if (type === 'current') {
            if (val) {
                let code = Country.getAllCountries()?.find(a => a?.name === val);
                let newCityPermanentList = [];
                City.getCitiesOfCountry(code?.isoCode)?.filter(a => {
                    newCityPermanentList.push(a?.name);
                    if (newCityPermanentList?.indexOf(a?.name) === -1) { newCityPermanentList.push(a?.name); }
                });
                setCityAccordingToCountry({ currentCity: newCityPermanentList, permanentCity: cityListAccordingToCountry?.permanentCity })
            }
        }
    }


    const { t } = useTranslation();
    return (<Row className="grid addressSettingComp">
        <div className="grid-column">
            <div className="widget-box">
                <p className="widget-box-title">{t('Address_info')}
                    <SaveEditSettingsBtn canEdit={canEdit} onEdit={onEdit} onSave={onSave} />
                </p>
                <div className="widget-box-content">
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '98%' },
                        }}
                        noValidate
                        autoComplete="off"
                        className="userdetail-form profile_parent11"
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <Row className="profile_subcategory22">

                            <Col md={12}> <p className="formAddressInfo"> {t('permanent_address')} </p></Col>

                            <Col md={4} className="subAddressField target_title88">

                                {/* <FormControl fullWidth>
                                    <InputLabel id="co-country-select-label">{t('country')} </InputLabel>
                                    <Select
                                        labelId="co-country-select-label"
                                        disablePortal
                                        disabled={!canEdit}
                                        value={formValues.permanentAddress?.country}
                                        onChange={(e) => setFormValues({
                                            ...formValues,
                                            permanentAddress: {
                                                country: allowOnlyLetters(e.target.value),
                                                city: formValues.permanentAddress?.city
                                            }
                                        })}
                                        label={t('country')}
                                    >
                                        {CountriesList.map((item, idx) => {
                                            return (
                                                <MenuItem key={idx} value={item}>{item}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    
                                </FormControl> */}
                                <FormControl fullWidth>

                                    <Autocomplete
                                        disabled={!canEdit}
                                        disablePortal
                                        value={formValues.permanentAddress?.country || ''}
                                        options={newCountryList}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, val) => {
                                            setFormValues({
                                                ...formValues,
                                                permanentAddress: {
                                                    country: (val !== null) ? allowOnlyLetters(val) : null,
                                                    city: ''
                                                }
                                            }); setCityListFunc('permanent', val);
                                        }}
                                        renderInput={(params) => <TextField {...params} label={t('country')} />}
                                    />
                                </FormControl>
                                {error && formValues.permanentAddress?.country === '' &&
                                    <div className="error-text">
                                        {t('Please_select_country')}
                                    </div>
                                }
                            </Col>


                            <Col md={4} className="subAddressField target_title88">
                                {/* <TextField
                                    value={formValues.permanentAddress?.city}
                                    disablePortal
                                    disabled={!canEdit}

                                    onChange={(e) => setFormValues({
                                        ...formValues,
                                        permanentAddress: {
                                            city: allowOnlyLetters(e.target.value),
                                            country: formValues.permanentAddress?.country
                                        }
                                    })}
                                    label={t('city')} /> */}
                                <FormControl fullWidth>

                                    <Autocomplete
                                        disablePortal
                                        disabled={!canEdit}
                                        value={formValues.permanentAddress?.city || ''}
                                        options={formValues.permanentAddress?.country ? cityListAccordingToCountry?.permanentCity : []}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, val) => {
                                            setFormValues({
                                                ...formValues,
                                                permanentAddress: {
                                                    country: (formValues.permanentAddress?.country) ? formValues.permanentAddress?.country : '',
                                                    city: (val !== null) ? allowOnlyLetters(val) : null,
                                                }
                                            }); setCityListFunc('permanent', val);
                                        }}
                                        renderInput={(params) => <TextField {...params} label={t('city')} />}
                                    />
                                </FormControl>




                            </Col>


                            <Col md={12} className="contact-current-address pb-0">
                                <div>
                                    <span className="formAddressInfo pe-4"> {t('current_address')} </span>
                                </div>
                                <div>
                                    <FormControlLabel control={<Checkbox
                                        disabled={!canEdit}
                                        checked={(formValues.permanentAddress?.city !== '' && formValues.permanentAddress?.country !== '') && (formValues.permanentAddress?.city && formValues.permanentAddress?.country &&
                                            formValues.currentAddress?.city && formValues.currentAddress?.country
                                        ) && (formValues.permanentAddress?.city === formValues.currentAddress?.city &&
                                            formValues.permanentAddress?.country === formValues.currentAddress?.country
                                            ) ? true : false}
                                        onChange={(e) => {
                                            (e.target.checked) ?
                                                setFormValues({
                                                    ...formValues,
                                                    currentAddress: {
                                                        city: formValues.permanentAddress.city,
                                                        country: formValues.permanentAddress.country
                                                    }
                                                }) : setFormValues({
                                                    ...formValues,
                                                    currentAddress: {
                                                        city: '',
                                                        country: ''
                                                    }
                                                })
                                        }}
                                    />} label={t('same_as_permanent_address')} />
                                </div>

                            </Col>

                            <Col md={4} className="subAddressField target_title88">
                                {/* <FormControl fullWidth>
                                    <InputLabel id="co-country-select-label">{t('country')} </InputLabel>
                                    <Select
                                        labelId="co-country-select-label"
                                        value={formValues.currentAddress?.country}
                                        disablePortal
                                        disabled={!canEdit}

                                        onChange={(e) => setFormValues({
                                            ...formValues,
                                            currentAddress: {
                                                country: allowOnlyLetters(e.target.value),
                                                city: formValues.currentAddress?.city
                                            }
                                        })}
                                        label={t('country')}
                                    >
                                        {CountriesList.map((item, idx) => {
                                            return (
                                                <MenuItem key={idx} value={item}>{item}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                    {error && formValues.currentAddress?.country === '' &&
                                        <div className="error-text">
                                            {t('Please_select_country')}
                                        </div>
                                    }
                                </FormControl> */}
                                <FormControl fullWidth>

                                    <Autocomplete
                                        disablePortal
                                        disabled={!canEdit}
                                        value={formValues.currentAddress?.country || ''}
                                        options={newCountryList}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, val) => {
                                            setFormValues({
                                                ...formValues,
                                                currentAddress: {
                                                    country: (val !== null) ? allowOnlyLetters(val) : null,
                                                    city: ''
                                                }
                                            }); setCityListFunc('current', val);
                                        }}
                                        renderInput={(params) => <TextField {...params} label={t('country')} />}
                                    />
                                </FormControl>
                                {/* {error && formValues.currentAddress?.country === '' &&
                                    <div className="error-text">
                                        {t('Please_select_country')}
                                    </div>
                                } */}
                            </Col>

                            <Col md={4} className="subAddressField target_title88">
                                {/* <TextField
                                    value={formValues.currentAddress?.city}
                                    disablePortal
                                    disabled={!canEdit}

                                    onChange={(e) => setFormValues({
                                        ...formValues,
                                        currentAddress: {
                                            city: allowOnlyLetters(e.target.value),
                                            country: formValues.currentAddress?.country
                                        }
                                    })}
                                    label={t('city')} /> */}
                                <FormControl fullWidth>

                                    <Autocomplete
                                        disablePortal
                                        disabled={!canEdit}
                                        value={formValues.currentAddress?.city || ''}
                                        options={formValues.currentAddress?.country ? cityListAccordingToCountry?.currentCity : []}
                                        getOptionLabel={(option) => option}
                                        onChange={(e, val) => {
                                            setFormValues({
                                                ...formValues,
                                                currentAddress: {
                                                    country: (formValues.currentAddress?.country) ? formValues.currentAddress?.country : '',
                                                    city: (val !== null) ? allowOnlyLetters(val) : null,
                                                }
                                            }); setCityListFunc('current', val);
                                        }}
                                        renderInput={(params) => <TextField {...params} label={t('city')} />}
                                    />
                                </FormControl>
                                {/* {error && formValues.currentAddress?.city === '' &&
                                    <div className="error-text">
                                        {t('Please_select_city')}
                                    </div>
                                } */}
                            </Col>


                        </Row>
                    </Box>
                </div>
            </div>
        </div>

    </Row>)
}

export default AddressSetting;